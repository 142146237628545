import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common/common.slice';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { getPermissionByTechnology } from '@utils/User';

export const useHasPermissions = (permissionNames: string | string[], technology?: SupportedTechnology | string | undefined) => {
	const loggedinUser = useSelector(userSelector);
	const currentUserPermissions = loggedinUser?.permissions;
	const permissionLookup = getPermissionByTechnology(permissionNames, technology);

	if (!currentUserPermissions) {
		return false;
	}

	if (typeof permissionLookup === 'string') {
		return currentUserPermissions.includes(permissionLookup);
	} else {
		return currentUserPermissions.some((permissionName: string) => Boolean(permissionLookup.includes(permissionName)));
	}
};
