import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC } from 'react';
import { IrWaveStatusColorMap, IrWaveStatusMap, TIrWaveStatus } from './IrWaveReportHelper';
import { TypeUtils } from '@utils/Type';

export const ReportStatus: FC<{ status: string }> = ({ status }) => {
	const currentStatusInfo =
		TypeUtils.returnValueOfKey<typeof IrWaveStatusMap, TIrWaveStatus>(status, IrWaveStatusMap) ?? IrWaveStatusMap.Ready;
	return (
		<Stack
			sx={{
				color: 'white',
				backgroundColor: IrWaveStatusColorMap[currentStatusInfo?.status],
				padding: '6px',
				minWidth: 0,
				minHeight: 0,
				height: 'fit-content',
				width: 'fit-content',
				borderRadius: '4px',
			}}
		>
			<Typography variant="subtitle3" lineHeight="12px" color={'white'}>
				<Tr.Admin path={`drm-pipeline.reports-page.${currentStatusInfo?.status.toLowerCase()}`} />
			</Typography>
		</Stack>
	);
};
