export enum AdminRole {
	SYSTEM_ADMIN = 'SystemAdmin',
	NMR_PIPELINE_PLANNER = 'NmrPipelinePlanner',
	NMR_ANALYST_QC_INSPECTOR = 'NmrAnalystQcInspector',
	NMR_QA_REVIEWER = 'NmrQaReviewer',
	MERCK_ADMIN = 'MerckAdmin',
	CUSTOMER_SUPPORT = 'CustomerSupport',
	IR_PIPELINE_PLANNER = 'IrPipelinePlanner',
	IR_QA_REVIEWER = 'IrQaReviewer',
}

export enum PortalRole {
	LAB_ADMIN = 'LabAdmin',
	LAB_MEMBER = 'LabMember',
	ORG_ADMIN = 'OrgAdmin',
}

export type UserRole = AdminRole | PortalRole;

export const UserRole = {
	...AdminRole,
	...PortalRole,
};
// TO DO: DELETE IT.
export enum AdminRoleEnum {
	CUSTOMER_SUPPORT = 4,
	SYSTEM_ADMIN = 5,
	NMR_PIPELINE_PLANNER = 6,
	NMR_ANALYST_QC_INSPECTOR = 7,
	NMR_QA_REVIEWER = 8,
	IR_PIPELINE_PLANNER = 9,
	IR_QA_REVIEWER = 10,
}
