import { SortOrder } from './sort-order.enum';

export interface ISortingDefinition<SortByTypes = string> {
	sortOrder?: SortOrder;
	sortBy?: SortByTypes;
}

export const getNextSortOrder = (current?: SortOrder): SortOrder => {
	if (current === SortOrder.ASC) return SortOrder.DESC;
	else if (current === SortOrder.DESC) return SortOrder.RESET;
	else return SortOrder.ASC;
};
