import { FC } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { IconProps, Stack, Typography } from '@mui/material';

import { Tr } from '@utils';
import { NMRMatchCategory } from '@services';

type ColorType = 'success' | 'warning' | 'error';
interface ISizeAndThreshold {
	length: number;
}

const StatusColorMapping: Record<Partial<ColorType>, ISizeAndThreshold> = {
	success: { length: 3 },
	warning: { length: 2 },
	error: { length: 1 },
};

export const valueColorMapping = (data: NMRMatchCategory) => {
	if (data === NMRMatchCategory.SATISFACTORY) {
		return 'success';
	} else if (data === NMRMatchCategory.QUESTIONABLE) {
		return 'warning';
	} else {
		return 'error';
	}
};

export const StarIconIndicator: FC<IconProps> = ({ color, sx }) => (
	<Stack direction="row" marginRight="0.5rem" sx={sx}>
		{Array(StatusColorMapping[color as ColorType].length)
			.fill(0)
			.map((_, index) => (
				<StarIcon key={`index-${index}-${color}`} color={color} />
			))}
	</Stack>
);

export const TableLegend: FC<{ isTheoretical?: boolean }> = ({ isTheoretical }) => (
	<Stack padding="1rem 1rem 1rem 0" direction="row">
		<Stack direction="row" alignItems="center">
			<StarIconIndicator color="success" />
			<Typography variant="pg-m" sx={{ lineHeight: '160%', fontWeight: 400 }}>
				<Tr.Portal path={`analysis-result-table.${isTheoretical ? 'theoretical-' : ''}satisfactory-result`} />
			</Typography>
		</Stack>
		<Stack direction="row" alignItems="center" margin="0.1rem 1.5rem">
			<StarIconIndicator color="warning" />
			<Typography variant="pg-m" sx={{ lineHeight: '160%', fontWeight: 400 }}>
				<Tr.Portal path={`analysis-result-table.${isTheoretical ? 'theoretical-' : ''}questionable-result`} />
			</Typography>
		</Stack>
		<Stack direction="row" alignItems="center">
			<StarIconIndicator color="error" />
			<Typography variant="pg-m" sx={{ lineHeight: '160%', fontWeight: 400 }}>
				<Tr.Portal path={`analysis-result-table.${isTheoretical ? 'theoretical-' : ''}unsatisfactory-result`} />
			</Typography>
		</Stack>
	</Stack>
);
