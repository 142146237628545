import { Body1, DataTableBackend, EditButton } from '@components/common';
import { getParams, useFilterSearchParams } from '@hooks';
import { useService } from '@hooks';
import { ExtendedColumn } from '@models/table';
import { Stack, Typography } from '@mui/material';
import { IrDRM, irDrmService, irLibraryService } from '@services/ir';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIrLibraryFilter, IrUserReferencesFilterOptions } from './ir-user-references-filter';
import { SortOrder } from '@models/sorting';
import { UserRole } from '@models/user';
import { useNavigate } from 'react-router-dom';
import { PortalPageRoutes } from '@models/router';
import { UserReferenceStatusButton } from '@components/common/UserReferenceStatus';
import { EmptyResult } from '@components/common/EmptyResult';
import { CreateReferenceButton } from './CreateReferenceButton';
import { ReactComponent as Database } from '@material-symbols/svg-600/outlined/database.svg';
import { userSelector } from '@store/slices/common/common.slice';
import { useSelector } from 'react-redux';
import { getIsFilterActive } from '@utils/Filter';

const MAX_LIBRARY_COUNT = '999';
export const IrUserReferences = () => {
	const { t: translate } = useTranslation('irportal');
	const { searchParams, filtering, setFiltering } = useFilterSearchParams();
	const [searchTextfieldValue, setSearchTextfieldValue] = useState(getParams(searchParams).getQuery() ?? '');

	const navigate = useNavigate();
	const user = useSelector(userSelector);

	const { data: userReferences, loading } = useService(() => {
		searchParams.set('QualityGrade', 'User');
		return irDrmService.getSubstancesbyLibrary(searchParams);
	}, [searchParams]);

	const { data: libraries } = useService(() => {
		const librarySearchParams = new URLSearchParams();
		librarySearchParams.set('IsUserLibrary', 'true');
		librarySearchParams.set('pageSize', MAX_LIBRARY_COUNT);
		librarySearchParams.set('pageIndex', '1');
		return irLibraryService.get(librarySearchParams);
	}, []);

	const isUserLabAdmin = user?.roles.includes(UserRole.LAB_ADMIN);
	const isFiltered = getIsFilterActive(filtering ?? []);

	const columns: ExtendedColumn<IrDRM>[] = [
		{
			accessor: 'status',
			disableSortBy: true,
			Header: (
				<Stack ml={1}>
					<Tr.IrPortal path="user-references.status" />
				</Stack>
			),
			Cell: ({ value }) => <Stack ml={1}>{value && <UserReferenceStatusButton status={value}></UserReferenceStatusButton>}</Stack>,
		},
		{
			accessor: 'referenceMaterialName',
			disableSortBy: true,
			Header: <Tr.IrPortal path="user-references.reference-name" />,
			Cell: ({ value }) => <Body1>{value || '-'}</Body1>,
		},
		{
			accessor: 'library',
			disableSortBy: true,
			Header: <Tr.IrPortal path="user-references.library" />,
			Cell: ({ value }) => <Body1>{value || '-'}</Body1>,
		},
		{
			accessor: 'createTime',
			Header: <Tr.IrPortal path="user-references.creation-date" />,
			Cell: ({ value }) => <Body1>{value ? DateUtils.getFormattedDate(value) : '-'}</Body1>,
		},
		{
			accessor: 'id',
			disableSortBy: true,
			Header: <></>,
			Cell: ({ row }) => (
				<Stack direction="row-reverse" sx={{ mr: 1 }}>
					<EditButton
						onClick={() => {
							navigate(
								`../../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.REFERENCES}/edit/${row?.original?.drmCode}/${row?.original?.id}/${row?.original?.status}`,
							);
						}}
					/>
				</Stack>
			),
		},
	];
	const filterOptions = IrUserReferencesFilterOptions;
	filterOptions[1].values = getIrLibraryFilter(libraries?.data);

	const isEmptyResult = userReferences?.data.length === 0 && !isFiltered && !searchParams.get('query') && !loading;
	return (
		<Stack>
			{!isEmptyResult && (
				<>
					<Stack direction="row" sx={{ marginBottom: 2, marginTop: 2 }} justifyContent="space-between" alignItems="center">
						<Stack direction="row" justifyContent="space-between" mb={1.5}>
							<Typography variant="h2" color="primary.main">
								<Tr.IrPortal path="user-references.all-references" />
							</Typography>
						</Stack>

						{isUserLabAdmin && <CreateReferenceButton buttonName={translate('user-references.add-reference')} />}
					</Stack>

					<DataTableBackend
						data={userReferences?.data ?? []}
						columns={columns}
						sorting={{
							sortBy: getParams(searchParams).getSortBy(),
							sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
						}}
						pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: userReferences?.totalPages ?? 1 }}
						searchText={searchTextfieldValue}
						searchPlaceholder={translate('user-references.search-placeholder')}
						onSearchTextChange={(text) => setSearchTextfieldValue(text ?? '')}
						searchFieldProps={{
							sx: {
								width: (600 / 1440) * window.innerWidth,
							},
						}}
						filterData={filterOptions}
						onFilter={setFiltering}
						filteringSelections={filtering}
						sx={{
							'& .MuiTableRow-root': {
								transition: 'all 100ms ease-in',
							},
						}}
					/>
				</>
			)}

			{userReferences && isEmptyResult && (
				<EmptyResult
					explanation={translate('user-references.no-references-title')}
					title={translate('user-references.no-references-msg')}
					icon={<Database fill="#8c8b9c" />}
				>
					<CreateReferenceButton
						buttonName={translate('user-references.new-reference')}
						sx={{ border: '2px solid #01884C', marginTop: '1rem' }}
					/>
				</EmptyResult>
			)}
		</Stack>
	);
};
