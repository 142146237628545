import { FormikApiType, TablePagination } from '@components/common';
import { IrDrmTabs, ErmSearchTabValues, IrDRMDetail, IrSubstanceList, IrReferenceSearchField } from '@components/ir-portal';
import { useComponentUpdated, useFilterSearchParams, useIrAnalysis, useIrDRMDetail, useService } from '@hooks';
import { FilterKey, PagedResult, QualityGrade } from '@models';
import { IrAnalysisType } from '@models';
import { Divider, Stack, SxProps, Typography } from '@mui/material';
import { irFilterService, IrSubstance, irSubstanceService, notificationService } from '@services';
import { cardSpacer, DataTestId, getFiltersFromSearchParams, getIsFilterActive, RxUtils, Tr } from '@utils';
import { createRef, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LIST_SIZE = '15';
const MIN_SEARCH_INPUT_LEN = 1;

const DRMDivider: FC<{ sx?: SxProps }> = ({ sx }) => <Divider sx={{ borderBottomWidth: 2, borderColor: 'grey.500', ...sx }} />;

// TO DO: why it is shared?
export const handleTabOnFilterChange = (
	searchParams: URLSearchParams,
	typedString: string,
	setTabChange: (tab: ErmSearchTabValues | null) => void,
	currentTab: Maybe<ErmSearchTabValues>,
) => {
	const filterParameters = getFiltersFromSearchParams(searchParams);

	const isFiltersActive = getIsFilterActive(filterParameters);
	if (isFiltersActive) {
		setTabChange(null);
	} else {
		!currentTab && !typedString && setTabChange(ErmSearchTabValues.Recent);
	}
};

interface IAnalyticalReferenceMaterialProps {
	onAddErmOrSubstance: (ermId: number | string) => void;
	allsubstances: (substances: IrSubstance[]) => void;
	addedSubstances: IrSubstance[] | undefined;
	referenceList: IrSubstance[];
	formik: FormikApiType;
	triggerSearch: number;
}
export const AnalyticalReferenceMaterial: FC<IAnalyticalReferenceMaterialProps> = ({
	onAddErmOrSubstance,
	allsubstances,
	addedSubstances,
	referenceList,
	formik,
	triggerSearch,
}) => {
	const { t } = useTranslation('irportal');
	const [typedString, setTypedString] = useState('');
	const { searchParams: userLibrarySearch } = useFilterSearchParams();
	const [currentTab, setCurrentTab] = useState<Maybe<ErmSearchTabValues>>(ErmSearchTabValues.Recent);
	const [searchResult, setSearchResult] = useState<PagedResult<IrSubstance>>();
	const { resetIrDetail, setIrDRMDetail, ...prop } = useIrDRMDetail();
	const { addedERMs } = useIrAnalysis();
	const { isAnalysisEditable } = useIrAnalysis();
	const [filterSearchParams, setFilterSearchParams] = useState<URLSearchParams>(new URLSearchParams());

	const memoizedLibraryValue = useMemo(() => formik?.values.selectedLibrary, [formik?.values.selectedLibrary]);
	useEffect(() => allsubstances(searchResult?.data ? [...searchResult.data] : []), [searchResult]);
	useEffect(() => onSearchClear(), [formik?.values.type]);
	useEffect(() => {
		if (formik?.values.selectedLibrary.length > 0 && memoizedLibraryValue) {
			getSubstanceList(typedString, 1, currentTab === ErmSearchTabValues.Recent);
		}
	}, [formik?.values.selectedLibrary, currentTab]);

	useComponentUpdated(() => {
		if (formik?.values.selectedLibrary.length > 0 && memoizedLibraryValue) {
			getSubstanceList(typedString, searchResult?.pageIndex, currentTab === ErmSearchTabValues.Recent);
		}
	}, [triggerSearch]);

	const addSubstanceOrErmToAnalysis = (ermId: number | string) => {
		resetIrDetail();
		onAddErmOrSubstance(ermId);
	};

	const handleFilterSearchParams = (searchParams: URLSearchParams) => {
		const newFilterValues = getFiltersFromSearchParams(filterSearchParams);

		newFilterValues.forEach((newFilter) => {
			Object.entries(newFilter).forEach((filterEntry) => {
				if (filterEntry[1] || filterEntry[1] === 0) {
					searchParams.append(filterEntry[0], `${filterEntry[1]}`);
				}
			});
		});
	};
	const getSubstanceList = (searchString, pageIndex, isLatestUsed?: boolean) => {
		const searchParams = new URLSearchParams();
		searchParams.set('isLatestUsed', `${isLatestUsed ? 'true' : 'false'}`);
		searchParams.set('pageIndex', pageIndex);
		searchParams.set('pageSize', LIST_SIZE);
		searchParams.set('query', searchString);
		searchParams.set(FilterKey.LIBRARIES, formik?.values.selectedLibrary.length > 0 ? formik?.values.selectedLibrary[0] : '');
		handleFilterSearchParams(searchParams);
		RxUtils.promisify(
			irSubstanceService.getSubstances(searchParams),
			(substanceData) => {
				setSearchResult(substanceData);
			},
			(error) => {
				notificationService.sendError(error.Message || t('search-references.search-error'));
			},
		);
	};

	const handleDRMSearch = (searchKey: string, pageIndex: number, fromTextSearch = false) => {
		DataTestId.resetState();
		setTypedString(searchKey);

		if (fromTextSearch && searchKey) {
			getSubstanceList(searchKey, pageIndex);
		} else if (!fromTextSearch) {
			getSubstanceList(searchKey, pageIndex);
		}
	};

	const substanceDetailRef = createRef<HTMLDivElement>();
	const onSearchClear = () => (setTypedString(''), setCurrentTab(ErmSearchTabValues.Recent));

	const isQuantification = formik?.values?.type === IrAnalysisType.QUANTIFICATION;
	const hasAddedSubstances = addedSubstances && addedSubstances.length > 0;
	const { data: substanceFilterData } = useService(() => irFilterService.getSubstanceFilterOptions());

	const getFilterData = () => {
		if (userLibrarySearch.get('IsUserLibrary') || !substanceFilterData) {
			return [];
		}

		const filterData = substanceFilterData.options?.filter((f) => f.key !== FilterKey.LIBRARIES);

		filterData.map((f) => {
			if (f.key === FilterKey.QUALITY_GRADES) {
				f.values = f.values.filter((v) => v.value !== QualityGrade.USER);
			}
		});
		return filterData;
	};

	const filterData = getFilterData();

	return (
		<>
			<Stack direction="column" width={1}>
				<IrReferenceSearchField
					placeholder={t('new-analysis.irsearch-message')}
					onSearch={(text) => handleDRMSearch(text, 1, true)}
					onSearchClear={() => onSearchClear()}
					value={typedString}
					onChange={(event) => {
						const searchedString = event.target.value;
						searchedString === '' ? setCurrentTab(ErmSearchTabValues.Recent) : setCurrentTab(null);
						setTypedString(event.target.value);
						searchedString.length < MIN_SEARCH_INPUT_LEN && setSearchResult(undefined);
					}}
					inputProps={{ 'data-testid': 'erm-search-input-id' }}
					disabled={formik?.values?.selectedLibrary < 1}
					searchParams={filterSearchParams}
					onSearchParamsChange={(searchParams) => {
						handleTabOnFilterChange(searchParams, typedString, setCurrentTab, currentTab);
						setFilterSearchParams(searchParams);
						handleDRMSearch(typedString, 1);
					}}
					filteringSelections={getFiltersFromSearchParams(filterSearchParams)}
					filterData={filterData}
					id="referenceSearchField"
				/>

				<Stack direction="row" justifyContent="space-between" marginTop={5}>
					<IrDrmTabs
						value={currentTab}
						onChange={(_, newTab: ErmSearchTabValues) => {
							setCurrentTab(newTab);
							setTypedString('');
						}}
					/>

					{searchResult && searchResult.totalPages > 1 && currentTab !== ErmSearchTabValues.Recent && (
						<TablePagination
							count={searchResult.totalPages}
							page={searchResult.pageIndex}
							onChange={(newPage) => {
								handleDRMSearch(typedString, +newPage, false);
							}}
						/>
					)}
				</Stack>

				{searchResult && (
					<Stack>
						<Typography>
							{typedString && !currentTab && `${searchResult.count} ${t('new-analysis.results-for')} "${typedString}"`}
							{!typedString && currentTab === ErmSearchTabValues.DrmSelectedLibrary
								? `${searchResult.count} ${t('new-analysis.total-result')}`
								: ''}
						</Typography>
					</Stack>
				)}

				{searchResult && allsubstances.length > 0 ? (
					<Stack>
						<Divider sx={{ mt: 1 }} />
						<IrSubstanceList
							substanceList={referenceList}
							type="add"
							disabled={!isAnalysisEditable}
							actionLabel={
								!isQuantification &&
								hasAddedSubstances &&
								addedSubstances &&
								addedSubstances.some((ele) => ele.selectedReferenceMaterials?.some((d) => d.isTargetSubstance))
									? t('new-analysis.add-to-impurity')
									: t('drm-detail.add-to-analysis')
							}
							onSubstanceAdd={(ermId: number) => addSubstanceOrErmToAnalysis(ermId)}
							addedSubstances={addedSubstances}
							onSubstanceListRefresh={() =>
								getSubstanceList(typedString, searchResult.pageIndex, currentTab === ErmSearchTabValues.Recent)
							}
							onSubstanceDetail={(drmVisibility: boolean, selectedDrmId: number) =>
								setIrDRMDetail({
									visible: drmVisibility,
									drmId: selectedDrmId,
									onHandleAction: (drmId) => {
										addSubstanceOrErmToAnalysis(drmId);
									},
									actionLabel:
										formik.values.type !== IrAnalysisType.QUANTIFICATION &&
										addedSubstances?.some((ele) => ele.selectedReferenceMaterials?.some((r) => r.isTargetSubstance))
											? t('new-analysis.add-to-impurity')
											: t('drm-detail.add-to-analysis'),
									actionDisabled: addedERMs?.some((s) =>
										s.selectedReferenceMaterials?.some((r) => r.id === selectedDrmId),
									),
								})
							}
							itemSx={{ padding: '1rem 0' }}
						/>
						<Divider />
					</Stack>
				) : null}
				{searchResult?.data.length === 0 && currentTab !== ErmSearchTabValues.Recent ? (
					<Stack direction="column" rowGap={3} divider={<DRMDivider />}>
						<Typography variant="h5" textAlign="center" color="grey.700" sx={{ marginTop: cardSpacer }}>
							<Tr.IrPortal path="new-analysis.empty-search-message" />
						</Typography>
					</Stack>
				) : null}
			</Stack>
			{useMemo(
				() => (
					<IrDRMDetail onCloseClick={() => resetIrDetail()} ref={substanceDetailRef} {...prop} />
				),
				[prop],
			)}
		</>
	);
};
