import { Body1, DataTableBackend, EditButton } from '@components/common';
import { getParams, useFilterSearchParams } from '@hooks';
import { useService } from '@hooks';
import { ExtendedColumn } from '@models/table';
import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from '@models/sorting';
import { UserReferenceStatusButton } from '@components/common/UserReferenceStatus';
import { EmptyResult } from '@components/common/EmptyResult';

import { ReactComponent as Database } from '@material-symbols/svg-600/outlined/database.svg';

import { getIsFilterActive } from '@utils/Filter';
import { NmrDRM, nmrDrmService, nmrFilterService } from '@services/nmr';

export const NmrUserReferences = () => {
	const { t: translate } = useTranslation('portal');
	const { searchParams, filtering, setFiltering } = useFilterSearchParams();
	const [searchTextfieldValue, setSearchTextfieldValue] = useState(getParams(searchParams).getQuery() ?? '');

	const { data: userReferences, loading } = useService(() => {
		return nmrDrmService.getSubstancesbyLibrary(searchParams);
	}, [searchParams]);
	const { data: filterData } = useService(() => nmrFilterService.getUserReferencesFilter(), []);

	const isFiltered = getIsFilterActive(filtering ?? []);

	const columns: ExtendedColumn<NmrDRM>[] = [
		{
			accessor: 'status',
			disableSortBy: true,
			Header: (
				<Stack ml={1}>
					<Tr.Portal path="user-references.status" />
				</Stack>
			),
			Cell: ({ value }) => <Stack ml={1}>{value && <UserReferenceStatusButton status={value}></UserReferenceStatusButton>}</Stack>,
		},
		{
			accessor: 'name',
			disableSortBy: true,
			Header: <Tr.Portal path="user-references.reference-name" />,
			Cell: ({ value }) => <Body1>{value || '-'}</Body1>,
		},
		{
			accessor: 'nmrReferenceMaterialLibrary',
			disableSortBy: true,
			Header: <Tr.Portal path="user-references.library" />,
			Cell: ({ value }) => <Body1>{value || '-'}</Body1>,
		},
		{
			accessor: 'createTime',
			Header: <Tr.Portal path="user-references.creation-date" />,
			Cell: ({ value }) => <Body1>{value ? DateUtils.getFormattedDate(value) : '-'}</Body1>,
		},
		{
			accessor: 'id',
			disableSortBy: true,
			Header: <></>,
			Cell: () => (
				<Stack direction="row-reverse" sx={{ mr: 1 }}>
					<EditButton onClick={() => undefined} />
				</Stack>
			),
		},
	];
	const userReferencesFilterData = { ...filterData };
	if (userReferencesFilterData?.options) {
		userReferencesFilterData.options[1].escapesTranslation = true;
	}
	const isEmptyResult = userReferences?.data.length === 0 && !isFiltered && !searchParams.get('query') && !loading;
	return (
		<Stack>
			{!isEmptyResult && (
				<>
					<Stack direction="row" sx={{ mb: 1.5 }} justifyContent="space-between" alignItems="center">
						<Stack direction="row" justifyContent="space-between" mb={1.5}>
							<Typography variant="h2" color="primary.main">
								<Tr.Portal path="user-references.all-references" />
							</Typography>
						</Stack>
					</Stack>

					<DataTableBackend
						data={userReferences?.data ?? ([] as any)}
						columns={columns}
						sorting={{
							sortBy: getParams(searchParams).getSortBy(),
							sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
						}}
						pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: userReferences?.totalPages ?? 1 }}
						searchText={searchTextfieldValue}
						searchPlaceholder={translate('user-references.search-placeholder')}
						onSearchTextChange={(text) => setSearchTextfieldValue(text ?? '')}
						searchFieldProps={{
							sx: {
								width: (600 / 1440) * window.innerWidth,
							},
						}}
						filterData={userReferencesFilterData?.options}
						onFilter={setFiltering}
						filteringSelections={filtering}
						sx={{
							'& .MuiTableRow-root': {
								transition: 'all 100ms ease-in',
							},
						}}
					/>
				</>
			)}

			{userReferences && isEmptyResult && (
				<EmptyResult
					explanation={translate('user-references.no-references-title')}
					title={translate('user-references.no-references-msg')}
					icon={<Database fill="#8c8b9c" />}
				></EmptyResult>
			)}
		</Stack>
	);
};
