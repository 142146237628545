import { FC, ReactNode, useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { Tr } from '@utils/Translation';
import { GenericCard } from '../GenericCard';
import { ModalDialog } from '../ModalDialog';
import { useTranslation } from 'react-i18next';
import { FormikAutoComplete } from '../FormikAutoComplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormikTextfield } from '../FormikTextfield';
import { DataTestId } from '@utils/DataTestId';

type TConfirmationWithReasonAndCommentProps = {
	visible: boolean;
	title?: string;
	subTitle?: ReactNode;
	reasonTitle?: string;
	detailsTitle?: string;
	reasonPlaceHolder?: string;
	detailsPlaceHolder?: string;
	cancelText?: ReactNode;
	buttonText?: ReactNode;
	validationSchema: yup.AnySchema;
	reasonOptions: unknown[];
	requestDetails?: boolean;
	onConfirm: (reason: string, details: string) => void;
	onCancel: () => void;
};

type FormFields = Extendable<{
	reason: string;
	details: string;
}>;

export const ConfirmationWithReasonAndComment: FC<TConfirmationWithReasonAndCommentProps> = ({
	visible,
	title,
	subTitle,
	reasonTitle,
	detailsTitle,
	reasonPlaceHolder,
	detailsPlaceHolder,
	onConfirm,
	onCancel,
	validationSchema,
	reasonOptions,
	cancelText,
	buttonText,
	requestDetails = true,
}) => {
	const { t } = useTranslation('common');

	const formik = useFormik<FormFields>({
		initialValues: {
			reason: '',
			details: '',
		},
		validationSchema: validationSchema,
		onSubmit: ({ reason, details }) => {
			onConfirm(reason, details);
		},
	});

	useEffect(() => {
		formik.resetForm();
		formik.validateForm();
	}, [visible]);
	return (
		<ModalDialog
			variant="secondary"
			onClose={onCancel}
			title={title ?? <Tr.Common path="confirmation" />}
			titleProps={{ sx: { paddingBottom: 0 } }}
			data-testid={DataTestId.getStaticTestId('confirm-dialog-withcomment-test-id')}
			onBackdropClick={onCancel}
			open={visible}
			sx={{
				'& .MuiDialog-container': {
					'& .MuiPaper-root': {
						width: '100%',
						maxWidth: '472px',
					},
				},
			}}
			PaperProps={{
				sx: {
					minWidth: '472px',
					maxWidth: '472px',
					maxHeight: '800px',
					minHeight: requestDetails ? '466px' : '440px',
				},
			}}
		>
			<GenericCard sx={{ overflow: 'auto', border: 'none', paddingTop: 0, paddingX: 8, paddingBottom: 6 }}>
				<form onSubmit={formik?.handleSubmit}>
					<Stack spacing={3} textAlign="center" sx={{ mt: 2 }} data-testid="confirmation-popup-wrapper">
						{subTitle}
						<Stack spacing={4}>
							<Stack>
								<FormikAutoComplete
									formik={formik}
									title={reasonTitle ?? t('reason')}
									onSelectionChange={(selection) => {
										formik?.setFieldValue('reason', selection?.value ?? '');
									}}
									select={false}
									options={reasonOptions}
									name="reason"
									placeholder={reasonPlaceHolder}
									variant="outlined"
									typographyProps={{ sx: { textAlign: 'left' } }}
									autocompleteProps={{
										disablePortal: true,
									}}
									data-testid={DataTestId.getStaticTestId('confirm-popup-reason')}
									required
								/>
							</Stack>

							{requestDetails && (
								<Stack>
									<FormikTextfield
										formikApi={formik}
										name="details"
										multiline
										title={detailsTitle ?? t('details')}
										hiddenLabel
										rows={4}
										placeholder={detailsPlaceHolder}
										variant="outlined"
										sx={{ backgroundColor: 'grey.50', padding: 0 }}
										focused={false}
										typographyProps={{ sx: { textAlign: 'left' } }}
										InputProps={{
											sx: {
												'& 	.MuiInputBase-input': {
													fontSize: 15,
													padding: 0,
												},
											},
										}}
										inputProps={{ maxLength: 4000, 'data-testid': 'confirm-popup-details' }}
										required
									/>
								</Stack>
							)}
						</Stack>

						<Stack direction="row" width={1} columnGap={3} sx={{ marginTop: 4 }}>
							<Button data-testid="confirmation-popup-cancel-button" variant="outlined" sx={{ width: 1 }} onClick={onCancel}>
								{cancelText || <Tr.Common path="cancel" />}
							</Button>
							<Button
								data-testid="confirmation-popup-confirm-button"
								variant="contained"
								sx={{ width: 1 }}
								disabled={!formik.isValid}
								type="submit"
								color="error"
							>
								{buttonText || <Tr.Common path="decline" />}
							</Button>
						</Stack>
					</Stack>
				</form>
			</GenericCard>
		</ModalDialog>
	);
};
