import { getStore } from '@store/index';
import { setTestState } from '@store/slices/common/test.slice';
import { TypeUtils } from '..';

export abstract class DataTestId {
	// FLAG TO CLOSE TEST IDS
	static readonly isTestEnabled =
		process.env.REACT_APP_TEST_ID || process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development';
	static store = getStore().store;

	private static handleNumberId(prefix: string) {
		const currentStore = this.store.getState().Test;
		const currentIndex =
			currentStore && prefix ? TypeUtils.returnValueOfKey<typeof currentStore, number | undefined>(prefix, currentStore) : undefined;
		const nextIndex = currentIndex !== undefined ? currentIndex + 1 : 0;

		this.store.dispatch(setTestState({ ...currentStore, [prefix]: nextIndex }));

		return nextIndex;
	}

	private static handleTestId(prefix: string) {
		const nextIndex = this.handleNumberId(prefix);

		return `${prefix}-${nextIndex}-id`;
	}

	public static getTestId = (prefix: string) => {
		return this.isTestEnabled ? this.handleTestId(prefix) : undefined;
	};

	public static getTestNumber = (prefix: string) => {
		return this.isTestEnabled ? this.handleNumberId(prefix) : undefined;
	};

	// for static flagged test ids
	public static getStaticTestId = (prefix: string) => {
		return this.isTestEnabled ? `${prefix}` : undefined;
	};

	public static isInTestEnvironment = () => process.env.NODE_ENV === 'test';

	static resetState = () => {
		this.isTestEnabled && this.store.dispatch(setTestState({}));
	};
}
