import { ModalDialog } from '@components/common';
import { CreateOrganizationPaidSubscriptionModel, subscribeService } from '@services/core';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaidSubscriptionForm } from '../PaidSubscriptionForm';
import { Currency, SubscriptionDefaults, SubscriptionType } from '@models/subscribe';
import { OrganizationSaveFields } from '../Organization';
import dayjs from 'dayjs';
import { getPaidSubscriptionSchema } from '@schemas';
import * as yup from 'yup';
import { DataTestId } from '@utils/DataTestId';

interface IAddSubscriptionModalProps {
	visible: boolean;
	onClose: () => void;
	onSubmit?: () => void;
	organizationId?: number;
}
type FormFields = Extendable<{ paidSubscription: CreateOrganizationPaidSubscriptionModel }>;

export const AddSubscriptionModal: FC<IAddSubscriptionModalProps> = ({ visible, onClose, organizationId, onSubmit }) => {
	const { t } = useTranslation('admin');

	const formik = useFormik<FormFields>({
		initialValues: {
			paidSubscription: {
				paymentType: SubscriptionType.PRE_PAID,
				budget: SubscriptionDefaults.BUDGET,
				startDate: dayjs(),
				endDate: dayjs().add(SubscriptionDefaults.MONTHS, 'M'),
				currency: Currency.USD,
				pricing: {
					NMR: SubscriptionDefaults.PRICING,
				},
				comment: '',
			},
		},
		validationSchema: yup.object({ paidSubscription: getPaidSubscriptionSchema(t) }),
		onSubmit: (value) => {
			if (organizationId)
				RxUtils.promisify(subscribeService.create(value.paidSubscription, organizationId), () => {
					onClose();
					onSubmit?.();
				});
		},
	});
	return (
		<ModalDialog
			variant="primary"
			open={visible}
			maxWidth="sm"
			onClose={onClose}
			sx={{ zIndex: 2 }}
			title={<Tr.Admin path={'organization.add-subscription'} />}
			PaperProps={{
				sx: {
					maxWidth: '832px',
					maxHeight: '650px',
					minHeight: '472px',
				},
			}}
			contentProps={{ sx: { padding: 3 } }}
		>
			<form onSubmit={formik.handleSubmit} data-testid={DataTestId.getStaticTestId('modal-form-id')}>
				<PaidSubscriptionForm formik={formik} required={true} />

				<OrganizationSaveFields
					formik={formik}
					editing={false}
					containerSx={{ justifyContent: 'flex-end' }}
					disabled={!formik?.isValid}
					saveButtonText={t('organization.add')}
				/>
			</form>
		</ModalDialog>
	);
};
