import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, SxProps, Typography, Grid, IconButton } from '@mui/material';
import { FormikApiType, FormikAutoComplete, FormikTextfield, GenericCard } from '@components/common';
import { DataTestId, Tr, innerTitleSpacer } from '@utils';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import { CreateOrganizationLabModel } from '@services/core';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

interface OrganizationLabsFormLayoutProps {
	formik: FormikApiType;
	sx?: SxProps;
}

export const OrganizationLabsFormLayout: FC<OrganizationLabsFormLayoutProps> = ({ formik }) => {
	const { t } = useTranslation('admin');

	const handleAddLab = () => {
		formik.setFieldValue('laboratories', [
			...formik.values.laboratories,
			{
				name: '',
				technology: '',
				adminEmail: '',
			},
		]);
	};

	const handleLabDelete = (index: number) => {
		formik.setFieldValue(
			'laboratories',
			formik.values.laboratories.filter((value: CreateOrganizationLabModel, idx: number) => idx !== index),
		);
	};
	type AutocompleteOption = string;

	const technologyOptions: Array<AutocompleteOption> = Object.values(SupportedTechnology);

	return (
		<Stack paddingY={6}>
			<Stack direction="row" justifyContent="space-between" marginBottom={innerTitleSpacer}>
				<Typography variant="h2" color="primary.main">
					<Tr.Admin path="organization.labs" />
				</Typography>
				<Button size="medium" onClick={handleAddLab} data-testid={DataTestId.getStaticTestId('add-lab-id')}>
					<AddIcon sx={{ paddingRight: 1, fontSize: 26 }} />
					<Tr.Admin path="organization.add-lab" />
				</Button>
			</Stack>
			{/* TO DO: CREATE GENERIC VERSION OF IT */}
			<GenericCard>
				{formik?.values.laboratories?.map((item: CreateOrganizationLabModel, index: number) => (
					<Grid container spacing={3} marginLeft={0} marginTop={0} key={`add_grid_wrapper_${index}`}>
						<Grid container spacing={3} width="100%" alignItems="end" marginBottom={1} key={`lab_grid_${index}`}>
							{Object.keys(item).map((key, keyIndex) => (
								<Grid item xs={4} key={`${index}_${key}_grid`}>
									<Stack direction="column" sx={{ marginBottom: 2 }}>
										{key === 'technology' ? (
											<FormikAutoComplete
												value={item[`${key}`] || null}
												options={technologyOptions}
												onSelectionChange={(value) => {
													formik.setFieldValue(`laboratories[${index}].${key}`, value);
												}}
												formik={formik}
												name={`laboratories[${index}].technology`}
												placeholder={t(`organization.labs-${key}-explanation`)}
												data-testid={DataTestId.getStaticTestId(`lab-form-${key}-${index}`)}
												required
											/>
										) : (
											<Stack>
												{keyIndex === 0 && (
													<Stack direction="row" justifyContent="space-between">
														<Typography
															variant="label-s"
															className="required"
															color="grey.800"
															sx={{ marginBottom: 1 }}
														>
															<Tr.Admin path={`organization.lab`}></Tr.Admin>
															{` ${index + 1}`}
														</Typography>
													</Stack>
												)}

												{keyIndex === Object.keys(item).length - 1 && (
													<Stack direction="row" justifyContent="end" sx={{ marginBottom: 1 }}>
														{index === 0 ? (
															<Typography variant="body2" color="grey.500">
																<Tr.Admin path="organization.1-lab-required" />
															</Typography>
														) : (
															<IconButton
																onClick={() => handleLabDelete(index)}
																data-testid={DataTestId.getStaticTestId(`lab-form-delete-${index}-id`)}
															>
																<Delete width={20} height={20} fill="#01884C" />
															</IconButton>
														)}
													</Stack>
												)}

												<FormikTextfield
													formikApi={formik}
													name={`laboratories[${index}].${key}`}
													placeholder={t(`organization.labs-${key}-explanation`)}
													data-testid={DataTestId.getStaticTestId(`lab-form-${key}-${index}-id`)}
												/>
											</Stack>
										)}
									</Stack>
								</Grid>
							))}
						</Grid>
					</Grid>
				))}
			</GenericCard>
		</Stack>
	);
};
