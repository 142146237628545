import { forwardRef, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertProps, AlertTitle, Box, Snackbar, SnackbarProps, Typography } from '@mui/material';
import { INotification, NotificationType } from '@services';
import { NotificationWarningIcon } from '@components/icons';
import { DataTestId } from '@utils/DataTestId';

const ErrorIconRound = () => (
	<Box>
		<NotificationWarningIcon />
	</Box>
);

const WarningIconRound = () => (
	<Box
		sx={{
			color: 'black',
		}}
	>
		<NotificationWarningIcon sx={{ ' path': { fill: '#0F1A2E' }, color: 'black' }} />
	</Box>
);

const SuccessIconRound = () => (
	<Box>
		<NotificationWarningIcon sx={{ transform: 'rotate(180deg)' }} />
	</Box>
);

const iconMapping: AlertProps['iconMapping'] = {
	error: <ErrorIconRound />,
	warning: <WarningIconRound />,
	success: <SuccessIconRound />,
};

interface IAlertSnackbarProps extends Partial<Omit<INotification, 'children'>>, Omit<SnackbarProps, 'onClose' | 'message'> {
	alertProps?: AlertProps;
	onClose?: () => void;
}

export const Notification = forwardRef<HTMLDivElement, IAlertSnackbarProps>(
	(
		{
			anchorOrigin = { horizontal: 'left', vertical: 'bottom' },
			children,
			type,
			title,
			message,
			messageTranslationOptions,
			onClose,
			alertProps,
			...props
		},
		ref,
	) => {
		const { t } = useTranslation(messageTranslationOptions?.ns || 'common');
		const hasContent = children || message;
		const content = children || (message && messageTranslationOptions ? t(message) : message) || '';
		const handleClose = (e: SyntheticEvent | Event, reason?: string) => reason !== 'clickaway' && onClose?.();

		return hasContent ? (
			<Snackbar
				anchorOrigin={anchorOrigin}
				autoHideDuration={message?.length && message.length < 50 ? 5000 : 10000}
				onClose={handleClose}
				{...props}
			>
				<Alert
					ref={ref}
					onClose={onClose}
					severity={type}
					data-testid={DataTestId.getStaticTestId('notification-test-id')}
					{...alertProps}
					iconMapping={iconMapping}
					sx={{ alignItems: 'center', paddingY: 1 }}
				>
					{title ? <AlertTitle>{title}</AlertTitle> : null}
					{children ? (
						children
					) : (
						<Typography variant="pg-s" sx={{ color: type === NotificationType.WARNING ? 'text.primary' : 'white' }}>
							{content}
						</Typography>
					)}
				</Alert>
			</Snackbar>
		) : null;
	},
);
