import { ReactNode } from 'react';
import { useHasPermissions } from './useHasPermissions';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

export const useRestrictedComponent = (
	permissionNames: string | string[],
	children?: ReactNode | undefined,
	technology?: SupportedTechnology | undefined,
) => {
	const hasPermission = useHasPermissions(permissionNames, technology);

	if (!hasPermission) {
		return null;
	}

	return children ?? <></>;
};
