import { FC, useEffect, useState } from 'react';
import { FormikApiType, RequestErmListItem } from '@components/common';
import { AnalysisFavorite } from './IrAnalysisFavorite';
import { IrAnalysisStage, alertService, IrSubstance, irAnalysisService, IrAnalysisAddRmRequest, IrLibrary } from '@services';
import { AnalyticalReferenceMaterial } from './IrAnalyticalReferenceMaterial';
import { useIrAnalysis, useService } from '@hooks';
import { IrAnalysisType } from '@models';
import { RxUtils, Tr } from '@utils';
import { IrAnalyticalProcedureLayout } from '@components/ir-portal';
import { QuantitativeStep } from '@hooks';
import { Stack } from '@mui/material';

const MAX_ERM_COUNT = 6;
const MAX_ERM_COUNT_QUANTIFICATION = 1;

interface IAnalyticalProcedureProps {
	id: number;
	isFormValid: boolean;
	onStageChange: (stage: IrAnalysisStage) => void;
	formik: FormikApiType;
	libraries?: IrLibrary[];
}

export const IrAnalyticalProcedure: FC<IAnalyticalProcedureProps> = ({ onStageChange, isFormValid, id, formik, libraries }) => {
	const { updateQuantitativeStep, updateAddedERMs, analysisType } = useIrAnalysis();
	const [addedlist, setAddedlist] = useState<IrSubstance[]>([]);
	// TO DO: should be done by useImperativeHandler...
	const [getSubstanceListTrigger, setGetSubstanceListTrigger] = useState<number>(0);

	const { data: addedSubstances, trigger: refreshAddedSubstances } = useService(() => irAnalysisService.getAnalysisRMs(id), [id]);

	updateAddedERMs(addedSubstances);

	const onSubstanceListRefresh = () => (setGetSubstanceListTrigger((prev) => prev + 1), refreshAddedSubstances());

	useEffect(() => {
		onStageChange(isFormValid && addedSubstances?.length ? IrAnalysisStage.DETAILS_FILLED : IrAnalysisStage.NEW);
	}, [isFormValid, addedSubstances]);

	useEffect(() => {
		updateQuantitativeStep(QuantitativeStep.STEP_1);
	}, [formik?.values?.type]);
	const onConfirmRemove = (drmId: any) => {
		RxUtils.promisify(irAnalysisService.removeRMfromAnalysis(id, drmId), () => refreshAddedSubstances());
	};

	const onRemoveSubstanceOrErm = (drmCode: number) => {
		if (addedSubstances?.some((s) => s.selectedReferenceMaterials?.some((r) => r.isTargetSubstance && r.id === drmCode))) {
			alertService.send({
				content: <Tr.IrPortal path="search-references.remove-erm-error" />,
				titleText: <Tr.IrPortal path="warning" />,
				closeTextComponent: <Tr.IrPortal path="cancel" />,
				confirmTextComponent: <Tr.IrPortal path="ok" />,
				onConfirm: () => {
					onConfirmRemove(drmCode);
				},
			});
		} else {
			onConfirmRemove(drmCode);
		}
	};

	const handleAddRMRequest = (data: IrAnalysisAddRmRequest) => {
		RxUtils.promisify(irAnalysisService.addRMtoAnalysis(id, data), () => refreshAddedSubstances());
	};

	const onAddSubstanceOrDrm = (ermId: number | string) => {
		const addedSubstancesCount = addedSubstances?.length ?? 0;

		if (addedSubstancesCount >= MAX_ERM_COUNT) {
			alertService.send({
				content: <Tr.IrPortal path="search-references.add-erm-error-multi" />,
				titleText: <Tr.IrPortal path="warning" />,
				closeTextComponent: <Tr.IrPortal path="cancel" />,
				confirmTextComponent: <Tr.IrPortal path="ok" />,
				onConfirm: () => null,
			});
		} else if (
			analysisType === IrAnalysisType.QUANTIFICATION &&
			addedSubstancesCount === MAX_ERM_COUNT_QUANTIFICATION &&
			addedSubstances?.[0].selectedReferenceMaterials &&
			addedSubstances?.[0].selectedReferenceMaterials[0].drmCode
		) {
			alertService.send({
				content: <Tr.IrPortal path="search-references.only-one-reference-allowed" />,
				titleText: <Tr.IrPortal path="warning" />,
				closeTextComponent: <Tr.IrPortal path="cancel" />,
				confirmTextComponent: <Tr.IrPortal path="clear-selection" />,
				onConfirm: () => onConfirmRemove(addedSubstances[0].selectedReferenceMaterials?.[0]?.id || ''),
			});
		} else {
			const isTargetSubstanceAdded =
				addedSubstancesCount > 0 && addedSubstances?.some((s) => s.selectedReferenceMaterials?.some((r) => r.isTargetSubstance));
			handleAddRMRequest({
				referenceMaterialId: ermId,
				isTargetSubstance: !isTargetSubstanceAdded,
			});
		}
	};

	const getallsubstances = (selectedList: IrSubstance[]) => {
		setAddedlist(selectedList);
	};
	return (
		<Stack>
			<IrAnalyticalProcedureLayout>
				<AnalysisFavorite
					formik={formik}
					onRemoveSubstance={onRemoveSubstanceOrErm}
					onAddErmOrSubstance={onAddSubstanceOrDrm}
					addedSubstances={addedSubstances}
					onSubstanceListRefresh={onSubstanceListRefresh}
					libraries={libraries}
					id={id}
				/>
				{!(formik?.values.type === IrAnalysisType.IDENTIFICATION) &&
					formik?.values.selectedLibrary &&
					formik?.values.selectedLibrary.length > 0 && (
						<AnalyticalReferenceMaterial
							triggerSearch={getSubstanceListTrigger}
							formik={formik}
							referenceList={addedlist}
							onAddErmOrSubstance={onAddSubstanceOrDrm}
							addedSubstances={addedSubstances ?? []}
							allsubstances={getallsubstances}
						/>
					)}
			</IrAnalyticalProcedureLayout>
			{(formik?.values.type === IrAnalysisType.PURITYCHECK || formik?.values.type === IrAnalysisType.QUANTIFICATION) &&
				formik?.values.selectedLibrary && <RequestErmListItem />}
		</Stack>
	);
};
