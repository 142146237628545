export class CreatePhysicalProductModel {
	casNumber: string;
	physicalProductNumber: string;
	physicalProductQualityGrade: string;
	siteSupplier: string;
}

export class PhysicalProduct {
	id: number;
	physicalProductNumber: string;
	physicalProductQualityGrade: string;
	siteSupplier: string;
}
