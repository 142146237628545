import { FormikTextfield, ModalDialog } from '@components/common';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsDataLocked, getIsFieldEditable, getPatchValue, getPatchValueReturnType } from './edit-data-details-modal.helper';
import { NmrDrmOrder, NmrDrmOrderStatus, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { RxUtils } from '@utils/Rx';
import { UserRole } from '@models/user';
import { DataTestId } from '@utils/DataTestId';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common/common.slice';
import { useService } from '@hooks';
import { CreatePhysicalProductModel, physicalProductService } from '@services/index';
import { SingleSelectMenu, SingleSelectMenuAddButton, SingleSelectMenuOnSelectType } from '@components/common/SingleSelectMenu';
import { AddPhysicalProductModal } from '../AddPhysicalProduct/AddPhysicalProduct';
import { PatchType } from '@models/request-response';

type EditDataDetailsFields = Extendable<{
	physicalProductId: string;
	qualityGrade: string;
	batchNumber: string;
	siteSupplier: string;
	pH: string;
	purity: string;
}>;
type TEditDataDetailsModalFormProps = {
	nmrDrmOrder: NmrDrmOrder;
	refreshData: () => void;
	onClose: () => void;
};
const SELECT_ADD_PRODUCT_ID = 'ADD';

const ADD_SELECT_VALUE = {
	id: SELECT_ADD_PRODUCT_ID,
	label: <SingleSelectMenuAddButton content={<Tr.Admin path="drm-order-details.add-physical-product.add-new" />} />,
};

const EditDataDetailsModalForm: FC<TEditDataDetailsModalFormProps> = ({ nmrDrmOrder, refreshData, onClose }) => {
	const { t } = useTranslation('admin');
	const user = useSelector(userSelector);
	const [shouldInitializePhysicalProduct, setShouldInitializeProduct] = useState<boolean>(false);
	const { data: physicalProduct, trigger: triggerPhysicalProduct } = useService(
		() => physicalProductService.getAllByCasNumber(nmrDrmOrder.casNumber),
		[],
	);
	const [isAddProductOpened, setIsAddProductOpened] = useState<boolean>(false);

	const formik = useFormik<EditDataDetailsFields>({
		initialValues: {
			physicalProductId: nmrDrmOrder.physicalProductNumber ?? '',
			qualityGrade: nmrDrmOrder.qualityGrade ?? '',
			batchNumber: nmrDrmOrder.batchNumber ?? '',
			siteSupplier: nmrDrmOrder.siteSupplier ?? '',
			purity: nmrDrmOrder.purity ?? '',
			pH: nmrDrmOrder.pH ?? '',
		},
		onSubmit: (values) => {
			const patchArray: Array<getPatchValueReturnType> = [];
			Object.entries(values).forEach((valuePair) => {
				const patchValue = getPatchValue(valuePair[0], valuePair[1]);
				if (valuePair[0] === 'physicalProductId') {
					const correspondingPhysicalProduct = physicalProduct?.filter((p) => p.physicalProductNumber === valuePair[1])[0].id;
					patchArray.push({
						op: PatchType.REPLACE,
						path: `/${valuePair[0]}`,
						value: correspondingPhysicalProduct ?? '',
					});
				}
				if (patchValue) {
					patchArray.push(patchValue);
				}
			});

			if (nmrDrmOrder.id) {
				RxUtils.promisify(nmrDrmOrderService.changeOrder(nmrDrmOrder.id, patchArray), () => (refreshData?.(), onClose()));
			}
		},
	});

	const isPhysicalProductNumberVisible = getIsFieldEditable('physicalProductNumber', nmrDrmOrder.status);
	const isQualityGradeVisible = getIsFieldEditable('qualityGrade', nmrDrmOrder.status);
	const isBatchNumberVisible = getIsFieldEditable('batchNumber', nmrDrmOrder.status);

	const getPhysicalProductItems = () => {
		const items = physicalProduct?.map((p) => ({ id: p.physicalProductNumber, label: p.physicalProductNumber })) ?? [];
		const itemsWithAdd = [...items, ADD_SELECT_VALUE];

		if (!itemsWithAdd.some((i) => i.id === formik.values.physicalProductId)) {
			itemsWithAdd.push({
				id: formik.values.physicalProductId,
				label: formik.values.physicalProductId,
			});
		}
		return itemsWithAdd;
	};

	const physicalProductItems = getPhysicalProductItems();

	const onPhysicalProductNumberChange: SingleSelectMenuOnSelectType = (item: unknown) => {
		const convertedItem = item as string;
		if (convertedItem === ADD_SELECT_VALUE.id) {
			setIsAddProductOpened(true);
		} else {
			formik.setFieldValue('physicalProductId', convertedItem);
			const selectedPhysicalProduct = physicalProduct?.find((p) => p.physicalProductNumber === (convertedItem as string));
			formik.setFieldValue('qualityGrade', selectedPhysicalProduct?.physicalProductQualityGrade);
			formik.setFieldValue('siteSupplier', selectedPhysicalProduct?.siteSupplier);
		}
	};

	useEffect(() => {
		if (shouldInitializePhysicalProduct) {
			const selectedPhysicalProduct = physicalProduct?.find((p) => p.physicalProductNumber === formik.values.physicalProductId);
			formik.setFieldValue('qualityGrade', selectedPhysicalProduct?.physicalProductQualityGrade);
			formik.setFieldValue('siteSupplier', selectedPhysicalProduct?.siteSupplier);
		}
	}, [physicalProduct]);
	const onAddPhysicalProductNumber = (payload: CreatePhysicalProductModel) => {
		setShouldInitializeProduct(true);
		formik.setFieldValue('physicalProductId', payload.physicalProductNumber);
		triggerPhysicalProduct();
		setIsAddProductOpened(false);
	};

	return (
		<>
			<form onSubmit={formik.handleSubmit} data-testid={DataTestId.getStaticTestId('edit-data-details-form-id')}>
				<Stack sx={{ marginY: 1 }}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Stack spacing={4} paddingBottom={3}>
								{isPhysicalProductNumberVisible && (
									<Stack>
										<Typography
											variant="label-s"
											color={'grey.800'}
											sx={{
												marginBottom: 1.25,
											}}
										>
											<Tr.Admin path="drm-order-details.edit-data-modal.physical-product-number" />
										</Typography>
										<SingleSelectMenu
											items={physicalProductItems}
											data-testid={DataTestId.getStaticTestId('physical-prod-number-id')}
											selectedItem={{
												id: formik.values.physicalProductId,
												label: formik.values.physicalProductId,
											}}
											defaultLabel={t('drm-order-details.edit-data-modal.select-physical-product-number')}
											sx={{
												height: '40px',
											}}
											onSelectedItemChange={onPhysicalProductNumberChange}
										/>
									</Stack>
								)}

								{isQualityGradeVisible && (
									<FormikTextfield
										name="qualityGrade"
										formikApi={formik}
										disabled
										title={t('drm-order-details.edit-data-modal.quality-grade')}
									/>
								)}

								{isBatchNumberVisible && user?.username === nmrDrmOrder?.analyst?.id && (
									<FormikTextfield
										name="purity"
										formikApi={formik}
										title={t('drm-order-details.edit-data-modal.purity')}
										placeholder={t('drm-order-details.edit-data-modal.enter-a-purity')}
									/>
								)}
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack spacing={4} paddingBottom={3}>
								{isBatchNumberVisible && user?.username === nmrDrmOrder?.analyst?.id && (
									<FormikTextfield
										name="batchNumber"
										formikApi={formik}
										title={t('drm-order-details.edit-data-modal.batch-number')}
										placeholder={t('drm-order-details.edit-data-modal.enter-batch-number')}
									/>
								)}
								{isQualityGradeVisible && (
									<FormikTextfield
										name="siteSupplier"
										formikApi={formik}
										disabled
										title={t('drm-order-details.edit-data-modal.site-supplier')}
									/>
								)}

								{isBatchNumberVisible && user?.username === nmrDrmOrder?.analyst?.id && (
									<FormikTextfield
										name="pH"
										formikApi={formik}
										title={t('drm-order-details.edit-data-modal.pH')}
										placeholder={t('drm-order-details.edit-data-modal.enter-a-ph')}
									/>
								)}
							</Stack>
						</Grid>
					</Grid>
					<Stack direction="row" justifyContent="flex-end" spacing={1.5} paddingRight={'2px'}>
						<Button
							variant="outlined"
							size="medium"
							onClick={onClose}
							data-testid={DataTestId.getStaticTestId('edit-data-details-cancel-id')}
						>
							<Tr.Admin path="drm-order-details.edit-data-modal.cancel" />
						</Button>
						<Button
							variant="contained"
							size="medium"
							type="submit"
							disabled={!formik.dirty || !formik.isValid}
							data-testid={DataTestId.getStaticTestId('edit-data-details-save-id')}
						>
							<Tr.Admin path="drm-order-details.edit-data-modal.save" />
						</Button>
					</Stack>
				</Stack>
			</form>
			{isAddProductOpened && (
				<AddPhysicalProductModal
					onClose={() => setIsAddProductOpened(false)}
					onSave={onAddPhysicalProductNumber}
					nmrDrmOrder={nmrDrmOrder}
				/>
			)}
		</>
	);
};
type TEditDataDetailsModalProps = {
	nmrDrmOrder: NmrDrmOrder;
	refreshData: () => void;
};

export const EditDataDetailsModal: FC<TEditDataDetailsModalProps> = ({ nmrDrmOrder, refreshData }) => {
	const [isModalOpened, setIsModalOpened] = useState(false);
	const user = useSelector(userSelector);

	const onCancel = () => {
		setIsModalOpened(false);
	};

	const hasRole = user?.roles.some((role) => role.trim() === UserRole.NMR_PIPELINE_PLANNER);
	const isAssignedAnalyst = nmrDrmOrder?.analyst?.id === user?.username;

	return (
		<>
			{!getIsDataLocked(nmrDrmOrder?.status ?? NmrDrmOrderStatus.DECLINED) && (hasRole || isAssignedAnalyst) && (
				<IconButton
					data-testid={DataTestId.getStaticTestId('edit-data-details-id')}
					sx={{ height: 20, width: 20 }}
					onClick={() => setIsModalOpened(true)}
				>
					<EditOutlined sx={{ height: 20, width: 20 }} />
				</IconButton>
			)}

			{isModalOpened && (
				<ModalDialog
					variant="primary"
					width={832}
					open={isModalOpened}
					onClose={onCancel}
					title={<Tr.Admin path="drm-order-details.edit-data-modal.edit-data-details" />}
					contentProps={{ sx: { padding: 3 } }}
				>
					<EditDataDetailsModalForm nmrDrmOrder={nmrDrmOrder} refreshData={refreshData} onClose={onCancel} />
				</ModalDialog>
			)}
		</>
	);
};
