export enum PageRoutes {
	DASHBOARD = 'dashboard',

	// admin routes
	CUSTOMER_MANAGEMENT = 'customer-management',
	SUBSTANCE_MANAGEMENT = 'substance-management',
	EDIT_DRM = 'edit-drm',
	DRM_MANAGEMENT = 'drm-management',
	ERM_DATABASE = 'drm-database',
	ERM_REQUESTS = 'drm-requests',
	SUBSTANCE_DETAILS = 'substance-details',
	LAB_DETAILS = 'lab-details',
	LAB_DETAILS_ADMIN = 'admin-lab-details',
	USERS_AND_ROLES = 'users',
	DRM_PIPELINE = 'drm-pipeline',
	DRM_PIPELINE_BACKLOG = 'backlog',
	DRM_PIPELINE_REPORTS = 'reports',
	DRM_PIPELINE_PRODUCTION = 'production',
	DRM_PIPELINE_DECLINED = 'declined',
	DRM_PIPELINE_RELEASED = 'released',
	DRM_PIPELINE_AUDIT = 'audit',
	DRM_ORDER_DETAILS = 'drm-order-details',
	ORGANIZATIONS = 'organizations',
	USERS = 'users',

	// portal routes
	PORTAL = 'portal',
	HISTORY = 'history',
	SEARCH = 'search',
	FAVOURITES = 'favorites',
	PHYSICAL_REFERENCE_MATERIAL = 'physical-reference-materials',
	ANALYSIS = 'analysis',
	MANAGE_USERS = 'manage-users',
	MANAGE_ORGANIZATION = 'manage-organization',
	ANALYSIS_RESULT = 'analysis-result',
	YOUR_PROFILE = 'your-profile',
	ORGANIZATION_SETTINGS = 'organization-settings',
	DEVICE_MANAGEMENT = 'device-management',
	ADD_DEVICE = 'add-device',
	DEVICE = 'device',
	// technology routes
	NMR = 'nmr',
	IR = 'ir',
}
