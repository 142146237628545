import { UserResponseModel } from '@models/user';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { TypeUtils } from '@utils/Type';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getDrmRequestSchema: (t: TFunction, merckAdmin: boolean, userList?: UserResponseModel[]) => yup.AnySchema = (
	t,
	merckAdmin,
	userList,
) =>
	yup.object({
		chemicalName: yup.string().trim().required(t('erm-request.chemical-name-is-required')),
		casNumber: yup.string().max(250).trim().required(t('erm-request.cas-number-is-required')),
		emailAddress: yup
			.string()
			.nullable()
			.email(t('erm-request.email-is-invalid'))
			.max(250)
			.required(t('erm-request.email-is-required'))
			.test({
				name: 'oneOf',
				exclusive: false,
				params: {},
				message: t('erm-request.email-not-in-list'),
				test: (value) => {
					return merckAdmin ? (userList?.some((user) => user.email === value) ?? false) : true;
				},
			}),
		organization: merckAdmin ? yup.string().required(t('erm-request.organization-is-required')) : yup.string(),
		physicalProductNumber: yup.string(),
		comment: yup.string().trim(),
		preferredSolvent: yup.string(),
		preferredApplication: yup.string(),
		referenceMethod: yup.string(),
		usualConcentration: yup
			.number()
			.transform((value, originalValue) => (!value || TypeUtils.isPositiveFloat(originalValue) ? value : NaN))
			.typeError(t('erm-request.ususal-concentration-number-message')),
		skipPubChemValidation: yup.boolean(),
		technology: yup
			.string()
			.trim()
			.oneOf(Object.values(SupportedTechnology), t('erm-request.technology-is-invalid'))
			.required(t('erm-request.technology-is-required')),
	});
