import { TOptions } from 'i18next';
import { FC } from 'react';
import { Translation } from 'react-i18next';

interface ITrProps {
	path: string;
	options?: TOptions;
}

export enum TranslationNamespaces {
	Common = 'Common',
	Portal = 'Portal',
	Admin = 'Admin',
	Filter = 'Filter',
	Notification = 'Notification',
	Login = 'Login',
	IrPortal = 'IrPortal',
}

export const Tr: Record<TranslationNamespaces, FC<ITrProps>> = {
	[TranslationNamespaces.Common]: ({ path, options }) => (
		<Translation ns="common">{(t, _, ready) => (ready ? t(path, options) : '')}</Translation>
	),
	[TranslationNamespaces.Portal]: ({ path, options }) => (
		<Translation ns="portal">{(t, _, ready) => (ready ? t(path, options) : '')}</Translation>
	),
	[TranslationNamespaces.Admin]: ({ path, options }) => (
		<Translation ns="admin">{(t, _, ready) => (ready ? t(path, options) : '')}</Translation>
	),
	[TranslationNamespaces.Filter]: ({ path, options }) => (
		<Translation ns="filter">{(t, _, ready) => (ready ? t(path, options) : '')}</Translation>
	),
	[TranslationNamespaces.Notification]: ({ path, options }) => (
		<Translation ns="notification">{(t, _, ready) => (ready ? t(path, options) : '')}</Translation>
	),
	[TranslationNamespaces.Login]: ({ path, options }) => (
		<Translation ns="login">{(t, _, ready) => (ready ? t(path, options) : '')}</Translation>
	),
	[TranslationNamespaces.IrPortal]: ({ path, options }) => (
		<Translation ns="irportal">{(t, _, ready) => (ready ? t(path, options) : '')}</Translation>
	),
};
