import { FC, useEffect, useState } from 'react';
import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FlaskLogo } from '@assets/icons/flask.svg';
import { useNavigate } from 'react-router-dom';
import { PortalPageRoutes } from '@models/router';
import { NotificationWarningIcon } from '@components/icons';
import { ModalDialog } from '@components/common';
import { LogoFooter } from '../LogoFooter';

const ProgressAnimation = () => {
	return (
		<Stack
			justifyContent="center"
			alignItems="center"
			sx={{ height: 100, width: 100, backgroundColor: '#01884C', borderRadius: '50%' }}
		>
			<SvgIcon component={FlaskLogo} viewBox={`0 0 32 32`} sx={{ fontSize: '2.9rem' }} />
		</Stack>
	);
};

export const WarningIndicator: FC = () => (
	<Box
		sx={{
			borderRadius: '50%',
			backgroundColor: '#FFC832',
		}}
	>
		<NotificationWarningIcon sx={{ ' path': { fill: '#000' }, border: '2px solid #FFC832' }} />
	</Box>
);

export const IrAnalysisInProgressModal: FC = () => {
	const { t } = useTranslation('portal');
	const navigate = useNavigate();
	const [messageText, setMessageText] = useState<string>();
	const [showLongWarning, setShowLongWarning] = useState(false);

	useEffect(() => {
		const analysisInProgressTimeout = setTimeout(() => {
			setMessageText(t('new-analysis.analysis-running-long'));
			setShowLongWarning(true);
		}, 15000);

		return () => {
			clearTimeout(analysisInProgressTimeout);
		};
	}, []);

	return (
		<>
			<ModalDialog
				variant="primary"
				fullWidth={false}
				open={true}
				titleProps={{
					sx: {
						justifyContent: 'space-between',
						marginTop: 0,
						marginBottom: 5,
						minHeight: 30,
						backgroundColor: 'grey.50',
						paddingY: 1.2,
						paddingX: 2,
					},
				}}
				title={
					<Typography variant="body2" sx={{ fontWeight: 400, lineHeight: '200%' }}>
						{t('analysis-result.result-pending-title')}
					</Typography>
				}
				onClose={() => navigate(PortalPageRoutes.DASHBOARD)}
				maxWidth="sm"
				iconButtonProps={{ padding: 0 }}
				contentProps={{ sx: { width: 472 } }}
			>
				<Stack width={1} direction="column" alignItems="center" justifyContent="space-between">
					<Stack sx={{ textAlign: 'center', paddingX: 8 }}>
						<Stack justifyContent="center" alignItems="center" marginBottom={5}>
							<ProgressAnimation />
						</Stack>
						<Typography variant="subtitle1">{t('analysis-result.result-pending-title')}</Typography>
						{showLongWarning && (
							<Stack
								direction="row"
								marginTop="1rem"
								justifyContent="center"
								alignItems="center"
								sx={{ backgroundColor: 'secondary.50', marginX: 4, height: '36px' }}
							>
								<WarningIndicator />
								<Typography variant="h6" sx={{ marginLeft: 1 }}>
									{t('new-analysis.analysis-taking-longer')}
								</Typography>
							</Stack>
						)}
						<Typography variant="pg-m" sx={{ marginTop: '1rem', lineHeight: '160%' }}>
							{messageText || t('new-analysis.analysis-running')}
						</Typography>
					</Stack>

					<LogoFooter
						fontSize={18}
						sx={{
							borderTop: '1px solid lightgray',
							height: 77,
							marginTop: 5,
							paddingX: 3,
							width: 1,
							alignItems: 'center',
						}}
					/>
				</Stack>
			</ModalDialog>
		</>
	);
};
