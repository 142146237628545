import { httpService } from '@services';
import { concatMap, tap, map } from 'rxjs/operators';
import { RxUtils, TypeUtils } from '@utils';
import { logger } from '@services';
import { AddUserToLabModal, Lab } from './lab.model';
import { coreConfig } from '../coreConfig';

export class LabService {
	private getURL() {
		return `${coreConfig.url}/Laboratory`;
	}

	get(id: number) {
		return httpService.get<Lab>(this.getURL(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Lab, result)),
		);
	}

	addUserToLab(lab: AddUserToLabModal, id: Number) {
		return httpService
			.post<AddUserToLabModal>(this.getURL(), `${id}/users`, { body: lab, errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.addUserToLab.name}]`, result)));
	}

	removeUserFromLab(id: number, userId: string, comment: string) {
		return httpService
			.delete(this.getURL(), `${id}/users/${userId}`, { body: { comment: comment }, errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.removeUserFromLab.name}]`, result)));
	}

	changeLaboratory(id: number, body: unknown) {
		return httpService
			.patch<Lab>(this.getURL(), `${id}`, {
				body: body,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.changeLaboratory.name}]`, result)));
	}

	setSetting(id: number, settingId: number, body: unknown) {
		return httpService
			.put<Lab>(this.getURL(), `${id}/settings/${settingId}`, {
				body: body,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.setSetting.name}]`, result)));
	}
}
export const labService = new LabService();
