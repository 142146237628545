import { FormikApiType, GenericCard, InfoMessageBox, ModalDialog, RequestErmListItem } from '@components/common';
import { Button, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useEffect, useState } from 'react';
import { QuantitiveNonTargetedPeaksInitialize } from './QuantitiveNonTargetedPeaksInitialize';
import { useService } from '@hooks';
import { nmrAnalysisService } from '@services/nmr';
import { useParams } from 'react-router-dom';
import { useAnalysis } from '@hooks';
import { config } from '@config';
import { HubConnection } from '@microsoft/signalr';
import { QuantitativeNonTargetedIntegrationHubMethods } from '@models';
import { useSignalRHubConnection } from '@hooks';
import { SelectableIntegratedPeakTable } from './SelectableIntegratedPeakTable';
import { getIsQuantitiveNonTargeted } from '@models';
import { QuantitiveNonTargetedTechnicalError } from './QuantitiveNonTargetedTechnicalError';

import { CompoundForm, ICompound } from './CompoundForm';
import { RxUtils } from '@utils/Rx';
import { buttonSpacer, innerTitleSpacer } from '@utils/Theme';
import { onUnselectingPeakIntegration } from './IntegratedTableHelper';

interface QuantitiveNonTargetedProcedureProps {
	allCompoundAssignedError: boolean;
	setAllCompoundAssignedError: (value: boolean) => void;
	formik: FormikApiType;
}

export const QuantitiveNonTargetedProcedure: React.FC<QuantitiveNonTargetedProcedureProps> = ({
	allCompoundAssignedError,
	setAllCompoundAssignedError,
	formik,
}) => {
	const [compounds, setCompounds] = useState([{ id: 0, name: '', molecularWeight: 0 }]);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [selectedCompound, setSelectedCompound] = useState<null | ICompound>(null);

	const { id = '' } = useParams<{ id: string }>();
	const { measurementType, targetType, updateChangedPeaks, checkCompoundsHavePeak } = useAnalysis();
	const isQuantitiveNonTargeted = getIsQuantitiveNonTargeted(measurementType, targetType);

	const { data: peakTableData, trigger: triggerQuantitiveSpectrumGet } = useService(() => {
		return nmrAnalysisService.getQuantitativeSpectrumData(+id);
	}, [isQuantitiveNonTargeted]);

	const { data: spectrumCompounds } = useService(() => {
		return nmrAnalysisService.getSpectrumCompounds(+id);
	}, [peakTableData]);

	useEffect(() => {
		if (spectrumCompounds && spectrumCompounds.length > 0) {
			const sortedCompounds = spectrumCompounds.sort((a, b) => a.id - b.id);
			setCompounds(sortedCompounds);
		}
	}, [spectrumCompounds]);

	useEffect(() => {
		updateChangedPeaks(peakTableData?.peaks.filter((value) => value.isSelected));
	}, [peakTableData]);

	useSignalRHubConnection({
		hubUrl: config.quantitativeNonTargetedIntegrationUrl,
		body: (connection: HubConnection) => {
			connection.send(QuantitativeNonTargetedIntegrationHubMethods.CHECK_INTEGRATION_STATUS, +id);
			connection.on(QuantitativeNonTargetedIntegrationHubMethods.INTEGRATION_COMPLETED, () => {
				triggerQuantitiveSpectrumGet();
			});
		},
		shouldStartConnection: () => peakTableData?.isExecuting(),
		deps: [peakTableData],
	});

	const getCurrentStateNode = () => {
		if (!peakTableData || peakTableData.isExecuting()) {
			return <QuantitiveNonTargetedPeaksInitialize />;
		}
		if (peakTableData.isTechnicalError()) {
			return <QuantitiveNonTargetedTechnicalError />;
		}
		return (
			<SelectableIntegratedPeakTable
				peakData={peakTableData.peaks}
				refresh={() => {
					triggerQuantitiveSpectrumGet();
				}}
				compounds={compounds}
			/>
		);
	};

	const deleteCompound = (compound) => {
		setSelectedCompound(compound);
		setIsDialogOpen(true);
	};

	const handleAddCompound = async () => {
		RxUtils.promisify(
			nmrAnalysisService.addCompound(+id),
			(updatedCompoundsResponse) => {
				const newCompound = {
					...updatedCompoundsResponse,
				};
				setCompounds((prevCompounds) => [...prevCompounds, newCompound]);
			},
			(err) => {
				console.error('Failed to add compound', err);
			},
		);
	};

	const handleDeleteCompound = async (compoundId: number | undefined = 0) => {
		await RxUtils.promisify(
			nmrAnalysisService.deleteCompound(+id, compoundId),
			(response) => {
				if (response) {
					setCompoundsOrder(compoundId);
					handlePeakDeletion(compoundId);
				}
			},
			(err) => {
				if (!err.Message) {
					setCompoundsOrder(compoundId);
					handlePeakDeletion(compoundId);
				} else {
					console.error('Failed to delete compound', err);
				}
			},
		);
	};

	const setCompoundsOrder = (compoundId) => {
		let filteredCompounds = compounds;

		if (compoundId) {
			filteredCompounds = compounds.filter((compound) => compound.id !== compoundId);
		}

		const sortedCompounds = filteredCompounds.sort((a, b) => a.id - b.id);
		setCompounds(sortedCompounds);
	};

	const handlePeakDeletion = (compoundId: number) => {
		const unCheckPeakOnDelete = peakTableData?.peaks.filter((peak) => {
			const peakCompoundId = typeof peak?.compoundId === 'number' ? peak.compoundId : Number(peak?.compoundId);
			const compareCompoundId = typeof compoundId === 'number' ? compoundId : Number(compoundId);
			if (peakCompoundId === compareCompoundId) {
				return true;
			} else {
				return false;
			}
		});
		const peakValue = unCheckPeakOnDelete && unCheckPeakOnDelete[0];
		if (unCheckPeakOnDelete && peakValue) {
			onUnselectingPeakIntegration(+id, peakValue, () => {
				triggerQuantitiveSpectrumGet();
			});
		}
	};

	useEffect(() => {
		const allCompoundsHavePeak = checkCompoundsAssignedToPeaks();
		checkCompoundsHavePeak(allCompoundsHavePeak);
	}, [compounds]);

	useEffect(() => {
		if (allCompoundAssignedError) {
			setIsDialogOpen(true);
		}
	}, [allCompoundAssignedError]);

	const checkCompoundsAssignedToPeaks = () => {
		if (!peakTableData || !peakTableData.peaks) {
			return false;
		}

		const compoundIds = compounds.map((compound) => compound.id);

		const allAssigned = compoundIds.every((compoundId) =>
			peakTableData.peaks.some((peak) => peak.compoundId?.toString() === compoundId?.toString()),
		);
		return allAssigned;
	};

	return (
		<Stack>
			<CompoundForm
				compounds={compounds}
				handleAddCompound={handleAddCompound}
				handleDeleteCompound={deleteCompound}
				setCompounds={setCompounds}
				nmrFormik={formik}
			/>
			<GenericCard sx={{ marginTop: 4 }}>
				<Typography variant="h3" color="primary.main">
					<Tr.Portal path="new-analysis.step-3-integrated-peak-table" />
				</Typography>

				{getCurrentStateNode()}
			</GenericCard>
			<RequestErmListItem title={<Tr.Portal path="new-analysis.request-a-drm" />} />
			<Stack direction="column" rowGap={innerTitleSpacer} sx={{ position: 'absolute', top: 10, right: 10 }}>
				<ModalDialog
					maxWidth="xs"
					onClose={() => {
						setIsDialogOpen(false);
						setSelectedCompound(null);
						setTimeout(() => {
							setAllCompoundAssignedError(false);
						}, 200);
					}}
					title={
						<Typography variant="inherit">
							{allCompoundAssignedError ? (
								<Tr.Portal path="new-analysis.warning" />
							) : (
								<Tr.IrPortal path="new-analysis.remove" />
							)}
						</Typography>
					}
					titleProps={{ sx: { paddingBottom: 0 } }}
					onBackdropClick={() => setIsDialogOpen(false)}
					open={isDialogOpen}
					variant="secondary"
					sx={{
						'& .MuiDialog-container': {
							'& .MuiPaper-root': {
								width: '100%',
								maxWidth: '472px',
							},
						},
					}}
				>
					<Stack sx={{ paddingX: 8, paddingTop: 3, paddingBottom: 7 }} textAlign="center" spacing={innerTitleSpacer}>
						<Stack>
							<Typography variant="h3">
								{allCompoundAssignedError ? (
									<Tr.Portal path="new-analysis.warning" />
								) : (
									<Tr.Portal path="new-analysis.compound-delete-title" />
								)}
							</Typography>
						</Stack>

						{!allCompoundAssignedError && (
							<Stack>
								<Typography variant="h4">
									<Tr.Portal path="new-analysis.compound-delete-warning" />
									{selectedCompound?.name || `the compound`}?
								</Typography>
							</Stack>
						)}

						<Stack>
							<InfoMessageBox
								message={
									allCompoundAssignedError ? (
										<Tr.Portal path="new-analysis.assign-all-compound-peak-warning" />
									) : (
										<Tr.Portal path="new-analysis.compound-delete-message" />
									)
								}
							/>
						</Stack>

						{!allCompoundAssignedError && (
							<Stack direction="row" justifyContent="space-between" spacing={buttonSpacer}>
								<Button
									fullWidth
									variant="outlined"
									onClick={() => {
										setSelectedCompound(null);
										setIsDialogOpen(false);
										setAllCompoundAssignedError(false);
									}}
								>
									<Tr.Portal path="new-analysis.remove-dialog.cancel" />
								</Button>
								<Button
									fullWidth
									variant="contained"
									onClick={() => {
										handleDeleteCompound(selectedCompound?.id);
										setIsDialogOpen(false);
										setAllCompoundAssignedError(false);
									}}
								>
									<Tr.Portal path="new-analysis.remove" />
								</Button>
							</Stack>
						)}
					</Stack>
				</ModalDialog>
			</Stack>
		</Stack>
	);
};
