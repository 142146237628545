import { Tr } from '@utils/Translation';
import { InfoMessageBox } from '@components/common';
import { FC } from 'react';
import { DataTestId } from '@utils/DataTestId';
import { Stack, Typography, Button } from '@mui/material';

type TCancelStep = {
	onCancel: () => void;
	onContinue: () => void;
};
export const CancelStep: FC<TCancelStep> = ({ onCancel, onContinue }) => {
	return (
		<Stack direction="column" alignItems={'center'} data-testid={DataTestId.getStaticTestId('drm-pipeline-cancel-card-id')}>
			<Typography variant="subtitle1" marginBottom={3}>
				<Tr.Admin path="drm-pipeline.cancel.title" />
			</Typography>
			<Typography variant="pg-m" marginBottom={2}>
				<Tr.Admin path="drm-pipeline.cancel.subtitle" />
			</Typography>
			<InfoMessageBox message={<Tr.Admin path="drm-pipeline.cancel.message" />} />
			<Stack spacing={2} direction="row" marginTop={3} width={1}>
				<Button fullWidth variant="outlined" onClick={onContinue}>
					<Tr.Admin path="drm-pipeline.cancel.continue-upload" />
				</Button>
				<Button fullWidth variant="contained" onClick={onCancel}>
					<Tr.Admin path="drm-pipeline.cancel.cancel-upload" />
				</Button>
			</Stack>
		</Stack>
	);
};
