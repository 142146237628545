import { CompletedStatusIcon, ICompletedStatusIcon } from '@components/common/Legend';
import { IrAnalysis } from './ir-analysis.class';
import { Tr, TypeUtils } from '@utils';
import { IrAnalysisStage } from './ir-analysis-stage.enum';
import { IRAnalysisResultStatus } from './ir-analysis-status.enum';
import ScienceOutlined from '@mui/icons-material/ScienceOutlined';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import DoneAll from '@mui/icons-material/DoneAll';
import { SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ReactComponent as Tune } from '@material-symbols/svg-600/outlined/discover_tune.svg';
import { ReactComponent as Check } from '@material-symbols/svg-600/outlined/check.svg';
import { ReactComponent as QuickReference } from '@material-symbols/svg-600/outlined/quick_reference_all.svg';

export const IRAnalysisResultStatusIconMap: Record<IRAnalysisResultStatus, FC<ICompletedStatusIcon>> = {
	[IRAnalysisResultStatus.SATISFIED]: ({ sx }) => <CompletedStatusIcon.Done sx={sx} />,
	[IRAnalysisResultStatus.QUESTIONABLE]: ({ sx }) => <CompletedStatusIcon.QuestionMark sx={sx} />,
	[IRAnalysisResultStatus.UNSATISFIED]: ({ sx }) => <CompletedStatusIcon.Close sx={sx} />,
	[IRAnalysisResultStatus.TECHNICAL_ERROR]: ({ sx }) => <CompletedStatusIcon.Remove sx={sx} />,
	[IRAnalysisResultStatus.ONLY_THEORETICAL]: ({ sx }) => <CompletedStatusIcon.OnlyTheoretical sx={sx} />,
};
export const getCompletedIRAnalysisResultStatusIcon = (analysis: IrAnalysis, sx?: SxProps) =>
	IRAnalysisResultStatusIconMap[analysis.status]?.({ sx });

const IRAnalysisResultStatusInfoMap = {
	[IRAnalysisResultStatus.SATISFIED]: () => <Tr.Portal path="completed-history-legend.green-tick" />,
	[IRAnalysisResultStatus.QUESTIONABLE]: () => <Tr.Portal path="completed-history-legend.yellow-questionmark" />,
	[IRAnalysisResultStatus.UNSATISFIED]: () => <Tr.Portal path="completed-history-legend.red-cross" />,
	[IRAnalysisResultStatus.TECHNICAL_ERROR]: () => <Tr.Portal path="completed-history-legend.grey-dash" />,
	[IRAnalysisResultStatus.ONLY_THEORETICAL]: () => <Tr.Portal path="completed-history-legend.theoretical-match" />,
};
export const getIRAnalysisResultStatusInfo = (analysis: IrAnalysis) => IRAnalysisResultStatusInfoMap[analysis.status]?.();

type TStageIcon =
	| IrAnalysisStage.NEW
	| IrAnalysisStage.DETAILS_FILLED
	| IrAnalysisStage.EXECUTING
	| IrAnalysisStage.EXECUTED
	| IrAnalysisStage.CONFIRMED_BY_LAB_MEMBER
	| IrAnalysisStage.APPROVED_BY_LAB_ADMIN;
const IRAnalysisStageIcon: Record<TStageIcon, ReactNode> = {
	[IrAnalysisStage.NEW]: <Tune width={24} height={24} />,
	[IrAnalysisStage.DETAILS_FILLED]: <QuickReference width={24} height={24} />,
	[IrAnalysisStage.EXECUTING]: <ScienceOutlined />,
	[IrAnalysisStage.EXECUTED]: <Check width={24} height={24} />,
	[IrAnalysisStage.CONFIRMED_BY_LAB_MEMBER]: <DoneAll />,
	[IrAnalysisStage.APPROVED_BY_LAB_ADMIN]: <Check />,
};

export const getIRAnalysisStageIcon = (stage: IrAnalysisStage) => {
	return (
		TypeUtils.returnValueOfKey<typeof IRAnalysisStageIcon, ReactNode>(stage as TStageIcon, IRAnalysisStageIcon) ?? (
			<ErrorOutlineRounded />
		)
	);
};
