import Done from '@mui/icons-material/Done';
import { IconButton, Stack } from '@mui/material';
import { OverflowText } from '../OverflowText';
import { FC, ReactNode } from 'react';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';

type TValidFileUpload = {
	additionalColumn?: ReactNode;
	fileName: string;
	onRemove: (index: number) => void;
	index?: number;
	buttonDisabled?: boolean;
};

const iconSx = {
	backgroundColor: 'success.main',
	color: 'background.paper',
	borderRadius: '16px',
	padding: '2px',
	fontSize: '1.1rem',
};

export const ValidFileUpload: FC<TValidFileUpload> = ({ fileName, additionalColumn, onRemove, index = 0, buttonDisabled = false }) => {
	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			height={40}
			sx={{ borderBottom: '1px solid', borderColor: 'grey.100', padding: '0 1rem' }}
		>
			<Stack direction="row" spacing={3} alignItems="center">
				<Done sx={iconSx} />

				<OverflowText enableTooltip={true} sx={{ color: 'primary.main' }} variant="label-s">
					{fileName}
				</OverflowText>
			</Stack>
			{additionalColumn ? additionalColumn : null}
			<IconButton
				data-testid={`device-list-trash-${index}-id`}
				color={'primary'}
				onClick={() => onRemove(index)}
				disabled={buttonDisabled}
			>
				<Delete width={24} height={24} fill="#01884C" />
			</IconButton>
		</Stack>
	);
};
