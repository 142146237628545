import { IrAccessoryCalibration, IrAccessoryCalibrationStatus } from '@services/index';

export class IrAccessory {
	isActive: boolean;
	name: string;
	accessoryName: string;
	manufacturer: string;
	deviceId: number;
	isGxP?: boolean;
	accessoryId: number;
	calibrationValue: number;
	irAccessoryCalibrations?: IrAccessoryCalibration[];
	accessoryType: string;
	lastCalibrationDate: string;
	archivedDate: string;
	nextCalibrationDate: string;
	healthCheckDate?: Date | undefined;
	calibrationStatus: number;
	accessoryStatus: IrAccessoryCalibrationStatus;
	comment: string;
	healthCheckStatus: number;
}
