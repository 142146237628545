import { FC, ReactNode, useState } from 'react';
import { PlotParams } from 'react-plotly.js';
import { Stack, SxProps } from '@mui/material';
import { IChartType } from '@components/common';
import { IDrmSpectrumDataType } from '@components/ir-portal';
import { IrChart } from '@components/ir-portal/IrChart';
import { getTransMissionData } from '@utils';
import { useIrSettingsSelector } from '@store/slices/ir/ir.slice';
import { useSelector } from 'react-redux';

interface ISpectrumChartType {
	hideZoomIcon?: boolean;
	sx?: SxProps;
	enableZoom?: boolean;
	color?: string;
	layout?: PlotParams['layout'];
	axisTitle?: string;
	uniqueId?: string;
	referenceMaterialId?: string;
	chartData: IDrmSpectrumDataType;
	borderColor?: string;
	subTitle?: ReactNode;
	subtitleVisibility?: boolean;
}

export const IrDrmSpectrumChart: FC<ISpectrumChartType & Omit<IChartType, 'subtitleVisibility' | 'subTitle'>> = ({
	hideZoomIcon = false,
	sx,
	enableZoom = true,
	color,
	layout,
	axisTitle,
	uniqueId,
	referenceMaterialId,
	chartData,
	borderColor,
	subTitle,
	subtitleVisibility = false,
	...props
}) => {
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra);

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
	};
	return (
		<Stack sx={{ width: '100%', display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
			{chartData && (
				<IrChart
					subtitleVisibility={subtitleVisibility}
					subTitle={subTitle}
					className={`c${referenceMaterialId}-spectrum-chart`}
					enableZoom={enableZoom}
					title={chartData.title}
					hideZoomIcon={hideZoomIcon}
					displaySettingsToggle={handleDisplaySettings}
					divId={uniqueId}
					borderColor={borderColor}
					data={[
						{
							y: displaySettingsToggle ? getTransMissionData([...chartData.y]) : chartData.y,
							x: chartData.x,
							type: 'scatter',
							mode: 'lines',
							marker: { color: color || 'gray' },
							direction: 'counterclockwise',
							name: chartData.title,
						},
					]}
					sx={sx}
					useResizeHandler
					style={{ width: '100%', height: '100%' }}
					layout={{
						showlegend: false,
						legend: {
							traceorder: 'reversed',
							yanchor: 'bottom',
							y: 1.1,
							xanchor: 'auto',
							x: 0.4,
							orientation: 'h',
							bgcolor: 'transparent',
							bordercolor: '#D6D6D6',
							borderwidth: 1,
							font: { color: '#000000', size: 14 },
						},
						autosize: true,
						xaxis: {
							range: [chartData.firstx, chartData.lastx],
							showdividers: false,
							showline: true,
							zeroline: false,
							title: axisTitle,
						},
						yaxis: {
							showdividers: false,
							showline: true,
							title: displaySettingsToggle ? 'Transmission [%]' : 'Absorbance [A.U.]',
							zeroline: false,
						},
						...layout,
					}}
					{...props}
				/>
			)}
		</Stack>
	);
};
