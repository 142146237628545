import { FC } from 'react';
import { Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { Tr } from '@utils';
import { useTranslation } from 'react-i18next';
import { AdjustableSwitch } from '@components/common';

interface ISecondApprovalSelectionProps {
	secondApprovalStatus: boolean;
	onSecondApprovalStatusChange: (secondApprovalStatus: boolean) => void;
	sx?: SxProps;
	title?: string;
	dataTestId?: string;
	disabled?: boolean;
}

export const SecondApprovalSelection: FC<ISecondApprovalSelectionProps> = ({
	secondApprovalStatus,
	onSecondApprovalStatusChange,
	sx,
	title = 'organization.second-approval-header',
	dataTestId,
	disabled = false,
}) => {
	const { t } = useTranslation('admin');

	const getStateText = () => {
		if (secondApprovalStatus) {
			return t('organization.two-step-approval');
		}
		return t('organization.two-step-decline');
	};
	return (
		<Stack direction="column" sx={sx}>
			<Stack>
				<Typography
					variant="label-s"
					sx={{
						whiteSpace: 'pre-line',
						textAlign: 'center',
						color: 'text.primary',
					}}
				>
					<Tr.Admin path={`organization.two-step-approval-info`} />
				</Typography>
			</Stack>
			<Stack direction="row" alignItems="center" spacing={1.5} marginTop={2}>
				<Stack>
					<Tooltip title={t(title)} placement="bottom">
						<AdjustableSwitch
							data-testid={dataTestId}
							name="secondApproval"
							checked={secondApprovalStatus}
							onChange={() => onSecondApprovalStatusChange(!secondApprovalStatus)}
							size="medium"
							switchTheme="onOff"
							sx={{ margin: 0, height: '32px' }}
							disabled={disabled}
						/>
					</Tooltip>
				</Stack>
				<Stack>
					<Typography
						variant="pg-m"
						sx={{
							whiteSpace: 'pre-line',
							textAlign: 'center',
							color: 'grey.800',
						}}
					>
						{getStateText()}
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};
