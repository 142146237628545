import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardLayout } from '@components/common';
export const Admin: FC = () => {
	return (
		<DashboardLayout>
			<Outlet />
		</DashboardLayout>
	);
};
