import { QualitativeOrQuantitative } from '@models/analysis';
import { TypeUtils } from '@utils/Type';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getAnalysisValidation: (t: TFunction, measurementType?: Maybe<QualitativeOrQuantitative>) => yup.AnySchema = (
	t,
	measurementType,
) =>
	yup.object({
		title: yup.string().trim().required(t('new-analysis.title-required')).nullable(),
		measurementType: yup.string().required(t('new-analysis.analysis-type-required')).nullable(),
		measurementId: yup.string().required(t('new-analysis.measurement-id-required')).nullable(),
		measurementDeviceBrand:
			measurementType === QualitativeOrQuantitative.QUALITATIVE
				? yup.string().nullable()
				: yup.string().required(t('new-analysis.measurement-device-required')).nullable(),
		solvent: yup.string().trim().required(t('new-analysis.solvent-required')).nullable(),
		deviceCalibrationId: yup
			.string()
			.nullable()
			.notRequired()
			.when('measurementType', ([value]) => {
				if (value === QualitativeOrQuantitative.QUANTITATIVE) {
					return yup.string().required(t('new-analysis.calibration-required')).nullable();
				}
				return yup.string().nullable();
			}),
		measurementDate: yup
			.date()
			.max(new Date(), t('new-analysis.measurement-date-invalid'))
			.required(t('new-analysis.measurement-date-required'))
			.typeError(t('new-analysis.measurement-date-invalid')),
		sampleWeight: yup
			.number()
			.nullable()
			.moreThan(0, t('new-analysis.sample-weight-more-than-zero'))
			.transform((value, originalValue) => (!value || TypeUtils.isPositiveFloat(originalValue) ? value : NaN))
			.typeError(t('new-analysis.sample-weight-invalid')),
		solventWeight: yup
			.number()
			.nullable()
			.moreThan(0, t('new-analysis.solvent-weight-more-than-zero'))
			.transform((value, originalValue) => (!value || TypeUtils.isPositiveFloat(originalValue) ? value : NaN))
			.typeError(t('new-analysis.solvent-weight-invalid')),
	});

export const getCompoundValidation: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		compounds: yup.array().of(
			yup.object().shape({
				name: yup.string().trim().required(t('new-analysis.compound-name-required')).nullable(),
				molecularWeight: yup
					.number()
					.positive()
					.moreThan(0, t('new-analysis.molecular-weight-min'))
					.max(100000, t('new-analysis.molecular-weight-max'))
					.nullable(),
			}),
		),
	});

export const getPeakValidation = (t: TFunction, showSelectDropdown: boolean, showTextField: boolean) => {
	return yup.object({
		compoundId: showSelectDropdown ? yup.number().required(t('new-analysis.number-of-protons-required')) : yup.number(),
		numberOfH: showTextField
			? yup
					.number()
					.min(1, t('new-analysis.you-can-only-enter-positive-integer'))
					.max(100000, t('new-analysis.max-proton-number-is'))
					.required(t('new-analysis.number-of-protons-required'))
			: yup
					.number()
					.min(1, t('new-analysis.you-can-only-enter-positive-integer'))
					.max(100000, t('new-analysis.max-proton-number-is')),
	});
};
