export enum FileValidationStatus {
	INVALID = 'file-invalid-type',
	INVALID_DATA_TYPE = 'file-data-type-invalid',
	MAX_SIZE = 'file-too-large',
	NAME_LARGE = 'name-too-large',
}

export enum FileStatus {
	'VALID' = 'valid',
	'INVALID' = 'invalid',
}
