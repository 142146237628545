import { useRef, useState, useEffect, FC, ReactNode } from 'react';
import { Tooltip, Typography, TypographyProps, Box, SxProps } from '@mui/material';

export interface IOverflowTextProps extends TypographyProps {
	children: NonNullable<ReactNode>;
	'data-testid'?: string;
	enableTooltip?: boolean;
	containerProps?: SxProps;
	onOverflow?: () => void;
}

export const OverflowText: FC<IOverflowTextProps> = ({ children, enableTooltip = false, containerProps, onOverflow, ...props }) => {
	const [isOverflowed, setIsOverflow] = useState(false);
	const textElementRef = useRef<HTMLElement>(null);
	const spanElementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (textElementRef?.current)
			setIsOverflow((spanElementRef.current?.getBoundingClientRect().width ?? 0) > (textElementRef.current?.clientWidth ?? 0));
	}, [textElementRef?.current, spanElementRef?.current, spanElementRef.current?.getBoundingClientRect().width]);

	useEffect(() => {
		if (isOverflowed) {
			onOverflow?.();
		}
	}, [isOverflowed]);
	const anchorEl =
		(spanElementRef.current?.getBoundingClientRect().width ?? 0) > (textElementRef.current?.clientWidth ?? 0)
			? textElementRef.current
			: spanElementRef.current;
	return (
		<Tooltip
			title={children}
			disableHoverListener={!isOverflowed || !enableTooltip}
			enterDelay={500}
			arrow
			componentsProps={{
				popper: {
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, -10],
							},
						},
					],
					anchorEl: anchorEl,
				},
			}}
		>
			<Box sx={{ width: '100%', overflow: 'hidden', ...containerProps }}>
				<Typography
					{...props}
					ref={textElementRef}
					sx={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						maxWidth: '100%',
						display: 'block',
						...props.sx,
					}}
				>
					<span ref={spanElementRef} style={{ overflow: 'hidden' }}>
						{children}
					</span>
				</Typography>
			</Box>
		</Tooltip>
	);
};
