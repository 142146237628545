import { Button, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useWindow } from '@hooks';
import { DrmOrderDataDetails } from './DataDetails';
import { useService } from '@hooks';
import {
	NmrDrmOrder,
	NmrDrmOrderCommentDropdownType,
	NmrDrmOrderFile,
	NmrDrmOrderFileType,
	NmrDrmOrderStatusParentStatusMap,
	nmrDrmOrderService,
} from '@services/nmr-drm-pipeline';
import { useParams } from 'react-router-dom';
import { DrmOrderDetailsOverview } from './Overview/DrmOrderDetailsOverview';
import { DrmOrderSubstanceDetail } from './DrmOrderSubstanceDetail';
import { DrmOrderDetailsHeader } from './DrmOrderDetailsHeader';
import { NmrDrmOrderFileUploader } from '../NmrDrmOrderFileUploader';
import { Tr } from '@utils/Translation';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb, setPageBackgroundSx, userSelector } from '@store/slices/common/common.slice';
import AddIcon from '@mui/icons-material/Add';
import {
	FileTypeRequestMapping,
	StatusBackgroundSxMap,
	StatusBreadcrumbMap,
	TCoreFile,
	getIsFileEditable,
	getFileUploadPatchPayload,
	sendFileUploadPatchPayload,
} from './drm-order-details.helper';
import { NmrDrmOrderFileRules } from '@models/drm-order';
import { notificationService } from '@services/index';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { setTransitionErrors } from '@store/slices/nmr/pipeline.slice';
import { DrmOrderTransitionDetails } from './DrmOrderTransitionDetails';
import { NmrDrmOrderUploadError } from '@models/drm-order/file-errors';
import { TypeUtils } from '@utils/Type';
import { DrmOrderComments } from './DrmOrderComments';
import { NmrAdminPermission } from '@models/user';
import { useHasPermissions } from '@hooks';
import { SingleSelectMenu } from '@components/common/SingleSelectMenu';
import { NmrRequestComments } from '../NmrRequestComments';
import { DataTestId } from '@utils/DataTestId';

const SelectionTitleMapping: Record<NmrDrmOrderCommentDropdownType, ReactNode> = {
	[NmrDrmOrderCommentDropdownType.ACTIVITIES]: <Tr.Admin path={'activities'} />,
	[NmrDrmOrderCommentDropdownType.ORDER]: <Tr.Admin path={`drm-order-details.order-comments.title`} />,
	[NmrDrmOrderCommentDropdownType.REQUEST]: <Tr.Admin path={`erm-request-detail.comment-title`} />,
};

const SelectionCommentMapping: Record<
	NmrDrmOrderCommentDropdownType,
	(
		drmOrderDetail: NmrDrmOrder,
		setIsAddCommentButtonClicked: (value: boolean) => void,
		isAddCommentButtonClicked: boolean,
		dropDownSelection: NmrDrmOrderCommentDropdownType,
	) => ReactNode
> = {
	[NmrDrmOrderCommentDropdownType.ACTIVITIES]: (drmOrderDetail) => <DrmOrderTransitionDetails nmrDrmOrder={drmOrderDetail} />,
	[NmrDrmOrderCommentDropdownType.ORDER]: (drmOrderDetail, setIsAddCommentButtonClicked, isAddCommentButtonClicked) => (
		<DrmOrderComments
			nmrDrmOrderId={drmOrderDetail.id ?? 0}
			setIsAddCommentButtonClicked={setIsAddCommentButtonClicked}
			isAddCommentButtonClicked={isAddCommentButtonClicked}
		/>
	),
	[NmrDrmOrderCommentDropdownType.REQUEST]: (
		drmOrderDetail,
		setIsAddCommentButtonClicked,
		isAddCommentButtonClicked,
		dropDownSelection,
	) => (
		<NmrRequestComments
			setIsAddCommentButtonClicked={setIsAddCommentButtonClicked}
			isAddCommentButtonClicked={isAddCommentButtonClicked}
			orderId={drmOrderDetail.id ?? 0}
			dropDownSelection={dropDownSelection}
		/>
	),
};

const SingleSelectionMapping: Record<string, NmrDrmOrderCommentDropdownType> = {
	['Activities']: NmrDrmOrderCommentDropdownType.ACTIVITIES,
	['Order Comments']: NmrDrmOrderCommentDropdownType.ORDER,
	['Request Comments']: NmrDrmOrderCommentDropdownType.REQUEST,
};

const drmOrderCommentDropdownType = ['Activities', 'Order Comments', 'Request Comments'];

export const onDrmOrderFileUpload = async (
	file: File,
	isAdditional: boolean,
	switchLoading: () => void,
	t: TFunction,
	coreFiles?: NmrDrmOrderFile[],
	drmOrderDetails?: NmrDrmOrder,
	refreshDrmOrderDetails?: () => void,
) => {
	const fileExtension = file.name.split('.').pop();
	const fileType = isAdditional ? NmrDrmOrderFileType.OTHER : FileTypeRequestMapping[fileExtension as TCoreFile];
	try {
		if (
			!isAdditional &&
			coreFiles?.some(
				(uploadedFile) => FileTypeRequestMapping[uploadedFile.name?.split('.')[1]?.toLowerCase() as TCoreFile] === fileType,
			)
		) {
			notificationService.sendError(
				t('drm-order-details.file-uploader.already-added-file-type', {
					fileType: t(`drm-pipeline.nmr.production.file-types.${fileType}`),
				}),
			);
			return;
		}
		if (!isAdditional) {
			const patchPayload = await getFileUploadPatchPayload(file, drmOrderDetails?.id);
			await sendFileUploadPatchPayload(patchPayload, drmOrderDetails?.id);
		}

		if (file.size > NmrDrmOrderFileRules.MIN_LOADING) {
			switchLoading();
		}
		if (drmOrderDetails?.id) {
			nmrDrmOrderService
				.postFile(drmOrderDetails?.id, {
					File: file,
					FileType: fileType.toString(),
				})
				.subscribe({
					next: () => {
						if (file.size > NmrDrmOrderFileRules.MIN_LOADING) {
							switchLoading();
						}
						refreshDrmOrderDetails?.();
					},
					error: () => {
						notificationService.sendError(t('drm-order-details.file-uploader.technical-error'));
						switchLoading();
					},
				});
		}
	} catch (error: unknown) {
		const convertedError: NmrDrmOrderUploadError = TypeUtils.transform(NmrDrmOrderUploadError, error);
		if (convertedError) {
			notificationService.sendError(t(convertedError.translationMessage, convertedError.translateOptions));
		}
	}
};

export const DrmOrderDetails: FC = () => {
	const window = useWindow();
	const componentArise = ((window.innerWidth - 32) * 1164) / 1440;
	const user = useSelector(userSelector);
	const { t } = useTranslation('admin');
	const { id } = useParams();
	const [dropDownSelection, setdropDownSelection] = useState<string>(NmrDrmOrderCommentDropdownType.ACTIVITIES);
	const [dropDownSelectionItem, setdropDownSelectionItem] = useState<string>(drmOrderCommentDropdownType[0]);
	const [isAddCommentButtonClicked, setIsAddCommentButtonClicked] = useState<boolean>(false);
	const { data: drmOrderDetails, trigger: refreshDrmOrderDetails } = useService(() => {
		return nmrDrmOrderService.get(id ? +id : 0);
	}, []);
	const hasEdDrmOrderPermission = useHasPermissions(NmrAdminPermission.EDIT_DRM_ORDER);

	const { data: files, trigger: refreshFiles } = useService(() => {
		return nmrDrmOrderService.getFiles(id ? +id : 0);
	}, [drmOrderDetails]);

	const dispatch = useDispatch();

	useEffect(() => {
		if (drmOrderDetails) {
			dispatch(setPageBackgroundSx(StatusBackgroundSxMap[NmrDrmOrderStatusParentStatusMap[drmOrderDetails.status]] ?? {}));
			dispatch(setTransitionErrors([]));
			dispatch(
				setBreadcrumb({
					':path': StatusBreadcrumbMap[NmrDrmOrderStatusParentStatusMap[drmOrderDetails?.status]].path,
					'status-label': StatusBreadcrumbMap[NmrDrmOrderStatusParentStatusMap[drmOrderDetails?.status]].label,
					'drm-order-name': drmOrderDetails?.name,
				}),
			);
		}
	}, [drmOrderDetails]);
	const additionalFiles = files?.filter((file) => file.fileType === 'Other');
	const coreFiles = files?.filter((file) => file.fileType !== 'Other');

	const getFileUploaderExplanation = (type: 'core' | 'additional') => {
		const isEditable = getIsFileEditable(type, drmOrderDetails, user);
		const maxFile = type === 'core' ? NmrDrmOrderFileRules.CORE_MAX_FILE_COUNT : NmrDrmOrderFileRules.ADDITIONAL_MAX_FILE_COUNT;
		const currentFileLength = (type === 'core' ? coreFiles?.length : additionalFiles?.length) ?? 0;
		if (!isEditable && currentFileLength === 0) {
			return 'drm-order-details.file-uploader.files-will-appear-below';
		}

		if (!isEditable || currentFileLength >= maxFile) {
			return 'drm-order-details.you-can-download-the-files';
		}
		return `drm-order-details.file-uploader.upload-files-${type}`;
	};
	const onSelectedItemChange = (item) => {
		setdropDownSelectionItem(item);
		setdropDownSelection(SingleSelectionMapping[`${item}`]);
	};
	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));

	return (
		<Stack data-testid={DataTestId.getStaticTestId('drm-order-details-layout-id')}>
			<DrmOrderDetailsHeader nmrDrmOrder={drmOrderDetails} refreshData={refreshDrmOrderDetails} />
			{componentArise ? (
				<Stack
					direction="row"
					spacing={media ? 0 : 4}
					sx={{
						flexWrap: 'wrap',
						marginBottom: 4,
					}}
				>
					<Stack direction="column" spacing={4} sx={{ flexGrow: 1, width: componentArise * 0.75 }}>
						<DrmOrderSubstanceDetail drmOrder={drmOrderDetails} />
						<DrmOrderDataDetails nmrDrmOrder={drmOrderDetails} refreshData={refreshDrmOrderDetails} />
					</Stack>
					<Stack direction="column" sx={{ width: media ? '100%' : componentArise * 0.25, mt: media ? 4 : 0 }}>
						<DrmOrderDetailsOverview nmrDrmOrder={drmOrderDetails} successCallback={refreshDrmOrderDetails} />
					</Stack>
				</Stack>
			) : null}
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<NmrDrmOrderFileUploader
						onFileUpload={(file, switchLoading) =>
							onDrmOrderFileUpload(file, false, switchLoading, t, coreFiles, drmOrderDetails, refreshDrmOrderDetails)
						}
						title={<Tr.Admin path="drm-order-details.file-uploader.drm-core-files" />}
						explanation={<Tr.Admin path={getFileUploaderExplanation('core')} />}
						files={coreFiles}
						maxFiles={NmrDrmOrderFileRules.CORE_MAX_FILE_COUNT}
						isEditable={getIsFileEditable('core', drmOrderDetails, user)}
						isCore={true}
						nmrDrmOrder={drmOrderDetails}
						successCallback={refreshFiles}
						refreshDrmOrder={refreshDrmOrderDetails}
						tooltipText={t('drm-order-details.file-uploader.core-file-tooltip')}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<NmrDrmOrderFileUploader
						onFileUpload={(file, switchLoading) =>
							onDrmOrderFileUpload(file, true, switchLoading, t, coreFiles, drmOrderDetails, refreshDrmOrderDetails)
						}
						title={<Tr.Admin path="drm-order-details.file-uploader.additional-files" />}
						explanation={<Tr.Admin path={getFileUploaderExplanation('additional')} />}
						files={additionalFiles}
						maxFiles={NmrDrmOrderFileRules.ADDITIONAL_MAX_FILE_COUNT}
						isEditable={getIsFileEditable('additional', drmOrderDetails, user)}
						isCore={false}
						nmrDrmOrder={drmOrderDetails}
						successCallback={refreshFiles}
						refreshDrmOrder={refreshDrmOrderDetails}
					/>
				</Grid>
			</Grid>

			{drmOrderDetails && hasEdDrmOrderPermission && (
				<Stack marginTop={5} spacing={4}>
					<Stack direction="row" justifyContent="space-between">
						<Typography variant="h2" color="primary.main" marginBottom={1.5}>
							{SelectionTitleMapping[`${dropDownSelection}`]}
						</Typography>
						<Stack direction="row">
							<SingleSelectMenu
								selectedItem={dropDownSelectionItem}
								sx={{ minWidth: 198, maxHeight: 40, marginRight: 2 }}
								items={drmOrderCommentDropdownType}
								onSelectedItemChange={onSelectedItemChange}
							/>
							{dropDownSelection === NmrDrmOrderCommentDropdownType.ORDER ? (
								<Button
									data-testid="add-comment-button-id"
									onClick={() => setIsAddCommentButtonClicked(true)}
									startIcon={<AddIcon />}
								>
									<Tr.Admin path={`drm-order-details.order-comments.add-comment`} />
								</Button>
							) : (
								<></>
							)}
						</Stack>
					</Stack>

					{SelectionCommentMapping[`${dropDownSelection}`](
						drmOrderDetails,
						setIsAddCommentButtonClicked,
						isAddCommentButtonClicked,
						dropDownSelection,
					)}
				</Stack>
			)}
		</Stack>
	);
};
