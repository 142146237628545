import { FC, ReactNode } from 'react';
import { alpha, Stack, Theme, Tooltip, TooltipProps, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { CommentTypeEnum, ICommentType } from '@services';
import { CUSTOM_THEME } from '@utils';
import { ExtendedComponent } from 'src/types';

export const Cell: FC<TypographyProps> = ({ sx, ...props }) => {
	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1500));
	return (
		<Typography
			variant="pg-s"
			sx={{
				whiteSpace: 'nowrap',
				paddingX: media ? 0.5 : 2,
				paddingY: media ? 1 : 1.5,
				...sx,
			}}
			{...props}
		/>
	);
};

export const HeaderCell: ExtendedComponent<{ children: ReactNode; createReport?: boolean }> = ({ children, createReport }) => (
	<Typography variant="label-xs" sx={{ maxWidth: '4rem', minWidth: '2rem', wordBreak: createReport ? 'unset' : 'keep-all' }}>
		{children}
	</Typography>
);

export const CellTooltip: ExtendedComponent<
	Omit<TooltipProps, 'title'> & { comments: ICommentType[]; targetCommentType: CommentTypeEnum }
> = ({ children, comments, targetCommentType, ...props }) => {
	const filteredComments = comments?.filter((comment) => comment.type === targetCommentType);

	const commentText = filteredComments?.map((comment) => comment.comment).join(', ');

	return (
		<Tooltip disableHoverListener={!commentText} title={commentText || ''} placement="top" arrow {...props}>
			<Stack
				style={{
					background: commentText ? alpha(CUSTOM_THEME.palette.secondary.main, 0.2) : 'unset',
					height: '2.5rem',
					justifyContent: 'center',
				}}
			>
				{children}
			</Stack>
		</Tooltip>
	);
};
