import { FC } from 'react';
import { Tr } from '@utils';
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IrResultImpurites } from '@services/ir';
import { QualityGradeChip } from '@components/common';

export const IrImpurites: FC<{ impurities: IrResultImpurites[] }> = ({ impurities }) => {
	return (
		<>
			<div style={{ padding: '25px 0px' }}>
				<Stack direction="column">
					<Box
						sx={{
							borderRadius: '10px',
							border: '1px solid',
							borderColor: 'grey.200',
							height: 'fit-content',
						}}
					>
						<TableContainer component={Paper} data-testid="ir-impurities">
							<Table
								sx={{
									'& .MuiTableBody-root 	.MuiTableRow-root': {
										'& .MuiTableCell-root': {
											padding: '6px 8px',
										},
										'&:last-child': {
											'& .MuiTableCell-root': {
												borderBottom: 'none',
											},
										},
									},
								}}
							>
								<TableHead>
									<TableRow
										sx={{
											'& .MuiTableCell-head': {
												padding: '6px 8px',
											},
										}}
									>
										<TableCell width={'20%'}>
											<Tr.IrPortal path="analysis-result.additional-component" />
										</TableCell>
										<TableCell width={'15%'}>
											<Tr.IrPortal path="analysis-result.cas-number" />
										</TableCell>
										<TableCell width={'15%'}>
											<Tr.IrPortal path="analysis-result.quality-grade" />
										</TableCell>
										<TableCell width={'15%'}>
											<Tr.IrPortal path="analysis-result.library" />
										</TableCell>
										<TableCell width={'20%'}>
											<Tr.IrPortal path="analysis-result.match-factor" />
										</TableCell>
										<TableCell width={'15%'}>
											<Tr.IrPortal path="analysis-result.additional-weight" />
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{impurities.map((item, index) => (
										<TableRow key={index}>
											<TableCell width={'20%'}>{item.substanceName}</TableCell>
											<TableCell width={'15%'}>{item.casNumber}</TableCell>
											<TableCell width={'15%'}>
												<QualityGradeChip isReferenceActive={item.isActive} qualityGrade={item.qualityGrade} />
											</TableCell>
											<TableCell width={'15%'}>{item.libraryName}</TableCell>
											<TableCell width={'20%'}>
												<Table>
													<TableBody>
														<TableRow>
															<TableCell width={'20%'} style={{ borderBottom: 'none', padding: '0' }}>
																<div
																	style={{
																		maxWidth: '5rem',
																		minWidth: '5rem',
																	}}
																></div>
															</TableCell>
															<TableCell style={{ borderBottom: 'none', padding: '0' }} width={'80%'}>
																<Typography variant="pg-m">
																	{`${Math.round(item.hqi <= 1 ? item.hqi * 100 : item.hqi)}% `}
																</Typography>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</TableCell>
											<TableCell width={'15%'}>{item.weights}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Stack>
			</div>
		</>
	);
};
