import { Card, Stack, Typography } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { useShowNmrWaveMenu } from './useShowNmrWaveMenu';
import { Tr } from '@utils/Translation';
import { EmptyWaveCard, WaveCard } from './WaveCards';
import { AddWaveOverlay } from './AddWave';
import { NmrDrmOrderWave, nmrDrmWaveService } from '@services/nmr-drm-pipeline';
import { useService } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setWave, triggerWaveRefreshSelector } from '@store/slices/nmr/pipeline.slice';
import { NmrAdminPermission } from '@models/user';
import { useHasPermissions } from '@hooks';
import { Observable } from 'rxjs';

export const NmrWaveMenu: FC = () => {
	const showMenu = useShowNmrWaveMenu();
	const dispatch = useDispatch();
	const cardRef = useRef<Maybe<HTMLDivElement>>();
	const triggerWaveRefreshCount = useSelector(triggerWaveRefreshSelector);
	const { data: drmWavesData } = useService(() => {
		if (showMenu) {
			return nmrDrmWaveService.fetchAllWaves();
		}
		return new Observable<NmrDrmOrderWave[]>();
	}, [triggerWaveRefreshCount, showMenu]);

	useEffect(() => {
		if (drmWavesData) {
			dispatch(setWave(drmWavesData));
		}
	}, [drmWavesData]);

	const getOverflowWidth = () => {
		if (cardRef.current) {
			return cardRef.current.scrollHeight > cardRef.current.clientHeight ? 0.25 : 0;
		}
		return 0;
	};

	const hasWaveManagementPermission = useHasPermissions(NmrAdminPermission.MANAGE_BACKLOG);
	if (!showMenu) {
		return null;
	}
	return (
		<Card
			ref={(ref) => (cardRef.current = ref)}
			sx={{
				width: (256 * window.innerWidth) / 1440 + getOverflowWidth(),
				minWidth: '256px',
				minHeight: '88vh',
				maxHeight: '150vh',
				overflowY: 'auto',
				paddingY: 2.5,
				paddingX: 1.5,
			}}
		>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 1 }}>
				<Typography variant="h4" color="primary.main">
					<Tr.Admin path="drm-pipeline.nmr.backlog.waves" />
				</Typography>
				<AddWaveOverlay />
			</Stack>

			{drmWavesData?.length === 0 && <EmptyWaveCard />}

			{drmWavesData?.map((wave) => (
				<WaveCard key={`${wave.id}-wave`} editable={hasWaveManagementPermission} waveData={wave} sx={{ marginBottom: 1.5 }} />
			))}
		</Card>
	);
};
