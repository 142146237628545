import { FC, useEffect, useState } from 'react';

import { IrResultImpurites, RmSpectrumInfoPurity, SampleSpectrumInfoPurity } from '@services';
import { SpectraColorCodes, Tr, getTransMissionData, mixtureColorCodes } from '@utils';

import { Stack } from '@mui/material';
import { IrChart } from '../IrChart';
import { useTranslation } from 'react-i18next';
import { setIrTransmissionSpectra, useIrSettingsSelector } from '@store/slices/ir/ir.slice';
import { useDispatch, useSelector } from 'react-redux';

interface IOverlaySpectrum {
	subtitleVisibility?: boolean;
	rankedRMData: RmSpectrumInfoPurity;
	userSpectrumInfo: SampleSpectrumInfoPurity;
	resultImpurities?: IrResultImpurites[];
}

export const IrOverlaySpectrumPurityCheck: FC<IOverlaySpectrum> = ({
	subtitleVisibility,
	rankedRMData,
	userSpectrumInfo,
	resultImpurities,
}) => {
	const chartClassName = `c-overlay-purityCheck-chart`;

	const [chartDataList, setChartDataList] = useState<any>([]);
	const { t } = useTranslation('irportal');
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra || false);
	const dispatch = useDispatch();

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
		dispatch(setIrTransmissionSpectra(!displaySettingsToggle));
	};
	useEffect(() => {
		if (userSpectrumInfo && rankedRMData) {
			//set user spectrum
			const chartDataAry: any = [];
			const userChartData = {
				y: displaySettingsToggle ? getTransMissionData([...userSpectrumInfo.sample_y]) : userSpectrumInfo.sample_y,
				x: userSpectrumInfo.sample_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.RICH_GREEN },
				direction: 'counterclockwise',
				name: t('analysis-result.sample-spectrum'),
			};
			chartDataAry.push(userChartData);

			const targetSubstanceChartData = {
				y: displaySettingsToggle ? getTransMissionData([...rankedRMData.spectrum_y]) : rankedRMData.spectrum_y,
				x: rankedRMData.spectrum_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
				direction: 'counterclockwise',
				name: t('analysis-result.target-substance-spectrum'),
			};
			chartDataAry.push(targetSubstanceChartData);

			if (resultImpurities) {
				resultImpurities.map((item, i) => {
					const impurityData = item && item?.impuritySpectrumData;
					const substanceName = item?.substanceName || '';
					if (impurityData) {
						const impurityChartData = {
							y: displaySettingsToggle ? getTransMissionData([...impurityData['y']]) : impurityData['y'],
							x: userSpectrumInfo?.sample_x,
							type: 'scatter',
							mode: 'lines',
							marker: { color: mixtureColorCodes[`${i}`] },
							direction: 'counterclockwise',
							name: substanceName,
						};
						chartDataAry.push(impurityChartData);
					}
				});
				setChartDataList(chartDataAry);
			}
		}
	}, [rankedRMData, userSpectrumInfo, displaySettingsToggle]);

	return (
		<Stack sx={{ border: '1px solid black', borderColor: 'grey.200', borderRadius: '8px' }}>
			{chartDataList && userSpectrumInfo && rankedRMData && (
				<IrChart
					subTitle={
						displaySettingsToggle ? (
							<Tr.IrPortal path="analysis-result.transmission" />
						) : (
							<Tr.IrPortal path="analysis-result.intensity" />
						)
					}
					subtitleVisibility={!!subtitleVisibility}
					className={chartClassName}
					enableZoom={false}
					data={chartDataList}
					useResizeHandler
					style={{ width: '100%', height: '100%' }}
					displaySettingsToggle={handleDisplaySettings}
					layout={{
						margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
						legend: {
							traceorder: 'reversed',
							yanchor: 'bottom',
							y: 1.1,
							xanchor: 'center',
							x: 0.4,
							orientation: 'h',
							bgcolor: 'transparent',
							font: { color: '#000000', size: 12 },
						},
						autosize: true,
						xaxis: {
							range: userSpectrumInfo.sample_x_range,
							showdividers: false,
							showline: true,
							zeroline: false,
							gridcolor: SpectraColorCodes.GRAY,
							griddash: 'dot',
						},
						yaxis: { showline: true, zeroline: false, position: 10 },
						shapes: [],
					}}
				/>
			)}
		</Stack>
	);
};
