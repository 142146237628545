import { Divider, LinearProgress, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';

export const QuantitiveNonTargetedPeaksInitialize = () => {
	const width = (window.innerWidth * 279) / 1440;
	return (
		<Stack
			direction="row"
			sx={{
				justifyContent: 'space-between',
				alignItems: 'center',
				backgroundColor: 'grey.50',
				borderRadius: '8px',
				padding: 2,
				marginTop: 3,
			}}
		>
			<Stack spacing={1}>
				<Typography variant="h6">
					<Tr.Portal path="new-analysis.integrated-peak-table-is-loading" />
				</Typography>
				<Typography variant="pg-s" color="grey.800">
					<Tr.Portal path="new-analysis.integrated-peak-table-is-loading-explanation" />
				</Typography>
			</Stack>
			<Stack direction="row" spacing={4}>
				<Divider orientation="vertical" flexItem />
				<Stack spacing={0.5}>
					<Typography variant="h6">
						<Tr.Portal path="new-analysis.loading" />
					</Typography>
					<Stack sx={{ height: '24px', width: width, justifyContent: 'center' }}>
						<LinearProgress />
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
