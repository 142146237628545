import { FC, PropsWithChildren, ReactNode } from 'react';
import {
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TypographyProps,
	TableProps as TablePropsMUI,
} from '@mui/material';
import { ExtendedColumn, SortOrder } from '@models';
import { IdType, TableProps, useSortBy, useTable } from 'react-table';
import { SortIcon } from '../../common/SortIcon';
import { DataTestId } from '@utils/DataTestId';

const Body1: FC<TypographyProps> = ({ sx, ...props }) => (
	<Typography variant="label-m" sx={{ ...sx, wordBreak: 'break-word' }} {...props} />
);
interface IExtraTableProps {
	sticky: boolean;
}

const getStickyProps = (column: IExtraTableProps) =>
	column.sticky ? { position: 'sticky', right: 0, backgroundColor: 'background.paper' } : {};

export interface IDataTableProps<DataType extends object = object> extends TableProps {
	columns: ExtendedColumn<DataType>[];
	data: DataType[];
	sx?: TablePropsMUI['sx'];
	hiddenColumns?: IdType<DataType>[];
	mainHeader?: { label: ReactNode; colspan: number }[];
	mainFooter?: { label: ReactNode; colspan: number; alignFooter: string }[];
}

export const IrDataTable = <DataType extends object>({
	columns,
	data,
	sx,
	style,
	hiddenColumns = [],
	mainHeader = [],
	mainFooter = [],
}: PropsWithChildren<IDataTableProps<DataType>>) => {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			disableMultiSort: true,
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					const row1Cell = row1.values[`${columnName}`];
					const row2Cell = row2.values[`${columnName}`];
					if (isNaN(row1Cell)) {
						return row1Cell.toUpperCase() > row2Cell.toUpperCase() ? 1 : -1;
					}
					return +row1Cell > +row2Cell ? 1 : -1;
				},
			},
			initialState: { hiddenColumns },
		},
		useSortBy,
	);

	return (
		<Table {...getTableProps()} style={style} sx={sx}>
			<TableHead>
				{mainHeader && (
					<TableRow>
						{mainHeader.map((header, index) => {
							return (
								<TableCell
									key={`header-${index}`}
									colSpan={header.colspan}
									className="main-header"
									sx={{
										fontWeight: 'bold',
										textAlign: 'center',
										padding: '0.8rem 0.8rem',
									}}
								>
									{header.label}
								</TableCell>
							);
						})}
					</TableRow>
				)}
				{headerGroups.map((headerGroup, i) => (
					<TableRow {...headerGroup.getHeaderGroupProps()} key={`header-${headerGroup.id}-${i}`}>
						{headerGroup.headers.map((column, j) => {
							return (
								<TableCell
									{...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
									key={`header-cell-${headerGroup.id}-${i}-${j}`}
									sx={{
										...getStickyProps(column as any),
									}}
								>
									<Stack direction="row" alignItems="center" justifyContent="space-between">
										<Body1>{column.render('Header')}</Body1>
										<SortIcon
											sortable={column.canSort}
											sortOrder={column.isSorted ? (column.isSortedDesc ? SortOrder.DESC : SortOrder.ASC) : undefined}
										/>
									</Stack>
								</TableCell>
							);
						})}
					</TableRow>
				))}
			</TableHead>
			<TableBody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<TableRow
							data-testid={DataTestId.getStaticTestId(`row-${row.index}-id`)}
							{...row.getRowProps()}
							key={`row-${row.id}-${i}`}
							sx={{ height: '4rem' }}
						>
							{row.cells.map((cell, j) => {
								return (
									<TableCell
										{...cell.getCellProps()}
										key={`row-${row.id}-${i}-cell-${j}`}
										sx={{ padding: '0.5rem  0.8rem', ...getStickyProps(cell.column as any), fontWeight: 400 }}
									>
										{cell.render('Cell')}
									</TableCell>
								);
							})}
						</TableRow>
					);
				})}
				{mainFooter && data.length > 1 && (
					<TableRow>
						{mainFooter.map((header, index) => {
							return (
								<TableCell
									key={`header-${index}`}
									colSpan={header.colspan}
									className="main-header"
									sx={{
										fontWeight: 'bold',
										textAlign: header.alignFooter ? header.alignFooter : 'left',
										padding: '0.8rem 0.8rem !important',
									}}
								>
									{header.label}
								</TableCell>
							);
						})}
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};
