export enum DeviceEventAction {
	ADD_DEVICE = 'AddDeviceOrCalibrate',
	RECALIBRATE_DEVICE = 'RecalibrateDevice',
	CALIBRATION_ERROR = 'CalibrationError',
}

export enum DeviceEventLabel {
	ADD_DEVICE = 'AddDeviceOrCalibrate',
	RECALIBRATE_DEVICE = 'RecalibrateDevice',
	CALIBRATION_ERROR = 'CalibrationError',
}
