import { config } from '@config';
import { RxUtils, TypeUtils } from '@utils';
import { concatMap, map, tap } from 'rxjs';
import { httpService, logger } from '@services';
import { Location } from './location.model';

class LocationService {
	private getUrl() {
		return `${config.apiUrl}/core/health`;
	}

	getLocation() {
		return httpService.get(this.getUrl(), 'location').pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getLocation.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Location, result)),
		);
	}
}

export const locationService = new LocationService();
