export enum IrDrmWaveReportStage {
	UPLOADED = 'Uploaded',
	RELEASED = 'Released',
}

export enum IrDrmWaveReportStatus {
	UPLOADED = 'Uploaded',
	RELEASED = 'Released',
	FAILED = 'Failed',
}
