import { IADUser } from './ad-user.interface';
import { UserLab } from './user-lab.class';
import { UserRole } from './user.role.enum';
import { UserStatus } from './user.status.enum';

export class UserStoreModel implements IADUser {
	username: string;
	name: string;
	surname: string;
	email: string;
	email_verified?: boolean | undefined;
	roles: UserRole[];
	exp: number;
	organizationId: string;
	mfaMethods: string;
	permissions?: string[]; // get from BE
	laboratories?: UserLab[];
	currentLabId?: string;
	status: UserStatus;
	isApiCalled?: boolean;
	// isTokenExpired? = () => isBefore(this.exp * 1000, new Date());
}
