import * as yup from 'yup';
import { TFunction } from 'i18next';

export const getUserReferenceValidationSchema: (t: TFunction, editing?: boolean) => yup.AnySchema = (t) =>
	yup.object().shape({
		status: yup.string().nullable(),
		name: yup.string().required(t('user-references.reference-required')),
		irReferenceMaterialLibraryId: yup.string().required(t('user-references.library-required')),
		casNumber: yup.string().nullable(),
		linearFormula: yup.string().nullable(),
		molecularWeight: yup.number().nullable().typeError(t('user-references.value-must-a-number')),
		smiles: yup.string().nullable(),
		synonyms: yup.string().nullable(),

		samplingTechnique: yup.string().nullable(),
		numberOfScans: yup.number().nullable().typeError(t('user-references.value-must-a-number')),
		resolution: yup.number().nullable().typeError(t('user-references.value-must-a-number')),
		lastX: yup.number().nullable().typeError(t('user-references.value-must-a-number')),
		firstX: yup.number().nullable().typeError(t('user-references.value-must-a-number')),

		physicalProductBatchNumber: yup.string().nullable(),
		generalComment: yup.string().nullable(),
	});
