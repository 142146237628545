export const fixedWith3 = (num?: unknown): number | string => {
	const numberData = typeof num === 'string' ? Number(num.split(',')[0]) : Number(num);
	return numberData ? numberData.toFixed(3) : '---';
};

export const mapNumberAndFixWidth3 = (str: Maybe<string>) =>
	str
		? str
				.split(',')
				.map((item) => fixedWith3(item))
				.join(', ')
		: '-';
