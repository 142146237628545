import { FC, useEffect, useState } from 'react';
import { Button, Stack, Typography, Modal, Box, IconButton } from '@mui/material';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GenericCard } from '@components/common';
import { mainTitleSpacer, cardSpacer, Tr } from '@utils';
import { authService, notificationService } from '@services/core';
import { PromiseUtils } from '@utils/PromiseUtils';
import { setUser, userSelector } from '@store/slices/common/common.slice';
import { MFATypeSelection } from './MFATypeSelection';
import useDigitInput from 'react-digit-input';
import { CodeInput } from '@components/common/LoginFlow/MFA/CodeInput';
import { SecurityTickIcon, VerifiedPhoneIcon } from '@components/icons';
import CloseIcon from '@mui/icons-material/Close';
import { MfaRules } from '@models';
import { ReactComponent as Counter1 } from '@material-symbols/svg-600/outlined/counter_1-fill.svg';
import { ReactComponent as Counter2 } from '@material-symbols/svg-600/outlined/counter_2-fill.svg';

const modalStyle = {
	layout: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '30rem',
		bgcolor: 'background.paper',
		borderRadius: 2,
	},
	title: { height: '40px', bgcolor: 'grey.50', borderRadius: '8px 8px 0 0', pl: 2, pt: 1.5 },
};

export const MFASettings: FC = () => {
	const { t } = useTranslation('login');
	const [openInfoDialog, setOpenInfoDialog] = useState(false);
	const [openQRDialog, setOpenQRDialog] = useState(false);
	const [openVerifiedDialog, setOpenVerifiedDialog] = useState(false);
	const [isMFAChecked, setIsMFAChecked] = useState(false);
	const [qrCode, setQrCode] = useState('');
	const user = useSelector(userSelector);
	const dispatch = useDispatch();

	const [codeInputValue, onCodeInputChange] = useState('');
	const digits = useDigitInput({
		acceptedCharacters: MfaRules.ACCEPTED_CHARACTERS,
		length: MfaRules.CODE_LENGTH,
		value: codeInputValue,
		onChange: (value) => onCodeInputChange(value.trim()),
	});

	useEffect(() => {
		if (openQRDialog) {
			PromiseUtils.runPromise(
				() => authService.setUpTOTP(),
				(secretCode) => {
					setQrCode(
						'otpauth://totp/ChemisTwin:' +
							(user?.email || '') +
							'?secret=' +
							secretCode?.sharedSecret +
							'&issuer=' +
							'ChemisTwin',
					);
				},
			);
		}
	}, [openQRDialog]);

	const updateMFAStatus = () => {
		PromiseUtils.runPromise(
			() => authService.updateUserAttributes({ 'custom:MFAMethods': 'SMS,TOTP' }),
			() => {
				user && dispatch(setUser({ ...user, mfaMethods: 'SMS,TOTP' }));
				notificationService.sendSuccess(t(`mfa-settings.preference-set-successfully`));
				setOpenQRDialog(false);
				setOpenVerifiedDialog(true);
				setIsMFAChecked(true);
			},
		);
	};

	const onSubmitClick = () => {
		PromiseUtils.runPromise(
			() => authService.verifyTOTPCode(codeInputValue),
			() => updateMFAStatus(),
			(err) => notificationService.sendError(t(`mfa-settings.${err.code}`)),
		);
	};

	return (
		<>
			<GenericCard
				sx={{
					marginTop: mainTitleSpacer,
					minWidth: '15rem',
					width: '30vw',
					maxWidth: '30rem',
					marginLeft: cardSpacer,
					height: '33rem',
				}}
			>
				<Stack spacing={4}>
					<Stack>
						<Typography sx={{ color: 'primary.main' }} variant="h1">
							<Tr.Login path="mfa-settings.two-factor-authentication" />
						</Typography>
					</Stack>
					<Stack>
						<MFATypeSelection
							desc={<Tr.Login path="mfa-settings.text-desc" />}
							title={<Tr.Login path="mfa-settings.text-title" />}
							checkboxProps={{ checked: true, disabled: true }}
						/>
					</Stack>
					<Stack>
						<MFATypeSelection
							desc={<Tr.Login path="mfa-settings.auth-desc" />}
							title={<Tr.Login path="mfa-settings.auth-title" />}
							checkboxProps={{
								onClick: (event) => {
									event.preventDefault(), setOpenInfoDialog(() => !openInfoDialog);
								},
								checked: user?.mfaMethods?.toLowerCase().includes('totp') || isMFAChecked,
								disabled: user?.mfaMethods?.toLowerCase().includes('totp'),
								'data-testid': 'totp-checkbox-id',
							}}
						/>
					</Stack>
				</Stack>
			</GenericCard>

			<Modal open={openInfoDialog} onClose={() => setOpenInfoDialog(false)}>
				<Box sx={modalStyle.layout}>
					<Box sx={modalStyle.title}>
						<Typography variant="pg-s">
							<Tr.Login path="mfa-settings.info-dialog.title" />
						</Typography>
						<IconButton
							data-testid="modal-close-id"
							color="inherit"
							sx={{ position: 'absolute', right: 6, top: 20, transform: 'translateY(-50%)' }}
							onClick={() => setOpenInfoDialog(false)}
						>
							<CloseIcon sx={{ width: 24, height: 24 }} />
						</IconButton>
					</Box>
					<Stack sx={{ margin: '2.5rem 4rem 4rem 3rem' }} spacing={4}>
						<Stack sx={{ alignItems: 'center' }} spacing={2}>
							<Stack>
								<SecurityTickIcon sx={{ fontSize: '80px' }} />
							</Stack>
							<Stack sx={{ width: '70%' }}>
								<Typography variant="h3" align="center">
									<Tr.Login path="mfa-settings.info-dialog.subtitle" />
								</Typography>
							</Stack>
						</Stack>
						<Stack direction="row" spacing={1}>
							<Stack sx={{ mt: '2px' }}>
								<Counter1 width={20} height={20} fill="#01884C" />
							</Stack>
							<Stack spacing={1}>
								<Typography variant="pg-s">
									<Tr.Login path="mfa-settings.info-dialog.first-bullet-title" />
								</Typography>
								<Typography variant="pg-xs">
									<Tr.Login path="mfa-settings.info-dialog.first-bullet-content" />
								</Typography>
							</Stack>
						</Stack>
						<Stack direction="row" spacing={1}>
							<Stack sx={{ mt: '2px' }}>
								<Counter2 width={20} height={20} fill="#01884C" />
							</Stack>
							<Stack spacing={1}>
								<Typography variant="pg-s">
									<Tr.Login path="mfa-settings.info-dialog.second-bullet-title" />
								</Typography>
								<Typography variant="pg-xs">
									<Tr.Login path="mfa-settings.info-dialog.second-bullet-content" />
								</Typography>
							</Stack>
						</Stack>
						<Stack direction="row" spacing={1}>
							<Button fullWidth variant="outlined" onClick={() => setOpenInfoDialog(false)}>
								<Tr.Login path="mfa-settings.info-dialog.button.cancel" />
							</Button>
							<Button
								fullWidth
								variant="contained"
								onClick={() => {
									setOpenInfoDialog(false), setOpenQRDialog(true);
								}}
								data-testid="get-started-button-id"
							>
								<Tr.Login path="mfa-settings.info-dialog.button.get-started" />
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Modal>

			<Modal
				open={openQRDialog}
				onClose={() => {
					setOpenQRDialog(false);
					setQrCode('');
					onCodeInputChange('');
				}}
			>
				<Box sx={modalStyle.layout}>
					<Box sx={modalStyle.title}>
						<Typography variant="pg-s">
							<Tr.Login path="mfa-settings.qr-dialog.title" />
						</Typography>
						<IconButton
							data-testid="modal-close-id"
							color="inherit"
							sx={{ position: 'absolute', right: 6, top: 20, transform: 'translateY(-50%)' }}
							onClick={() => {
								setOpenQRDialog(false);
								setQrCode('');
								onCodeInputChange('');
							}}
						>
							<CloseIcon sx={{ width: 24, height: 24 }} />
						</IconButton>
					</Box>
					<Stack sx={{ margin: '1rem 2rem' }} alignItems="center" spacing={2}>
						<Stack>
							<Typography textAlign="center" variant="subtitle1">
								<Tr.Login path="mfa-settings.qr-dialog.title" />
							</Typography>
						</Stack>
						<Stack sx={{ width: '80%' }}>
							<Typography textAlign="center" variant="pg-m">
								<Tr.Login path="mfa-settings.qr-dialog.content" />
							</Typography>
						</Stack>
						{qrCode && (
							<Stack alignItems="center">
								<QRCode value={qrCode} />
								<Stack direction="column" marginTop="1.2rem" spacing={3}>
									<Stack width="20em">
										<CodeInput digits={digits} value={codeInputValue} />
									</Stack>
									<Stack direction="row" spacing={1}>
										<Button
											fullWidth
											variant="outlined"
											onClick={() => {
												setOpenQRDialog(false);
												setQrCode('');
												onCodeInputChange('');
											}}
											data-testid="close-modal-w-button-id"
										>
											<Tr.Login path="mfa-settings.qr-dialog.button.cancel" />
										</Button>

										<Button
											variant="contained"
											fullWidth
											type="submit"
											disabled={digits.some((digit) => digit.value === '')}
											onClick={() => onSubmitClick()}
											data-testid="qr-code-validate-test-id"
										>
											<Tr.Login path="mfa-settings.qr-dialog.button.validate" />
										</Button>
									</Stack>
								</Stack>
							</Stack>
						)}
					</Stack>
				</Box>
			</Modal>

			<Modal
				open={openVerifiedDialog}
				onClose={() => {
					setOpenVerifiedDialog(false);
				}}
			>
				<Box sx={modalStyle.layout}>
					<Box sx={modalStyle.title}>
						<Typography variant="pg-s" sx={{ fontWeight: 400 }}>
							<Tr.Login path="mfa-settings.verified-dialog.title" />
						</Typography>
						<IconButton
							data-testid="modal-close-id"
							color="inherit"
							sx={{ position: 'absolute', right: 6, top: 20, transform: 'translateY(-50%)' }}
							onClick={() => {
								setOpenVerifiedDialog(false);
							}}
						>
							<CloseIcon sx={{ width: 24, height: 24 }} />
						</IconButton>
					</Box>

					<Stack sx={{ margin: '2rem' }} alignItems="center" spacing={3}>
						<Stack>
							<VerifiedPhoneIcon sx={{ fontSize: '80px' }} />
						</Stack>
						<Stack>
							<Typography variant="h3">
								<Tr.Login path="mfa-settings.verified-dialog.subtitle" />
							</Typography>
						</Stack>
						<Stack sx={{ width: '80%' }}>
							<Typography textAlign="center" variant="pg-m">
								<Tr.Login path="mfa-settings.verified-dialog.content" />
							</Typography>
						</Stack>
					</Stack>
					<Stack sx={{ mb: 5 }} alignItems="center">
						<Button sx={{ width: '166px' }} variant="contained" onClick={() => setOpenVerifiedDialog(false)}>
							<Tr.Login path="mfa-settings.verified-dialog.button.done" />
						</Button>
					</Stack>
				</Box>
			</Modal>
		</>
	);
};
