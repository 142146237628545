// TO DO: WHEN LINKS ARE READY, CHANGE THEM TO CORRECT LINKS
export enum UsefulLinkUtils {
	// TRAINING = 'https://www.sigmaaldrich.com/chemistwin-training',
	TRAINING = 'https://www.sigmaaldrich.com/',
	// FAQ = 'https://www.sigmaaldrich.com/chemistwin-faq',
	FAQ = 'https://www.sigmaaldrich.com/DE/en/technical-documents/technical-article/analytical-chemistry/nuclear-magnetic-resonance/chemistwin-portal-faq',
	// GUIDELINE = 'https://www.sigmaaldrich.com/chemistwin-training',
	GUIDELINE = 'https://www.sigmaaldrich.com/',
	CUSTOMER_SUPPORT = 'https://www.sigmaaldrich.com/support/customer-support',
	SITE_TERMS = 'https://www.sigmaaldrich.com/life-science/legal/site-use-terms',
	PRIVACY = 'https://www.sigmaaldrich.com/life-science/legal/privacy-statement',
	IMPRINT = 'https://www.sigmaaldrich.com/life-science/legal/imprint',
	SIGMAALDRICH = 'https://www.sigmaaldrich.com',
	SPECIAL_TERMS_AND_CONDITION = 'https://stljirap.sial.com/secure/attachment/1190154/1190154_Terms+and+Conditions+Template_ChemisTwin.pdf',
	WEB_SHOP_LINK = 'https://www.sigmaaldrich.com/products/analytical-chemistry/reference-materials',
}
