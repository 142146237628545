import { RxUtils } from '@utils';
import { coreConfig } from '../coreConfig';
import { tap, concatMap } from 'rxjs/operators';
import { logger, httpService } from '@services';
import { ConfigurationResult } from './configuration.interface';

class ConfigurationService {
	private getURL() {
		return `${coreConfig.url}/Configuration`;
	}

	public getConfiguration(key: string) {
		return httpService.get<ConfigurationResult>(this.getURL(), `${key}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getConfiguration.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}
}

export const configurationService = new ConfigurationService();
