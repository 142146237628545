import { IFilter } from '@models/filter';
import { IrLibrary } from '@services/ir';

enum IrDrmStatus {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	INPROGRESS = 'InProgress',
	DEACTIVATING = 'Deactivating',
	ACTIVATING = 'Activating',
	DRAFT = 'Draft',
}

export const getIrLibraryFilter = (irLibraries?: IrLibrary[]) => {
	if (!irLibraries) return [];
	return irLibraries.map((i) => ({ value: i.libraryName, id: String(i.libraryId) }));
};
export const IrUserReferencesFilterOptions: IFilter[] = [
	{
		key: 'statuses',
		values: [
			{
				id: '1',
				value: IrDrmStatus.INPROGRESS,
			},
			{
				id: '2',
				value: IrDrmStatus.DEACTIVATING,
			},
			{
				id: '4',
				value: IrDrmStatus.INACTIVE,
			},
			{
				id: '5',
				value: IrDrmStatus.ACTIVE,
			},
			{
				id: '6',
				value: IrDrmStatus.ACTIVATING,
			},
		],
		type: 'dropdown',
	},
	{
		key: 'libraries',
		escapesTranslation: true,
		values: [],
		type: 'dropdown',
	},
	{
		key: 'dateRange',
		values: [
			{
				id: '1',
				value: '',
			},
			{
				id: '2',
				value: '',
			},
		],
		type: 'dateRange',
	},
];
