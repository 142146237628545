import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { TypeUtils } from '@utils/Type';
import { FC } from 'react';

interface ActiveStateColors {
	backgroundColor: string;
	color: string;
}

const StateColorMap: Record<string, ActiveStateColors> = {
	Active: { backgroundColor: 'primary.main', color: 'white' },
	Inactive: { backgroundColor: 'grey.100', color: 'grey.500' },
	InProgress: { backgroundColor: 'secondary.main', color: 'black' },
	Deactivating: { backgroundColor: 'error.main', color: 'white' },
	Reactivating: { backgroundColor: 'secondary.main', color: 'black' },
};

// TO DO: DEFINE IT UNDER ir-drm.class.ts
export enum UserReferenceStatus {
	Active = 'Active',
	Inactive = 'Inactive',
	InProgress = 'InProgress',
	Deactivating = 'Deactivating',
	Reactivating = 'Reactivating',
}

export enum ReferenceStatusCode {
	InProgress = '1',
	Deactivating = '2',
	Activating = '6',
}

export const UserReferenceStatusButton: FC<{ status: string }> = ({ status }) => {
	const style = TypeUtils.returnValueOfKey<typeof StateColorMap, ActiveStateColors>(status, StateColorMap);
	return (
		<Stack
			sx={{
				...style,
				padding: '6px',
				minWidth: 0,
				minHeight: 0,
				height: 'fit-content',
				width: 'fit-content',
				borderRadius: '4px',
			}}
			data-testid="user-reference-status"
		>
			<Typography variant="subtitle3" lineHeight="12px" color={style?.color}>
				<Tr.Common path={UserReferenceStatus[`${status}`]} />
			</Typography>
		</Stack>
	);
};
