import { Stack, Tooltip } from '@mui/material';
import { Body1WithBorders } from '../CommonTableComponents';
import { Tr } from '@utils/Translation';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export const DefaultLibraryInfo = () => {
	return (
		<Body1WithBorders sx={{ alignItems: 'center', direction: 'row', justifyContent: 'center' }} borderEnabled>
			<Stack direction="row">
				<Tr.Common path="user-references.default-library" />
				<Tooltip placement="top" title={<Tr.Common path="user-references.default-library-tooltip" />}>
					<InfoOutlinedIcon sx={{ height: '0.75rem', width: '0.75rem', ml: 0.75 }} />
				</Tooltip>
			</Stack>
		</Body1WithBorders>
	);
};
