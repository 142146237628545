import { getParams, useFilterSearchParams } from '@hooks';
import { SortOrder } from '@models/sorting';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from '@hooks';
import { Stack } from '@mui/material';
import { DataTableBackend } from '@components/common';
import { DataTestId } from '@utils/DataTestId';
import { IrReportColumns } from './Columns';
import { irDrmWaveService, irDrmPipelineFilterService } from '@services/ir-drm-pipeline';

export const IrDrmPipelineReport = () => {
	const { searchParams, filtering, setFiltering } = useFilterSearchParams('createTime', SortOrder.DESC);

	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');

	const { t, ready } = useTranslation('admin');
	const { data: filterData } = useService(() => irDrmPipelineFilterService.irPipelineWaveReportsFilterOptions());
	const translate = (path: string) => (ready ? t(path) : '');
	const { data: reportList } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		return irDrmWaveService.getAllWithParams(searchParams);
	}, [searchParams]);

	const columns = IrReportColumns();

	return (
		<Stack data-testid={DataTestId.getStaticTestId('drm-pipeline-report-wrapper-id')}>
			<DataTableBackend
				data={reportList?.data || []}
				columns={columns}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: reportList?.totalPages ?? 1 }}
				searchText={searchTextfieldValue}
				searchPlaceholder={translate('drm-pipeline.reports-page.search-placeholder')}
				onSearchTextChange={setSearchTextfieldValue}
				searchFieldProps={{
					sx: {
						width: (400 / 1440) * window.innerWidth,
						minWidth: '450px',
						marginLeft: 1,
					},
				}}
				headerCellProps={{
					sx: {
						fontSize: '14px',
						fontWeight: 700,
						lineHeight: '105%',
						color: 'text.primary',
					},
				}}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
		</Stack>
	);
};
