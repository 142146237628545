import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { nmrDeviceService } from '@services';
import { useService } from '@hooks';
import { useEffect } from 'react';
import { useDeviceStatusHook } from '@components/nmr-portal';
import { CalibrationInProgressModal } from '@components/common';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common/common.slice';
import { Tr } from '@utils/Translation';

export const NmrDevice = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	if (!id) return null;

	const { data, trigger } = useService(() => nmrDeviceService.getDevice(+id), [id]);
	const deviceData = { calibrationData: data, id: id ? +id : null };

	const { isCalibrationProgress, setIsCalibrationProgress } = useDeviceStatusHook(deviceData, trigger);
	const showDeviceOutlet = !isCalibrationProgress && typeof isCalibrationProgress === 'boolean';
	const loc = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		const pathList = loc.pathname.split('/');
		if (pathList[pathList.length - 2] !== 'recalibrate' && pathList[pathList.length - 1] !== 'add-calibration') {
			navigate('./details', { replace: true });
		}
	}, [loc.pathname]);

	useEffect(() => {
		if (deviceData.calibrationData?.name && showDeviceOutlet) {
			dispatch(setBreadcrumb({ name: deviceData?.calibrationData?.name }));
		}
	}, [deviceData]);
	return (
		<>
			{showDeviceOutlet && (
				<Outlet
					context={{
						deviceData: deviceData,
						refreshDeviceData: trigger,
						setIsCalibrationProgress: setIsCalibrationProgress,
					}}
				/>
			)}

			{isCalibrationProgress && (
				<CalibrationInProgressModal
					title={<Tr.Portal path="device-management.calibrating" />}
					message={<Tr.Portal path="device-management.calibration-progress.message" />}
					subtitle={<Tr.Portal path="device-management.calibration-progress.title" />}
				/>
			)}
		</>
	);
};
