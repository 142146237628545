import { OverflowText } from '@components/common';
import { Stack } from '@mui/material';
import { FC } from 'react';

type TBulkUploadError = {
	message: string;
};
export const BulkUploadError: FC<TBulkUploadError> = ({ message }) => {
	return (
		<Stack position="absolute" sx={{ bottom: -25 }} width="100%">
			<OverflowText variant="label-s" color="error.main" enableTooltip>
				{message}
			</OverflowText>
		</Stack>
	);
};
