import { DependencyList, useEffect } from 'react';
import { history, RxUtils } from '@utils';

export enum Reason {
	NAVIGATION = 'NAVIGATION',
	TIMER = 'TIMER',
}

export const usePageLeaveDetection = (
	cb: (reason: Reason) => Promise<boolean> | boolean,
	interval?: number,
	block: () => boolean = () => true,
	deps?: DependencyList,
): void => {
	useEffect(() => {
		let unblock: () => void = () => null;
		if (block()) {
			unblock = history.block((transition) => {
				const handleResult = (result: boolean) => result && (unblock(), transition.retry());
				const callbackResult = cb(Reason.NAVIGATION);
				if (callbackResult instanceof Promise) {
					callbackResult.then(handleResult).catch(RxUtils.noop);
				} else {
					handleResult(callbackResult);
				}
			});
		}
		const timer = interval ? setInterval(() => (cb(Reason.TIMER), unblock?.()), interval) : null;

		return () => {
			unblock?.();
			timer && clearInterval(timer);
		};
	}, [interval, ...(deps || [])]);
};
