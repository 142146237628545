import { NrmDrmOrderPriority } from '@services/nmr-drm-pipeline';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getPipelineDrmOrderValidationSchema: (t: TFunction, onFocus?: boolean) => yup.AnySchema = (t, onFocus) =>
	yup.object({
		casNumber: onFocus
			? yup.string()
			: yup
					.string()
					.max(250)
					.nullable()
					.required(t('validation.required', { key: t('cas-number') })),
		priority: yup
			.string()
			.max(250)
			.nullable()
			.oneOf(Object.values(NrmDrmOrderPriority), t('validation.select-value', { key: t('priority.name') }))
			.required(t('validation.required', { key: t('priority.name') })),
	});

export const getPipelineBulkDrmOrderValidationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		wave: yup
			.number()
			.nullable()
			.required(t('validation.required', { key: t('cas-number') })),
		priority: yup
			.string()
			.max(250)
			.nullable()
			.oneOf(Object.values(NrmDrmOrderPriority), t('validation.select-value', { key: t('priority.name') }))
			.required(t('validation.required', { key: t('priority.name') })),
	});
