import { useAnalysis } from '@hooks';
import { Link, Stack, Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { MeasurementTypeError, getErrorType } from './analysis-error-helper';
import { Tr } from '@utils/Translation';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { NmrDevice } from '@services/nmr';
import { HereLink } from '../NmrAnalysisTypeSelection';
import { UsefulLinkUtils } from '@utils/Link';
import { PortalPageRoutes } from '@models/router';
import { TypeUtils } from '@utils/Type';
import { useScrollTop } from '@hooks';

const ContentTypography: FC<Omit<TypographyProps, 'variant'>> = ({ children, ...props }) => {
	return (
		<Typography variant="label-s" sx={{ color: 'error.main', ...props.sx }}>
			{children}
		</Typography>
	);
};

type TErrorCallback = (device: Maybe<NmrDevice>) => ReactNode | null;
const ErrorContentMapping: Record<MeasurementTypeError, TErrorCallback> = {
	noError: () => null,
	noDevice: () => (
		<ContentTypography>
			<Tr.Portal path="new-analysis.device-not-found-lab-admin" />
			<HereLink
				href={`../../${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE_MANAGEMENT}`}
			/>
			<Tr.Portal path="new-analysis.device-not-found-lab-admin-part-2" />
		</ContentTypography>
	),
	inactiveDevice: (device) =>
		device ? (
			<ContentTypography>
				<Tr.Portal path="new-analysis.your-device-is-inactive" />
				<HereLink
					href={`../../${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${device.id}`}
				/>
				<Tr.Portal path="new-analysis.your-device-is-inactive-2" />
			</ContentTypography>
		) : null,
	noActiveCalibration: (device) =>
		device ? (
			<ContentTypography>
				<Tr.Portal path="new-analysis.device-needs-calibration" />
				<HereLink
					href={`../../${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${device.id}`}
				/>
				<Tr.Portal path="new-analysis.device-needs-calibration-part-2" />
			</ContentTypography>
		) : null,
	noProbId: () => (
		<ContentTypography>
			<Tr.Portal path="new-analysis.probe-id-not-available" />
			<HereLink target="_blank" href={UsefulLinkUtils.GUIDELINE} />.
		</ContentTypography>
	),
	noRG: () => (
		<ContentTypography>
			<Tr.Portal path="new-analysis.rg-not-available" />
			<HereLink target="_blank" href={UsefulLinkUtils.GUIDELINE} />.
		</ContentTypography>
	),
	waitsCalibration: () => (
		<ContentTypography>
			<Tr.Portal path="new-analysis.waits-calibration" />
		</ContentTypography>
	),
	receiverGainThreshold: (device) =>
		device ? (
			<ContentTypography>
				<Tr.Portal path="new-analysis.receiver-gain" />
				<Link
					sx={{
						display: 'inline-block',
						fontWeight: 'bolder',
						color: 'inherit',
						textDecorationColor: 'inherit',
						textTransform: 'lowercase',
					}}
					href={`../../${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${device?.id}`}
				>
					<Tr.Portal path="device-management.recalibrate" />
				</Link>
				<Tr.Portal path="new-analysis.receiver-gain-rest" />
			</ContentTypography>
		) : null,
};

const ErrorTitle: FC<{ errorType: MeasurementTypeError }> = ({ errorType }) => {
	if (errorType === 'noProbId') {
		return <Tr.Portal path="new-analysis.no-prob-id-title" />;
	} else if (errorType === 'noRG') {
		return <Tr.Portal path="new-analysis.no-rg-title" />;
	}
	return <Tr.Portal path="new-analysis.device-calibration-error" />;
};

export const AnalysisErrorIndicator: FC = () => {
	const { nmrDevice, metaData, measurementType, targetType } = useAnalysis();
	const errorType = getErrorType(nmrDevice, metaData, measurementType);
	const hasError = errorType && errorType !== 'noError';
	useScrollTop([measurementType, targetType], hasError);

	if ((nmrDevice === undefined && errorType === 'noDevice') || !hasError) {
		return null;
	}

	const errorCallback = TypeUtils.returnValueOfKey<typeof ErrorContentMapping, TErrorCallback>(errorType, ErrorContentMapping);
	return (
		<Stack sx={{ width: 1, padding: 2, marginBottom: 3, borderRadius: '12px', backgroundColor: 'error.200' }} spacing={1.25}>
			<Stack direction="row" alignItems="center" paddingLeft="2px" spacing={1.25} color="error.main">
				<ErrorOutlinedIcon sx={{ height: 20, width: 20 }} />
				<Typography variant="h5" color="error.main">
					<ErrorTitle errorType={errorType} />
				</Typography>
			</Stack>
			<Stack paddingLeft={4}>{errorCallback?.(nmrDevice)}</Stack>
		</Stack>
	);
};
