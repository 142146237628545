import { Stack } from '@mui/material';
import { GenericCard } from '@components/common';
import { cardSpacer } from '@utils';
import { ExtendedComponent } from 'src/types';

export const NmrAnalyticalProcedureLayout: ExtendedComponent = ({ children }) => (
	<GenericCard sx={{ marginTop: cardSpacer }}>
		<Stack direction="column">{children}</Stack>
	</GenericCard>
);
