import { resolvePath } from 'react-router-dom';
import { IRoute, UserRole, UserStatus, UserStoreModel } from '@models';
import { IRoutingPolicy, ROLE_PATH_MAPPING } from './role-path.mapping';

export class RouterUtils {
	static isLocationAndRouteMatched(href?: Maybe<string>): boolean {
		let isLocationMatched = false;
		if (href) {
			const resolved = resolvePath(href);
			isLocationMatched = location.pathname.includes(resolved.pathname);
		}
		return isLocationMatched;
	}

	static checkUserHasAccessToPage(route: Maybe<IRoute>, user: Maybe<UserStoreModel>): boolean {
		if (!user || !user.permissions || user.status === UserStatus.INACTIVE) {
			return false;
		}
		if (route && route.accessPermissions) {
			return route.accessPermissions.some((permission: string) => user.permissions?.includes(permission));
		}
		return true;
	}

	static checkPathValid(match: IRoutingPolicy, user: UserStoreModel) {
		const shouldHaveActiveLab = match.shouldHaveActiveLab;

		if (shouldHaveActiveLab && !user.laboratories?.some((l) => l.isActive)) return false;
		if (match.policy.length === 0) return true;

		const userHasPolicyRole = match.policy.some((policy: UserRole) => user.roles.includes(policy));
		if (userHasPolicyRole) return true;
	}

	static getUsersPathName(user: Maybe<UserStoreModel>): Maybe<string> {
		if (!user || !user.roles) {
			return undefined;
		}
		const isLabUser = user.roles.some((r) => r.trim() === UserRole.LAB_ADMIN || r.trim() === UserRole.LAB_MEMBER);
		if (isLabUser && user.laboratories === undefined) {
			return undefined;
		}
		const possibleMatches = ROLE_PATH_MAPPING[user.roles[0]].filter((match: IRoutingPolicy) => this.checkPathValid(match, user));
		possibleMatches.sort((a, b) => (a.prioritizied && !b.prioritizied ? -1 : 1));
		return possibleMatches && possibleMatches?.[0]?.path;
	}
}
