import { notificationService } from '@services/core';
import { irAnalysisService } from '@services/ir';
import { RxUtils } from '@utils/Rx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface IMasterData {
	USERNAME: string; // measurement executer IDd
	ORIGIN: string; // device name
	['.SOLVENTNAME']: string; // solvent
	TITLE: string;
	DATE: string; // measurement date
	SAMPLINGPROCEDURE: string;
	XUNITS: string;
	YUNITS: string;
	DATATYPE: string;
	RESOLUTION: string;
}

export type IrMetaDataType = Record<keyof IMasterData, string>;

interface IrType {
	meta: IrMetaDataType;
	dependentVariables: any[];
}

export const useIrParser = (fileContent?: string) => {
	const [file, setFile] = useState<IrType>();
	const { t } = useTranslation('portal');

	const { id = '' } = useParams<{ id: string }>();
	useEffect(() => {
		if (fileContent) {
			try {
				RxUtils.promisify(irAnalysisService.getSpectrumData(+id), (specData) => {
					setFile(specData.data['metadata']);
				});
			} catch (e) {
				notificationService.sendError(t('file-invalid'));
			}
		}
	}, [fileContent]);

	return {
		file: file,
		meta: file?.meta,
	};
};
