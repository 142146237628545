import { BehaviorSubject } from 'rxjs';
import { IAlert } from './alert.interface';

class AlertService {
	private _notifications = new BehaviorSubject<Maybe<IAlert>>(null);
	getSubject = () => this._notifications;
	send = (alert: IAlert): unknown =>
		this._notifications.next({
			...alert,
			onClose: () => (alert.onClose?.(), this.clear()),
			onConfirm: () => (alert.onConfirm?.(), this.clear()),
		});
	clear = (): unknown => this._notifications.next(null);
}

export const alertService = new AlertService();
