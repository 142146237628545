import { FC, ReactNode, useRef, useState } from 'react';
import { Stack, SxProps, Typography } from '@mui/material';
import Plot, { PlotParams } from 'react-plotly.js';
import { useContext } from 'react';
import { IrChartHeader } from '@components/ir-portal';
import { Tr } from '@utils';
import { ZoomChild } from '@components/common';
import { IrAnalysisResultContext } from '@routes/Portal/ir/IrAnalysisResult';

interface IChartType {
	analysisId?: number;
	hideZoomIcon?: boolean;
	title?: string;
	sx?: SxProps;
	enableZoom?: boolean;
	subTitle: ReactNode;
	subtitleVisibility: boolean;
	infoMessage?: string;
	isTheoretical?: boolean;
	isZoomIconEnabled?: boolean;
	isDeactivated?: boolean;
	header?: string | ReactNode;
	displaySettingsToggle?: () => any;
	borderColor?: string;
}

export const IrChart: FC<PlotParams & IChartType> = ({
	hideZoomIcon,
	title,
	sx,
	enableZoom = true,
	subTitle,
	subtitleVisibility,
	isTheoretical,
	infoMessage,
	isZoomIconEnabled = true,
	isDeactivated = false,
	header,
	displaySettingsToggle,
	borderColor,
	...props
}) => {
	const [showBigger, setShowBigger] = useState(false);
	const chartRef = useRef(null);
	const { analysisResultData } = useContext(IrAnalysisResultContext);
	const analysisTitle = analysisResultData?.irAnalysis?.title || '';

	setTimeout(() => {
		const modebarContainer = document.querySelectorAll(`.${props.className} .modebar-container`) as unknown as HTMLElement[];
		modebarContainer.length > 0 &&
			modebarContainer.forEach((item) => {
				item.style.display = 'none';
			});
	});

	return enableZoom ? (
		<ZoomChild
			title={title}
			sx={{ height: '100%', margin: '2.5px 0', ...sx }}
			fullScreen
			hideZoomIcon={hideZoomIcon}
			infoMessage={infoMessage}
			isTheoretical={isTheoretical}
			isZoomIconEnabled={isZoomIconEnabled}
			isDeactivated={isDeactivated}
			header={header}
			onZoomChange={(isBig) => {
				const timeout = setTimeout(() => {
					const autoScaleButtons = document.querySelectorAll(`#${props.divId} a[data-title=Autoscale]`);
					autoScaleButtons.forEach((btn) => (btn as HTMLElement).click());
					setShowBigger(isBig);
				}, 0);
				return () => {
					clearTimeout(timeout);
				};
			}}
			iconColor={borderColor}
		>
			<>
				{showBigger && (
					<IrChartHeader
						chartClass={props.className || 'chart'}
						title={subTitle}
						titleVisibility={subtitleVisibility}
						displaySettingsToggle={displaySettingsToggle}
					/>
				)}
				<Plot
					{...props}
					layout={{
						xaxis: { range: [0, 10] },
						shapes: [],
						...props.layout,
					}}
					className={props.className || 'chart'}
					ref={chartRef}
					config={{
						displayModeBar: showBigger ? true : false,
						staticPlot: false,
						displaylogo: false,
						toImageButtonOptions: {
							filename: `${analysisTitle}_graph`,
						},
					}}
				/>
			</>
		</ZoomChild>
	) : (
		<>
			<IrChartHeader
				chartClass={props.className || 'chart'}
				titleVisibility={false}
				title={undefined}
				displaySettingsToggle={displaySettingsToggle}
			/>

			<Stack direction="row">
				<Stack>
					{subtitleVisibility && (
						<Typography
							variant="h6"
							sx={{
								marginTop: 'auto',
								marginBottom: 'auto',
								marginLeft: 3,
								color: 'text.primary',
								writingMode: 'vertical-rl',
								textOrientation: 'mixed',
								transform: 'rotate(180deg)',
							}}
							fontWeight={700}
						>
							{subTitle}
						</Typography>
					)}
				</Stack>

				<Stack flex={10} direction="column">
					<Stack direction="row">
						<Plot
							{...props}
							layout={{
								shapes: [],
								...props.layout,
							}}
							ref={chartRef}
							config={{
								displayModeBar: true,
								displaylogo: false,
								toImageButtonOptions: {
									filename: `${analysisTitle}_graph`,
								},
							}}
						/>
					</Stack>
					{subtitleVisibility && subTitle && (
						<Typography
							variant="h6"
							sx={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: 3, color: 'text.primary' }}
							fontWeight={700}
						>
							<Tr.IrPortal path="analysis-result.wave-number" />
						</Typography>
					)}
				</Stack>
			</Stack>
		</>
	);
};
