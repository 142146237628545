import { FC } from 'react';
import { Tr } from '@utils/Translation';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack, Typography, Avatar, Button } from '@mui/material';
import { DataTestId } from '@utils/DataTestId';

export const FailedStep: FC<{ message: string | null; onClose: () => void }> = ({ message, onClose }) => {
	return (
		<Stack direction="column" alignItems={'center'} data-testid={DataTestId.getStaticTestId('drm-pipeline-failed-card-id')}>
			<Avatar
				sx={{
					width: '5rem',
					height: '5rem',
					marginBottom: 3,
					backgroundColor: '#E61E501A',
				}}
			>
				<CloseRoundedIcon sx={{ fontSize: '2.5rem', color: 'error.main' }} />
			</Avatar>
			<Typography variant="subtitle1" marginBottom={3}>
				<Tr.Admin path="drm-pipeline.failed.title" />
			</Typography>
			<Typography variant="pg-m" textAlign="center" marginBottom={5}>
				{message}
			</Typography>
			<Button variant="contained" onClick={onClose} sx={{ width: '10rem' }}>
				<Tr.Common path="ok" />
			</Button>
		</Stack>
	);
};
