import { FC } from 'react';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { Tr, TypeUtils } from '@utils';
import { CompletedStatusIcon, ICompletedStatusIcon, IconType } from '@components/common';

interface ILegendGridProps {
	icon: IconType;
}

const LegendGrid: FC<ILegendGridProps & StackProps> = ({ children, icon, ...props }) => (
	<Stack {...props} direction="row" alignItems="center">
		{TypeUtils.returnValueOfKey<typeof CompletedStatusIcon, FC<ICompletedStatusIcon>>(
			icon,
			CompletedStatusIcon,
		)?.({ sx: { width: '1rem', height: '1rem', padding: '0.1rem' } })}
		<Typography variant="body3" sx={{ marginLeft: 1, lineHeight: '180%', color: 'grey.800' }}>
			{children}
		</Typography>
	</Stack>
);

export const IrCompletedHistoryLegend = () => {
	return (
		<Box sx={{ display: 'flex' }} data-testid="grid-test">
			<LegendGrid icon={IconType.DONE}>
				<Tr.Portal path="completed-history-legend.green-tick" />
			</LegendGrid>
			<LegendGrid marginLeft={3} icon={IconType.QUESTION_MARK}>
				<Tr.Portal path="completed-history-legend.yellow-questionmark" />
			</LegendGrid>
			<LegendGrid marginLeft={3} icon={IconType.CLOSE}>
				<Tr.Portal path="completed-history-legend.red-cross" />
			</LegendGrid>
			<LegendGrid marginLeft={3} icon={IconType.REMOVE}>
				<Tr.Portal path="completed-history-legend.grey-dash" />
			</LegendGrid>
		</Box>
	);
};
