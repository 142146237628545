import { useLocation } from 'react-router-dom';
import { useHasPermissions } from './useHasPermissions';
import { AdminPermission } from '@models/user';

export const useMainRoute = () => {
	const loc = useLocation();
	if (useHasPermissions(AdminPermission.VIEW_DASHBOARD)) {
		return '/admin';
	} else if (loc.pathname.includes('/portal')) {
		return '/portal';
	} else {
		return '/orgAdmin';
	}
};
