import { ExtendedComponent } from 'src/types';
import { IModalDialogProps, ModalDialog } from '../ModalDialog';
import { Scroll } from '@utils/Theme';
import { SlidingTransition } from '@components/common/Transitions/SlidingTransition';
import { ReactNode } from 'react';

type TFullScreenModalDialog = {
	actionButtons?: ReactNode;
};

export const FullScreenModalDialog: ExtendedComponent<TFullScreenModalDialog & IModalDialogProps> = ({
	children,
	title,
	actionButtons,
	...props
}) => {
	return (
		<ModalDialog
			data-testid="drm-modal-id"
			TransitionComponent={SlidingTransition}
			TransitionProps={{
				timeout: 300,
			}}
			sx={{ marginTop: 5 }}
			PaperProps={{
				sx: {
					maxWidth: 'calc(100% - 36px)',
					width: 'calc(100% - 72px)',
					margin: 0,
					height: '100%',
					maxHeight: '100%',
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				},
			}}
			componentsProps={{ backdrop: { style: { opacity: 0.5 } } }}
			titleProps={{ sx: { marginTop: 0, minHeight: 50, width: '100%', borderTopRightRadius: 0, borderTopLeftRadius: 0 } }}
			title={title}
			variant="primary"
			contentProps={{
				sx: { ...Scroll.Y, marginRight: 1 },
			}}
			actionButtons={actionButtons}
			{...props}
		>
			{children}
		</ModalDialog>
	);
};
