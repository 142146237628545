import { Type } from 'class-transformer';
import { NmrDrmRequestStatus } from './nmr-request-drm.enum';
import { NmrRequestAccordionItem } from './nmr-admin-note-model.interface';

export const NmrDrmRequestStatusTypes = Object.keys(NmrDrmRequestStatus).filter((s) => isNaN(Number(s)));
export type NmrDrmRequestStatusType = (typeof NmrDrmRequestStatusTypes)[number];

export class NmrRequestDrm {
	id: number;
	casNumber: string;
	substance: string;
	comment: string;
	email: string;
	merckPhysicalProductNumber: string;
	organization: string;
	preferredApplication: string;
	preferredSolvent: string;
	referenceMethod: string;
	status: NmrDrmRequestStatusType;
	usualConcentration: string;
	notes: Array<NmrRequestAccordionItem>;
	chemicalName?: string;
	actionRequired: Boolean;
	nmrDrmOrderId?: number;

	@Type(() => Date)
	date?: Date;

	isNewRequest() {
		return this.status === 'NewRequest';
	}
	isNotStarted() {
		return this.status === 'NotStarted';
	}
	isInprogress() {
		return this.status === 'InProgress';
	}
	isOnHold() {
		return this.status === 'OnHold';
	}
	isNotAvailable() {
		return this.status === 'NotAvailable';
	}
	isReleased() {
		return this.status === 'Released';
	}
}
