import { Stack } from '@mui/material';
import { FC, useState } from 'react';
import { NoPaidSubscription } from '../PaidSubscriptionForm/NoPaidSubscription';
import { GenericCard } from '@components/common';
import { AddSubscriptionModal } from './AddSubscriptionModal';

interface IAddSubscriptionProps {
	organizationId: number;
	onSubmit?: () => void;
}

export const AddSubscription: FC<IAddSubscriptionProps> = ({ organizationId, onSubmit }) => {
	const [isAddSubscriptionModalVisible, setIsAddSubscriptionModalVisible] = useState<boolean>(false);
	return (
		<Stack sx={{ height: 1 }}>
			<GenericCard
				sx={{ height: 1, minHeight: '533px', justifyContent: 'center' }}
				containerSx={{ height: 1, justifyContent: 'center' }}
			>
				<NoPaidSubscription onSave={() => setIsAddSubscriptionModalVisible(true)} />
				{isAddSubscriptionModalVisible && (
					<AddSubscriptionModal
						organizationId={organizationId}
						visible={isAddSubscriptionModalVisible}
						onClose={() => {
							setIsAddSubscriptionModalVisible(false);
						}}
						onSubmit={() => {
							onSubmit?.();
						}}
					/>
				)}
			</GenericCard>
		</Stack>
	);
};
