import { lastValueFrom } from 'rxjs';
import { Tr } from '@utils/Translation';
import { MerckError } from '@utils/Error';
import { useTranslation } from 'react-i18next';
import { ModalDialog } from '@components/common';
import { IrFileUploadCard } from './IrFileUploadCard';
import { notificationService } from '@services/core';
import { FC, useState, ReactNode, useEffect, useRef } from 'react';
import { DoneStep, CancelStep, FailedStep, UploadingStep } from './Steps';
import { IrDrmWaveStatus, irDrmWaveService } from '@services/ir-drm-pipeline';
import { createWave, getWaveName, uploadFile, validateFiles } from './IrFileUploadHelper';
import { DataTestId } from '@utils/DataTestId';
import { TypeUtils } from '@utils/Type';

type TProgressStep = {
	id: string;
	title: JSX.Element;
	component: ReactNode;
};

export const IrBulkFileUpload: FC<{ onUpdate: () => void }> = ({ onUpdate }) => {
	const abortRef = useRef(false);
	const { t } = useTranslation('admin');
	const [progress, setProgress] = useState<number>(0);
	const [isModalVisible, setModalVisibility] = useState(false);

	const defaultError = t('drm-pipeline.error.uploading-failed');
	const [error, setError] = useState<string>(defaultError);

	useEffect(() => {
		if (isModalVisible) {
			abortRef.current = false;
		} else {
			setProgress(0);
			setStepID(StepContentMapping.uploading.id);
		}
	}, [isModalVisible]);

	const handleSelectedFolder = async (fileList: FileList | null) => {
		try {
			setModalVisibility(true);
			const { metaDataFile, allFiles } = await validateFiles(fileList);
			const waveName = getWaveName(metaDataFile);
			const waveId = await createWave(waveName);

			for (const file of allFiles) {
				if (abortRef.current) break;
				await uploadFile(waveId, file);
				setProgress((prev) => prev + 100 / allFiles.length);
			}

			if (!abortRef.current) {
				await lastValueFrom(irDrmWaveService.updateWaveStatus(waveId, IrDrmWaveStatus.UPLOAD_COMPLETED));
				setStepID(StepContentMapping.done.id);
			}
			setProgress(0);
		} catch (e) {
			if (e instanceof MerckError) {
				setError(e.message);
			}
			setStepID(StepContentMapping.failed.id);
		}
	};

	const closeModal = () => {
		const step = TypeUtils.returnValueOfKey<typeof StepContentMapping, TProgressStep>(stepID, StepContentMapping);

		if (step?.id === StepContentMapping.cancel.id) {
			notificationService.sendSuccess(t('drm-pipeline.canceled'), {
				duration: 5000,
			});
		}
		if (step?.id === StepContentMapping.uploading.id) {
			setStepID(StepContentMapping.cancel.id);
		} else {
			abortRef.current = true;
			setModalVisibility(false);
		}
		if (step?.id === StepContentMapping.done.id) {
			onUpdate();
		}
		setError(defaultError);
	};

	const StepContentMapping: Record<string, TProgressStep> = {
		uploading: {
			id: 'uploading',
			title: <Tr.Admin path={'drm-pipeline.uploading.title'} />,
			component: <UploadingStep progress={progress} />,
		},
		cancel: {
			id: 'cancel',
			title: <Tr.Common path={'confirmation'} />,
			component: <CancelStep onCancel={closeModal} onContinue={() => setStepID(StepContentMapping.uploading.id)} />,
		},
		done: {
			id: 'done',
			title: <Tr.Common path={'done'} />,
			component: <DoneStep onClose={closeModal} />,
		},
		failed: {
			id: 'failed',
			title: <Tr.Common path={'failed'} />,
			component: <FailedStep message={error} onClose={closeModal} />,
		},
	};

	const [stepID, setStepID] = useState(StepContentMapping.uploading.id);
	const step = TypeUtils.returnValueOfKey<typeof StepContentMapping, TProgressStep>(stepID, StepContentMapping);

	return (
		<>
			<IrFileUploadCard onFolderSelected={handleSelectedFolder} />
			<ModalDialog
				maxWidth="sm"
				sx={{ zIndex: 2 }}
				variant="secondary"
				open={isModalVisible}
				onClose={closeModal}
				title={step?.title}
				PaperProps={{ sx: { width: '472px' } }}
				contentProps={{ sx: { paddingX: 8, paddingTop: 2, paddingBottom: 7 } }}
				data-testid={DataTestId.getStaticTestId('bulk-file-upload-id')}
			>
				{step?.component}
			</ModalDialog>
		</>
	);
};
