import { FC, ReactNode, useMemo } from 'react';
import { Column } from 'react-table';
import { Typography, TypographyProps } from '@mui/material';
import { CommentTypeEnum, NmrAnalysisResult, NmrResultAtoms } from '@services';
import { mapNumberAndFixWidth2, NumberUtils, Tr } from '@utils';
import { DataTable } from '@components/common';
import { CellTooltip } from '../QualitativeTableOfAssignment/QualitativeHelperComponents';

const Cell: FC<TypographyProps> = ({ sx, ...props }) => {
	return (
		<Typography
			variant="pg-s"
			data-testid="quantitative-label"
			sx={{
				whiteSpace: 'nowrap',
				paddingX: 2,
				paddingY: 1.5,
				...sx,
			}}
			{...props}
		/>
	);
};
const HeaderCell: FC<{ children: ReactNode }> = ({ children }) => (
	<span style={{ maxWidth: '4rem', minWidth: '2rem', wordBreak: 'keep-all' }}>{children}</span>
);

interface IQuantitativeTableOfAssignment {
	resultData: NmrAnalysisResult;
	selectedErmIndex: number;
}

export const QuantitativeTableOfAssignment: FC<IQuantitativeTableOfAssignment> = ({ resultData, selectedErmIndex }) => {
	const { resultMatches } = resultData;

	const columns = useMemo<Column<NmrResultAtoms>[]>(
		() => [
			{
				accessor: 'atomNumber',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.atom" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<CellTooltip comments={row.original.comments} targetCommentType={CommentTypeEnum.SIGNAL}>
						<Cell sx={{ color: 'primary.main', textAlign: 'center' }}>{value || '-'}</Cell>
					</CellTooltip>
				),
			},
			{
				accessor: 'rmChemicalGroup',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.chemical-group" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell sx={{ whiteSpace: 'nowrap' }}>{value || '-'} </Cell>,
			},
			{
				accessor: 'rmChemShift',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.chem-shift" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<CellTooltip comments={row.original.comments} targetCommentType={CommentTypeEnum.CHEM_SHIFT}>
						<Cell sx={{ whiteSpace: 'nowrap' }}>{mapNumberAndFixWidth2(value)}</Cell>
					</CellTooltip>
				),
			},
			{
				accessor: 'rmHMultiplicity',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.multiplicity" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell>{value || '-'} </Cell>,
			},
			{
				accessor: 'rmCouplingConstant',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.coupling-constant" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell>{mapNumberAndFixWidth2(value)} </Cell>,
			},
			{
				accessor: 'rmNumberOfH',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.number-of-h" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell>{value ?? '-'} </Cell>,
			},
			{
				accessor: 'absoluteIntegral',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.absolute-integral" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<CellTooltip comments={row.original.comments} targetCommentType={CommentTypeEnum.INTEGRAL}>
						<Cell>{value ? NumberUtils.format(Number(value)) : '-'} </Cell>
					</CellTooltip>
				),
			},
		],
		[resultMatches, selectedErmIndex],
	);
	const selectedDrm = resultMatches[`${selectedErmIndex}`];
	return (
		<>
			{selectedDrm?.atoms ? (
				<DataTable
					columns={columns}
					data={selectedDrm?.atoms || []}
					sx={{
						'& 	.MuiTableCell-root': {
							borderRight: '1px solid',
							borderColor: 'grey.200',
							padding: 0,
							'&:last-child': {
								borderRight: 'none',
							},
						},
						'& 	.MuiTableCell-head': {
							padding: 1.2,
						},
						'& .MuiTableBody-root 	.MuiTableRow-root': {
							'&:last-child': {
								'& .MuiTableCell-root': {
									borderBottom: 'none',
								},
							},
							'& .MuiTableCell-root': {
								padding: '0',
							},
							height: '2.5rem',
						},
					}}
				/>
			) : (
				<></>
			)}
		</>
	);
};
