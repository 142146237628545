import { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { DataTestId, Tr } from '@utils';
import { IFileStateType } from '../DeviceForm/DeviceFileHook';
import { IRecalibrationType } from '../DeviceForm/DeviceUtils';

const colorMap = {
	active: {
		title: 'text.primary',
		content: 'grey.800',
	},
	inactive: {
		title: 'grey.500',
		content: 'grey.500',
	},
};

export const DeviceSpecificationInfo: FC<{ title: string | JSX.Element; value: string | JSX.Element; isActive?: boolean }> = ({
	title,
	value,
	isActive = true,
	...props
}) => {
	const colors = isActive ? colorMap.active : colorMap.inactive;
	return (
		<>
			<Typography variant="label-s" color={colors.title}>
				<Tr.Portal path={title as string} />
			</Typography>
			<Typography variant="pg-m" sx={{ marginBottom: '1.5rem', wordBreak: 'break-all', color: colors.content }} {...props}>
				{value}
			</Typography>
		</>
	);
};

const ROUND_JS_VALUE = 10;
export const DeviceSpecification: FC<{
	formik?: ReturnType<typeof useFormik>;
	file?: IFileStateType;
	recalibration?: IRecalibrationType;
}> = ({ file, formik, recalibration }) => {
	const { t } = useTranslation('portal');
	const titleColor = (recalibration?.calibrationData?.isActive ?? true) ? 'primary.main' : 'grey.500';
	return (
		<>
			<Typography variant="h3" sx={{ marginBottom: '1.5rem', textTransform: 'none' }} color={titleColor}>
				<Tr.Portal path="device-management.device-specification" />
			</Typography>

			<DeviceSpecificationInfo
				title={t('device-management.device-name')}
				value={recalibration?.calibrationData?.name ?? formik?.values.deviceName ?? ''}
				isActive={recalibration?.calibrationData?.isActive}
				data-testid={DataTestId.getStaticTestId(`device-name-id`)}
			/>
			<DeviceSpecificationInfo
				title={t('device-management.manufacturer')}
				value={recalibration?.calibrationData?.manufacturer ?? file?.manufacturer ?? ''}
				isActive={recalibration?.calibrationData?.isActive}
				data-testid={DataTestId.getStaticTestId(`device-manufacturer-id`)}
			/>
			<DeviceSpecificationInfo
				title={t('device-management.probe-id')}
				value={recalibration?.calibrationData?.probeId ?? file?.probeId ?? ''}
				isActive={recalibration?.calibrationData?.isActive}
				data-testid={DataTestId.getStaticTestId(`device-probe-id`)}
			/>
			<DeviceSpecificationInfo
				title={t('device-management.frequency')}
				value={
					recalibration?.calibrationData?.frequency ??
					(file ? `${Math.round(+file?.frequency / ROUND_JS_VALUE) * ROUND_JS_VALUE}` : '')
				}
				isActive={recalibration?.calibrationData?.isActive}
				data-testid={DataTestId.getStaticTestId(`device-frequency-id`)}
			/>
		</>
	);
};
