import { FC, ReactNode, useState, useEffect } from 'react';

import { Stack, SxProps, Typography } from '@mui/material';
import { IRPeaksTable, IrStackedQuantification, IrStackedSpectrum, IrStackedSpectrumPurityCheck } from '@components/ir-portal';
import {
	IrAnalysisResult,
	IrResultImpurites,
	RankBasedMixtureData,
	RmSpectrumInfoPurity,
	SampleSpectrumInfo,
	SampleSpectrumInfoPurity,
} from '@services';
import { Tr } from '@utils/Translation';

import { IrChartSelectButton, ChartType } from '../IrChartSelectButton';
import { IrOverlayQuantificationChart, IrOverlaySpectrum, IrOverlaySpectrumPurityCheck } from '../IrOverlaySpectrum';
import { setChart } from '@store/slices/ir/ir.slice';
import { useDispatch } from 'react-redux';

export const IrMolecularImage: FC<{ src: string; title: ReactNode }> = ({ src, title }) => (
	<Stack>
		<Typography variant="h4" sx={{ color: 'primary.main' }} textAlign="left" marginBottom={2}>
			<Tr.IrPortal path="analysis-result.molecule-structure" />: <span style={{ color: '#5E5E72' }}>{title}</span>
		</Typography>
		<Stack sx={{ borderRadius: '8px', border: '1px solid', borderColor: 'grey.200', padding: '1rem' }}>
			<img src={src} crossOrigin="use-credentials" style={{ maxWidth: '300px', margin: 'auto' }} id="molecular-image" />
		</Stack>
	</Stack>
);

interface IReferenceMaterialResultViewProps {
	resultData: IrAnalysisResult;
	rankedRMData: RankBasedMixtureData;
	containerSx?: SxProps;
	hasSubtitle?: boolean;
	userSpectrumInfo: SampleSpectrumInfo;
	userSpectrumInfoPurityCheck: SampleSpectrumInfoPurity;
	rmSpectrumInfoPurityCheck: RmSpectrumInfoPurity;
	resultImpurities?: IrResultImpurites[];
}

export const IrReferenceMaterialResultView: FC<IReferenceMaterialResultViewProps> = ({
	resultData,
	rankedRMData,
	containerSx,
	hasSubtitle = false,
	userSpectrumInfo,
	userSpectrumInfoPurityCheck,
	rmSpectrumInfoPurityCheck,
	resultImpurities,
}) => {
	const [chartType, setChartType] = useState<ChartType>(ChartType.SEPARATE_CHART);
	const dispatch = useDispatch();

	const isSeparateChart = () => chartType === ChartType.SEPARATE_CHART;
	const isOverlayChart = () => chartType === ChartType.OVERLAY;

	useEffect(() => {
		dispatch(setChart(chartType));
	}, [chartType]);

	return rankedRMData ? (
		<Stack rowGap={4} sx={{ ...containerSx, marginTop: !resultData.isIdentification() ? '1rem' : '-2rem' }}>
			<Stack direction="row">
				<Stack sx={{ width: '100%' }}>
					{
						<Stack sx={{ marginLeft: 'auto', marginBottom: '1rem' }}>
							<IrChartSelectButton onChange={(selected) => setChartType(selected)} isOverlay={isOverlayChart()} />
						</Stack>
					}

					{isSeparateChart() && resultData.isIdentification() && (
						<IrStackedSpectrum
							subtitleVisibility={hasSubtitle}
							rankedRMData={rankedRMData}
							userSpectrumInfo={userSpectrumInfo}
						/>
					)}

					{isOverlayChart() && resultData.isIdentification() && (
						<IrOverlaySpectrum
							subtitleVisibility={hasSubtitle}
							rankedRMData={rankedRMData}
							userSpectrumInfo={userSpectrumInfo}
						/>
					)}
					{!resultData.isIdentification() && !resultData.isQuantification() && (
						<Stack
							sx={{
								padding: '0.5rem 1rem 0 0',
							}}
						>
							{isOverlayChart() && (
								<IrOverlaySpectrumPurityCheck
									subtitleVisibility={hasSubtitle}
									rankedRMData={rmSpectrumInfoPurityCheck}
									userSpectrumInfo={userSpectrumInfoPurityCheck}
									resultImpurities={resultImpurities}
								/>
							)}
							{isSeparateChart() && (
								<IrStackedSpectrumPurityCheck
									subtitleVisibility={hasSubtitle}
									rankedRMData={rmSpectrumInfoPurityCheck}
									userSpectrumInfo={userSpectrumInfoPurityCheck}
									resultImpurities={resultImpurities}
								/>
							)}
						</Stack>
					)}
					{resultData.isQuantification() && (
						<Stack
							sx={{
								padding: '0.5rem 1rem 0 0',
							}}
						>
							{isOverlayChart() && (
								<IrOverlayQuantificationChart
									subtitleVisibility={hasSubtitle}
									rankedRMData={rmSpectrumInfoPurityCheck}
									userSpectrumInfo={userSpectrumInfoPurityCheck}
									quantificationDetails={resultData?.quantificationDetails}
								/>
							)}
							{isSeparateChart() && (
								<IrStackedQuantification
									subtitleVisibility={hasSubtitle}
									rankedRMData={rmSpectrumInfoPurityCheck}
									userSpectrumInfo={userSpectrumInfoPurityCheck}
									quantificationDetails={resultData?.quantificationDetails}
								/>
							)}
						</Stack>
					)}
				</Stack>
			</Stack>
			<Stack>
				<IRPeaksTable resultData={resultData} rankedRMData={rankedRMData} userSpectrumInfo={userSpectrumInfo} />
			</Stack>
		</Stack>
	) : null;
};
