import { FC } from 'react';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import { InfoMessageBox } from '@components/common';
import { MerckLinearProgressWithLabel } from '@components/ui';
import { Stack, Typography, Avatar, Box } from '@mui/material';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';

export const UploadingStep: FC<{ progress: number }> = ({ progress }) => {
	return (
		<Stack direction="column" alignItems={'center'} data-testid={DataTestId.getStaticTestId('drm-pipeline-uploading-card-id')}>
			<Avatar
				sx={{
					width: '5rem',
					height: '5rem',
					marginBottom: 3,
					backgroundColor: 'primary.main',
				}}
			>
				<DriveFolderUploadRoundedIcon sx={{ fontSize: '2.5rem', opacity: '0.32' }} />
			</Avatar>
			<Typography variant="subtitle1" marginBottom={1.5}>
				<Tr.Admin path="drm-pipeline.uploading.title" />
			</Typography>
			<Box marginBottom={2.5} sx={{ width: '100%' }}>
				<MerckLinearProgressWithLabel value={progress} />
			</Box>
			<InfoMessageBox
				message={
					// TODO: transform mui components
					<ul style={{ margin: 0, paddingTop: '0.25rem', paddingLeft: '1.5rem' }}>
						<li>
							<Tr.Admin path="drm-pipeline.uploading.warning.dont-navigate" />
						</li>
						<li>
							<Tr.Admin path="drm-pipeline.uploading.warning.maintain-connection" />
						</li>
						<li>
							<Tr.Admin path="drm-pipeline.uploading.warning.dont-close-browser" />
						</li>
					</ul>
				}
			/>
		</Stack>
	);
};
