import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageRoutes, AppNotification, NotificationType, PortalPageRoutes } from '@models';

export const NotificationMessage: FC<{ notification: AppNotification }> = ({ notification: { data, notificationType } }) => {
	const { t, ready } = useTranslation('notification');
	const translate = (path: string, options: any) => (ready ? t(path, options) : '');
	const parsedData = JSON.parse(data as unknown as string);
	return <span dangerouslySetInnerHTML={{ __html: translate(`notification-message.${notificationType}`, { ...parsedData }) }}></span>;
};

type LinkCategory =
	| 'analysisResult'
	| 'device'
	| 'accessory'
	| 'newErmRequest'
	| 'organizationSetting'
	| 'subscriptionSetting'
	| 'organizationSettingMerckAdmin'
	| 'searchReference'
	| 'backlog'
	| 'nmrDrmOrderDetail'
	| 'customReferenceMaterial';

const LinkMapping: Record<LinkCategory, (notification: AppNotification) => string> = {
	analysisResult: (notification) => `/portal/${PortalPageRoutes.ANALYSIS_RESULT}/${notification.getData().analysisId}`,
	device: (notification) =>
		`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${notification.getData().deviceId}`,
	accessory: (notification) =>
		`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/${notification.getData().deviceId}`,
	newErmRequest: (notification) =>
		`/admin/${PageRoutes.SUBSTANCE_MANAGEMENT}/${PageRoutes.ERM_REQUESTS}/${notification.getData().ermRequestId}`,
	subscriptionSetting: (notification) =>
		`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ORGANIZATION_SETTINGS}/${
			notification.getData().organizationId
		}/subscription`,
	organizationSetting: (_) => `/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ORGANIZATION_SETTINGS}`,
	organizationSettingMerckAdmin: (notification) =>
		`/admin/${PageRoutes.CUSTOMER_MANAGEMENT}/${notification.getData().organizationId}/subscription`,
	searchReference: (_) => `/portal/search`,
	backlog: (_) => `/admin/${PageRoutes.DRM_PIPELINE}`,
	nmrDrmOrderDetail: (notification) =>
		`/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.DRM_ORDER_DETAILS}/${notification.getData().orderId}`,
	customReferenceMaterial: (_) =>
		`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
};

export const NotificationContentLinkMapping: Record<NotificationType, (notification: AppNotification) => string> = {
	AnalysisApproved: (notification) => LinkMapping.analysisResult(notification),
	AnalysisExecutionCompleted: (notification) => LinkMapping.analysisResult(notification),
	AnalysisRejected: (notification) => LinkMapping.analysisResult(notification),
	ApprovalRequired: (notification) => LinkMapping.analysisResult(notification),
	ApprovalRequiredMoreThan7Days: (notification) => LinkMapping.analysisResult(notification),

	DeviceCalibrationCheckNeeded: (notification) => LinkMapping.device(notification),
	DeviceCalibrationExpiringInOneDay: (notification) => LinkMapping.device(notification),
	DeviceCalibrationExpiringInOneWeek: (notification) => LinkMapping.device(notification),

	AccessoryCalibrationCheckNeeded: (notification) => LinkMapping.accessory(notification),
	AccessoryCalibrationExpiringInOneDay: (notification) => LinkMapping.accessory(notification),
	AccessoryCalibrationExpiringInOneWeek: (notification) => LinkMapping.accessory(notification),

	IrWaveFileUploadFailed: (notification) => LinkMapping.backlog(notification),
	IrWaveFileUploadSuccess: (notification) => LinkMapping.backlog(notification),
	IrWaveReleaseFailed: (notification) => LinkMapping.backlog(notification),
	IrWaveReleaseSuccess: (notification) => LinkMapping.backlog(notification),

	IrCustomRMCreateProcessSucceded: (notification) => LinkMapping.customReferenceMaterial(notification),
	IrCustomRMCreateProcessFailed: (notification) => LinkMapping.customReferenceMaterial(notification),
	IrCustomRMUpdateProcessFailed: (notification) => LinkMapping.customReferenceMaterial(notification),
	IrCustomRMUpdateProcessSucceded: (notification) => LinkMapping.customReferenceMaterial(notification),
	IrCustomRMReactivateProcessFailed: (notification) => LinkMapping.customReferenceMaterial(notification),
	IrCustomRMReactivateProcessSucceded: (notification) => LinkMapping.customReferenceMaterial(notification),
	IrCustomRMDeactivateProcessFailed: (notification) => LinkMapping.customReferenceMaterial(notification),
	IrCustomRMDeactivateProcessSucceded: (notification) => LinkMapping.customReferenceMaterial(notification),

	NewDeviceAvailable: (notification) => LinkMapping.device(notification),
	NewDeviceCalibrated: (notification) => LinkMapping.device(notification),

	NewErmAvailable: (notification) => LinkMapping.searchReference(notification),
	NewErmRequest: (notification) => LinkMapping.newErmRequest(notification),
	RequestedErmAvailable: (notification) => LinkMapping.searchReference(notification),

	ReachedNinetyFivePercentOfAnalysis: (notification) => LinkMapping.subscriptionSetting(notification),
	ReachedNinetyPercentOfAnalysis: (notification) => LinkMapping.subscriptionSetting(notification),
	ReachedTheCountLimitOfAnalysis: (notification) => LinkMapping.subscriptionSetting(notification),

	SubscriptionExpired: (notification) => LinkMapping.subscriptionSetting(notification),
	SubscriptionOneDayRemained: (notification) => LinkMapping.subscriptionSetting(notification),
	SubscriptionOneMonthRemained: (notification) => LinkMapping.subscriptionSetting(notification),
	SubscriptionOneWeekRemained: (notification) => LinkMapping.subscriptionSetting(notification),

	SubscriptionExpiredForMerckAdmin: (notification) => LinkMapping.organizationSettingMerckAdmin(notification),
	SubscriptionOneMonthRemainedForMerckAdmin: (notification) => LinkMapping.organizationSettingMerckAdmin(notification),
	SubscriptionOneDayRemainedForMerckAdmin: (notification) => LinkMapping.organizationSettingMerckAdmin(notification),
	SubscriptionOneWeekRemainedForMerckAdmin: (notification) => LinkMapping.organizationSettingMerckAdmin(notification),

	NewDrmRequestCreatedWithActionRequired: (notification) => LinkMapping.newErmRequest(notification),
	NewDrmRequestCreatedWithActionRequiredMoreThan7Days: (notification) => LinkMapping.newErmRequest(notification),

	NmrDrmOrderDeclined: (notification) => LinkMapping.nmrDrmOrderDetail(notification),
	NmrDrmOrderRejected: (notification) => LinkMapping.nmrDrmOrderDetail(notification),
	NmrDrmOrderBulkUploadCompleted: (_) => LinkMapping.backlog(_),
};
