import { useParams } from 'react-router-dom';
import { RxUtils, Tr } from '@utils';
import { FC, useEffect, useState } from 'react';
import { GenericCard } from '@components/common';
import { Grid } from '@mui/material';
import { irDrmRequestService, IrRequestAccordionItem } from '@services/index';
import { EmptyAdditionalActions } from '@components/admin/EmptyAdditionalActions/EmptyAdditionalActions';
import { IrRequestCommentForm } from './IrRequestCommentForm';
import { IrRequestNoteAccordion } from './IrRequestNoteAccordion';
interface IIrRequestCommentFormProps {
	isAddCommentButtonClicked: boolean;
	requestId?: number;
	orderId?: number;
	setIsAddCommentButtonClicked: (value: boolean) => void;
}
export const IrRequestComments: FC<IIrRequestCommentFormProps> = ({
	isAddCommentButtonClicked,
	requestId,
	orderId,
	setIsAddCommentButtonClicked,
}) => {
	const { id } = useParams();

	const [comments, setComments] = useState<IrRequestAccordionItem[]>();
	const [isCommentsUpdated, setIsCommentsUpdated] = useState(false);

	const getDetailData = () =>
		id &&
		RxUtils.promisify(irDrmRequestService.requestsCommentDetail(requestId, orderId), (value) => {
			setComments(value);
		});

	useEffect(() => {
		getDetailData();
		return () => {
			setComments(undefined);
		};
	}, []);

	useEffect(() => {
		if (isCommentsUpdated) {
			getDetailData();
			setIsCommentsUpdated(false);
		}
	}, [isCommentsUpdated]);

	return (
		<>
			{!id || (!(comments && comments?.length > 0) && !(comments && comments?.length > 0) && !isAddCommentButtonClicked) ? (
				<EmptyAdditionalActions
					title={
						<>
							<Tr.Admin path="erm-request-detail.comments.no-comments" />
						</>
					}
					explanation={
						<>
							<Tr.Admin path={'erm-request-detail.comments.no-comment-explanation'} />
						</>
					}
				/>
			) : (
				<Grid container direction="column">
					<Grid item>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<Grid container justifyContent="space-between">
									<Grid item></Grid>
								</Grid>
							</Grid>
							<Grid item>
								<GenericCard sx={{ paddingY: 2 }}>
									<Grid container direction="column" spacing={2}>
										<Grid item>
											{isAddCommentButtonClicked && (
												<IrRequestCommentForm
													onCommentUpdate={setIsCommentsUpdated}
													requestId={requestId}
													setIsAddCommentButtonClicked={setIsAddCommentButtonClicked}
												/>
											)}
										</Grid>
										<Grid item>
											<IrRequestNoteAccordion
												onCommentUpdate={setIsCommentsUpdated}
												itemList={comments ? comments : []}
											/>
										</Grid>
									</Grid>
								</GenericCard>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};
