import { Type } from 'class-transformer';

export class AuditTrail {
	id: number;
	@Type(() => Date)
	createTime: Date;
	user: string;
	entityId: number;
	title: string;
	action: string;
	comment: string;
	oldData: string;
	newData: string;
	change?: string;
}
