import { FC, useEffect, useState } from 'react';
import { matchRoutes, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Stack, Tab, Tabs } from '@mui/material';
import { AnalysisHistoryTabs, LabPermission, PortalPageRoutes } from '@models';
import { ROUTES } from '@routes/routes';
import { DataTestId, mainTitleSpacer } from '@utils';
import { Tr } from '@utils';
import { useHasPermissions } from '@hooks';

export const NmrAnalysisHistory: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedRoute, setSelectedRoute] = useState<string>();

	const hasAnalysisPermission = useHasPermissions(LabPermission.VIEW_ANALYSIS);

	useEffect(() => {
		const matches = matchRoutes(ROUTES, location);
		const route = matches?.slice(-1)?.[0].route.path?.replace('/:id', '');
		if (route === PortalPageRoutes.HISTORY) {
			setSelectedRoute(AnalysisHistoryTabs.ONGOING_ANALYSIS);
			navigate(`../${PortalPageRoutes.HISTORY}/${AnalysisHistoryTabs.ONGOING_ANALYSIS}`, { replace: true });
		} else {
			setSelectedRoute(route);
		}
	}, [location]);

	return (
		<Stack sx={{ marginTop: mainTitleSpacer }}>
			<Stack data-testid="analysis-history-wrapper-id" marginBottom={mainTitleSpacer}>
				<Tabs
					textColor="primary"
					value={selectedRoute || AnalysisHistoryTabs.ONGOING_ANALYSIS}
					onChange={(_, newValue) => {
						navigate(`../${PortalPageRoutes.HISTORY}/${newValue}`);
					}}
				>
					{hasAnalysisPermission && (
						<Tab
							data-testid={DataTestId.getStaticTestId('ongoing-analysis-tab-id')}
							value={AnalysisHistoryTabs.ONGOING_ANALYSIS}
							label={<Tr.Portal path="analysis-history.ongoing-analysis" />}
						/>
					)}
					{hasAnalysisPermission && (
						<Tab
							value={AnalysisHistoryTabs.COMPLETED_ANALYSIS}
							label={<Tr.Portal path="analysis-history.completed-analysis" />}
							data-testid={DataTestId.getStaticTestId('completed-analysis-tab-id')}
						/>
					)}
				</Tabs>
			</Stack>
			<Outlet />
		</Stack>
	);
};
