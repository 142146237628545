import { SubscriptionDefaults } from '@models/subscribe';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getFreeSubscriptionSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		budget: yup
			.number()
			.typeError(t('organization.number-of-analyses-is-invalid'))
			.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
			.min(1, t('organization.number-of-analyses-min'))
			.max(SubscriptionDefaults.MAX_BUDGET, t('organization.number-of-analyses-max'))
			.required(t('organization.number-of-analyses-is-required')),
		comment: yup.string(),
		startDate: yup.date().required(),
		endDate: yup.date().required(),
	});
