import { EventCategory } from '@models/analytics';
import { OrganizationEventAction, OrganizationEventLabel } from '@models/analytics';

export enum OrganizationEventType {
	ORGANIZATION_CREATED = 'OrganizationCreated',
}

export const sendEvent = (category: EventCategory, action: OrganizationEventAction, label: string) =>
	(window as any).gtag('event', action, {
		event_category: category,
		event_label: label,
		value: label,
	});

export const analyticsOrganizationEvent: Record<OrganizationEventType, (organizationId: string) => void> = {
	OrganizationCreated: (organizationId: string) =>
		sendEvent(
			EventCategory.Organization,
			OrganizationEventAction.ORGANIZATION_CREATED,
			`${OrganizationEventLabel.ORGANIZATION_CREATED} - ${organizationId}`,
		),
};
