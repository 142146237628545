import { Collapse, Stack } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';
import { ExtendedComponent } from 'src/types';

type THeightTransition = {
	showBiggerComponent: boolean;
	refreshHeightSize: boolean;
};

export const HeightTransition: ExtendedComponent<THeightTransition> = ({ showBiggerComponent, children, refreshHeightSize }) => {
	const [height, setHeight] = useState<number | string>('auto');
	const [collapsedSize, setCollapsedSize] = useState<number | string>('auto');
	const containerRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (containerRef.current && !showBiggerComponent) {
			setHeight(containerRef.current.scrollHeight);
			setCollapsedSize(containerRef.current.scrollHeight);
		}
	}, [refreshHeightSize, showBiggerComponent, containerRef.current?.scrollHeight]);

	useLayoutEffect(() => {
		if (containerRef.current) {
			setHeight(containerRef.current.scrollHeight);
		}
	}, [showBiggerComponent]);

	return (
		<Collapse in={showBiggerComponent} collapsedSize={collapsedSize}>
			<Stack height={height}>
				<Stack ref={containerRef}>{children}</Stack>
			</Stack>
		</Collapse>
	);
};
