import { FC } from 'react';
import { Typography, Stack, StackProps } from '@mui/material';
import { Tr } from '@utils';
import { ReactComponent as ViewAgenda } from '@material-symbols/svg-600/outlined/view_agenda.svg';

export enum ChartType {
	OVERLAY = 'overlay',
	SEPARATE_CHART = 'separate_chart',
}

interface IChartSelectButtonProps {
	value?: ChartType;
	isOverlay: boolean;
	onChange: (selected: ChartType) => void;
}

export const NmrChartSelectButton: FC<Omit<StackProps, 'onChange'> & IChartSelectButtonProps> = ({ onChange, sx, isOverlay, ...props }) => {
	return (
		<Stack
			data-testid="chart-select-wrapper-id"
			direction="row"
			sx={{ ...sx, cursor: 'pointer' }}
			{...props}
			onClick={() => {
				onChange(isOverlay ? ChartType.SEPARATE_CHART : ChartType.OVERLAY);
			}}
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			<Stack display="flex">
				<ViewAgenda width={16} height={16} fill="#01884C" />
			</Stack>
			<Stack sx={{ display: 'flex', margin: '4px' }}>
				<Typography sx={{ color: 'text.secondary', whiteSpace: 'nowrap', display: 'flex' }} variant="pg-m">
					<Tr.Portal path={isOverlay ? 'analysis-result.spectra-separate' : 'analysis-result.spectra-overlay'} />
				</Typography>
			</Stack>
		</Stack>
	);
};
