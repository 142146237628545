import { Button, Grid, Stack, StackProps, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { ModalDialog } from '@components/common/ModalDialog';
import { FormikTextfield } from '@components/common/FormikTextfield';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FC } from 'react';
import { RxUtils } from '@utils/Rx';
import { labService } from '@services/core/lab/lab.service';
import { PatchType } from '@models/request-response';
import { PropertyOf } from '@models/request-response/http-patch';
import { Lab } from '@services/index';

const fullWidth: StackProps = { width: 1 };

interface IRenameLabDialogProps {
	id: number;
	initialName: string;
	isOpen: boolean;
	onCancel?: () => void;
	onSave?: () => void;
}

type IRenameLabFields = Extendable<{
	name: string;
}>;

export const RenameLabDialog: FC<IRenameLabDialogProps> = ({ id, isOpen = false, initialName, onCancel, onSave }) => {
	const { t } = useTranslation('common');

	const formik = useFormik<IRenameLabFields>({
		initialValues: {
			name: initialName,
		},
		validationSchema: yup.object({
			name: yup
				.string()
				.trim()
				.max(32, t('organization-details.labs.name-should-have-max-32-characters'))
				.required(t('organization-details.labs.name-required')),
		}),
		onSubmit: (fields: IRenameLabFields) => {
			if (formik.isValid) {
				RxUtils.promisify(
					labService.changeLaboratory(id, [
						{
							op: PatchType.REPLACE,
							path: PropertyOf<Lab>('name'),
							value: fields.name,
						},
					]),
					() => {
						onSave && onSave();
					},
				);
			}
		},
		validateOnChange: true,
	});

	return (
		<ModalDialog
			variant="secondary"
			open={isOpen}
			title={
				<Typography variant="pg-s">
					<Tr.Common path="lab-details.rename" />
				</Typography>
			}
			closeIconClick={onCancel}
			maxWidth={false}
			PaperProps={{
				sx: {
					maxWidth: '472px',
					maxHeight: '383px',
					minHeight: '383px',
				},
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<Stack
					sx={{
						paddingX: '68px',
						marginY: 4,
					}}
					spacing={6}
				>
					<Stack justifyContent="center" textAlign="center" spacing={3}>
						<Typography variant="subtitle1">
							<Tr.Common path="lab-details.rename-lab" />
						</Typography>
						<Typography variant="pg-m">
							<Tr.Common path="lab-details.rename-lab-explanation" />
						</Typography>
					</Stack>
					<Stack spacing={4}>
						<FormikTextfield
							formikApi={formik}
							name="name"
							placeholder={t('lab-details.lab-name')}
							value={formik.values.name || initialName}
							variant="outlined"
							containerProps={fullWidth}
							inputProps={{ 'data-testid': 'name-test-id' }}
							required
						/>

						<Grid container paddingTop={1}>
							<Grid
								item
								xs={6}
								sx={{
									paddingRight: 1,
								}}
							>
								<Button
									variant="outlined"
									fullWidth={true}
									data-testid="rename-cancel-button-id"
									disableElevation
									onClick={() => {
										onCancel && onCancel();
									}}
								>
									<Tr.Common path="cancel" />
								</Button>
							</Grid>

							<Grid
								item
								xs={6}
								sx={{
									paddingLeft: 1,
								}}
							>
								<Button
									fullWidth={true}
									data-testid="rename-approve-button-id"
									variant="contained"
									type="submit"
									disableElevation
									disabled={!formik.dirty || !formik.isValid}
									sx={{ textTransform: 'capitalize' }}
								>
									<Tr.Common path="save" />
								</Button>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
