import { DependencyList, useEffect, useRef } from 'react';

export const useComponentUpdated = (func: Function, deps?: DependencyList) => {
	const didMount = useRef<boolean>(false);

	useEffect(() => {
		if (didMount.current) func();
		else didMount.current = true;
	}, deps);

	return didMount.current;
};
