import { CreateOrganizationPaidSubscriptionModel, httpService, SubscriptionHistory } from '@services';
import { concatMap, tap, map } from 'rxjs/operators';
import { RxUtils, TypeUtils } from '@utils';

import { logger } from '@services';
import { Subscription } from './subscription.class';
import { coreConfig } from '../coreConfig';
import { PatchType } from '@models/request-response';

export class SubscribeService {
	private getURL() {
		return `${coreConfig.url}/Organization`;
	}

	getActiveSubscriptionPlans(orgId: number) {
		return httpService.get<Subscription[]>(this.getURL() + `/${orgId}/subscriptions/active`, ``, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getActiveSubscriptionPlans.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Subscription, result)),
		);
	}

	getSubscriptionHistory(orgId: number, params: URLSearchParams) {
		const paramsObject = Object.fromEntries(params.entries());
		return httpService
			.get<SubscriptionHistory>(this.getURL() + `/${orgId}/subscriptions`, ``, {
				errorHandler: 'notification',
				params: { IsActive: false, ...paramsObject },
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getActiveSubscriptionPlans.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(SubscriptionHistory, result)),
			);
	}

	edit(subscription: Partial<Subscription>) {
		return httpService.put<Subscription>(this.getURL(), '', { body: subscription, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.edit.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Subscription, result)),
		);
	}

	create(subscription: CreateOrganizationPaidSubscriptionModel, orgId: number) {
		return httpService
			.post(this.getURL(), `${orgId}/subscriptions`, {
				body: subscription,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.create.name}]`, result)));
	}

	changeSubscription(subscription: Partial<Subscription>, organizationId: number) {
		const patch = [
			{
				op: PatchType.REPLACE,
				path: '/EndDate',
				value: subscription.endDate,
			},
		];
		return httpService
			.patch<unknown>(this.getURL(), `${organizationId}/subscriptions/${subscription.id}`, {
				body: subscription.budget
					? [
							...patch,
							{
								op: PatchType.REPLACE,
								path: '/Budget',
								value: subscription.budget,
							},
						]
					: [...patch],
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.changeSubscription.name}]`, result)));
	}

	delete(orgId: number, subscriptionId: number) {
		return httpService
			.delete<unknown>(this.getURL(), `${orgId}/subscriptions/${subscriptionId}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.delete.name}]`, result)));
	}
}
export const subscribeService = new SubscribeService();
