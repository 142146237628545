import { alertService, userService } from '@services/index';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { ExtendedComponent } from 'src/types';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import { useTranslation } from 'react-i18next';

type TDeleteUser = {
	id: string;
	cb: () => void;
};
export const DeleteUser: ExtendedComponent<TDeleteUser> = ({ id, cb, ...props }) => {
	const { t } = useTranslation('common');
	return (
		<Delete
			width={24}
			height={24}
			fill="#01884C"
			style={{ cursor: 'pointer' }}
			onClick={() => {
				alertService.send({
					titleText: <Tr.Common path="user-management.confirmation" />,
					content: t('user-management.cancel-invite-explanation', { orgName: 'ChemistTwin' }),
					closeTextComponent: <Tr.Common path="cancel" />,
					confirmTextComponent: <Tr.Common path="user-management.cancel-invite-approve" />,
					confirmTextHeader: t('user-management.cancel-invite-title', { orgName: 'ChemisTwin' }),
					onConfirm: () => {
						RxUtils.promisify(userService.deleteUser(id), cb);
					},
					onClose: () => null,
				});
			}}
			{...props}
		/>
	);
};
