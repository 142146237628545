import { IFilterValues } from '@models/filter';
import { OrganizationManagementModel } from '@services/core';

export const organizationToFilter: (org: OrganizationManagementModel) => IFilterValues = (org: OrganizationManagementModel) => {
	return {
		id: String(org.id),
		value: org.name,
	};
};

export const organizationListToFilter: (org: OrganizationManagementModel[]) => IFilterValues[] = (orgs) => {
	return orgs.map(organizationToFilter);
};
