import { UserStoreModel } from '@models/user';
import { MenuItem, Select, SelectChangeEvent, SxProps, Typography } from '@mui/material';
import { setUser, userSelector } from '@store/slices/common/common.slice';
import { DataTestId } from '@utils/DataTestId';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const containerSx: SxProps = {
	borderColor: '#FFFFFF80',
	color: 'white',
	backgroundColor: 'transparent',
	borderRadius: 0,
	marginY: 1,
	borderWidth: 0,
	borderLeftWidth: '1px',
	borderRightWidth: '1px',
};

export const LabDropdown = () => {
	const user = useSelector(userSelector);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSelectedLabChange = (e: SelectChangeEvent) => {
		dispatch(setUser({ ...user, currentLabId: e.target.value.toString() } as UserStoreModel));
		navigate('../');
	};

	return (
		<>
			{user?.currentLabId && (
				<Select
					value={user?.currentLabId}
					data-testid="lab-dropdown-test-id"
					defaultValue={user?.currentLabId}
					onChange={(e: SelectChangeEvent) => onSelectedLabChange(e)}
					autoWidth
					sx={{
						marginRight: 1,
						paddingRight: 1,
						fieldset: {
							...containerSx,
						},
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': containerSx,
						'&:hover .MuiOutlinedInput-notchedOutline': containerSx,
						'&.MuiOutlinedInput-root:has(input)': {
							backgroundColor: 'transparent',
						},
						'.MuiSvgIcon-root ': {
							fill: 'white',
						},
						'.MuiSelect-select .labName': {
							color: 'white !important',
						},
						'.MuiTypography-root': {
							'.technology': {
								display: 'none',
							},
						},
						'&.MuiOutlinedInput-root .MuiSelect-select h6': {
							lineHeight: '120%',
						},
						color: 'white',
					}}
					SelectDisplayProps={{
						style: {
							fontSize: 1,
							lineHeight: '160%',
						},
					}}
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: '4px',
								minWidth: '320px',
								minHeight: '48px',
								marginLeft: 1,
								'& .MuiMenuItem-root': {
									borderBottom: '1px solid #E1E1EA',
									'&:last-child': {
										borderBottom: 'none',
									},
									padding: '8px 40px',
								},
							},
						},
						sx: {
							paddingRight: 2,
						},
					}}
				>
					{user?.laboratories?.map((item, index) => {
						return (
							item.isActive && (
								<MenuItem key={`${item.id}-${item.name}`} value={item.id?.toString()} sx={{ color: 'grey.800' }}>
									<Typography
										variant="h6"
										color="inherit"
										data-testid={DataTestId.getStaticTestId(`${index}-lab-dropdown-test-id`)}
									>
										<span style={{ color: '#0F1A2E' }} className="labName">
											{item.name}
										</span>
										<br />
										<span style={{ fontSize: '12px' }} className="technology">
											{item?.technology}
										</span>
									</Typography>
								</MenuItem>
							)
						);
					})}
				</Select>
			)}
		</>
	);
};
