export interface ICalibrationRange {
	min: number;
	max: number;
}

export class NmrReceiverGainThresholds {
	id: number;
	manufacturer: string;
	calibration: ICalibrationRange[];
	calibrationCheck: ICalibrationRange[];
}
