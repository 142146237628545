export const MAX_FILE_LENGTH = 6;
export const MAX_HEALTH_CHECK_FILE = 1;
const MULTIPLE_FILES_THRESHOLD = 4;

export interface ICheckFields {
	manufacturer: string;
	probeId: string;
	frequency: string;
}

export const checkFieldsSame = (base: ICheckFields, others: ICheckFields[]) => {
	for (let index = 0; index < others.length; index++) {
		const { frequency, probeId } = others[`${index}`] ?? {};
		if (
			`${Math.round(+base.frequency / 10) * 10}`.trim() !== `${Math.round(+frequency / 10) * 10}`.trim() ||
			`${base.probeId}`.trim() !== `${probeId}`.trim()
		) {
			return false;
		}
	}
	return true;
};

export const getMatchFiles = (base: ICheckFields, others: ICheckFields[]) =>
	others.reduce((first: number[], next, index) => {
		const { frequency, probeId } = next ?? {};
		if (
			`${Math.round(+base.frequency / 10) * 10}` === `${Math.round(+frequency / 10) * 10}` &&
			`${base.probeId}`.trim() === `${probeId}`.trim()
		) {
			return [...first, index];
		}
		return [...first];
	}, []);

export const isEqualMaxFileSize = <T>(files: T[]) => files.length === MAX_FILE_LENGTH;
export const isEqualHealthCheckSize = <T>(files: T[]) => files.length === MAX_HEALTH_CHECK_FILE;
export const greaterThanMaxFiles = <T>(files: T[]) => files.length >= MAX_FILE_LENGTH;
export const greaterThanThreshold = <T>(arr: T[]) => arr.length >= MULTIPLE_FILES_THRESHOLD;
