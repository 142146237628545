import { useEffect, useRef } from 'react';
import { ExtendedComponent } from 'src/types';

export const ColorTransition: ExtendedComponent = ({ children }) => {
	const ref = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		const firstChildren = ref.current?.children?.[0] as HTMLSpanElement;
		if (firstChildren) {
			firstChildren.style.transition = 'color 0.1s';
		}
	}, [ref.current]);

	return <span ref={ref}>{children}</span>;
};
