export type TFileTypeMatching = {
	[key: string]: string[];
};
export const FileGeneralTypeAcceptedExtensionMapping: Record<'core' | 'additional', TFileTypeMatching> = {
	core: {
		'chemical/x-jcamp-dx': ['.jdx', '.spectrus'],
		'application/vnd.cip4-jdf+xml': ['.jdf'],
		'application/x-zip-compressed': ['.zip'],
		'application/pdf': ['.pdf'],
	},
	additional: {
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
		'application/x-zip-compressed': ['.zip'],
		'chemical/x-jcamp-dx': ['.jdx', '.spectrus'],
		'application/vnd.cip4-jdf+xml': ['.jdf'],
		'application/pdf': ['.pdf'],
	},
};

export const FILE_UPLOADER_WIDTHS = {
	DEFAULT: 3,
	XL: 4,
};
