import { IconButton, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import { FC } from 'react';

interface INoPaidSubscriptionProps {
	onSave?: () => void;
}

export const NoPaidSubscription: FC<INoPaidSubscriptionProps> = ({ onSave }) => {
	return (
		<Box height={1} justifyContent="center">
			<Typography variant="h5">
				<Tr.Admin path="organization.no-paid-subscription" />
			</Typography>
			<Stack height={1} paddingTop={2} paddingBottom={3}>
				<Stack
					justifyContent="center"
					alignItems="center"
					height={1}
					marginTop="auto"
					sx={{ border: '1px solid', borderColor: 'grey.200', borderRadius: '8px' }}
				>
					<IconButton
						onClick={() => onSave?.()}
						data-testid="add-button-test-id"
						sx={{ width: '80px', height: '80px', backgroundColor: 'primary.50', marginBottom: 3 }}
					>
						<AddIcon sx={{ transform: 'scale(2)', color: 'primary.main' }} />
					</IconButton>
					<Typography variant="subtitle3" color="primary.main">
						<Tr.Admin path="organization.add-subscription" />
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
};
