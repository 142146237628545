import { Type } from 'class-transformer';
import { IrAccessoryCalibrationStatus } from './ir-accessory-status.enum';
import { IrAccessoryCalibration } from './ir-accessory-calibration.class';

export class IrAccessoryRecalibrateModel {
	name: string;
	status: IrAccessoryCalibrationStatus;
	manufacturer: string;
	calibrationValue: number;
	isGxP: boolean;

	@Type(() => Date)
	lastCalibrationTime: Date;
	@Type(() => Date)
	nextCalibrationTime: Date;

	calibrationErrorMessage: string;
	accessoryCalibrations?: IrAccessoryCalibration[];

	public isInActive() {
		return this.status === IrAccessoryCalibrationStatus.INACTIVE;
	}

	public isActive() {
		return this.status === IrAccessoryCalibrationStatus.ACTIVE;
	}

	public isCalibrateHealthCheck() {
		return this.status === IrAccessoryCalibrationStatus.CALIBRATION_CHECK_NEEDED;
	}

	public isCalibrationNeeded() {
		return this.status === IrAccessoryCalibrationStatus.CALIBRATION_NEEDED;
	}

	public isCalibrating() {
		return this.status === IrAccessoryCalibrationStatus.CALIBRATING;
	}

	public isCalibrate() {
		return (
			this.status === IrAccessoryCalibrationStatus.CALIBRATION_CHECK_NEEDED ||
			this.status === IrAccessoryCalibrationStatus.CALIBRATION_NEEDED
		);
	}

	public isCalibrationError() {
		return this.status === IrAccessoryCalibrationStatus.CALIBRATION_ERROR;
	}
}
