import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getPhysicalProductValidationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		casNumber: yup.string().required(),
		physicalProductNumber: yup
			.string()
			.max(32, t('drm-order-details.add-physical-product.prod-number-should-have-max-32-characters'))
			.trim()
			.required(t('drm-order-details.add-physical-product.prod-number-required')),
		physicalProductQualityGrade: yup.string().max(250).trim().required(),
		siteSupplier: yup.string().max(250).trim().required(),
	});
