import { UserRole, UserStatus } from '@models/user';
import { UserLab } from '@models/user/user-lab.class';

// User Information comes from API (BE)
export class UserResponseModel {
	id: string;
	email: string;
	fullName?: string;
	status: UserStatus;
	roles: UserRole[];
	laboratories?: UserLab[];
	lastActivity?: Date;
	createdDate?: Date;
	organizationId?: number;
	organizationName?: string;
	name: string;
	surname: string;
	mobilePhone?: string;
	language?: string;
	betaTester?: boolean;
	permissions?: string[];
	comment?: string;
	action?: string;

	isActive() {
		return this.status === UserStatus.ACTIVE;
	}
}
