import { Grid } from '@mui/material';
import { FC, useContext } from 'react';
import { InputSpectrum } from './InputSpectrum';
import { QuantitativeNonTargetedSuccessfullResult } from './QuantitativeNonTargetedSuccessfullResult';
import { UpdateButtons } from '../UpdateButtons';
import { NmrAnalysisResultLabel } from '@components/nmr-portal';
import { NmrAnalysisResultFields } from '../NmrAnalysisResultFields';
import { NmrAnalysisErrorSummary } from '../NmrAnalysisErrorSummary';
import { AnalysisResultContext } from '../analysis-result-context';
import { NmrAlgorithmSummary, NmrAnalysisSetupSummary } from '@components/nmr-portal';
import { DataTestId } from '@utils/DataTestId';

export const QuantitativeNonTargetedResultPage: FC = () => {
	const { analysisResultData } = useContext(AnalysisResultContext);

	if (!analysisResultData) return null;

	return (
		<Grid container spacing={4} data-testid={DataTestId.getStaticTestId('quantitative-non-targeted-id')}>
			<Grid item xs={6}>
				<NmrAnalysisResultLabel />
			</Grid>
			<Grid item xs={6}>
				<UpdateButtons />
			</Grid>
			<Grid item xs={12}>
				<NmrAnalysisResultFields id={analysisResultData.analysisId} />
			</Grid>
			<Grid item xs={12}>
				{analysisResultData.isError() ? <NmrAnalysisErrorSummary /> : <QuantitativeNonTargetedSuccessfullResult />}
			</Grid>
			<Grid item xs={12}>
				<InputSpectrum />
			</Grid>
			<Grid item xs={12}>
				<NmrAnalysisSetupSummary />
			</Grid>
			<Grid item xs={12}>
				<NmrAlgorithmSummary
					targetType={analysisResultData.nmrAnalysis.targetType}
					measurementType={analysisResultData.nmrAnalysis.measurementType}
				/>
			</Grid>
		</Grid>
	);
};
