export const PriorityRules = {
	PRIORITY_MAX_LENGTH: 4,
	PRIORITY_MIN_LENGTH: 1,
	ACCEPTED_PRIORITY: new RegExp(/^[0-9]+$/),
	ACCEPTED_CHARACTERS: new RegExp(/^[0-9]$/),
};

export const PriorityRuleCheck = {
	isValid: (value: string) => PriorityRules.PRIORITY_MAX_LENGTH >= value.length && PriorityRules.ACCEPTED_PRIORITY.test(value),
	isEmpty: (value: string) => !value,
};
