import { QualityGrade, QualityGradeChipColorMapping } from '@models/reference-material';
import { Button, Stack, Typography } from '@mui/material';
import { ReferenceTechnique } from '@services/core/substance/technique.enum';
import { FC } from 'react';

export interface ISubstanceTechnology {
	disabled?: boolean;
	qualityGrade?: QualityGrade;
}

export const SubstanceTechnology: Record<ReferenceTechnique, FC<ISubstanceTechnology>> = {
	[ReferenceTechnique.NMR_REFERENCE_MATERIAL]: (props) => (
		<Technique
			techniqueText={ReferenceTechniqueTextMap[ReferenceTechnique.NMR_REFERENCE_MATERIAL]}
			textColor={props.qualityGrade ? QualityGradeChipColorMapping[props.qualityGrade].text || 'primary.main' : 'primary.main'}
			backgroundColor={
				props.qualityGrade ? QualityGradeChipColorMapping[props.qualityGrade].background || 'primary.400' : 'primary.400'
			}
			testId="substance-nmr-technique"
			{...props}
		/>
	),
	[ReferenceTechnique.IR_REFERENCE_MATERIAL]: (props) => (
		<Technique
			techniqueText={ReferenceTechniqueTextMap[ReferenceTechnique.IR_REFERENCE_MATERIAL]}
			textColor={props.qualityGrade ? QualityGradeChipColorMapping[props.qualityGrade].text || 'primary.main' : 'primary.main'}
			backgroundColor={
				props.qualityGrade ? QualityGradeChipColorMapping[props.qualityGrade].background || 'primary.400' : 'primary.400'
			}
			testId="substance-ir-technique"
			{...props}
		/>
	),
	[ReferenceTechnique.NMR_THEORETICAL_SPECTRUM]: (props) => (
		<Technique
			techniqueText={ReferenceTechniqueTextMap[ReferenceTechnique.NMR_THEORETICAL_SPECTRUM]}
			textColor={'info.700'}
			backgroundColor={'info.600'}
			testId="substance-theoretical-technique"
			{...props}
		/>
	),
};

const ReferenceTechniqueTextMap: Record<ReferenceTechnique, string> = {
	[ReferenceTechnique.NMR_REFERENCE_MATERIAL]: 'NMR',
	[ReferenceTechnique.IR_REFERENCE_MATERIAL]: 'IR',
	[ReferenceTechnique.NMR_THEORETICAL_SPECTRUM]: 'Theoretical NMR',
};

interface ITechniqueProps {
	techniqueText: string;
	textColor: string;
	backgroundColor: string;
	disabled?: boolean;
	testId: string;
}

const Technique: FC<ITechniqueProps> = ({ techniqueText, textColor, backgroundColor, disabled, testId }) => (
	<Button
		variant="contained"
		disableElevation
		data-testid={testId}
		sx={{
			width: 'min-content',
			height: 'min-content',
			paddingX: 1,
			paddingY: 0.5,
			backgroundColor: disabled ? 'grey.100' : backgroundColor,
			pointerEvents: 'none',
		}}
	>
		<Stack direction="row">
			<Typography variant="label-s" sx={{ color: disabled ? 'grey.500' : textColor }}>
				{techniqueText}
			</Typography>
		</Stack>
	</Button>
);
