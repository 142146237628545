import { FormikTextfield, GenericCard, InfoIconOutlined, InfoMessageBox, ModalDialog } from '@components/common';
import MuiOutlinedInfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { TheoreticalRunExecutingWarningMessage } from '../AnalysisResultRunTheoretical';
interface IRejectAnalysisProps {
	visible: boolean;
	analysisId: number;
	onCancel: () => void;
	onReject: (comment: string, analysisError: boolean) => void;
	isTheoreticalExecuting?: boolean;
}

type FormFields = Extendable<{
	comment: string;
	decision: boolean;
	analysisError: boolean;
}>;

const SummaryRecordWarning = () => {
	const heights = '0.875rem';
	return (
		<Stack direction="row" alignItems="center" spacing={0.75} height={heights}>
			<InfoIconOutlined
				sx={{ width: heights, minWidth: heights, height: heights, marginTop: 0, overflow: 'hidden' }}
				iconSx={{ fontSize: 8 }}
			/>
			<Typography variant="label-s" lineHeight="0.875rem">
				<Tr.Portal path="analysis-result.stay-on-record" />
			</Typography>
		</Stack>
	);
};

/* TODO: refactor here use DeactivationConfirmPopUp component */
export const NmrRejectAnalysis: FC<IRejectAnalysisProps> = ({ visible, onCancel, onReject, isTheoreticalExecuting }) => {
	const { t } = useTranslation('portal');
	const checkRef = useRef<HTMLInputElement>(null);

	const formik = useFormik<FormFields>({
		initialValues: {
			comment: '',
			sendReview: false,
			analysisError: false,
		},
		validationSchema: yup.object({
			comment: yup.string().required(t('analysis-result.rejection-reason')),
		}),
		onSubmit: ({ comment, analysisError }) => {
			onReject(comment, analysisError);
		},
	});

	useEffect(() => {
		formik.resetForm();
	}, [visible]);

	const showSummaryWarning = !!formik.values.comment && !formik.errors.comment;
	return (
		<ModalDialog
			width={472}
			onClose={onCancel}
			title={
				<Typography variant="pg-s">
					<Tr.Portal path="analysis-result.reject-analysis" />
				</Typography>
			}
			titleProps={{ sx: { paddingBottom: 0 } }}
			onBackdropClick={onCancel}
			open={visible}
			variant="secondary"
		>
			<form onSubmit={formik.handleSubmit}>
				<GenericCard
					dataTestId="reject-card"
					sx={{ overflow: 'auto', border: 'none', paddingTop: 3, paddingBottom: 7, paddingX: 8 }}
				>
					<Stack spacing={3}>
						<Stack sx={{ mt: 1, mb: 2 }} textAlign="center" spacing={3}>
							<Typography variant="h3">
								<Tr.Portal path="analysis-result.reject-analysis-reason" />
							</Typography>
							{isTheoreticalExecuting && <InfoMessageBox message={<TheoreticalRunExecutingWarningMessage />} />}
						</Stack>

						<Stack spacing={3}>
							<Stack spacing={1.25}>
								<FormikTextfield
									sx={{ backgroundColor: 'grey.50' }}
									InputProps={{
										sx: {
											'& 	.MuiInputBase-input': {
												fontSize: 16,
											},
											paddingY: 1.25,
											paddingX: 1.5,
											'& + .MuiFormHelperText-root.Mui-error': {
												bottom: '-24px',
												top: 'unset',
											},
										},
									}}
									formikApi={formik}
									multiline
									rows={5}
									name="comment"
									title={
										<Typography variant="label-s">
											<Tr.Portal path="analysis-result.reason" />
											<span style={{ color: '#E61E50' }}>*</span>
										</Typography>
									}
									placeholder={t('analysis-result.rejection-comment')}
								/>

								{showSummaryWarning && <SummaryRecordWarning />}
							</Stack>

							<Stack>
								<Box sx={{ pl: 2, pr: 1, mt: 1, border: '1px solid', borderColor: 'grey.200', borderRadius: 1 }}>
									<Stack justifyContent="space-between" alignItems="center" direction="row">
										<Stack>
											<FormControlLabel
												sx={{ width: 'fit-content' }}
												control={<Checkbox inputRef={checkRef} size="small" />}
												label={
													<Typography variant="pg-s">
														<Tr.Portal path="analysis-result.report-issue" />
													</Typography>
												}
											/>
										</Stack>
										<Stack>
											<Tooltip title={<Tr.Portal path="analysis-result.reject-info-tooltip" />} placement="top" arrow>
												<MuiOutlinedInfoIcon style={{ color: 'grey', width: '16px', cursor: 'pointer' }} />
											</Tooltip>
										</Stack>
									</Stack>
								</Box>
							</Stack>
						</Stack>
					</Stack>

					<Stack direction="row" width={1} columnGap={1.5} sx={{ marginTop: 3 }}>
						<Button data-testid="cancel-button-id" variant="outlined" sx={{ width: 1 }} onClick={onCancel}>
							<Tr.Portal path="analysis-result.cancel" />
						</Button>
						<Button
							data-testid="reject-button-id"
							variant="contained"
							sx={{ width: 1 }}
							disableElevation
							type="submit"
							onClick={() => {
								formik.setFieldValue('analysisError', !!checkRef.current?.checked);
							}}
						>
							<Tr.Portal path="analysis-result.save" />
						</Button>
					</Stack>
				</GenericCard>
			</form>
		</ModalDialog>
	);
};
