import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikTextfield, ModalDialog } from '@components/common';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RxUtils } from '@utils/Rx';
import { irAccessoryService } from '@services/ir';
import { notificationService } from '@services/core';

export interface IREditAccessoryDialogProps {
	deviceId: number;
	currentName: string;
	onClose: (success?: boolean) => void;
}

export const EditAccessoryDialog: FC<IREditAccessoryDialogProps> = ({ deviceId, currentName, onClose }) => {
	const { t } = useTranslation('irportal');
	const formik = useFormik<Extendable<{ name: string }>>({
		initialValues: {
			name: currentName,
		},
		validationSchema: yup.object({
			name: yup.string().trim().required(t('device-management.field-error.calibration-name')),
		}),
		initialTouched: {
			name: true,
		},
		onSubmit: ({ name }) => {
			RxUtils.promisify(
				irAccessoryService.editAccessory(deviceId, name),
				() => {
					onClose();
				},
				() => {
					notificationService.sendError('The name of accessory must be unique');
				},
			);
		},
	});

	return (
		<ModalDialog
			variant="primary"
			fullWidth={false}
			open={true}
			titleProps={{
				sx: {
					justifyContent: 'space-between',
					marginTop: 0,
					marginBottom: 5,
					minHeight: 30,
					backgroundColor: 'grey.50',
					paddingY: 1.2,
					paddingX: 2,
				},
			}}
			title={
				<Typography variant="body1" sx={{ fontWeight: 400, lineHeight: '200%' }}>
					{t('device-management.edit-accessory')}
				</Typography>
			}
			onClose={() => onClose()}
			maxWidth="sm"
			iconButtonProps={{ padding: 0 }}
			contentProps={{ sx: { width: 472, paddingBottom: 8 } }}
		>
			<Stack data-testid="edit-device-wrapper" width={1} direction="column" justifyContent="space-between">
				<form onSubmit={formik.handleSubmit} data-testid="editAccessoryDialogForm">
					<Stack paddingX={8}>
						<Stack sx={{ textAlign: 'center' }}>
							<Typography variant="h3"> {t('device-management.edit-accessory-name')}</Typography>
							<Typography variant="body2" sx={{ marginTop: '1rem', lineHeight: '160%' }}>
								{t('device-management.edit-accessory-title')}
							</Typography>
						</Stack>
						<FormikTextfield
							formikApi={formik}
							name="name"
							title={t('device-management.accessory-name')}
							required
							placeholder={t('device-management.calibration-name-placeholder')}
							containerProps={{ width: 1, marginTop: '1rem' }}
							inputProps={{ 'data-testid': 'device-name-id' }}
						/>
					</Stack>
					<Stack direction="row" width={1} paddingX={8} marginTop={4}>
						<Button
							data-testid="cancel-btn-id"
							variant="outlined"
							sx={{ marginRight: '1rem', width: '100%' }}
							onClick={() => onClose()}
						>
							<Tr.IrPortal path="device-management.cancel" />
						</Button>
						<Button
							data-testid="submit-button-id"
							type="submit"
							disabled={!formik.dirty}
							sx={{ width: '100%' }}
							variant="contained"
							disableElevation
						>
							<Tr.IrPortal path="Save" />
						</Button>
					</Stack>
				</form>
			</Stack>
		</ModalDialog>
	);
};
