import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';
import { LoadingLayout } from '@components/common';
import { notificationService, NotificationType } from '@services';

export interface IAuthCallbackPageProps {
	mode: 'login' | 'logout';
}

export const AuthCallbackPage: FC<IAuthCallbackPageProps> = ({ mode }) => {
	const navigate = useNavigate();
	const queryParameters = new URLSearchParams(window.location.search);

	getCurrentUser()
		.then(() => {
			mode === 'logout' && notificationService.send({ type: NotificationType.ERROR, title: 'Error', message: 'Not signed out' });
			navigate(`/${queryParameters.get('target')}`);
		})
		.catch(() => {
			mode === 'login' && notificationService.send({ type: NotificationType.ERROR, message: 'User is disabled' });
			navigate('/');
		});

	useEffect(() => {
		const timer = setTimeout(() => navigate('/'), 1500);
		return () => clearTimeout(timer);
	}, []);

	return <LoadingLayout />;
};
