import { FC, ReactNode } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as FrameInspect } from '@material-symbols/svg-600/outlined/frame_inspect.svg';
import OpenWithOutlined from '@mui/icons-material/OpenWithOutlined';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import FitScreenOutlined from '@mui/icons-material/FitScreenOutlined';
import CameraAlt from '@mui/icons-material/CameraAlt';
import DisplaySettings from '@mui/icons-material/DisplaySettings';

const ChartIconButton: FC<{ icon: ReactNode; targetElement: string; chartClass: string; dataTestId?: string }> = ({
	icon,
	targetElement,
	chartClass,
	dataTestId,
}) => (
	<IconButton
		data-testid={dataTestId}
		onClick={() => {
			(document.querySelectorAll(`.${chartClass}  a[data-title="${targetElement}"]`) as unknown as any[]).forEach((item) => {
				item.click();
			});
		}}
	>
		{icon}
	</IconButton>
);

interface IChartHeaderProps {
	titleVisibility: boolean;
	title: ReactNode;
	chartClass: string;
	displaySettingsToggle?: () => any;
}

export const IrChartHeader: FC<IChartHeaderProps> = ({ chartClass, title, titleVisibility, displaySettingsToggle }) => {
	return (
		<Stack direction="row" justifyContent="space-between" marginX="1rem" alignItems="center" data-testid="ir-chart-header">
			{titleVisibility && (
				<Typography sx={{ color: 'text.primary', margin: '24px', fontSize: '1.1rem' }} fontWeight={700}>
					{title}
				</Typography>
			)}
			<Box marginLeft="auto">
				<ChartIconButton
					chartClass={chartClass}
					targetElement="Zoom"
					icon={<FrameInspect width={24} height={24} fill="#5E5E72" />}
				/>
				<ChartIconButton chartClass={chartClass} targetElement="Download plot as a png" icon={<CameraAlt />} />
				<ChartIconButton chartClass={chartClass} targetElement="Pan" icon={<OpenWithOutlined />} />
				<ChartIconButton chartClass={chartClass} targetElement="Zoom in" icon={<AddBoxOutlined />} />
				<ChartIconButton chartClass={chartClass} targetElement="Zoom out" icon={<IndeterminateCheckBoxOutlined />} />
				<ChartIconButton chartClass={chartClass} targetElement="Autoscale" icon={<FitScreenOutlined />} />
				<IconButton onClick={() => displaySettingsToggle?.()}>
					<DisplaySettings />
				</IconButton>
			</Box>
		</Stack>
	);
};
