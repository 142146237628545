import { FC, useRef } from 'react';
import { NmrDrmOrder } from '@services/nmr-drm-pipeline';
import { NmrDrmOrderCard } from '../NmrPipelineProduction/NmrDrmOrderCard';
import { Card, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { cardSpacer, Scroll } from '@utils/Theme';

type TDrmPipelineCardGroupProps = {
	title: string;
	orders: NmrDrmOrder[];
	refreshNmrDrmOrders: () => void;
};
const EmptyCardGroupItem: FC = () => {
	const { t: translate } = useTranslation('admin');
	return (
		<Stack
			justifyContent="center"
			alignItems="center"
			height={1}
			sx={{
				backgroundColor: 'grey.100',
				padding: 2,
				marginRight: 0.5,
				minHeight: 200,
				height: 200,
				border: '1px dashed black',
				borderColor: 'grey.400',
				borderRadius: 2,
			}}
		>
			<Typography variant="pg-s" color="grey.800" lineHeight="180%">
				{translate('drm-pipeline.nmr.production.no-available-drm-orders')}
			</Typography>
		</Stack>
	);
};

type TCardCountBox = {
	title: string;
	count: number;
};
const CardCountBox: FC<TCardCountBox> = ({ title, count }) => {
	const { t } = useTranslation('admin');
	if (!count) {
		return null;
	}

	return (
		<Tooltip title={t('drm-pipeline.nmr.production.orders-in', { count: count, name: title })} placement="top">
			<Stack
				sx={{ padding: 0.75, marginRight: 1, border: '1px solid #A9A9BA', borderRadius: 1 }}
				justifyContent="center"
				alignItems="center"
			>
				<Typography variant="label-s" color="grey.800" lineHeight={0.8}>
					{count}
				</Typography>
			</Stack>
		</Tooltip>
	);
};
export const DrmPipelineCardGroup: FC<TDrmPipelineCardGroupProps> = ({ title, orders, refreshNmrDrmOrders }) => {
	const childRef = useRef<HTMLDivElement>(null);
	return (
		<Card
			variant="outlined"
			sx={{
				backgroundColor: 'grey.100',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				height: 'min-content',
				padding: '8px 4px 8px 8px',
			}}
		>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: cardSpacer }}>
				<Typography variant="h4" color="text.primary">
					{title}
				</Typography>
				<CardCountBox title={title} count={orders.length ?? 0} />
			</Stack>

			{orders.length > 0 && (
				<Stack
					direction="column"
					paddingRight={0.5}
					spacing={1.5}
					sx={{
						maxHeight: (childRef?.current?.offsetHeight ?? 200) * 2.5,
						minHeight: childRef?.current?.offsetHeight ?? 200,
						...Scroll.Y,
					}}
				>
					{orders.map((order) => (
						<NmrDrmOrderCard ref={childRef} key={`title_${order.id}`} order={order} refreshNmrDrmOrders={refreshNmrDrmOrders} />
					))}
				</Stack>
			)}
			{orders.length === 0 && <EmptyCardGroupItem />}
		</Card>
	);
};
