import { Grid, Typography } from '@mui/material';
import { ExtendedComponent } from 'src/types';

export const DrmSubtitle: ExtendedComponent<{ isActive?: boolean }> = ({ children, isActive = true }) => {
	return (
		<Grid item>
			<Typography variant="h5" color={isActive ? 'text.primary' : 'grey.500'}>
				{children}
			</Typography>
		</Grid>
	);
};
