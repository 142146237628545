export const brandCountryMapping = {
	millipore: ['US', 'CA'],
	vibrant: [],
};

export const MailMapping = {
	millipore: 'chemistwin@milliporesigma.com',
	merck: 'chemistwin@merckgroup.com',
};

export const isMillipore = (countryCode?: Maybe<string>) =>
	countryCode && brandCountryMapping.millipore.some((code) => code.toUpperCase() === countryCode.toUpperCase());

export const isGermany = (countryCode?: Maybe<string>) => countryCode && countryCode.toUpperCase() === 'DE';

export const getContactMailAddress = (countryCode: Maybe<string>) =>
	isMillipore(countryCode) ? 'chemistwin@milliporesigma.com' : 'chemistwin@merckgroup.com';
