/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormikApiType } from '@components/common';
import { IErrorResponse } from '@models/index';
import {
	alertService,
	INmrDeviceFileModel,
	NmrDevice,
	NmrDeviceCalibration,
	nmrDeviceService,
	notificationService,
	NmrReceiverGainThresholds,
	ICalibrationRange,
} from '@services';
import { ErrorCode } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { analyticsDeviceEvent } from '@utils/Analytics/device-events';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import { IFileStateType } from './DeviceFileHook';

export type IRecalibrationType = { calibrationData?: NmrDevice; id?: Maybe<number> };

interface ICalibrationHealthCheckType {
	deviceId: number;
	calibrationId: number;
	files: IFileStateType[];
	finalCb: () => void;
	navigate: NavigateFunction;
	t: TFunction;
	successCb: () => void;
}

interface ICalibrateDeviceType {
	deviceId: Maybe<number>;
	calibrationId?: number;
	files: IFileStateType[];
	successCb?: (deviceId: number) => void;
	finalCb: () => void;
	formik?: FormikApiType;
	receiverGainRange?: NmrReceiverGainThresholds[];
}

export const onCalibrationHealthCheck = ({
	deviceId,
	calibrationId,
	files,
	finalCb,
	navigate,
	t,
	successCb,
}: ICalibrationHealthCheckType) => {
	deviceId &&
		RxUtils.promisify(
			nmrDeviceService.calibrationCheck(deviceId, {
				calibrationId,
				solvent: files[0]?.solventName,
				file: { file: files[0]?.file, fileName: files[0]?.fileName, receiverGain: +files[0]?.receiverGain },
				comment: '',
			}),
			(val) => {
				val
					? (notificationService.sendSuccess(t('device-management.calibrated-successfully')), navigate(0))
					: alertService.send({
							content: t('device-management.recalibrate-needed'),
							titleText: t('device-management.alert.warning'),
							confirmTextComponent: t('device-management.calibrate'),
							onConfirm: () => navigate(0),
							hideCloseIcon: true,
						});
			},
			() => successCb(),
			() => finalCb(),
		);
};

export const onCalibrateDevice = ({
	files,
	successCb,
	deviceId,
	calibrationId,
	formik,
	finalCb,
	receiverGainRange,
}: ICalibrateDeviceType) => {
	const { deviceName, calibrationName } = formik?.values ?? {};
	const { frequency, probeId, solventName, manufacturer } = files[0] ?? { manufacturer: '' };
	const targetManufacturer = receiverGainRange?.find((item) =>
		manufacturer.toLocaleLowerCase().trim().includes(item.manufacturer?.toLocaleLowerCase().trim()),
	);
	const getRequestBody = (data: string[]) => {
		return {
			name: deviceId ? calibrationName?.trim() : deviceName?.trim(),
			calibrationName: calibrationName?.trim(),
			comment: '',
			solvent: solventName,
			calibrationId,
			deviceModelId: targetManufacturer?.id,
			frequency,
			probeId,
			files: data.map((_, index: number) => {
				return {
					file: files[`${index}`].file,
					fileName: files[`${index}`].fileName,
					receiverGain: files[`${index}`].receiverGain,
				};
			}) as unknown as INmrDeviceFileModel[],
		};
	};

	const handleError = (err: IErrorResponse) => {
		analyticsDeviceEvent.CalibrationError(deviceName);
		if (+err.ErrorCode === ErrorCode.DeviceNameAlreadyExistingException) {
			const [first, ...rest] = (formik?.values?.deviceName ?? '').split('_').reverse();
			let newName = '';
			if (TypeUtils.isNumber(first)) {
				newName = [+first + 1, ...rest].reverse().join('_');
			} else {
				newName = [1, first, ...rest].reverse().join('_');
			}
			formik?.setFieldValue('deviceName', newName);
		}
	};

	Promise.all(files.map((mapFile) => mapFile.file.text())).then((data) => {
		deviceId
			? RxUtils.promisify(
					nmrDeviceService.calibrate(getRequestBody(data), deviceId),
					() => {
						analyticsDeviceEvent.RecalibrateDevice(deviceName);
						successCb?.(deviceId);
					},
					(err) => handleError(err),
				)
			: RxUtils.promisify(
					nmrDeviceService.createDevice(getRequestBody(data)),
					(device: NmrDevice) => {
						analyticsDeviceEvent.AddDeviceOrCalibrate(deviceName);
						successCb?.(device.id);
					},
					(err) => handleError(err),
					finalCb,
				);
	});
};

export const manipulatedReceiverThreshold = (
	healthCheckCalibration: Maybe<NmrDeviceCalibration>,
	file: IFileStateType,
	receiverGainRange: Maybe<NmrReceiverGainThresholds[]>,
) => {
	const receiverGainFor = healthCheckCalibration ? 'calibrationCheck' : 'calibration';
	const deviceName = file.manufacturer.split(' ')[0].trim().toLowerCase();
	const receiverRanges: NmrReceiverGainThresholds =
		receiverGainRange?.reduce<NmrReceiverGainThresholds>((first, second) => {
			return {
				...first,
				[second.manufacturer.toLowerCase()]: { calibration: second.calibration, calibrationCheck: second.calibrationCheck },
			};
		}, new NmrReceiverGainThresholds()) ?? new NmrReceiverGainThresholds();

	return TypeUtils.returnValueOfKeys<typeof receiverRanges, ICalibrationRange[]>(
		[deviceName as keyof typeof receiverRanges, receiverGainFor as keyof typeof receiverRanges],
		receiverRanges ?? {},
	);
};

export const findMissingSmallestInteger = (arr: (string | number)[]) => {
	let smallest = 1;
	const sortedArr = arr.sort((a, b) => (a as number) - (b as number));
	for (let i = 0; i < sortedArr.length; i++) {
		if (Number(sortedArr[`${i}`]) > smallest) return smallest;
		else if (Number(sortedArr[`${i}`]) === smallest) smallest++;
	}
	return smallest;
};

export const unknownToFile: (data: unknown[] | undefined) => File[] = (data: unknown[] | undefined) => {
	if (data) {
		const arrayOfFiles: File[] = data.filter((item): item is File => item instanceof File);
		return arrayOfFiles;
	}

	return [];
};
