export enum ErrorCode {
	OtherErrorCode = 0,

	OrganizationNotFoundExceptionCode = 104,
	OrganizationAlreadyInactiveExceptionCode = 105,
	UnsuppliedOrganizationExceptionCode = 106,
	MaximumPageSizeExceedExceptionCode = 107,
	CommandIsNullException = 108,
	OrganizationAlreadyActiveExceptionCode = 109,
	OrganizationAlreadyExistExceptionCode = 110,
	OrganizationIsInactiveExceptionCode = 111,

	AnalysisFileSizeExceedExceptionCode = 401,
	UnsupportedAnalysisFileTypeExceptionCode = 402,
	AnalysisNotFoundExceptionCode = 403,
	DeletionOfAnalysisNotAllowedExceptionCode = 404,
	AnalysisFileNameExceptionCode = 405,
	AnalysisFileNotFoundExceptionCode = 406,

	SubstanceAlreadyExiston = 409,

	InternalServiceError = 500,
	ReferenceMaterialNotFoundExceptionCode = 501,
	DrmAlreadyAddedExceptionCode = 502,
	DrmAlreadyNotFoundExceptionCode = 503,
	CloudFrontTimeout = 504,
	NmrReferenceMaterialCreationExceptionCode = 505,

	CognitoServiceExceptionCode = 601,
	CognitoUserAlreadyExistException = 602,

	FavoriteSubstanceNotFoundExceptionCode = 701,
	FavoriteSubstanceNameAlreadyExistExceptionCode = 702,
	FavoriteReferenceNameSizeExceedExceptionCode = 703,
	FavoriteSubstanceMaximumSubstanceCountExceededExceptionCode = 704,
	FavoriteSubstanceAlreadyExistsExceptionCode = 705,

	UserNotFoundExceptionCode = 801,
	NoActiveLabManagerException = 802,
	NoActiveOrgAdminExceptionCode = 803,

	SubscriptionNotFoundExceptionCode = 901,
	ReachedToTheLimitOfTheSubscriptionException = 903,
	FreeSubscriptionAlreadyExistsExceptionCode = 904,
	SubscriptionIsInactiveExceptionCode = 905,

	DeviceNameAlreadyExistingException = 10001,
	DeviceProbeIdAlreadyExistingException = 10002,
	DeviceNotFoundExceptionCode = 10003,
	DeviceCalibrationNotFoundExceptionCode = 10004,
	DeviceCalibrationFileNotFoundExceptionCode = 10005,
	CalibrationNameAlreadyExistException = 10006,
	DeviceManufacturerInvalidException = 10007,

	AcdLabsAnalysisExecutionUnsuccessfulExceptionCode = 11001,
	CreateErmAcdLabsIntegrationException = 11002,

	RSquareAboveTheThresholdExceptionCode = 12001,
	SignalToNoiseAboveTheThresholdExceptionCode = 12002,
	DeviceCalibrationConnectionExceptionCode = 12003,
	MoleculeNotCorrespondingExceptionCode = 12004,
	MoleculeNotCorrespondingLowMatchFactorExceptionCode = 12005,

	AnalysisReviewLabAdminApproveOwnAnalysisException = 13002,

	ErmFileUploadExceptionCode = 14004,
	UnsupportedReferenceMaterialFileTypeExceptionCode = 14005,
	ReferenceMaterialAlreadyExistsExceptionCode = 14006,
	ReferenceMaterialWrongTechnologyExceptionCode = 14007,
	ReferenceMaterialAlreadyRequestedExceptionCode = 14008,
	ReferenceMaterialAlreadyReleasedExceptionCode = 14009,
	ReferenceMaterialRequestInvalidStatusException = 14010,

	UnSupportedManufacturer = 17001,

	QueueServiceExceptionCode = 18001,

	TheoreticalSpectrumConflictExceptionCode = 24003,

	SubstanceAlreadyExistsExceptionCode = 26001,
	SubstanceNotFoundExceptionCode = 26002,
	SubstanceMissingFromPubchemExceptionCode = 26003,

	LaboratoryNotFoundExceptionCode = 28001,
	LaboratoryAlreadyExistsExceptionCode = 28002,
	UserIsInactiveExceptionCode = 28003,
	LaboratoryAlreadyInactiveExceptionCode = 28004,
	LaboratoryAlreadyActiveExceptionCode = 28005,
	UnsuppliedLaboratoryExceptionCode = 28006,
	LaboratoryAlreadyExistExceptionCode = 28007,
	LaboratoryIsInactiveExceptionCode = 28008,

	AttemptedRoleOperationNotValidExceptionCode = 29001,
	UserIsNotInGivenOrganizationExceptionCode = 29003,
	UserIsAlreadyInGivenLaboratoryExceptionCode = 29004,
	UserNotInLaboratoryExceptionCode = 29005,
	LabAdminAlreadyExistExceptionCode = 29006,
	OrgAdminAlreadyExistExceptionCode = 29007,

	NumberOfAnalysisNotValidExceptionCode = 30001,

	AnalysisReviewInvalidStageExceptionCode = 100001,
	AnalysisReviewLabAdminApproveOwnAnalysisExceptionCode = 100002,
	SubscriptionAlreadyExistsExceptionCode = 100003,
	ReachedToTheLimitOfTheSubscriptionExceptionCode = 100004,
	TechnologyPricingHigherThanBudgetExceptionCode = 100005,
	UserCannotBeBothAdminAndLabMemberExceptionCode = 100006,

	DrmWaveAlreadyExists = 31003,
	DrmWaveDeletionNotAllowed = 31004,

	PhysicalProductAlreadyExists = 33001,

	LibraryNameAlreadyExists = 35000,
}
export enum SpecialError {
	NetworkError = 'Network Error',
	OnlineError = 'OnlineError',
}

export const ERROR_CODE_TRANSLATION_KEY: Record<ErrorCode & SpecialError, string> = {
	[ErrorCode.OtherErrorCode]: 'error-codes.other-error-code',

	[ErrorCode.OrganizationNotFoundExceptionCode]: 'error-codes.organization-not-found-exception',
	[ErrorCode.OrganizationAlreadyInactiveExceptionCode]: 'error-codes.organization-already-inactive-exception',
	[ErrorCode.UnsuppliedOrganizationExceptionCode]: 'error-codes.unsupplied-organization-exception',
	[ErrorCode.MaximumPageSizeExceedExceptionCode]: 'error-codes.maxiumum-page-size-exceed-exception',
	[ErrorCode.CommandIsNullException]: 'error-codes.command-is-null-exception',
	[ErrorCode.OrganizationAlreadyActiveExceptionCode]: 'error-codes.organization-already-active-exception',
	[ErrorCode.OrganizationAlreadyExistExceptionCode]: 'error-codes.organization-already-exist-exception',
	[ErrorCode.OrganizationIsInactiveExceptionCode]: 'error-codes.organization-is-inactive-exception',

	[ErrorCode.AnalysisFileSizeExceedExceptionCode]: 'error-codes.analysis-file-size-exceed-exception',
	[ErrorCode.UnsupportedAnalysisFileTypeExceptionCode]: 'error-codes.unsupported-analysis-file-type-exception',
	[ErrorCode.AnalysisNotFoundExceptionCode]: 'error-codes.analysis-not-found-exception',
	[ErrorCode.DeletionOfAnalysisNotAllowedExceptionCode]: 'error-codes.deletion-of-analysis-not-allowed-exception',
	[ErrorCode.AnalysisFileNameExceptionCode]: 'error-codes.analysis-file-name-exception',
	[ErrorCode.AnalysisFileNotFoundExceptionCode]: 'error-codes.analysis-file-not-found-exception',

	[ErrorCode.InternalServiceError]: 'error-codes.other-error-code',
	[ErrorCode.ReferenceMaterialNotFoundExceptionCode]: 'error-codes.reference-material-not-found-exception',
	[ErrorCode.DrmAlreadyAddedExceptionCode]: 'error-codes.drm-already-added-exception',
	[ErrorCode.DrmAlreadyNotFoundExceptionCode]: 'error-codes.drm-already-not-found-exception',
	[ErrorCode.CloudFrontTimeout]: 'error-codes.cloudfront-timeout',

	[ErrorCode.CognitoServiceExceptionCode]: 'error-codes.cognito-service-exception',
	[ErrorCode.CognitoUserAlreadyExistException]: 'error-codes.cognito-user-already-exist-exception',

	[ErrorCode.FavoriteSubstanceNotFoundExceptionCode]: 'error-codes.favorite-substance-not-found-exception',
	[ErrorCode.FavoriteSubstanceNameAlreadyExistExceptionCode]: 'error-codes.favorite-substance-name-already-exists-exception',
	[ErrorCode.FavoriteReferenceNameSizeExceedExceptionCode]: 'error-codes.favorite-substance-name-size-exceed-exception',
	[ErrorCode.FavoriteSubstanceMaximumSubstanceCountExceededExceptionCode]:
		'error-codes.favorite-substance-maximum-substance-count-exceed-exception',
	[ErrorCode.FavoriteSubstanceAlreadyExistsExceptionCode]: 'error-codes.favorite-substance-already-exists-exception',

	[ErrorCode.UserNotFoundExceptionCode]: 'error-codes.user-not-found-exception',
	[ErrorCode.NoActiveLabManagerException]: 'error-codes.no-active-lab-manager-exception',
	[ErrorCode.NoActiveOrgAdminExceptionCode]: 'error-codes.no-active-org-admin-exception',

	[ErrorCode.SubscriptionNotFoundExceptionCode]: 'error-codes.subscription-not-found-exception',
	[ErrorCode.ReachedToTheLimitOfTheSubscriptionException]: 'error-codes.reached-to-the-limit-of-the-subscription-exception',
	[ErrorCode.FreeSubscriptionAlreadyExistsExceptionCode]: 'error-codes.free-subscription-already-exists-exception',
	[ErrorCode.SubscriptionIsInactiveExceptionCode]: 'error-codes.subscription-is-inactive-exception',

	[ErrorCode.DeviceNameAlreadyExistingException]: 'error-codes.device-already-exist-exception',
	[ErrorCode.DeviceProbeIdAlreadyExistingException]: 'error-codes.device-prob-id-already-exist-exception',
	[ErrorCode.DeviceNotFoundExceptionCode]: 'error-codes.device-not-found-exception',
	[ErrorCode.DeviceCalibrationFileNotFoundExceptionCode]: 'error-codes.device-calibration-file-not-found-exception',
	[ErrorCode.DeviceCalibrationNotFoundExceptionCode]: 'error-codes.device-calibration-not-found-exception',
	[ErrorCode.CalibrationNameAlreadyExistException]: 'error-codes.calibration-already-exist-exception',
	[ErrorCode.DeviceManufacturerInvalidException]: 'error-codes.device-manufacturer-invalid-exception',

	[ErrorCode.AcdLabsAnalysisExecutionUnsuccessfulExceptionCode]: 'error-codes.acd-labs-analysis-execution-unsuccessful-exception',
	[ErrorCode.CreateErmAcdLabsIntegrationException]: 'error-codes.create-erm-acdlabs-integration-exception',

	[ErrorCode.RSquareAboveTheThresholdExceptionCode]: 'error-codes.rsquare-above-the-threshold-exception',
	[ErrorCode.SignalToNoiseAboveTheThresholdExceptionCode]: 'error-codes.signal-to-noise-above-the-threshold-exception',
	[ErrorCode.DeviceCalibrationConnectionExceptionCode]: 'error-codes.device-calibration-connection-exception',
	[ErrorCode.MoleculeNotCorrespondingExceptionCode]: 'error-codes.molecule-not-corresponding-exception',
	[ErrorCode.MoleculeNotCorrespondingLowMatchFactorExceptionCode]: 'error-codes.molecule-not-corresponding-low-match-factor-exception',

	[ErrorCode.AnalysisReviewLabAdminApproveOwnAnalysisException]: 'error-codes.analysis-review-labadmin-approve-own-analysis-exception',

	[ErrorCode.ErmFileUploadExceptionCode]: 'error-codes.erm-file-upload-exception',
	[ErrorCode.UnsupportedReferenceMaterialFileTypeExceptionCode]: 'error-codes.unsupported-reference-material-file-type-exception',
	[ErrorCode.ReferenceMaterialAlreadyExistsExceptionCode]: 'error-codes.reference-material-already-exists-exception',
	[ErrorCode.ReferenceMaterialWrongTechnologyExceptionCode]: 'error-codes.reference-material-wrong-technology-exception',
	[ErrorCode.ReferenceMaterialAlreadyRequestedExceptionCode]: 'error-codes.reference-material-already-requested-exception',
	[ErrorCode.ReferenceMaterialAlreadyReleasedExceptionCode]: 'error-codes.reference-material-already-released-exception',
	[ErrorCode.ReferenceMaterialRequestInvalidStatusException]: 'error-codes.reference-material-request-invalid-status-exception',

	[SpecialError.OnlineError]: 'error-codes.online-error',
	[SpecialError.NetworkError]: 'error-codes.other-error-code',

	[ErrorCode.QueueServiceExceptionCode]: 'error-codes.queue-service-exception',

	[ErrorCode.UnSupportedManufacturer]: 'error-codes.unsupported-manufacturer',

	[ErrorCode.TheoreticalSpectrumConflictExceptionCode]: 'error-codes.theoretical-spectrum-conflict',

	[ErrorCode.SubstanceAlreadyExistsExceptionCode]: 'error-codes.substance-already-exist-exception',
	[ErrorCode.SubstanceNotFoundExceptionCode]: 'error-codes.substance-not-found-exception',
	[ErrorCode.SubstanceMissingFromPubchemExceptionCode]: 'error-codes.substance-not-found-pubchem-exception',

	[ErrorCode.LaboratoryNotFoundExceptionCode]: 'error-codes.laboratory-not-found-exception',
	[ErrorCode.LaboratoryAlreadyExistsExceptionCode]: 'error-codes.laboratory-already-existing-exception',
	[ErrorCode.UserIsInactiveExceptionCode]: 'error-codes.user-is-inactive-exception',
	[ErrorCode.LaboratoryAlreadyInactiveExceptionCode]: 'error-codes.laboratory-already-inactivate-exception',
	[ErrorCode.LaboratoryAlreadyActiveExceptionCode]: 'error-codes.laboratory-already-active-exception',
	[ErrorCode.UnsuppliedLaboratoryExceptionCode]: 'error-codes.unsupplied-laboratory-exception',
	[ErrorCode.LaboratoryAlreadyExistExceptionCode]: 'error-codes.laboratory-already-exist-exception',
	[ErrorCode.LaboratoryIsInactiveExceptionCode]: 'error-codes.laboratory-is-inactive-exception',

	[ErrorCode.AttemptedRoleOperationNotValidExceptionCode]: 'error-codes.attempted-role-operation-not-valid-exception',
	[ErrorCode.UserIsNotInGivenOrganizationExceptionCode]: 'error-codes.user-is-not-in-given-organization-exception',
	[ErrorCode.UserIsAlreadyInGivenLaboratoryExceptionCode]: 'error-codes.user-is-already-in-given-laboratory-exception',
	[ErrorCode.UserNotInLaboratoryExceptionCode]: 'error-codes.user-is-not-in-laboratory-exception',
	[ErrorCode.LabAdminAlreadyExistExceptionCode]: 'error-codes.lab-admin-already-exist-exception',
	[ErrorCode.OrgAdminAlreadyExistExceptionCode]: 'error-codes.org-admin-already-exist-exception',

	[ErrorCode.NumberOfAnalysisNotValidExceptionCode]: 'error-codes.number-of-analysis-is-not-valid-exception',

	[ErrorCode.AnalysisReviewInvalidStageExceptionCode]: 'error-codes.analysis-review-invalid-stage-exception',
	[ErrorCode.AnalysisReviewLabAdminApproveOwnAnalysisExceptionCode]:
		'error-codes.analysis-review-lab-admin-approve-own-analysis-exception',
	[ErrorCode.SubscriptionAlreadyExistsExceptionCode]: 'error-codes.subscription-already-exists-exception',
	[ErrorCode.ReachedToTheLimitOfTheSubscriptionExceptionCode]: 'error-codes.reached-to-the-limit-of-the-subscription-exception',
	[ErrorCode.TechnologyPricingHigherThanBudgetExceptionCode]: 'error-codes.technology-pricing-higher-than-budget-exception',
	[ErrorCode.UserCannotBeBothAdminAndLabMemberExceptionCode]: 'error-codes.user-cannot-be-both-admin-and-lab-member-exception',

	[ErrorCode.DrmWaveAlreadyExists]: 'error-codes.drm-wave-already-exists',
	[ErrorCode.DrmWaveDeletionNotAllowed]: 'error-codes.drm-wave-deletion-not-allowed',

	[ErrorCode.PhysicalProductAlreadyExists]: 'error-codes.physical-product-already-exists',

	[ErrorCode.LibraryNameAlreadyExists]: 'error-codes.library-name-already-exists',
};
