import { FC, ReactNode } from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { OverflowText } from '@components/common';
import { DateUtils, Tr } from '@utils';

export interface IPortalLayoutTitleProps extends Omit<StackProps, 'title'> {
	title?: ReactNode;
	savedTime?: Date;
	id?: string;
}

export const PortalLayoutTitle: FC<IPortalLayoutTitleProps> = ({ title, sx, savedTime, children, id, ...props }) => {
	return (
		<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ ...sx }} {...props}>
			<Stack sx={{ width: '100%', maxWidth: 'calc(100% - 450px)' }}>
				{title ? (
					<OverflowText variant="h1" sx={{ color: 'text.secondary' }}>
						{title}
					</OverflowText>
				) : null}
				<div style={{ display: 'inline-flex' }}>
					{id && (
						<Typography color="primary.secondary" variant="pg-m" fontWeight="500">
							<Tr.Portal path="new-analysis.analysis-id" /> <span style={{ color: '#5E5E72' }}>{id}</span>
						</Typography>
					)}
					{savedTime && (
						<>
							<div style={{ height: '26px', border: '1px solid #C7C7D1', margin: '0px 10px' }}></div>
							<Typography color="primary.secondary" variant="pg-m" fontWeight="500">
								<Tr.Portal path="new-analysis.saved" /> -
								<span style={{ color: '#5E5E72' }}>{DateUtils.getFormattedDate(savedTime, 'yyyy-MM-dd HH:mm:ss')} </span>
							</Typography>
						</>
					)}
				</div>
			</Stack>
			{children}
		</Stack>
	);
};
