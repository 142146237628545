import { NmrCalibrationStatus, NmrDeviceCalibration } from '@services/nmr';
import { FC } from 'react';
import { getCalibrationStatusColors } from '../NmrDeviceInfo/device-calibration-colors';
import { Stack, Typography } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Error from '@mui/icons-material/Error';
import { isBefore } from 'date-fns';
import { CalibrationActionMenu } from './CalibrationActionMenu';
import { NmrDeviceInfo } from '../NmrDeviceInfo';
import { Tr } from '@utils/Translation';
export const DeviceCalibration: FC<{
	calibration: NmrDeviceCalibration;
	isDeviceActive: boolean;
	isCalibrationActive: boolean;
	refresh: () => void;
}> = ({ calibration, isDeviceActive, isCalibrationActive, refresh }) => {
	const isCalibrationNeededOrError =
		calibration?.status === NmrCalibrationStatus.CALIBRATION_NEEDED || calibration?.status === NmrCalibrationStatus.CALIBRATION_ERROR;
	const { nextCalibrationTime, lastCalibrationTime } = calibration;
	const differenceIsOneMonth = nextCalibrationTime && lastCalibrationTime ? isBefore(nextCalibrationTime, Date.now()) : false;
	const calibrationCheckNeeded = differenceIsOneMonth || calibration?.status === NmrCalibrationStatus.CALIBRATION_CHECK_NEEDED;

	const colors = getCalibrationStatusColors(isDeviceActive && isCalibrationActive, isCalibrationNeededOrError || calibrationCheckNeeded);

	return (
		<Stack direction="column" marginBottom={3}>
			<Stack direction="column" paddingX={3} paddingY={2} sx={{ backgroundColor: colors.backgroundColor }} borderRadius={1}>
				<Stack direction="row" justifyContent="space-between" borderBottom="1px solid #E1E1EA" paddingBottom={2}>
					<Stack direction="row">
						{isCalibrationNeededOrError || calibrationCheckNeeded ? (
							<Error color="error" sx={{ marginRight: 1, marginTop: 0.2, height: 20 }} />
						) : (
							<CheckCircle sx={{ marginRight: 1, marginTop: 0.2, color: colors.primary, height: 20 }} />
						)}

						<Typography variant="h5" sx={{ wordBreak: 'break-all', textAlign: 'center' }} color={colors.primary}>
							{calibration.name}
						</Typography>
					</Stack>
					<Stack direction="row" alignItems="center">
						{isDeviceActive && (
							<CalibrationActionMenu calibrationData={calibration} refresh={refresh} menuColor={colors.primary} />
						)}
					</Stack>
				</Stack>

				<Stack direction="row">
					<NmrDeviceInfo recalibrationData={calibration} isDeviceActive={isDeviceActive} />
				</Stack>
			</Stack>

			{isCalibrationNeededOrError && (
				<Typography variant="label-s" color="error.main" sx={{ marginY: '1rem' }}>
					{calibration.calibrationErrorMessage || <Tr.Portal path="device-management.recalibration-needed" />}
				</Typography>
			)}
			{!isCalibrationNeededOrError && calibrationCheckNeeded && (
				<Typography variant="label-s" color="error.main" sx={{ marginY: '1rem' }}>
					<Tr.Portal path="device-management.calibration-overdue-message" />
				</Typography>
			)}
		</Stack>
	);
};
