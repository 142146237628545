import { concatMap, map, tap } from 'rxjs';
import { httpService, logger } from '@services/core';
import { nmrConfig } from '../nmrConfig';
import { RxUtils, TypeUtils } from '@utils';
import { PagedResult, PatchType } from '@models/request-response';
import {
	NmrReceiverGainThresholds,
	NmrDeviceNamesResponse,
	NmrDeviceCalibration,
	NmrDeviceCalibrationForList,
	INmrDeviceHealthCheckRequestModel,
	INmrDeviceRequestModel,
	NmrDevice,
} from './index';

class NmrDeviceService {
	private getURL() {
		return `${nmrConfig.url}/device`;
	}

	public getAllDeviceCalibrations(params: URLSearchParams) {
		return httpService
			.get<PagedResult<NmrDeviceCalibrationForList[]>>(this.getURL(), '', { params: params, errorHandler: 'notification' })
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllDeviceCalibrations.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) =>
					TypeUtils.transformFromExist(new PagedResult<NmrDeviceCalibrationForList>(NmrDeviceCalibrationForList), result),
				),
			);
	}

	public getDevice(id: number, disableLoader?: boolean) {
		return httpService.get<NmrDevice>(this.getURL(), `${id}`, { errorHandler: 'notification', disableLoader: !!disableLoader }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getDevice.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrDevice, result)),
		);
	}

	public getDeviceByProbeId(probeId: string) {
		return httpService.get<NmrDevice>(this.getURL(), `probe/${encodeURIComponent(probeId)}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getDeviceByProbeId.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrDevice, result)),
		);
	}

	public getDeviceNames() {
		return httpService
			.get<NmrDeviceNamesResponse>(this.getURL(), `deviceNames`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.getDeviceNames.name}]`, result)));
	}

	public createDevice(body: INmrDeviceRequestModel) {
		const formData = new FormData();
		formData.append('name', body.name);
		formData.append('calibrationName', body.calibrationName);
		body.deviceModelId && formData.append('deviceModelId', `${body.deviceModelId}`);
		body.frequency && formData.append('frequency', body.frequency);
		body.probeId && formData.append('probeId', body.probeId);
		body.solvent && formData.append('solvent', body.solvent);

		body.files.forEach((file, index) => {
			formData.append(`file${index + 1}.file`, file.file);
			formData.append(`file${index + 1}.receiverGain`, `${file.receiverGain}`);
		});

		return httpService.post<NmrDevice>(this.getURL(), '', { body: formData, disableLoader: true, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.createDevice.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	public calibrate(body: INmrDeviceRequestModel, deviceId: number) {
		const formData = new FormData();
		formData.append('name', body.name);
		body.solvent && formData.append('solvent', body.solvent);
		body.calibrationId && formData.append('calibrationId', `${body.calibrationId}`);

		body.files.forEach((file, index) => {
			formData.append(`file${index + 1}.file`, file.file);
			formData.append(`file${index + 1}.receiverGain`, `${file.receiverGain}`);
		});
		return httpService
			.post<NmrDevice>(this.getURL(), `${deviceId}/calibrations`, {
				body: formData,
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.calibrate.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(NmrDeviceCalibration, result)),
			);
	}

	public calibrationCheck(deviceId: number, body: INmrDeviceHealthCheckRequestModel) {
		const formData = new FormData();
		formData.append('calibrationId', ` ${body.calibrationId}`);
		body.solvent && formData.append('solvent', body.solvent);
		formData.append('file.file', body.file.file);
		formData.append('file.receiverGain', `${body.file.receiverGain}`);

		return httpService
			.post<boolean>(this.getURL(), `${deviceId}/calibrations/${body.calibrationId}/check`, {
				body: formData,
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.calibrationCheck.name}]`, result)));
	}

	public deleteCalibration(deviceId: number, calibrationId: number) {
		return httpService
			.delete(this.getURL(), `${deviceId}/calibrations/${calibrationId}/delete`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.deleteCalibration.name}]`, result)));
	}

	public receiverGainThreshold() {
		return httpService.get<NmrReceiverGainThresholds[]>(this.getURL(), `receiverGains`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.receiverGainThreshold.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	public renameDevice(id: number, name: string) {
		return httpService.patch(this.getURL(), `${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Name',
					value: name,
				},
			],
			errorHandler: 'notification',
		});
	}

	public renameCalibration(id: number, name: string) {
		return httpService.patch(this.getURL(), `calibrations/${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Name',
					value: name,
				},
			],
			errorHandler: 'notification',
		});
	}

	public changeDeviceStatus(id: number, status: boolean) {
		return httpService.patch(this.getURL(), `${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Status',
					value: status,
				},
			],
			errorHandler: 'notification',
		});
	}

	public changeCalibrationStatus(id: number, status: boolean) {
		return httpService.patch(this.getURL(), `calibrations/${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Status',
					value: status,
				},
			],
			errorHandler: 'notification',
		});
	}
}

export const nmrDeviceService = new NmrDeviceService();
