import { FC, useCallback } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { loadingSpinnerOverlayService, notificationService, NotificationType } from '@services';
import { FileValidationStatus, IUploadedFile } from '@models';
import { FileUtils, nameLengthValidator, nmrFileValidator, Tr } from '@utils';
import { ReactComponent as CloudUpload } from '@material-symbols/svg-600/outlined/cloud_upload-fill.svg';
import { Box, Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SpectrumChart } from '@components/common';

const MAX_FILE_SIZE = 209715200;

export interface ISampleFileUploaderProps {
	analysisHasFile?: boolean;
	onUploadedFileChange?: (f?: IUploadedFile) => void;
	uploadedFile: any;
	title: string;
	isDisabled?: boolean;
}

export const NmrSampleFileUploader: FC<ISampleFileUploaderProps> = ({
	analysisHasFile,
	onUploadedFileChange,
	uploadedFile,
	isDisabled = false,
	title,
}) => {
	const { t } = useTranslation('portal');

	const ErrorContentMapping: Record<FileValidationStatus, string> = {
		[FileValidationStatus.INVALID]: t('new-analysis.file-type-error'),
		[FileValidationStatus.MAX_SIZE]: t('new-analysis.file-size-error'),
		[FileValidationStatus.NAME_LARGE]: t('new-analysis.file-length-error'),
		[FileValidationStatus.INVALID_DATA_TYPE]: t('new-analysis.file-data-type-error'),
	};

	const onDrop = useCallback<Required<DropzoneOptions>['onDrop']>(([file]) => {
		if (file) {
			loadingSpinnerOverlayService.increment();
			file.text()
				.then((content) => ({
					file,
					content,
					ext: FileUtils.getExtension(file),
					parsedData: FileUtils.parseContent(file, content),
				}))
				.catch(() => undefined)
				.then(onUploadedFileChange)
				.finally(() => loadingSpinnerOverlayService.decrement());
		}
	}, []);

	const onDropRejected = ([{ errors }]: FileRejection[]) => {
		notificationService.send({
			type: NotificationType.ERROR,
			message: ErrorContentMapping[errors[0].code],
		});
	};

	const onDropActivated = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
		nmrFileValidator(acceptedFiles[0]).then((val) => {
			if (val) {
				notificationService.send({
					type: NotificationType.ERROR,
					message: ErrorContentMapping[val.code],
				});
			} else {
				onDrop(acceptedFiles, fileRejections, event);
			}
		});
	};

	const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
		onDrop: onDropActivated,
		multiple: false,
		accept: {
			'chemical/x-jcamp-dx': ['.jdx', '.dx'],
			'application/vnd.cip4-jdf+xml': ['.jdf'],
			'application/x-zip-compressed': ['.zip'],
		},
		useFsAccessApi: false,
		maxSize: MAX_FILE_SIZE,
		validator: nameLengthValidator,
		onDropRejected,
		disabled: isDisabled,
	});
	const getBorderColor = () => {
		if (isDragActive) {
			return 'grey.700';
		} else {
			return '#C7C7D1';
		}
	};

	const getTypographyColor = () => {
		if (!isDisabled) {
			return 'grey.800';
		}
		return 'grey.200';
	};

	const getIconColor = () => {
		if (!isDisabled) {
			return '#5E5E72';
		}
		return '#E1E1EA';
	};

	return (
		<Paper
			{...(analysisHasFile ? {} : getRootProps())}
			elevation={0}
			square
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: 218,
				padding: analysisHasFile ? 1 : 2,
				border: analysisHasFile ? '1.5px solid' : '2px dashed',
				borderRadius: '8px',
				borderColor: getBorderColor(),
				backgroundColor: analysisHasFile ? undefined : 'grey.50',
			}}
		>
			<Stack direction="column" alignItems="center" justifyContent="center" flexGrow={1}>
				{analysisHasFile ? (
					<Box sx={{ position: 'relative', width: 1.0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<IconButton
							data-testid="sample-file-icon-button-id"
							sx={{ position: 'absolute', bottom: 4, right: 8, color: 'primary.main' }}
							onClick={() => fileRejections.length === 0 && onUploadedFileChange?.(undefined)}
						/>
						<SpectrumChart axisTitle="Chemical shift (ppm)" file={uploadedFile} title={title} uniqueId="file-uploader-chart" />
					</Box>
				) : (
					<>
						{/* TODO: Use FileUploader component here  */}
						{!isDisabled && <input data-testid="file-upload-id" {...getInputProps()} />}

						<Stack spacing={3} alignItems="center">
							<CloudUpload width={40} height={40} fill={getIconColor()} />
							<Typography variant="pg-m" textAlign="center" color={getTypographyColor()}>
								<Tr.Portal path="new-analysis.drag-and-drop-file-here" />
							</Typography>

							<Button variant="contained" disabled={isDisabled} sx={{ width: 116, height: 40, paddingX: 1.5 }}>
								<Tr.Portal path="new-analysis.choose-file" />
							</Button>
						</Stack>
					</>
				)}
			</Stack>
		</Paper>
	);
};
