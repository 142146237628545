import { Type } from 'class-transformer';
import { SupportedTechnology } from '../nmr-analysis/technology.enum';
import { QualityGrade } from '@models/reference-material';

export class NmrDRM {
	id: number;
	name: string;

	@Type(() => Date)
	systemCreationDate: Date;

	createdBy: string;

	@Type(() => Date)
	lastUpdateTime: Date;

	lastUpdatedBy: string;
	merckId: string;
	linearFormula: string;
	solvent: string;
	molecularWeight: number;
	casNumber: string;
	synonyms: string;
	frequency: string;
	numberOfScans: number;
	substance: string;
	ermProductNumber: string;
	technology: SupportedTechnology;
	ph: string;
	version: string;
	isActive: boolean;
	smilesCode?: string;
	qualityGrade: QualityGrade;
	favoriteId: number;
	status?: string;
	library?: string;
	referenceMaterialName?: string;
	drmCode?: string;
	nmrReferenceMaterialLibrary?: string;

	@Type(() => Date)
	createTime?: Date;

	isTheoretical() {
		return QualityGrade.THEORETICAL === this.qualityGrade;
	}

	isDAS() {
		return QualityGrade.DAS === this.qualityGrade;
	}

	isDRM() {
		return QualityGrade.DRM === this.qualityGrade;
	}

	isFavorite() {
		return !!this.favoriteId;
	}
}

export interface INmrDrmSearchModel {
	query: string;
	pageIndex: number;
	pageSize: number;
	sortBy?: string;
	sortOrder?: string;
	filteringData: unknown[];
}
