export const getPeakHighligtShapes = (data): any => {
	const shapesArray: any = [];
	const peakData = data || [];
	peakData.map((item, index) => {
		shapesArray.push({
			type: 'rect',
			xref: 'x',
			// y-reference is assigned to the plot paper [0,1]
			yref: 'paper',
			x0: item?.peakMin,
			y0: 0,
			x1: item?.peakMax,
			y1: 1,
			fillcolor: 'rgb(220,240,231)',
			opacity: 0.7,
			line: {
				width: 1,
				color: 'rgb(65,175,126)',
			},
			label: {
				text: peakData.length > 1 ? `Peak ${index + 1}` : `Peak ${peakData.length - index}`,
				textposition: 'top center',
				yanchor: 'bottom',
			},
		});
	});

	return shapesArray;
};
