import { FC, useCallback } from 'react';
import { Accept, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { notificationService, NotificationType } from '@services';
import { FileValidationStatus } from '@models';
import { nameLengthValidator, Tr } from '@utils';
import { Button, Paper, PaperProps, Stack, StackProps, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ReactComponent as CloudUpload } from '@material-symbols/svg-600/outlined/cloud_upload.svg';

const MAX_FILE_SIZE = 209715200;

export interface IFileUploader {
	multiple?: boolean;
	onUploadedFileChange?: (f?: unknown[]) => void;
	disable?: boolean;
	message?: string;
	containerProps?: StackProps;
	accept: Accept;
	errorMapping: Record<FileValidationStatus, string>;
}

export const FileUploader: FC<IFileUploader & PaperProps> = ({
	onUploadedFileChange,
	multiple = false,
	disable = false,
	message,
	sx,
	containerProps,
	accept,
	errorMapping,
}) => {
	const onDrop = useCallback<Required<DropzoneOptions>['onDrop']>((file) => {
		if (file) {
			onUploadedFileChange?.(file);
		}
	}, []);
	const onDropRejected = ([{ errors }]: FileRejection[]) => {
		notificationService.send({
			type: NotificationType.ERROR,
			message: errorMapping[errors[0].code],
		});
	};
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: multiple,
		accept: accept,
		useFsAccessApi: false,
		maxSize: MAX_FILE_SIZE,
		validator: nameLengthValidator,
		onDropRejected,
	});

	const getBorderColor = () => {
		if (isDragActive) {
			return 'grey.700';
		} else {
			return 'grey.200';
		}
	};

	return (
		<Paper
			{...getRootProps()}
			elevation={0}
			square
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				height: 40,
				paddingLeft: 2,
				paddingRight: 0.5,
				paddingTop: 0.5,
				borderWidth: 1,
				borderStyle: 'solid',
				borderRadius: '4px',
				borderColor: getBorderColor(),
				backgroundColor: 'background.paper',
				pointerEvents: disable ? 'none' : 'initial',
				...sx,
			}}
		>
			<input {...getInputProps()} data-testid="file-upload-id" />
			<Stack alignItems="center" direction="row" {...containerProps}>
				<CloudUpload width={24} height={24} fill="#01884C" />

				<Typography
					variant="label-s"
					sx={{
						color: disable ? 'grey.400' : 'grey.800',
						marginLeft: 2.75,
						marginRight: 2.75,
					}}
				>
					{message ?? <Tr.Portal path="new-analysis.drag-and-drop-file-here-device" />}
				</Typography>
			</Stack>

			<Button data-testid="file-upload-button-id" disabled={disable}>
				<AddBoxOutlinedIcon sx={{ height: 20, width: 20, transform: 'translateY(-1px)', marginLeft: 'auto' }} />
			</Button>
		</Paper>
	);
};
