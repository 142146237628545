import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Reason, usePageLeaveDetection, useService, useDebounce, useIrAnalysis } from '@hooks';
import { analyticsAnalysisEvent, checkFileIsValid, compareArrays, RxUtils } from '@utils';
import { IUploadedFile, PortalPageRoutes } from '@models';
import {
	notificationService,
	IrAccessoryCalibration,
	IrAccessoryCalibrationStatus,
	irAnalysisService,
	getIrAnalysisRoute,
	IrAnalysisResultMatchType,
	IrAnalysisStage,
	IrAccessory,
	accessoryService,
	Accessory,
	irAccessoryService,
	IrAnalysis as CIrAnalysis,
	loadingSpinnerOverlayService,
} from '@services';
import { Stack } from '@mui/material';
import { LoadingLayout } from '@components/common';
import { FormFields, initialFormValues } from './analysis-form';
import { useIrParser } from '@hooks';
import { IrAnalysisDetail } from './AnalysisDetail';
import { IrAnalysisTitle } from './AnalysisTitle';
import { IrAnalyticalProcedure } from './AnalyticalProcedure';
import { IrSampleMeasurements } from './SampleMeasurements';
import { IrAnalysisType } from '@models';
import { IrAnalysisInProgressModal } from '@components/ir-portal';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common/common.slice';
import { getIrAnalysisValidationSchema } from '@schemas';
import { useLibraries } from './AnalyticalProcedure/useLibraries';

const DownloadFileExtension = ['.jdf', '.zip'];

export enum IrAnalysisErrorCodes {
	METADATA_MISSING = '414',
	NOT_COMPATIBLE_FILE = '415',
}
export const IrAnalysis: FC = () => {
	const { t } = useTranslation('irportal');
	const navigate = useNavigate();
	const { id = '' } = useParams<{ id: string }>();
	const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(undefined);
	const [spectrumFile, setSpectrumFile] = useState<Partial<IUploadedFile>>({ content: null });
	const [lastSavedTime, setLastSavedTime] = useState<Date>();
	const [fileUploadLoader, setFileUploadLoader] = useState(false);
	const { data: analysisData, loading } = useService(() => irAnalysisService.get(+id), []);
	const [isAnalysisFilesLoading, setAnalysisFilesLoading] = useState(true);
	const [calibrationList, setCalibrationList] = useState<IrAccessoryCalibration[]>([]);
	const { meta } = useIrParser(spectrumFile.content || '');
	const { debounce } = useDebounce(500);
	const [analysisprogress, setAnalysisProgress] = useState(false);
	const user = useSelector(userSelector);
	const {
		irDevice,
		irAccessory,
		updateIrAccessory,
		updateAnalysisType,
		updateAnalysisEditable,
		analysisType,
		selectedLibrary,
		updateSelectedLibrary,
	} = useIrAnalysis();

	const formik = useFormik<FormFields>({
		validateOnChange: false,
		validateOnBlur: false,
		validateOnMount: false,
		initialValues: initialFormValues.initialValues,
		initialTouched: initialFormValues.initialTouched,
		validationSchema: getIrAnalysisValidationSchema(t),
		onSubmit: () => runAnalysis(),
	});

	useMemo(() => {
		debounce(() => formik.validateForm());
	}, [formik.values]);

	useMemo(() => {
		if (analysisData?.operatorId) updateAnalysisEditable(analysisData?.operatorId === user?.username);
	}, [analysisData?.operatorId, user?.email]);

	const runAnalysis = () => {
		analysisData && (analysisData.status = IrAnalysisResultMatchType.NOMATCH);
		analysisData && (analysisData.stage = IrAnalysisStage.EXECUTING);
		analyticsAnalysisEvent.AnalysisExecuting(id); // For Google analytics
		setAnalysisProgress(true);
		const successCb = () => {
			analyticsAnalysisEvent.Executed(id);
			navigate(`../${PortalPageRoutes.ANALYSIS_RESULT}/${id}`);
			setAnalysisProgress(false);
		};
		const errorCb = () => {
			analyticsAnalysisEvent.AnalysisError(id);
		};
		updateAnalysis(true, successCb, errorCb);
	};
	const downloadOtherFileTypes = (filename: string, fileId: number) => {
		RxUtils.promisify(irAnalysisService.downloadFile(+id, fileId), (fileContent) => {
			setUploadedFileName(filename);
			setSpectrumFile({ content: fileContent });
			notificationService.sendSuccess(t('new-analysis.success-file-upload'));
		});
	};

	const onUploadedFileChange = (file?: IUploadedFile) => {
		if (!file || !checkFileIsValid(file?.content || '')) {
			notificationService.sendError(t('file-invalid'));
			return;
		}

		setFileUploadLoader(true);
		RxUtils.promisify(
			irAnalysisService.uploadFile(+id, file),
			({ id: fileId }) => {
				if (DownloadFileExtension.some((item) => file.file.name.toLocaleLowerCase().includes(item))) {
					downloadOtherFileTypes(file.file.name, fileId);
				} else {
					setUploadedFileName(file.file.name);
					setSpectrumFile({ content: file.content });
					notificationService.sendSuccess(t('new-analysis.success-file-upload'));
				}
			},
			(err) => {
				if (err?.ErrorCode?.toString() === IrAnalysisErrorCodes.METADATA_MISSING) {
					notificationService.sendError(t('ir-error-messages.required-meta-data'));
				}
				if (err?.ErrorCode?.toString() === IrAnalysisErrorCodes.NOT_COMPATIBLE_FILE) {
					notificationService.sendError(t('ir-error-messages.not-compatible-file'));
				}
			},
			() => setFileUploadLoader(false),
		);
	};

	const sendUpdateAnalysisRequest = (data: Partial<CIrAnalysis>, disableLoader = false) => {
		return irAnalysisService.update(data, disableLoader);
	};

	const updateAnalysisPromisify = (
		data: Partial<CIrAnalysis>,
		noLoader = false,
		successCb: (res?: unknown) => void,
		errorCb: () => void,
	) => {
		return RxUtils.promisify(
			sendUpdateAnalysisRequest(data, noLoader),
			(res) => successCb(res),
			() => errorCb(),
		);
	};

	const updateAnalysis = (noLoader = false, successCb: (res?: unknown) => void, errorCb: () => void) => {
		const quantificationMethod =
			analysisType === IrAnalysisType.QUANTIFICATION && !formik?.values.quantificationMethod
				? 'SinglePeakComparison'
				: formik.values.quantificationMethod;

		return updateAnalysisPromisify(
			{
				...analysisData,
				...formik?.values,
				peakMin1: formik.values.peakMin1,
				peakMin2: formik.values.peakMin2,
				peakMax1: formik.values.peakMax1,
				peakMax2: formik.values.peakMax2,
				type: analysisType ?? formik?.values.type ?? analysisData?.type,
				selectedLibrary: selectedLibrary ?? formik?.values.selectedLibrary ?? analysisData?.selectedLibrary,
				quantificationMethod: quantificationMethod,
				stage: analysisData?.stage,
				status: analysisData?.status,
				deviceCalibrationId: !formik?.values.deviceCalibrationId ? undefined : +formik?.values.deviceCalibrationId,
			},
			noLoader,
			(res) => successCb(res),
			() => errorCb(),
		);
	};
	const updateSuccessCallback = (value: unknown) => {
		const formValues = value as FormFields;
		const formState = {
			values: {
				...formValues,
				type: analysisType ?? formik?.values.type ?? analysisData?.type,
				selectedLibrary: selectedLibrary ?? formik?.values.selectedLibrary ?? analysisData?.selectedLibrary,
				deviceCalibrationId:
					formValues.deviceCalibrationId ??
					(calibrationList.length > 0
						? calibrationList.filter((calibration) => calibration?.status === IrAccessoryCalibrationStatus.ACTIVE)?.[0].id
						: ''),
				measurementDeviceBrand: irDevice?.manufacturer || null,
			},
			touched: {
				...formik.touched,
			},
			errors: {
				...formik.errors,
			},
			isValidating: true,
		};
		formik?.resetForm(formState);
		setTimeout(() => formik?.validateForm(formState.values), 50);
		setLastSavedTime(new Date());
	};
	usePageLeaveDetection(
		(reason) => {
			const checkDataAvailable = () => !loading && analysisData && formik?.dirty;
			if (reason === Reason.TIMER) {
				checkDataAvailable() &&
					!analysisData?.isExecuting() &&
					!analysisData?.isExecuted() &&
					updateAnalysis(true, updateSuccessCallback, () => undefined);
			} else if (reason === Reason.NAVIGATION) {
				checkDataAvailable() &&
					!analysisData?.isExecuted() &&
					!analysisData?.isExecuting() &&
					updateAnalysis(
						true,
						() => undefined,
						() => undefined,
					);
			}
			return true;
		},
		2000,
		() => !!meta && (formik?.dirty as boolean),
		[updateAnalysis],
	);

	useEffect(() => {
		if (analysisData) {
			loadingSpinnerOverlayService.increment();

			formik?.setFieldValue('secondApproval', analysisData?.secondApproval);
			if (getIrAnalysisRoute(analysisData.stage) !== PortalPageRoutes.ANALYSIS) {
				navigate(`../${getIrAnalysisRoute(analysisData.stage)}/${id}`);
				return;
			}
			RxUtils.promisify(
				irAnalysisService.getFiles(+id),
				(data) => {
					if (data.length > 0) {
						RxUtils.promisify(
							irAnalysisService.downloadFile(+id, data[0].id),
							(file) => {
								setSpectrumFile({ content: file } as any);
							},
							undefined,
							() => (setAnalysisFilesLoading(false), loadingSpinnerOverlayService.decrement()),
						);
						formik?.resetForm({
							values: { ...analysisData, secondApproval: analysisData.secondApproval },
						});
						setTimeout(() => {
							formik?.validateForm();
						}, 500);
						updateAnalysisType(analysisData.type);
						updateSelectedLibrary(analysisData.selectedLibrary);
					} else {
						setAnalysisFilesLoading(false);
						loadingSpinnerOverlayService.decrement();
					}
				},
				() => (setAnalysisFilesLoading(false), loadingSpinnerOverlayService.decrement()),
			);
			const timer = setTimeout(() => {
				formik?.validateForm();
			}, 500);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [analysisData]);

	useEffect(() => {
		if (irAccessory?.accessoryId) {
			RxUtils.promisify(accessoryService.getAccessory(irAccessory.accessoryId), (accessoryResponse: Accessory) => {
				RxUtils.promisify(
					irAccessoryService.getAccessory(accessoryResponse.id),
					(irAccessoryResponse: IrAccessory) => {
						updateIrAccessory(irAccessoryResponse);
						setCalibrationList(irAccessoryResponse.irAccessoryCalibrations || []);
					},
					() => setCalibrationList([]),
				);
			});
		}
	}, [irAccessory]);

	const resetLibraries = (values: FormFields | undefined) => {
		formik.resetForm({
			values: { ...values, selectedLibrary: [] },
			touched: {
				peakMin1: false,
				peakMin2: false,
				peakMax1: false,
				peakMax2: false,
			},
		});
		updateSelectedLibrary(new Array<string>());
	};

	useEffect(() => {
		const isLibrariesChanged = !compareArrays(selectedLibrary ?? [], formik.values.selectedLibrary);
		if (isLibrariesChanged) updateAnalysis(false, updateSuccessCallback, () => undefined);
	}, [selectedLibrary]);

	const onAnalysisTypeChange = (val: IrAnalysisType) => {
		loadingSpinnerOverlayService.increment();
		RxUtils.promisify(
			irAnalysisService.changeAnalysisType(+id, val),
			() => {
				updateAnalysisType(val);
				resetLibraries({ ...formik.values, type: val });
			},
			() => undefined,
			() => loadingSpinnerOverlayService.decrement(),
		);
	};

	const libraries = useLibraries();

	const showAnalysisComponents = !loading && !isAnalysisFilesLoading && spectrumFile;
	return (
		<form onSubmit={formik?.handleSubmit} data-testid="analysis-initial-upload-id">
			{fileUploadLoader && <LoadingLayout sx={{ backgroundColor: 'rgba(0, 0, 0, 0.35)' }} />}
			<Stack direction="column" data-testid="analysis-test-id">
				{!isAnalysisFilesLoading && spectrumFile && (
					<>
						<IrAnalysisTitle formik={formik} hasFile={!!spectrumFile.content} savedTime={lastSavedTime} />
						<IrSampleMeasurements
							formik={formik}
							spectrumFile={spectrumFile}
							onAnalysisTypeChange={(val) => onAnalysisTypeChange(val)}
							onUploadedFileChange={onUploadedFileChange}
						/>
					</>
				)}
				{showAnalysisComponents ? (
					<Stack sx={{ display: !formik?.values.type ? 'none' : 'initial' }}>
						<IrAnalysisDetail
							formik={formik}
							fileName={uploadedFileName || ''}
							analysisData={analysisData as any}
							uploadedFile={!!uploadedFileName}
						/>
					</Stack>
				) : null}
				{showAnalysisComponents && formik?.values.type ? (
					<IrAnalyticalProcedure
						formik={formik}
						id={+id}
						isFormValid={formik?.isValid}
						onStageChange={(stage) => analysisData && (analysisData.stage = stage as unknown as IrAnalysisStage)}
						libraries={libraries}
					/>
				) : null}
				{analysisprogress && <IrAnalysisInProgressModal />}
			</Stack>
		</form>
	);
};
