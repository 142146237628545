import { NmrDrmOrderRejectionSeverity } from '@services/nmr-drm-pipeline';
import { FC } from 'react';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Stack, SxProps, Tooltip } from '@mui/material';
import { Tr } from '@utils/Translation';

const RejectionColorMapping: Record<
	NmrDrmOrderRejectionSeverity,
	{
		color: string;
		tooltipPath: string;
	}
> = {
	[NmrDrmOrderRejectionSeverity.MINOR]: { color: '#8B8B9C', tooltipPath: 'drm-pipeline.nmr.backlog.minor-tooltip' },
	[NmrDrmOrderRejectionSeverity.MAJOR]: { color: '#FFC832', tooltipPath: 'drm-pipeline.nmr.backlog.major-tooltip' },
	[NmrDrmOrderRejectionSeverity.CRITICAL]: { color: '#E61E50', tooltipPath: 'drm-pipeline.nmr.backlog.critical-tooltip' },
};

type TRejectionSeverityIconProps = {
	severity: NmrDrmOrderRejectionSeverity;
	iconSx?: SxProps;
};

const RejectionSeverityIcon: FC<TRejectionSeverityIconProps> = ({ severity, iconSx }) => (
	<WarningAmberOutlinedIcon
		sx={{ color: RejectionColorMapping[`${severity}`]?.color, marginRight: 3.5, width: 20, height: 20, marginTop: 0.5, ...iconSx }}
	/>
);

type TRejectionSeverityProps = {
	severity?: NmrDrmOrderRejectionSeverity;
	testId?: string;
	iconSx?: SxProps;
	tooltipEnabled?: boolean;
};

export const RejectionSeverity: FC<TRejectionSeverityProps> = ({ severity, testId, iconSx, tooltipEnabled = true }) => {
	if (!severity) {
		return null;
	}
	if (!tooltipEnabled) {
		return <RejectionSeverityIcon severity={severity} iconSx={iconSx} />;
	}

	return (
		<Tooltip
			placement="top"
			data-testid={testId}
			title={
				<Stack>
					<Tr.Admin path={RejectionColorMapping[`${severity}`]?.tooltipPath} />
				</Stack>
			}
		>
			<Stack>
				<RejectionSeverityIcon severity={severity} iconSx={iconSx} />
			</Stack>
		</Tooltip>
	);
};
