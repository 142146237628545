import { Stack, SxProps } from '@mui/material';
import { CSSProperties, ChangeEvent, FC, useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { PriorityRuleCheck, PriorityRules } from '@models/drm-order';
import { NmrDrmOrderWave, nmrDrmWaveService } from '@services/nmr-drm-pipeline';
import { RxUtils } from '@utils/Rx';
import { setTriggerBacklogRefresh, setTriggerWaveRefresh } from '@store/slices/nmr/pipeline.slice';
import { useDispatch } from 'react-redux';
import { DataTestId } from '@utils/DataTestId';

const iconStyle: SxProps = {
	width: 20,
	height: 20,
	color: '#5E5E72',
};

const inputStyle: CSSProperties = {
	fontSize: '12px',
	fontWeight: 700,
	lineHeight: '120%',
	letterSpacing: '0.2px',
	borderRadius: '4px',
	border: 'none',
	height: '20px',
	width: '100%',
	padding: '6px',
	outline: '0px',
	color: '#5E5E72',
	backgroundColor: '#ECECF4',
	textAlign: 'center',
};

const PRIORITY_INPUT_SETTINGS = {
	LETTER_SIZE: 7,
	SPACER: 8,
};

type TChangeWavePriorityProps = {
	waveData: NmrDrmOrderWave;
};

export const ChangeWavePriority: FC<TChangeWavePriorityProps> = ({ waveData }) => {
	const [priority, setPriority] = useState<string>(String(waveData.priority));
	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [width, setWidth] = useState(20);
	useEffect(() => {
		setPriority(waveData.priority);
	}, [waveData.priority]);
	useEffect(() => {
		const minLengthValue =
			String(priority).length > PriorityRules.PRIORITY_MIN_LENGTH ? String(priority).length : PriorityRules.PRIORITY_MIN_LENGTH;
		setWidth((minLengthValue + 1) * PRIORITY_INPUT_SETTINGS.LETTER_SIZE + PRIORITY_INPUT_SETTINGS.SPACER);
	}, [priority]);

	const inputChangePreceedor = (value: string) => (clearTimeout(timer), setPriority(value));

	const dispatch = useDispatch();

	const wavePriorityHandler = (value: string | number) => {
		RxUtils.promisify(
			nmrDrmWaveService.patch({
				id: waveData.id,
				priority: value.toString(),
			}),
			() => (dispatch(setTriggerWaveRefresh()), dispatch(setTriggerBacklogRefresh())),
		);
	};

	const onDigitInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = event.currentTarget.value;
		if (PriorityRuleCheck.isEmpty(value)) {
			inputChangePreceedor(value);
			return;
		}
		if (PriorityRuleCheck.isValid(value)) {
			inputChangePreceedor(value);
			const currentTimer = setTimeout(() => {
				wavePriorityHandler(value);
			}, 1000);
			setTimer(() => currentTimer);
		}
	};

	return (
		<Stack onClick={(event) => event.stopPropagation()} direction="row" spacing={0.25}>
			<ArrowUpwardIcon sx={iconStyle} onClick={() => wavePriorityHandler(Number(waveData.priority) - 1)} />
			<Stack sx={{ width: 'min-content' }}>
				{width && (
					<input
						value={priority}
						data-testid={DataTestId.getStaticTestId('change-priority')}
						inputMode="decimal"
						onChange={onDigitInputChange}
						style={{ ...inputStyle, width: width }}
					/>
				)}
			</Stack>
			<ArrowDownwardIcon sx={iconStyle} onClick={() => wavePriorityHandler(Number(waveData.priority) + 1)} />
		</Stack>
	);
};
