import { concatMap, map, tap } from 'rxjs';
import { httpService, logger } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { irConfig } from '../irConfig';
import { IrLibrary, IrAddLibrary } from './ir-library.class';
import { PagedResult } from '@models/request-response';

class IrLibraryService {
	private getUrl() {
		return `${irConfig.url}/Library`;
	}

	get(params?: URLSearchParams) {
		return httpService.get<PagedResult<IrLibrary>>(this.getUrl(), '', { params: params, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transformFromExist(new PagedResult<IrLibrary>(IrLibrary), result)),
		);
	}

	addLibrary(libraryName?: string) {
		return httpService.post<IrAddLibrary>(this.getUrl(), '', { body: { name: libraryName }, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrLibrary, result)),
		);
	}

	put(id: number, libraryName: string) {
		return httpService
			.put(this.getUrl(), `${id}`, {
				body: {
					name: libraryName,
				},
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.put.name}]`, result)));
	}

	delete(id: number) {
		return httpService
			.delete(this.getUrl(), `${id}`, {
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.delete.name}]`, result)));
	}
}

export const irLibraryService = new IrLibraryService();
