import { lastValueFrom } from 'rxjs';
import { MerckError } from '@utils/Error';
import { irDrmWaveService } from '@services/ir-drm-pipeline';

const RETRY_LIMIT = 3;
export const METADATA_HEADER_COUNT = 28;
const METADATA_FILENAME = 'metadata.csv';

export const getFileStatistic = async (file: File) => {
	return new Promise<[headerCount: number, rowCount: number]>((resolve, rejects) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const text = <string>reader.result;
			if (!text) rejects('drm-pipeline.error.csv-reading-error');

			const header = text.slice(0, text.indexOf('\n')).split(';');
			const rows = text
				.slice(text.indexOf('\n') + 1)
				.split('\n')
				.filter((row) => !!row);

			resolve([header.length, rows.length]);
		};
		reader.readAsText(file);
	});
};

export const validateFiles = async (files: FileList | null) => {
	// Check if there are no files
	if (!files || files.length === 0) throw new MerckError('drm-pipeline.error.no-files', { ns: 'admin' });

	const fileArray = Array.from(files);

	// Check metadata exists
	const metaDataFiles = fileArray.filter(({ name }) => name === METADATA_FILENAME);
	if (metaDataFiles.length === 0) throw new MerckError('drm-pipeline.error.no-metadata-file', { ns: 'admin' });

	// Check there is only one metadata file
	if (metaDataFiles.length > 1) throw new MerckError('drm-pipeline.error.multiple-metadata-files', { ns: 'admin' });

	//Check count of spectral files matching metadata
	const metaDataFile = metaDataFiles[0];
	const [headerCount, rowCount] = await getFileStatistic(metaDataFile);

	if (headerCount !== METADATA_HEADER_COUNT) throw new MerckError('drm-pipeline.error.header-count-mismatch', { ns: 'admin' });
	if (rowCount !== fileArray.length - 1) throw new MerckError('drm-pipeline.error.row-count-mismatch', { ns: 'admin' });

	return { metaDataFile, allFiles: fileArray };
};

export const uploadFile = async (waveId: number, file: File, retryCount = 1) => {
	if (retryCount > RETRY_LIMIT) {
		throw new MerckError('drm-pipeline.error.uploading-failed', { ns: 'admin' });
	}

	try {
		await lastValueFrom(irDrmWaveService.uploadFile(waveId, file));
	} catch {
		await uploadFile(waveId, file, retryCount + 1);
	}
};

export const getWaveName = (metaDataFile: File): string => {
	return metaDataFile.webkitRelativePath.replace(`/${METADATA_FILENAME}`, '');
};

export const createWave = async (name: string): Promise<number> => {
	const { id } = await lastValueFrom(irDrmWaveService.createWave(name));

	if (!id) throw new MerckError('drm-pipeline.error.uploading-failed', { ns: 'admin' });

	return id;
};
