import { TypeUtils } from '@utils/Type';
import { TFunction } from 'i18next';

export enum IrAccessoryCalibrationStatus {
	ACTIVE = 'Active',
	CALIBRATED = 'Calibrated',
	CALIBRATION_NEEDED = 'Uncalibrated',
	INACTIVE = 'Inactive',
	CALIBRATION_CHECK_NEEDED = 'HealthCheckRequred',
	CALIBRATING = 'Calibrating',
	CALIBRATION_ERROR = 'Error',
	ARCHIVED = 'Archived',
}

type TAccessoryCalibrationStatusConfig = {
	statusMessage: string;
	color: string;
};

const IrAccessoryCalibrationStatusConfig = {
	[IrAccessoryCalibrationStatus.ACTIVE]: {
		statusMessage: 'accessory-status.active',
		color: 'rgba(0,136,76,0.6)',
	},
	[IrAccessoryCalibrationStatus.CALIBRATED]: {
		statusMessage: 'accessory-status.calibrated',
		color: 'rgba(1,136,76)',
	},
	[IrAccessoryCalibrationStatus.CALIBRATION_NEEDED]: {
		statusMessage: 'accessory-status.uncalibrated',
		color: 'rgb(255, 200, 50)',
	},
	[IrAccessoryCalibrationStatus.CALIBRATION_CHECK_NEEDED]: {
		statusMessage: 'accessory-status.healthcheck-needed',
		color: 'rgb(255, 200, 50)',
	},
	[IrAccessoryCalibrationStatus.CALIBRATING]: {
		statusMessage: 'accessory-status.calibrating',
		color: 'secondary.main',
	},
	[IrAccessoryCalibrationStatus.ARCHIVED]: {
		statusMessage: 'accessory-status.archived',
		color: 'secondary.error',
	},
	[IrAccessoryCalibrationStatus.CALIBRATION_ERROR]: {
		statusMessage: 'accessory-status.error',
		color: 'rgb(230, 30, 80)',
	},
};

const getStatusConfig = (status: IrAccessoryCalibrationStatus) =>
	status !== IrAccessoryCalibrationStatus.INACTIVE
		? TypeUtils.returnValueOfKey<typeof IrAccessoryCalibrationStatusConfig, TAccessoryCalibrationStatusConfig>(
				status,
				IrAccessoryCalibrationStatusConfig,
			)
		: undefined;

export const getIrAccessoryCalibrationStatusMessage = (status: IrAccessoryCalibrationStatus, t: TFunction) => {
	return t(getStatusConfig(status)?.statusMessage ?? '');
};

export const getIrAccessoryCalibrationStatusColor = (status: IrAccessoryCalibrationStatus) => {
	return getStatusConfig(status)?.color;
};
