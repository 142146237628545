import { ReferenceMaterialFavorite, TReferenceMaterialFavorite } from '@components/common';
import { alertService, notificationService } from '@services/core';
import { favoriteReferencesService } from '@services/nmr';
import { RxUtils } from '@utils/Rx';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NmrAddFavoriteDialog } from '../NmrAddFavoriteDialog';
import { Tr } from '@utils/Translation';

type TNmrReferenceMaterialFavorite = {
	drmCode: string;
	favoriteId?: number;
	name?: string;
	onSuccess: () => void;
};

export const NmrReferenceMaterialFavorite: FC<
	Omit<TReferenceMaterialFavorite, 'onClick' | 'isFavorite'> & TNmrReferenceMaterialFavorite
> = ({ drmCode, name, onSuccess, favoriteId, ...props }) => {
	const [isAddToFavoritesOpened, setIsAddToFavoritesOpened] = useState<boolean>(false);
	const { t } = useTranslation('portal');
	const onRemoveFavoriteConfirmed = () => {
		if (favoriteId)
			RxUtils.promisify(
				favoriteReferencesService.delete(favoriteId),
				() => {
					notificationService.sendSuccess(t('favourites.successfully-deleted'));
					onSuccess();
				},
				(err) => notificationService.sendError(err.Message),
			);
	};

	const onButtonClick = () => {
		if (favoriteId) {
			alertService.send({
				titleText: <Tr.Portal path="favourites.delete" />,
				confirmTextHeader: name ? (
					<Tr.Portal path="favourites.delete-the-diaolog-header" />
				) : (
					<Tr.Portal path="favourites.delete-diaolog-header" />
				),
				onConfirm: () => onRemoveFavoriteConfirmed(),
				content: name
					? t('favourites.delete-confirmation-message', { name: name })
					: t('favourites.delete-this-confirmation-message'),
				confirmTextComponent: <Tr.Portal path="favourites.confirm-delete" />,
			});
		} else {
			setIsAddToFavoritesOpened(true);
		}
	};
	return (
		<>
			{isAddToFavoritesOpened && (
				<NmrAddFavoriteDialog
					drmCode={drmCode}
					onCloseDialog={() => setIsAddToFavoritesOpened(false)}
					onSuccess={() => (onSuccess(), setIsAddToFavoritesOpened(false))}
				/>
			)}

			<ReferenceMaterialFavorite onClick={onButtonClick} isFavorite={!!favoriteId} {...props} />
		</>
	);
};
