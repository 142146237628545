import { CircularProgress, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';

export const LoadingFileUpload = () => {
	return (
		<Stack
			direction="row"
			alignItems="center"
			height={40}
			sx={{ borderBottom: '1px solid', borderColor: 'grey.100', padding: '0 1rem' }}
		>
			<Stack direction="row" spacing={3}>
				<CircularProgress color="primary" thickness={4} size={20} />

				<Typography sx={{ color: 'grey.800' }} variant="label-s">
					<Tr.Common path="upload-in-progress" />
				</Typography>
			</Stack>
		</Stack>
	);
};
