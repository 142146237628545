export function spectrumUtils(specData) {
	for (let i = 0; i < specData?.data['history'].length; i++) {
		if (specData?.data['history'][`${i}`]['name'] === 'apply_range_harmonization_2') {
			const xAry: number[] = [];
			for (let k = specData?.data['history'][`${i}`]['x'].start; k >= specData?.data['history'][`${i}`]['x'].stop; k--) {
				xAry.push(k);
			}
			return {
				x: xAry,
				y: specData?.data['history'][`${i}`]['y'],
				meta: specData.data['metadata'],
			};
		}
	}
}
