import { Button, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormikTextfield, ModalDialog } from '@components/common';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SingleSelectMenu } from '@components/common/SingleSelectMenu';
import { qualityGrades, siteSuppliers } from './add-physical-product.constants';
import { RxUtils } from '@utils/Rx';
import { CreatePhysicalProductModel, physicalProductService } from '@services/index';
import { TypeUtils } from '@utils/Type';
import { NmrDrmOrder } from '@services/nmr-drm-pipeline';
import { getPhysicalProductValidationSchema } from '@schemas';

type AddPhysicalProductFields = Extendable<{
	physicalProductNumber: string;
	physicalProductQualityGrade: string;
	siteSupplier: string;
}>;

type TAddPhysicalProduct = {
	onClose: () => void;
	onSave: (payload: CreatePhysicalProductModel) => void;
	nmrDrmOrder: NmrDrmOrder;
};

export const AddPhysicalProductModal: FC<TAddPhysicalProduct> = ({ onClose, onSave, nmrDrmOrder }) => {
	const { t } = useTranslation('admin');
	const formik = useFormik<AddPhysicalProductFields>({
		initialValues: {
			casNumber: nmrDrmOrder.casNumber,
			physicalProductNumber: '',
			physicalProductQualityGrade: undefined,
			siteSupplier: undefined,
		},
		validationSchema: getPhysicalProductValidationSchema(t),
		onSubmit: (values) => {
			const payload: CreatePhysicalProductModel = TypeUtils.transform(CreatePhysicalProductModel, values);
			RxUtils.promisify(physicalProductService.create(payload), () => onSave(payload));
		},
	});
	return (
		<ModalDialog
			variant="primary"
			width={832}
			open={true}
			title={<Tr.Admin path="drm-order-details.add-physical-product.add-new-product" />}
			onClose={onClose}
			contentProps={{ sx: { padding: 3 } }}
		>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing={4} sx={{ marginTop: 1, marginBottom: 2 }}>
					<Stack spacing={4}>
						<FormikTextfield
							name="physicalProductNumber"
							formikApi={formik}
							title={t('drm-order-details.edit-data-modal.physical-product-number')}
							placeholder={t('drm-order-details.add-physical-product.enter-physical-product-number')}
							required
						/>
					</Stack>
					<Stack>
						<Grid container spacing={4}>
							<Grid item xs={6}>
								<Stack>
									<Typography
										variant="label-s"
										color={'grey.800'}
										className="required"
										sx={{
											marginBottom: 1.25,
										}}
									>
										<Tr.Admin path="drm-order-details.edit-data-modal.quality-grade" />
									</Typography>
									<SingleSelectMenu
										selectedItem={formik.values.physicalProductQualityGrade || ''}
										items={qualityGrades}
										defaultLabel={t('drm-order-details.add-physical-product.select-quality-grade')}
										sx={{ height: 40 }}
										onSelectedItemChange={(item) => formik.setFieldValue('physicalProductQualityGrade', item)}
									/>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack>
									<Typography
										variant="label-s"
										color={'grey.800'}
										className="required"
										sx={{
											marginBottom: 1.25,
										}}
									>
										<Tr.Admin path="drm-order-details.edit-data-modal.site-supplier" />
									</Typography>
									<SingleSelectMenu
										selectedItem={formik.values.siteSupplier || ''}
										items={siteSuppliers}
										defaultLabel={t('drm-order-details.add-physical-product.select-site-supplier')}
										sx={{ height: 40 }}
										onSelectedItemChange={(item) => formik.setFieldValue('siteSupplier', item)}
										displayEmpty
										native={false}
									/>
								</Stack>
							</Grid>
						</Grid>
					</Stack>

					<Stack direction="row" justifyContent="flex-end" spacing={1.5} paddingRight={'2px'}>
						<Button
							variant="outlined"
							size="medium"
							onClick={onClose}
							data-testid={DataTestId.getStaticTestId('edit-data-details-cancel-id')}
						>
							<Tr.Admin path="drm-order-details.edit-data-modal.cancel" />
						</Button>
						<Button
							variant="contained"
							size="medium"
							type="submit"
							disabled={!formik.dirty || !formik.isValid}
							data-testid={DataTestId.getStaticTestId('edit-data-details-save-id')}
						>
							<Tr.Admin path="drm-order-details.edit-data-modal.save" />
						</Button>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
