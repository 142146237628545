export enum AdminEditOrganizationTabs {
	OVERVIEW = 'overview',
	SUBSCRIPTION = 'subscription',
	LABS = 'labs',
}

export enum PortalEditOrganizationTabs {
	OVERVIEW = 'overview',
	SUBSCRIPTION = 'subscription',
	LABS = 'labs',
	USERS = 'users',
}
