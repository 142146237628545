export class UserReferenceModel {
	id?: number;
	name: string;
	irReferenceMaterialLibraryId: string;
	casNumber?: string;
	linearFormula?: string;
	molecularWeight?: number;
	smilesCode?: string;
	synonyms?: string;

	samplingTechnique?: string;
	numberOfScans?: number;
	resolution?: number;
	lastX?: number;
	firstX?: number;
	physicalProductBatchNumber?: string;
	generalComment?: string;
	isEditing?: boolean;
	status?: string;
	batch?: string;
	libraryName?: string;
	libraryId?: number;
}
