import { useEffect, useState } from 'react';

export const useWindow = () => {
	const [windowSizes, setWindowSizes] = useState({
		innerWidth: window.innerWidth,
		innerHeight: window.innerHeight,
	});
	const handleWindowHeightChange = () => {
		setWindowSizes({
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowHeightChange);

		return () => {
			window.removeEventListener('resize', handleWindowHeightChange);
		};
	});

	return windowSizes;
};
