import { TypeUtils } from '@utils/Type';

export class NmrDrmOrderWave {
	id: number;
	name: string;
	endDate: Date;
	status: string;
	isActive: true;
	isClosed?: boolean;
	startDate: Date;
	priority: string;
	backlogOrderCount?: number;
	productionOrderCount?: number;
}

export const UnassignedWave = TypeUtils.transform(NmrDrmOrderWave, {
	id: undefined,
	name: 'Unassigned',
});
