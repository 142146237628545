import { ReactComponent as CloudUpload } from '@material-symbols/svg-600/outlined/cloud_upload.svg';
import Close from '@mui/icons-material/Close';
import { IconButton, Paper, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { OverflowText } from '../OverflowText';

const iconSx = {
	backgroundColor: 'error.main',
	color: 'background.paper',
	borderRadius: '16px',
	padding: '2px',
	fontSize: '1.1rem',
};

type TInvalidFileUpload = {
	fileName: string;
	additionalColumn?: ReactNode;
	getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
	getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
	setReUploading: (index: number) => void;
	index?: number;
};

export const InvalidFileUpload: FC<TInvalidFileUpload> = ({
	fileName,
	getRootProps,
	index = 0,
	getInputProps,
	setReUploading,
	additionalColumn,
}) => {
	const inputProps = getInputProps();
	inputProps.onClick = undefined;

	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			height={40}
			sx={{ borderBottom: '1px solid', borderColor: 'grey.100', padding: '0 1rem' }}
		>
			<Stack direction="row" spacing={3} alignItems="center">
				<Close sx={iconSx} />

				<OverflowText enableTooltip={true} sx={{ color: 'error.main' }} variant="label-s">
					{fileName}
				</OverflowText>
			</Stack>
			{additionalColumn}
			<Paper {...getRootProps()} elevation={0} sx={{ height: 39, overflow: 'hidden' }}>
				<IconButton
					data-testid={`device-list-button-${index}-id`}
					onClick={() => {
						setReUploading(index);
					}}
				>
					<input {...inputProps} data-testid="invalid-file-input-test-id" />
					<CloudUpload width={24} height={24} fill="#E61E50" />
				</IconButton>
			</Paper>
		</Stack>
	);
};
