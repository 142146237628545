import { FC, ReactNode } from 'react';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { ModalDialog } from '../ModalDialog';
import { ReactComponent as CalibrationLogo } from '@assets/icons/calibrationAnimation.svg';
import { useNavigate } from 'react-router-dom';
import { PortalPageRoutes } from '@models/router';

const ProgressAnimation = () => {
	return (
		<Stack justifyContent="center" alignItems="center" sx={{ height: 80, width: 80, backgroundColor: '#01884C', borderRadius: '50%' }}>
			<SvgIcon component={CalibrationLogo} viewBox={`0 0 21 25`} sx={{ fontSize: '2.9rem' }} />
		</Stack>
	);
};

type TCalibrationInProgressModal = {
	title: ReactNode;
	subtitle: ReactNode;
	message: ReactNode;
};

export const CalibrationInProgressModal: FC<TCalibrationInProgressModal> = ({ title, subtitle, message }) => {
	const navigate = useNavigate();

	return (
		<>
			<ModalDialog
				variant="primary"
				fullWidth={false}
				open={true}
				titleProps={{
					sx: {
						justifyContent: 'space-between',
						marginTop: 0,
						marginBottom: 5,
						minHeight: 30,
						backgroundColor: 'grey.50',
						paddingY: 1.2,
						paddingX: 2,
					},
				}}
				title={
					<Typography variant="body2" sx={{ fontWeight: 400, lineHeight: '200%' }} data-testid="calirating-modal">
						{title}
					</Typography>
				}
				onClose={() => navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE_MANAGEMENT}`)}
				maxWidth="sm"
				contentProps={{ sx: { width: 472, paddingBottom: 8 } }}
			>
				<Stack width={1} direction="column" alignItems="center" justifyContent="space-between">
					<Stack sx={{ textAlign: 'center', paddingX: 8 }}>
						<Stack justifyContent="center" alignItems="center" marginBottom={5}>
							<ProgressAnimation />
						</Stack>
						<Typography variant="subtitle1">{subtitle}</Typography>
						<Typography variant="pg-m" sx={{ marginTop: '1rem', lineHeight: '160%' }}>
							{message}
						</Typography>
					</Stack>
				</Stack>
			</ModalDialog>
		</>
	);
};
