import { Tr } from '@utils/Translation';
import { PageRoutes } from '@models/router';
import { DataTestId } from '@utils/DataTestId';
import { useState, useCallback, useEffect } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const routes = [PageRoutes.DRM_PIPELINE_BACKLOG, PageRoutes.DRM_PIPELINE_REPORTS];

type ValidRoutes = (typeof routes)[number];

export const IrPipeline = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState<ValidRoutes>(PageRoutes.DRM_PIPELINE_BACKLOG);

	const setTab = useCallback((route: ValidRoutes) => {
		setCurrentTab(route);
		navigate(route);
	}, []);

	useEffect(() => {
		const pieces = location.pathname.split('/');
		const result = pieces?.[pieces.length - 1];
		const targetTab = routes.find((route) => route === result);
		if (targetTab) {
			setCurrentTab(targetTab);
		} else {
			navigate(currentTab, { replace: true });
		}
	}, [location]);

	const availableTabs = routes.map((route) => {
		return (
			<Tab
				key={route}
				value={route}
				label={<Tr.Admin path={`drm-pipeline.${route}`} />}
				data-testid={DataTestId.getStaticTestId(`drm-pipeline-${route}-id`)}
			/>
		);
	});

	return (
		<Stack direction="column" data-testid={DataTestId.getStaticTestId('drm-pipeline-wrapper-id')}>
			<Tabs value={currentTab} textColor="primary" sx={{ marginBottom: '2.5rem' }} onChange={(_, tab) => setTab(tab)}>
				{availableTabs}
			</Tabs>
			<Outlet />
		</Stack>
	);
};
