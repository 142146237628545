import { IrSubstance } from './ir-substance.class';
import { Type } from 'class-transformer';
import { IrMatchCategory } from '../ir-analysis';
import { QualityGrade } from '@models/reference-material';

export class IrSubstanceDetail extends IrSubstance {
	title: string;
	ermProductNumber: string;
	qualityGrade: QualityGrade;
	inChICode: string;
	pH: string;
	purity: string;
	numberOfScans: number;
	synonyms: string;
	relaxationTime: string;
	resolution: number;
	lowerRange: number;
	upperRange: number;
	acquisitionTime: string;
	physicalProductNumber: string;
	physicalProductQuality: string;
	generalComment: string;
	instrumentIdentification: string;
	instrumentManufacturer: string;
	measurementUserId: string;
	receiverGain: string;
	pulse: string;
	physicalProductBatchNumber: string;
	physicalProductQualityGrade: string;
	fileStorageKey: string;
	fileName: string;
	smilesCode: string;
	temperature: string;
	version: string;
	id: number;
	drmCode: string;
	@Type(() => Date)
	createTime: Date;
	createdBy: string;
	@Type(() => Date)
	lastUpdateTime: Date;
	lastUpdatedBy: string;
	analysisId: number;
	substanceName: string;
	name: string;
	casNumber: string;
	lastX: string;
	firstX: string;
	library: string;
	identityConfirmed: boolean;
	matchFactor: number;
	referenceMaterialId: number;
	moleculeImage: string;
	rmSpectrum: string;
	sampleSpectrum: string;
	concentration: string;
	sampleWeight: string;
	solventWeight: string;
	isFalsePositive: boolean;
	isQuestionableHCount: boolean;
	isQuestionableMaxDa: boolean;
	isQuestionableNmrPurity: boolean;
	comments: string;
	category: IrMatchCategory;
	samplingTechnique: string;
	isActive: boolean;
	substanceId?: number;
	status?: string;

	isSatisfactory(): boolean {
		return this.category === IrMatchCategory.SATISFACTORY;
	}

	isQuestionable(): boolean {
		return this.category === IrMatchCategory.QUESTIONABLE;
	}

	isUnSatisfactory(): boolean {
		return this.category === IrMatchCategory.UNSATISFATORY;
	}
}
