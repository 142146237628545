import { Pagination, PaginationItem, PaginationProps } from '@mui/material';
import { FC } from 'react';

type PaginationType = {
	onChange: (page: number) => void;
} & PaginationProps;

export const TablePagination: FC<PaginationType> = ({ onChange, ...props }) => {
	return (
		<Pagination
			data-testid="pagination-test-id"
			count={5}
			shape="rounded"
			onChange={(_, page) => onChange(page)}
			renderItem={(item) => (
				<PaginationItem
					{...item}
					sx={{
						color: item.selected ? 'primary.300' : 'primary.main',
						fontWeight: '700',
					}}
				/>
			)}
			{...props}
		/>
	);
};
