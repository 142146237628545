import { Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC } from 'react';

type TDrmOrderDetailsError = {
	fileError?: boolean;
};

export const DrmOrderDetailsError: FC<TDrmOrderDetailsError> = ({ fileError }) => {
	return (
		<Typography variant="label-s" color="error.main">
			<Tr.Admin path={fileError ? 'drm-order-details.upload-all-files' : 'drm-order-details.complete-all-fields'} />
		</Typography>
	);
};
