import { IFilter, FilterType, IFilterValues, FilterKey } from '@models/filter';
import { UserResponseModel } from '@models/user';
import {
	NmrDrmOrder,
	NmrDrmOrderDeclineReason,
	NmrDrmOrderFileType,
	NmrDrmOrderRejectionReason,
	NmrDrmOrderRejectionSeverity,
	NmrDrmOrderStatus,
	NmrDrmOrderWave,
	UnassignedWave,
} from '@services/nmr-drm-pipeline';
import { TFunction } from 'i18next';

export const UnassignedWaveFilter = {
	id: '0',
	value: UnassignedWave.name,
};

export const getWaveFilter: (waveData: NmrDrmOrderWave[]) => IFilter = (waveData: NmrDrmOrderWave[]) => {
	const type: FilterType = 'dropdown';
	const waveFilter: IFilter = {
		key: 'waveIds',
		values: [],
		type: type,
		escapesTranslation: true,
	};
	if (!waveData) {
		waveFilter.values = [UnassignedWaveFilter];
		return waveFilter;
	}
	const values: IFilterValues[] = [
		UnassignedWaveFilter,
		...waveData.map((wave) => ({
			id: wave.id.toString(),
			value: wave.name,
		})),
	];

	waveFilter.values = values;
	return waveFilter;
};

export const isOnlyPageIndexChanged = (searchParams: URLSearchParams, oldSearchParams: URLSearchParams) => {
	let onlyPageIndexChanged = true;
	searchParams.forEach((value, key) => {
		const oldValue = oldSearchParams.getAll(key);
		const newValue = searchParams.getAll(key);
		if (key === 'pageIndex' && value === oldSearchParams.get(key)) {
			onlyPageIndexChanged = false;
		} else if (key === 'pageIndex') {
			return;
		}
		if (!oldValue.every((val) => newValue.includes(val))) {
			onlyPageIndexChanged = false;
		}
	});
	return onlyPageIndexChanged;
};

export const groupNmrDrmOrders = (orders: NmrDrmOrder[]) => {
	const orderGroups = orders.reduce((group: { [key: string]: NmrDrmOrder[] }, item) => {
		let status = item.status;
		if (status === NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT) {
			status = NmrDrmOrderStatus.IN_PRODUCTION;
		}

		if (!group[`${status}`]) {
			group[`${status}`] = [];
		}

		group[`${status}`].push(item);
		return group;
	}, {});

	return orderGroups;
};

export const getAssigneeFilter: (userData: UserResponseModel[]) => IFilter = (userData: UserResponseModel[]) => {
	const type: FilterType = 'dropdown';
	const assigneeFilter: IFilter = {
		key: FilterKey.ASSIGNEES,
		values: [],
		type: type,
		escapesTranslation: true,
	};

	const values: IFilterValues[] = [
		...userData
			.filter((user) => !!user.fullName)
			.map((user) => ({
				id: user.id,
				value: user.fullName ?? '',
			})),
	];

	assigneeFilter.values = values;
	return assigneeFilter;
};

export const getDeclineReasonFilter: (t: TFunction) => IFilter[] = (t: TFunction) => {
	return [
		{
			key: FilterKey.DECLINE_REASONS,
			values: [
				{
					id: NmrDrmOrderDeclineReason.ON_HOLD,
					value: t(`drm-order-details.decline-drm-order.reason.${NmrDrmOrderDeclineReason.ON_HOLD}`),
				},
				{
					id: NmrDrmOrderDeclineReason.NOT_SUITABLE,
					value: t(`drm-order-details.decline-drm-order.reason.${NmrDrmOrderDeclineReason.NOT_SUITABLE}`),
				},
			],
			type: 'dropdown',
			escapesTranslation: true,
		},
	];
};

export const getDrmOrderFiles: (nmrDrmOrder?: NmrDrmOrder) => NmrDrmOrderFileType[] = (nmrDrmOrder) => {
	const allowedFileTypes = [NmrDrmOrderFileType.SPECTRUS, NmrDrmOrderFileType.JDX, NmrDrmOrderFileType.RAW, NmrDrmOrderFileType.PDF];
	return allowedFileTypes.filter((fileType) => nmrDrmOrder?.fileTypes?.includes(fileType));
};

type TBacklogFilterOptions = {
	hideFilter?: boolean;
	order: number;
	title?: string;
	includeIds?: string[];
};

const FLAGGED_ORDER_ID = '1';

const FilterOptionsMapping: Record<string, TBacklogFilterOptions> = {
	assignees: { order: 1 },
	waveIds: { order: 3 },
	requestTypes: {
		order: 4,
	},
	priorities: { order: 2 },
	orderTypes: { order: 5, title: 'other', includeIds: [FLAGGED_ORDER_ID] },
	rejectionSeverities: { order: Infinity, hideFilter: true },
	statuses: { order: Infinity, hideFilter: true },
	rejectReasons: { order: Infinity, hideFilter: true },
};

export const getOrderedBacklogFilter = (options?: IFilter[]) => {
	if (!options) {
		return [];
	}

	let filteredOptions = options.filter((o) => !FilterOptionsMapping[o.key]?.hideFilter);

	filteredOptions = filteredOptions.map((option) =>
		FilterOptionsMapping[option.key]?.includeIds
			? {
					...option,
					values: option.values.filter((value) => FilterOptionsMapping[option.key]?.includeIds?.includes(value.id)),
				}
			: option,
	);

	return filteredOptions
		.map((o) => ({ ...o, title: FilterOptionsMapping[o.key]?.title }))
		.sort((a, b) => FilterOptionsMapping[a.key]?.order - FilterOptionsMapping[b.key]?.order);
};
export const getRejectionSeverityOptions: () => unknown[] = () => {
	return Object.values(NmrDrmOrderRejectionSeverity).map((severity) => {
		return {
			value: severity,
			label: severity,
		};
	});
};

export const getRejectionReasonOptions: (t: TFunction) => IFilterValues[] = (t: TFunction) => {
	return Object.values(NmrDrmOrderRejectionReason).map((reason) => {
		return {
			id: reason,
			value: t(`drm-order-details.reject-drm-order.reason.${reason}`),
		};
	});
};
