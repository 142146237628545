import { Type } from 'class-transformer';

class NmrDrmOrderBatchReportFileModel {
	uri: string;
	fileName: string;
}

export class NmrDrmOrderBatch {
	id: number;
	uploaderId: string;
	uploader: string;
	numberOfFailedOrders: number;
	numberOfSuccessfulOrders: number;
	waveName: string;

	@Type(() => NmrDrmOrderBatchReportFileModel)
	pdfReport: NmrDrmOrderBatchReportFileModel;
	@Type(() => NmrDrmOrderBatchReportFileModel)
	csvReport: NmrDrmOrderBatchReportFileModel;

	@Type(() => Date)
	uploadDate: Date;
}
