import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getLabValidationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		name: yup
			.string()
			.max(32, t('organization.name-should-have-max-32-characters'))
			.trim()
			.required(t('organization.lab-name-required')),
		technology: yup
			.string()
			.nullable()
			.max(250)
			.trim()
			.oneOf(Object.values(SupportedTechnology), t('organization.invalid-lab-technology'))
			.required(t('organization.lab-technology-required')),
		adminEmail: yup
			.string()
			.email(t('organization.email-is-invalid'))
			.max(250)
			.trim()
			.required(t('organization.lab-admin-email-required')),
	});
