import { LoadingLayout } from '@components/common';
import { useComponentUpdated, useService } from '@hooks';
import { IRoute } from '@models';
import { ROUTES } from '@routes';
import { authService } from '@services';
import { refreshUserSelector, userSelector } from '@store/slices/common/common.slice';
import { RouterUtils } from '@utils';
import { useSelector } from 'react-redux';
import { matchRoutes, Navigate, useLocation } from 'react-router-dom';
import { from } from 'rxjs';
import { ExtendedComponent } from 'src/types';

interface IAuthorizationComponentProps {
	path: string;
}

export const AuthorizationComponent: ExtendedComponent<IAuthorizationComponentProps> = ({ path, children }) => {
	const loc = useLocation();

	const refreshUser = useSelector(refreshUserSelector);

	const isComponentUpdated = useComponentUpdated(() => undefined, [refreshUser ?? undefined]);

	const matches = matchRoutes(ROUTES, loc);
	const route = matches?.slice(-1)?.[0].route as IRoute;

	const currentUser = useSelector(userSelector);
	const { loading, data: user, error } = useService(() => from(authService.getUser(true, { forceRefresh: true })), [refreshUser]);
	const requiredPath = RouterUtils.getUsersPathName(currentUser);

	if ((loading && !isComponentUpdated) || (currentUser && !requiredPath)) {
		return <LoadingLayout />;
	}
	if (error) {
		return <Navigate to="/login" state={{ redirect: loc.pathname }} />;
	}
	if (user && user.roles && user.permissions) {
		if (!RouterUtils.checkUserHasAccessToPage(route, user) || (requiredPath && requiredPath !== path)) {
			return <Navigate to={`/${requiredPath}`} state={{ redirect: loc.pathname }} />;
		}
	}
	const redirectUrl = window.localStorage.getItem('redirectUrl');

	if (redirectUrl) {
		window.localStorage.removeItem('redirectUrl'); // REMOVE IT from storage and redirect here
		return <Navigate to={redirectUrl} />;
	}
	return children ? <>{children}</> : <Navigate to={path} replace />;
};
