import { Button, Card, ClickAwayListener, Popper, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import AddIcon from '@mui/icons-material/Add';
import { useState, useLayoutEffect } from 'react';
import { FormikTextfield } from '@components/common';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RxUtils } from '@utils/Rx';
import { nmrDrmWaveService } from '@services/nmr-drm-pipeline';
import { useDispatch } from 'react-redux';
import { setTriggerBacklogRefresh, setTriggerWaveRefresh } from '@store/slices/nmr/pipeline.slice';
import { useRestrictedComponent } from '@hooks';
import { NmrAdminPermission } from '@models/user';

export const AddWaveOverlay = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
	const [popperWidth, setPopperWidth] = useState(380);
	const open = Boolean(anchorEl);
	const { t } = useTranslation('admin');

	const dispatch = useDispatch();

	const refreshData = () => {
		dispatch(setTriggerWaveRefresh());
		dispatch(setTriggerBacklogRefresh());
	};

	useLayoutEffect(() => {
		setPopperWidth((window.innerWidth * 380) / 1440);
	});

	const formik = useFormik<Extendable<{ name: string }>>({
		initialValues: {
			name: '',
		},
		validationSchema: yup.object({
			name: yup.string().max(250).required(''),
		}),
		onSubmit: (values) => {
			RxUtils.promisify(nmrDrmWaveService.post(values.name), () => (refreshData(), onClose()));
		},
	});

	const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		formik.resetForm();
	};

	const onClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{useRestrictedComponent(
				NmrAdminPermission.MANAGE_BACKLOG,
				<Button
					sx={{
						width: 'fit-content',
					}}
					data-testid="add-wave-test-id"
					variant="text"
					onClick={onButtonClick}
				>
					<AddIcon sx={{ fontSize: '16px' }} />
					<Typography variant="subtitle3" sx={{ paddingTop: 0.2, paddingLeft: 0.5 }} color="primary.main">
						<Tr.Admin path="drm-pipeline.nmr.backlog.add-wave" />
					</Typography>
				</Button>,
			)}

			<Popper open={open} anchorEl={anchorEl} placement="bottom-end">
				<ClickAwayListener onClickAway={onClose}>
					<form onSubmit={formik.handleSubmit}>
						<Card sx={{ borderRadius: 0.5, padding: 3, width: popperWidth }}>
							<FormikTextfield
								name="name"
								title={<Tr.Admin path="drm-pipeline.nmr.backlog.name" />}
								placeholder={t('drm-pipeline.nmr.backlog.name')}
								formikApi={formik}
								required
							/>
							<Stack direction="row" justifyContent="flex-end" spacing={1.5} marginTop={3}>
								<Button variant="text" size="medium" onClick={onClose}>
									<Tr.Admin path="drm-pipeline.nmr.backlog.cancel" />
								</Button>
								<Button
									variant="contained"
									size="medium"
									type="submit"
									sx={{ width: '58px' }}
									disabled={!formik.isValid || !formik.dirty}
								>
									<Tr.Admin path="drm-pipeline.nmr.backlog.save" />
								</Button>
							</Stack>
						</Card>
					</form>
				</ClickAwayListener>
			</Popper>
		</>
	);
};
