import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';

import { DataTestId } from '@utils/DataTestId';

export const PeakRangeInput: FC<
	TextFieldProps & { valueChange: (val: number) => void; suffixforNumeric: boolean; rangeMax: number; isRequiredField?: boolean }
> = ({ title, valueChange, placeholder, suffixforNumeric, rangeMax, inputProps, isRequiredField, ...props }) => (
	<Stack direction="column">
		<Typography className={isRequiredField ? 'required' : ''} variant="label-s" sx={{ marginBottom: 1.25 }}>
			{title}
		</Typography>
		<NumericFormat
			customInput={TextField}
			allowNegative={false}
			decimalScale={0}
			data-testid={DataTestId.getStaticTestId('solvent-sample-weight-input-id')}
			isAllowed={({ value }) => (value && value.length > 0 ? +value < rangeMax && +value > 0 : true)}
			sx={{
				backgroundColor: 'primary.100',
				...props.sx,
			}}
			inputProps={inputProps}
			onValueChange={(value) => valueChange(value.floatValue as number)}
			value={props.value as number}
			placeholder={placeholder}
			suffix={suffixforNumeric ? ' [cm⁻¹]' : ''}
			disabled={props.disabled}
			onBlur={props.onBlur}
		/>
	</Stack>
);
