// TODO: do it with axios
export const downloadFileFromLink = (uri: string, filename?: string) => {
	if (uri)
		fetch(uri).then((response) => {
			response.blob().then((blob) => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				// Setting various property values
				const alink = document.createElement('a');
				alink.href = fileURL;
				if (filename) alink.download = filename;
				alink.click();
			});
		});
};

export const downloadFileFromContent = (content: any, filename?: string) => {
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(content);
	if (filename) link.download = filename;

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
