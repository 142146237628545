import { Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ReactNode } from 'react';
import { ExtendedComponent } from 'src/types';

type TSingleSelectMenuAddButton = {
	content: ReactNode | string;
};
export const SingleSelectMenuAddButton: ExtendedComponent<TSingleSelectMenuAddButton> = ({ content }) => {
	return (
		<>
			<Stack direction="row" alignItems="center" spacing={1} sx={{ color: 'primary.main' }}>
				<AddIcon sx={{ width: 20 }} />
				<Typography color="primary.main" variant="pg-m">
					{content}
				</Typography>
			</Stack>
		</>
	);
};
