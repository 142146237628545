import { FC } from 'react';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import { NmrCalibrationStatus } from '@services';
import { Chip, Typography } from '@mui/material';
import { TypeUtils } from '@utils/Type';

type TStatusStyle = {
	color: string;
	backgroundColor: string;
};

const StatusStyleMapping: Record<NmrCalibrationStatus, TStatusStyle> = {
	[NmrCalibrationStatus.INACTIVE]: {
		color: 'grey.500',
		backgroundColor: 'grey.100',
	},
	[NmrCalibrationStatus.ACTIVE]: {
		color: 'white',
		backgroundColor: 'success.main',
	},
	[NmrCalibrationStatus.CALIBRATING]: {
		color: 'text.primary',
		backgroundColor: 'warning.main',
	},
	[NmrCalibrationStatus.CALIBRATION_ERROR]: {
		color: 'white',
		backgroundColor: 'error.main',
	},
	[NmrCalibrationStatus.CALIBRATION_NEEDED]: {
		color: 'white',
		backgroundColor: 'error.main',
	},
	[NmrCalibrationStatus.CALIBRATION_CHECK_NEEDED]: {
		color: 'text.primary',
		backgroundColor: 'warning.main',
	},
};

export const MerckCalibrationChip: FC<{ status: NmrCalibrationStatus }> = ({ status }) => {
	const style = TypeUtils.returnValueOfKey<typeof StatusStyleMapping, TStatusStyle>(status, StatusStyleMapping);

	return (
		<Chip
			size="small"
			sx={{ ...style }}
			data-testid={DataTestId.getStaticTestId('merck-calibration-chip-id')}
			label={
				<Typography variant="subtitle3">
					<Tr.Portal path={`device-management.${status.toLowerCase()}`} />
				</Typography>
			}
		/>
	);
};
