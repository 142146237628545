import { Type } from 'class-transformer';
import { NmrCalibrationStatus } from './nmr-device-status.enum';
import { NmrDeviceCalibration } from './nmr-device-calibration.class';

export class NmrRecalibrateModel {
	name: string;
	status: NmrCalibrationStatus;
	manufacturer: string;
	frequency: string;
	probeId: string;
	calibrationValue: number;
	isGxP: boolean;

	@Type(() => Date)
	lastCalibrationTime: Date;
	@Type(() => Date)
	nextCalibrationTime: Date;

	calibrationErrorMessage: string;
	deviceCalibrations?: NmrDeviceCalibration[];

	public isInActive() {
		return this.status === NmrCalibrationStatus.INACTIVE;
	}

	public isActive() {
		return this.status === NmrCalibrationStatus.ACTIVE;
	}

	public isCalibrateHealthCheck() {
		return this.status === NmrCalibrationStatus.CALIBRATION_CHECK_NEEDED;
	}

	public isCalibrationNeeded() {
		return this.status === NmrCalibrationStatus.CALIBRATION_NEEDED;
	}

	public isCalibrating() {
		return this.status === NmrCalibrationStatus.CALIBRATING;
	}

	public isCalibrate() {
		return this.status === NmrCalibrationStatus.CALIBRATION_CHECK_NEEDED || this.status === NmrCalibrationStatus.CALIBRATION_NEEDED;
	}

	public isCalibrationError() {
		return this.status === NmrCalibrationStatus.CALIBRATION_ERROR;
	}
}
