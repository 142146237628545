import { TOptions } from 'i18next';

export enum NmrDrmOrderUploadErrorType {
	FILE_CONTENT_ERROR = 'FileContentError',
	FOLDER_CONTENT_ERROR = 'FolderContentError',
	UNEXPECTED_ERROR = 'UnexpectedError',
	INVALID_ACQUS_FILE = 'InvalidAcqusFile',
	DUPLICATED_ACQUS_FILE = 'DuplicatedAcqusFile',
	MISSING_ACQUS_FILE = 'MissingAcqusFile',
	INVALID_SOLVENT = 'InvalidSolvent',
	INVALID_HEADER_COUNT = 'InvalidHeaderCount',
	HEADERS_MISSING = 'HeadersMissing',
	INCONSISTENT_FILE_NAME = 'InconsistentFileName',
}

export class NmrDrmOrderUploadError extends Error {
	translateOptions?: TOptions;
	translationMessage: string;
	constructor(message: string, name: NmrDrmOrderUploadErrorType, translateOptions?: TOptions) {
		super(message);
		this.translationMessage = message;
		this.name = name;
		this.translateOptions = translateOptions;
	}
}
