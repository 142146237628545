import { Type } from 'class-transformer';
export class IrLibrary {
	library: string;
	count: number;
	libraryCode: string;
	libraryName: string;
	libraryId: number;

	isDefault?: boolean;
	isUserLibrary?: boolean;

	@Type(() => Date)
	createTime: Date;
}

export class IrAddLibrary {
	libraryName: string;
}
