import { QualityGrade, QualityGradeChipColorMapping } from '@models/reference-material';
import { Chip, SxProps, Typography } from '@mui/material';
import { TypeUtils } from '@utils/Type';
import { FC } from 'react';

interface ITechnologyChipProps {
	technology: string;
	qualityGrade?: QualityGrade;
	isActive?: boolean;
	sx?: SxProps;
}

const TechnologyColorMapping: Record<string, { background: string; text: string }> = {
	NMR: {
		background: '#DEFBE4',
		text: 'primary.main',
	},
	IR: {
		background: '#FFEDEE',
		text: 'error.main',
	},
};

export const TechnologyChip: FC<ITechnologyChipProps> = ({ technology, isActive = false, qualityGrade, sx }) => {
	const style = TypeUtils.returnValueOfKey<typeof QualityGradeChipColorMapping, typeof QualityGradeChipColorMapping.Drm>(
		qualityGrade,
		QualityGradeChipColorMapping,
	);
	const technologyColor = qualityGrade ? style.background : TechnologyColorMapping[`${qualityGrade}`];
	const technologyTextColorActive = qualityGrade ? style.text : TechnologyColorMapping[`${qualityGrade}`];

	return (
		<Chip
			sx={{
				borderRadius: '0.25rem',
				padding: 0,
				paddingX: 0.75,
				' .MuiChip-label': { padding: 0 },
				backgroundColor: isActive ? technologyColor : 'grey.100',
				...sx,
			}}
			label={
				<Typography variant="label-s" sx={{ padding: 0 }} color={isActive ? technologyTextColorActive : 'grey.500'}>
					{technology}
				</Typography>
			}
		/>
	);
};
