import { createTheme, Theme } from '@mui/material';

interface CustomThemeProperties {}

export type CustomThemeDefinition = Theme & CustomThemeProperties;

declare module '@mui/material/styles' {
	interface TypographyVariants {
		'pg-xl': React.CSSProperties;
		'pg-l': React.CSSProperties;
		'pg-m': React.CSSProperties;
		'pg-s': React.CSSProperties;
		'pg-xs': React.CSSProperties;
		'caption-s': React.CSSProperties;
		'caption-l': React.CSSProperties;
		'caption-m': React.CSSProperties;
		'label-m': React.CSSProperties;
		'label-s': React.CSSProperties;
		'label-xs': React.CSSProperties;
		body3: React.CSSProperties;
		subtitle3: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		'pg-xl'?: React.CSSProperties;
		'pg-l'?: React.CSSProperties;
		'pg-m'?: React.CSSProperties;
		'pg-s'?: React.CSSProperties;
		'pg-xs'?: React.CSSProperties;
		'caption-s'?: React.CSSProperties;
		'caption-l'?: React.CSSProperties;
		'caption-m'?: React.CSSProperties;
		'label-m'?: React.CSSProperties;
		'label-s'?: React.CSSProperties;
		'label-xs'?: React.CSSProperties;
		body3?: React.CSSProperties;
		subtitle3?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		'pg-xl': true;
		'pg-l': true;
		'pg-m': true;
		'pg-s': true;
		'pg-xs': true;
		'caption-s': true;
		'caption-l': true;
		'caption-m': true;
		'label-m': true;
		'label-s': true;
		'label-xs': true;
		body3: true;
		subtitle3: true;
	}
}

// created by using Material-UI Theme Creator
// See: https://bareynol.github.io/mui-theme-creator
export const CUSTOM_THEME: CustomThemeDefinition = Object.assign<Theme, CustomThemeProperties>(
	createTheme({
		components: {
			MuiAlert: {
				styleOverrides: {
					root: {
						padding: '1rem',
						borderRadius: '0.75rem',
						'& .MuiAlert-icon': {
							padding: 0,
						},
						'& .MuiAlert-message': {
							padding: 0,
						},
						'& .MuiAlert-action': {
							padding: 0,
						},
					},
				},
				variants: [
					{
						props: { variant: 'standard', severity: 'error' },
						style: {
							backgroundColor: '#FFF6F8',
							'& .MuiAlert-icon': {
								color: '#E61E50',
							},
						},
					},
				],
			},
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: 'none',
						borderRadius: 4,
						borderWidth: '2px',
						lineHeight: '120%',
						fontWeight: 700,
						whiteSpace: 'nowrap',
						minWidth: 'auto',
						alignItems: 'center',
						borderColor: '#01884c',
						'&.Mui-disabled': {
							opacity: 0.3,
							borderWidth: 2,
							'&.MuiButton-contained': {
								backgroundColor: '#01884c',
								color: '#FFFFFF',
								'&.MuiButton-containedSecondary': {
									backgroundColor: '#FFC832',
									color: '#000000',
								},
								'&.MuiButton-containedError': {
									backgroundColor: '#E61E50',
								},
							},
							'&.MuiButton-outlined': {
								color: '#01884c',
								borderColor: '#01884c',
								'&.MuiButton-outlinedSecondary': {
									color: '#FFC832',
									borderColor: '#FFC832',
								},
								'&.MuiButton-outlinedError': {
									color: '#E61E50',
									borderColor: '#E61E50',
								},
							},
						},
						'&:hover': {
							borderWidth: 2,
						},
					},
					sizeLarge: {
						fontSize: 20,
						height: 50,
					},
					sizeMedium: {
						fontSize: 16,
						height: 40,
					},
					sizeSmall: {
						fontSize: 14,
						height: 32,
					},
				},
				defaultProps: { disableFocusRipple: true },
			},
			MuiCard: {
				variants: [
					{
						props: { variant: 'elevation' },
						style: {
							borderRadius: '0.5rem',
							boxShadow: '0px 1px 4px 0px rgba(15, 26, 46, 0.15), 0px 0px 1px 0px rgba(15, 26, 46, 0.15)',
						},
					},
				],
			},
			MuiChip: {
				styleOverrides: {
					root: {
						borderRadius: '0.25rem',
					},
				},
			},
			MuiDivider: {
				variants: [
					{
						props: { variant: 'fullWidth', orientation: 'horizontal' },
						style: {
							height: 2,
							border: 'none',
							backgroundColor: '#E1E1EA',
						},
					},
					{
						props: { variant: 'fullWidth', orientation: 'vertical' },
						style: {
							borderColor: '#E1E1EA',
							borderRightWidth: '0.125rem',
						},
					},
				],
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						width: 'fit-content',
					},
				},
			},
			MuiLinearProgress: {
				styleOverrides: {
					root: {
						width: '100%',
						height: '0.5rem',
						borderRadius: '0.25rem',
					},
					colorPrimary: {
						backgroundColor: '#E1E1EA',
					},
					bar: {
						borderRadius: '0.25rem',
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						'&:has(input)': {
							backgroundColor: '#F8F8FC',
						},
						'&:has(.MuiSelect-select)': {
							backgroundColor: '#fff',
						},
						'& fieldset': {
							borderColor: '#E1E1EA !important',
							padding: 0,
							borderWidth: '1.5px',
						},
						'& .MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: '#8B8B9C',
							'&::placeholder': {
								WebkitTextFillColor: '#8B8B9C',
								opacity: 1,
							},
						},
						'& .MuiInputBase-root:has(.MuiSelect-select).Mui-disabled': {
							'& > .MuiOutlinedInput-notchedOutline': {
								borderColor: '#8cffcb',
							},
						},
						// if disabled on sibling, change fieldset's border color
						'& .MuiInputBase-input.Mui-disabled + .MuiOutlinedInput-notchedOutline': {
							borderColor: '#E1E1EA',
						},

						// Select disabled
						'& .MuiSelect-select.Mui-disabled ~ .MuiOutlinedInput-notchedOutline': {
							border: '1.5px solid #E1E1EA',
						},
						// This is implemented to fix borderWidth extending on focus
						'&.Mui-focused': {
							'& .MuiOutlinedInput-notchedOutline': {
								borderWidth: '1.5px',
							},
						},
						'&.Mui-error': {
							input: {
								color: '#E61E50',
								backgroundColor: '#FFF6F8',
								'&::placeholder': {
									color: '#E61E50',
								},
							},
							'& fieldset': {
								borderColor: 'red !important',
								padding: 0,
								borderWidth: '1.5px',
							},

							backgroundColor: '#FFF6F8',
						},
						'&:hover': {
							'&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
								borderColor: '#A9A9BA',
							},
						},
						input: {
							padding: '7px 14px',
							fontSize: 16,
							fontWeight: 400,
							height: 26, // 40px input 26 + 7 * 2
							verticalAlign: 'middle',
							lineHeight: '160%',
							backgroundColor: '#F8F8FC',
							color: '#0F1A2E',
							'&:-webkit-autofill': {
								transition: 'background-color 50000000s ease-in-out 0s',
								// WebkitTextFillColor: '#000000 !important',
							},
							'&::placeholder': {
								color: '#5E5E72',
							},
						},
						// Helper Text background color should not inherit FormHelperClass'es background color
						'& + .MuiFormHelperText-root.Mui-error': {
							position: 'absolute',
							top: '96%',
							backgroundColor: 'transparent',
							marginLeft: 0,
							marginTop: 5,
							fontSize: 14,
							fontWeight: 700,
							lineHeight: '120%',
							letterSpacing: '0.2px',
							'&::before': {
								content: 'url(/assets/warning.svg)',
								display: 'inline-block',
								transform: 'translateY(2px)',
								marginRight: 6,
							},
						},
					},
					disabled: {},
				},
			},
			MuiNativeSelect: {
				styleOverrides: {
					root: {
						'& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
							borderColor: '#ff00ff',
						},
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					rounded: {
						borderRadius: 8,
					},
					outlined: {
						border: '1.5px solid #E1E1EA',
					},
				},
			},
			MuiTabs: {
				styleOverrides: {
					root: {
						position: 'relative',
						'&::before': {
							bottom: 0,
							content: '""',
							width: '100%',
							height: '3px',
							position: 'absolute',
							backgroundColor: '#ECECF4',
						},
					},
					indicator: {
						height: '3px',
						background: 'primary.main',
					},
				},
			},

			MuiTab: {
				styleOverrides: {
					root: {
						opacity: 1,
						fontWeight: '700',
						fontSize: '1rem',
						lineHeight: '1rem',
						textTransform: 'capitalize',
						color: '#5E5E72',
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						background: '#FFFFFF',
						color: '#000000',
						boxShadow: '0px 16px 32px rgba(15, 26, 46, 0.15), 0px 0px 4px rgba(15, 26, 46, 0.15);',
						padding: '0.5em 1rem',
						fontSize: '14px',
						fontWeight: '450',
					},
					arrow: {
						overflow: 'hidden',
						color: '#FFFFFF',
					},
				},
			},
			MuiSwitch: {
				styleOverrides: {
					root: {
						width: 52,
						height: 32,
						padding: 0,
						margin: 4,
					},
					switchBase: {
						color: '#FFFFFF',
						padding: 0,
						margin: 4,
						transitionDuration: '300ms',
					},
					colorPrimary: {
						color: '#FFFFFF',
						'&.Mui-checked': {
							color: '#FFFFFF.',
						},
					},
					thumb: {
						color: '#FFFFFF',
						boxSizing: 'border-box',
						width: 24,
						height: 24,
					},
					track: {
						opacity: 1,
						backgroundColor: '#01884C',
						'.Mui-checked.Mui-checked + &': {
							opacity: 1,
							backgroundColor: '#01884C',
						},
						'.Mui-checked.Mui-disabled + &': {
							opacity: 0.3,
							backgroundColor: '#01884C',
						},
						border: 0,
						borderRadius: 52 / 2,
					},
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					colorSecondary: {
						color: '#FFFFFF',
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						'& .MuiFormHelperText-root': {
							position: 'absolute',
							top: '95%',
							backgroundColor: 'transparent',
							marginLeft: 0,
							marginTop: 5,
							fontSize: 14,
							fontWeight: 700,
							lineHeight: '120%',
							letterSpacing: '0.2px',
							color: '#E61E50',
							'&::before': {
								content: 'url(/assets/warning.svg)',
								display: 'inline-block',
								transform: 'translateY(2px)',
								marginRight: 6,
							},
						},
					},
				},
			},

			MuiAutocomplete: {
				styleOverrides: {
					root: {
						backgroundColor: '#fff',
						// error state background color
						'& .Mui-error': {
							backgroundColor: '#FFF6F8',
							'& .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
								color: '#E61E50',
							},
						},
						input: {
							'&::placeholder': {
								color: '#0F1A2E',
								opacity: 1,
							},
						},
						// the cover element
						fieldset: {
							padding: 0,
							borderColor: 'grey.200',
							borderWidth: '1.5px',
						},

						// for all of states
						'& .MuiOutlinedInput-root': {
							padding: 0,
							paddingLeft: 9,
						},
						'& .MuiOutlinedInput-root:not(.Mui-error)': {
							backgroundColor: '#fff',
						},
						'& .MuiOutlinedInput-root:not(.Mui-error) .MuiAutocomplete-input': {
							color: 'black',
							backgroundColor: '#fff',
							paddingTop: 7,
							paddingBottom: 7,
							'&::placeholder': {
								color: '#0F1A2E',
							},
						},
						'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
							paddingTop: 7,
							paddingBottom: 7,
						},
						'& .MuiFormHelperText-root.Mui-error': {
							position: 'absolute',
							top: '93%',
						},
					},
				},
			},
			MuiPaginationItem: {
				styleOverrides: {
					root: {
						'&.Mui-selected': {
							background: 'rgba(1, 136, 76, 0.10)',
						},
						'&:hover': {
							background: 'rgba(1, 136, 76, 0.20)',
						},
						'&.Mui-selected:hover': {
							background: 'rgba(1, 136, 76, 0.20)',
						},
					},
				},
			},
		},
		palette: {
			mode: 'light',
			grey: {
				'50': '#F8F8FC',
				'100': '#ECECF4',
				'200': '#E1E1EA',
				'300': '#DCDCE8',
				'400': '#A9A9BA',
				'500': '#8B8B9C',
				'600': '#E0E0E0',
				'700': '#9E9E9E',
				'800': '#5E5E72',
				'900': '#000',
			},

			primary: {
				main: '#01884C', // Merck
				dark: '#002D0E',
				light: '#A5CD50',
				'50': '#01884C1A',
				'100': '#F4FBF8',
				'200': '#085897',
				'300': '#04642E',
				'400': '#DEFBE4',
			},
			secondary: {
				main: '#FFC832', // Merck
				'50': '#FEFBF5',
				'300': '#FDE5A6', // Merck
			},
			background: {
				default: '#F8F8FC', // Merck
				paper: '#ffffff',
			},
			text: {
				primary: '#0F1A2E', // Merck
				secondary: '#01884C', // Merck
			},
			success: {
				main: '#01884C',
				'400': '#50C073',
				'900': '#002D0E',
			},
			error: {
				main: '#E61E50', // Merck
				100: '#EB3C96',
				200: '#FFF6F8',
				300: '#FFEDEE',
			},
			warning: {
				main: '#FFC832',
				light: '#FEFBF5',
			},
			info: {
				main: '#50A5F1',
				dark: '#0F69AF',
				'600': '#F2ECFD',
				'700': '#8A68D2',
				'800': '#9E7EE0',
				'900': '#503291',
			},
		},
		typography: {
			fontFamily: ['Lato', 'sans-serif'].join(', '),
			allVariants: {
				color: '#0F1A2E', // Merck
			},
			// Headers
			h1: {
				fontSize: 32,
				fontWeight: 700,
				lineHeight: '140%',
			},
			h2: {
				fontSize: 26,
				fontWeight: 700,
				lineHeight: '140%',
			},
			h3: {
				fontSize: 22,
				fontWeight: 700,
				lineHeight: '140%',
			},
			h4: {
				fontSize: 18,
				fontWeight: 700,
				lineHeight: '140%',
			},
			h5: {
				fontSize: 16,
				fontWeight: 700,
				lineHeight: '140%',
			},
			h6: {
				fontSize: 14,
				fontWeight: 700,
				lineHeight: '140%',
			},
			// Captions
			'caption-s': {
				fontSize: 10,
				fontWeight: 700,
				lineHeight: '160%',
				letterSpacing: '10%',
			},
			'caption-l': {
				fontSize: 20,
				fontWeight: 700,
				lineHeight: '140%',
				letterSpacing: '15%',
			},
			'caption-m': {
				fontSize: 14,
				fontWeight: 700,
				lineHeight: '140%',
				letterSpacing: '10%',
			},
			// Paragraph
			'pg-xl': {
				fontSize: 22,
				fontWeight: 400,
				lineHeight: '160%',
			},
			'pg-l': {
				fontSize: 18,
				fontWeight: 400,
				lineHeight: '160%',
			},
			'pg-m': {
				fontSize: 16,
				fontWeight: 400,
				lineHeight: '160%',
			},
			'pg-s': {
				fontSize: 14,
				fontWeight: 400,
				lineHeight: '160%',
			},
			'pg-xs': {
				fontSize: 12,
				fontWeight: 400,
				lineHeight: '160%',
			},
			// Labels
			'label-m': {
				fontSize: 16,
				fontWeight: 400,
				lineHeight: '140%',
			},
			'label-s': {
				fontSize: 14,
				fontWeight: 700,
				lineHeight: '120%',
				letterSpacing: '0.2px',
			},
			'label-xs': {
				fontSize: 12,
				fontWeight: 700,
				lineHeight: '120%',
				letterSpacing: '0.2px',
			},
			// body (120% line height 400 weight)
			body1: {
				fontSize: 16,
				fontWeight: 400,
				lineHeight: '120%',
			},
			body2: {
				fontSize: 14,
				fontWeight: 400,
				lineHeight: '120%',
			},
			body3: {
				fontSize: 12,
				fontWeight: 400,
				lineHeight: '120%',
			},
			// subtitle (120% 700)
			subtitle1: {
				fontSize: 22,
				fontWeight: 700,
				lineHeight: '120%',
			},
			subtitle2: {
				fontSize: 16,
				fontWeight: 700,
				lineHeight: '120%',
			},
			subtitle3: {
				fontSize: 14,
				fontWeight: 700,
				lineHeight: '120%',
			},
		},
	}),
	{},
);
