import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import { ISubPath } from '../Breadcrumb';
import { Tr } from '@utils/Translation';
import { OverflowText } from '../OverflowText';
import { TypeUtils } from '@utils/Type';

interface IBreadcrumbItemProps {
	subPath: ISubPath;
	isActive: boolean;
	data: object;
}

export const BreadcrumbItem: FC<IBreadcrumbItemProps> = ({ subPath, isActive, data }: IBreadcrumbItemProps) => {
	const { navigateTo, entityKey, textLabel, dynamicParameterList } = subPath;
	const navigate = useNavigate();

	const dynamicParameterHandler = () => {
		let newNavigation = navigateTo;
		dynamicParameterList?.forEach((parameter) => {
			// eslint-disable-next-line
			newNavigation = newNavigation?.replace(parameter, data ? data[`${parameter}`] : '');
		});
		return newNavigation;
	};
	const navigatePath: string = dynamicParameterList ? (dynamicParameterHandler() as string) : (navigateTo as string);
	const renderedText = entityKey && data ? TypeUtils.returnValueOfKey<typeof data, ReactNode>(entityKey, data) : undefined;
	return (
		<Link style={{ cursor: 'pointer' }} underline="hover" data-testid="breadcrumb-item" onClick={() => navigate(navigatePath)}>
			<OverflowText variant={isActive ? 'label-s' : 'pg-s'} color={isActive ? 'primary.main' : 'black'}>
				{renderedText ?? <Tr.Common path={`breadcrumb.${textLabel}`} />}
			</OverflowText>
		</Link>
	);
};
