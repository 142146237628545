import { Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import { FC } from 'react';

type emptyBacklogTableprops = { searchTextfieldValue?: string | undefined };

export const EmptyBacklogTable: FC<emptyBacklogTableprops> = ({ searchTextfieldValue }) => {
	return (
		<Stack
			sx={{
				alignItems: 'center',
				height: '454px',
				paddingTop: '56px',
			}}
		>
			<Stack
				sx={{
					width: 80,
					height: 80,
					borderRadius: '50%',
					backgroundColor: 'grey.100',
					justifyContent: 'center',
					alignItems: 'center',
					marginBottom: 3,
				}}
				data-testid={DataTestId.getStaticTestId('empty-backlog-id')}
			>
				<SearchIcon sx={{ width: 40, height: 40, color: 'grey.400' }} />
			</Stack>
			<Typography variant="h3" color="grey.800" marginBottom={1}>
				<Tr.Admin path="drm-pipeline.nmr.backlog.no-result" />
			</Typography>
			<Typography variant="pg-m" color="grey.800">
				<Tr.Admin
					path={
						searchTextfieldValue
							? 'drm-pipeline.nmr.backlog.no-result-found-for'
							: 'drm-pipeline.nmr.backlog.no-result-explanation'
					}
					options={searchTextfieldValue ? { searchText: searchTextfieldValue } : {}}
				/>
			</Typography>
		</Stack>
	);
};
