import { PagedResult } from '@models/request-response';
import { Type } from 'class-transformer';
import { NmrDrmDetail } from '../nmr-drm';

export class NmrSubstance {
	id: number;
	name: string;
	synonyms: string;
	casNumber: string;
	linearMolecularFormula: string;
	molecularWeight: number;
	smilesCode: string;
	@Type(() => Date)
	createTime: Date;
	isTheoretical: boolean;
	@Type(() => NmrDrmDetail)
	referenceMaterials: NmrDrmDetail[];
	selectedSubItemId?: number;
	@Type(() => NmrDrmDetail)
	selectedReferenceMaterials?: NmrDrmDetail[];
}

export class NmrSubstanceListResponse {
	substances: PagedResult<NmrSubstanceListItem>;
	totalSubstanceCount: number;
	totalTheoreticalSpectrumCount: number;
}

export class NmrSubstanceListItem extends NmrSubstance {
	searchString?: string;
	totalTheoreticalSpectrumCount: number;
	theoreticalStartIndex: number;
}

export interface ISubstanceGetModel {
	query: string;
	pageIndex: number;
	pageSize: number;
	sortBy?: string;
	sortOrder?: string;
	isLatestUsed: boolean;
	isTheoretical: boolean;
}

export class NmrAnalysisSubstanceListItem extends NmrSubstance {
	selectedSubItemId?: number;
}
