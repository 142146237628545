import { FC, ReactNode } from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { innerTitleSpacer } from '@utils';

export interface ISectionWithSubtitleProps extends StackProps {
	subtitle?: ReactNode;
}

export const SectionWithSubtitle: FC<ISectionWithSubtitleProps> = ({ subtitle, children, ...props }) => {
	return (
		<Stack {...props}>
			{subtitle ? (
				<Typography variant="h2" sx={{ color: 'primary.main', marginBottom: innerTitleSpacer }}>
					{subtitle}
				</Typography>
			) : null}
			{children}
		</Stack>
	);
};
