import { PatchType } from '@models/request-response';
import { UserStatus } from '@models/user';
import { userService } from '@services/core';
import { RxUtils } from '@utils/Rx';
interface StatusContent {
	color: string;
	buttonText: string;
	saveDisabled: boolean;
}
export const StatusContentMapping: Record<UserStatus.ACTIVE | UserStatus.INACTIVE, StatusContent> = {
	[UserStatus.ACTIVE]: {
		color: 'error.main',
		buttonText: 'users-and-roles.deactivate',
		saveDisabled: false,
	},
	[UserStatus.INACTIVE]: {
		color: 'primary.main',
		buttonText: 'users-and-roles.reactivate',
		saveDisabled: true,
	},
};

export const handleStatusUpdate = (id: string, isActive: boolean, callback: () => void) => {
	RxUtils.promisify(
		userService.updateMerckUser(id, {
			op: PatchType.REPLACE,
			path: '/Status',
			value: !isActive,
		}),
		callback,
	);
};
