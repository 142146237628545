import { TabDivider } from '@components/common/TabDivider';
import { PortalPageRoutes } from '@models/router';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { setBreadcrumb } from '@store/slices/common/common.slice';
import { Tr } from '@utils/Translation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
const routes = [PortalPageRoutes.REFERENCES, PortalPageRoutes.LIBRARIES];

type ValidRoutes = (typeof routes)[number];
export const NmrUserReferenceManagement = () => {
	const [currentTab, setCurrentTab] = useState<ValidRoutes>(PortalPageRoutes.REFERENCES);

	const navigate = useNavigate();
	const { t } = useTranslation('portal');
	const location = useLocation();
	const dispatch = useDispatch();

	const setTab = useCallback((route: ValidRoutes) => {
		setCurrentTab(route);
		navigate(route);
	}, []);

	useEffect(() => {
		const pieces = location.pathname.split('/');
		const result = pieces?.[pieces.length - 1];
		const targetTab = routes.find((route) => route === result);
		if (targetTab) {
			setCurrentTab(targetTab);
		} else {
			navigate(currentTab, { replace: true });
		}
	}, [location]);

	useEffect(() => {
		dispatch(
			setBreadcrumb({
				tab: t(`user-references.${currentTab}`),
			}),
		);
	}, [t, currentTab]);

	return (
		<Stack>
			<Stack>
				<Typography variant="h1" color="primary.main">
					<Tr.Portal path="user-references.title" />
				</Typography>
			</Stack>
			<Tabs
				value={currentTab}
				textColor="primary"
				sx={{ position: 'relative', marginTop: 3, marginBottom: 5 }}
				onChange={(_, tab) => setTab(tab)}
			>
				<Tab
					value={PortalPageRoutes.REFERENCES}
					label={t(`user-references.${PortalPageRoutes.REFERENCES}`)}
					sx={{
						color: currentTab === PortalPageRoutes.REFERENCES ? 'primary.main' : 'grey.800',
					}}
				/>

				<Tab
					value={PortalPageRoutes.LIBRARIES}
					label={t(`user-references.${PortalPageRoutes.USER_LIBRARIES}`)}
					sx={{
						color: currentTab === PortalPageRoutes.LIBRARIES ? 'primary.main' : 'grey.800',
					}}
				/>

				<TabDivider />
			</Tabs>
			<Outlet />
		</Stack>
	);
};
