import { FC, useContext } from 'react';
import { Typography } from '@mui/material';

import { GenericCard } from '@components/common';
import { Tr } from '@utils';
import { IrAnalysisTypeIndicator } from '@components/ir-portal/IrAnalysisTypeIndicator';
import { IrAnalysisResultContext } from './Iranalysis-result-context';
import { AnalysisCommentField } from './IrAnalysisCommentField';
import { IrAnalysisResultTable } from '@components/ir-portal/IrAnalysisResultTable/IrAnalysisResultTable';
import { IrResultImpurites, RankBasedMixtureData } from '@services/ir';

interface IAnalysisMatchTableProps {
	rankedRMData: RankBasedMixtureData[];
	selectedRMIndex: number;
	onChangeMatchTable: (index: number) => void;
	resultImpurities?: IrResultImpurites[];
}

export const AnalysisMatchTable: FC<IAnalysisMatchTableProps> = ({
	rankedRMData,
	selectedRMIndex,
	onChangeMatchTable,
	resultImpurities,
}) => {
	const { analysisResultData } = useContext(IrAnalysisResultContext);
	return analysisResultData &&
		!analysisResultData.isExecuting() &&
		(analysisResultData.isExecutedSuccessful() || analysisResultData.isRejected()) ? (
		<GenericCard
			sx={{
				boxShadow: '0px 0px 1px rgba(15, 26, 46, 0.15), 0px 1px 4px rgba(15, 26, 46, 0.15)',
				border: 'none',
				display: analysisResultData.isIdentification() ? 'flex' : 'block',
				paddingX: 3,
				paddingY: 4,
			}}
			enableWidthControl={true}
		>
			<Typography variant="h3" sx={{ color: 'primary.main' }} mb={1.5}>
				<Tr.IrPortal path="analysis-result.analysis-results" />
			</Typography>
			{!analysisResultData.isIdentification() && !analysisResultData.isQuantification() && (
				<Typography variant="body1" sx={{ color: 'grey.800', marginTop: '0.6rem' }}>
					<Tr.IrPortal path="analysis-result.purity-label" />
				</Typography>
			)}
			{analysisResultData.isQuantification() && analysisResultData.irAnalysis.quantificationMethod === 'PeakRatio' && (
				<Typography variant="body1" sx={{ color: 'grey.800', marginTop: '0.6rem' }}>
					<Tr.IrPortal path="analysis-result.quantification-label" />
				</Typography>
			)}
			{analysisResultData.isQuantification() && analysisResultData.irAnalysis.quantificationMethod === 'SinglePeakComparison' && (
				<Typography variant="body1" sx={{ color: 'grey.800', marginTop: '0.6rem' }}>
					<Tr.IrPortal path="analysis-result.quantification-label-single" />
				</Typography>
			)}
			{analysisResultData.isIdentification() && (
				<IrAnalysisTypeIndicator sx={{ color: 'grey.800', marginTop: '0.6rem' }} type={analysisResultData.irAnalysis.type} />
			)}

			<IrAnalysisResultTable
				onChangeMatchTable={onChangeMatchTable}
				rankedRMData={rankedRMData}
				selectedRMIndex={selectedRMIndex}
				resultImpurities={resultImpurities}
			/>

			{!analysisResultData.isMatched() && <AnalysisCommentField />}
		</GenericCard>
	) : null;
};
