import { DeactivationConfirmPopUp } from '@components/common';
import { UserResponseModel } from '@models/user';
import { Button, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusContentMapping, handleStatusUpdate } from './EditUserButtons.logic';
import { alertService } from '@services/core';
import { DataTestId } from '@utils/DataTestId';

interface IEditUserButtons {
	onCancel: () => void;
	onSave: () => void;
	isValid: boolean;
	refreshDataCallback: () => void;
	user: UserResponseModel;
}

export const EditUserButtons: FC<IEditUserButtons> = ({ onCancel, onSave, refreshDataCallback, user, isValid }) => {
	const [isDeactivateModalOpened, setIsDeactivateModalOpened] = useState(false);
	const { t } = useTranslation('admin');
	const USER_STATUS_CONTENT = StatusContentMapping[user.status];

	const handleReactivateRender = () => {
		alertService.send({
			confirmTextHeader: <Tr.Admin path="users-and-roles.reactivate-user" />,
			titleText: <Tr.Admin path={'users-and-roles.confirmation'} />,
			confirmTextComponent: <Tr.Admin path={'users-and-roles.reactivate-save'} />,
			closeTextComponent: <Tr.Admin path="users-and-roles.cancel" />,
			onConfirm: () => handleStatusUpdate(user.id, user.isActive(), refreshDataCallback),
			content: <Tr.Admin path="users-and-roles.reactivate-user-explanation" options={{ fullName: user.fullName }} />,
		});
	};

	const onDeactivationSuccess = () => {
		setIsDeactivateModalOpened(false);
		refreshDataCallback();
	};
	return (
		<Stack width={1} justifyContent="space-between" flexDirection="row">
			{isDeactivateModalOpened && (
				<DeactivationConfirmPopUp
					visible={isDeactivateModalOpened}
					title={<Tr.Common path="confirmation" />}
					message={t('users-and-roles.deactivation-message')}
					subTitle={
						<>
							<Stack>
								<Typography variant="h3" lineHeight="120%">
									<Tr.Admin path="users-and-roles.deactivate-user" />
								</Typography>
							</Stack>
							<Stack>
								<Typography variant="pg-m">
									{t('users-and-roles.deactivate-explanation', { fullName: user.fullName ?? '-' })}
								</Typography>
							</Stack>
						</>
					}
					onConfirm={() => {
						handleStatusUpdate(user.id, user.isActive(), onDeactivationSuccess);
					}}
					onCancel={() => setIsDeactivateModalOpened(false)}
				/>
			)}
			<Button
				data-testid={DataTestId.getStaticTestId('reactivate-deactivate-button')}
				size="medium"
				variant="text"
				sx={{
					color: USER_STATUS_CONTENT.color,
				}}
				onClick={() => (user.isActive() ? setIsDeactivateModalOpened(true) : handleReactivateRender())}
			>
				<Tr.Admin path={USER_STATUS_CONTENT.buttonText} />
			</Button>
			<Stack flexDirection="row" gap={1.5}>
				<Button
					size="medium"
					variant="outlined"
					onClick={() => {
						onCancel();
					}}
					data-testid={DataTestId.getStaticTestId('close-edit-admin-id')}
				>
					<Tr.Admin path="users-and-roles.cancel" />
				</Button>
				<Button
					data-testid={DataTestId.getStaticTestId('save-changes-button')}
					size="medium"
					variant="contained"
					onClick={() => onSave()}
					disabled={USER_STATUS_CONTENT.saveDisabled || !isValid}
				>
					<Tr.Admin path="users-and-roles.save-changes" />
				</Button>
			</Stack>
		</Stack>
	);
};
