import { DataTableBackend, OverflowText, QualityGradeChip } from '@components/common';
import { TableButton } from '@components/common/TableButton';
import { getParams, useFilterSearchParams, useRestrictedComponent, useService } from '@hooks';
import { AdminPermission, ExtendedColumn, PageRoutes, SortOrder } from '@models';
import { Button, Stack, Typography, TypographyProps } from '@mui/material';
import { IrDrmDetail, irDrmService } from '@services';
import { DateUtils, RxUtils, Tr } from '@utils';
import { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IR_DRM_DATABASE_FILTER_OPTIONS } from './ir-drm-database-filter';
import { Row } from 'react-table';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';

const cellWidth = { minWidth: 100, maxWidth: 400, width: '100%' };

const Body1: FC<TypographyProps & { active?: boolean }> = ({ sx, active = true, ...props }) => (
	<Typography variant="pg-s" sx={{ minWidth: 100, color: getColor(active), padding: 0, ...sx }} {...props} />
);

const getColor = (active: boolean) => (active ? 'text.primary' : 'grey.400');

const StatusButton: FC<{ isActive: boolean; label: ReactNode }> = ({ isActive, label }) => {
	return (
		<Button
			sx={{ backgroundColor: isActive ? 'primary.main' : 'grey.400', pointerEvents: 'none', height: '24px', padding: 0.75 }}
			variant="contained"
			disableElevation
		>
			<Typography variant="subtitle3" sx={{ lineHeight: '80%' }}>
				{label}
			</Typography>
		</Button>
	);
};

const getIrDrmActive = (row: Row<IrDrmDetail>) => {
	return row.original.status === UserReferenceStatus.Active;
};

export const IrDrmDatabase: FC = () => {
	const { searchParams, filtering, setFiltering } = useFilterSearchParams('systemCreationDate', SortOrder.DESC);
	const [typedString, setTypedString] = useState('');

	const { t } = useTranslation('admin');

	const { data: ermList } = useService(() => {
		setTypedString(getParams(searchParams).getQuery());
		return irDrmService.getBySearchParams(searchParams);
	}, [searchParams]);

	const columns = useMemo<ExtendedColumn<IrDrmDetail>[]>(
		() => [
			{
				accessor: 'fileName',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.status" />,
				Cell: ({ row }) => (
					<StatusButton
						isActive={getIrDrmActive(row)}
						label={
							getIrDrmActive(row) ? (
								<Tr.Admin path="substance-management.active" />
							) : (
								<Tr.Admin path="substance-management.inactive" />
							)
						}
					/>
				),
			},
			{
				accessor: 'drmCode',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.drm-reference-number" />,
				Cell: ({ value, row }) => (
					<OverflowText variant="pg-s" sx={{ color: getColor(getIrDrmActive(row)), ...cellWidth }}>
						{value || '-'}
					</OverflowText>
				),
			},
			{
				accessor: 'qualityGrade',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.quality-grade" />,
				Cell: ({ row }) => (
					<>
						<QualityGradeChip isReferenceActive={getIrDrmActive(row)} qualityGrade={row?.original?.qualityGrade} />
					</>
				),
			},
			{
				accessor: 'library',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.library" />,
				Cell: ({ value, row }) => (
					<OverflowText variant="pg-s" sx={{ color: getColor(getIrDrmActive(row)), ...cellWidth }}>
						{value || '-'}
					</OverflowText>
				),
			},
			{
				accessor: 'substance',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.substance" />,
				Cell: ({ value, row }) => (
					<OverflowText
						data-testid="substance-detail-id"
						sx={{ color: getColor(getIrDrmActive(row)) }}
						enableTooltip={true}
						variant="pg-s"
					>
						{value}
					</OverflowText>
				),
			},
			{
				accessor: 'casNumber',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.cas-number" />,
				Cell: ({ value, row }) => <Body1 active={getIrDrmActive(row)}>{value}</Body1>,
			},
			{
				accessor: 'version',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.version" />,
				Cell: ({ value, row }) => <Body1 active={getIrDrmActive(row)}>{value || '-'}</Body1>,
			},
			{
				accessor: 'systemCreationDate',
				Header: <Tr.Admin path="substance-management.creation-date" />,
				Cell: ({ value, row }) => (
					<Body1 active={getIrDrmActive(row)}>{value ? DateUtils.getFormattedDate(new Date(value)) : '-'}</Body1>
				),
			},
			{
				accessor: 'id',
				sticky: true,
				Header: <></>,
				disableSortBy: true,
				Cell: ({ value }) => (
					<Stack direction="row" justifyContent="flex-end" sx={{ mr: 2 }}>
						{useRestrictedComponent(
							AdminPermission.VIEW_DRM,
							<TableButton.Outlined
								data-testid="goto-erm-test-id"
								size="small"
								href={`/admin/${PageRoutes.DRM_MANAGEMENT}/${PageRoutes.IR}/${PageRoutes.EDIT_DRM}/${value}`}
							>
								<Tr.Admin path="substance-management.view-details" />
							</TableButton.Outlined>,
						)}
					</Stack>
				),
			},
		],
		[],
	);

	function onSaveAsCsv(): void {
		RxUtils.promisify(
			irDrmService.exportAsCsv({ query: getParams(searchParams).getQuery(), filteringData: filtering }),
			(blob: Blob) => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const a = document.createElement('a');
				a.href = url;
				a.download = 'dRM Database ' + new Date().toISOString().split('T')[0] + '.csv';
				document.body.appendChild(a);
				a.click();
				a.remove();
			},
		);
	}

	return (
		<>
			<DataTableBackend
				data={ermList?.data || []}
				columns={columns}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: ermList?.totalPages ?? 1 }}
				searchText={typedString}
				onSearchTextChange={setTypedString}
				searchPlaceholder={t('substance-management.search-placeholder')}
				searchFieldProps={{ sx: { maxWidth: 700, width: 700 }, 'aria-label': 'erm-db-search-id' }}
				showExportButton={true}
				exportButtonText={t('substance-management.export-csv')}
				onExportButtonClick={() => onSaveAsCsv()}
				filterData={IR_DRM_DATABASE_FILTER_OPTIONS}
				onFilter={setFiltering}
				filteringSelections={filtering}
				sx={{ marginX: 2 }}
			/>
		</>
	);
};
