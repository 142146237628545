import { FC } from 'react';
import { Autocomplete, AutocompleteProps, Stack, SxProps, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { FormikApiType, TextInputWithFormikApi } from '../FormikTextfield';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type T = any;
interface IFomikAutoCompleteProps {
	name: string;
	title?: string;
	formik?: FormikApiType;
	options?: unknown[];
	typographyProps?: TypographyProps;
	autoCompleteSx?: SxProps;
	onInputChange?: (data: T) => void;
	onSelectionChange: (data: T) => void;
	autocompleteProps?: Omit<
		AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>,
		'renderInput' | 'options'
	>;
}

export const FormikAutoComplete: FC<IFomikAutoCompleteProps & TextFieldProps & { 'data-testid'?: string }> = ({
	formik,
	name,
	onInputChange,
	onSelectionChange,
	required,
	options,
	title,
	disabled,
	value,
	typographyProps: { sx: typographySx, ...typographyProps } = {},
	autoCompleteSx,
	autocompleteProps,
	...params
}) => {
	return (
		<Stack>
			{title && (
				<Typography
					className={required && !disabled ? 'required' : ''}
					variant="label-s"
					color={disabled ? 'grey.200' : 'grey.800'}
					sx={{ marginBottom: 1.25, ...typographySx }}
					{...typographyProps}
				>
					{title}
				</Typography>
			)}

			<Autocomplete
				onChange={(_, newValue) => onSelectionChange(newValue)}
				onInputChange={(_, newValue) => onInputChange?.(newValue)}
				sx={{ ...autoCompleteSx }}
				options={options || []}
				disabled={disabled}
				value={value}
				popupIcon={
					<KeyboardArrowDownIcon
						sx={{
							color: disabled ? 'grey.200' : 'primary.main',
						}}
					/>
				}
				renderInput={(inputParam) =>
					formik && (
						<TextInputWithFormikApi
							{...params}
							{...inputParam}
							name={name}
							api={formik}
							required={required}
							disabled={disabled}
							sx={{ ...params.sx }}
						/>
					)
				}
				{...autocompleteProps}
			/>
		</Stack>
	);
};
