import { useFormik } from 'formik';
import { IRecalibrationType } from './DeviceUtils';
import { useTranslation } from 'react-i18next';
import { NmrDeviceNamesResponse } from '@services';
import { getRecalibrationValidationSchema } from '@schemas';

export enum DeviceFormType {
	NONE = 'none',
	NEW_DEVICE = 'newDevice',
	RECALIBRATE = 'recalibrate',
	NEW_CALIBRATION = 'newCalibration',
	HEALTH_CHECK = 'healthCheck',
}

export interface DeviceFormikProps {
	deviceData?: IRecalibrationType;
	onSubmit: () => void;
	deviceNamesResponse?: NmrDeviceNamesResponse;
	formType?: DeviceFormType;
}

export const useDeviceFormik = ({ deviceData, onSubmit, deviceNamesResponse, formType }: DeviceFormikProps) => {
	const { t } = useTranslation('portal');

	return useFormik<Extendable<{ deviceName: string; calibrationName: string; type: DeviceFormType }>>({
		initialValues: {
			calibrationName: '',
			deviceName: '',
			type: formType ?? DeviceFormType.NONE,
		},
		validationSchema: getRecalibrationValidationSchema(t, deviceNamesResponse?.deviceNames, deviceData),
		initialTouched: {
			calibrationName: false,
			deviceName: false,
		},
		onSubmit: onSubmit,
		validateOnChange: true,
		validateOnBlur: false,
	});
};
