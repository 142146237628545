import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';

export const NotificationEmpty: FC = () => {
	return (
		<Stack data-testid={DataTestId.getStaticTestId('notification-empty-id')} margin="auto" justifyContent="center" alignItems="center">
			<Box
				sx={{
					width: '7.5rem',
					height: '7.5rem',
					backgroundColor: '#DEFBE4',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: '50%',
				}}
			>
				<NotificationsIcon sx={{ width: '4rem', height: '4rem' }} color="primary" />
			</Box>
			<Typography variant="h3" marginTop="1.5rem">
				<Tr.Notification path="up-to-date" />
			</Typography>
			<Typography variant="pg-m" marginTop="0.5rem">
				<Tr.Notification path="empty-explanation" />
			</Typography>
		</Stack>
	);
};
