export interface IrMethod {
	label: string;
	name: string;
}
export const Methods: IrMethod[] = [
	{
		label: 'Single Peak Comparison',
		name: 'SinglePeakComparison',
	},
	{
		label: 'Peak Ratio',
		name: 'PeakRatio',
	},
];
