import { plainToClassFromExist, plainToInstance } from 'class-transformer';

export class TypeUtils {
	public static isDefined(input: unknown): boolean {
		return input !== undefined && input !== null;
	}

	public static isKeyOf<T extends Object>(key: Maybe<keyof any>, obj: T): key is keyof T {
		if (key && obj) {
			return key in obj;
		}
		return false;
	}

	public static returnValueOfKey<T extends Object, K>(key: Maybe<any>, obj: T): K {
		if (!obj) return undefined as unknown as K;
		if (TypeUtils.isKeyOf(key, obj)) {
			return obj[key as keyof T] as unknown as K;
		}
		return undefined as unknown as K;
	}

	public static returnValueOfKeys<T extends Object, K>(keys: Maybe<any>[], obj: T): K {
		if (!obj) return undefined as unknown as K;
		if (!Array.isArray(keys)) {
			return undefined as unknown as K;
		}
		const value = keys.reduce<Maybe<keyof T | keyof unknown> | unknown>((prev, key) => {
			return TypeUtils.returnValueOfKey(key, prev as Object);
		}, obj);

		return (value ?? null) as unknown as K;
	}

	private static isDangerousKey(key: Maybe<string | number | symbol>): boolean {
		return key === '__proto__' || key === 'constructor' || key === 'prototype';
	}

	public static assignValueOfKey<T extends Object>(key: Maybe<keyof unknown | keyof T>, obj: T, value: unknown) {
		if (Object.getPrototypeOf(obj) !== null) {
			throw new Error('Object must be created with Object.create(null) to ensure it has no prototype');
		}

		if (this.isDangerousKey(key)) {
			throw new Error(`Attempted to assign to dangerous key: ${String(key)}`);
		}

		if (TypeUtils.isKeyOf(key, obj)) {
			obj[key as keyof T] = value as T[keyof T];
		} else {
			throw new Error(`Invalid key: ${String(key)} for the provided object.`);
		}
	}

	public static isPositiveFloat(input: unknown): boolean {
		return (
			(TypeUtils.isDefined(input) && typeof input === 'number' && !isNaN(input) && input >= 0) ||
			(TypeUtils.isDefined(input) &&
				typeof input === 'string' &&
				!isNaN(parseFloat(input)) &&
				parseFloat(input) >= 0 &&
				/[0-9]*\.?[0-9]+/.test(input))
		);
	}

	public static isFloat(input: unknown): boolean {
		return (
			(TypeUtils.isDefined(input) && typeof input === 'number' && !isNaN(input)) ||
			(TypeUtils.isDefined(input) &&
				typeof input === 'string' &&
				!isNaN(parseFloat(input)) &&
				parseFloat(input) >= 0 &&
				/[0-9]*\.?[0-9]+/.test(input))
		);
	}

	public static isPositiveNumber(input: unknown): boolean {
		return (
			(TypeUtils.isDefined(input) && typeof input === 'number' && !isNaN(input) && input >= 0) ||
			(TypeUtils.isDefined(input) &&
				typeof input === 'string' &&
				!isNaN(parseInt(input, 10)) &&
				parseInt(input, 10) >= 0 &&
				/[0-9]+$/.test(input))
		);
	}

	public static isNumber(input: unknown): boolean {
		return (
			(TypeUtils.isDefined(input) && typeof input === 'number' && !isNaN(input)) ||
			(TypeUtils.isDefined(input) && typeof input === 'string' && !isNaN(parseInt(input, 10)) && /[0-9]+$/.test(input))
		);
	}

	public static isPositiveInteger(input: unknown): boolean {
		return (
			(TypeUtils.isDefined(input) && typeof input === 'number' && !isNaN(input)) ||
			(TypeUtils.isDefined(input) && typeof input === 'string' && !isNaN(parseInt(input, 10)) && /^\d+$/.test(input))
		);
	}

	public static isDate(input: unknown): boolean {
		return TypeUtils.isDefined(input) && Object.prototype.toString.call(input) === '[object Date]' && !isNaN((input as Date).getTime());
	}
	public static transform: typeof plainToInstance = plainToInstance;
	public static transformFromExist: typeof plainToClassFromExist = plainToClassFromExist;
}
