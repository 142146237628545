export enum SupportedTechnology {
	NMR = 'NMR',
	IR = 'IR',
}

export type TechnologyFields = {
	[key: string]: number;
};

export type TechnologyObject = {
	technology: string;
	price: number;
};
