import { Type } from 'class-transformer';
import { ReferenceTechnique } from './technique.enum';

interface ISubstanceReference {
	id: number;
	isActive: boolean;
	referenceId: number;
	substanceId: number;
	technique: ReferenceTechnique;
}

export class Substance {
	id: number;
	name: string;
	casNumber: string;
	@Type(() => Date)
	createTime: Date;
	isTheoretical: boolean;
	isTheoreticalActive: boolean;
	linearMolecularFormula?: string;
	molecularWeight?: number;
	smilesCode?: string;
	synonyms?: string;
	references: ISubstanceReference[];
	qualityGrade?: string;

	public hasNmrReference() {
		return this.references?.some((r) => r.technique === ReferenceTechnique.NMR_REFERENCE_MATERIAL);
	}

	public hasIrReference() {
		return this.references?.some((r) => r.technique === ReferenceTechnique.IR_REFERENCE_MATERIAL);
	}
}

export interface ISubstanceSearchModel {
	query: string;
	pageIndex: number;
	pageSize: number;
	sortBy?: string;
	sortOrder?: string;
	filteringData: unknown[];
}
