import { FC, useContext } from 'react';
import { IrAnalysisResult, RankBasedMixtureData, SampleSpectrumInfo } from '@services';
import { Grid } from '@mui/material';
import { renderPeakItensityDataGrid, getTargetAndSampleTableofAssignmentPeaks } from './IrTableOfAssignmentHelperComponents';
import { IrAnalysisResultContext } from '@routes/Portal/ir/IrAnalysisResult';

interface IQualitativeProps {
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
	resultData: IrAnalysisResult;
}

export const IrIdentificationTableOfAssignment: FC<IQualitativeProps> = ({ rankedRMData }) => {
	const { table_of_assignment } = rankedRMData;
	const { analysisResultData } = useContext(IrAnalysisResultContext);
	const reference_peak_intensity = (table_of_assignment && table_of_assignment['reference_peak_intensity']) || [];
	const sample_peak_intensity = (table_of_assignment && table_of_assignment['sample_peak_intensity']) || [];
	const peak_pos = (table_of_assignment && table_of_assignment['peak_pos']) || [];
	const targetSubstanceTitle = rankedRMData?.mixtures?.length > 1 ? `Mixture ${rankedRMData?.rank}` : rankedRMData?.mixtures[0]?.name;
	const sampleTitle = analysisResultData?.irAnalysis?.title || '';

	const displayPeakPosition = () => {
		if (peak_pos && peak_pos?.length > 0) {
			if (peak_pos) {
				return <>{renderPeakItensityDataGrid('', peak_pos, false, 'peak-values')}</>;
			}
		}
	};

	return rankedRMData ? (
		<>
			<div>
				<Grid item container alignItems="center" xs="auto">
					{displayPeakPosition()}
					{getTargetAndSampleTableofAssignmentPeaks(
						reference_peak_intensity,
						sample_peak_intensity,
						targetSubstanceTitle,
						sampleTitle,
					)}
				</Grid>
			</div>
		</>
	) : (
		<></>
	);
};
