import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './reducers';
import { RootState } from './types';
import { PersistPartial } from 'redux-persist/lib/persistReducer';

interface TRootState extends PersistPartial, RootState {}

const createStore = (preloadedState?: TRootState) => {
	return configureStore({
		preloadedState,
		reducer: persistReducer(
			{
				key: 'ui-store',
				storage,
			},
			rootReducer,
		),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}),
	});
};

const store = createStore();
const persistor = persistStore(store);

export const getStore = () => ({ store, persistor });
