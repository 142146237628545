import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { DeactivationConfirmPopUp, GenericCard, MolecularFormula, SpectrumChart, PortalBadge, QualityGradeChip } from '@components/common';
import { alertService, notificationService, nmrDrmService, NmrDrmDetail } from '@services';
import { RxUtils, Tr } from '@utils';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common/common.slice';
import { differenceInWeeks } from 'date-fns';
import { NmrReferenceMaterialDetails, SubstanceTechnology, TheoreticalSpectrumDetails } from '@components/admin';
import { TechnologyPermissionKeys } from '@models/user';
import { useRestrictedComponent } from '@hooks';
import { IUploadedFile } from '@models/file';
import { getReferenceBorderColor } from '@utils/QualityGrade';

export const EditNmrDrm = () => {
	const { id } = useParams();
	const { t } = useTranslation('admin');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1350));

	const [confirmation, setConfirmation] = useState<{ visible: Maybe<boolean> }>({ visible: null });
	const [drmFile, setDrmFile] = useState<string>();
	const [drm, setDrm] = useState<NmrDrmDetail>();
	const tech = t('drm');
	const { isActive } = drm ?? { isActive: true };

	const changeErmStatus = (msg: string) => {
		id &&
			RxUtils.promisify(
				nmrDrmService.updateErmActivity(+id, msg, !isActive),
				() => (
					setConfirmation({ visible: false }),
					notificationService.sendSuccess(
						isActive
							? t('substance-detail.deactivated-successfully', { tech: tech, ermName: drm?.name })
							: t('substance-detail.activated-successfully', { tech: tech, ermName: drm?.name }),
					),
					navigate(-1)
				),
			);
	};

	useEffect(() => {
		if (id) {
			RxUtils.promisify(nmrDrmService.get(+id), (drmDetail) => {
				dispatch(
					setBreadcrumb({
						drmProductNumber: drmDetail?.ermProductNumber ? drmDetail?.ermProductNumber : t('substance-detail.drm-detail'),
						':id': drmDetail?.substanceId,
					}),
				);

				setDrm(drmDetail);
			});

			RxUtils.promisify(nmrDrmService.getERMFile(+id), (data) => {
				setDrmFile(data);
			});
		}
	}, [id]);

	const isNew = useMemo(() => (drm?.createTime ? Math.abs(differenceInWeeks(new Date(drm?.createTime), new Date())) < 1 : false), [drm]);

	const borderColor = getReferenceBorderColor(drm?.qualityGrade, !isActive);

	const molecularFormulaRef = React.createRef<HTMLDivElement>();
	return (
		<>
			<GenericCard dataTestId="generic-card-id" containerSx={{ flexGrow: 1 }}>
				<Grid container spacing={7}>
					<Grid item md={4} xl={4}>
						<MolecularFormula
							height={media ? 150 : 280}
							sx={{ width: '100%', height: '282px' }}
							deactivateColors={!isActive}
							smilesFormula={drm?.smilesCode}
							ref={molecularFormulaRef}
							borderColor={borderColor}
						/>
						<Box
							position="relative"
							sx={{
								borderWidth: 2,
								borderStyle: 'solid',
								borderColor: isActive ? (borderColor ? borderColor : 'primary.main') : 'grey.800',
								minWidth: '10rem',
								width: '100%',
								borderRadius: 2,
								height: '282px',
								mt: 4,
							}}
						>
							<SpectrumChart
								file={{ content: drmFile } as IUploadedFile}
								title={drm?.name}
								sx={{ padding: 0 }}
								isZoomIconEnabled={isActive}
								isDeactivated={!isActive}
								uniqueId="edit-erm-chart"
								layout={{
									margin: {
										t: 0,
										b: 16,
									},
									height: 230,
								}}
								borderColor={borderColor}
							/>
						</Box>
					</Grid>
					<Grid item sx={{ width: '100%' }} xs={12} md={8} xl={8}>
						<Stack width="100%">
							<Grid container direction="column" spacing={1.5} flexWrap="nowrap">
								<Grid item xs={12}>
									<Stack direction="row" gap={1.5}>
										{drm && (
											<QualityGradeChip isReferenceActive={drm?.isActive || false} qualityGrade={drm?.qualityGrade} />
										)}
										{drm && (
											<SubstanceTechnology.NmrReferenceMaterial
												disabled={!isActive}
												qualityGrade={drm?.qualityGrade}
											/>
										)}

										{isNew && (
											<PortalBadge
												badgeContent={
													<Typography
														color={!drm || drm?.isActive ? 'text.primary' : 'grey.500'}
														variant="subtitle3"
														lineHeight="12px"
													>
														<Tr.Common path="new" />
													</Typography>
												}
												sx={{
													alignItems: 'center',
													'& .MuiBadge-badge': {
														backgroundColor: drm?.isActive ? 'warning.main' : 'grey.100',
													},
												}}
											/>
										)}
									</Stack>
									<Stack marginTop={2} mb={0.5}>
										<Typography variant="h3" color={isActive ? 'text.primary' : 'grey.400'}>
											{drm?.name}
										</Typography>
									</Stack>
								</Grid>
								{drm && (
									<>
										{drm.isTheoretical() ? (
											<TheoreticalSpectrumDetails drm={drm} />
										) : (
											<NmrReferenceMaterialDetails drm={drm} />
										)}
									</>
								)}
							</Grid>
						</Stack>
					</Grid>
				</Grid>
			</GenericCard>

			{useRestrictedComponent(
				TechnologyPermissionKeys.EDIT_DRM,
				<Button
					color={isActive ? 'error' : 'primary'}
					sx={{
						width: '5rem',
						marginTop: 2,
					}}
					size="medium"
					variant="text"
					disableElevation
					data-testid="deactivate-erm-button-id"
					onClick={() => {
						if (!isActive) {
							alertService.send({
								titleText: <Tr.Admin path="substance-detail.confirmation" />,
								content: <Tr.Admin path="substance-detail.reactivation-message" options={{ tech: tech }} />,
								closeTextComponent: <Tr.Common path="cancel" />,
								confirmTextComponent: <Tr.Admin path="substance-detail.reactivate" />,
								confirmTextHeader: <Tr.Admin path="substance-detail.reactivation-subtitle" options={{ tech: tech }} />,
								onConfirm: () => changeErmStatus(''),
								onClose: () => null,
							});
						} else {
							setConfirmation({ visible: true });
						}
					}}
				>
					<Tr.Admin path={isActive ? 'substance-detail.deactivate' : 'substance-detail.reactivate'} />
				</Button>,
				drm?.technology,
			)}

			<DeactivationConfirmPopUp
				visible={!!confirmation.visible}
				message={t('substance-detail.deactivation-reason-placeholder', { tech: tech })}
				title={<Tr.Admin path="substance-detail.confirmation" />}
				subTitle={
					<>
						<Stack>
							<Typography variant="subtitle1">
								<Tr.Admin path="substance-detail.deactivation-subtitle" options={{ tech: tech }} />
							</Typography>
						</Stack>
						<Stack>
							<Typography variant="pg-m">
								<Tr.Admin path="substance-detail.deactivation-message" options={{ tech: tech }} />
							</Typography>
						</Stack>
					</>
				}
				onConfirm={(msg) => changeErmStatus(msg)}
				onCancel={() => setConfirmation({ visible: null })}
				buttonText={<Tr.Admin path="substance-detail.deactivate" />}
			/>
		</>
	);
};
