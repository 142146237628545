import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { concatMap, map, tap } from 'rxjs';
import { coreConfig } from '../coreConfig';
import { httpService } from '../http';
import { logger } from '../logger';
import { Filter } from './filter.class';

class FilterService {
	getURl() {
		return `${coreConfig.url}`;
	}

	ermListFilterOptions() {
		return httpService
			.get<Filter>(this.getURl(), `ReferenceMaterialRequest/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.ermListFilterOptions.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	merckUserFilterOptions() {
		return httpService
			.get<Filter>(this.getURl(), `User/merck/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.merckUserFilterOptions.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	irPipelineWaveReportsFilterOptions() {
		return httpService
			.get<Filter>(this.getURl(), `irdrmpipeline/Wave/reports/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.irPipelineWaveReportsFilterOptions.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	auditLogsFilterOptions() {
		return httpService
			.get<Filter>(this.getURl(), `auditlog/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.auditLogsFilterOptions.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}
}

export const filterService = new FilterService();
