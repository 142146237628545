import { useRef, useContext, useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Stack, TextField, Typography } from '@mui/material';

import { RxUtils, Tr } from '@utils';
import { nmrAnalysisService, notificationService } from '@services';
import { Reason, usePageLeaveDetection } from '@hooks';
import { userSelector } from '@store/slices/common/common.slice';
import { AnalysisResultContext } from './analysis-result-context';

export const NmrAnalysisCommentField: FC = () => {
	const { analysisResultData, analysisId } = useContext(AnalysisResultContext);
	const analysisCommentRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation('portal');
	const user = useSelector(userSelector);
	const [analysisComment, setAnalysisComment] = useState(analysisResultData?.comment || '');

	const isEqualPreviousComment = () => analysisCommentRef.current?.value === analysisComment;
	const isNotMyAnalyze = () => user?.username !== analysisResultData?.nmrAnalysis?.operatorId;
	const canEditComment = () => isNotMyAnalyze() || !!analysisResultData?.confirmationDate;

	const analysisCommentSave = () => {
		!canEditComment() &&
			analysisId &&
			RxUtils.promisify(nmrAnalysisService.comment(analysisId, analysisCommentRef.current?.value || ''), () => {
				setAnalysisComment(analysisCommentRef.current?.value || '');
				notificationService.sendSuccess(t('analysis-result.autosave-message'));
			});
	};

	usePageLeaveDetection(
		(reason) => {
			if (reason === Reason.TIMER && !isEqualPreviousComment() && !analysisResultData?.isExecuting()) {
				analysisCommentSave();
			} else if (reason === Reason.NAVIGATION && !isEqualPreviousComment() && !analysisResultData?.isExecuting()) {
				analysisCommentSave();
				return false;
			}
			return true;
		},
		5000,
		() => !canEditComment(),
		[analysisComment, user, analysisResultData],
	);

	useEffect(() => {
		if (analysisCommentRef.current) {
			analysisCommentRef.current.value = analysisResultData?.comment || '';
			setAnalysisComment(analysisResultData?.comment || '');
		}
	}, [analysisResultData]);

	return (
		<Stack marginTop="1.75rem" sx={{ fontSize: 12 }}>
			<Typography variant="caption-m" letterSpacing="unset">
				<Tr.Portal path="analysis-result.comment" />
			</Typography>
			<TextField
				inputRef={analysisCommentRef}
				multiline
				hiddenLabel
				rows={4}
				placeholder={t('analysis-result.comment-message')}
				variant="outlined"
				sx={{
					marginTop: '0.625rem',
					backgroundColor: 'grey.50',
					pointerEvents: canEditComment() ? 'default' : 'initial',
				}}
				InputProps={{ readOnly: canEditComment() }}
				inputProps={{ 'data-testid': 'comment-text-id', maxLength: 4000, style: { fontSize: 16 } }}
			/>
		</Stack>
	);
};
