import { FC, useState } from 'react';
import { Avatar, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalDialog } from '@components/common';
import { Tr } from '@utils/Translation';
import { notificationService } from '@services';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { RxUtils } from '@utils/Rx';
import { irAccessoryService } from '@services/ir';
import { IrFileUploader } from '@components/ir-portal/IrFileUploader';

export interface IrEditDeviceNameDialogProps {
	deviceId: number;
	onClose: (success?: boolean) => void;
}

export const HealthCheckUploadDialog: FC<IrEditDeviceNameDialogProps> = ({ deviceId, onClose }) => {
	const { t } = useTranslation('irportal');
	const [isDirty, setIsDirty] = useState(false);
	const onUploadedFileChange = (fileType: string, file?: File[], polyStyreneStandardId?: string) => {
		if (!file || '' || file?.length < 1) {
			notificationService.sendError(t('file-invalid'));
			return;
		}
		RxUtils.promisify(
			irAccessoryService.uploadHealthCheckFile(deviceId, file[0], fileType, polyStyreneStandardId || ''),
			() => {
				setIsDirty(true);
				notificationService.sendSuccess(t('new-analysis.success-file-upload'));
			},
			(err) => {
				setIsDirty(false);
				notificationService.sendError(err.Message);
			},
		);
	};
	const getHealth = () => {
		RxUtils.promisify(
			irAccessoryService.chealthCheckComplete(deviceId),
			() => {
				onClose();
			},
			(err) => {
				notificationService.sendError(err.Message);
			},
		);
	};
	return (
		<ModalDialog
			variant="primary"
			fullWidth={false}
			open={true}
			titleProps={{
				sx: {
					justifyContent: 'space-between',
					marginTop: 0,
					marginBottom: 5,
					minHeight: 30,
					backgroundColor: 'grey.50',
					paddingY: 1.2,
					paddingX: 2,
				},
			}}
			title={
				<Typography variant="body1" sx={{ fontWeight: 400, lineHeight: '200%' }}>
					{t('device-management.health-check-upload')}
				</Typography>
			}
			onClose={() => onClose()}
			maxWidth="sm"
			iconButtonProps={{ padding: 0 }}
			contentProps={{ sx: { width: 472, paddingBottom: 8 } }}
		>
			<Stack data-testid="edit-device-wrapper" spacing={0} width={1} direction="column" justifyContent="center" alignItems={'center'}>
				<Stack direction="column" width={1} paddingX={5} justifyContent="center" alignItems={'center'}>
					<Avatar
						style={{
							backgroundColor: '#01884C',
							width: '90px',
							height: '90px',
						}}
					>
						<CloudDoneIcon sx={{ fontSize: 40 }} />
					</Avatar>
					<Typography variant="h5" color="text.primary" marginTop={2}>
						{t('device-management.health-check-accessory')}
					</Typography>
					<Typography variant="h6" sx={{ marginTop: 2, fontSize: '0.9rem', whiteSpace: 'pre-line', textAlign: 'center' }}>
						<Tr.IrPortal path="device-management.irhealtcheck-instruction" />
					</Typography>
				</Stack>

				<Stack direction="row" width={1} paddingX={5}>
					<IrFileUploader
						disable={false}
						multiple={false}
						onUploadedFileChange={(data) => onUploadedFileChange('EmptyCellFile', data as File[], '')}
						sx={{ marginTop: 3 }}
					/>
				</Stack>
				<Stack direction="row" width={1} paddingX={5} marginTop={4}>
					<Button
						data-testid="cancel-btn-id"
						variant="outlined"
						sx={{ marginRight: '1rem', width: '100%' }}
						onClick={() => onClose()}
					>
						<Tr.IrPortal path="device-management.cancel" />
					</Button>
					<Button
						data-testid="submit-button-id"
						type="submit"
						sx={{ width: '100%' }}
						variant="contained"
						disabled={isDirty === false}
						disableElevation
						onClick={() => getHealth()}
					>
						<Tr.IrPortal path="Check" />
					</Button>
				</Stack>
			</Stack>
		</ModalDialog>
	);
};
