import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RxUtils, Tr } from '@utils';
import { Dispatch, FC, SetStateAction } from 'react';
import { FormikTextfield } from '@components/common';

import { notificationService } from '@services/core';
import { Button, Grid } from '@mui/material';
import { NmrDrmOrderCommentInfo, nmrDrmOrderService } from '@services/nmr-drm-pipeline';

type FormFields = Extendable<{
	comment: string;
}>;
interface IDrmOrderCommentFormProps {
	commentInfo?: NmrDrmOrderCommentInfo;
	nmrDrmOrderId?: number;
	setCommentEditId?: Dispatch<SetStateAction<number | undefined>>;
	onCommentUpdate: (value: boolean) => void;
	setIsAddCommentButtonClicked?: (value: boolean) => void;
}

export const DrmOrderCommentForm: FC<IDrmOrderCommentFormProps> = ({
	commentInfo,
	setCommentEditId,
	nmrDrmOrderId,
	onCommentUpdate,
	setIsAddCommentButtonClicked,
}) => {
	const { t } = useTranslation('admin');

	const formik = useFormik<FormFields>({
		initialValues: { comment: commentInfo?.content ? commentInfo.content : undefined },
		validateOnChange: true,
		validationSchema: yup.object({
			comment: yup.string().trim().max(500, t('erm-request-detail.comments.max-comment')),
			isReq: yup.string().when('comment', ([value]) => {
				if (!value) return yup.string().required();
				return yup.string();
			}),
		}),
		onSubmit: () => {
			commentInfo ? handleEditComment(commentInfo) : handleAddComment();
		},
	});

	const handleAddComment = () => {
		const comment = formik.values.comment;
		RxUtils.promisify(
			nmrDrmOrderService.addComment(nmrDrmOrderId ?? 0, comment),
			() => {
				notificationService.sendSuccess(t('drm-order-details.order-comments.add-comment-success'));
			},
			(err) => notificationService.sendError(err.Message),
			() => {
				formik.resetForm();
				onCommentUpdate(true);
				setIsAddCommentButtonClicked?.(false);
			},
		);
	};
	const handleEditComment = (commentInformation: NmrDrmOrderCommentInfo) => {
		const comment = formik.values.comment;
		RxUtils.promisify(
			nmrDrmOrderService.editComment(commentInformation.nmrDrmOrderId, commentInformation.id, comment),
			() => {
				notificationService.sendSuccess(t('drm-order-details.order-comments.edit-comment-success'));
			},
			(err) => notificationService.sendError(err.Message),
			() => {
				formik.resetForm();
				onCommentUpdate(true);
				setIsAddCommentButtonClicked?.(false);
			},
		);
		setCommentEditId?.(undefined);
	};

	const handleCancelClick = () => {
		formik.resetForm();
		setCommentEditId?.(undefined);
		setIsAddCommentButtonClicked?.(false);
	};

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<Grid container direction="column" spacing={3} sx={{ paddingTop: 1 }}>
					<Grid item>
						<FormikTextfield
							formikApi={formik}
							name="comment"
							title={t('drm-order-details.order-comments.comment')}
							placeholder={t('drm-order-details.order-comments.placeholder')}
							sx={{ backgroundColor: 'grey.50' }}
							multiline
							rows={3}
							containerProps={{ width: 1 }}
							inputProps={{
								'data-testid': 'comment-form-id',
								maxLength: undefined,
							}}
						/>
					</Grid>

					<Grid item>
						<Grid container justifyContent="flex-end" spacing={2}>
							<Grid item>
								<Button data-testid="comment-cancel-id" size="small" onClick={handleCancelClick}>
									<Tr.Admin path={`drm-order-details.order-comments.cancel`} />
								</Button>
							</Grid>
							<Grid item>
								<Button
									disabled={!formik.isValid || !formik.dirty}
									type="submit"
									size="small"
									variant="contained"
									data-testid={commentInfo ? 'comment-save-id' : 'comment-add-id'}
								>
									<Tr.Admin
										path={
											commentInfo ? `drm-order-details.order-comments.save` : `drm-order-details.order-comments.add`
										}
									/>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</>
	);
};
