import { FC } from 'react';
import { Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import Delete from '@mui/icons-material/Delete';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Edit from '@mui/icons-material/Edit';
import { IrAccessoryCalibrationStatus } from '@services/ir';
import { ReactComponent as CloudUpload } from '@material-symbols/svg-600/outlined/cloud_upload.svg';
import { DateUtils, TypeUtils } from '@utils/Type';
import { parseISO } from 'date-fns';

export interface AccessoryListProps {
	accessoryName: string;
	lastCalibration: string;
	archivedDate: string;
	calibrationCheck: string;
	calibrationValue: number;
	isError: boolean;
	error: string;
	id: number;
	deviceId: number;
	statusCalibration: IrAccessoryCalibrationStatus;
	statusHealth: number;
	addCalibrate: (index: number, deviceId: number, accessoryName: string) => void;
	restoreAccessory: (index: number) => void;
	deleteCalibrate: (index: number, name: string) => void;
	editAccessory: (index: number, name: string) => void;
	healthCheck: (index: number, statusCalibration: IrAccessoryCalibrationStatus) => void;
}

export const AccessoryList: FC<AccessoryListProps> = ({
	accessoryName,
	lastCalibration,
	archivedDate,
	calibrationCheck,
	calibrationValue,
	id,
	deviceId,
	statusCalibration,
	addCalibrate,
	deleteCalibrate,
	editAccessory,
	restoreAccessory,
	healthCheck,
}) => {
	const AccessoryStatusColorMap: Record<IrAccessoryCalibrationStatus, string> = {
		[IrAccessoryCalibrationStatus.ACTIVE]: 'rgba(0,136,76,0.1)',
		[IrAccessoryCalibrationStatus.CALIBRATED]: 'rgba(0,136,76,0.1)',
		[IrAccessoryCalibrationStatus.CALIBRATION_NEEDED]: 'rgb(250, 208, 44, 0.1)',
		[IrAccessoryCalibrationStatus.INACTIVE]: 'rgba(169,169,169,0.1)',
		[IrAccessoryCalibrationStatus.CALIBRATION_CHECK_NEEDED]: 'rgb(250, 208, 44, 0.1)',
		[IrAccessoryCalibrationStatus.CALIBRATING]: 'rgb(250, 208, 44, 0.1)',
		[IrAccessoryCalibrationStatus.CALIBRATION_ERROR]: 'rgb(255,246,248)',
		[IrAccessoryCalibrationStatus.ARCHIVED]: 'rgba(169,169,169,0.1)',
	};

	const AccessoryStatusTextColorMap: Record<IrAccessoryCalibrationStatus, string> = {
		[IrAccessoryCalibrationStatus.ACTIVE]: 'rgba(0,136,76)',
		[IrAccessoryCalibrationStatus.CALIBRATED]: 'rgba(0,136,76)',
		[IrAccessoryCalibrationStatus.CALIBRATION_NEEDED]: 'rgb(250, 208, 44)',
		[IrAccessoryCalibrationStatus.INACTIVE]: 'rgba(0,0,0)',
		[IrAccessoryCalibrationStatus.CALIBRATION_CHECK_NEEDED]: 'rgb(250, 208, 44)',
		[IrAccessoryCalibrationStatus.CALIBRATING]: 'rgb(250, 208, 44)',
		[IrAccessoryCalibrationStatus.CALIBRATION_ERROR]: 'rgb(255,0,0)',
		[IrAccessoryCalibrationStatus.ARCHIVED]: 'rgba(0,0,0)',
	};

	const getIcon = (status: IrAccessoryCalibrationStatus) => {
		const textColor = TypeUtils.returnValueOfKey(status, AccessoryStatusTextColorMap);

		if (
			status === IrAccessoryCalibrationStatus.CALIBRATION_ERROR ||
			status === IrAccessoryCalibrationStatus.CALIBRATION_NEEDED ||
			status === IrAccessoryCalibrationStatus.CALIBRATION_CHECK_NEEDED
		) {
			return <ErrorRounded sx={{ color: `${textColor} !important` }} />;
		}
		if (status === IrAccessoryCalibrationStatus.ACTIVE || status === IrAccessoryCalibrationStatus.CALIBRATED) {
			return <CheckCircle sx={{ color: `${textColor} !important` }} />;
		}
		if (status === IrAccessoryCalibrationStatus.ARCHIVED || status === IrAccessoryCalibrationStatus.INACTIVE) {
			return <RemoveCircle sx={{ color: `${textColor} !important` }} />;
		} else {
			return <></>;
		}
	};

	const statusColor = TypeUtils.returnValueOfKey<typeof AccessoryStatusColorMap, string>(statusCalibration, AccessoryStatusColorMap);
	const textColor = TypeUtils.returnValueOfKey<typeof AccessoryStatusColorMap, string>(statusCalibration, AccessoryStatusTextColorMap);
	return (
		<Stack sx={{ margin: 1 }}>
			<Stack sx={{ backgroundColor: statusColor }} padding={3}>
				<Stack
					direction="row"
					justifyContent="space-between"
					sx={{ paddingBottom: 2 }}
					borderBottom="1px solid"
					borderColor="grey.200"
				>
					<Chip
						icon={getIcon(statusCalibration)}
						label={
							<Typography variant="h5" color={{ color: textColor }}>
								{accessoryName}
							</Typography>
						}
						clickable={false}
						sx={{
							borderRadius: '0px',
							background: 'none',
						}}
					/>
					{statusCalibration === IrAccessoryCalibrationStatus.ARCHIVED ? (
						<Stack direction="row" alignSelf="flex-end">
							<Button
								variant="outlined"
								data-testid="restoreAccessory"
								sx={{
									marginRight: '1rem',
									backgroundColor: statusColor,
									color: textColor,
									borderColor: textColor,
								}}
								onClick={() => restoreAccessory(id)}
							>
								<Tr.IrPortal path="device-management.restore" />
							</Button>
						</Stack>
					) : (
						<Stack direction="row" alignSelf="flex-end">
							<Button
								variant="outlined"
								data-testid="addCalibrate"
								sx={{
									marginRight: '1rem',
									// TO DO: FIX THIS LINE, IDK WHATS THE BUSINESS HERE
									// backgroundColor: IrAccessoryCalibrationStatus[statusCalibration],
									color: textColor,
									borderColor: textColor,
								}}
								onClick={() => addCalibrate(id, deviceId, accessoryName)}
							>
								<Tr.IrPortal path="device-management.calibrate" />
							</Button>

							<Button onClick={() => deleteCalibrate(id, accessoryName)}>
								<Delete color="inherit" sx={{ color: textColor }} />
							</Button>
							<Button onClick={() => editAccessory(id, accessoryName)}>
								<Edit color="inherit" sx={{ color: textColor }} />
							</Button>
							<Button onClick={() => healthCheck(id, statusCalibration)} data-testid="healthCheck">
								<CloudUpload color="inherit" width={24} height={24} style={{ fill: textColor ?? undefined }} />
							</Button>
						</Stack>
					)}
				</Stack>
				{statusCalibration === IrAccessoryCalibrationStatus.ARCHIVED ? (
					<Grid container columns={3} rowGap={2} margin="1rem 0">
						<Grid item md={1} sm={1} lg={1}>
							<Typography marginBottom={1}>
								<Tr.IrPortal path="device-management.device-info.archived-since" />
							</Typography>
							<Typography variant="h6" fontSize="0.9rem">
								{archivedDate ? DateUtils.getFormattedDate(parseISO(archivedDate)) : '-'}
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Grid container columns={3} rowGap={2} margin="1rem 0">
						<Grid item md={1} sm={1} lg={1} borderRight="1px solid #E1E1EA">
							<Typography marginBottom={1}>
								<Tr.IrPortal path="device-management.device-info.last-calibration-date" />
							</Typography>
							<Typography variant="h6" fontSize="0.9rem">
								{lastCalibration ? DateUtils.getFormattedDate(parseISO(lastCalibration)) : '-'}
							</Typography>
						</Grid>
						<Grid item md={1} sm={1} lg={1} paddingLeft={2} borderRight="1px solid #E1E1EA">
							<Typography
								marginBottom={1}
								color={statusCalibration === IrAccessoryCalibrationStatus.CALIBRATION_ERROR ? '#E61E50' : 'text.primary'}
							>
								<Tr.IrPortal path="device-management.device-info.calibration-check" />
							</Typography>
							<Typography
								variant="h6"
								fontSize="0.9rem"
								color={statusCalibration === IrAccessoryCalibrationStatus.CALIBRATION_ERROR ? '#E61E50' : 'text.primary'}
							>
								{calibrationCheck ? DateUtils.getFormattedDate(parseISO(calibrationCheck)) : '-'}
							</Typography>
						</Grid>
						<Grid item md={1} sm={1} lg={1} paddingLeft={2}>
							<Typography marginBottom={1}>
								<Tr.IrPortal path="device-management.device-info.calibration-value" />
							</Typography>
							<Typography variant="h6" fontSize="0.9rem">
								{calibrationValue ? calibrationValue : '-'}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Stack>

			{statusCalibration === IrAccessoryCalibrationStatus.CALIBRATION_ERROR ? (
				<Typography margin={2} variant="h6" fontSize="0.8rem" sx={{ color: 'rgba(230,31,79)' }}>
					{' '}
					<Tr.IrPortal path="device-management.device-info.calibration-error" />{' '}
				</Typography>
			) : (
				<Typography />
			)}
		</Stack>
	);
};
