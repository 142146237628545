import { SingleSelectMenu, SingleSelectMenuCheckmark, SingleSelectMenuOnSelectType } from '@components/common/SingleSelectMenu';
import { waveToSelectItem } from '@models/drm-order/wave';
import { NmrDrmOrder, UnassignedWave, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { pipelineSelector, setTriggerBacklogRefresh, setTriggerWaveRefresh } from '@store/slices/nmr/pipeline.slice';
import { RxUtils } from '@utils/Rx';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type TWaveDropdownProps = {
	order: NmrDrmOrder;
	readOnly: boolean;
};
export const WaveDropdown: FC<TWaveDropdownProps> = ({ order, readOnly }) => {
	const [selectedItem, setSelectedItem] = useState(waveToSelectItem(order.wave ? order.wave : UnassignedWave));
	const { t } = useTranslation('admin');
	useEffect(() => {
		if (order.wave) setSelectedItem(waveToSelectItem(order.wave));
	}, [order.wave]);
	const { waveData } = useSelector(pipelineSelector);
	const currentWaveItems = [waveToSelectItem(UnassignedWave), ...(waveData?.map((wave) => waveToSelectItem(wave)) ?? [])];

	const dispatch = useDispatch();
	const refreshData = () => {
		dispatch(setTriggerBacklogRefresh());
		dispatch(setTriggerWaveRefresh());
	};

	const onSelect: SingleSelectMenuOnSelectType = (item) => {
		if (order.id) RxUtils.promisify(nmrDrmOrderService.changeOrderWave(order.id, Number(item)), refreshData);
	};

	if (readOnly) {
		return <>{order?.wave?.name ?? t('drm-pipeline.nmr.backlog.unassigned')} </>;
	}

	return (
		<SingleSelectMenu
			items={currentWaveItems}
			onSelectedItemChange={onSelect}
			selectedItem={selectedItem}
			sx={{
				width: '160px',
				height: '32px',
			}}
			preceedor={<SingleSelectMenuCheckmark />}
		/>
	);
};
