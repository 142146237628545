import { FormikTextfield, ModalDialog } from '@components/common';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { formFieldSpacer } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface IAddUserModalProps {
	visible: boolean;
	onClose: () => void;
	onSubmit?: (data: AddLibraryFormFields) => void;
}
export type AddLibraryFormFields = Extendable<{
	libraryName: string;
}>;

export const AddUserLibraryModal: FC<IAddUserModalProps> = ({ visible, onClose, onSubmit }) => {
	const { t } = useTranslation('common');

	const formik = useFormik<AddLibraryFormFields>({
		initialValues: {
			libraryName: '',
		},
		validationSchema: yup.object({
			libraryName: yup.string().required(t('add-user-library.library-name-required')),
		}),
		onSubmit: (values) => {
			onSubmit?.({
				libraryName: values.libraryName,
			});
		},
		initialTouched: {
			libraryName: false,
		},
	});

	const saveUserLibary = () => {
		formik.validateForm();
		formik.submitForm();
	};

	return (
		<ModalDialog
			data-testid="Add-user-library-modal"
			variant="secondary"
			open={visible}
			maxWidth="xs"
			onClose={onClose}
			title={<Typography variant="pg-s">{t('add-user-library.add')}</Typography>}
			contentProps={{ sx: { paddingX: 2 } }}
		>
			<Stack data-testid="edit-library-id" direction="column" sx={{ mt: 3 }}>
				<Typography variant="h3" textAlign="center" sx={{ mb: 3 }}>
					<Tr.Common path="add-user-library.new-library" />
				</Typography>
				<form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
					<Stack paddingX={6} sx={{ mb: 7 }}>
						<Grid container rowSpacing={formFieldSpacer} columnSpacing={3} marginLeft={-2.25}>
							<Grid item xs={12}>
								<FormikTextfield
									formikApi={formik}
									name="libraryName"
									title={t('add-user-library.library-name')}
									placeholder={t('add-user-library.library-name-placeholder')}
									variant="outlined"
									fullWidth
									required={true}
									inputProps={{ 'data-testid': 'add-user-libary-test-id' }}
									onChange={(e) => formik.setFieldValue('libraryName', e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Stack
									flexDirection="row"
									width={'100%'}
									marginBottom={1}
									flexGrow={1}
									alignItems="center"
									justifyContent="space-between"
								>
									<Stack direction="row" flexGrow={1} justifyContent="flex-end" alignItems="end">
										<Grid item xs={6}>
											<Button
												data-testid="cancel-user-library-button-id"
												type="submit"
												onClick={onClose}
												variant="outlined"
												size="medium"
												sx={{ width: '100%' }}
											>
												<Tr.Portal path={t('add-user-library.cancel-button')} />
											</Button>
										</Grid>
										<Grid item xs={6}>
											<Button
												data-testid="add-user-library-button-id"
												onClick={saveUserLibary}
												variant="contained"
												size="medium"
												disabled={!formik.isValid || !formik.dirty}
												sx={{ alignSelf: 'end', marginLeft: 1, width: '100%' }}
											>
												<Tr.Portal path={t('add-user-library.add-button')} />
											</Button>
										</Grid>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					</Stack>
				</form>
			</Stack>
		</ModalDialog>
	);
};
