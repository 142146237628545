import { OverflowText, SelectMenu } from '@components/common';
import { IconButton, Stack, StackProps, Typography } from '@mui/material';
import { FC, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ChangeWavePriority } from './ChangeWavePriority';
import { alertService } from '@services/core';
import { Tr } from '@utils/Translation';
import { RxUtils } from '@utils/Rx';
import { NmrDrmOrderWave, UnassignedWave, nmrDrmWaveService } from '@services/nmr-drm-pipeline';
import { RenameWaveModalDialog } from './RenameWaveModalDialog';
import { useSearchParams } from 'react-router-dom';
import { FilterKey } from '@models/filter';
import { useDispatch } from 'react-redux';
import { setTriggerBacklogRefresh, setTriggerWaveRefresh } from '@store/slices/nmr/pipeline.slice';
import { useTranslation } from 'react-i18next';
import { DataTestId } from '@utils/DataTestId';
type TWaveCardProps = {
	editable: boolean;
	waveData: NmrDrmOrderWave;
};

const sendDeleteWave = (wave: NmrDrmOrderWave, confirmCallback?: () => void) => {
	alertService.send({
		titleText: <Tr.Admin path="drm-pipeline.nmr.backlog.confirmation" />,
		confirmTextHeader: <Tr.Admin path="drm-pipeline.nmr.backlog.delete-wave" />,
		content: <Tr.Admin path="drm-pipeline.nmr.backlog.delete-content" options={{ title: wave.name }} />,
		confirmTextComponent: <Tr.Admin path="drm-pipeline.nmr.backlog.delete" />,
		closeTextComponent: <Tr.Admin path="drm-pipeline.nmr.backlog.cancel" />,
		onConfirm: () => {
			RxUtils.promisify(nmrDrmWaveService.delete(wave.id), () => confirmCallback?.());
		},
	});
};

type TOrderCount = {
	waveData: NmrDrmOrderWave;
};

const getOrderCountStyle = (waveData: NmrDrmOrderWave) => {
	if (waveData.productionOrderCount && waveData?.productionOrderCount > 0) {
		return {
			color: 'primary.main',
			borderColor: 'primary.main',
		};
	}
	return {
		color: 'grey.800',
		borderColor: 'grey.400',
	};
};

const OrderCount: FC<TOrderCount> = ({ waveData }) => {
	const style = getOrderCountStyle(waveData);

	return (
		<Stack sx={{ border: '1px solid', borderRadius: 1, padding: 3 / 4, ...style }}>
			<Typography variant="label-s" lineHeight={6 / 7}>
				{waveData.productionOrderCount ? (
					<Tr.Admin
						path="drm-pipeline.nmr.backlog.production-order"
						options={{
							totalOrderCount: (waveData.productionOrderCount ?? 0) + (waveData.backlogOrderCount ?? 0),
							productionOrderCount: waveData.productionOrderCount,
						}}
					/>
				) : (
					<Tr.Admin path="drm-pipeline.nmr.backlog.orders" options={{ orderCount: waveData.backlogOrderCount ?? 0 }} />
				)}
			</Typography>
		</Stack>
	);
};
export const WaveCard: FC<TWaveCardProps & StackProps> = ({ editable, waveData, ...props }) => {
	const [isRenameModalOpened, setIsRenameModalOpened] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation('admin');

	const onCardClick = () => {
		// TO DO: FIND BETTER SOLUTION FOR CLICKING BUG
		if (isRenameModalOpened) {
			return;
		}
		const waveIdFilters = searchParams.getAll(FilterKey.WAVE_IDS);
		const stringWaveId = String(waveData.id);
		if (waveIdFilters.includes(stringWaveId)) {
			searchParams.delete(FilterKey.WAVE_IDS);
			const filteredWaveId = waveIdFilters.filter((filterId) => filterId !== stringWaveId);
			filteredWaveId.forEach((id) => searchParams.append(FilterKey.WAVE_IDS, id));
			searchParams.set('pageIndex', '1');
			setSearchParams(searchParams);
		} else {
			const unassignedWaveId = String(UnassignedWave.id);
			if (waveIdFilters?.includes(unassignedWaveId)) {
				searchParams.delete(FilterKey.WAVE_IDS);
				const filteredWaveId = waveIdFilters.filter((filterId) => filterId !== unassignedWaveId);
				filteredWaveId.forEach((id) => searchParams.append(FilterKey.WAVE_IDS, id));
			}
			searchParams.set('pageIndex', '1');
			searchParams.append(FilterKey.WAVE_IDS, stringWaveId);
			setSearchParams(searchParams);
		}
	};

	const dispatch = useDispatch();
	const refreshBacklogData = () => {
		dispatch(setTriggerBacklogRefresh());
		dispatch(setTriggerWaveRefresh());
	};

	const WaveCardActionMapping: Record<'rename' | 'delete', { onClick: () => void }> = {
		rename: {
			onClick: () => setIsRenameModalOpened(true),
		},
		delete: {
			onClick: () => sendDeleteWave(waveData, refreshBacklogData),
		},
	};

	const cardSelected = searchParams.getAll(FilterKey.WAVE_IDS).some((id) => id === String(waveData.id));
	return (
		<Stack
			{...props}
			data-testid={DataTestId.getStaticTestId(`wave-card-${waveData.priority}`)}
			onClick={onCardClick}
			sx={{
				borderRadius: 1,
				backgroundColor: 'grey.50',
				padding: 2,
				cursor: 'pointer',
				border: `1.5px solid ${cardSelected ? '#01884C' : 'transparent'}`,
				'&:hover': {
					backgroundColor: 'grey.100',
				},
				...props.sx,
			}}
		>
			<Stack direction="row" justifyContent="space-between" alignItems="center" width={1} marginBottom={2}>
				<OverflowText enableTooltip variant="h6" maxWidth="80%">
					{waveData.name}
				</OverflowText>
				{editable && (
					<SelectMenu
						items={Object.keys(WaveCardActionMapping)}
						onSelect={(item) => WaveCardActionMapping[item as 'delete' | 'rename'].onClick()}
						selectable={false}
						getButton={(event) => (
							<IconButton
								data-testid={DataTestId.getStaticTestId(`wave-menu-${waveData.priority}`)}
								onClick={event}
								sx={{ width: 20, height: 20 }}
							>
								<MoreVertIcon sx={{ width: 20, height: 20 }} />
							</IconButton>
						)}
					/>
				)}
			</Stack>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<OrderCount waveData={waveData} />
				{editable ? (
					<ChangeWavePriority waveData={waveData} />
				) : (
					<Typography variant="label-xs" color="grey.800">
						{`${t('drm-pipeline.nmr.backlog.wave')} ${waveData.priority}`}
					</Typography>
				)}
			</Stack>
			{isRenameModalOpened && (
				<RenameWaveModalDialog
					wave={waveData}
					onCancel={() => setIsRenameModalOpened(false)}
					onSave={() => (setIsRenameModalOpened(false), refreshBacklogData())}
				/>
			)}
		</Stack>
	);
};
