import { Stack, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
interface UserCountProps {
	value?: Number | string;
	typographyProps?: TypographyProps;
}

export const UserCount: FC<UserCountProps> = ({ value = 0, typographyProps }) => {
	return (
		<Stack direction="row" alignItems="center" spacing={0.5}>
			<PersonOutlinedIcon sx={{ color: 'grey.500', lineHeight: '13.33px' }} />
			<Typography variant="pg-s" color="grey.800" {...typographyProps}>
				{value.toString()}
			</Typography>
		</Stack>
	);
};
