import { ErmEventAction, ErmEventLabel, EventCategory } from '@models/analytics';

export enum ErmEventType {
	ERM_SEARCHED = 'ErmSearched',
	ERM_REQUESTED = 'ErmRequested',
	ERM_USED = 'ErmUsed',
	ERM_INPROGRESS = 'ErmInprogress',
	ERM_UNAVAILABLE = 'ErmUnavailable',
}

export const sendEvent = (category: EventCategory, action: ErmEventAction, label: string) =>
	(window as any).gtag('event', action, {
		event_category: category,
		event_label: label,
		value: label,
	});

export const analyticsErmEvent: Record<ErmEventType, (searchKey?: string) => void> = {
	ErmSearched: (searchKey?: string) =>
		sendEvent(EventCategory.Erm, ErmEventAction.ERM_SEARCHED, `${ErmEventLabel.ERM_SEARCHED} - ${searchKey}`),
	ErmRequested: (searchKey?: string) =>
		sendEvent(EventCategory.Erm, ErmEventAction.ERM_REQUESTED, `${ErmEventLabel.ERM_REQUESTED} ${searchKey ? ` - ${searchKey}` : ''}`),
	ErmUsed: (searchKey?: string) => sendEvent(EventCategory.Erm, ErmEventAction.ERM_USED, `${ErmEventLabel.ERM_USED} - ${searchKey}`),
	ErmInprogress: (searchKey?: string) =>
		sendEvent(EventCategory.Erm, ErmEventAction.ERM_INPROGRESS, `${ErmEventLabel.ERM_INPROGRESS} - ${searchKey}`),
	ErmUnavailable: (searchKey?: string) =>
		sendEvent(EventCategory.Erm, ErmEventAction.ERM_UNAVAILABLE, `${ErmEventLabel.ERM_UNAVAILABLE} - ${searchKey}`),
};
