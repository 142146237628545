import { getParams, useFilterSearchParams } from '@hooks';
import { SortOrder } from '@models/sorting';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from '@hooks';
import { filterService, userService } from '@services/core';
import { AdminPermission, UserResponseModel } from '@models/user';
import { Stack } from '@mui/material';
import { DataTableBackend } from '@components/common';
import { EditAdminUserModal } from '@components/admin/EditAdminUserModal';
import { AdminUserColumns } from '@components/admin/TableComponents/Columns';
import { DataTestId } from '@utils/DataTestId';
import { useRestrictedComponent } from '@hooks';

export const AdminUsers = () => {
	const { searchParams, filtering, setFiltering } = useFilterSearchParams('', SortOrder.RESET);

	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');
	const [editAdminUserModalOpened, setEditAdminUserModalOpened] = useState(false);
	const [selectedUser, setSelectedUser] = useState<UserResponseModel>();
	const { t, ready } = useTranslation('admin');
	const { data: filterData } = useService(() => filterService.merckUserFilterOptions());
	const translate = (path: string) => (ready ? t(path) : '');
	const { data: userList, trigger: refreshUserList } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		return userService.getAllWithParams(searchParams, '/merck');
	}, [searchParams]);

	const columns = AdminUserColumns({
		onEditClick: (user: UserResponseModel) => {
			setSelectedUser(user);
			setEditAdminUserModalOpened(true);
		},
	});
	return (
		<Stack data-testid={DataTestId.getStaticTestId('users-and-roles-test-id')} marginTop={4}>
			{editAdminUserModalOpened && selectedUser && (
				<EditAdminUserModal
					visible={editAdminUserModalOpened}
					user={selectedUser}
					onClose={() => {
						setEditAdminUserModalOpened(false);
						setSelectedUser(undefined);
					}}
					onSave={() => {
						refreshUserList();
						setEditAdminUserModalOpened(false);
					}}
					roles={filterData?.options[0].values.map((i) => i.value) ?? []}
				/>
			)}
			{useRestrictedComponent(
				AdminPermission.VIEW_USERS,
				<DataTableBackend
					data={userList?.data || []}
					columns={columns}
					sorting={{
						sortBy: getParams(searchParams).getSortBy(),
						sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
					}}
					pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: userList?.totalPages ?? 1 }}
					searchText={searchTextfieldValue}
					searchPlaceholder={translate('users-and-roles.search-placeholder')}
					onSearchTextChange={setSearchTextfieldValue}
					searchFieldProps={{
						sx: {
							width: (400 / 1440) * window.innerWidth,
							marginLeft: 1,
						},
					}}
					headerCellProps={{
						sx: {
							fontSize: '14px',
							fontWeight: 700,
							lineHeight: '105%',
							color: 'text.primary',
						},
					}}
					filterData={filterData?.options}
					onFilter={setFiltering}
					filteringSelections={filtering}
				/>,
			)}
		</Stack>
	);
};
