import { UserResponseModel } from '@models/user';
import { Button, ButtonProps } from '@mui/material';
import { alertService, userService } from '@services/index';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { ExtendedComponent } from 'src/types';

type TReactivateUser = {
	user?: UserResponseModel;
	disabled?: boolean;
	refresh: () => void;
};

export const ReactivateUser: ExtendedComponent<TReactivateUser & ButtonProps> = ({ user, refresh, disabled, ...props }) => {
	const { t } = useTranslation('common');
	if (!user) return <></>;
	const reactivateUser = () => {
		alertService.send({
			titleText: <Tr.Common path="user-management.confirmation" />,
			content: t('user-management.reactivate-user-explanation'),
			closeTextComponent: <Tr.Common path="cancel" />,
			confirmTextComponent: <Tr.Common path="user-management.confirm" />,
			confirmTextHeader: t('user-management.reactivate-user-title'),
			onConfirm: () => {
				RxUtils.promisify(
					userService.updateUser({
						...user,
						laboratories: user.laboratories?.map((l) => l.id) ?? [],
						status: true,
					}),
					refresh,
				);
			},
			onClose: () => null,
		});
	};
	return (
		<Button
			data-testid="reactivate-button-id"
			variant="text"
			size="medium"
			sx={{
				margin: 'auto auto 0 0',
				textTransform: 'capitalize',
			}}
			disabled={disabled}
			onClick={reactivateUser}
			{...props}
		>
			<Tr.Common path={'reactivate'} />
		</Button>
	);
};
