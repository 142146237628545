import { Radio, RadioProps, Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

type TBorderedRadio = {
	containerProps?: StackProps;
	typographyProps?: TypographyProps;
	title: string;
};

export const BorderedRadio: FC<TBorderedRadio & RadioProps> = ({ title, containerProps, typographyProps, ...props }) => {
	return (
		<Stack
			direction="row"
			alignItems="middle"
			sx={{
				border: '1px solid',
				borderColor: 'grey.200',
				borderRadius: 1,
				width: '100%',
			}}
			{...containerProps}
		>
			<Radio {...props} />
			<Stack height="100%" justifyContent="center" alignItems="center">
				<Typography variant="label-s" {...typographyProps}>
					{title}
				</Typography>
			</Stack>
		</Stack>
	);
};
