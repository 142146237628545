import { Type } from 'class-transformer';
import { IrRequestAccordionItem } from './ir-admin-note-model.interface';
import { IrDrmRequestStatus } from './ir-request-drm.enum';

export const IrDrmRequestStatusTypes = Object.keys(IrDrmRequestStatus).filter((s) => isNaN(Number(s)));
export type IrDrmRequestStatusType = (typeof IrDrmRequestStatusTypes)[number];

export class IrRequestDrm {
	id: number;
	casNumber: string;
	substance: string;
	comment: string;
	email: string;
	merckPhysicalProductNumber: string;
	organization: string;
	preferredApplication: string;
	preferredSolvent: string;
	referenceMethod: string;
	status: IrDrmRequestStatusType;
	usualConcentration: string;
	notes: Array<IrRequestAccordionItem>;
	chemicalName?: string;
	actionRequired: Boolean;
	nmrDrmOrderId?: number;

	@Type(() => Date)
	date?: Date;

	isNewRequest() {
		return this.status === 'NewRequest';
	}
	isNotStarted() {
		return this.status === 'NotStarted';
	}
	isInprogress() {
		return this.status === 'InProgress';
	}
	isOnHold() {
		return this.status === 'OnHold';
	}
	isNotAvailable() {
		return this.status === 'NotAvailable';
	}
	isReleased() {
		return this.status === 'Released';
	}
}

export interface IIrDrmSearchModel {
	query: string;
	pageIndex: number;
	pageSize: number;
	sortBy?: string;
	sortOrder?: string;
	filteringData: unknown[];
}
