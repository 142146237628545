import { IrLibrary } from '@services/ir';
import { NmrLibrary } from '@services/nmr';
import { useEffect } from 'react';

export const useDefaultLibrary = (libraryList: (IrLibrary | NmrLibrary)[]) => {
	useEffect(() => {
		const tableRows = document.querySelectorAll('tbody [role="row"]');

		libraryList.forEach((l, i) => {
			if (l.isDefault) {
				(tableRows[`${i}`] as HTMLTableRowElement).style.backgroundColor = '#F4FBF8';
			} else {
				(tableRows[`${i}`] as HTMLTableRowElement).style.backgroundColor = '#ffffff';
			}
		});
	}, [libraryList]);
};
