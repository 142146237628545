import { TFunction } from 'i18next';
import * as yup from 'yup';
import { SubscriptionDefaults, SubscriptionType } from '@models/subscribe';
import { TechnologyFields } from '@services/nmr/nmr-analysis/technology.enum';
import { TypeUtils } from '@utils/Type';

type TValidationSchema = {
	[key: string]: yup.AnySchema;
};
export const getPaidSubscriptionSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		budget: yup
			.number()
			.when('paymentType', {
				is: (paymentType: SubscriptionType) => paymentType === SubscriptionType.PRE_PAID,
				then: () =>
					yup
						.number()
						.typeError(t('organization.budget-should-be-number'))
						.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
						.min(1, t('organization.budget-min'))
						.max(SubscriptionDefaults.MAX_BUDGET, t('organization.budget-max'))
						.required(t('organization.budget-is-required')),
				otherwise: () => yup.number().required(t('organization.budget-limit-is-required')),
			})
			.when('paymentType', {
				is: (paymentType: SubscriptionType) => paymentType === SubscriptionType.POST_PAID,
				then: () =>
					yup
						.number()
						.typeError(t('organization.budget-limit-should-be-number'))
						.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
						.min(1, t('organization.budget-limit-min'))
						.max(SubscriptionDefaults.MAX_BUDGET, t('organization.budget-limit-max'))
						.required(t('organization.budget-limit-is-required')),
				otherwise: () => yup.number().required(t('organization.budget-limit-is-required')),
			})
			.required(),
		pricing: yup.lazy((value: TechnologyFields, options) => {
			if (value !== undefined) {
				const keys = Object.keys(value);
				const validationSchema: TValidationSchema = {};
				keys.forEach((key) => {
					if (TypeUtils.returnValueOfKey(key, value)) {
						Object.assign(validationSchema);
						validationSchema[`${key}`] = yup
							.number()
							.nullable()
							.lessThan(
								options.parent?.budget ?? SubscriptionDefaults.MAX_BUDGET,
								options.parent?.paymentType === SubscriptionType.POST_PAID
									? t('organization.technology-price-should-be-lower-than-budget-limit')
									: t('organization.technology-price-should-be-lower-than-budget'),
							)
							.required(t('organization.technology-price-required', { technology: key }));
					} else {
						validationSchema[`${key}`] = yup
							.number()
							.nullable()
							.test('greaterThanZero', t('organization.price-should-be-bigger-than-0'), (techPrice) => techPrice !== 0)
							.required(t('organization.technology-price-required', { technology: key }));
					}
				});
				return yup.object().shape(validationSchema);
			}
			return yup.mixed().required();
		}),
		startDate: yup.date().required(),
		endDate: yup.date().required(),
		comment: yup.string(),
	}) as yup.AnySchema;
