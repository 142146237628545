import { Tr } from '@utils/Translation';
import { mainTitleSpacer } from '@utils/Theme';
import { DataTestId } from '@utils/DataTestId';
import { PortalPageRoutes } from '@models/router';
import { DeviceManagementTabs } from '@models/device';
import { useState, useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';

const routes = [DeviceManagementTabs.ACTIVE, DeviceManagementTabs.WAITING, DeviceManagementTabs.INACTIVE];

type ValidRoutes = (typeof routes)[number];

export const NmrDeviceCalibrationManagement = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState<ValidRoutes>(DeviceManagementTabs.ACTIVE);

	const setTab = useCallback((route: ValidRoutes) => {
		setCurrentTab(route);
		navigate(route);
	}, []);

	useEffect(() => {
		const pieces = location.pathname.split('/');
		const result = pieces?.[pieces.length - 1];
		const targetTab = routes.find((route) => route === result);
		if (targetTab) {
			setCurrentTab(targetTab);
		} else {
			navigate(currentTab, { replace: true });
		}
	}, [location]);

	const availableTabs = routes.map((route) => {
		return (
			<Tab
				key={route}
				value={route}
				label={<Tr.Portal path={`device-management.${route}`} />}
				data-testid={DataTestId.getStaticTestId(`device-calibration-management-${route}-id`)}
			/>
		);
	});

	return (
		<Stack direction="column" data-testid={DataTestId.getStaticTestId('device-calibration-management-wrapper-id')}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h1" sx={{ color: 'text.secondary', marginBottom: mainTitleSpacer }}>
					<Tr.Portal path="device-management.title" />
				</Typography>
				<Button
					disableElevation
					variant="contained"
					data-testid={DataTestId.getStaticTestId('device-calibration-management-add-btn-id')}
					onClick={() => navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ADD_DEVICE}`)}
				>
					<Tr.Portal path="device-management.add-device" />
				</Button>
			</Stack>

			<Tabs value={currentTab} textColor="primary" sx={{ marginBottom: '2.5rem' }} onChange={(_, tab) => setTab(tab)}>
				{availableTabs}
			</Tabs>
			<Outlet />
		</Stack>
	);
};
