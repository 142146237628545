import { AnalysisEventAction, EventCategory, AnalysisEventLabel } from '@models';

export enum AnalysisEventType {
	ANALYSIS_CREATED = 'AnalysisCreated',
	EXECUTING_ANALYSIS = 'AnalysisExecuting',
	ERROR_ANALYSIS = 'AnalysisError',
	EXECUTED = 'Executed',
	REMOVE_ANALYSIS = 'RemoveAnalysis',
	APPROVED_LAB_MEMBER = 'ApprovedLabMember',
	APPROVED_LAB_ADMIN = 'ApprovedLabAdmin',
	REJECTED_LAB_MEMBER = 'RejectedLabMember',
	REJECTED_LAB_ADMIN = 'RejectedLabAdmin',
}

export const sendEvent = (category: EventCategory, action: AnalysisEventAction, label: string) =>
	(window as any).gtag('event', action, {
		event_category: category,
		event_label: label,
		value: label,
	});

export const analyticsAnalysisEvent: Record<AnalysisEventType, (analysisId: string) => void> = {
	AnalysisExecuting: (analysisId: string) =>
		sendEvent(
			EventCategory.Analysis,
			AnalysisEventAction.EXECUTING_ANALYSIS,
			`${AnalysisEventLabel.EXECUTING_ANALYSIS} - ${analysisId}`,
		),
	AnalysisError: (analysisId: string) =>
		sendEvent(EventCategory.Analysis, AnalysisEventAction.EXECUTING_ANALYSIS, `${AnalysisEventLabel.ERROR_ANALYSIS} - ${analysisId}`),
	Executed: (analysisId: string) =>
		sendEvent(EventCategory.Analysis, AnalysisEventAction.EXECUTED_ANALYSIS, `${AnalysisEventLabel.EXECUTED_ANALYSIS} - ${analysisId}`),
	AnalysisCreated: (analysisId: string) =>
		sendEvent(EventCategory.Analysis, AnalysisEventAction.ANALYSIS_CREATED, `${AnalysisEventLabel.ANALYSIS_CREATED} - ${analysisId}`),
	RemoveAnalysis: (analysisId: string) =>
		sendEvent(EventCategory.Analysis, AnalysisEventAction.REMOVE_ANALYSIS, `${AnalysisEventLabel.REMOVE_ANALYSIS} - ${analysisId}`),
	ApprovedLabAdmin: (analysisId: string) =>
		sendEvent(
			EventCategory.Analysis,
			AnalysisEventAction.APPROVED_LAB_ADMIN,
			`${AnalysisEventLabel.APPROVED_LAB_ADMIN} - ${analysisId}`,
		),
	ApprovedLabMember: (analysisId: string) =>
		sendEvent(
			EventCategory.Analysis,
			AnalysisEventAction.APPROVED_LAB_MEMBER,
			`${AnalysisEventLabel.APPROVED_LAB_MEMBER} - ${analysisId}`,
		),
	RejectedLabAdmin: (analysisId: string) =>
		sendEvent(
			EventCategory.Analysis,
			AnalysisEventAction.REJECTED_LAB_ADMIN,
			`${AnalysisEventLabel.REJECTED_LAB_ADMIN} - ${analysisId}`,
		),
	RejectedLabMember: (analysisId: string) =>
		sendEvent(
			EventCategory.Analysis,
			AnalysisEventAction.REJECTED_LAB_MEMBER,
			`${AnalysisEventLabel.REJECTED_LAB_MEMBER} - ${analysisId}`,
		),
};
