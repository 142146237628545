import { PageRoutes } from '@models/router';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useShowNmrWaveMenu = () => {
	const loc = useLocation();
	const showMenu = useMemo(
		() => loc.pathname.includes(`${PageRoutes.DRM_PIPELINE}/${PageRoutes.NMR}/${PageRoutes.DRM_PIPELINE_BACKLOG}`),
		[loc],
	);

	return showMenu;
};
