import { FC } from 'react';
import { IrAnalysisResult } from '@services';
import { Box, Typography } from '@mui/material';
import { Tr } from '@utils';

export const IrNoMatchTable: FC<{ analysisResult: IrAnalysisResult }> = ({ analysisResult }) => {
	return (
		<Box
			data-testid="no-match-test-id"
			sx={{ border: '1px solid', borderColor: 'grey.200', padding: '24px', borderRadius: '12px', width: '100%' }}
		>
			<Typography variant="h5" color="error.main" marginBottom="1rem">
				<Tr.IrPortal path="analysis-result-table.no-match-found" />
			</Typography>

			{!analysisResult.isIdentification() &&
				analysisResult?.referenceMaterials?.map((material, index) => (
					<Typography key={`index-${index}-material-not-matched-${material.id}`} marginTop="1rem">
						<strong>{material.name} </strong>
						{`(Cas No: ${material.casNumber})  `}
						<Tr.IrPortal path="analysis-result-table.has-below" />
					</Typography>
				))}

			{analysisResult.isIdentification() && (
				<Typography marginTop="1rem" variant="pg-m">
					<Tr.IrPortal path="analysis-result-table.no-match-found-description" />
				</Typography>
			)}
		</Box>
	);
};
