import { FC, useContext } from 'react';
import { RankBasedMixtureData, SampleSpectrumInfo } from '@services';

import { IrAnalysisResultContext } from '@routes/Portal/ir/IrAnalysisResult';
import { Grid } from '@mui/material';
import {
	renderPeakItensityDataGrid,
	getTargetAndSampleTableofAssignmentPeaks,
} from '../IrIdentificationTableOfAssignment/IrTableOfAssignmentHelperComponents';

interface IQuantitativeTableOfAssignment {
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
}

export const IrPurityCheckTableOfAssignment: FC<IQuantitativeTableOfAssignment> = ({ userSpectrumInfo, rankedRMData }) => {
	const { analysisResultData, peakData, impurityPeakData } = useContext(IrAnalysisResultContext);
	const reference_peak_intensity = (peakData && peakData['reference_peak_intensity']) || [];
	const sample_peak_intensity = (peakData && peakData['sample_peak_intensity']) || [];
	const peak_pos = (peakData && peakData['peak_pos']) || [];
	const targetSubstanceTitle = rankedRMData?.mixtures[0].substanceName || '';
	const sampelTitle = analysisResultData?.irAnalysis?.title || '';

	const getImpuritiesTableofAssignmentPeaks = () => {
		if (impurityPeakData && impurityPeakData?.length > 0) {
			return impurityPeakData.map((item, index) => {
				if (impurityPeakData) {
					const impurity_peak_intensity = (item && item['impurity_peak_intensity']) || [];
					return (
						<div key={index}>
							{renderPeakItensityDataGrid(item?.substance_name, impurity_peak_intensity, true, 'impurity-intensity', true)}
						</div>
					);
				}
			});
		}
	};

	const displayPeakPosition = () => {
		if (peak_pos && peak_pos?.length > 0) {
			if (peak_pos) {
				return <>{renderPeakItensityDataGrid('', peak_pos, false, 'peak-position')}</>;
			}
		}
	};

	return userSpectrumInfo || analysisResultData?.peakJson ? (
		<>
			<Grid item container alignItems="center" xs="auto">
				{displayPeakPosition()}
				{getTargetAndSampleTableofAssignmentPeaks(
					reference_peak_intensity,
					sample_peak_intensity,
					targetSubstanceTitle,
					sampelTitle,
				)}
				{getImpuritiesTableofAssignmentPeaks()}
			</Grid>
		</>
	) : (
		<></>
	);
};
