import { FC, ReactNode } from 'react';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { DataTestId } from '@utils/DataTestId';
import { IrAdminPermission } from '@models/user';
import { OverflowText } from '@components/common';
import { notificationService } from '@services/core';
import { useHasPermissions } from '@hooks';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { IrDrmWave, irDrmWaveService } from '@services/ir-drm-pipeline';
import { Card, Stack, Typography, Chip, Divider, IconButton } from '@mui/material';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import { TypeUtils } from '@utils/Type';

type TWaveDynamicCard = {
	wave: IrDrmWave;
	onUpdate: () => void;
	type: 'onUpload' | 'onRelease';
};

type TErrorOptions = {
	message: ReactNode;
	iconTitle: ReactNode;
};
export const FailedWaveCard: FC<TWaveDynamicCard> = ({ wave, type, onUpdate }) => {
	const { t } = useTranslation('admin');

	const { id, name } = wave;

	const deleteWave = (waveId: number) => {
		RxUtils.promisify(irDrmWaveService.deleteWave(waveId), () => {
			onUpdate();
			notificationService.sendSuccess(t('wave.delete-message', { key: name }), {
				duration: 5000,
			});
		});
	};

	const errors = {
		onUpload: {
			message: (
				<>
					<Tr.Admin path="wave.failed.upload-failed-message" /> &nbsp;
					<Tr.Admin path="wave.failed.removeable-message" />
				</>
			),
			iconTitle: <Tr.Admin path="wave.failed.upload-failed-iconTitle" />,
		},
		onRelease: {
			message: <Tr.Admin path="wave.failed.released-failed-message" />,
			iconTitle: <Tr.Admin path="wave.failed.released-failed-iconTitle" />,
		},
	};

	const error = TypeUtils.returnValueOfKey<typeof errors, TErrorOptions>(type, errors);

	const userHasDeletePermission = () => {
		if (type === 'onUpload') {
			return useHasPermissions(IrAdminPermission.REMOVE_FAILED_UPLOAD);
		} else if (type === 'onRelease') {
			return useHasPermissions(IrAdminPermission.REMOVE_FAILED_WAVE);
		}

		return false;
	};

	const hasDeletePermission = userHasDeletePermission();

	return (
		<Card
			variant="elevation"
			sx={{ height: '14rem', padding: 3, display: 'grid' }}
			data-testid={DataTestId.getStaticTestId('wave-failed-card-id')}
		>
			<Stack overflow="hidden">
				<Stack direction="row" justifyContent="space-between">
					<OverflowText variant="h4" enableTooltip={true} sx={{ width: 'calc(100% - 0.5rem)' }}>
						{name}
					</OverflowText>
					<Chip
						size="small"
						color="error"
						variant="filled"
						label={
							<Typography variant="subtitle3" color="white">
								<Tr.Admin path="wave.failed.name" />
							</Typography>
						}
					/>
				</Stack>
				<Divider sx={{ marginY: 1.5 }} />
				<Stack paddingY={1.5} maxHeight={92} overflow="auto">
					<Typography variant="pg-s" color="grey.800">
						{error?.message}
					</Typography>
				</Stack>
			</Stack>
			<Stack direction="row" alignItems="end" justifyContent="space-between" spacing={0.5}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<TaskOutlinedIcon sx={{ color: 'error.main', fontSize: 20 }} />
					<Typography variant="subtitle3" color="error.main">
						{error?.iconTitle}
					</Typography>
				</Stack>

				{hasDeletePermission && (
					<IconButton
						disableRipple
						sx={{ paddingY: 'unset' }}
						onClick={() => id && deleteWave(id)}
						data-testid={DataTestId.getStaticTestId('wave-failed-card-remove-btn-id')}
					>
						<Delete width={20} height={20} fill="#5E5E72" />
					</IconButton>
				)}
			</Stack>
		</Card>
	);
};
