import { FormikTextfield, ModalDialog } from '@components/common';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';
import { useParams } from 'react-router-dom';
import { onPeakSelectionsChange } from './IntegratedTableHelper';
import { SingleSelectMenu, SingleSelectMenuOnSelectType, getValue } from '@components/common/SingleSelectMenu';
import { formFieldSpacer } from '@utils/Theme';
import { InfoOutlined } from '@mui/icons-material';
import { ICompound } from './CompoundForm';
import { getPeakValidation } from '@schemas';
import { FormUtils } from '@utils/Form';

type TAddNumberOfProtonsModalProps = {
	peak: QuantitivePeakType;
	onSubmit: () => void;
	onClose: () => void;
	compounds?: ICompound[];
	showSelectDropdown?: boolean;
	showTextField?: boolean;
};

export type FormFields = Extendable<{ numberOfH: number; compoundId?: number }>;

export const AddNumberOfProtonsModal: FC<TAddNumberOfProtonsModalProps> = ({
	peak,
	onSubmit,
	onClose,
	compounds,
	showSelectDropdown = true,
	showTextField = true,
}) => {
	const { id = '' } = useParams<string>();
	const { t } = useTranslation('portal');

	const formik = useFormik<FormFields>({
		enableReinitialize: true,
		initialValues: {
			numberOfH: peak.numberOfH,
			compoundId: peak.compoundId,
		},
		validationSchema: getPeakValidation(t, showSelectDropdown, showTextField),
		onSubmit: (changes) => {
			onPeakSelectionsChange(+id, changes.numberOfH, peak, onSubmit, changes.compoundId);
		},
		validateOnChange: true,
	});

	const closeModal = () => {
		onClose();
		formik.resetForm();
	};

	const compoundItems = compounds
		?.filter((compound) => compound.name)
		.map((compound) => ({
			id: String(compound.id),
			label: compound.name,
		}));

	const handleSelectChange: SingleSelectMenuOnSelectType = (item) => {
		const selectedId = getValue(item as string);
		formik.setFieldValue('compoundId', selectedId);
	};

	const selectedItem = (compoundItems && compoundItems.find((item: any) => item.id === String(formik.values.compoundId))) || undefined;

	return (
		<ModalDialog
			variant="primary"
			open={true}
			titleProps={{
				sx: {
					justifyContent: 'space-between',
					marginTop: 0,
					marginBottom: 3,
					minHeight: 30,
					backgroundColor: 'grey.50',
					paddingY: 1.2,
					paddingX: 2,
					height: 40,
				},
			}}
			title={
				<Typography variant="pg-s">{t(showSelectDropdown && showTextField ? 'new-analysis.add' : 'new-analysis.edit')}</Typography>
			}
			maxWidth="sm"
			iconButtonProps={{
				right: 8,
			}}
			PaperProps={{
				sx: {
					maxWidth: '472px',
					minHeight: '351px',
					justifyContent: 'space-between',
				},
			}}
			onClose={closeModal}
			contentProps={{ sx: { width: 472, paddingBottom: 3 } }}
		>
			<form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
				<Stack spacing={3} sx={{ alignItems: 'center', textAlign: 'center', width: '100%', paddingX: 8, paddingBottom: 7 }}>
					<Typography variant="subtitle1">
						<Typography>
							{showSelectDropdown && showTextField
								? t('new-analysis.add-compound-and-protons')
								: !showSelectDropdown
									? t('new-analysis.edit-number-of-protons')
									: t('new-analysis.edit-compounds')}
						</Typography>
					</Typography>

					<Stack style={{ width: '100%', textAlign: 'start' }}>
						{showSelectDropdown && compoundItems && compoundItems.length > 0 && (
							<SingleSelectMenu
								items={compoundItems}
								selectedItem={selectedItem}
								onSelectedItemChange={handleSelectChange}
								defaultLabel={t('new-analysis.select-a-compound')}
								menuType="selectMenu"
								title={t('new-analysis.compound-title')}
								required
								sx={{ width: '344px', height: '40px', marginBottom: formFieldSpacer }}
								applyMenuItemBorder={true}
							/>
						)}

						{showTextField && (
							<FormikTextfield
								formikApi={formik}
								name="numberOfH"
								title={
									<Stack direction="row" justifyContent="space-between" padding="0" margin="0">
										<span className="required">{t('new-analysis.number-of-protons')}</span>
										<Tooltip placement="top" arrow title={t('new-analysis.add-number-of-protons-explanation')}>
											<InfoOutlined color="action" fontSize="small" />
										</Tooltip>
									</Stack>
								}
								placeholder={t('new-analysis.enter-number-of-protons')}
								onKeyDown={FormUtils.preventNonNumeric}
								type="number"
								typographyProps={{ sx: { marginBottom: 0.5 } }}
							/>
						)}
					</Stack>
					<Stack direction="row" width="100%" paddingTop={1} spacing={1.5}>
						<Button data-testid="cancel-btn-id" variant="outlined" sx={{ width: '100%' }} onClick={closeModal}>
							<Tr.Portal path="new-analysis.cancel" />
						</Button>
						<Button
							data-testid="submit-button-id"
							type="submit"
							disabled={!formik.dirty || !formik.isValid}
							sx={{ width: '100%' }}
							variant="contained"
							disableElevation
						>
							<Tr.Portal path="Save" />
						</Button>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
