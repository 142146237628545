import React, { FC, useState } from 'react';
import { DatePicker } from 'antd';
import { Box, Grid, Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import './FormikDateRangePicker.css';
const { RangePicker } = DatePicker;
import type { TimeRangePickerProps } from 'antd';
import { Dayjs } from 'dayjs';
import { FormikApiType } from '../FormikTextfield';

export type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>>[0];

interface IFormikDateRangePickerProps {
	containerProps?: StackProps;
	formik?: FormikApiType;
	pickerClassName?: string;
	presets?: TimeRangePickerProps['presets'];
	firstTitle?: string;
	secondTitle?: string;
	typographyProps?: TypographyProps;
	errorText?: string;
	value?: RangeValue;
	required?: boolean;
	name: string;
}

const RangeInfoType = {
	END: 'end',
	START: 'start',
};

// TODO: This component is a huge mistake. Find another solution
export const FormikDateRangePicker: FC<IFormikDateRangePickerProps & TimeRangePickerProps> = ({
	containerProps,
	formik,
	pickerClassName,
	presets,
	firstTitle,
	secondTitle,
	typographyProps,
	name,
	errorText,
	value,
	required = true,
	...props
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	return (
		<Stack sx={{ position: 'relative' }}>
			<Grid container marginBottom={0.5}>
				<Grid item xs={6}>
					{firstTitle ? (
						<Typography variant="label-s" color="grey.800" className={required ? 'required' : ''} {...typographyProps}>
							{firstTitle}
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={6}>
					{secondTitle ? (
						<Typography
							variant="label-s"
							color="grey.800"
							className={required ? 'required' : ''}
							{...typographyProps}
							sx={{ paddingLeft: '10px', ...typographyProps?.sx }}
						>
							{secondTitle}
						</Typography>
					) : null}
				</Grid>
			</Grid>
			<RangePicker
				className={`antd-custom-picker ${pickerClassName}`}
				data-testid="range-picker-test-id"
				open={isOpen}
				value={value ?? formik?.values[`${name}`]}
				inputReadOnly={true}
				suffixIcon={undefined}
				separator={
					<React.Fragment>
						<Box sx={{ width: '5px' }}></Box>
					</React.Fragment>
				}
				popupStyle={{
					zIndex: 100000000000,
				}}
				presets={presets}
				allowClear={false}
				onChange={(values) => formik?.setFieldValue(name, values)}
				onCalendarChange={(dates, dateStrings, info) => {
					if (info.range === RangeInfoType.END && dates?.[0] && dateStrings?.[0]) {
						setIsOpen(false);
					}
				}}
				onOpenChange={(open) => setIsOpen(open)}
				cellRender={(current, info) => {
					if (info.type !== 'date') return info.originNode;
					return (
						<Stack
							className="ant-picker-cell-inner"
							sx={{
								backgroundColor: 'transparent',
								border: '1px solid transparent',
								borderRadius: '50%',
								padding: '5px',
								alignItems: 'center',
								height: '100% !important',
								justifyContent: 'center',
								textAlign: 'center',
								'&:hover': {
									border: '1px solid transparent',
								},
							}}
							{...containerProps}
						>
							<Typography
								fontWeight={400}
								className={
									formik?.values?.[`${name}`] && formik?.values?.[`${name}`].some((i: Dayjs) => i.isSame(current))
										? 'ant-picker-inner-cell-selected-typography'
										: 'ant-picker-inner-cell-typography'
								}
							>
								{new Date(current.toString()).getDate().toString()}
							</Typography>
						</Stack>
					);
				}}
				superPrevIcon={<></>}
				superNextIcon={<></>}
				variant="borderless"
				{...props}
			/>
			{props.status && (
				<Typography
					variant="body1"
					sx={{
						fontSize: '0.75rem',
						fontWeight: 400,
						position: 'absolute',
						bottom: -15,
						left: 10,
					}}
					color="error.main"
				>
					{errorText}
				</Typography>
			)}
		</Stack>
	);
};
