import Switch, { SwitchProps } from '@mui/material/Switch';
import { FC } from 'react';
import { useMemoizedTestId } from '@hooks';

type SwitchTheme = 'onOff' | 'choice';

export const AdjustableSwitch: FC<SwitchProps & { switchTheme?: SwitchTheme }> = ({ switchTheme = 'choice', ...props }) => {
	const testId = useMemoizedTestId('switch-test');
	// TO DO: FIX & .MuiSwitch-track selector is not working
	return (
		<Switch
			data-testid={testId}
			{...props}
			sx={{
				'& .MuiSwitch-track': { backgroundColor: switchTheme === 'onOff' ? 'grey.800' : 'primary.main' },
				...props?.sx,
			}}
		/>
	);
};
