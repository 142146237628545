import { TFunction } from 'i18next';
import * as yup from 'yup';
import countries from '@assets/data/countries.json';
import { getPhoneValidationSchema } from '@schemas';

const countryList = countries.map(({ label }) => ({ label, value: label }));

export const getOrganizationValidationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup
		.object({
			name: yup.string().max(250).trim().required(t('organization.organization-name-is-required')),
			legalName: yup.string().max(250).trim().required(t('organization.organization-legal-name-is-required')),
			street: yup.string().max(250).trim().required(t('organization.street-is-required')),
			number: yup.string().max(50).trim().required(t('organization.number-is-required')),
			zipCode: yup.string().max(50).trim().required(t('organization.zip-code-is-required')),
			city: yup.string().max(150).trim().required(t('organization.city-is-required')),
			country: yup
				.string()
				.max(100)
				.oneOf(
					countryList.map(({ label }) => label),
					t('organization.country-not-found'),
				)
				.trim()
				.required(t('organization.country-is-required')),
			mainContactPerson: yup.string().max(250).trim().required(t('organization.main-contact-person-is-required')),
			adminEmail: yup.string().email(t('organization.email-is-invalid')).max(250).required(t('organization.email-is-required')),
			email: yup.string().email(t('organization.email-is-invalid')).max(250).required(t('organization.email-is-required')),

			sapCustomerId: yup.string().max(250).trim(),
		})
		.concat(getPhoneValidationSchema(t));
