import { FC } from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield } from '@components/common';
import { useTranslation } from 'react-i18next';
import { Methods } from '@models/analysis';

interface IMethodListType {
	name: string;
	formik?: FormikApiType;
	title?: string;
	required?: boolean;
	typographyProps?: any;
	disabled?: boolean;
}

// TO DO: THIS COMPONENT SEEMS LIKE HAVE A LOT COMMON PROPERTIES WITH MEASUREMENTTYPELISTFORIR COMPONENT. MERGE THEM AND USE IN THE OTHER COMPONENTS.
export const MethodList: FC<IMethodListType> = ({ name, title, required, typographyProps, formik, disabled = false }) => {
	const { t } = useTranslation('irportal');
	return (
		<Stack direction="column" sx={{ marginBottom: 1 }}>
			<FormikTextfield
				formikApi={formik}
				name={name}
				select
				defaultValue={formik?.values[`${name}`] || Methods[0].name}
				required={false}
				disabled={disabled}
				value={formik?.values[`${name}`] || Methods[0].name}
				onChange={(e) => formik?.setFieldValue(name, e.target.value)}
				placeholder="method"
				title={
					title ?? (
						<Stack direction="row">
							<Typography variant="label-s" className={required ? 'required' : ''} color="grey.800" display="inline">
								{t('new-analysis.method')}
							</Typography>
						</Stack>
					)
				}
				variant="outlined"
				typographyProps={typographyProps}
				fullWidth
				type="number"
				sx={{
					backgroundColor: 'grey.50',
					'& .MuiOutlinedInput-root': {
						height: '40px',
					},
				}}
			>
				{Methods.map((item, index) => (
					<MenuItem key={`index-${index}}`} value={item.name} sx={{ height: '40px' }}>
						{item.label}
					</MenuItem>
				))}
			</FormikTextfield>
		</Stack>
	);
};
