import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { concatMap, map, tap } from 'rxjs';
import { httpService, logger, Filter } from '@services/core';
import { irDrmPipelineConfig } from '../irDrmPipelineConfig';

class IrDrmPipelineFilterService {
	getURl() {
		return `${irDrmPipelineConfig.url}`;
	}

	irPipelineWaveReportsFilterOptions() {
		return httpService
			.get<Filter>(this.getURl(), `Wave/reports/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.irPipelineWaveReportsFilterOptions.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}
}

export const irDrmPipelineFilterService = new IrDrmPipelineFilterService();
