import { IrFavoritesTable, IFavoritesTableRef } from '@components/ir-portal/IrFavorites';
import { IrReferenceMaterialFavorite } from '@components/ir-portal/IrFavorites/IrReferenceMaterialFavorite';
import { useMemoizedTestId } from '@hooks';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { IconButton, Stack } from '@mui/material';
import { alertService, irFavoriteReferencesService, notificationService } from '@services';
import { mainTitleSpacer, RxUtils, Tr } from '@utils';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditFavoriteDialog } from './EditFavoriteDialog';

interface IEditingFavorite {
	favoriteName: string;
	favoriteId: number;
}

export const IrFavourites = () => {
	const { t } = useTranslation('irportal');
	const childRef = useRef<IFavoritesTableRef>(null);

	const [editingFavorite, setEditingFavorite] = useState<IEditingFavorite>();
	const [editDialogOpen, setEditDialogOpen] = useState(false);

	const onSuccessfullDelete = () => {
		notificationService.sendSuccess(t('favourites.successfully-deleted'));
		childRef.current?.refetch();
	};
	const onDelete = (id: number) => {
		RxUtils.promisify(irFavoriteReferencesService.delete(id), () => {
			onSuccessfullDelete();
		});
	};

	const openDeleteDialog = (id: number, name: string): void => {
		alertService.send({
			titleText: <Tr.IrPortal path="favourites.delete" />,
			confirmTextHeader: <Tr.IrPortal path="favourites.delete-the-diaolog-header" />,
			onConfirm: () => onDelete(id),
			content: t('favourites.delete-confirmation-message', { name: name }),
			confirmTextComponent: <Tr.IrPortal path="favourites.confirm-delete" />,
		});
	};

	const onEditDialogClose = () => {
		setEditDialogOpen(false);
		setEditingFavorite(undefined);
		childRef.current?.refetch();
	};

	return (
		<Stack data-testid="favourite-page-id" direction="column" sx={{ marginTop: mainTitleSpacer }}>
			<IrFavoritesTable
				ref={childRef}
				library={''}
				leftActions={[
					{
						renderer: ({ id, name, drmCode }) => (
							<IrReferenceMaterialFavorite
								drmCode={drmCode}
								onSuccess={() => onSuccessfullDelete()}
								favoriteId={id}
								name={name}
							/>
						),
					},
				]}
				actions={[
					{
						renderer: ({ id, name }) => (
							<IconButton
								data-testid={useMemoizedTestId('edit-fav-test')}
								color="primary"
								onClick={() => {
									setEditingFavorite({ favoriteId: id, favoriteName: name });
									setEditDialogOpen(true);
								}}
							>
								<EditOutlined color="inherit" />
							</IconButton>
						),
					},
					{
						renderer: ({ id, name }) => (
							<IconButton
								data-testid={useMemoizedTestId('delete-fav-test')}
								color="primary"
								onClick={() => openDeleteDialog(id, name)}
							>
								<Delete width={24} height={24} fill="#01884C" />
							</IconButton>
						),
					},
				]}
			/>

			{editingFavorite && (
				<EditFavoriteDialog
					open={editDialogOpen}
					onSuccess={onEditDialogClose}
					onCloseDialog={onEditDialogClose}
					{...editingFavorite}
				/>
			)}
		</Stack>
	);
};
