import { Type } from 'class-transformer';
import { QualityGrade } from '@models/reference-material';

export class IrDRM {
	id: number;
	name: string;
	drmCode: string;
	library: string;
	libraryName: string;
	@Type(() => Date)
	systemCreationDate: Date;

	createdBy: string;

	@Type(() => Date)
	lastUpdateTime: Date;

	lastUpdatedBy: string;
	merckId: string;
	linearFormula: string;
	solvent: string;
	molecularWeight: number;
	casNumber: string;
	synonyms: string;
	numberOfScans: number;
	substance: string;
	ermProductNumber: string;
	technology: string;
	version: number;
	smilesCode?: string;
	favoriteId?: number;
	referenceMaterialName?: string;

	@Type(() => Date)
	@Type(() => Date)
	createTime?: Date;

	/* createdBy: string; */

	@Type(() => Date)

	// we can use as system creation Date
	@Type(() => Date)
	date?: Date;
	massFraction?: number;
	status?: string; // for Requests Erm
	qualityGrade: QualityGrade;
	organizationId?: number;
}
