import { SubscriptionDefaults } from '@models/subscribe';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getTechnologyValidationSchema: (t: TFunction, budget?: number) => yup.AnySchema = (t, budget) =>
	yup.object({
		technology: yup
			.string()
			.trim()
			.oneOf(Object.values(SupportedTechnology), t('organization.technology-field-is-invalid'))
			.required(t('organization.technology-is-required')),
		price: yup
			.number()
			.typeError(t('organization.price-is-invalid'))
			.test('greaterThanZero', t('organization.price-should-be-bigger-than-0'), (techPrice) => techPrice !== 0)
			.max(budget ?? SubscriptionDefaults.MAX_BUDGET, t('organization.price-should-be-lower-decided-limit'))
			.required(t('organization.price-is-required')),
	});
