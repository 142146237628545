import { Link, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { getContactMailAddress } from '@utils/Location';
import { useSelector } from 'react-redux';
import { useLocationSelector } from '@store/slices/common/location.slice';
import { useTranslation } from 'react-i18next';
export const QuantitiveNonTargetedTechnicalError = () => {
	const { t } = useTranslation('portal');
	const country = useSelector(useLocationSelector);
	return (
		<Stack
			sx={{
				color: 'error.main',
				backgroundColor: 'error.200',
				borderRadius: 1.5,
				padding: 2,
				marginTop: 3,
			}}
		>
			<Stack direction="row" spacing={1.25} marginBottom={1.25}>
				<ErrorOutlinedIcon sx={{ width: '20px', marginLeft: '2px' }} />
				<Typography variant="h5" color="error.main">
					<Tr.Portal path="new-analysis.a-technical-error-occured" />
				</Typography>
			</Stack>
			<Stack marginLeft={4}>
				<Typography variant="label-s">
					<Tr.Portal path="new-analysis.you-can-delete-this-analysis" />
					<Link
						variant="label-s"
						color="error.main"
						sx={{ display: 'inline-block', textDecorationColor: 'error.main' }}
						href={`mailto:${getContactMailAddress(country.code)}?subject=${t('new-analysis.technical-error-occured')}`}
					>
						<Tr.Portal path="notification.contact-us" />
					</Link>
					<Tr.Portal path="new-analysis.you-can-delete-this-analysis-part-2" />
				</Typography>
			</Stack>
		</Stack>
	);
};
