import { ReactNode } from 'react';
import { Tr } from '@utils/Translation';

export enum EventType {
	ANALYSIS_CREATED = 'Analysis Created',
	ANALYSIS_DELETED = 'Analysis Deleted',
	ANALYSIS_EXECUTED = 'Analysis Executed',
	ANALYSIS_FILE_UPLOADED = 'Analysis File Uploaded',
	APPROVED_LAB_MEMBER = 'Approved by Lab Member',
	APPROVED_LAB_ADMIN = 'Approved by Lab Admin',
	REJECTED_LAB_MEMBER = 'Rejected by Lab Member',
	REJECTED_LAB_ADMIN = 'Rejected by Lab Admin',
	ERM_REMOVED = 'ERM Removed from Analysis',
	ERM_CREATED = 'ERM Added to Analysis',
	ANALYSIS_UPDATED = 'Analysis Updated',
	ANALYSIS_RUN = 'Analysis Run',
	ANALYSIS_QUEUED = 'Analysis Queued',
	ANALYSIS_ERROR_IN_QUEUING = 'Analysis Error In Queuing',
	THEORETICAL_SPECTRUM_ADDED_TO_ANALYSIS = 'Theoretical Spectrum Added To Analysis',
	THEORETICAL_SPECTRUM_REMOVED_FROM_ANALYSIS = 'Theoretical Spectrum Removed From Analysis',
	THEORETICAL_SPECTRA_SEARCH_QUEUED = 'Theoretical Spectra Search Queued',
	THEORETICAL_SPECTRA_SEARCH_QUEUE_ERROR = 'Theoretical Spectra Search Queue Error',
}

export const emptyList = [
	EventType.APPROVED_LAB_MEMBER,
	EventType.APPROVED_LAB_ADMIN,
	EventType.ANALYSIS_EXECUTED,
	EventType.REJECTED_LAB_MEMBER,
	EventType.REJECTED_LAB_ADMIN,
	EventType.THEORETICAL_SPECTRA_SEARCH_QUEUED,
	EventType.THEORETICAL_SPECTRA_SEARCH_QUEUE_ERROR,
];

export const isShowEmpty = (action: EventType) => emptyList.includes(action);

export const TitleMapping: Record<EventType, ReactNode> = {
	'Analysis Created': <Tr.Portal path="analysis-history.audit-trail.created" />,
	'Analysis Deleted': <Tr.Portal path="analysis-history.audit-trail.deleted" />,
	'Analysis Executed': <Tr.Portal path="analysis-history.audit-trail.executed" />,
	'Analysis File Uploaded': <Tr.Portal path="analysis-history.audit-trail.file-uploaded" />,
	'Approved by Lab Member': <Tr.Portal path="analysis-history.audit-trail.approved" />,
	'Approved by Lab Admin': <Tr.Portal path="analysis-history.audit-trail.approved" />,
	'ERM Removed from Analysis': <Tr.Portal path="analysis-history.audit-trail.erm-removed" />,
	'ERM Added to Analysis': <Tr.Portal path="analysis-history.audit-trail.erm-added" />,
	'Rejected by Lab Member': <Tr.Portal path="analysis-history.audit-trail.rejected-lab-member" />,
	'Rejected by Lab Admin': <Tr.Portal path="analysis-history.audit-trail.rejected-lab-admin" />,
	'Analysis Updated': <Tr.Portal path="analysis-history.audit-trail.updated" />,
	'Analysis Run': <Tr.Portal path="analysis-history.audit-trail.run" />,
	'Analysis Queued': <Tr.Portal path="analysis-history.audit-trail.queued" />,
	'Analysis Error In Queuing': <Tr.Portal path="analysis-history.audit-trail.error-in-queue" />,
	'Theoretical Spectrum Added To Analysis': <Tr.Portal path="analysis-history.audit-trail.theoretical-spectrum-added-to-analysis" />,
	'Theoretical Spectrum Removed From Analysis': (
		<Tr.Portal path="analysis-history.audit-trail.theoretical-spectrum-removed-from-analysis" />
	),
	'Theoretical Spectra Search Queued': <Tr.Portal path="analysis-history.audit-trail.theoretical-spectra-search-queued" />,
	'Theoretical Spectra Search Queue Error': <Tr.Portal path="analysis-history.audit-trail.theoretical-spectra-search-queue-error" />,
};
