import { combineReducers } from '@reduxjs/toolkit';
import { reducer as Common } from './slices/common/common.slice';
import { reducer as Location } from './slices/common/location.slice';
import { reducer as Notification } from './slices/common/notification.slice';
import { reducer as Test } from './slices/common/test.slice';
import { reducer as Pipeline } from './slices/nmr/pipeline.slice';
import { reducer as IrSettings } from './slices/ir/ir.slice';

export const rootReducer = combineReducers({
	Common,
	Location,
	Notification,
	Test,
	Pipeline,
	IrSettings,
});
