export const onPhoneCodeChange = (oldValue?: string, newValue?: string | null) => {
	if (!oldValue) {
		return `${newValue} `;
	}
	return `${newValue ?? ''} ${oldValue.substring(oldValue.search(' ') + 1)}`;
};

export const onPhoneNumberChange = (oldValue?: string, newValue?: string | null) => {
	if (!oldValue) {
		return ' ' + newValue || '';
	}
	return `${oldValue.substring(0, oldValue.search(' '))} ${newValue ?? ''}`;
};

export const getPhoneCode = (value: string) => value?.substring(0, value?.search(' '));
export const getPhoneNumber = (value: string) => value?.substring(value?.search(' ') + 1);
