import { Type } from 'class-transformer';
import { SubscriptionPlan } from './subscriptionPlan.class';
import { Currency } from '@models/subscribe/currency.types.enum';
import { SubscriptionType } from '@models/subscribe';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

class Technology {
	price: number;
	technology: SupportedTechnology;
	consumptionCount: number;
}

export class Subscription {
	id: number;
	subscriptionType: string;
	paymentType: SubscriptionType;
	organizationId: number;
	subscriptionPlan: SubscriptionPlan;
	subscriptionPlanId: number;
	analysisCount: number;
	budget: number;
	comment: string;
	technologyDetails: Technology[];
	isActive: boolean;

	@Type(() => Date)
	startDate: Date;

	@Type(() => Date)
	endDate: Date;

	totalConsumptionCount: number;

	currency: Currency;
}
export class SubscriptionHistory {
	data: Subscription[];
	count: number;
}
