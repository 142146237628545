import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PortalPageRoutes } from '@models';
import { RxUtils, analyticsAnalysisEvent } from '@utils';
import { nmrAnalysisService } from '@services';
import { NmrConfirmAnalysis, NmrRejectAnalysis } from '@components/nmr-portal';
import { userSelector } from '@store/slices/common/common.slice';
import { AnalysisResultContext } from './analysis-result-context';

interface IAnalysisConfirmation {
	confirmVisible: boolean;
	setConfirmationVisibility: (val: boolean) => void;
	rejectVisible: boolean;
	setRejectionVisibility: (val: boolean) => void;
}

export const NmrAnalysisConfirmation: FC<IAnalysisConfirmation> = ({
	confirmVisible,
	setConfirmationVisibility,
	rejectVisible,
	setRejectionVisibility,
}) => {
	const user = useSelector(userSelector);
	const { analysisId, analysisResultData, triggerResult, isTheoreticalExecuting } = useContext(AnalysisResultContext);
	const navigate = useNavigate();

	if (!analysisId || !analysisResultData) return null;

	const onApprove = (comment?: string) => {
		if (isTheoreticalExecuting) {
			RxUtils.promisify(nmrAnalysisService.cancelTheoreticalSearch(analysisResultData.analysisId), () =>
				sendReview(true, comment, false),
			);
		} else {
			sendReview(true, comment, false);
		}
	};

	const onReject = (comment: string, analysisError: boolean) => {
		if (isTheoreticalExecuting) {
			RxUtils.promisify(nmrAnalysisService.cancelTheoreticalSearch(analysisResultData.analysisId), () =>
				sendReview(false, comment, analysisError),
			);
		} else {
			sendReview(false, comment, analysisError);
		}
	};

	const sendReview = (decision: boolean, comment?: string, analysisError?: boolean) => {
		RxUtils.promisify(nmrAnalysisService.sendReview(analysisId, decision, comment), undefined, undefined, () => {
			if (!analysisResultData?.isConfirmedByLabMember()) {
				decision
					? analyticsAnalysisEvent.ApprovedLabMember(`${analysisId}`)
					: analyticsAnalysisEvent.RejectedLabMember(`${analysisId} ${analysisError ? 'analysis has wrong interpretation' : ''}`);
			} else {
				decision
					? analyticsAnalysisEvent.ApprovedLabAdmin(`${analysisId}`)
					: analyticsAnalysisEvent.RejectedLabAdmin(`${analysisId} ${analysisError ? 'analysis has wrong interpretation' : ''}`);
			}

			setConfirmationVisibility(false);
			setRejectionVisibility(false);
			//TODO SECOND APPROVAL
			if (!analysisResultData?.isConfirmedByLabMember() && analysisResultData?.nmrAnalysis?.secondApproval) {
				navigate(`/${PortalPageRoutes.DASHBOARD}`);
			} else {
				triggerResult();
			}
		});
	};

	return (
		<>
			<NmrConfirmAnalysis
				visible={confirmVisible}
				isTheoreticalExecuting={isTheoreticalExecuting}
				user={user}
				onCancel={() => setConfirmationVisibility(false)}
				onConfirmation={onApprove}
			/>

			<NmrRejectAnalysis
				visible={rejectVisible}
				isTheoreticalExecuting={isTheoreticalExecuting}
				analysisId={analysisId}
				onCancel={() => setRejectionVisibility(false)}
				onReject={onReject}
			/>
		</>
	);
};
