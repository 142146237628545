import { RejectionSeverity } from '@components/admin/NmrPipelineBacklog/TableComponents';
import { useService } from '@hooks';
import { Card, Divider, Stack, Typography } from '@mui/material';
import { NmrDrmOrder, NmrDrmOrderTransitionResponseType, NmrDrmOrderTransitionType, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { TFunction } from 'i18next';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyAdditionalActions } from '@components/admin/EmptyAdditionalActions';
import { Tr } from '@utils/Translation';
import { NmrRequestNoteAccordion } from '@components/admin/NmrRequestComments/NmrRequestNoteAccordion';

export const DrmOrderTransitionDetailMapping: Record<
	NmrDrmOrderTransitionType,
	(nmrDrmOderTransition: NmrDrmOrderTransitionResponseType, t: TFunction) => ReactNode
> = {
	[NmrDrmOrderTransitionType.ACCEPT]: (nmrDrmOrderTransition, t) => (
		<Stack marginTop={2} padding={2} borderRadius={'8px'} sx={{ backgroundColor: 'grey.50' }}>
			<Typography variant="label-s" color="grey.800">
				{t('drm-order-details.reason')}
			</Typography>
			<Typography variant="body2" lineHeight={'180%'} color="text.primary">
				{nmrDrmOrderTransition.declineReason
					? t(
							`drm-order-details.change-status-to-waiting-for-measurement-drm-order.reason.${nmrDrmOrderTransition.declineReason}`,
						)
					: '-'}
			</Typography>
		</Stack>
	),
	[NmrDrmOrderTransitionType.REJECT]: (nmrDrmOrderTransition, t) => (
		<Stack direction="row" marginTop={2} padding={2} borderRadius={'8px'} sx={{ backgroundColor: 'grey.50' }}>
			<Stack direction="column">
				<Typography variant="label-s" color="grey.800">
					{t('drm-order-details.severity')}
				</Typography>
				<Stack direction="row">
					<RejectionSeverity severity={nmrDrmOrderTransition.severity} iconSx={{ marginRight: 1 }} tooltipEnabled={false} />
					<Typography variant="body2" lineHeight="180%">
						{nmrDrmOrderTransition.severity}
					</Typography>
				</Stack>
			</Stack>
			<Divider
				orientation="vertical"
				flexItem
				sx={{
					marginX: 3,
					borderColor: 'grey.200',
				}}
			/>
			<Stack>
				<Typography variant="label-s" color="grey.800">
					{t('drm-order-details.rejection-reason')}
				</Typography>
				<Typography
					variant="body2"
					lineHeight={'180%'}
					color="text.primary"
					sx={{ '::first-letter': { textTransform: 'capitalize' } }}
				>
					{nmrDrmOrderTransition.rejectReasons
						?.map((rejectionReason) => t(`drm-order-details.reject-drm-order.reason.${rejectionReason}`))
						?.join(', ')
						?.toLowerCase()}
				</Typography>
			</Stack>
		</Stack>
	),
	[NmrDrmOrderTransitionType.DECLINE]: (nmrDrmOrderTransition, t) => (
		<Stack marginTop={2} padding={2} borderRadius={'8px'} sx={{ backgroundColor: 'grey.50' }}>
			<Typography variant="label-s" color="grey.800">
				{t('drm-order-details.decline-reason')}
			</Typography>
			<Typography variant="body2" lineHeight={'180%'} color="text.primary">
				{t(`drm-order-details.decline-drm-order.reason.${nmrDrmOrderTransition.declineReason}`)}
			</Typography>
		</Stack>
	),
};

type TDrmOrderTransitionDetailsProps = {
	nmrDrmOrder: NmrDrmOrder;
};

export const DrmOrderTransitionDetails: FC<TDrmOrderTransitionDetailsProps> = ({ nmrDrmOrder }) => {
	const { data: drmOrderTransitions } = useService(() => {
		return nmrDrmOrderService.listTransitions(nmrDrmOrder.id ?? 0, [
			NmrDrmOrderTransitionType.DECLINE,
			NmrDrmOrderTransitionType.REJECT,
			NmrDrmOrderTransitionType.ACCEPT,
		]);
	}, [nmrDrmOrder]);

	return (
		<Stack direction="column">
			<DrmOrderTransitionDetail transitions={drmOrderTransitions} />
		</Stack>
	);
};

type TDrmOrderTransitionDetailProps = {
	transitions?: NmrDrmOrderTransitionResponseType[];
};

export const DrmOrderTransitionDetail: FC<TDrmOrderTransitionDetailProps> = ({ transitions }) => {
	const { t: translate } = useTranslation('admin');
	const renderTransitionDetail = (item: NmrDrmOrderTransitionResponseType) =>
		DrmOrderTransitionDetailMapping[item.transitionType]?.(item, translate);

	return (
		<Stack>
			{!transitions || transitions.length === 0 ? (
				<EmptyAdditionalActions
					title={
						<>
							<Tr.Admin path="drm-order-details.no-activities" />
						</>
					}
					explanation={
						<>
							<Tr.Admin path={'drm-order-details.no-activities-explanation'} />
						</>
					}
				/>
			) : (
				<Card
					variant="outlined"
					sx={{
						paddingY: 2.5,
						paddingX: 3,
					}}
				>
					<NmrRequestNoteAccordion
						dateFormat="dd/MM/yyyy"
						avatarIconTextColor="grey.400"
						isEditable={false}
						renderTitleAction={(item) =>
							translate(`drm-order-details.${(item as NmrDrmOrderTransitionResponseType).transitionType}-transition-header`)
						}
						renderChildren={(item) => renderTransitionDetail(item as NmrDrmOrderTransitionResponseType)}
						itemList={transitions.map((transition, index) => {
							return {
								content: transition.comment ?? '',
								createTime: transition.createTime,
								id: index,
								name: transition.user?.split(' ')[0],
								surname: transition.user?.split(' ')[1],
								userId: transition.createdBy,
								data: transition,
								isEdited: transition.isEdited ?? false,
								referenceMaterialRequestId: transition.referenceMaterialRequestId ?? 0,
							};
						})}
					/>
				</Card>
			)}
		</Stack>
	);
};
