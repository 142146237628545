export const NmrDrmOrderFileRules = {
	CORE_MAX_FILE_COUNT: 4,
	ADDITIONAL_MAX_FILE_COUNT: 10,
	MIN_LOADING: 100000,
};

export type TParamArray = {
	name: string;
	scaler: number;
	value: number | string;
	valueType: string;
};

type TMagnitudeWithUnit = {
	magnitude: number;
	unit: string;
};

type JdfFileDate = {
	year: number;
	month: number;
	day: number;
};

export class JdfFileContentType {
	info: {
		sampleName: string;
		solvent: string;
		numberOfScans: string;
		originFrequency: TMagnitudeWithUnit[];
		creationTime: JdfFileDate;
		temperature: TMagnitudeWithUnit;
		relaxationTime: TMagnitudeWithUnit;
		pulseStrength90: TMagnitudeWithUnit;
		dimension: string;
		nucleus: string;
		paramList: string;
		acquisitionTime: TMagnitudeWithUnit[];
	};
	parameters: {
		paramArray: TParamArray[];
	};
}
