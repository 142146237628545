import { Button, Grid, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { ModalDialog } from '@components/common/ModalDialog';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { RxUtils } from '@utils/Rx';
import { labService } from '@services/core/lab/lab.service';
import { PatchType } from '@models/request-response';
import { PropertyOf } from '@models/request-response/http-patch';
import { Lab } from '@services/index';

interface IReactivateDialogProps {
	id: number;
	labName: string;
	isOpen: boolean;
	onCancel?: () => void;
	onSave?: () => void;
}

export const ReactivateDialog: FC<IReactivateDialogProps> = ({ id, labName, isOpen = false, onCancel, onSave }) => {
	const { t } = useTranslation('common');

	const handleReactivate = () => {
		RxUtils.promisify(
			labService.changeLaboratory(+id, [
				{
					op: PatchType.REPLACE,
					path: PropertyOf<Lab>('isActive'),
					value: true,
				},
			]),
			() => {
				onSave && onSave();
			},
		);
	};
	return (
		<ModalDialog
			variant="secondary"
			open={isOpen}
			title={
				<Typography variant="pg-s">
					<Tr.Common path="lab-details.confirmation" />
				</Typography>
			}
			closeIconClick={onCancel}
			maxWidth={false}
			PaperProps={{
				sx: {
					minWidth: '472px',
					maxWidth: '472px',
					maxHeight: '400px',
					minHeight: '303px',
				},
			}}
		>
			<Stack
				sx={{
					paddingX: '68px',
					marginY: 4,
				}}
				spacing={3}
			>
				<Stack justifyContent="center" textAlign="center">
					<Typography variant="subtitle1" paddingBottom={3}>
						{t('lab-details.reactivate-title', { labName: labName })}
					</Typography>
					<Typography variant="pg-m">{t('lab-details.reactivate-explanation', { labName: labName })}</Typography>
					<Typography variant="pg-m">{t('lab-details.reactivate-all-users', { labName: labName })}</Typography>
				</Stack>
				<Grid container>
					<Grid
						item
						xs={6}
						sx={{
							paddingRight: 1,
						}}
					>
						<Button
							variant="outlined"
							fullWidth={true}
							data-testid="reactivate-cancel-button-id"
							disableElevation
							onClick={() => {
								onCancel && onCancel();
							}}
						>
							<Tr.Common path="cancel" />
						</Button>
					</Grid>

					<Grid
						item
						xs={6}
						sx={{
							paddingLeft: 1,
						}}
					>
						<Button
							fullWidth={true}
							data-testid="reactivate-approve-button-id"
							variant="contained"
							type="submit"
							disableElevation
							onClick={() => handleReactivate()}
							sx={{ textTransform: 'capitalize' }}
						>
							<Tr.Common path="lab-details.reactivate" />
						</Button>
					</Grid>
				</Grid>
			</Stack>
		</ModalDialog>
	);
};
