export enum AnalysisEventLabel {
	ANALYSIS_CREATED = 'Analyze was Created',
	EXECUTING_ANALYSIS = 'Analyze is Executing',
	ERROR_ANALYSIS = 'Analyze is Executed with Error',
	EXECUTED_ANALYSIS = 'Analyze was executed successfully',
	REMOVE_ANALYSIS = 'Analyze was removed',
	APPROVED_LAB_MEMBER = 'Analyze was Approved by Lab Member',
	APPROVED_LAB_ADMIN = 'Analyze was Approved by Lab Member',
	REJECTED_LAB_MEMBER = 'Analyze was Rejected by Lab Member',
	REJECTED_LAB_ADMIN = 'Analyze was Rejected by Lab Admin',
}

export enum AnalysisEventAction {
	EXECUTING_ANALYSIS = 'AnalysisExecuting',
	EXECUTED_ANALYSIS = 'AnalysisExecutedSuccessfully',
	ANALYSIS_CREATED = 'AnalysisCreated',
	REMOVE_ANALYSIS = 'AnalysisRemoved',
	APPROVED_LAB_MEMBER = 'ApprovedLabMember',
	APPROVED_LAB_ADMIN = 'ApprovedLabAdmin',
	REJECTED_LAB_MEMBER = 'RejectedLabMember',
	REJECTED_LAB_ADMIN = 'RejectedLabAdmin',
}
