export const qualityGrades = ['Certified reference material', 'Analytical standard', 'General chemical'];

export const siteSuppliers = [
	'Bangalore',
	'Bellefonte',
	'Buchs',
	'Cleveland',
	'Darmstadt',
	'EMD',
	'Laramie',
	'Makor',
	'Milwaukee',
	'Rehovot',
	'Round Rock',
	'Schnelldorf',
	'Sheboygan',
	'St. Louis',
	'Stenheim',
	'Wuxi',
];
