import { FC, useEffect } from 'react';
import { SingleSelectMenu, SingleSelectMenuOnSelectType } from '../SingleSelectMenu';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type TDynamicTechnologyHandler = {
	mainPath: string;
	appendAfter?: string;
};

export const getSelectedTechnology = (loc: Location) => {
	if (loc.pathname.includes(SupportedTechnology.NMR.toLowerCase())) return SupportedTechnology.NMR;

	if (loc.pathname.includes(SupportedTechnology.IR.toLowerCase())) return SupportedTechnology.IR;
	return undefined;
};

const DEFAULT_TECH = SupportedTechnology.NMR;
export const DynamicTechnologyHandler: FC<TDynamicTechnologyHandler> = ({ mainPath, appendAfter = '' }) => {
	const loc = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation('common');

	const getDestination = (tech: string) => {
		const occurenceIdx = mainPath.indexOf(appendAfter);
		if (!occurenceIdx || occurenceIdx < 0) throw new Error('Implementation Error');

		const newPath =
			mainPath.substring(0, occurenceIdx + appendAfter.length) +
			'/' +
			tech.toLowerCase() +
			mainPath.substring(occurenceIdx + appendAfter.length);
		return newPath;
	};

	useEffect(() => {
		const selectedTechnology = getSelectedTechnology(loc);

		if (!selectedTechnology) {
			navigate(getDestination(DEFAULT_TECH).toLowerCase());
		}
	}, [loc.pathname]);

	const onSelectedItemChange: SingleSelectMenuOnSelectType = (item) => {
		if (getSelectedTechnology(loc)?.toLowerCase() !== String(item).toLowerCase()) {
			navigate(getDestination(String(item)));
		}
	};

	return (
		<>
			<SingleSelectMenu
				items={Object.keys(SupportedTechnology).map((i) => ({ id: i, label: t(`${i.toLowerCase()}-tech`) }))}
				onSelectedItemChange={onSelectedItemChange}
				sx={{ minWidth: 198, maxHeight: 40 }}
				selectedItem={getSelectedTechnology(loc) ?? DEFAULT_TECH}
			/>
		</>
	);
};
