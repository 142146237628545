import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { analyticsAnalysisEvent, RxUtils, Tr } from '@utils';
import { LabPermission, PortalPageRoutes } from '@models';
import { nmrAnalysisService } from '@services';
import { SectionWithSubtitle } from '@components/common';
import { Button, Stack, Typography } from '@mui/material';
import { NmrMyAnalyses } from './MyAnalyses';
import { NmrMostRecentCompletedAnalysis } from './NmrMostRecentCompletedAnalysis';
import { useHasPermissions } from '@hooks';

export const NmrDashboard: FC = () => {
	const navigate = useNavigate();
	const hasAnalysisPermission = useHasPermissions(LabPermission.VIEW_ANALYSIS);

	const startNewAnalysis = () =>
		RxUtils.promisify(nmrAnalysisService.create(), (data) => {
			analyticsAnalysisEvent.AnalysisCreated(`${data.id}`);
			navigate(`../${PortalPageRoutes.ANALYSIS}/${data.id}`);
		});
	return (
		<Stack data-testid="dashboard-wrapper-id" direction="column" spacing={5}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h1" sx={{ color: 'text.secondary' }}>
					<Tr.Portal path="dashboard.title" />
				</Typography>
				{hasAnalysisPermission && (
					<Button
						data-testid="start-new-analyze-button-id"
						variant="contained"
						size="medium"
						disableElevation
						onClick={startNewAnalysis}
						sx={{
							width: '7.5rem',
							height: '2.5rem',
						}}
					>
						<Tr.Portal path="dashboard.new-analysis" />
					</Button>
				)}
			</Stack>
			{hasAnalysisPermission && (
				<SectionWithSubtitle
					subtitle={
						<Typography sx={{ fontSize: '1.625rem', fontWeight: 700, color: 'text.secondary' }}>
							<Tr.Portal path="dashboard.my-analyses.title" />
						</Typography>
					}
				>
					<NmrMyAnalyses />
				</SectionWithSubtitle>
			)}
			{hasAnalysisPermission && <NmrMostRecentCompletedAnalysis />}
		</Stack>
	);
};
