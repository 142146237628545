import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	IconButton,
	List,
	ListItemButton,
	ListItemButtonProps,
	ListItemIcon,
	ListItemText,
	Menu,
	Stack,
	StackProps,
	Typography,
} from '@mui/material';
import { authService } from '@services';
import { setUser } from '@store/slices/common/common.slice';
import { RxUtils, Tr } from '@utils';
import { UserPermission } from '@models';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import PersonOutlineRounded from '@mui/icons-material/PersonOutlineRounded';
import { useHasPermissions } from '@hooks';

export interface IUserAvatarProps extends Omit<StackProps, 'direction' | 'alignItems'> {
	noDropdown?: boolean;
	username?: string;
}

const listItemStyle: ListItemButtonProps['sx'] = {
	width: 264,
	height: 48,
	overflow: 'hidden',
	py: 1.25,
};

const listItemIconStyle = { minWidth: '36px' };
const listItemTextPrimaryStyle = { fontSize: 14, fontWeight: 700, color: 'grey.800' };

const svgIconStyle = { color: 'grey.800' };

export const UserAvatar: FC<IUserAvatarProps> = ({ noDropdown, username, children, ...props }) => {
	const navigate = useNavigate();
	const avatarRef = useRef<HTMLButtonElement>(null);
	const [userMenuVisibility, setUserMenuVisibility] = useState(false);
	const dispatch = useDispatch();
	const hasYourProfilePermission = useHasPermissions(UserPermission.USER_PROFILE);

	const onLogoutClicked = async () => {
		setUserMenuVisibility(false);

		dispatch(setUser(null));

		authService
			.logout()
			.then(() => navigate('/login'))
			.catch(RxUtils.noop);
	};

	return (
		<Stack {...props} direction="row" alignItems="center">
			<IconButton
				data-testid="avatar-icon-id"
				ref={avatarRef}
				disableRipple
				sx={{ p: 0.5, mr: 2, color: 'inherit' }}
				onClick={() => setUserMenuVisibility(!userMenuVisibility)}
			>
				{children || ''}
				{username && (
					<Typography variant="h5" sx={{ pl: '12px', color: 'common.white' }}>
						{username}
					</Typography>
				)}
			</IconButton>
			{!noDropdown && (
				<Menu
					anchorEl={avatarRef.current}
					open={userMenuVisibility}
					onClose={() => setUserMenuVisibility(false)}
					transformOrigin={{ vertical: -4, horizontal: 'left' }}
					sx={{
						'& 	.MuiMenu-paper': {
							borderRadius: '4px',
						},
						'& 	.MuiMenu-list': {
							padding: 0,
						},
						zIndex: (theme) => theme.zIndex.modal + 1,
					}}
				>
					<List disablePadding sx={{ minWidth: avatarRef.current?.clientWidth }}>
						{hasYourProfilePermission && (
							<>
								<ListItemButton
									dense
									data-testid="go-to-profile-id"
									sx={listItemStyle}
									onClick={() => (setUserMenuVisibility(false), navigate('your-profile'))}
								>
									<ListItemIcon sx={listItemIconStyle}>
										<PersonOutlineRounded sx={svgIconStyle} />
									</ListItemIcon>
									<ListItemText
										primaryTypographyProps={listItemTextPrimaryStyle}
										primary={<Tr.Portal path="profile" />}
									/>
								</ListItemButton>
							</>
						)}
						<ListItemButton dense sx={listItemStyle} onClick={onLogoutClicked}>
							<ListItemIcon sx={listItemIconStyle}>
								<LogoutRounded sx={svgIconStyle} />
							</ListItemIcon>
							<ListItemText primaryTypographyProps={listItemTextPrimaryStyle} primary={<Tr.Portal path="logout" />} />
						</ListItemButton>
					</List>
				</Menu>
			)}
		</Stack>
	);
};
