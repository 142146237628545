import { DependencyList, useEffect } from 'react';

export const usePreloadImage = (sources: string[] | string, deps: DependencyList) => {
	const preloadImage = (src: string) => {
		const img = new Image();
		img.src = src;
	};

	useEffect(() => {
		if (typeof sources === 'string') {
			preloadImage(sources);
		} else {
			sources.forEach(preloadImage);
		}
	}, deps);
};
