import { FC, ReactNode, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Tr } from '@utils';

const MAX_PASS_LENGTH = 8;

const colorLabelMap = {
	valid: 'primary.main',
	invalid: 'error.main',
	initial: 'grey.400',
};

const SpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

interface IPatternIndicatorProps {
	label: ReactNode;
	value: string;
	isValid?: boolean;
}

const PatternIndicator: FC<IPatternIndicatorProps> = ({ label, isValid, value }) => {
	const color = value === '' ? 'initial' : isValid ? 'valid' : 'invalid';

	return (
		<Stack direction="row" alignItems="center" width="10rem">
			<Box
				sx={{
					width: '0.5rem',
					height: '0.5rem',
					borderRadius: '50%',
					backgroundColor: colorLabelMap[`${color}`],
					marginRight: '0.625rem',
				}}
			/>
			<Typography variant="pg-xs" sx={{ color: colorLabelMap[`${color}`], fontWeight: 450, whiteSpace: 'nowrap' }}>
				{label}
			</Typography>
		</Stack>
	);
};

interface IPasswordPattern {
	pass1: string;
	pass2: string;
	patternIsValid: (isValid: boolean) => void;
}

export const PasswordPattern: FC<IPasswordPattern> = ({ pass1, pass2, patternIsValid }) => {
	const PasswordValidityFunction = {
		lowercase: () => !!pass1.match(/[a-z]/),
		uppercase: () => !!pass1.match(/[A-Z]/),
		SpecialCharacter: () => SpecialCharacter.test(pass1),
		minCharacter: () => pass1.length >= MAX_PASS_LENGTH,
		oneNumber: () => !!pass1.match(/[0-9]/),
		match: () => pass1 === pass2,
	};

	useEffect(() => {
		patternIsValid(
			Object.values(PasswordValidityFunction).reduce((first, next) => {
				return first && next();
			}, true),
		);
	}, [pass1, pass2]);

	return (
		<Stack data-testid="password-pattern-wrapper-id">
			<Stack direction="row" justifyContent="space-between">
				<PatternIndicator
					isValid={PasswordValidityFunction.lowercase()}
					value={pass1}
					label={<Tr.Login path="register.lowercase" />}
				/>
				<PatternIndicator
					isValid={PasswordValidityFunction.uppercase()}
					value={pass1}
					label={<Tr.Login path="register.uppercase" />}
				/>
				<PatternIndicator
					isValid={PasswordValidityFunction.SpecialCharacter()}
					value={pass1}
					label={<Tr.Login path="register.special-char" />}
				/>
			</Stack>
			<Stack direction="row" justifyContent="space-between">
				<PatternIndicator
					isValid={PasswordValidityFunction.minCharacter()}
					value={pass1}
					label={<Tr.Login path="register.min-char" />}
				/>
				<PatternIndicator
					isValid={PasswordValidityFunction.oneNumber()}
					value={pass1}
					label={<Tr.Login path="register.one-number" />}
				/>
				<PatternIndicator
					isValid={PasswordValidityFunction.match()}
					value={pass1}
					label={<Tr.Login path="register.pass-match" />}
				/>
			</Stack>
		</Stack>
	);
};
