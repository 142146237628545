import { useCallback } from 'react';

export const useDebounce = (duration: number) => {
	let timer: NodeJS.Timeout;
	const debounce = useCallback((cb: () => void) => {
		clearTimeout(timer);
		timer = setTimeout(() => cb(), duration);
	}, []);

	return { debounce };
};
