import { Stack, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const getStatusColor = (status: boolean) => (status ? 'initial' : 'grey.500');
export const Body1: FC<TypographyProps & { status?: boolean }> = ({ status = true, sx, ...props }) => (
	<Typography variant="pg-s" fontWeight={400} lineHeight="12px" sx={{ padding: 0, color: getStatusColor(status), ...sx }} {...props} />
);

export const Body1WithBorders: FC<TypographyProps & { status?: boolean; borderEnabled: boolean }> = ({
	status = true,
	borderEnabled,
	sx,
	...props
}) => {
	const borderColor = status ? 'primary.main' : 'grey.400';
	const typographyColor = !borderEnabled ? getStatusColor(status) : status ? 'primary.main' : 'grey.800';
	return (
		<Stack
			sx={{
				...(!borderEnabled
					? {}
					: {
							border: '1px solid',
							borderColor: borderColor,
							borderRadius: '4px',
							width: 'fit-content',
							padding: '6px',
						}),
			}}
		>
			<Typography
				variant="subtitle3"
				lineHeight="12px"
				sx={{
					padding: 0,
					color: typographyColor,
					...sx,
				}}
				{...props}
			/>
		</Stack>
	);
};
