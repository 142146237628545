import CheckIcon from '@mui/icons-material/Check';
import { SvgIconOwnProps } from '@mui/material';
import { FC } from 'react';

export const SingleSelectMenuCheckmark: FC<SvgIconOwnProps> = ({ sx, ...props }) => {
	return (
		<CheckIcon
			color="primary"
			sx={{
				width: 14,
				height: 14,
				marginRight: 1.5,
				...sx,
			}}
			{...props}
		/>
	);
};
