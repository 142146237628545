import { IrAnalysisType } from '@models';
import { Type } from 'class-transformer';
import { IrDrmDetail } from '../ir-drm';
import { IrAnalysisStage } from './ir-analysis-stage.enum';
import { IrAnalysisResultStatus } from './ir-analysis-result-status.enum';
import { SupportedTechnology } from './technology.enum';
import { QualityGrade } from '@models/reference-material';

export enum IrAnalysisResultMatchType {
	MATCH = 'Match',
	QUESTIONABLE_MATCH = 'QuestionableMatch',
	NOMATCH = 'NoMatch',
}

export enum IrMatchCategory {
	SATISFACTORY = 'Satisfactory',
	QUESTIONABLE = 'Questionable',
	UNSATISFATORY = 'Unsatisfactory',
}
export class IrAnalysisResult {
	analysisId: number;
	reviewerName: string;
	matchType: IrAnalysisResultMatchType;
	comment: string;
	resultType: number;
	integrationMessage: string;
	status: IrAnalysisResultStatus;
	resultMatches: IrReferenceMaterialResult[];
	referenceMaterials: IrDrmDetail[];
	certifiedReferenceMaterial: IrDrmDetail;
	integrationErrorMessage: string;
	quantificationDetails: peakDetails[];
	resultImpurities: IrResultImpurites[];

	irAnalysis: {
		title: string;
		projectId: string;
		secondApproval: boolean;
		stage: IrAnalysisStage;
		operatorName: string;
		operatorId: string;
		integrationId: string;
		isCompleted: boolean;
		measurementExecutorId: string;
		solvent: string;
		details: string;
		technology: SupportedTechnology;
		measurementDeviceBrand: string;
		measurementDeviceName: string;
		type: IrAnalysisType;
		productAndBatchNumber: string;
		measurementId: string;
		includeMixture: boolean;
		sampleName: string;
		peakMin1: number;
		peakMax1: number;
		peakMin2: number;
		peakMax2: number;
		quantificationMethod: string;
	};

	@Type(() => Date)
	confirmationDate: Date;
	sampleSpectrum: string;
	@Type(() => Date)
	reviewDate: Date;
	confirmationLevel: string;
	rmSpectrum: string;
	peakJson: string;
	impurityPeakJson: string;

	public isRejectedByLabMember(): boolean {
		return this.irAnalysis.stage === IrAnalysisStage.REJECTED_BY_LAB_MEMBER;
	}
	public isRejectedByLabAdmin(): boolean {
		return this.irAnalysis.stage === IrAnalysisStage.REJECTED_BY_LAB_ADMIN;
	}

	public isRejected(): boolean {
		return this.isRejectedByLabAdmin() || this.isRejectedByLabMember();
	}

	public isError(): boolean {
		return this.status === IrAnalysisResultStatus.TECHNICAL_ERROR;
	}

	public isExecuting(): boolean {
		return this.irAnalysis.stage === IrAnalysisStage.EXECUTING;
	}

	public isExecutedSuccessful(): boolean {
		return this.status !== IrAnalysisResultStatus.TECHNICAL_ERROR;
	}

	public isApprovedByLabAdmin(): boolean {
		return this.irAnalysis.stage === IrAnalysisStage.APPROVED_BY_LAB_ADMIN;
	}

	public isConfirmedByLabMember(): boolean {
		return this.irAnalysis.stage === IrAnalysisStage.CONFIRMED_BY_LAB_MEMBER;
	}

	public isExecuted(): boolean {
		return this.irAnalysis.stage === IrAnalysisStage.EXECUTED;
	}

	public isMatched(): boolean {
		return this.matchType !== IrAnalysisResultMatchType.NOMATCH;
	}

	public isSatisfied(): boolean {
		return this.status === IrAnalysisResultStatus.SATISFIED;
	}

	public isUnSatisfied(): boolean {
		return this.status === IrAnalysisResultStatus.UNSATISFIED;
	}

	public isQuestionable(): boolean {
		return this.status === IrAnalysisResultStatus.QUESTIONABLE;
	}

	public isQuantification(): boolean {
		return this.irAnalysis.type === IrAnalysisType.QUANTIFICATION;
	}

	public isIdentification(): boolean {
		return this.irAnalysis.type === IrAnalysisType.IDENTIFICATION;
	}

	public isPurityCheck(): boolean {
		return this.irAnalysis.type === IrAnalysisType.PURITYCHECK;
	}
	public isCompleted(): boolean {
		return this.irAnalysis.isCompleted;
	}
}

export class IrReferenceMaterialResult {
	id: number;
	@Type(() => Date)
	createTime: Date;
	createdBy: string;
	@Type(() => Date)
	lastUpdateTime: Date;
	lastUpdatedBy: string;
	analysisId: number;
	substanceName: string;
	casNumber: string;
	identityConfirmed: boolean;
	matchFactor: number;
	referenceMaterialId: number;
	moleculeImage: string;
	rmSpectrum: string;
	sampleSpectrum: string;
	concentration: string;
	massFraction: string;
	sampleWeight: string;
	solventWeight: string;
	isFalsePositive: boolean;
	isQuestionableHCount: boolean;
	isQuestionableMaxDa: boolean;
	isQuestionableNmrPurity: boolean;
	comments: string;
	category: IrMatchCategory;
	library: string;
	name: string;
	drmCode: string;
	fileStorageKey: string;
	scaled_spectrum_y: number[];
	weights?: string;
	isSimilarSpectra?: boolean;

	qualityGrade: QualityGrade;
	isSatisfactory(): boolean {
		return this.category === IrMatchCategory.SATISFACTORY;
	}

	isQuestionable(): boolean {
		return this.category === IrMatchCategory.QUESTIONABLE;
	}

	isUnSatisfactory(): boolean {
		return this.category === IrMatchCategory.UNSATISFATORY;
	}
}

export enum IrCommentTypeEnum {
	CHEM_SHIFT = 'ChemShift',
	INTEGRAL = 'Integral',
	SIGNAL = 'Signal',
}

export interface IrCommentType {
	comment: string;
	type: IrCommentTypeEnum;
}

export class IrPeaksInfo {
	rmPeakPosition: number[];
	rmintensity: number[];
	samplePeakPosition: number[];
	sampleintensity: number[];
	impurityPeaks?: IrImpurityPeak[];
	peakPosition?: number[];
}

export class peakDetails {
	method: string;
	peakMin: number;
	peakMax: number;
}

export class IrPeaksQuantificationInfo {
	peakNumber: number;
	rmPeakXminXmax: string;
	rmPeakArea: number;
	userPeakArea: number;
}

export class RankBasedMixtureData {
	rank: number;
	matchFactor: number;
	library: string;
	selectedResult: number;
	mixtures: IrReferenceMaterialResult[];
	combined_spectrum_y: number[];
	combined_spectrum_peak_features: CombinedPeakFeatures;
	spectrum_x: number[];
	x_range: number[];
	table_of_assignment?: IrPurityCheckPeakjson;
	drmCode: string;
}

export class CombinedPeakFeatures {
	peak_wavenumbers: number[];
	peak_heights: number[];
}

export class SampleSpectrumInfo {
	peak_features: CombinedPeakFeatures;
	sample_x: number[];
	sample_y: number[];
	sample_x_range: number[];
	baseline_curve_y: number[];
	sampleName: string;
	sampleSpectrumUrl?: string;
	metadata: SpectrumMetaData;
}
export class SampleFileInfo {
	x: number[];
	y: number[];
	meta: SpectrumFIleMetaData;
}
export class SpectrumFIleMetaData {
	title: string;
	firstx: string;
	lastx: string;
}
export class SampleSpectrumInfoPurity {
	sample_x: number[];
	sample_y: number[];
	sample_x_range: number[];
}

export class RmSpectrumInfoPurity {
	spectrum_x: number[];
	spectrum_y: number[];
	x_range: number[];
}
export class SpectrumMetaData {
	title: string;
	data_type: string;
	sampling_procedure: string;
	xunits: string;
	yunits: string;
	resolution: number;
}
export class IrResultImpurites {
	substanceName: string;
	libraryName: string;
	libraryId: number;
	casNumber: string;
	matchFactor: number;
	weights?: string;
	hqi: number;
	impuritySpectrum?: string;
	impuritySpectrumData: ImpuritySpectrum[];
	isActive: boolean;
	qualityGrade: QualityGrade;
}

export class ImpuritySpectrum {
	x: number[];
	y: number[];
}
export class IrPurityCheckPeakjson {
	sample_peak_pos?: number[];
	sample_peak_intensity?: number[];
	reference_peak_intensity?: number[];
	reference_peak_pos?: number[];
	reference_drm_code?: string;
	peak_pos?: number[];
}
export class IrImpurityPeak {
	impurity_drm_code: string;
	impurity_peak_intensity: number[];
	impurity_peak_pos: number[];
	substance_name: string;
}
export class IrPurityCheckRefPeaks {
	referencePeakPosition: [];
	referencePeakIntensity: [];
	samplePeakPosition: [];
	sampleintensity: [];
	referenceDrmCode?: string;
	impurites: [];
}

export class IrPdfReportImpurityPeak {
	drmCode: string;
	impurityPeakPosition: number[];
	impurityPeakIntensity: number[];
	substanceName: string;
}
