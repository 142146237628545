import { FC } from 'react';
import { OverflowText } from '@components/common';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Stack, CardProps, SxProps, Card, Button, Typography } from '@mui/material';
import { DataTestId } from '@utils/DataTestId';

type DashboardCardType = {
	img: string;
	title: string;
	description: string;
	sx?: SxProps;
	dataTestId?: string;
	onClick: () => void;
} & Omit<CardProps, 'sx'>;

export const DashboardCard: FC<DashboardCardType> = ({ img, title, description, onClick, dataTestId }) => {
	return (
		<Card variant="elevation" onClick={onClick} data-testid={dataTestId} sx={{ height: '100%', display: 'grid', cursor: 'pointer' }}>
			<Stack>
				<img src={img} style={{ width: '100%' }} />
				<Stack overflow="hidden" p={2} spacing={1.5}>
					<OverflowText variant="caption-s" sx={{ color: 'grey.400', textTransform: 'uppercase' }}>
						{title}
					</OverflowText>
					<Typography variant="pg-l" sx={{ color: 'grey.800' }}>
						{description}
					</Typography>
				</Stack>
			</Stack>

			<Stack alignItems="flex-end" justifyContent="flex-end" m={2}>
				<Button
					variant="contained"
					sx={{ padding: 1 }}
					color="success"
					disableElevation
					data-testid={DataTestId.getStaticTestId(`${dataTestId}-button`)}
				>
					<ArrowForwardOutlinedIcon fontSize="small" />
				</Button>
			</Stack>
		</Card>
	);
};
