import { FC, ReactNode } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalDialog } from '../ModalDialog';
import { Tr } from '@utils/Translation';
import { InfoMessageBox } from '../InfoMessageBox';
import { DataTestId } from '@utils/DataTestId';

interface IConfirmDialogWithInfoProps {
	visible: boolean;
	titleText: ReactNode;
	confirmText?: ReactNode;
	infoText: ReactNode;
	onClose?: () => void;
	onConfirm?: () => void;
	closeButtonText?: ReactNode;
	confirmButtonText?: ReactNode;
}

export const ConfirmDialogWithInfo: FC<IConfirmDialogWithInfoProps> = ({
	titleText,
	confirmText,
	infoText,
	visible,
	closeButtonText,
	confirmButtonText,
	onClose,
	onConfirm,
}) => {
	const { t } = useTranslation('portal');

	return (
		<>
			<ModalDialog
				variant="primary"
				fullWidth={false}
				open={visible}
				titleProps={{
					sx: {
						justifyContent: 'space-between',
						marginBottom: 3,
						minHeight: 30,
						paddingY: 1.2,
						paddingX: 2,
					},
				}}
				data-testid={DataTestId.getStaticTestId('confirm-dialog-test-id')}
				title={
					<Typography variant="pg-s" sx={{ lineHeight: '200%' }}>
						{t('confirmation')}
					</Typography>
				}
				onClose={() => onClose?.()}
				maxWidth="sm"
				iconButtonProps={{ padding: 0, right: 16 }}
				contentProps={{ sx: { width: 472 } }}
			>
				<Stack width={1} direction="column" alignItems="center" justifyContent="space-between">
					<Stack sx={{ textAlign: 'center', paddingX: 8 }}>
						<Typography variant="subtitle1">{titleText}</Typography>
						{confirmText && (
							<Typography variant="body2" marginTop={3} lineHeight="160%">
								{confirmText}
							</Typography>
						)}
						<Stack marginTop={1}>
							<InfoMessageBox message={infoText} />
						</Stack>
					</Stack>

					<Stack marginTop={3} direction="row" paddingX={4} marginBottom="56px" justifyContent="center" width={1}>
						{onClose && (
							<Button
								sx={{ width: '166px', height: '40px' }}
								variant="outlined"
								disableElevation
								onClick={onClose}
								data-testid="cancel-button-test-id"
							>
								{closeButtonText || <Tr.Portal path="analysis-result.cancel" />}
							</Button>
						)}

						{onConfirm && (
							<Button
								sx={{ width: '166px', height: '40px', marginLeft: '12px' }}
								variant="contained"
								disableElevation
								onClick={onConfirm}
								data-testid="confirm-test-id"
							>
								{confirmButtonText || <Tr.Portal path="analysis-result.i-approve" />}
							</Button>
						)}
					</Stack>
				</Stack>
			</ModalDialog>
		</>
	);
};
