import { FC, ReactNode } from 'react';
import { Link, MenuItem, Stack, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { FormikApiType, FormikTextfield } from '@components/common';
import { NmrCalibrationStatus, NmrDeviceCalibration } from '@services';
import { HereLink } from '../NmrAnalysisTypeSelection';
import { Tr } from '@utils/Translation';
import { PortalPageRoutes } from '@models/router';
import { useAnalysis } from '@hooks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { useTranslation } from 'react-i18next';
interface ICalibrationListType {
	name: string;
	title?: ReactNode;
	typographyProps?: any;
	calibrationList?: NmrDeviceCalibration[];
	formik: FormikApiType;
}

const TypographyType: FC<TypographyProps & { render: ReactNode }> = ({ sx, render, ...props }) => (
	<Typography variant="label-s" sx={{ color: 'error.main', ...sx }} {...props}>
		<ErrorOutlinedIcon sx={{ height: 14, width: 14, marginRight: '6px', transform: 'translateY(2px)' }} />
		{render}
	</Typography>
);

const CalibrationErrorMessage = (calibration: NmrDeviceCalibration) => (
	<TypographyType
		render={
			<>
				<Tr.Portal path="new-analysis.calibration-has-failed" />
				<HereLink href={`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${calibration.nmrDeviceId}`} />
				<Tr.Portal path="new-analysis.to-view-details-of-calibration-error." />
			</>
		}
	/>
);

const UnCalibratedMessage = (calibration: NmrDeviceCalibration) => (
	<TypographyType
		render={
			<>
				<Tr.Portal path="new-analysis.selected-calibration-overdue" />
				<HereLink href={`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${calibration.nmrDeviceId}`} />
				<Tr.Portal path="new-analysis.to-calibrate" />
			</>
		}
	/>
);

const ReceiverGainErrorMessage = (calibration: NmrDeviceCalibration) => (
	<TypographyType
		render={
			<>
				<Tr.Portal path="new-analysis.receiver-gain" />
				<Link
					sx={{
						display: 'inline-block',
						fontWeight: 'bolder',
						color: 'inherit',
						textDecorationColor: 'inherit',
						textTransform: 'lowercase',
					}}
					href={`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${calibration.nmrDeviceId}`}
				>
					<Tr.Portal path="new-analysis.recalibrate" />
				</Link>
				<Tr.Portal path="new-analysis.receiver-gain-rest" />
			</>
		}
	/>
);

const CalibratingMessage = () => <TypographyType render={<Tr.Portal path="new-analysis.calibration-in-progress" />} />;

const EmptyMessage = () => null;

const ErrorContentMapping: Record<NmrCalibrationStatus, (calibration: NmrDeviceCalibration) => ReactNode> = {
	[NmrCalibrationStatus.CALIBRATION_ERROR]: CalibrationErrorMessage,
	[NmrCalibrationStatus.CALIBRATION_CHECK_NEEDED]: UnCalibratedMessage,
	[NmrCalibrationStatus.CALIBRATION_NEEDED]: UnCalibratedMessage,
	[NmrCalibrationStatus.CALIBRATING]: CalibratingMessage,
	[NmrCalibrationStatus.ACTIVE]: EmptyMessage,
	[NmrCalibrationStatus.INACTIVE]: EmptyMessage,
};

export const CalibrationList: FC<ICalibrationListType & TextFieldProps> = ({
	formik,
	name,
	title,
	typographyProps,
	calibrationList,
	...rest
}) => {
	const { metaData } = useAnalysis();
	const { t } = useTranslation('portal');

	const getLegend = () => {
		const value = formik?.values[`${name}`];
		if (!value) EmptyMessage();

		const calibration = calibrationList?.find((cal) => cal.id === value);

		if (!calibration) return EmptyMessage();

		const isCalibrationActive = calibration.status === NmrCalibrationStatus.ACTIVE;
		const isRgInvalid =
			Number(metaData?.RG) < calibration.receiverGainThresholds.min || Number(metaData?.RG) > calibration.receiverGainThresholds.max;

		if (isCalibrationActive && isRgInvalid) {
			return ReceiverGainErrorMessage(calibration);
		}
		return ErrorContentMapping[calibration.status](calibration);
	};

	const errorMessage = getLegend();

	const textInputStyle = {
		backgroundColor: 'grey.50',
		'& .MuiOutlinedInput-root': {
			height: '40px',
		},
		fieldset: {
			borderColor: errorMessage ? 'error.main' : 'grey.200',
		},
	};

	return (
		<Stack direction="column" sx={{ marginBottom: 1 }}>
			<FormikTextfield
				data-testid="calibration-list-test-id"
				formikApi={formik as FormikApiType}
				name={name}
				select
				required
				onChange={(e) => formik?.setFieldValue(name, e.target.value)}
				title={title}
				variant="outlined"
				typographyProps={typographyProps}
				fullWidth
				type="number"
				helperText={false}
				sx={{ ...textInputStyle }}
				SelectProps={{
					IconComponent: (props) => <KeyboardArrowDownIcon {...props}></KeyboardArrowDownIcon>,
					displayEmpty: true,
					sx: {
						'& svg': {
							color: 'primary.main',
						},
					},
					renderValue: (value) => (
						<>
							{value
								? calibrationList?.filter((calibration) => calibration.id === value)?.[0].name
								: t('new-analysis.select-a-calibration')}
						</>
					),
				}}
				{...rest}
			>
				{calibrationList
					?.filter((calibration) => calibration.status !== NmrCalibrationStatus.INACTIVE)
					.map((calibration, index) => (
						<MenuItem key={`index-${index}-${calibration.id}`} value={calibration.id}>
							{calibration.name}
						</MenuItem>
					))}
			</FormikTextfield>
			{errorMessage && <Stack marginTop={0.5}>{errorMessage}</Stack>}
		</Stack>
	);
};
