import { UserStoreModel } from '@models/user';
import { Avatar, AvatarProps, SxProps, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

export interface IAvatarIcon extends AvatarProps {
	user: Maybe<UserStoreModel>;
	backgroundColor?: string;
	textColor?: string;
	sx?: SxProps;
}

export const AvatarIcon: FC<IAvatarIcon> = ({ user, backgroundColor, textColor, sx, ...props }) => {
	const name = (user?.name ?? 'Unknown') + ' ' + (user?.surname ?? 'User');
	return (
		<Tooltip placement="top" title={name}>
			<Avatar
				{...props}
				sx={{
					color: 'primary.main',
					backgroundColor: backgroundColor ?? '#e0f1e9',
					width: '32px',
					height: '32px',
					...sx,
				}}
			>
				<Typography variant="h6" color={textColor ?? 'primary.main'}>
					{getAvatarLetters(user)}
				</Typography>
			</Avatar>
		</Tooltip>
	);
};

const getAvatarLetters = (user: Maybe<UserStoreModel>) => {
	return (user?.name && user?.surname ? [user.name[0], user.surname[0]] : user?.email ? [user.email[0]] : []).join('').toUpperCase();
};
