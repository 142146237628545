import { concatMap, tap, map } from 'rxjs/operators';
import { PagedResult, PatchType, UserStatus } from '@models';
import { RxUtils, TypeUtils } from '@utils';
import { httpService } from '@services';
import { logger } from '@services';
import { AddUserToOrganization, EditUserOrganization } from './add-user-to-organization.interface';
import { UserRequestModel } from './user-request-model.interface';
import { coreConfig } from '../coreConfig';
import { UserResponseModel } from '@models/user/user-response.model';
import { UserUpdateModel } from '@models/user/user-update.model';
import { UserExportModel } from './user-export-model.interface';

export class UserService {
	private getURL() {
		return `${coreConfig.url}/User`;
	}

	getAll(userRequestModel: Partial<UserRequestModel>) {
		const params = new URLSearchParams();
		Object.entries(userRequestModel).forEach(([k, v]) => params.append(k, v.toString()));
		return this.getAllWithParams(params);
	}

	getAllWithParams(params: URLSearchParams, suffix?: string) {
		return httpService
			.get<PagedResult<UserResponseModel>>(`${this.getURL()}${suffix ?? ''}`, '', {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllWithParams.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<UserResponseModel>(UserResponseModel), result)),
			);
	}

	get(id: string) {
		return httpService.get<UserResponseModel>(`${this.getURL()}`, `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(UserResponseModel, result)),
		);
	}

	addUserToOrganization(addUserToOrganizationModel: AddUserToOrganization) {
		return httpService.post(this.getURL(), '', { body: addUserToOrganizationModel, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.addUserToOrganization.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	updateUser(user: UserUpdateModel) {
		return httpService
			.put<UserUpdateModel>(this.getURL(), '', {
				body: { ...user },
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.updateUser.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(UserResponseModel, result)),
			);
	}

	updateOrganizationUser(user: EditUserOrganization) {
		//parameter model should be refactored
		return httpService
			.put<UserResponseModel>(this.getURL(), '', {
				body: { ...user, status: user.status === UserStatus.ACTIVE ? true : false },
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.updateOrganizationUser.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(UserResponseModel, result)),
			);
	}

	deleteUser(id: string) {
		return httpService
			.delete(this.getURL(), `${id}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.deleteUser.name}]`, result)));
	}

	resendInvitation(userId: string) {
		return httpService.put(this.getURL(), `invitation/${userId}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.resendInvitation.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(UserResponseModel, result)),
		);
	}

	getCurrentUserPermissions(currentLabId?: Maybe<string>) {
		return httpService
			.get<string[]>(`${this.getURL()}`, `me/permissions`, {
				errorHandler: 'notification',
				headers: { LaboratoryId: currentLabId ?? '' },
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getCurrentUserPermissions.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	updateStatus(id: string, status: UserStatus) {
		return httpService.patch(`${this.getURL()}`, `${id}`, {
			errorHandler: 'notification',
			body: [{ op: PatchType.REPLACE, path: '/status', value: status }],
		});
	}

	updateMerckUser(id: string, updatedAttributes: { op: PatchType; path: string; value: any }) {
		return httpService
			.patch<UserUpdateModel>(this.getURL(), `merck/${id}`, {
				body: [updatedAttributes],
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.updateMerckUser.name}]`, result)));
	}

	patchUser(id: string, data: { op: PatchType; path: string; value: any }) {
		return httpService
			.patch<UserUpdateModel>(this.getURL(), `${id}`, {
				body: [data],
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.updateMerckUser.name}]`, result)));
	}

	export({ query, filteringData, sortBy, sortOrder }: UserExportModel) {
		const params = new URLSearchParams();
		params.append('query', `${query || ''}`);
		if (sortBy && sortOrder) {
			params.append('sortBy', sortBy);
			params.append('sortOrder', sortOrder);
		}
		filteringData?.forEach((item) => {
			const entry = Object.entries(item as {})[0];
			entry[0] && entry[1] && params.append(entry[0], `${entry[1]}`);
		});
		return httpService
			.get(this.getURL(), `export`, {
				params: params,
				responseType: 'blob',
				headers: { 'Content-Type': 'text/csv' },
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.export.name}]`, result)));
	}
}

export const userService = new UserService();
