import { UserRole } from '@models/user';

const PortalOptionSet = [UserRole.ORG_ADMIN, UserRole.LAB_ADMIN, UserRole.LAB_MEMBER];

const PortalOptionSetConfig = {
	[UserRole.ORG_ADMIN]: {
		disabledOnChoosen: [UserRole.LAB_MEMBER],
	},
	[UserRole.LAB_ADMIN]: {
		disabledOnChoosen: [UserRole.LAB_MEMBER],
	},
	[UserRole.LAB_MEMBER]: {
		disabledOnChoosen: [UserRole.ORG_ADMIN, UserRole.LAB_ADMIN],
	},
};

export interface SetConfig {
	[key: string]: {
		disabledOnChoosen: UserRole[];
	};
}

export interface IOptionConfig {
	options: UserRole[];
	config: SetConfig;
}

export const SetConfigMapping: Record<'portal', IOptionConfig> = {
	portal: {
		options: PortalOptionSet,
		config: PortalOptionSetConfig,
	},
};
