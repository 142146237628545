import { GenericCard, InvalidFileUpload, LoadingFileUpload, ValidFileUpload } from '@components/common';
import { FileStatus, UploadableFileExtensionMapping } from '@models/file';
import { nameLengthValidator } from '@utils/File';
import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { NmrPipelineBulkUploadFile } from './nmr-pipeline-bulk-upload.enum';

const MAX_FILE_SIZE = 209715200;

type TNmrPipelineBulkUpload = {
	onRemove: (index: number) => void;
	onUploadedFileChange?: (f?: unknown[]) => void;
	uploadedFile: NmrPipelineBulkUploadFile;
	disabled?: boolean;
	loading?: boolean;
};

export const NmrPipelineBulkUpload: FC<TNmrPipelineBulkUpload> = ({
	onRemove,
	onUploadedFileChange,
	uploadedFile,
	disabled = false,
	loading = false,
}) => {
	const [reUploading, setReUploading] = useState<number>();
	const onDrop = (file: File[]) => {
		if (file && reUploading !== undefined) {
			onRemove(reUploading);
			onUploadedFileChange?.(file);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (acceptedFiles) => onDrop(acceptedFiles),
		multiple: false,
		accept: UploadableFileExtensionMapping.NmrPipeline,
		useFsAccessApi: false,
		maxSize: MAX_FILE_SIZE,
		validator: nameLengthValidator,
	});

	return (
		<GenericCard sx={{ padding: 0 }}>
			{!loading && (
				<>
					{uploadedFile.fileStatus === FileStatus.VALID ? (
						<ValidFileUpload onRemove={onRemove} index={0} fileName={uploadedFile.fileName} buttonDisabled={disabled} />
					) : (
						<InvalidFileUpload
							fileName={uploadedFile.fileName}
							getRootProps={getRootProps}
							getInputProps={getInputProps}
							setReUploading={setReUploading}
							index={0}
						/>
					)}
				</>
			)}
			{loading && <LoadingFileUpload />}
		</GenericCard>
	);
};
