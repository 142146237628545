import { concatMap, map, tap } from 'rxjs';
import { httpService, logger } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { irConfig } from '../irConfig';
import { PagedResult } from '@models';
import { IrSubstance } from './ir-substance.class';
import {} from './ir-drm.class';

class IrSubstanceService {
	private getUrl() {
		return `${irConfig.url}`;
	}

	getSubstances(searchParams: URLSearchParams) {
		return httpService
			.get<PagedResult<IrSubstance>>(`${this.getUrl()}/substance`, ``, {
				params: searchParams,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getSubstances.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrSubstance>(IrSubstance), result)),
			);
	}
}

export const irSubstanceService = new IrSubstanceService();
