import { FilterKey, FilterValueType, IFilter } from '@models';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Chip, ChipProps, SxProps } from '@mui/material';
import { Tr } from '@utils';
import { format } from 'date-fns';
import { FC, useRef } from 'react';

interface IFilterChips {
	filterData?: IFilter[];
	selectedFilterData?: FilterValueType[];
	onDeleteItem?: (deletedItem: { key: string; value: string }) => void;
	sx?: SxProps;
	chipProps?: Omit<ChipProps, 'color'>;
	buttonSx?: SxProps;
}

const ChipComponent: FC<Omit<ChipProps, 'color'>> = ({ sx, ...props }) => (
	<Chip
		color="success"
		sx={{
			borderRadius: '0.2rem',
			fontSize: '0.85rem',
			fontWeight: 650,
			margin: '10px 0 10px 0.75rem',
			' .MuiChip-deleteIcon': {
				color: 'inherit',
			},
			...sx,
		}}
		{...props}
		deleteIcon={<CloseIcon sx={{ width: '1.2rem', height: '1.2rem', color: 'background.paper' }} />}
	/>
);

export const FilterChips: FC<IFilterChips> = ({ filterData, selectedFilterData, buttonSx, chipProps, onDeleteItem, sx }) => {
	const boxRef = useRef<HTMLElement>(null);
	const getData = (key: string, id: string) =>
		filterData?.find((item) => item.key === key)?.values.find((item) => `${item.id}` === id)?.value;

	const getEscapesTranslation = (key: string) => filterData?.find((item) => item.key === key)?.escapesTranslation;

	const getDateRangeFormat = (from?: Date, to?: Date) => `${from ? format(from, 'MMM dd') : ''}-${to ? format(to, 'MMM dd') : ''}`;

	return selectedFilterData?.length && selectedFilterData.length > 0 ? (
		<Box sx={{ borderBottom: '1px solid #DCDCE8', display: 'flex', alignItems: 'center', ...sx }}>
			<Box ref={boxRef}>
				{selectedFilterData.map((item, index) => {
					const [key, value] = Object.entries(item)[0] as [string, string] | [string, Date[]];
					if (!Object.values(FilterKey).includes(key as FilterKey)) return null;
					if (key === 'from' || key === 'to') return null;
					if (value && key === 'dateRange' && (typeof value === 'string' || (value as Date[])?.every((date) => !date)))
						return null;

					if (value && key === 'dateRange') {
						return (
							<ChipComponent
								onDelete={() => onDeleteItem?.({ key: 'dateRange', value: 'dateRange' })}
								key={`${index}`}
								label={getDateRangeFormat((value as Date[])?.[0], (value as Date[])?.[1]) || ''}
								{...chipProps}
							/>
						);
					}

					if (value) {
						const escapesTranslation = getEscapesTranslation(key);
						const data = getData(key as string, value as string);
						if (!data) {
							return null;
						}
						return (
							<ChipComponent
								onDelete={() => onDeleteItem?.({ key, value: value as string })}
								key={`${index}`}
								label={escapesTranslation ? data : <Tr.Filter path={`filter.${data?.toLowerCase()}`} />}
								{...chipProps}
							/>
						);
					}
				})}
			</Box>
			{boxRef?.current?.children.length && boxRef.current.children.length > 1 ? (
				<Button
					onClick={() => onDeleteItem?.({ key: 'clearAll', value: 'clearAll' })}
					color="inherit"
					size="small"
					disableElevation
					sx={{ color: '#8B8B9C', marginLeft: '1rem', marginRight: '1rem', fontWeight: 550, ...buttonSx }}
				>
					<Tr.Filter path="common.clear-all" />
				</Button>
			) : (
				<></>
			)}
		</Box>
	) : (
		<></>
	);
};
