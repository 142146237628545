import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RmSpectrumInfoPurity, SampleSpectrumInfoPurity, peakDetails } from '@services';
import { SpectraColorCodes, Tr, getPeakHighligtShapes, getTransMissionData } from '@utils';
import { Stack } from '@mui/material';
import { IrChart } from '../IrChart';
import { setIrTransmissionSpectra, useIrSettingsSelector } from '@store/slices/ir/ir.slice';
import { useDispatch, useSelector } from 'react-redux';

interface IStackedSpectrumProps {
	createdReport?: boolean;
	subtitleVisibility?: boolean;
	rankedRMData: RmSpectrumInfoPurity;
	userSpectrumInfo: SampleSpectrumInfoPurity;
	quantificationDetails?: Array<peakDetails>;
}

export const IrOverlayQuantificationChart: FC<IStackedSpectrumProps> = ({
	subtitleVisibility,
	createdReport = false,
	rankedRMData,
	userSpectrumInfo,
	quantificationDetails,
}) => {
	const [chartDataList, setChartDataList] = useState<any>([]);
	const { t } = useTranslation('irportal');
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra || false);
	const dispatch = useDispatch();

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
		dispatch(setIrTransmissionSpectra(!displaySettingsToggle));
	};
	useEffect(() => {
		if (userSpectrumInfo && rankedRMData) {
			const chartDataAry: any = [];
			const sampleSpectrumData = {
				y: displaySettingsToggle ? getTransMissionData([...userSpectrumInfo.sample_y]) : userSpectrumInfo.sample_y,
				x: userSpectrumInfo.sample_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.RICH_GREEN },
				direction: 'counterclockwise',
				name: t('analysis-result.sample-spectrum'),
			};
			const targetSubstanceData = {
				y: displaySettingsToggle ? getTransMissionData([...rankedRMData.spectrum_y]) : rankedRMData.spectrum_y,
				x: rankedRMData.spectrum_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
				direction: 'counterclockwise',
				name: t('analysis-result.target-substance-spectrum'),
			};
			chartDataAry.push(sampleSpectrumData, targetSubstanceData);
			setChartDataList(chartDataAry);
		}
	}, [rankedRMData, userSpectrumInfo, displaySettingsToggle]);

	return (
		<Stack sx={{ border: '1px solid black', borderColor: 'grey.200', borderRadius: '8px' }} data-testid="IrOverlayQuantificationChart">
			{chartDataList && (
				<IrChart
					subTitle={
						displaySettingsToggle ? (
							<Tr.IrPortal path="analysis-result.transmission" />
						) : (
							<Tr.IrPortal path="analysis-result.intensity" />
						)
					}
					subtitleVisibility={!!subtitleVisibility}
					className={'c-seperated-chart'}
					enableZoom={false}
					hideZoomIcon={createdReport}
					data={chartDataList}
					useResizeHandler
					displaySettingsToggle={handleDisplaySettings}
					style={{ width: '100%', height: '100%' }}
					layout={{
						margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
						hovermode: 'x unified',
						autosize: true,
						legend: {
							traceorder: 'reversed',
							yanchor: 'bottom',
							y: 1.1,
							xanchor: 'center',
							x: 0.4,
							orientation: 'h',
							bgcolor: 'transparent',
							font: { color: '#0F1A2E', size: 12 },
						},
						xaxis: {
							showdividers: false,
							showline: true,
							zeroline: false,
							range: userSpectrumInfo.sample_x_range,
							gridcolor: SpectraColorCodes.GRAY,
							griddash: 'dot',
							//automargin: true,
							position: 10,
						},
						yaxis: { showline: true, zeroline: false, automargin: true },
						shapes: getPeakHighligtShapes(quantificationDetails),
					}}
				/>
			)}
		</Stack>
	);
};
