import { IrAnalysisType } from '@models';
import { AnalysisTechnology, IrAnalysisResultStatus } from '@services';
import { Type } from 'class-transformer';
import { IrAnalysisStage } from './ir-analysis-stage.enum';
import { IrAnalysisResultMatchType, IrPdfReportImpurityPeak, IrPeaksInfo } from './ir-analysis-result.class';

export class IrAnalysis {
	id: number;
	title: string;
	projectId: string;
	stage: IrAnalysisStage;
	status: IrAnalysisResultStatus | IrAnalysisResultMatchType;
	statusIcon?: IrAnalysisResultStatus;
	operatorName: string;
	operatorId?: string;
	comment: string;
	technology: AnalysisTechnology;
	type: IrAnalysisType;
	matchFound: boolean;

	@Type(() => Date)
	startDate: Date;

	@Type(() => Date)
	measurementDate: Date;

	@Type(() => Date)
	lastUpdateTime: Date;

	@Type(() => Date)
	lastActivityDate: Date;

	@Type(() => Date)
	completedTime: Date;

	substances: string;
	solvent: string;
	quantificationMethod: string;
	device: string;
	deviceId: Maybe<number>;
	accessory: string;
	measurementDeviceBrand: string;
	probhd: string | undefined;
	details: string;
	measurementId: string;
	productAndBatchNumber: string;
	measurementExecutorId: string;
	sampleWeight: number;
	solventWeight: number;
	peakMin1: number;
	peakMax1: number;
	peakMin2: number;
	peakMax2: number;
	receiverGain: number | null | undefined;
	secondApproval: boolean;
	includeMixture: boolean;
	deviceCalibrationId: number;
	accessoryId: Maybe<number>;
	selectedLibrary: Array<string>;
	referenceId: string;
	selectedImpurity: Maybe<string>;
	approverId: Maybe<string>;

	isExecuting() {
		return this.stage === IrAnalysisStage.EXECUTING;
	}

	isExecuted() {
		return this.stage === IrAnalysisStage.EXECUTED;
	}

	public isRejectedByAdmin(): boolean {
		return this.stage === IrAnalysisStage.REJECTED_BY_LAB_ADMIN;
	}

	public isRejectedByMember(): boolean {
		return this.stage === IrAnalysisStage.REJECTED_BY_LAB_MEMBER;
	}

	public isAnalysisRejected(): boolean {
		return this.isRejectedByAdmin() || this.isRejectedByMember();
	}

	public isUnsuccessful(): boolean {
		return this.status === IrAnalysisResultStatus.UNSATISFIED || this.status === IrAnalysisResultStatus.TECHNICAL_ERROR;
	}

	public isSuccessful(): boolean {
		return this.status === IrAnalysisResultStatus.SATISFIED || this.status === IrAnalysisResultStatus.QUESTIONABLE;
	}

	public isQuestionablySuccessful(): boolean {
		return this.status === IrAnalysisResultStatus.QUESTIONABLE;
	}

	public isCompletedNoMatch(): boolean {
		return this.status === IrAnalysisResultStatus.UNSATISFIED;
	}

	public isErrorInAnalysis(): boolean {
		return this.status === IrAnalysisResultStatus.TECHNICAL_ERROR;
	}
}

export interface IrReportImageModel {
	referenceMaterialId: string;
	casNumber: string;
	moleculeImage: string;
	referenceMaterialSpectrum: string;
	peaktableJson: IrPeaksInfo[];
	referencePeakRatio: string;
	userPeakRatio: string;
	impurityPeakJson?: IrPdfReportImpurityPeak[];
}

export interface IrAnalysisReportImages {
	substanceImages: Partial<IrReportImageModel>[];
	mixtureImages: string | null;
	id?: number;
}
