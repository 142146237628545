import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikTextfield, ModalDialog } from '@components/common';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RxUtils } from '@utils/Rx';
import { nmrDeviceService } from '@services/nmr';

export interface IEditCalibrationNameDialogProps {
	calibrationId: number;
	name: string;
	onClose: () => void;
	onSave: () => void;
}

export const EditCalibrationNameDialog: FC<IEditCalibrationNameDialogProps> = ({ calibrationId, name, onClose, onSave }) => {
	const { t } = useTranslation('portal');

	const formik = useFormik<Extendable<{ calibrationName: string }>>({
		initialValues: {
			calibrationName: name,
		},
		validationSchema: yup.object({
			calibrationName: yup.string().trim().required(t('device-management.field-error.calibration-name')),
		}),
		initialTouched: {
			calibrationName: true,
		},
		onSubmit: ({ calibrationName }) =>
			RxUtils.promisify(nmrDeviceService.renameCalibration(calibrationId, calibrationName.trim()), () => onSave()),
	});

	// TO DO: USE FORM MODAL DIALOG
	return (
		<>
			<ModalDialog
				variant="primary"
				fullWidth={false}
				open={true}
				titleProps={{
					sx: {
						justifyContent: 'space-between',
						marginTop: 0,
						marginBottom: 3,
						minHeight: 40,
						backgroundColor: 'grey.50',
						paddingX: 2,
						paddingY: 1.2,
						height: 40,
					},
				}}
				title={<Typography variant="pg-s">{t('device-management.rename')}</Typography>}
				onClose={() => onClose()}
				maxWidth="sm"
				iconButtonProps={{
					right: 8,
				}}
				PaperProps={{
					sx: {
						maxWidth: '472px',
						minHeight: '351px',
						justifyContent: 'space-between',
					},
				}}
				contentProps={{ sx: { width: 472, paddingBottom: 3 } }}
			>
				<Stack data-testid="dialog-wrapper-id" width={1} direction="column" justifyContent="space-between">
					<form onSubmit={formik.handleSubmit}>
						<Stack paddingX={8} spacing={3}>
							<Stack sx={{ textAlign: 'center' }} spacing={3}>
								<Typography variant="subtitle1">{t('device-management.edit-calibration-name')}</Typography>
								<Typography variant="pg-m" sx={{ lineHeight: '160%' }}>
									{t('device-management.edit-calibration-name-message')}
								</Typography>
							</Stack>

							<FormikTextfield
								formikApi={formik}
								data-testid="edit-calibration-name-text-id"
								name="calibrationName"
								required
								placeholder={t('device-management.calibration-name-placeholder')}
								containerProps={{ width: 1 }}
								inputProps={{ maxLength: 256 }}
							/>
						</Stack>

						<Stack direction="row" width={1} paddingX={8} marginTop={4}>
							<Button
								data-testid="edit-calibration-name-cancel-id"
								variant="outlined"
								sx={{ marginRight: '1rem', width: '100%' }}
								onClick={() => onClose()}
							>
								<Tr.Portal path="device-management.cancel" />
							</Button>
							<Button
								data-testid="edit-calibration-name-button-id"
								type="submit"
								sx={{ width: '100%' }}
								disabled={!formik.dirty}
								variant="contained"
								disableElevation
							>
								<Tr.Portal path="Save" />
							</Button>
						</Stack>
					</form>
				</Stack>
			</ModalDialog>
		</>
	);
};
