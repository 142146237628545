import { FC, ReactNode, useState } from 'react';
import { styled } from '@mui/material/styles';
import { AccordionProps, Grid, Typography, Accordion, Button } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AvatarIcon } from '@components/common/UserAvatar/AvatarIcon';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { alertService, notificationService } from '@services/core';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common/common.slice';
import { useTranslation } from 'react-i18next';
import { RxUtils } from '@utils/Rx';
import { PageRoutes } from '@models/router';
import { useLocation } from 'react-router-dom';
import { nmrDrmRequestService, NmrRequestAccordionItem } from '@services/nmr';
import { NmrRequestCommentForm } from '../NmrRequestCommentForm';
import { NmrDrmOrderCommentDropdownType } from '@services/nmr-drm-pipeline';
import { TableButton } from '@components/common/TableButton';

interface INmrRequestNoteAccordionProps {
	itemList: Array<NmrRequestAccordionItem>;
	renderChildren?: (data: unknown) => ReactNode;
	renderTitleAction?: (item: unknown) => ReactNode;
	dateFormat?: string;
	avatarIconTextColor?: string;
	onCommentUpdate?: (value: boolean) => void;
	dropDownSelection?: NmrDrmOrderCommentDropdownType;
	isEditable?: boolean;
}

export const NmrRequestNoteAccordion: FC<INmrRequestNoteAccordionProps> = ({
	itemList,
	renderChildren,
	onCommentUpdate,
	renderTitleAction,
	dateFormat = 'dd/MM/yyyy HH:mm',
	avatarIconTextColor,
	dropDownSelection,
	isEditable = true,
}) => {
	const loggedinUser = useSelector(userSelector);
	const [commentEditId, setCommentEditId] = useState<number | undefined>(-1);
	const loc = useLocation();

	const isOrderRequestPage = loc.pathname.split('/').includes(PageRoutes.DRM_ORDER_DETAILS);

	// TODO cleanup styled containers
	const AccordionContainer = styled((props: AccordionProps) => (
		<Accordion disableGutters elevation={0} defaultExpanded={false} square {...props} />
	))(({ theme }) => ({
		'&:not(:last-child)': {
			borderBottom: `1px solid ${theme.palette.grey[200]}`,
		},
		'&:before': {
			display: 'none',
		},
	}));

	const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'text.secondary' }} />} {...props} />
	))(() => ({
		paddingLeft: 0,
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(() => ({
		paddingLeft: '40px',
	}));

	const AccordionAvatarIcon = styled((props: any) => <AvatarIcon textColor={avatarIconTextColor} {...props} />)(({ theme }) => ({
		backgroundColor: `${theme.palette.grey[100]}`,
		'> p': {
			color: `${theme.palette.grey[400]}`,
		},
	}));
	const { t } = useTranslation('admin');

	const handleDeleteComment = (item: NmrRequestAccordionItem) => {
		alertService.send({
			titleText: t('erm-request-detail.comments.confirmation'),
			content: <Typography>{t('erm-request-detail.comments.confirmation-explanation')}</Typography>,
			closeTextComponent: t('erm-request-detail.comments.cancel'),
			confirmTextComponent: t('erm-request-detail.comments.yes-delete'),
			confirmTextHeader: t('erm-request-detail.comments.confirmation-title'),
			onConfirm: () =>
				RxUtils.promisify(
					nmrDrmRequestService.deleteComment(item.referenceMaterialRequestId ?? 0, item.id),
					() => {
						notificationService.sendSuccess(t('erm-request-detail.comments.delete-comment-success'));
					},
					(err) => notificationService.sendError(err.Message),
					() => {
						onCommentUpdate?.(true);
					},
				),
			onClose: () => null,
		});
	};

	return (
		<>
			{itemList.map((item, i) => (
				<AccordionContainer
					key={i}
					data-testid={`accordion-wrapper-${i}-id`}
					defaultExpanded={!commentEditId || commentEditId === 0 || commentEditId !== item.id ? false : true}
				>
					<AccordionSummary>
						<Grid container spacing={2}>
							<Grid item>
								<AccordionAvatarIcon user={{ name: item.name, surname: item.surname }} />
							</Grid>
							<Grid item>
								<Typography variant="pg-m">
									<span style={{ color: '#01884C' }}>
										{item.name} {item.surname}
									</span>
									{renderTitleAction ? (
										renderTitleAction(item.data)
									) : (
										<Tr.Admin path={`erm-request-detail.accordion.title`} />
									)}
									{DateUtils.getFormattedDate(new Date(item.createTime), dateFormat)}
									{item.isEdited && (
										<Typography variant="pg-m" color="grey.800" marginLeft={1}>
											<Tr.Admin path={`erm-request-detail.accordion.edited`} />
										</Typography>
									)}
								</Typography>
							</Grid>
						</Grid>

						{dropDownSelection === NmrDrmOrderCommentDropdownType.REQUEST && isOrderRequestPage && (
							<Grid item sx={{ paddingRight: '12px' }}>
								<TableButton.Outlined
									sx={{
										alignSelf: 'end',
										borderColor: '#A9A9BA',
										color: '#5E5E72',
										padding: '6px',
										fontSize: '14px',
										borderWidth: '1px',
										width: '7rem',
									}}
									href={`/admin/${PageRoutes.DRM_MANAGEMENT}/${PageRoutes.ERM_REQUESTS}/nmr/${item.referenceMaterialRequestId}`}
									data-testid={`erm-request-detail.request-${item}-id`}
								>
									{`Request #${item.referenceMaterialRequestId}`}
								</TableButton.Outlined>
							</Grid>
						)}
					</AccordionSummary>
					{loggedinUser?.username === item.userId && commentEditId === item.id ? (
						<Grid item sx={{ paddingY: 2 }}>
							<NmrRequestCommentForm
								commentInfo={item}
								setCommentEditId={setCommentEditId}
								onCommentUpdate={onCommentUpdate}
							/>
						</Grid>
					) : (
						<AccordionDetails>
							<Typography
								variant="pg-m"
								sx={{
									overflowWrap: 'anywhere',
								}}
							>
								{item.content}
							</Typography>
							{renderChildren?.(item.data)}
						</AccordionDetails>
					)}
					{loggedinUser?.username === item.userId && commentEditId !== item.id && isEditable && (
						<Grid item sx={{ paddingY: 2 }}>
							<Grid container justifyContent="flex-end" spacing={2}>
								<Grid item>
									<Button data-testid="comment-delete-id" size="small" onClick={() => handleDeleteComment(item)}>
										<Tr.Admin path={`erm-request-detail.comments.delete`} />
									</Button>
								</Grid>
								<Grid item>
									<Button
										data-testid="comment-edit-id"
										size="small"
										onClick={() => {
											setCommentEditId(item.id);
										}}
									>
										<Tr.Admin path={`erm-request-detail.comments.edit`} />
									</Button>
								</Grid>
							</Grid>
						</Grid>
					)}
				</AccordionContainer>
			))}
		</>
	);
};
