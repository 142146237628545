import { SxProps } from '@mui/material';
import { NmrDrmDetail } from '@services/nmr';
import { useState } from 'react';

export interface DRMDetailState {
	visible: boolean;
	drmId?: number | null;
	drmData?: NmrDrmDetail;
	actionLabel?: string;
	onHandleAction?: (drmId: number) => void;
	actionDisabled?: boolean;
	actionBtnSx?: SxProps;
	isTheoretical?: boolean;
	onRequestDrm?: (drm: NmrDrmDetail) => void;
}

const initialDRMDetail = {
	visible: false,
	drmId: null,
	actionLabel: '',
	actionBtnSx: {},
	onHandleAction: () => null,
	actionDisabled: false,
};

export const useDRMDetail = () => {
	const [drmDetail, setDRMDetail] = useState<DRMDetailState>(initialDRMDetail);

	function resetDetail() {
		setDRMDetail(initialDRMDetail);
	}

	return { ...drmDetail, setDRMDetail, resetDetail };
};

// TO DO: CHANGE ITS FILE
