export enum ErmEventAction {
	ERM_SEARCHED = 'ErmSearched',
	ERM_REQUESTED = 'ErmRequested',
	ERM_USED = 'ErmUsed',
	ERM_INPROGRESS = 'ErmInprogress',
	ERM_UNAVAILABLE = 'ErmUnavailable',
}

export enum ErmEventLabel {
	ERM_SEARCHED = 'Erm was Searched by',
	ERM_REQUESTED = 'Erm was requested',
	ERM_USED = 'ERM was used',
	ERM_INPROGRESS = 'ErmInprogress',
	ERM_UNAVAILABLE = 'ErmUnavailable',
}
