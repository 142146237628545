import { createContext } from 'react';
import { IProps } from './props';
export * from './provider';
export * from './props';
export const AnalysisContext = createContext<IProps>({
	addedERMs: [],
	updateAddedERMs: () => null,
	quantitativeStep: null,
	updateQuantitativeStep: () => null,
	nmrDevice: null,
	updateNmrDevice: () => null,
	measurementType: null,
	updateMeasurementType: () => null,
	targetType: null,
	updateTargetType: () => null,
	metaData: null,
	updateMetaData: () => null,
	changedPeaks: [],
	updateChangedPeaks: () => null,
	isAnalysisEditable: null,
	updateAnalysisEditable: () => null,
	allCompoundsAssigned: false,
	checkCompoundsHavePeak: () => null,
});
