import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { SlotComponentProps, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FC } from 'react';
import { FormikApiType } from '../FormikTextfield';
type TFormikDatePicker = {
	formik?: FormikApiType;
	name: string;
	disabled?: boolean;
	maxDate?: number | Date;
	minDate?: number | Date;
	format?: string;
	required?: boolean;
	showPickerIcon?: boolean;
};
const DatePickerTextInput = ({ textFieldProps, ...params }: { textFieldProps: TextFieldProps; params: TextFieldProps }) => {
	return (
		<TextField
			onKeyDown={(e) => {
				e.preventDefault();
			}}
			inputProps={{ 'data-testid': 'datepicker-test-id' }}
			disabled={textFieldProps.disabled}
			sx={{
				pointerEvents: textFieldProps.disabled ? 'none' : 'unset',
			}}
			{...params}
			{...textFieldProps}
			helperText={textFieldProps.helperText}
			error={textFieldProps.error}
		/>
	);
};
export const FormikDatePicker: FC<TFormikDatePicker & TextFieldProps> = ({
	formik,
	name,
	disabled,
	showPickerIcon = true,
	required,
	maxDate,
	minDate,
	format = 'dd/MM/yyyy',
	title,
	onChange,
	...textFieldProps
}) => {
	return (
		<Stack>
			{title ? (
				<Typography
					variant="label-s"
					className={required && !disabled ? 'required' : ''}
					color={disabled ? 'grey.200' : 'grey.800'}
					sx={{
						marginBottom: 1.25,
					}}
				>
					{title}
				</Typography>
			) : null}
			{formik && (
				<DatePicker
					value={formik?.values[`${name}`]}
					format={format}
					maxDate={maxDate}
					minDate={minDate}
					disabled={disabled}
					onChange={onChange}
					slots={{
						openPickerIcon: (props) =>
							showPickerIcon ? <CalendarMonthIcon sx={{ padding: 0.25 }} {...props}></CalendarMonthIcon> : null,
						textField: DatePickerTextInput,
					}}
					slotProps={{
						textField: {
							textFieldProps: textFieldProps,
						} as SlotComponentProps<typeof TextField, {}, Record<string, any>>,
					}}
				/>
			)}
		</Stack>
	);
};
