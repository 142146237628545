import { concatMap, map, tap } from 'rxjs';
import { httpService, logger, IAccessoryRequestType } from '@services/core';
import { irConfig } from '../irConfig';
import { RxUtils, TypeUtils } from '@utils';
import { IrAccessoryHealthCheckRequestModel, IrAccessoryRequestModel } from './ir-accessory-request-response.model';
import { IrAccessory } from './ir-accessory.class';
import { IrAccessoryCalibration, IrAccessoryCalibrationForList } from './ir-accessory-calibration.class';
import { PagedResult } from '@models/request-response';

class IrAccessoryService {
	private getURL() {
		return `${irConfig.url}/Accessory`;
	}

	public getAllAccessory(id: number, req: Partial<IAccessoryRequestType>) {
		const params = new URLSearchParams();

		Object.entries(req).forEach(([k, v]) => params.append(k, v.toString()));
		return this.getAllAccessoryWithParams(id, params);
	}

	public getAllAccessoryWithParams(deviceId: number, params: URLSearchParams) {
		return httpService
			.get<PagedResult<IrAccessory[]>>(this.getURL(), '${deviceId}', { params: params, errorHandler: 'notification' })
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllAccessory.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrAccessory>(IrAccessory), result)),
			);
	}

	public getAllAccessoryCalibrations(params: URLSearchParams) {
		return httpService
			.get<PagedResult<IrAccessoryCalibrationForList[]>>(this.getURL(), '', { params: params, errorHandler: 'notification' })
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllAccessoryCalibrations.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) =>
					TypeUtils.transformFromExist(new PagedResult<IrAccessoryCalibrationForList>(IrAccessoryCalibrationForList), result),
				),
			);
	}

	public getAccessory(id: number, disableLoader?: boolean) {
		return httpService.get<IrAccessory>(this.getURL(), `${id}`, { errorHandler: 'notification', disableLoader: !!disableLoader }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getAccessory.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrAccessory, result)),
		);
	}
	public getAccessories(id: number, type: string, disableLoader?: boolean) {
		return httpService
			.get<IrAccessory[]>(this.getURL(), `?deviceId=${id}&type=${type}`, {
				errorHandler: 'notification',
				disableLoader: !!disableLoader,
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAccessories.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => result),
			);
	}

	public getRestoreAccessory(id: number, disableLoader?: boolean) {
		return httpService.put<IrAccessory>(this.getURL(), `${id}`, { errorHandler: 'notification', disableLoader: !!disableLoader }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getRestoreAccessory.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => result),
		);
	}

	public createAccessory(body: IrAccessoryRequestModel) {
		const formData = new FormData();
		formData.append('name', body.name);
		formData.append('type', body.accessoryType);
		body.deviceId && formData.append('deviceId', `${body.deviceId}`);

		return httpService.post<IrAccessory>(this.getURL(), '', { body: formData, disableLoader: true, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.createAccessory.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	public calibrate(body: IrAccessoryRequestModel, accessoryId: number) {
		const formData = new FormData();
		formData.append('name', body.name);
		body.accessory && formData.append('accessory', body.accessory);
		body.accessoryType && formData.append('accessoryType', body.accessoryType);
		body.calibrationId && formData.append('calibrationId', `${body.calibrationId}`);

		body.files.forEach((file, index) => {
			formData.append(`file${index + 1}.file`, file.file);
			formData.append(`file${index + 1}.receiverGain`, `${file.receiverGain}`);
		});
		return httpService
			.post<IrAccessory>(this.getURL(), `${accessoryId}/calibrations`, {
				body: formData,
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.calibrate.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(IrAccessoryCalibration, result)),
			);
	}

	public calibrationCheck(accessoryId: number, body: IrAccessoryHealthCheckRequestModel) {
		const formData = new FormData();
		formData.append('calibrationId', ` ${body.calibrationId}`);
		body.accessory && formData.append('accessory', body.accessory);
		body.accessoryType && formData.append('accessoryType', body.accessoryType);
		formData.append('file.file', body.file.file);
		formData.append('file.receiverGain', `${body.file.receiverGain}`);

		return httpService
			.post<boolean>(this.getURL(), `${accessoryId}/calibrations/${body.calibrationId}/check`, {
				body: formData,
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.calibrationCheck.name}]`, result)));
	}

	public addAccessory(accessoryName: string, accessoryType: number, deviceId: number, neverExpires: boolean, validityDuration: number) {
		return httpService
			.post<IrAccessory>(this.getURL(), '', {
				body: {
					accessoryName: accessoryName,
					accessoryType: accessoryType,
					deviceId: deviceId,
					neverExpires: neverExpires,
					validityDuration: validityDuration,
				},
				disableLoader: true,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.addAccessory.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	public deleteAccessory(Id: number) {
		return httpService.delete<string>(this.getURL(), `${Id}`, { errorHandler: 'notification' });
	}

	public editAccessory(Id: number, name: string) {
		return httpService.patch(this.getURL(), `${Id}`, {
			body: { name: name },
			disableLoader: true,
			errorHandler: 'notification',
		});
	}

	public startCalibrate(accessoryId: number) {
		return httpService
			.post<IrAccessory>(this.getURL(), `${accessoryId}/calibration`, {
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.calibrate.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(IrAccessoryCalibration, result)),
			);
	}

	public startHealthCheck(accessoryId: number) {
		return httpService
			.post<IrAccessory>(this.getURL(), `${accessoryId}/healthcheck`, {
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.calibrate.name}]`, result)));
	}

	public uploadFile(Id: number, file: File, fileType: string, polyStyreneStandardId: string) {
		const formData = new FormData();
		formData.append('data', file as Blob);

		formData.append('filetype', fileType);
		formData.append('PolyStyreneStandardId', polyStyreneStandardId);

		return httpService
			.post<{ id: number; uri: string }>(this.getURL(), `calibration/${Id}/files`, {
				body: formData,
				disableLoader: true,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.uploadFile.name}]`, result)));
	}

	public uploadHealthCheckFile(Id: number, file: File, fileType: string, polyStyreneStandardId: string) {
		const formData = new FormData();
		formData.append('data', file as Blob);
		formData.append('filetype', fileType);
		formData.append('PolyStyreneStandardId', polyStyreneStandardId);
		return httpService
			.post<{ id: number; uri: string }>(this.getURL(), `healthcheck/${Id}/files`, {
				body: formData,
				disableLoader: true,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.uploadFile.name}]`, result)));
	}

	public calibrationComplete(id: number) {
		return httpService.get<IrAccessory>(this.getURL(), `calibration/${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.calibrationComplete.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => result),
		);
	}

	public chealthCheckComplete(id: number) {
		return httpService.get<IrAccessory>(this.getURL(), `healthcheck/${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.calibrationComplete.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => result),
		);
	}
}

export const irAccessoryService = new IrAccessoryService();
