import { Accept } from 'react-dropzone';

enum UploadableFileExtensions {
	DEVICE = 'Device',
	HEALTH_CHECK = 'HealthCheck',
	NMR_PIPELINE = 'NmrPipeline',
}

export const UploadableFileExtensionMapping: Record<UploadableFileExtensions, Accept> = {
	[UploadableFileExtensions.DEVICE]: {
		'chemical/x-jcamp-dx': ['.jdx', '.dx'],
	},
	[UploadableFileExtensions.HEALTH_CHECK]: {
		'chemical/x-jcamp-dx': ['.jdx', '.dx'],
	},
	[UploadableFileExtensions.NMR_PIPELINE]: {
		'text/csv': ['.csv'],
	},
};
