import { DeviceManagementTabs, PortalPageRoutes } from '@models';
import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { ROUTES } from '@routes/routes';
import { DataTestId, mainTitleSpacer, Tr } from '@utils';
import { FC, useEffect, useState } from 'react';
import { matchRoutes, Outlet, useLocation, useNavigate } from 'react-router-dom';

export const IrDeviceManagementTabView: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedRoute, setSelectedRoute] = useState<string>();

	useEffect(() => {
		const matches = matchRoutes(ROUTES, location);
		const route = matches?.slice(-1)?.[0].route.path?.replace('/:id', '');
		const parentRoute = `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE_MANAGEMENT}`;
		if (route === parentRoute) {
			setSelectedRoute(DeviceManagementTabs.ACTIVE_DEVICES);
			navigate(`./${DeviceManagementTabs.ACTIVE_DEVICES}`);
		} else {
			setSelectedRoute(route);
		}
	}, [location]);

	const addNewDevice = () => {
		navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ADD_DEVICE}`);
	};

	return (
		<Stack direction="column" data-testid={DataTestId.getStaticTestId('device-management-wrapper-id')}>
			<Stack direction="row" alignItems="start" justifyContent="space-between">
				<Typography variant="h1" sx={{ color: 'text.secondary', marginBottom: mainTitleSpacer }}>
					<Tr.IrPortal path="device-management.title" />
				</Typography>

				<Button
					variant="contained"
					data-testid={DataTestId.getStaticTestId('device-management-add-btn-id')}
					size="medium"
					disableElevation
					onClick={addNewDevice}
				>
					<Tr.IrPortal path="device-management.add-device" />
				</Button>
			</Stack>

			<Tabs
				textColor="inherit"
				value={selectedRoute || DeviceManagementTabs.ACTIVE_DEVICES}
				onChange={(_, newValue) => {
					navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE_MANAGEMENT}/${newValue}`);
				}}
				sx={{ marginBottom: mainTitleSpacer }}
			>
				<Tab value={DeviceManagementTabs.ACTIVE_DEVICES} label={<Tr.IrPortal path="device-management.active-devices" />} />
				<Tab
					value={DeviceManagementTabs.ARCHIVED_DEVICES}
					label={<Tr.IrPortal path="device-management.archived-devices" />}
					data-testid="archived-devices-tab-id"
				/>

				<Tab
					value={DeviceManagementTabs.INACTIVE_DEVICES}
					label={<Tr.IrPortal path="device-management.inactive-devices" />}
					data-testid="inactive-devices-tab-id"
				/>
			</Tabs>

			<Outlet />
		</Stack>
	);
};
