import { loadingSpinnerOverlayService } from '@services';
import { UsefulLinkUtils } from '@utils/Link';
import { FC, useEffect } from 'react';

export const NmrPhysicalReferenceMaterial: FC = () => {
	useEffect(() => {
		loadingSpinnerOverlayService.increment();
		return () => loadingSpinnerOverlayService.reset();
	}, []);
	return (
		<iframe
			data-testid="prm-iframe-test-id"
			onLoad={() => loadingSpinnerOverlayService.reset()}
			src={UsefulLinkUtils.WEB_SHOP_LINK}
			style={{ flexGrow: 1, border: 'none' }}
		></iframe>
	);
};
