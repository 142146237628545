import { FC, MouseEvent, ReactNode, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import { Stack, alpha } from '@mui/material';
import { CUSTOM_THEME } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';

export interface SelectMenuButtonMapperProps {
	openMenu: (event: MouseEvent<HTMLElement>) => void;
}

interface ISelectMenuProps {
	items: readonly string[];
	menuItemProps?: (MenuItemProps | undefined)[] | undefined;
	selectedItem?: string;
	onSelect: (item: string) => void;
	getButton: (handleClick: (event: MouseEvent<HTMLElement>) => void) => ReactNode;
	disabled?: boolean;
	selectable?: boolean;
}

export const SelectMenu: FC<ISelectMenuProps> = ({
	items,
	selectedItem,
	getButton,
	onSelect,
	disabled,
	selectable = true,
	menuItemProps,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleClick = (event: MouseEvent<HTMLElement>) => (event.stopPropagation(), setAnchorEl(event.currentTarget));
	const handleClose = (event: MouseEvent<HTMLElement>) => (event.stopPropagation(), setAnchorEl(null));

	const onSelectedItem = (selected: string, event: MouseEvent<HTMLElement>) => {
		onSelect(selected);
		handleClose(event);
	};

	return (
		<>
			{getButton(handleClick)}
			<Menu
				anchorEl={anchorEl}
				elevation={0}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={!!anchorEl && !disabled}
				onClose={handleClose}
				sx={{
					'& .MuiPaper-root': {
						minWidth: 180,
						borderRadius: '4px 4px 0px 0px',
						boxShadow:
							'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
						'& .MuiMenu-list': {
							padding: '0',
						},
						'& .MuiDivider-root': { margin: 0, height: '1px' },
						'& .MuiMenuItem-root': {
							paddingY: 1.5,
							'& .MuiSvgIcon-root': {
								fontSize: 18,
								color: '#01884C',
								marginRight: CUSTOM_THEME.spacing(1.5),
							},
							'&:active': {
								backgroundColor: alpha(CUSTOM_THEME.palette.primary.main, CUSTOM_THEME.palette.action.selectedOpacity),
							},
						},
					},
				}}
			>
				{items.map((item, index) => (
					<div key={index}>
						<MenuItem
							data-testid={DataTestId.getStaticTestId(`menu-item-${index}`)}
							onClick={(event) => onSelectedItem(item, event)}
							disableRipple
							{...menuItemProps?.[`${index}`]}
						>
							{selectable && (
								<Stack width={25} height={18}>
									{item === selectedItem && <CheckIcon />}
								</Stack>
							)}

							<Tr.Common path={`select-menu-item.${item.toLowerCase()}`} />
						</MenuItem>
						<Divider />
					</div>
				))}
			</Menu>
		</>
	);
};
