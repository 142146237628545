import { FC, useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { DataTestId } from '@utils/DataTestId';
import { FormikAutoComplete, FormikTextfield, GenericCard, ModalDialog } from '@components/common';
import { SelectDropDown } from '@components/common/Filter/SelectDropdown';
import { getRejectionReasonOptions, getRejectionSeverityOptions } from '@components/admin/NmrPipelineBacklog/nmr-pipeline-helper';
import { getRejectConfirmationSchema } from '@schemas';

type TRejectDrmOrderProps = {
	visible: boolean;
	onConfirm: (rejectionReasons: string[], reason: string, details: string) => void;
	onCancel: () => void;
};

type FormFields = Extendable<{
	rejectionReasons: string[];
	severity: string;
	details: string;
}>;

export const RejectDrmOrder: FC<TRejectDrmOrderProps> = ({ visible, onConfirm, onCancel }) => {
	const { t } = useTranslation('common');
	const { t: tAdmin } = useTranslation('admin');

	const formik = useFormik<FormFields>({
		initialValues: {
			rejectionReasons: [],
			severity: '',
			details: '',
		},
		validationSchema: getRejectConfirmationSchema(t),
		onSubmit: ({ rejectionReasons, severity, details }) => {
			onConfirm(rejectionReasons, severity, details);
		},
	});

	useEffect(() => {
		formik.resetForm();
		formik.validateForm();
	}, [visible]);

	return (
		<ModalDialog
			variant="secondary"
			onClose={onCancel}
			title={<Tr.Common path="confirmation" />}
			titleProps={{ sx: { paddingBottom: 0 } }}
			onBackdropClick={onCancel}
			open={visible}
			data-testid={DataTestId.getStaticTestId('reject-drm-order-dialog')}
			sx={{
				'& .MuiDialog-container': {
					'& .MuiPaper-root': {
						width: '100%',
						maxWidth: '472px',
					},
				},
			}}
			PaperProps={{
				sx: {
					minWidth: '472px',
					maxWidth: '472px',
					maxHeight: '800px',
					minHeight: '466px',
				},
			}}
		>
			<GenericCard sx={{ overflow: 'auto', border: 'none', paddingTop: 0, paddingX: 8, paddingBottom: 6 }}>
				<form onSubmit={formik?.handleSubmit}>
					<Stack
						spacing={3}
						textAlign="center"
						sx={{ mt: 2 }}
						data-testid={DataTestId.getStaticTestId('reject-drm-order-popup-wrapper')}
					>
						<Typography variant="h3">{tAdmin('drm-order-details.reject-drm-order.title')}</Typography>
						<Typography>{tAdmin('drm-order-details.reject-drm-order.subtitle')}</Typography>
						<Stack spacing={4}>
							<Stack>
								<SelectDropDown
									key={'reject'}
									elementTitle={tAdmin('drm-order-details.reject-drm-order.reason-title')}
									filterOption={false}
									required
									options={getRejectionReasonOptions(tAdmin)}
									selectedValues={formik.values.rejectionReasons || []}
									inputProps={{ 'aria-label': 'Without label' }}
									typographyProps={{ sx: { textAlign: 'left', marginBottom: 1.25 } }}
									displayEmpty
									sx={{ textAlign: 'left', marginBottom: 0 }}
									MenuProps={{ sx: { maxHeight: '50%' } }}
									renderValue={(renderedValues) => {
										if ((!renderedValues && renderedValues !== 0) || (renderedValues as string[]).length === 0) {
											return (
												<Typography variant="label-m">
													{tAdmin('drm-order-details.reject-drm-order.reason-placeholder')}
												</Typography>
											);
										}

										return formik.values.rejectionReasons
											?.map((s: string) => tAdmin(`drm-order-details.reject-drm-order.reason.${s}`))
											.join(', ');
									}}
									onChange={(e) => {
										formik.setFieldValue('rejectionReasons', e.target.value);
									}}
									escapesTranslation={true}
								/>
							</Stack>

							<Stack>
								<FormikAutoComplete
									formik={formik}
									title={tAdmin('drm-order-details.reject-drm-order.severity-title')}
									onSelectionChange={(selection) => {
										formik?.setFieldValue('severity', selection?.value || '');
									}}
									select={false}
									options={getRejectionSeverityOptions()}
									name="severity"
									placeholder={tAdmin('drm-order-details.reject-drm-order.severity-placeholder')}
									variant="outlined"
									typographyProps={{ sx: { textAlign: 'left' } }}
									autocompleteProps={{
										disablePortal: true,
									}}
									required
								/>
							</Stack>

							<Stack>
								<FormikTextfield
									formikApi={formik}
									name="details"
									multiline
									title={tAdmin('drm-order-details.reject-drm-order.details-title')}
									hiddenLabel
									rows={4}
									placeholder={tAdmin('drm-order-details.reject-drm-order.details-placeholder')}
									variant="outlined"
									sx={{ backgroundColor: 'grey.50', padding: 0 }}
									focused={false}
									typographyProps={{ sx: { textAlign: 'left' } }}
									InputProps={{
										sx: {
											'& 	.MuiInputBase-input': {
												fontSize: 15,
												padding: 0,
											},
										},
									}}
									inputProps={{ maxLength: 4000, 'data-testid': 'reject-drm-order-details' }}
									required
								/>
							</Stack>
						</Stack>

						<Stack direction="row" width={1} columnGap={3}>
							<Button
								data-testid={DataTestId.getStaticTestId('reject-drm-order-cancel-button')}
								variant="outlined"
								sx={{ width: 1 }}
								onClick={onCancel}
							>
								{<Tr.Common path="cancel" />}
							</Button>
							<Button
								data-testid={DataTestId.getStaticTestId('reject-drm-order-reject-button')}
								variant="contained"
								sx={{ width: 1 }}
								disabled={!formik.isValid}
								type="submit"
							>
								{<Tr.Common path="confirm" />}
							</Button>
						</Stack>
					</Stack>
				</form>
			</GenericCard>
		</ModalDialog>
	);
};
