import { FormikTextfield, ModalDialog } from '@components/common';
import { Button, Stack, Typography } from '@mui/material';
import { getAddToFavoritesValidationSchema } from '@schemas';
import { favoriteReferencesService, notificationService } from '@services';
import { formFieldSpacer, RxUtils, Tr } from '@utils';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type FormFields = Extendable<{ name: string }>;
export interface IEditFavoriteDialogProps {
	favoriteId: number;
	favoriteName: string;
	open: boolean;
	onCloseDialog: () => void;
	onSuccess: () => void;
}

export const EditFavoriteDialog: FC<IEditFavoriteDialogProps> = ({ favoriteId, favoriteName, open, onCloseDialog, onSuccess }) => {
	const { t } = useTranslation('portal');
	const formik = useFormik<FormFields>({
		initialValues: { name: favoriteName },
		validationSchema: getAddToFavoritesValidationSchema(t),
		onSubmit: ({ name }) => {
			RxUtils.promisify(favoriteReferencesService.update(favoriteId, name), () => {
				notificationService.sendSuccess(t('favourites.successfully-saved'));
				onSuccess();
			});
		},
	});
	return (
		<>
			<ModalDialog
				variant="secondary"
				open={open && !!favoriteId}
				title={
					<Typography variant="pg-s">
						<Tr.Portal path="favourites.edit-title" />
					</Typography>
				}
				onClose={onCloseDialog}
				maxWidth="xs"
				contentProps={{ sx: { paddingX: 8 } }}
				data-testid="favorite-model-id"
			>
				<form onSubmit={formik.handleSubmit}>
					<Stack direction="column">
						<Stack sx={{ marginTop: 3, marginBottom: 4 }}>
							<Typography variant="h3" textAlign="center">
								<Tr.Portal path="favourites.edit" />
							</Typography>
						</Stack>

						<FormikTextfield
							name="name"
							title={t('favourites.favorite-name-title')}
							formikApi={formik}
							placeholder={t('favourites.edit-favorite-name-placeholder')}
							sx={{
								mb: formFieldSpacer,
							}}
						/>

						<Stack direction="row" spacing={1.5} mb={7}>
							<Button
								data-testid="on-close-dialog-button"
								variant="outlined"
								disableElevation
								fullWidth
								onClick={onCloseDialog}
							>
								<Tr.Portal path="erm-request.cancel" />
							</Button>
							<Button
								type="submit"
								variant="contained"
								data-testid="update-favorite-id"
								disableElevation
								fullWidth
								disabled={!formik.dirty || !formik.isValid}
							>
								<Tr.Portal path="favourites.save" />
							</Button>
						</Stack>
					</Stack>
				</form>
			</ModalDialog>
		</>
	);
};
