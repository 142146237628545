import {
	addHours,
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInMonths,
	differenceInYears,
	format,
	isValid,
	parse,
} from 'date-fns';

import { useTranslation } from 'react-i18next';

export class DateUtils {
	static getFormattedDate = (date: Date, fmt = 'dd/MM/yy', invalid = '') => (isValid(date) ? format(date, fmt) : invalid);
	static getDateFromString = (dateString: string, fmt: string) => parse(dateString, fmt, new Date());
	static checkDateStringValidity = (date: Maybe<string>, fmt: string) => (date ? isValid(this.getDateFromString(date, fmt)) : false);

	static getMilliseconds = (timestamp: string) => {
		if (+timestamp) {
			const date = new Date(timestamp).getTime();
			if (Math.abs(Date.now() - date) < Math.abs(Date.now() - date * 1000)) {
				return +timestamp;
			} else {
				return date * 1000;
			}
		}

		return timestamp;
	};

	static getTimeDifferences = (date: Date) => {
		const { t } = useTranslation('common');

		if (differenceInYears(Date.now(), date)) return `${differenceInYears(Date.now(), date)} ${t('year-ago')}`;
		if (differenceInMonths(Date.now(), date)) return `${differenceInMonths(Date.now(), date)} ${t('month-ago')}`;
		if (differenceInDays(Date.now(), date)) return `${differenceInDays(Date.now(), date)} ${t('day-ago')}`;
		if (differenceInHours(Date.now(), date)) return `${differenceInHours(Date.now(), date)} ${t('hour-ago')}`;
		if (differenceInMinutes(Date.now(), date)) return `${differenceInMinutes(Date.now(), date)} ${t('min-ago')}`;

		return t('just-now');
	};

	static isValidDate = (date: Date) => date && date.getTime() <= new Date().getTime();

	static getZonedToUtcTime = (date: Date | string) => addHours(new Date(date), new Date(date).getTimezoneOffset() / 60);

	static changeTimezone(date: Date | undefined, ianatz: string) {
		if (!date) {
			return date ?? new Date(Date.now());
		}
		const invdate = new Date(
			date.toLocaleString('en-US', {
				timeZone: ianatz,
			}),
		);
		return invdate;
	}

	static setFullDay = (date: Date | undefined) => date?.setUTCHours(23, 59, 59);
}
