import { AnalysisType } from '@models/analysis';
import { CombinedPeakFeatures, IrPurityCheckPeakjson, IrReferenceMaterialResult, RankBasedMixtureData } from '@services/ir';

export function getResultData(analysisType, matchedResult, rmData) {
	const rankMixtureAry: RankBasedMixtureData[] = [];
	for (let i = 0; i < matchedResult.length; i++) {
		const matchVar = matchedResult[`${i}`];
		let combined_features_info: CombinedPeakFeatures = { peak_wavenumbers: [], peak_heights: [] };
		let combined_spectrum_y = [];
		let spectrum_x: number[] = [];
		let matchFac = 0;
		let libName = '';
		const mixAry: IrReferenceMaterialResult[] = [];

		//only for Identification analysis
		const selectedRanked = matchVar['rank'];
		let table_of_assignment: IrPurityCheckPeakjson = {};

		for (let j = 0; j < matchVar['irAnalysisResultMixtures'].length; j++) {
			const mixture = matchVar['irAnalysisResultMixtures'][`${j}`];

			//TODO double check stale code ?
			if (mixture?.qualityGrade !== null && rmData) {
				if (
					rmData !== undefined &&
					rmData['match_results']?.['rank_' + (i + 1)]?.['mixture_' + mixture.position]?.['drmCode'] ===
						mixture?.['referenceMaterialId']
				) {
					mixture.scaled_spectrum_y =
						rmData['match_results']?.['rank_' + (i + 1)]?.['mixture_' + mixture.position]?.['scaled_spectrum_y'];
					const xAry: number[] = [];
					for (
						let k = rmData['match_results']?.['rank_' + (i + 1)]?.['spectrum_x'].start;
						k >= rmData['match_results']?.['rank_' + (i + 1)]?.['spectrum_x'].stop;
						k--
					) {
						xAry.push(k);
					}
					spectrum_x = xAry;
					combined_spectrum_y = rmData['match_results']?.['rank_' + (i + 1)]?.['combined_spectrum_y'];
					combined_features_info = rmData['match_results']?.['rank_' + (i + 1)]?.['combined_spectrum_peak_features'];
					matchFac = mixture.matchFactor;

					if (analysisType === AnalysisType.IDENTIFICATION) {
						table_of_assignment = rmData['match_results']?.['rank_' + (i + 1)]?.['table_of_assignment'];
					}

					libName = mixture.library;
				} else {
					mixture.scaled_spectrum_y =
						rmData['match_results']?.['rank_' + selectedRanked]?.['mixture_' + mixture.position]?.['scaled_spectrum_y'];
					const xAry: number[] = [];
					for (
						let k = rmData['match_results']?.['rank_' + selectedRanked]?.['spectrum_x'].start;
						k >= rmData['match_results']?.['rank_' + selectedRanked]?.['spectrum_x'].stop;
						k--
					) {
						xAry.push(k);
					}
					spectrum_x = xAry;
					combined_spectrum_y = rmData['match_results']?.['rank_' + selectedRanked]?.['combined_spectrum_y'];
					combined_features_info = rmData['match_results']?.['rank_' + selectedRanked]?.['combined_spectrum_peak_features'];
					table_of_assignment = rmData['match_results']?.['rank_' + (i + 1)]?.['table_of_assignment'];
					matchFac = mixture.matchFactor;

					libName = mixture.library;
				}
				mixAry.push(mixture);
			}
		}

		if (analysisType !== AnalysisType.IDENTIFICATION) {
			rankMixtureAry.push({
				rank: matchVar?.['rank'],
				matchFactor: matchFac,
				selectedResult: matchVar?.['selectedResult'],
				drmCode: matchVar?.drmCode,
				library: libName,
				mixtures: mixAry,
				combined_spectrum_peak_features: combined_features_info,
				combined_spectrum_y: combined_spectrum_y,
				spectrum_x: spectrum_x,
				x_range: [spectrum_x[0], spectrum_x[spectrum_x.length - 1]],
			});
		}

		if (analysisType === AnalysisType.IDENTIFICATION) {
			rankMixtureAry.push({
				rank: matchedResult[`${i}`]?.['mixture'],
				matchFactor: matchFac,
				selectedResult: matchedResult[`${i}`]?.['selectedResult'],
				drmCode: matchVar.drmCode,
				library: libName,
				mixtures: mixAry,
				combined_spectrum_peak_features: combined_features_info,
				combined_spectrum_y: combined_spectrum_y,
				spectrum_x: spectrum_x,
				x_range: [spectrum_x[0], spectrum_x[spectrum_x.length - 1]],
				table_of_assignment: table_of_assignment,
			});
		}
	}

	const filteredArray = rankMixtureAry.filter(
		(item) => item.mixtures.filter((mixture) => mixture.qualityGrade !== null).length > 0 && !!item.rank,
	);
	return filteredArray;
}
