import { FC, ReactNode, MouseEvent } from 'react';
import {
	Box,
	Dialog,
	DialogContent,
	DialogContentProps,
	DialogProps,
	DialogTitle,
	DialogTitleProps,
	IconButton,
	Stack,
	SxProps,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type VariantType = 'primary' | 'secondary';
export interface IModalDialogProps extends Omit<DialogProps, 'title'> {
	title?: ReactNode;
	titleProps?: DialogTitleProps;
	hideCloseIcon?: boolean;
	contentProps?: Omit<DialogContentProps, 'dividers'>;
	dividers?: boolean;
	closeIconClick?: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
	titleTypographyProps?: SxProps;
	iconButtonProps?: SxProps;
	iconProps?: SxProps;
	variant?: VariantType;
	actionButtons?: ReactNode;
	titleSx?: SxProps;
	subtitle?: ReactNode;
	width?: number;
	height?: number;
	tag?: string;
	tagSx?: SxProps;
	dialogSx?: SxProps;
}

const VariantTypeMapping: Record<
	VariantType,
	(
		actionButtons,
		onClose,
		title,
		titleSx,
		titleProps,
		hideCloseIcon,
		tag,
		tagSx,
		closeIconClick,
		iconProps,
		iconButtonProps,
		subtitle,
	) => ReactNode
> = {
	['primary']: (
		actionButtons,
		onClose,
		title,
		titleSx,
		titleProps,
		hideCloseIcon,
		tag,
		tagSx,
		closeIconClick,
		iconProps,
		iconButtonProps,
	) => (
		<>
			{title || !hideCloseIcon ? (
				<>
					<DialogTitle
						sx={{
							minHeight: 70,
							display: 'flex',
							position: 'relative',
							justifyContent: 'start',
							alignItems: 'center',
							paddingLeft: 3,
							paddingTop: 2,
							marginTop: 2,
							...titleSx,
						}}
						{...titleProps}
					>
						<span
							style={{
								maxWidth: 'calc(100% - 90px)',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
						>
							<Typography variant="h3">{title}</Typography>
						</span>
						{tag && (
							<Box
								height={24}
								width={60}
								sx={{
									backgroundColor: 'grey.100',
									display: 'flex',
									borderRadius: '4px',
									justifyContent: 'center',
									...tagSx,
								}}
							>
								<Typography padding="3px" variant="h6" color={'grey.500'}>
									{tag}
								</Typography>
							</Box>
						)}
						{!hideCloseIcon && (
							<IconButton
								data-testid="modal-close-id"
								color="inherit"
								sx={{
									position: 'absolute',
									right: '16px',
									top: 2,

									...iconButtonProps,
								}}
								onClick={(e) => (closeIconClick ? closeIconClick(e) : onClose?.(e, 'escapeKeyDown'))}
							>
								<CloseIcon sx={{ width: 24, height: 24, ...iconProps }} />
							</IconButton>
						)}
					</DialogTitle>
					{actionButtons && <Stack px={13.5}>{actionButtons}</Stack>}
				</>
			) : null}
		</>
	),

	['secondary']: (
		actionButtons,
		onClose,
		title,
		titleSx,
		titleProps,
		hideCloseIcon,
		tag,
		tagSx,
		closeIconClick,
		iconProps,
		iconButtonProps,
	) => (
		<>
			{title || !hideCloseIcon ? (
				<>
					<DialogTitle
						sx={{
							display: 'flex',
							position: 'relative',
							justifyContent: 'flex-start',
							alignItems: 'center',
							paddingY: 0,
							height: '40px',
							marginTop: 0,
							minHeight: 0,
							backgroundColor: 'grey.50',
							paddingLeft: '16px',
							...titleSx,
						}}
						{...titleProps}
					>
						<span
							style={{
								maxWidth: 'calc(100% - 90px)',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
						>
							{title}
						</span>

						{tag && (
							<Box
								height={24}
								width={60}
								sx={{
									backgroundColor: 'grey.100',
									display: 'flex',
									borderRadius: '4px',
									justifyContent: 'center',
									...tagSx,
								}}
							>
								<Typography variant="h6" color={'grey.500'}>
									{tag}
								</Typography>
							</Box>
						)}

						{!hideCloseIcon && (
							<IconButton
								data-testid="modal-close-id"
								color="inherit"
								sx={{ position: 'absolute', top: '50%', right: 16, transform: 'translateY(-50%)', ...iconButtonProps }}
								onClick={(e) => (closeIconClick ? closeIconClick(e) : onClose?.(e, 'escapeKeyDown'))}
							>
								<CloseIcon sx={{ width: 20, height: 20, ...iconProps }} />
							</IconButton>
						)}
					</DialogTitle>
					{actionButtons && <Stack px={13.5}>{actionButtons}</Stack>}
				</>
			) : null}
		</>
	),
};
export const ModalDialog: FC<IModalDialogProps> = ({
	children,
	title,
	titleProps: { sx: titleSx, ...titleProps } = {},
	hideCloseIcon,
	contentProps: { sx: contentSx, ...contentProps } = {},
	dividers,
	closeIconClick,
	iconButtonProps,
	iconProps,
	fullWidth = true,
	variant = 'primary',
	actionButtons,
	subtitle,
	width,
	height,
	tag,
	tagSx,
	dialogSx,
	...props
}) => {
	const { onClose } = props;

	return (
		<Dialog
			fullWidth={fullWidth}
			maxWidth={false}
			sx={{ marginTop: 2, ...dialogSx }}
			PaperProps={{
				sx: {
					maxWidth: width,
					maxHeight: height,
					minHeight: height,
				},
			}}
			{...props}
		>
			{VariantTypeMapping[`${variant}`](
				actionButtons,
				onClose,
				title,
				titleSx,
				titleProps,
				hideCloseIcon,
				tag,
				tagSx,
				closeIconClick,
				iconProps,
				iconButtonProps,
				subtitle,
			)}

			<DialogContent sx={{ p: 0, ...contentSx }} dividers={dividers} {...contentProps}>
				{subtitle && (
					<Stack>
						<Stack sx={{ paddingY: 3 }} justifyContent="center" alignItems="center">
							<Typography variant="h3">{subtitle}</Typography>
						</Stack>
					</Stack>
				)}

				{children}
			</DialogContent>
		</Dialog>
	);
};
