import { FC } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mainTitleSpacer } from '@utils/Theme';
import { useTranslation } from 'react-i18next';
import { DataTestId } from '@utils/DataTestId';
import { DashboardCard } from '@components/admin';
import { PortalPageRoutes } from '@models/router';
import { userSelector } from '@store/slices/common/common.slice';
import { useHasPermissions } from '@hooks';
import DRMManagementPNG from '@assets/images/drm_management.png';
import { LabPermission, OrganizationPermission, UserPermission } from '@models/user';
import AccessManagementPNG from '@assets/images/access_management.png';
import { PortalEditOrganizationTabs } from '@models/organization/edit-organization-tabs.enum';

export const IrOrganizationManagement: FC = () => {
	const navigate = useNavigate();
	const user = useSelector(userSelector);
	const { t, ready } = useTranslation('portal');

	const translate = (path: string) => (ready ? t(path) : '');

	const cards = [
		{
			key: 'organization-settings',
			permission: OrganizationPermission.VIEW_DETAILS,
			component: (
				<DashboardCard
					img={AccessManagementPNG}
					title={translate('manage-organization.organization-settings')}
					dataTestId={DataTestId.getStaticTestId('manage-org-card-test-id')}
					description={translate('manage-organization.organization-settings-desc')}
					onClick={() =>
						navigate(
							`./${PortalPageRoutes.ORGANIZATION_SETTINGS}/${user?.organizationId}/${PortalEditOrganizationTabs.OVERVIEW}`,
						)
					}
				/>
			),
		},
		{
			key: 'device-management',
			permission: LabPermission.DEVICE,
			component: (
				<DashboardCard
					img={DRMManagementPNG}
					title={translate('manage-organization.device-management')}
					description={translate('manage-organization.device-settings-desc')}
					onClick={() => navigate(`./${PortalPageRoutes.DEVICE_MANAGEMENT}`)}
					dataTestId={DataTestId.getStaticTestId('manage-device-card-test-id')}
				/>
			),
		},
		{
			key: 'user-references',
			permission: UserPermission.USER_REFERENCE_CREATE,
			component: (
				<DashboardCard
					img={DRMManagementPNG}
					title={translate('manage-organization.user-references')}
					description={translate('manage-organization.user-references-desc')}
					onClick={() => navigate(`./${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`)}
					dataTestId={DataTestId.getStaticTestId('user-references-card-test-id')}
				/>
			),
		},
	];

	const filteredCards = cards.filter(({ permission }) => useHasPermissions(permission));

	return (
		<Grid container spacing={4} paddingTop={mainTitleSpacer} data-testid={DataTestId.getStaticTestId('organization-management-id')}>
			{filteredCards.map(({ component, key }) => (
				<Grid key={key} item xs={6} md={4} xl={3}>
					{component}
				</Grid>
			))}
		</Grid>
	);
};
