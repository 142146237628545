import { TypeUtils } from '@utils/Type';
import { QualityGradeBorderColorMapping } from '@models/reference-material';

export const getReferenceBorderColor = (qualityGradeValue: string | undefined, deactivateBorderColors: boolean): string => {
	if (deactivateBorderColors) {
		return 'grey.800';
	}

	const borderColor = TypeUtils.returnValueOfKey<typeof QualityGradeBorderColorMapping, string>(
		qualityGradeValue,
		QualityGradeBorderColorMapping,
	);
	return borderColor ?? 'primary.main';
};
