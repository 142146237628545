import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterSearchParams, useService, getParams } from '@hooks';
import { SortOrder } from '@models';
import { irDeviceService } from '@services';
import { inactiveDeviceColumns } from '@components/ir-portal';
import { DataTableBackend } from '@components/common/DataTableBackend';

export const IrInactiveDeviceTable: FC = () => {
	const { filtering, setFiltering, searchParams } = useFilterSearchParams('createTime', SortOrder.DESC);
	const [typedString, setTypedString] = useState('');

	const { t: tPortal, ready } = useTranslation('irportal');
	const translate = (path: string) => (ready ? tPortal(path) : '');

	const { data: deviceData, loading } = useService(() => {
		searchParams.set('deviceKey', 'withoutaccessory');
		setTypedString(getParams(searchParams).getQuery());
		return irDeviceService.getAllDevices(searchParams);
	}, [searchParams]);

	return (
		<>
			<DataTableBackend
				data={deviceData ? deviceData.data : ([] as any)}
				columns={inactiveDeviceColumns()}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: deviceData?.totalPages ?? 1 }}
				searchText={typedString}
				searchPlaceholder={translate('device-management.irinactivedevice-search-placeholder')}
				onSearchTextChange={setTypedString}
				sx={{ visibility: loading ? 'hidden' : 'visible', marginX: 2 }}
				headerCellProps={{ sx: { minWidth: 60 } }}
				searchFieldProps={{ sx: { maxWidth: 600, width: 600 } }}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
		</>
	);
};
