import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { concatMap, map, tap } from 'rxjs';
import { httpService, logger, Filter } from '@services/core';
import { irConfig } from '../irConfig';

class IRFilterService {
	getURl() {
		return `${irConfig.url}`;
	}

	drmDatabaseFilter() {
		return httpService
			.get<Filter>(this.getURl(), `ReferenceMaterial/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.drmDatabaseFilter.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	public drmRequestFilter() {
		return httpService
			.get<Filter>(this.getURl(), `ReferenceMaterialRequest/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.drmRequestFilter.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	public getIrAnalysisHistoryFilter(completed = false) {
		return httpService
			.get<Filter>(this.getURl(), `Analysis/filterOptions`, { errorHandler: 'notification', params: { completed } })
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getIrAnalysisHistoryFilter.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	public getSubstanceFilterOptions() {
		return httpService.get<Filter>(this.getURl(), `Substance/filterOptions`).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getSubstanceFilterOptions.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Filter, result)),
		);
	}

	public getIrLibrariesFilterOptions() {
		return httpService.get<Filter>(this.getURl(), `Library/filterOptions`).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getSubstanceFilterOptions.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Filter, result)),
		);
	}
}

export const irFilterService = new IRFilterService();
