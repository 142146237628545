import { concatMap, tap, map } from 'rxjs/operators';
import { RxUtils, TypeUtils } from '@utils';
import { PagedResult } from '@models';
import { logger, httpService, AuditTrail, AuditTrailPagedRequest } from '@services';
import { coreConfig } from '../coreConfig';

export class AuditTrailService {
	private getUrl() {
		return `${coreConfig.url}/AuditLog/analysis`;
	}

	getAll({ pageIndex, pageSize, query, sortBy, sortOrder, entity, entityId }: Partial<AuditTrailPagedRequest>) {
		const params = new URLSearchParams();
		params.append('pageIndex', `${pageIndex || ''}`);
		params.append('pageSize', `${pageSize || ''}`);
		params.append('query', `${query || ''}`);
		params.append('sortBy', `${sortBy || ''}`);
		params.append('sortOrder', `${sortOrder || ''}`);
		params.append('entity', `${entity || ''}`);
		params.append('entityId', `${entityId || ''}`);

		return this.getAllWithParams(params);
	}

	getAllWithParams(params: URLSearchParams) {
		return httpService
			.get<PagedResult<AuditTrail[]>>(this.getUrl(), '', {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAll.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<AuditTrail>(AuditTrail), result)),
			);
	}

	getReport(params: URLSearchParams) {
		return httpService
			.get<any>(this.getUrl(), 'report', {
				params: params,
				errorHandler: 'notification',
				responseType: 'blob',
				headers: { 'Content-Type': 'application/pdf' },
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAll.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}
}

export const auditTrailService = new AuditTrailService();
