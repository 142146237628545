import { FC, useContext } from 'react';
import { Stack, SxProps, Typography, TypographyProps } from '@mui/material';
import { NmrAnalysisResult } from '@services';
import { useTranslation } from 'react-i18next';
import { AnalysisResultContext } from '@routes/Portal/nmr/NmrAnalysisResult';
import { AnalysisType } from '@models';
import { ExtendedComponent } from 'src/types';
import { TFunction } from 'i18next';
import { CompletedStatusIcon } from '@components/common';

const statusIconSx: SxProps = { width: 32, height: 32, borderRadius: '50%' };
const StatusLabel: ExtendedComponent<TypographyProps> = ({ children, sx }) => (
	<Typography data-testid="status-label-id" variant="h1" sx={{ color: 'success.main', marginLeft: 2, textAlign: 'center', ...sx }}>
		{children}
	</Typography>
);

const getSatisfiedMessage = (analysisResult: NmrAnalysisResult) => {
	if (analysisResult.nmrAnalysis.type === AnalysisType.VERIFICATION) return `verification`;
	if (analysisResult.nmrAnalysis.type === AnalysisType.IDENTIFICATION) return `identification`;
	return `quantification`;
};

const getAnalysisResultIcons = (analysisResultData: NmrAnalysisResult, t: TFunction<'portal', undefined>) => {
	if (analysisResultData.isError()) {
		return (
			<Stack data-testid="technical-error-id" width="30em" direction="row" alignItems="center">
				<CompletedStatusIcon.Remove sx={statusIconSx} />
				<StatusLabel sx={{ color: 'grey.400' }}>{t('analysis-result-label.technical-error')}</StatusLabel>
			</Stack>
		);
	}

	if (analysisResultData.isUnSatisfied()) {
		return (
			<Stack data-testid="unsatisfy-match-id" width="30em" direction="row" alignItems="center">
				<CompletedStatusIcon.Close sx={statusIconSx} />
				<StatusLabel sx={{ color: 'error.main' }}>
					{analysisResultData.isRejected()
						? t('analysis-result-label.analysis-rejected')
						: t('analysis-result-label.no-match-found')}
				</StatusLabel>
			</Stack>
		);
	}

	if (analysisResultData.isQuestionable()) {
		const matchFactor = analysisResultData.resultMatches[0].matchFactor;
		return (
			<Stack data-testid="questionable-match-id" width="30em" direction="row" alignItems="center">
				<CompletedStatusIcon.QuestionMark sx={statusIconSx} />
				<StatusLabel sx={{ color: 'secondary.main' }}>
					{matchFactor && Math.round(matchFactor <= 1 ? matchFactor * 100 : matchFactor) >= 75
						? t('analysis-result-label.questionable-match')
						: t('analysis-result-label.unsatisfactory-match')}
				</StatusLabel>
			</Stack>
		);
	}

	if (analysisResultData.isTheoreticalMatch()) {
		return (
			<Stack data-testid="satisfy-label-id" width="35em" direction="row" alignItems="center">
				<CompletedStatusIcon.OnlyTheoretical sx={statusIconSx} />
				<StatusLabel sx={{ color: 'info.700' }}>
					{t(`analysis-result-label.${getSatisfiedMessage(analysisResultData)}`)}
				</StatusLabel>
			</Stack>
		);
	}

	if (analysisResultData.isSatisfied()) {
		return (
			<Stack data-testid="satisfy-label-id" width="35em" direction="row" alignItems="center">
				<CompletedStatusIcon.Done sx={statusIconSx} />
				<StatusLabel sx={{ color: 'primary.main' }}>
					{t(`analysis-result-label.${getSatisfiedMessage(analysisResultData)}`)}
				</StatusLabel>
			</Stack>
		);
	}

	if (analysisResultData.isQuantitativeNonTargeted() && analysisResultData.isConfirmedByLabMember()) {
		return (
			<Stack direction="row" alignItems="center">
				<CompletedStatusIcon.Done sx={statusIconSx} />
				<StatusLabel sx={{ color: 'primary.main' }}>{t('analysis-result-label.your-compound-quantified')}</StatusLabel>
			</Stack>
		);
	}

	if (!analysisResultData.isCompleted())
		return (
			<StatusLabel sx={{ color: 'primary.main', textAlign: 'start' }}>
				{t('analysis-result-label.analysis-execution-completed')}
			</StatusLabel>
		);
};

export const NmrAnalysisResultLabel: FC = () => {
	const { t } = useTranslation('portal');
	const { analysisResultData } = useContext(AnalysisResultContext);
	return <Stack data-testid="analysis-result-label">{analysisResultData && getAnalysisResultIcons(analysisResultData, t)}</Stack>;
};
