export enum Challenges {
	NewPassRequired = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
	SMS_MFA = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
	SELECT_MFA_TYPE = 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
	DONE = 'DONE',
}

export const checkChallenges = (challenge: string) => {
	return {
		isNewPassRequired: () => challenge === Challenges.NewPassRequired,
		isSmsMfa: () => challenge === Challenges.SMS_MFA,
		isMFAType: () => challenge === Challenges.SELECT_MFA_TYPE,
		isDone: () => challenge === Challenges.DONE,
	};
};
