import { SelectMenu } from '@components/common';
import { FC, useState } from 'react';
import { IconButton, MenuItemProps } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { EditCalibrationNameDialog } from './EditCalibrationNameDialog';
import { NmrDeviceCalibration, nmrDeviceService } from '@services/nmr';
import { alertService } from '@services/core';
import { Tr } from '@utils/Translation';
import { RxUtils } from '@utils/Rx';
import { HealthCheckModalDialog } from './HealthCheckModal';
import { TypeUtils } from '@utils/Type';
import { useNavigate } from 'react-router-dom';
import { PortalPageRoutes } from '@models/router';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
enum CalibrationActions {
	HEALTH_CHECK = 'health-check',
	DEACTIVATE = 'deactivate',
	REACTIVATE = 'reactivate',
	RENAME = 'rename',
	RECALIBRATION = 'recalibration',
}

type CalibrationActionFunctionality = {
	onClick: () => void;
	visibility?: boolean;
	menuItemProps?: MenuItemProps;
};

type TCalibrationActionMenuProps = {
	calibrationData: NmrDeviceCalibration;
	refresh: () => void;
	menuColor?: string;
};

const sendDeactivateConfirmation = (data: NmrDeviceCalibration, refresh: () => void, t: TFunction) => {
	alertService.send({
		confirmTextHeader: <Tr.Portal path="device-management.deactivate-calibration" />,
		titleText: <Tr.Portal path={'device-management.confirmation'} />,
		confirmTextComponent: <Tr.Portal path={'device-management.deactivate'} />,
		closeTextComponent: <Tr.Portal path="device-management.cancel" />,
		onConfirm: () =>
			RxUtils.promisify(nmrDeviceService.changeCalibrationStatus(data.id, false), () => {
				refresh?.();
			}),
		content: t('device-management.deactivate-calibration-message', { calibrationName: data.name }),
		confirmButtonProps: {
			sx: {
				backgroundColor: 'error.main',
			},
		},
	});
};

const sendReactivateConfirmation = (data: NmrDeviceCalibration, refresh: () => void, t: TFunction) => {
	alertService.send({
		confirmTextHeader: <Tr.Portal path="device-management.reactivate-calibration" />,
		titleText: <Tr.Portal path={'device-management.confirmation'} />,
		confirmTextComponent: <Tr.Portal path={'device-management.reactivate'} />,
		closeTextComponent: <Tr.Portal path="device-management.cancel" />,
		onConfirm: () =>
			RxUtils.promisify(nmrDeviceService.changeCalibrationStatus(data.id, true), () => {
				refresh?.();
			}),
		content: t('device-management.reactivate-calibration-message', { calibrationName: data.name }),
	});
};

export const CalibrationActionMenu: FC<TCalibrationActionMenuProps> = ({ calibrationData, refresh, menuColor }) => {
	const [healthCheckOpen, setHealthCheckOpen] = useState(false);
	const [editCalibrationNameOpen, setEditCalibrationNameOpen] = useState(false);
	const { t } = useTranslation('portal');

	const navigate = useNavigate();
	const tCalibrationData = TypeUtils.transform(NmrDeviceCalibration, calibrationData);
	const itemFunctionMap: Record<CalibrationActions, CalibrationActionFunctionality> = {
		[CalibrationActions.HEALTH_CHECK]: {
			onClick: () => setHealthCheckOpen(true),
			visibility: tCalibrationData.isActive() || (tCalibrationData.isCalibrate() && !tCalibrationData.isCalibrationNeeded()),
		},
		[CalibrationActions.REACTIVATE]: {
			onClick: () => sendReactivateConfirmation(calibrationData, refresh, t),
			visibility: tCalibrationData.isInActive(),
		},
		[CalibrationActions.RECALIBRATION]: {
			onClick: () =>
				navigate(
					`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${calibrationData.nmrDeviceId}/${PortalPageRoutes.RECALIBRATE}/${calibrationData.id}`,
				),
			visibility: tCalibrationData.isCalibrationError() || tCalibrationData.isCalibrationNeeded(),
		},
		[CalibrationActions.RENAME]: {
			onClick: () => setEditCalibrationNameOpen(true),
			visibility: tCalibrationData.isActive(),
		},
		[CalibrationActions.DEACTIVATE]: {
			onClick: () => sendDeactivateConfirmation(calibrationData, refresh, t),
			visibility: !tCalibrationData.isInActive(),
			menuItemProps: {
				sx: {
					color: 'error.main',
				},
			},
		},
	};
	const currentItems = Object.entries(itemFunctionMap)
		.filter((entry) => entry[1].visibility)
		.map((entry) => ({ item: entry[0], props: entry[1].menuItemProps }));
	return (
		<>
			<SelectMenu
				items={currentItems.map((value) => value.item)}
				menuItemProps={currentItems.map((value) => value.props)}
				selectable={false}
				onSelect={(item) => itemFunctionMap[item as CalibrationActions].onClick()}
				getButton={(event) => (
					<IconButton sx={{ height: '24px', width: '24px', color: menuColor }} onClick={event}>
						<MoreVertOutlinedIcon height={16} />
					</IconButton>
				)}
			/>

			{healthCheckOpen && (
				<HealthCheckModalDialog calibration={calibrationData} onClose={() => setHealthCheckOpen(false)} refresh={refresh} />
			)}
			{editCalibrationNameOpen && (
				<EditCalibrationNameDialog
					calibrationId={calibrationData.id}
					onSave={() => (refresh(), setEditCalibrationNameOpen(false))}
					name={calibrationData.name}
					onClose={() => setEditCalibrationNameOpen(false)}
				/>
			)}
		</>
	);
};
