import { PatchType } from '@models/request-response';
import { Accessory } from './accessory.class';
import { httpService } from '../http';
import { concatMap, map, tap } from 'rxjs';
import { logger } from '../logger';
import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { ReceiverGainThresholds } from './receiver-gain-threshold';
import { AccessoryStatus as AccessoryStatusClass } from './accessory-status.class';
import { coreConfig } from '../coreConfig';
import { AccessoryNamesResponse } from './accessory-names-response.class';

class AccessoryService {
	private getURL() {
		return `${coreConfig.url}/Accessory`;
	}

	public getAccessory(id: number) {
		return httpService.get<Accessory>(this.getURL(), `${encodeURIComponent(id)}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getAccessory.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Accessory, result)),
		);
	}

	public receiverGainThreshold() {
		return httpService.get<ReceiverGainThresholds[]>(this.getURL(), `receiverGains`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.receiverGainThreshold.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	public getAccessoryStatus(id: Maybe<number>) {
		return httpService.get<AccessoryStatusClass>(this.getURL(), `${id}/status`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getAccessoryStatus.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	public renameAccessory(id: number, name: string) {
		return httpService.patch(this.getURL(), `${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Name',
					value: name,
				},
			],
			errorHandler: 'notification',
		});
	}

	public renameAccessoryCalibration(id: number, name: string) {
		return httpService.patch(this.getURL(), `calibrations/${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Name',
					value: name,
				},
			],
			errorHandler: 'notification',
		});
	}

	public deleteAccessoryCalibration(accessoryId: number, calibrationId: number) {
		return httpService
			.delete(this.getURL(), `${accessoryId}/calibrations/${calibrationId}/delete`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.deleteAccessoryCalibration.name}]`, result)));
	}

	public getAccessoryNames() {
		return httpService
			.get<AccessoryNamesResponse>(this.getURL(), `accessoryNames/`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.getAccessoryNames.name}]`, result)));
	}
}

export const accessoryService = new AccessoryService();
