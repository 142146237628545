export type FilterType = 'dropdown' | 'dateRange' | 'searchableDropdown';

export interface IFilterValues {
	id: string;
	value: string;
}

export interface IFilter {
	key: string;
	values: IFilterValues[];
	type: FilterType;
	escapesTranslation?: boolean;
	disabled?: boolean;
	single?: boolean;
	loading?: boolean;
	title?: string;
}

export enum FilterKey {
	STATUS = 'statuses',
	STAGE = 'stages', // REMOVE THIS
	TYPE = 'types', // REMOVE THIS
	TECHNOLOGY = 'technologies', // REMOVE THIS
	DATE_RANGE = 'dateRange',
	FROM = 'from',
	TO = 'to',
	TECHNIQUES = 'techniques',
	REQUEST_TECHNOLOGIES = 'requestTechnologies',
	ROLES = 'roles',
	ORDER_TYPES = 'orderTypes',
	REQUEST_TYPES = 'requestTypes',
	PRIORITIES = 'priorities',
	WAVE_IDS = 'waveIds',
	REJECTION_SEVERITIES = 'rejectionSeverities',
	ASSIGNEES = 'assignees',
	DECLINE_REASONS = 'declineReasons',
	ORGANIZATION_ID = 'organizationId',
	LABORATORY_IDS = 'laboratoryIds',
	QUALITY_GRADES = 'qualityGrades',
	LIBRARIES = 'libraries',
	REJECT_REASONS = 'rejectReasons',
}
