import { FC } from 'react';
import { Typography } from '@mui/material';
import { Tr, innerTitleSpacer } from '@utils';

interface OrganizationName {
	organizationName: string;
}

export const OrganizationName: FC<OrganizationName> = ({ organizationName }) => {
	return (
		<Typography
			data-testid="organization-name-id"
			variant="h2"
			sx={{ color: 'text.secondary', wordBreak: 'break-all', marginBottom: innerTitleSpacer }}
		>
			{organizationName ? organizationName : <Tr.Admin path="organization.new-organization" />}
		</Typography>
	);
};
