import { AdminRole, PortalRole, UserRole } from '@models/user/user.role.enum';
import { TypeUtils } from '..';

export interface IRoutingPolicy {
	path: string;
	policy: UserRole[];
	prioritizied?: boolean;
	shouldHaveActiveLab?: boolean;
}
interface IRolePathMap {
	[key: string]: IRoutingPolicy[];
}

const ROLE_PATH_MAPS: IRolePathMap = {
	AdminRole: [{ path: 'admin', policy: [] }],
	PortalRole: [
		{
			path: 'portal',
			policy: [],
			prioritizied: true,
			shouldHaveActiveLab: true,
		},
		{
			path: 'orgAdmin',
			policy: [UserRole.ORG_ADMIN],
		},
	],
};
const ROLES_BY_ENUMS = { AdminRole, PortalRole };

const proxyHandler = {
	get(target: typeof ROLE_PATH_MAPS, prop: UserRole) {
		const foundElement = Object.keys(ROLES_BY_ENUMS).find((role) =>
			Object.values(ROLES_BY_ENUMS[role as keyof typeof ROLES_BY_ENUMS]).includes(prop),
		);
		return foundElement ? TypeUtils.returnValueOfKey(foundElement, target) : null;
	},
};

export const ROLE_PATH_MAPPING = new Proxy(ROLE_PATH_MAPS, proxyHandler);
