import { FC, ReactNode } from 'react';
import { NmrAnalysisResult } from '@services';
import { SxProps, Typography } from '@mui/material';
import { Tr } from '@utils';

type MessageMappingType =
	| 'QualitativePositive'
	| 'QualitativeNegative'
	| 'QuantitativePositive'
	| 'QuantitativeNegative'
	| 'QualitativeTheoreticalPositive'
	| 'QualitativeTheoreticalNegative'
	| 'QuantitativeTheoreticalPositive'
	| 'QuantitativeTheoreticalNegative';

const messageMapping: Record<MessageMappingType, ReactNode> = {
	QualitativePositive: <Tr.Portal path="analysis-result-table.was-verified" />,
	QualitativeTheoreticalPositive: <Tr.Portal path="analysis-result-table.was-verified-theoretical" />,
	QualitativeNegative: <Tr.Portal path="analysis-result-table.was-not-verified" />,
	QualitativeTheoreticalNegative: <Tr.Portal path="analysis-result-table.was-not-verified-theoretical" />,
	QuantitativePositive: <Tr.Portal path="analysis-result-table.was-quantified" />,
	QuantitativeNegative: <Tr.Portal path="analysis-result-table.was-not-quantified" />,
	QuantitativeTheoreticalPositive: <Tr.Portal path="analysis-result-table.was-quantified-theoretical" />,
	QuantitativeTheoreticalNegative: <Tr.Portal path="analysis-result-table.was-not-verified-theoretical" />,
};

export const AnalysisMatchedDescription: FC<{ analysisResult: NmrAnalysisResult; isTheoretical?: boolean }> = ({
	analysisResult,
	isTheoretical,
}) => {
	const { resultMatches: results, nmrAnalysis } = analysisResult;
	const { measurementType } = analysisResult.nmrAnalysis;
	const messageType: 'Positive' | 'Negative' = results.length > 0 ? 'Positive' : 'Negative';

	const labelStyle: SxProps = {
		marginBottom: 2,
		fontWeight: 400,
		color: 'grey.800',
		fontSize: 16,
		lineHeight: '160%',
	};

	const theoreticals = analysisResult?.resultMatches?.filter((item) => item.isTheoretical);
	const isMultiTheoretical = theoreticals && theoreticals.length > 1;

	// TO DO: FIX IMPLICIT ANY ERRORS
	return analysisResult.isTargeted() ? (
		<>
			<Typography variant="pg-m" sx={labelStyle}>
				<Tr.Portal path="analysis-result-table.label" />
				{nmrAnalysis?.sampleName?.split(/\.jdx|\.dx|\.zip/g)[0]}{' '}
				{messageMapping[`${measurementType}${isTheoretical ? 'Theoretical' : ''}${messageType}`]}
			</Typography>
		</>
	) : (
		<Typography variant="pg-m" sx={labelStyle}>
			<Tr.Portal path="analysis-result-table.label" />
			{nmrAnalysis?.sampleName?.split(/\.jdx|\.dx|\.zip/g)[0]}{' '}
			{results &&
				results.length > 0 &&
				(isTheoretical ? (
					isMultiTheoretical ? (
						<Tr.Portal path="analysis-result-table.theoretical-identified-entire-lib-multi" />
					) : (
						<Tr.Portal path="analysis-result-table.theoretical-identified-entire-lib-single" />
					)
				) : (
					<Tr.Portal path="analysis-result-table.identified-entire-lib" />
				))}
			{!results ||
				(results.length === 0 &&
					(isTheoretical ? (
						<Tr.Portal path="analysis-result-table.theoretical-not-identified-entire-lib" />
					) : (
						<Tr.Portal path="analysis-result-table.not-identified-entire-lib" />
					)))}
		</Typography>
	);
};
