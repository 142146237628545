import { ActiveState, Body1, Body1WithBorders, DataTableBackend, EditButton, getStatusColor, OverflowText } from '@components/common';
import { getParams, useFilterSearchParams, useHasPermissions, useService } from '@hooks';
import { AdminPermission, ExtendedColumn, PageRoutes, SortOrder, SubscriptionType } from '@models';
import { Stack, Typography } from '@mui/material';
import { DateUtils, Tr } from '@utils';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationStatus } from '@models/organization';
import { AdminEditOrganizationTabs } from '@models/organization/edit-organization-tabs.enum';
import Add from '@mui/icons-material/Add';
import { OrganizationManagementModel, organizationService } from '@services';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { of } from 'rxjs';
import { TableButton } from '@components/common/TableButton';

const isActive = (status: OrganizationStatus) => status === OrganizationStatus.ACTIVE;

export const CustomerOrganizationManagement: FC = () => {
	const { searchParams } = useFilterSearchParams('createTime', SortOrder.DESC);

	const navigate = useNavigate();
	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');
	const { t, ready } = useTranslation('admin');
	const translate = (path: string) => (ready ? t(path) : '');
	const hasCustomerManagementPermission = useHasPermissions(AdminPermission.VIEW_CUSTOMER_MANAGEMENT);

	const { data: subscriberList } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		return hasCustomerManagementPermission ? organizationService.getAllWithParams(searchParams) : of();
	}, [searchParams]);

	const columns = useMemo<ExtendedColumn<OrganizationManagementModel>[]>(
		() => [
			{
				accessor: 'status',
				Header: (
					<Stack marginLeft={1}>
						<Tr.Common path="status" />
					</Stack>
				),
				Cell: (props) => (
					<Stack marginLeft={1}>
						<ActiveState status={props.cell.value.toUpperCase()} />
					</Stack>
				),
			},
			{
				accessor: 'name',
				Header: <Tr.Common path="organization" />,
				Cell: ({ row, value }) => (
					<OverflowText
						variant="pg-s"
						sx={{ minWidth: 100, maxWidth: 200, color: getStatusColor(isActive(row.original.status)), fontWeight: 400 }}
					>
						{value}
					</OverflowText>
				),
			},
			{
				accessor: 'sapCustomerId',
				Header: <Tr.Common path="merck-cust-id" />,
				Cell: ({ row, value }) => (
					<OverflowText
						variant="pg-s"
						sx={{ minWidth: 100, maxWidth: 250, color: getStatusColor(isActive(row.original.status)), fontWeight: 400 }}
					>
						{value}
					</OverflowText>
				),
			},
			{
				id: 'freeRuns',
				accessor: (originalRow) => originalRow.usedFreeRuns,
				Header: (
					<Stack marginRight={1}>
						<Tr.Common path="free-runs" />
					</Stack>
				),
				disableSortBy: true,
				Cell: ({ row }: PropsWithChildren<CellProps<OrganizationManagementModel>>) => (
					<Body1WithBorders borderEnabled={!!row.original.totalFreeRuns} status={isActive(row.original.status)}>
						{!row.original.totalFreeRuns ? '-' : row.original.usedFreeRuns + '/' + row.original.totalFreeRuns}
					</Body1WithBorders>
				),
			},
			{
				id: 'consumption',
				accessor: (originalRow) => originalRow.usedFreeRuns,
				Header: (
					<Stack marginRight={1}>
						<Tr.Common path="consumption" />
					</Stack>
				),
				disableSortBy: true,
				Cell: ({ row }: PropsWithChildren<CellProps<OrganizationManagementModel>>) => (
					<Body1WithBorders
						status={isActive(row.original.status)}
						borderEnabled={row.original.subscriptionType !== SubscriptionType.FREE}
					>
						{row.original.subscriptionType === SubscriptionType.FREE
							? '-'
							: `${row.original.usedConsumption}/${row.original.totalConsumption} ${row.original.currency ?? ''}`}
					</Body1WithBorders>
				),
			},
			{
				id: 'subscriptionType',
				accessor: (originalRow) => originalRow.subscriptionType,
				Header: <Tr.Common path="subscription-type" />,
				Cell: ({ row }: PropsWithChildren<CellProps<OrganizationManagementModel>>) => (
					<Body1 status={isActive(row.original.status)} sx={{ minWidth: 100, maxWidth: 100 }}>
						{t(`customer-management.${row.original.subscriptionType}`)}
					</Body1>
				),
			},
			{
				id: 'startDate',
				accessor: (originalRow) => originalRow.startDate,
				Header: <Tr.Common path="start-date" />,
				Cell: ({ row }: PropsWithChildren<CellProps<OrganizationManagementModel>>) => (
					<Body1 status={isActive(row.original.status)}>
						{row.original.startDate ? DateUtils.getFormattedDate(new Date(row.original.startDate), 'dd/MM/yyyy') : '-'}
					</Body1>
				),
			},
			{
				id: 'endDate',
				accessor: (originalRow) => originalRow.endDate,
				Header: <Tr.Common path="end-date" />,
				Cell: ({ row }: PropsWithChildren<CellProps<OrganizationManagementModel>>) => (
					<Body1 status={isActive(row.original.status)}>
						{row.original.endDate ? DateUtils.getFormattedDate(new Date(row.original.endDate), 'dd/MM/yyyy') : '-'}
					</Body1>
				),
			},
			{
				accessor: 'createTime',
				Header: <Tr.Common path="creation-date" />,
				Cell: ({ value, row }) => (
					<Body1 status={isActive(row.original.status)}>
						{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy') : '-'}
					</Body1>
				),
			},
			{
				accessor: 'id',
				Header: <></>,
				sticky: true,
				disableSortBy: true,
				Cell: ({ row }) => (
					<EditButton
						onClick={() => {
							navigate(`../../${PageRoutes.CUSTOMER_MANAGEMENT}/${row.original.id}/${AdminEditOrganizationTabs.OVERVIEW}`);
						}}
					/>
				),
			},
		],
		[],
	);

	return (
		<Stack data-testid="customer-management-test-id" direction="column">
			<Stack direction="row" sx={{ marginBottom: 2 }} justifyContent="space-between" alignItems="center">
				<Typography variant="h2" sx={{ color: 'text.secondary' }}>
					<Tr.Admin path={'organization-management.title'} />
				</Typography>

				<TableButton.Text
					data-testid="add-organization-id"
					sx={{ alignItems: 'center', height: '2.5rem' }}
					onClick={() => navigate(`../../${PageRoutes.CUSTOMER_MANAGEMENT}/new`)}
				>
					<Add sx={{ fontSize: '20px' }} />
					<Typography variant="subtitle2" sx={{ paddingBottom: 0.2, paddingLeft: 0.5 }} color="primary.main">
						<Tr.Admin path="customer-management.add-organization" />
					</Typography>
				</TableButton.Text>
			</Stack>
			<DataTableBackend
				data={subscriberList?.data || []}
				columns={columns}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: subscriberList?.totalPages ?? 1 }}
				searchText={searchTextfieldValue}
				searchPlaceholder={translate('customer-management.search-placeholder')}
				onSearchTextChange={setSearchTextfieldValue}
				searchFieldProps={{
					sx: {
						width: (400 / 1440) * window.innerWidth,
						borderColor: 'grey.200',
						borderWidth: '1.5px',
						input: {
							fontWeight: 400,
							fontSize: '14px',
							color: 'grey.800',
							lineHeight: '120%',
						},
						marginLeft: 1,
					},
				}}
				headerCellProps={{
					sx: {
						fontSize: '14px',
						fontWeight: 700,
						lineHeight: '105%',
						color: 'text.primary',
					},
				}}
			/>
		</Stack>
	);
};
