import { Amplify } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userSelector } from '@store/slices/common/common.slice';
import { amplifyConfig, PoolType } from '@utils/AmplifyConfig';

export const useAuthorizeConfig = () => {
	const user = useSelector(userSelector);
	const { pathname, search } = useLocation();
	const params = new URLSearchParams(search);
	if (pathname === '/loggedin' || user) {
		if (params.get('target') === PoolType.ADMIN_POOL.toLowerCase() || !user?.organizationId) {
			amplifyConfig.currentConfig = PoolType.ADMIN_POOL;
		} else {
			amplifyConfig.currentConfig = PoolType.USER_POOL;
		}
	}
	Amplify.configure(amplifyConfig.getCurrentAmplifyConfig());
};
