import { FC, ReactNode } from 'react';
import { Card, CardProps, Stack, SxProps } from '@mui/material';
import { mainTitleSpacer } from '@utils';

export interface IGenericCardProps extends Omit<CardProps, 'title'> {
	title?: ReactNode;
	sx?: SxProps;
	containerSx?: SxProps;
	dataTestId?: string;
	enableWidthControl?: boolean;
}

export const GenericCard: FC<IGenericCardProps> = ({
	title,
	sx,
	children,
	onClick = () => null,
	id,
	dataTestId,
	enableWidthControl = false,
	containerSx,
}) => {
	return (
		<Card data-testid={dataTestId} variant="outlined" sx={{ paddingX: mainTitleSpacer, paddingY: 4, ...sx }} onClick={onClick} id={id}>
			<Stack direction="column" width={enableWidthControl ? '100%' : 'auto'} sx={{ ...containerSx }}>
				{title ? <Stack sx={{ marginBottom: 2.5 }}>{title}</Stack> : null}
				{children}
			</Stack>
		</Card>
	);
};
