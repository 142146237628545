import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formFieldSpacer, innerTitleSpacer, Tr } from '@utils';
import { Grid, Stack, SxProps, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield, GenericCard } from '@components/common';
import { useParams } from 'react-router-dom';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';

interface IrReferenceAcquisitionParametersProps {
	userReferenceData?: any;
	formik: FormikApiType;
	sx?: SxProps;
	editing?: boolean;
}

export const IrReferenceAcquisitionParameters: FC<IrReferenceAcquisitionParametersProps> = ({
	userReferenceData,
	formik,
	sx,
	editing = false,
}) => {
	const { t } = useTranslation('irportal');
	const { status } = useParams<{ status: string }>();
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;

	useEffect(() => {
		if (editing) {
			formik.validateForm();
		}
	}, [userReferenceData]);

	return (
		<GenericCard sx={sx}>
			<Stack marginBottom={innerTitleSpacer}>
				<Typography variant="h3" color={disableRmInActiveFields ? 'grey.400' : 'primary.main'}>
					<Tr.IrPortal path="drm-detail.acquisition-parameters" />
				</Typography>
			</Stack>
			<Grid container spacing={formFieldSpacer}>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="samplingTechnique"
						title={t('drm-detail.sampling-technique')}
						placeholder={t('user-references.placeholders.sampling-technique')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="numberOfScans"
						title={t('drm-detail.number-of-scans')}
						placeholder={t('user-references.placeholders.number-of-scans')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="firstX"
						title={t('drm-detail.erm-upper-range')}
						placeholder={t('user-references.placeholders.upper-range')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="lastX"
						title={t('drm-detail.erm-lower-range')}
						placeholder={t('user-references.placeholders.lower-range')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="resolution"
						title={t('drm-detail.erm-resolution')}
						placeholder={t('user-references.placeholders.resolution')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="physicalProductBatchNumber"
						title={t('user-references.batch-number')}
						placeholder={t('user-references.placeholders.batch-number')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
			</Grid>
		</GenericCard>
	);
};
