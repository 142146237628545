import { IFilter } from '@models/filter';
import { QualityGrade } from '@models/reference-material';

enum IrDrmStatus {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
}

export const IR_DRM_DATABASE_FILTER_OPTIONS: IFilter[] = [
	{
		key: 'statuses',
		values: [
			{
				id: '5',
				value: IrDrmStatus.ACTIVE,
			},
			{
				id: '4',
				value: IrDrmStatus.INACTIVE,
			},
		],
		type: 'dropdown',
	},
	{
		key: 'qualityGrades',
		values: [
			{
				id: '0',
				value: QualityGrade.DRM,
			},
			{
				id: '1',
				value: QualityGrade.DAS,
			},
			{
				id: '2',
				value: QualityGrade.THEORETICAL,
			},
		],
		type: 'dropdown',
	},
	{
		key: 'dateRange',
		values: [
			{
				id: '1',
				value: '',
			},
			{
				id: '2',
				value: '',
			},
		],
		type: 'dateRange',
	},
];
