import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { RxUtils, Tr, analyticsAnalysisEvent } from '@utils';
import { IPortalLayoutTitleProps, PortalLayoutTitle } from '@components/common';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import { alertService, nmrAnalysisService, notificationService } from '@services';
import { NmrAnalysisStepButton } from '../NmrAnalyticalProcedure/NmrAnalysisStepButton';
import { useAnalysis } from '@hooks';
import { FormikApiType } from '@components/common';

interface IAnalysisTitleProps extends IPortalLayoutTitleProps {
	hasFile?: boolean;
	savedTime?: Date;
	formik: FormikApiType;
}

export const NmrAnalysisTitle: FC<IAnalysisTitleProps> = ({ formik, hasFile, savedTime, sx, ...props }) => {
	const { id = '' } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { t } = useTranslation('portal');
	const { measurementType, isAnalysisEditable } = useAnalysis();

	const isDeleteButtonDisabled = !isAnalysisEditable;
	return (
		<PortalLayoutTitle
			sx={{ lineBreak: 'anywhere', padding: '10px 0', position: 'sticky', backgroundColor: 'grey.50', top: 0, zIndex: 2, ...sx }}
			title={formik?.values.title || <Tr.Portal path="new-analysis.title" />}
			id={id}
			savedTime={savedTime}
			{...props}
		>
			{hasFile && (
				<Stack direction="row" spacing={2}>
					<Stack>
						<Button
							variant="outlined"
							size="medium"
							data-testid="reset-button-id"
							onClick={() => {
								alertService.send({
									onConfirm: () => {
										formik?.resetForm({ values: { ...formik?.values, title: '' } });
										RxUtils.promisify(
											nmrAnalysisService.deleteAnalysis(+id),
											() => {
												analyticsAnalysisEvent.RemoveAnalysis(id);
												notificationService.sendSuccess(t('new-analysis.success-file-delete'));
												navigate('/dashboard');
											},
											() => notificationService.sendSuccess(t('new-analysis.error-file-delete')),
										);
									},
									content: <Tr.Portal path="new-analysis.delete-message" />,
									confirmTextHeader: <Tr.Portal path="new-analysis.delete-analysis-title" />,
									titleText: <Tr.Portal path="confirmation" />,
									confirmTextComponent: <Tr.Portal path="new-analysis.delete" />,
									closeTextComponent: <Tr.Common path="cancel" />,
								});
							}}
							sx={{ minWidth: 180 }}
							disabled={isDeleteButtonDisabled}
							startIcon={<Delete width={20} height={20} fill="#01884C" />}
						>
							<Tr.Portal path="new-analysis.delete-analysis" />
						</Button>
					</Stack>
					<Stack>{measurementType && <NmrAnalysisStepButton formik={formik} />}</Stack>
				</Stack>
			)}
		</PortalLayoutTitle>
	);
};
