import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { httpService, logger } from '@services/core';
import { Observable, concatMap, map, tap } from 'rxjs';
import { nmrDrmPipelineConfig } from '../nmrDrmPipelineConfig';
import { NmrDrmOrderWave } from './nmr-drm-wave.class';
import { PatchType } from '@models/request-response';

class NmrDrmWaveService {
	private getUrl() {
		return `${nmrDrmPipelineConfig.url}/Wave`;
	}

	fetchAllWaves(): Observable<NmrDrmOrderWave[]> {
		return httpService.get<NmrDrmOrderWave[]>(this.getUrl(), ``, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.fetchAllWaves.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrDrmOrderWave, result)),
		);
	}

	delete(id: number) {
		return httpService
			.delete(this.getUrl(), `${id}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.delete.name}]`, result)));
	}

	post(name: string) {
		return httpService
			.post(this.getUrl(), ``, { body: { name: name }, errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.delete.name}]`, result)));
	}

	patch(wave: Partial<NmrDrmOrderWave>) {
		return httpService
			.patch(this.getUrl(), `${wave.id}`, {
				body: [
					wave.priority
						? {
								op: PatchType.REPLACE,
								path: '/Priority',
								value: wave.priority,
							}
						: {
								op: PatchType.REPLACE,
								path: '/Name',
								value: wave.name,
							},
				],
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.patch.name}]`, result)));
	}
}

export const nmrDrmWaveService = new NmrDrmWaveService();
