import { IrCalibrationStatus } from './ir-device-status.enum';
import { AnalysisTechnology } from '@services/core';
import { IrAccessoryCalibrationStatus } from '../ir-accessory';

export class IrDeviceAccessory {
	id: number;
	accessoryId: number;
	name: string;
	irDeviceId: number;
	status: IrCalibrationStatus;
	manufacturer: string;
	linearity: number;
	solvent?: string;
	intercept?: number;
	standardDeviation?: number;
	calibrationErrorMessage?: string;
	accessoryType: number;
	createTime: string;
	nextCalibrationTime: string;
	nextCalibrationDate: string;
	archivedDate: string;
	lastCalibrationTime: string;

	public isCalibrate() {
		return this.status === IrCalibrationStatus.UNCALIBRATED;
	}

	public isCalibrating() {
		return this.status === IrCalibrationStatus.CALIBRATED;
	}
	public isCalibrationError() {
		return this.status === IrCalibrationStatus.ERROR;
	}
}

export class IrDeviceAccessoryForList extends IrDeviceAccessory {
	accessoryName: string;
	manufacturer: string;
	accessoryStatus: IrAccessoryCalibrationStatus;
	supportedTechnology: AnalysisTechnology;
	calibrationErrorMessage?: string;
}
