import { FC, useState } from 'react';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import { TableButton } from '@components/nmr-portal';
import { Tr } from '@utils';
import { authService } from '@services';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

interface IUserConsentProps {
	onBackClick: () => void;
	onNextClick: () => void;
}

export const UserConsent: FC<IUserConsentProps> = ({ onBackClick, onNextClick }) => {
	const [isAccept, setIsAccept] = useState(false);

	return (
		<Stack data-testid="user-consent-wrapper-id">
			<Typography variant="h1" sx={{ marginBottom: '2rem' }}>
				<Tr.Login path="user-consent.user-consent" />
			</Typography>
			<Stack direction="row" alignItems="flex-start">
				<FormControlLabel
					sx={{ marginRight: '0' }}
					label=""
					control={
						<Checkbox
							data-testid="check-consent-id"
							checked={isAccept}
							onChange={(event) => setIsAccept(event.target.checked)}
							sx={{ marginTop: '-5px' }}
						/>
					}
				/>
				<Typography variant="pg-xs" color="grey.800" lineHeight="180%">
					<Tr.Login path="user-consent.user-consent-text" />
				</Typography>
			</Stack>
			<Stack direction="row" marginTop="2.5rem" alignItems="center">
				{/* TO DO: Make Back Button a component */}
				<Stack
					data-testid="back-button-id"
					onClick={() => {
						authService.logout().finally(() => {
							onBackClick();
						});
					}}
					direction="row"
					sx={{ cursor: 'pointer', alignItems: 'center' }}
				>
					<KeyboardBackspaceIcon color="primary" />
					<Typography variant="label-s" color="primary" marginLeft="0.625rem">
						<Tr.Login path="register.back" />
					</Typography>
				</Stack>
				<TableButton.Contained
					type="submit"
					size="small"
					sx={{ marginLeft: 'auto', display: 'flex', width: '3.75rem', height: '2.5rem' }}
					onClick={() => onNextClick()}
					disabled={!isAccept}
					data-testid="register-button-id"
				>
					<Tr.Login path="register.next" />
				</TableButton.Contained>
			</Stack>
		</Stack>
	);
};
