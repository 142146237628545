import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';

export const EmptyWaveCard = () => {
	return (
		<Stack sx={{ height: '93px', borderRadius: 1, border: '1px dashed #E1E1EA', textAlign: 'center', justifyContent: 'center' }}>
			<Typography variant="pg-s" color="grey.800">
				<Tr.Admin path="drm-pipeline.nmr.backlog.no-waves" />
			</Typography>
		</Stack>
	);
};
