import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { RxUtils, getContactMailAddress } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppNotification, NotificationType } from '@models/notification';
import CloseIcon from '@mui/icons-material/Close';
import { NotificationWarningIcon } from '@components/icons';
import { notificationService } from '@services/core';
import { PagedResult } from '@models/request-response';
import { setBannerNotifications } from '@store/slices/common/common.slice';
import { useTranslation } from 'react-i18next';
import { useLocationSelector } from '@store/slices/common/location.slice';

interface IOrganizationNotificationProps {
	notification: AppNotification;
}

export const OrganizationNotification: FC<IOrganizationNotificationProps> = ({ notification }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation('portal');
	const country = useSelector(useLocationSelector);

	const organizationNotificationTypeMapper = {
		[NotificationType.ReachedTheCountLimitOfAnalysis]: 'reached-to-the-limit-of-analysis',
		[NotificationType.ReachedNinetyPercentOfAnalysis]: 'reaching-the-limit-of-analysis',
		[NotificationType.SubscriptionOneWeekRemained]: 'reaching-the-limit-of-time',
		[NotificationType.ReachedNinetyFivePercentOfAnalysis]: 'reached-to-the-limit-of-analysis',
		[NotificationType.SubscriptionOneDayRemained]: 'reaching-the-limit-of-time',
		[NotificationType.SubscriptionOneMonthRemained]: 'reaching-the-limit-of-time',
		[NotificationType.SubscriptionExpired]: 'subscription-expired',
	};

	const onPermanentNotificationClose = (id: number) => {
		RxUtils.promisify(notificationService.updateNotificationStatus(id, true), () => {
			RxUtils.promisify(notificationService.getNotifications(1, 0, false, true), (appNotifications: PagedResult<AppNotification>) => {
				dispatch(setBannerNotifications(appNotifications.data));
			});
		});
	};

	const remainingPercantage =
		notification?.notificationType === NotificationType.ReachedNinetyPercentOfAnalysis
			? '10%'
			: notification?.notificationType === NotificationType.ReachedNinetyFivePercentOfAnalysis
				? '5%'
				: '';

	return (
		<Stack
			data-testid="organization-notification-wrapper-id"
			direction="column"
			sx={{
				height: 40,
				backgroundColor: 'secondary.main',
			}}
		>
			<Stack
				direction="row"
				alignContent="center"
				sx={{
					textAlign: 'center',
					marginLeft: 2,
					marginRight: 2,
					marginTop: 1,
					whiteSpace: 'nowrap',
				}}
			>
				<Box
					sx={{
						color: 'black',
						marginRight: 1,
					}}
				>
					<NotificationWarningIcon
						sx={{ ' path': { fill: '#0F1A2E' }, border: '2px solid #0F1A2E', color: 'black', fontSize: '18px' }}
					/>
				</Box>
				<Typography variant="label-m">
					{t(`notification.${organizationNotificationTypeMapper[notification.notificationType]}`, {
						remaining: remainingPercantage,
					})}
					<Link
						sx={{ display: 'inline-block' }}
						href={`mailto:${getContactMailAddress(country.code)}?subject=${t('notification.analysis-limit-exceeded')}`}
					>
						<Typography variant="label-m" sx={{ fontWeight: 'bolder' }}>
							{t('notification.contact-us')}
						</Typography>
					</Link>
					{t('notification.part2')}
				</Typography>
				<IconButton
					data-testid="permanent-button-id"
					onClick={() => onPermanentNotificationClose(notification.id)}
					sx={{ marginLeft: 'auto', padding: 0, color: 'text.primary' }}
				>
					<CloseIcon sx={{ width: '1.2rem', height: '1.2rem', color: 'inherit' }} />
				</IconButton>
			</Stack>
		</Stack>
	);
};
