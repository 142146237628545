import PriorityHighRounded from '@mui/icons-material/PriorityHighRounded';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { Alert, Avatar, IconButton, Tooltip, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type TActionRequired = {
	showAction?: boolean;
	onClickAction?: () => void;
};

export const ActionRequired: FC<TActionRequired> = ({ showAction, onClickAction }) => {
	const { t } = useTranslation('admin');
	return (
		<Alert
			severity="error"
			sx={{ marginBottom: 3 }}
			icon={
				<Avatar sx={{ width: '1.25rem', height: '1.25rem', backgroundColor: 'error.main' }}>
					<PriorityHighRounded sx={{ fontSize: '14px', color: 'white' }} />
				</Avatar>
			}
			action={
				showAction && (
					<Tooltip placement="top" arrow title={t('erm-request-detail.remove-banner')}>
						<IconButton disableRipple sx={{ color: 'grey.800', padding: 0 }} onClick={onClickAction}>
							<CloseOutlined />
						</IconButton>
					</Tooltip>
				)
			}
		>
			<Typography variant="pg-s">
				<Tr.Admin path={'erm-request-detail.action-required-message'} />
			</Typography>
		</Alert>
	);
};
