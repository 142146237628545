import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikTextfield, ModalDialog } from '@components/common';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RxUtils } from '@utils/Rx';
import { irDeviceService } from '@services/ir';
import { formFieldSpacer } from '@utils/Theme';

export interface IrEditDeviceNameDialogProps {
	deviceId: number;
	currentName: string;
	currentManufacturer: string;
	onClose: (success?: boolean) => void;
}

export const IrEditDeviceNameDialog: FC<IrEditDeviceNameDialogProps> = ({ deviceId, currentName, currentManufacturer, onClose }) => {
	const { t } = useTranslation('irportal');
	const formik = useFormik<Extendable<{ name: string; manufacturer: string }>>({
		initialValues: {
			name: currentName,
			manufacturer: currentManufacturer,
		},
		validationSchema: yup.object({
			name: yup.string().trim().required(t('device-management.field-error.calibration-name')),
			manufacturer: yup.string().trim().required(t('device-management.field-error.manufacturer-name')),
		}),
		initialTouched: {
			name: true,
			manufacturer: true,
		},
		onSubmit: ({ name, manufacturer }) => {
			RxUtils.promisify(irDeviceService.renameDevice(deviceId, name, manufacturer), () => onClose());
		},
	});

	return (
		<ModalDialog
			fullWidth={false}
			open={true}
			titleProps={{
				sx: {
					justifyContent: 'space-between',
					marginTop: 0,
					marginBottom: 5,
					minHeight: 30,
					backgroundColor: 'grey.50',
					paddingY: 1.2,
					paddingX: 2,
				},
			}}
			title={
				<Typography variant="body1" sx={{ fontWeight: 400, lineHeight: '200%' }}>
					{t('device-management.rename-device-title')}
				</Typography>
			}
			onClose={() => onClose()}
			maxWidth="sm"
			iconButtonProps={{ padding: 0, right: 16 }}
			iconProps={{ width: 24, height: 24 }}
			contentProps={{ sx: { width: 472, paddingBottom: 8 } }}
		>
			<Stack data-testid="edit-device-wrapper" width={1} direction="column" justifyContent="space-between">
				<form onSubmit={formik.handleSubmit}>
					<Stack paddingX={8}>
						<Stack sx={{ textAlign: 'center' }}>
							<Typography variant="h3"> {t('device-management.rename-device')}</Typography>
							{/* TO DO: MIGRATE IT TO TRANSLATION */}
							<Typography variant="body2" sx={{ marginTop: '1rem', lineHeight: '160%' }}>
								You can rename your device title below
							</Typography>
						</Stack>
						<FormikTextfield
							formikApi={formik}
							name="name"
							data-testid="edit-device-name-id"
							title={t('device-management.device-name')}
							required
							placeholder={t('device-management.calibration-name-placeholder')}
							containerProps={{ width: 1, marginTop: '1rem' }}
							inputProps={{ 'data-testid': 'device-name-id' }}
						/>
						<FormikTextfield
							formikApi={formik}
							name="manufacturer"
							data-testid="edit-manufacturer-name-id"
							title={t('device-management.manufacturer')}
							required
							placeholder={t('device-management.manufacturer-name-placeholder')}
							containerProps={{ width: 1, marginTop: formFieldSpacer }}
							inputProps={{ 'data-testid': 'device-manufacturer-id' }}
						/>
					</Stack>
					<Stack direction="row" width={1} paddingX={8} marginTop={formFieldSpacer}>
						<Button
							data-testid="cancel-btn-id"
							variant="outlined"
							sx={{ marginRight: '1rem', width: '100%' }}
							onClick={() => onClose()}
						>
							<Tr.IrPortal path="device-management.cancel" />
						</Button>
						<Button
							data-testid="submit-button-id"
							type="submit"
							disabled={!formik.dirty}
							sx={{ width: '100%' }}
							variant="contained"
							disableElevation
						>
							<Tr.IrPortal path="Save" />
						</Button>
					</Stack>
				</form>
			</Stack>
		</ModalDialog>
	);
};
