export enum ReferenceMaterialHubMethods {
	CREATE_PROCESS_SUCCEEDED = 'CreateProcessSucceeded',
	CREATE_PROCESS_FAILED = 'CreateProcessFailed',
	UPDATE_PROCESS_SUCCEEDED = 'UpdateProcessSucceeded',
	UPDATE_PROCESS_FAILED = 'UpdateProcessFailed',
	REACTIVATION_PROCESS_SUCCEEDED = 'ReactivationProcessSucceeded',
	REACTIVATION_PROCESS_FAILED = 'ReactivationProcessFailed',
	DEACTIVATION_PROCESS_SUCCEEDED = 'DeactivationProcessSucceeded',
	DEACTIVATION_PROCESS_FAILED = 'DeactivationProcessFailed',
	CHECK_CUSTOM_REFERENCE_MATERIAL_STATUS = 'CheckCustomReferenceMaterialStatus',
	PROCESS_COMPLETED = 'ProcessCompleted',
}
