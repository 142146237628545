export enum AnalysisHubMethods {
	CHECK_ANALYSIS_RESULT = 'CheckAnalysisResult',
	ANALYSIS_COMPLETED = 'AnalysisCompleted',
}

export enum AnalysisTheoreticalHubMethods {
	CHECK_THEORETICAL_SPECTRA_RESULT = 'CheckTheoreticalSpectraSearchResult',
	THEORETICAL_SPECTRA_SEARCH_COMPLETED = 'TheoreticalSpectraSearchCompleted',
}

export enum QuantitativeNonTargetedIntegrationHubMethods {
	CHECK_INTEGRATION_STATUS = 'CheckIntegrationStatus',
	INTEGRATION_COMPLETED = 'IntegrationCompleted',
}
