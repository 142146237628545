import { AnalysisTargetType, QualitativeOrQuantitative } from '@models';
import { AnalysisTechnology } from '@services';
import { Type } from 'class-transformer';
import { NmrAnalysisStage } from './nmr-analysis-stage.enum';
import { NMRAnalysisResultMatchType } from './nmr-analysis-result.class';
import { NmrAnalysisResultStatus } from './nmr-analysis-result-status.enum';

export class NmrAnalysis {
	id: number;
	title: string;
	projectId: string;
	stage: NmrAnalysisStage;
	status: NmrAnalysisResultStatus | NMRAnalysisResultMatchType;
	statusIcon?: NmrAnalysisResultStatus;
	operatorName: string;
	operatorId?: string;
	comment: string;
	frequency: Maybe<string>;
	measurementType: QualitativeOrQuantitative;
	targetType: AnalysisTargetType;
	technology: AnalysisTechnology;
	type: string;
	matchFound: boolean;

	@Type(() => Date)
	startDate: Date;

	@Type(() => Date)
	measurementDate: Date;

	@Type(() => Date)
	lastUpdateTime: Date;

	@Type(() => Date)
	lastActivityDate: Date;

	@Type(() => Date)
	completedTime: Date;

	substances: string;
	solvent: string;
	measurementDeviceBrand: string;
	probhd: string | undefined;
	details: string;
	measurementId: string;
	calibrationName: string;
	productAndBatchNumber: string;
	measurementExecutorId: string;
	molecularWeight: number;
	sampleWeight: number;
	solventWeight: number;
	receiverGain: number | null | undefined;
	secondApproval: boolean;
	deviceCalibrationId: number;

	isExecuting() {
		return this.stage === NmrAnalysisStage.EXECUTING;
	}

	isExecuted() {
		return this.stage === NmrAnalysisStage.EXECUTED;
	}

	public isRejectedByAdmin(): boolean {
		return this.stage === NmrAnalysisStage.REJECTED_BY_LAB_ADMIN;
	}

	public isRejectedByMember(): boolean {
		return this.stage === NmrAnalysisStage.REJECTED_BY_LAB_MEMBER;
	}

	public isAnalysisRejected(): boolean {
		return this.isRejectedByAdmin() || this.isRejectedByMember();
	}

	public isUnsuccessful(): boolean {
		return this.status === NmrAnalysisResultStatus.UNSATISFIED || this.status === NmrAnalysisResultStatus.TECHNICAL_ERROR;
	}

	public isSuccessful(): boolean {
		return this.status === NmrAnalysisResultStatus.SATISFIED || this.status === NmrAnalysisResultStatus.QUESTIONABLE;
	}

	public isQuestionablySuccessful(): boolean {
		return this.status === NmrAnalysisResultStatus.QUESTIONABLE;
	}

	public isCompletedNoMatch(): boolean {
		return this.status === NmrAnalysisResultStatus.UNSATISFIED;
	}

	public isErrorInAnalysis(): boolean {
		return this.status === NmrAnalysisResultStatus.TECHNICAL_ERROR;
	}
}

export interface IReportImageModel {
	casNumber: string;
	moleculeImage: string;
	spectrumSeperated: string;
	referenceMaterialSpectrum: string;
}

export interface IAnalysisReportImages {
	substanceImages: Partial<IReportImageModel>[];
	id?: number;
}
