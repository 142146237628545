import { FormikSubmitButton, FormikTextfield, ModalDialog } from '@components/common';
import { Button, Stack, Typography } from '@mui/material';
import { getAddToFavoritesValidationSchema } from '@schemas';
import { favoriteReferencesService, notificationService } from '@services';
import { formFieldSpacer, RxUtils, Tr } from '@utils';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type FormFields = Extendable<{ name: string }>;

export interface IAddFavoriteDialogProps {
	drmCode: string;
	onCloseDialog: () => void;
	onSuccess: () => void;
}

export const NmrAddFavoriteDialog: FC<IAddFavoriteDialogProps> = ({ drmCode, onSuccess, onCloseDialog }) => {
	const { t } = useTranslation('portal');

	const formik = useFormik<FormFields>({
		initialValues: { name: '' },
		validationSchema: getAddToFavoritesValidationSchema(t),
		onSubmit: ({ name }) => {
			RxUtils.promisify(
				favoriteReferencesService.create({
					name: name,
					drmCode: drmCode,
				}),
				() => {
					notificationService.sendSuccess(t('favourites.successfully-saved'));
					onSuccess();
				},
			);
		},
	});

	const closeDialog = () => {
		onCloseDialog?.();
	};

	return (
		<>
			<ModalDialog
				maxWidth="xs"
				open={true}
				variant="secondary"
				onClose={closeDialog}
				title={<Typography variant="pg-s">{t('favourites.save')}</Typography>}
				contentProps={{ sx: { paddingX: 8 } }}
			>
				<Stack data-testid="save-as-favorite-dialog-id" direction="column" sx={{ mt: 3 }}>
					<Typography variant="h3" textAlign="center" sx={{ mb: 3 }}>
						<Tr.Portal path="favourites.save-to-favorites-title" />
					</Typography>
					<form onSubmit={formik.handleSubmit}>
						<Stack direction="column" rowGap={formFieldSpacer} justifyContent="space-between">
							<FormikTextfield
								formikApi={formik}
								name="name"
								title={t('favourites.favorite-name-title')}
								placeholder={t('favourites.favorite-name-placeholder')}
								variant="outlined"
								fullWidth
								containerProps={{ sx: { flexGrow: 1 } }}
								inputProps={{ 'data-testid': 'favorite-name-id' }}
							/>

							<Stack direction="row" justifyContent="flex-end" columnGap={3} sx={{ mb: 7 }}>
								<Button variant="outlined" data-testid="cancel-button-id" disableElevation fullWidth onClick={closeDialog}>
									<Tr.Portal path="erm-request.cancel" />
								</Button>
								<FormikSubmitButton
									disabled={!formik.isValid || !formik.dirty}
									fullWidth
									formik={formik}
									data-testid="save-favorite-button-id"
								>
									<Tr.Portal path="favourites.save" />
								</FormikSubmitButton>
							</Stack>
						</Stack>
					</form>
				</Stack>
			</ModalDialog>
		</>
	);
};
