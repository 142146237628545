import { FormikApiType, FormikTextfield, GenericCard } from '@components/common';
import { Grid, IconButton, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import { formFieldSpacer, innerTitleSpacer, mainTitleSpacer } from '@utils/Theme';
import { nmrAnalysisService } from '@services/nmr';
import { RxUtils } from '@utils/Rx';
import { getCompoundValidation } from '@schemas';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';

export interface ICompound {
	id: number;
	name: string;
	molecularWeight: number;
}

interface ICompoundFormValues {
	compounds: {
		name: string;
		molecularWeight: number | string;
		id: number;
	}[];
}

interface ICompoundFormProps {
	compounds: ICompound[];
	handleAddCompound: () => void;
	handleDeleteCompound: (compound) => void;
	setCompounds: (compounds) => void;
	nmrFormik: FormikApiType;
}

export const CompoundForm: FC<ICompoundFormProps> = ({ compounds, handleAddCompound, handleDeleteCompound, setCompounds, nmrFormik }) => {
	const { t } = useTranslation('portal');
	const newCompoundInputRef = useRef<HTMLInputElement | null>(null);
	const { id: analysisId = '' } = useParams<{ id: string }>();
	const [initialData, setInitialData] = useState<ICompoundFormValues>({
		compounds: compounds.map((compound) => ({
			name: compound.name || '',
			molecularWeight: compound.molecularWeight || '',
			id: compound.id,
		})),
	});
	const [formData, setFormData] = useState<ICompoundFormValues>(initialData);
	const [loading, setLoading] = useState<boolean>(false);
	const [debouncedFormData, setDebouncedFormData] = useState(formData);

	const formik = useFormik<ICompoundFormValues>({
		enableReinitialize: true,
		initialValues: {
			compounds: compounds.map((compound) => ({
				name: compound.name || '',
				molecularWeight: compound.molecularWeight || '',
				id: compound.id,
			})),
		},
		validationSchema: getCompoundValidation(t),
		onSubmit: () => {
			//
		},
		validateOnChange: true,
	});

	useEffect(() => {
		setFormData(formik.values);
	}, [formik.values]);

	useEffect(() => {
		nmrFormik.setErrors(formik.errors);
	}, [formik.errors, nmrFormik]);

	useEffect(() => {
		const initalCompoundState = compounds.map((compound) => ({
			name: compound.name || '',
			molecularWeight: compound.molecularWeight || '',
			id: compound.id,
		}));
		setInitialData({ compounds: initalCompoundState });
	}, [compounds]);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedFormData(formData);
		}, 1000);

		return () => {
			clearTimeout(handler);
		};
	}, [formData]);

	const hasChanges = (initial: ICompoundFormValues, current: ICompoundFormValues) => {
		const initialCompoundsMap = new Map(initial.compounds.map(({ id, name, molecularWeight }) => [id, { name, molecularWeight }]));

		return current.compounds.filter(({ id, name, molecularWeight }) => {
			const initialCompound = initialCompoundsMap.get(id);
			return initialCompound && (initialCompound.name !== name || initialCompound.molecularWeight !== molecularWeight);
		});
	};

	useEffect(() => {
		const changedCompounds = hasChanges(initialData, debouncedFormData);
		if (changedCompounds.length > 0) {
			setLoading(true);
			changedCompounds.forEach((compound) => {
				if (compound.name) {
					RxUtils.promisify(
						nmrAnalysisService.updateCompound(+analysisId, {
							id: compound.id,
							name: compound.name,
							molecularWeight: compound.molecularWeight,
						}),
						() => {
							const updatedCompounds = compounds.map((existingCompound) =>
								compound.id === existingCompound.id
									? { id: compound.id, name: compound.name, molecularWeight: compound.molecularWeight }
									: existingCompound,
							);
							setCompounds(updatedCompounds);
						},
						(err) => {
							console.error('API call failed', err);
						},
					);
				}
			});
		}
		setTimeout(() => setLoading(false), 1000);
	}, [debouncedFormData]);

	const handleAddCompoundWithFocus = () => {
		formik.validateForm().then((errors) => {
			if (Object.keys(errors).length === 0) {
				handleAddCompound();
			} else {
				formik.setTouched({
					compounds: formik.values.compounds.map(() => ({ name: true, molecularWeight: true })),
				});
				setTimeout(() => {
					if (newCompoundInputRef.current) {
						newCompoundInputRef.current.focus();
					}
				}, 0);
			}
		});
	};

	return (
		<GenericCard sx={{ marginTop: 4 }}>
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: mainTitleSpacer }}>
				<Typography variant="h3" color="primary.main">
					<Tr.Portal path="new-analysis.step-2-add-compound" />
				</Typography>

				<IconButton
					disabled={compounds.length >= 10 || !formik.isValid || loading}
					onClick={handleAddCompoundWithFocus}
					aria-label="add"
				>
					<Add sx={{ fontSize: '20px' }} />
					<Typography
						variant="subtitle2"
						sx={{ paddingBottom: 0.2, paddingLeft: 0.5 }}
						color={compounds.length >= 10 || !formik.isValid || loading ? 'grey.400' : 'primary.main'}
					>
						<Tr.Portal path="new-analysis.add-compound" />
					</Typography>
				</IconButton>
			</Stack>

			{compounds && compounds.length > 0 && compounds[0].id !== 0 ? (
				<Stack>
					{compounds.map((compound, index) => (
						<Stack key={compound.id}>
							{index === 0 && (
								<Typography
									sx={{
										color: 'grey.500',
										fontWeight: 400,
										fontSize: 14,
										alignSelf: 'flex-end',
										marginBottom: compounds.length > 1 ? 2 : 0,
									}}
								>
									{t('new-analysis.compound-min-message')}
								</Typography>
							)}
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								sx={{ marginBottom: innerTitleSpacer }}
							>
								<Stack direction="row" alignItems="center" justifyContent="space-between" flexDirection="row-reverse">
									<Typography variant="h4">
										<Tr.Portal path={`Compound ${index + 1}`} />
									</Typography>
								</Stack>
								{compounds.length > 1 && (
									<IconButton sx={{ padding: 0 }} onClick={() => handleDeleteCompound(compound)} aria-label="delete">
										<Delete width={20} height={20} fill="#5E5E72" />
									</IconButton>
								)}
							</Stack>
							<Grid container flexWrap="nowrap" spacing={formFieldSpacer} sx={{ marginBottom: formFieldSpacer }}>
								<Grid item xs={12}>
									<FormikTextfield
										formikApi={formik as any} // Using 'any' to bypass type issues for now
										name={`compounds[${index}].name`}
										title={<span>{t('new-analysis.compound-name')}</span>}
										placeholder={t('new-analysis.compound-name-placeholder')}
										variant="outlined"
										typographyProps={{ sx: { marginBottom: 0.75 } }}
										required
										inputRef={index === compounds.length - 1 ? newCompoundInputRef : null}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormikTextfield
										formikApi={formik as any} // Using 'any' to bypass type issues for now
										name={`compounds[${index}].molecularWeight`}
										title={
											<Stack direction="row" justifyContent="space-between" padding="0" margin="0">
												<>{t('new-analysis.moleculer-weight-in-g-mol')}</>
												<Tooltip placement="top" arrow title={t('new-analysis.compound-warning')}>
													<InfoOutlined color="action" fontSize="small" />
												</Tooltip>
											</Stack>
										}
										placeholder={t('new-analysis.molecular-weight-placeholder')}
										typographyProps={{ sx: { marginBottom: 0.45 } }}
										variant="outlined"
										type="number"
										onKeyDown={(e) => {
											if (e.key === '-') e.preventDefault();
										}}
									/>
								</Grid>
							</Grid>
						</Stack>
					))}
				</Stack>
			) : (
				<Stack sx={{ height: '24px', justifyContent: 'center' }}>
					<LinearProgress />
				</Stack>
			)}
		</GenericCard>
	);
};
