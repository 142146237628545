import { FC } from 'react';
import { Badge, BadgeProps } from '@mui/material';

export const PortalBadge: FC<BadgeProps> = ({ sx, ...props }) => (
	<Badge
		data-testid="portal-badge-id"
		sx={{
			' .MuiBadge-badge': {
				borderRadius: 1,
				fontWeight: 700,
				fontSize: '0.875rem',
				position: 'relative',
				transform: 'none',
				height: '1.5rem',
			},
			...sx,
		}}
		{...props}
	/>
);
