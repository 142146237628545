import { FormikApiType, TablePagination } from '@components/common';
import { NmrDRMDetail, NmrSubstanceList } from '@components/nmr-portal';
import { AddFromFavoritesButton } from '@components/nmr-portal/NmrFavorites';
import { useDRMDetail } from '@hooks';
import { AnalysisTargetType, QualitativeOrQuantitative } from '@models';
import { Divider, Stack, Typography } from '@mui/material';
import { NmrAnalysisSubstanceListItem, NmrDrmDetail, NmrSubstanceListItem } from '@services';
import { Tr, TypeUtils, cardSpacer } from '@utils';
import { FC, createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ADDED_SUBSTANCE_PAGE_SIZE = 3;

interface IAnalysisFavorite {
	addedSubstances: NmrAnalysisSubstanceListItem[] | undefined;
	onRemoveSubstance: (substanceId: number, isSubstance: boolean) => void;
	onAddReferenceMaterial: (id: number) => void;
	onRefreshSubstanceList: () => void;
	formik: FormikApiType;
	actionsDisabled: boolean;
}
export const NmrAnalysisFavorite: FC<IAnalysisFavorite> = ({
	addedSubstances,
	onRemoveSubstance,
	onAddReferenceMaterial,
	formik,
	onRefreshSubstanceList,
	actionsDisabled,
}) => {
	const [substancesToAddFavorite, setSubstancesToAddFavorite] = useState<number[]>([]);
	const [addedSubstancePageIndex, setAddedSubstancePageIndex] = useState(1);
	const [addedSubstancePageCount, setAddedSubstancePageCount] = useState<number>();
	const [displayedAddedSubstanceList, setDisplayedAddedSubstanceList] = useState<NmrAnalysisSubstanceListItem[]>([]);

	const { resetDetail: resetDrmDetail, setDRMDetail, ...drmProps } = useDRMDetail();
	const { t } = useTranslation('portal');

	const onRemoveAddedSubstance = (substanceId: number, isSubstance: boolean) => {
		resetDrmDetail();
		addedSubstances &&
			displayedAddedSubstanceList.length === 1 &&
			addedSubstancePageIndex !== 1 &&
			setAddedSubstancePageIndex(addedSubstancePageIndex - 1);

		onRemoveSubstance(substanceId, isSubstance);
	};

	const onFavoriteSelected = (drmId: number) => {
		onAddReferenceMaterial(drmId);
	};

	useEffect(() => {
		if (addedSubstances?.length) {
			setAddedSubstancePageCount(Math.ceil(addedSubstances?.length / ADDED_SUBSTANCE_PAGE_SIZE));
			formik?.setFieldValue('targetType', AnalysisTargetType.TARGETED);
		} else {
			setAddedSubstancePageCount(0);

			formik?.values.measurementType === QualitativeOrQuantitative.QUALITATIVE &&
				formik?.setFieldValue('targetType', AnalysisTargetType.NON_TARGETED);
		}

		setDisplayedAddedSubstanceList(
			addedSubstances?.slice(
				(addedSubstancePageIndex - 1) * ADDED_SUBSTANCE_PAGE_SIZE,
				addedSubstancePageIndex * ADDED_SUBSTANCE_PAGE_SIZE,
			) || [],
		);
	}, [addedSubstances, addedSubstancePageIndex]);

	const drmDetailRef = createRef<HTMLDivElement>();

	return (
		<Stack data-testid="analysis-favorite">
			<Stack direction="row" justifyContent="space-between">
				<Stack>
					<Typography variant="h3" color="primary.main">
						<Tr.Portal path="new-analysis.add-reference" />
					</Typography>
				</Stack>
				<Stack direction="row" sx={{ marginLeft: 'auto' }}>
					<AddFromFavoritesButton
						measurementType={formik?.values.measurementType}
						onFavoriteSelect={onFavoriteSelected}
						disabled={actionsDisabled}
						addedSubstances={addedSubstances}
					/>
				</Stack>
			</Stack>

			{formik?.values.measurementType === QualitativeOrQuantitative.QUALITATIVE && (
				<Stack sx={{ width: '70%', marginTop: 1, marginBottom: 4 }}>
					<Typography variant="pg-m">
						<Tr.Portal path="new-analysis.add-reference-description" />
					</Typography>
				</Stack>
			)}

			{displayedAddedSubstanceList?.length ? (
				<>
					<Stack data-testid="drm-list-wrapper-id" direction="column" sx={{ mt: 1 }}>
						<Stack direction="row" alignItems="center">
							<Typography variant="label-m">
								<Tr.Portal path="new-analysis.your-references" />
							</Typography>
							{addedSubstancePageCount && addedSubstancePageCount > 1 && (
								<TablePagination
									sx={{ marginLeft: 'auto', marginY: cardSpacer }}
									page={addedSubstancePageIndex}
									count={addedSubstancePageCount}
									onChange={(value) => setAddedSubstancePageIndex(+value)}
								/>
							)}
						</Stack>
						<NmrSubstanceList
							substanceList={displayedAddedSubstanceList as NmrSubstanceListItem[]}
							referenceMaterialAccessor="selectedReferenceMaterials"
							type="remove"
							actionLabel={t('drm-detail.remove-from-analysis')}
							substancesToAddFavorite={substancesToAddFavorite}
							onCloseFavoriteDialog={() => setSubstancesToAddFavorite([])}
							onSubstanceRemove={(drmId) => {
								onRemoveAddedSubstance(drmId, false);
							}}
							onRefreshSubstanceList={onRefreshSubstanceList}
							onDrmDetail={(drmVisibility, detail) => {
								const selectedItemId = detail.id;
								const selectedDrm = TypeUtils.transform(NmrDrmDetail, detail);
								if (selectedItemId) {
									setDRMDetail({
										actionLabel: t('drm-detail.remove-from-analysis'),
										actionBtnSx: { marginTop: 'auto' },
										drmId: selectedItemId,
										onHandleAction: (_) => onRemoveAddedSubstance(selectedItemId, false),
										visible: drmVisibility,
										isTheoretical: selectedDrm?.isTheoretical(),
									});
								}
							}}
							itemSx={{ padding: '1rem 0' }}
							dataTestId="substance-added-drms-wrapper"
							disabled={actionsDisabled}
						/>
						<Divider sx={{ mb: 2 }} />
					</Stack>
				</>
			) : null}
			{useMemo(
				() => (
					<NmrDRMDetail onCloseClick={() => resetDrmDetail()} ref={drmDetailRef} {...drmProps} />
				),
				[drmProps],
			)}
		</Stack>
	);
};
