import { FC, ReactNode, useRef } from 'react';
import { Typography, TypographyProps, Box } from '@mui/material';

export interface IOverflowTextMaxLinesProps extends TypographyProps {
	children: NonNullable<ReactNode>;
}

interface IOverflowTextMaxAdditionalProps {
	maxHeight: string;
	backgroundColor: string;
}

export const OverflowTextMaxLines: FC<IOverflowTextMaxLinesProps & IOverflowTextMaxAdditionalProps> = ({
	children,
	maxHeight,
	backgroundColor = 'white',
	...props
}) => {
	const textElementRef = useRef<HTMLElement>(null);
	const spanElementRef = useRef<HTMLDivElement>(null);

	return (
		<Typography
			ref={textElementRef}
			{...props}
			sx={{
				...props.sx,
				display: 'block',
				textOverflow: 'ellipsis',
				wordWrap: 'break-word',
				overflow: 'hidden',
				maxHeight: maxHeight,
				position: 'relative',
			}}
		>
			{<div ref={spanElementRef}>{children}</div>}
			<Box
				visibility={
					Number(Math.floor(spanElementRef?.current?.offsetHeight ?? 0)) >
					Number(Math.floor(textElementRef?.current?.offsetHeight ?? 0))
						? 'visible'
						: 'hidden'
				}
				sx={{ backgroundColor: backgroundColor, position: 'absolute', bottom: 0, right: 0, paddingLeft: '1px' }}
			>
				...
			</Box>
		</Typography>
	);
};
