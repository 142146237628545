export enum IrDrmWaveStatus {
	READY = 'Ready',
	RELEASED = 'Released',
	RELEASING = 'Releasing',
	PROCESSING = 'Processing',
	UPLOAD_FAILED = 'UploadFailed',
	RELEASE_FAILED = 'ReleaseFailed',
	UPLOAD_COMPLETED = 'UploadCompleted',
}

export enum IrWaveReportType {
	UPLOAD_PDF = 'UploadPdf',
	UPLOAD_CSV = 'UploadCsv',
	RELEASE_PDF = 'ReleasePdf',
	RELEASE_CSV = 'ReleaseCsv',
}

export enum IrWaveReportExtension {
	CSV = 'csv',
	PDF = 'pdf',
}
