import { FC, ReactNode } from 'react';
import { Tr } from '@utils/Translation';
import { ListCard } from '@components/common';
import { DataTestId } from '@utils/DataTestId';
import { NmrDrmOrder, NmrDrmOrderStatus } from '@services/nmr-drm-pipeline';
import { Stack, SxProps } from '@mui/material';
import { ReferenceTechnique } from '@services/core/substance/technique.enum';
import { useTranslation } from 'react-i18next';
import { useHasPermissions } from '@hooks';
import { NmrAdminPermission } from '@models/user';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@models/router';
import { CardContent } from './DigitalReferenceCard';

type TDrmOrderStatusChipOptions = {
	sx?: SxProps;
	chipText: ReactNode;
};

const IN_STEP_SX = {
	backgroundColor: 'warning.main',
	color: 'text.primary',
};

const DECLINED_SX = {
	backgroundColor: 'grey.100',
	color: 'grey.500',
};

const DrmOrderStatusChipMapping: Record<NmrDrmOrderStatus, TDrmOrderStatusChipOptions> = {
	[NmrDrmOrderStatus.DRAFT]: {
		sx: IN_STEP_SX,
		chipText: <Tr.Admin path="drm-order.in-planning" />,
	},
	[NmrDrmOrderStatus.READY]: {
		sx: IN_STEP_SX,
		chipText: <Tr.Admin path="drm-order.in-planning" />,
	},
	[NmrDrmOrderStatus.IN_PRODUCTION]: {
		sx: IN_STEP_SX,
		chipText: <Tr.Admin path="drm-order.in-progress" />,
	},
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: {
		sx: IN_STEP_SX,
		chipText: <Tr.Admin path="drm-order.in-progress" />,
	},
	[NmrDrmOrderStatus.QC_INSPECTION]: {
		sx: IN_STEP_SX,
		chipText: <Tr.Admin path="drm-order.in-progress" />,
	},
	[NmrDrmOrderStatus.QA_REVIEW]: {
		sx: IN_STEP_SX,
		chipText: <Tr.Admin path="drm-order.in-progress" />,
	},
	[NmrDrmOrderStatus.DECLINED]: {
		sx: DECLINED_SX,
		chipText: <Tr.Admin path="drm-order.declined" />,
	},
	[NmrDrmOrderStatus.RELEASED]: {
		sx: IN_STEP_SX,
		chipText: <Tr.Portal path="drm-order.new" />,
	},
};

export const DigitalReferenceOrderCard: FC<{ dRMOrder: NmrDrmOrder }> = ({ dRMOrder }) => {
	const { status, priority, physicalProductNumber, id } = dRMOrder;
	const { t: translate } = useTranslation('admin');

	const chipOptions = DrmOrderStatusChipMapping[dRMOrder.status];
	const hasNmrDrmOrderPermission = useHasPermissions(NmrAdminPermission.VIEW_DRM_PIPELINE);
	const navigate = useNavigate();
	const onDrmOrderClick = () => navigate(`/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.DRM_ORDER_DETAILS}/${id}`);
	const isActive = dRMOrder.status !== NmrDrmOrderStatus.DECLINED && hasNmrDrmOrderPermission;
	return (
		<ListCard
			isActive={isActive}
			title={physicalProductNumber}
			technique={ReferenceTechnique.NMR_REFERENCE_MATERIAL}
			chipText={<>{chipOptions?.chipText}</>}
			chipProps={{
				sx: hasNmrDrmOrderPermission ? chipOptions?.sx : DECLINED_SX,
			}}
			isLocked={!hasNmrDrmOrderPermission}
			lockedText={translate('substance-detail.drm-order-no-access')}
			onClick={onDrmOrderClick}
		>
			<Stack direction="row" width={1} data-testid={DataTestId.getStaticTestId('drm-order-card-id')}>
				<Stack direction="column" width={'33%'}>
					<CardContent
						value={priority || '-'}
						isActive={isActive}
						title={<Tr.Common path="priority.name" />}
						overflowTextProps={{
							'data-testid': 'drm-order-priority-id',
						}}
					/>
				</Stack>

				<Stack direction="column" width={'33%'}>
					<CardContent
						value={translate(status) || '-'}
						isActive={isActive}
						title={<Tr.Common path="status" />}
						disableDivider
						overflowTextProps={{
							'data-testid': 'drm-order-status-id',
						}}
					/>
				</Stack>
			</Stack>
		</ListCard>
	);
};
