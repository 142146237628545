import './polyfills';
import { createRoot } from 'react-dom/client';
import { CustomThemeProvider, history, StoreProvider, TranslationProvider } from '@utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { App } from './App';
import '@aws-amplify/ui-react/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import { ToastContainer } from 'react-toastify';
import { RouteProvider } from '@utils/RouteProvider';

let container: Maybe<HTMLElement> = null;

document.addEventListener('DOMContentLoaded', function (_) {
	if (!container) {
		container = document.getElementById('root') as HTMLElement;
		const root = createRoot(container);
		root.render(
			<RouteProvider history={history}>
				<StoreProvider>
					<CustomThemeProvider>
						<TranslationProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns as any}>
								<ToastContainer hideProgressBar autoClose={5000} limit={5} style={{ width: '23.75rem' }} />
								<App />
							</LocalizationProvider>
						</TranslationProvider>
					</CustomThemeProvider>
				</StoreProvider>
			</RouteProvider>,
		);
	}
});
