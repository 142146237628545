import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import SearchIcon from '@mui/icons-material/Search';
import { PipelineAddDrmOrderModal } from '../AddDrmOrder/PipelineAddDrmOrderModal';
export const EmptyBacklog = () => {
	return (
		<Stack width={1} sx={{ justifyContent: 'center', alignItems: 'center', paddingY: 7 }}>
			<Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
				<Stack
					sx={{
						width: 80,
						height: 80,
						borderRadius: '50%',
						backgroundColor: 'grey.100',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: 3,
					}}
				>
					<SearchIcon sx={{ width: 40, height: 40, color: 'grey.400' }} />
				</Stack>
				<Typography variant="h3" color="grey.800" marginBottom={1}>
					<Tr.Admin path="drm-pipeline.nmr.backlog.no-drm-orders" />
				</Typography>
				<Typography variant="pg-m" color="grey.800">
					<Tr.Admin path="drm-pipeline.nmr.backlog.no-drm-orders-explanation" />
				</Typography>
				<Stack paddingTop={2}>
					<PipelineAddDrmOrderModal
						buttonSx={{
							border: '2px solid #01884C',
							borderRadius: 1,
						}}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};
