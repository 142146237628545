import { Type } from 'class-transformer';
export class NmrLibrary {
	count: number;
	libraryName: string;
	libraryId: number;

	isDefault?: boolean;
	isUserLibrary?: boolean;

	@Type(() => Date)
	createTime: Date;
}

export class NmrAddLibrary {
	libraryName: string;
}
