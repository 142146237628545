import { useHasPermissions } from '@hooks/common/useHasPermissions';
import { LabPermission } from '@models/user';
import { Divider, Stack, SxProps, Typography } from '@mui/material';
import { Lab } from '@services/core';
import { labService } from '@services/core/lab/lab.service';
import { RxUtils } from '@utils/Rx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericCard } from '../GenericCard';
import { LabSettingsSwitch } from './LabSettingsSwitch';
interface ILabInfoTitleProps {
	title: string;
	sx?: SxProps;
}

interface ILabInformationDetailProps {
	labData?: Lab;
	setLabData: (value: Lab) => void;
}
export const LabInformationDetail: FC<ILabInformationDetailProps> = ({ labData, setLabData }) => {
	const { t } = useTranslation('common');

	const canSeeLabFeatureToggles = useHasPermissions(LabPermission.VIEW_SETTINGS);
	const canEditLabFeatureToggles = useHasPermissions(LabPermission.EDIT_SETTINGS);

	const LabInfoTitle: FC<ILabInfoTitleProps> = ({ title, sx }) => {
		return (
			<Typography variant="label-m" color="grey.800" sx={{ paddingBottom: 1, ...sx }}>
				{title}
			</Typography>
		);
	};
	const LabInfoDivider = () => {
		return <Divider orientation="vertical" sx={{ color: 'red' }} flexItem />;
	};

	const handleSettingStatusChange = (settingId: number, value: string) => {
		if (labData?.id) {
			RxUtils.promisify(
				labService.setSetting(+labData.id, +settingId, {
					value: value,
				}),
				() => {
					const updatedSettings = labData.settings.map((setting) => {
						if (setting.id === settingId) {
							return { ...setting, value: value };
						}
						return setting;
					});
					setLabData({ ...labData, settings: updatedSettings });
				},
			);
		}
	};

	return (
		<Stack>
			<GenericCard>
				<Stack direction="row" spacing={3}>
					<Stack direction="column" justifyContent="space-between">
						<LabInfoTitle title={t('lab-details.status')} />
						<Typography variant="pg-m" color={labData?.isActive ? 'primary.main' : 'grey.800'}>
							{labData?.isActive ? t('lab-details.active') : t('lab-details.inactive')}
						</Typography>
					</Stack>
					<LabInfoDivider />
					<Stack direction="column" justifyContent="space-between">
						<LabInfoTitle title={t('lab-details.technology')} />
						<Typography variant="pg-m" color={labData?.isActive ? 'text.primary' : 'grey.800'}>
							{labData?.technology}
						</Typography>
					</Stack>
					<LabInfoDivider />

					<Stack direction="column" justifyContent="space-between">
						<LabInfoTitle title={t('lab-details.admins')} />
						<Typography variant="pg-m" color={labData?.isActive ? 'text.primary' : 'grey.800'}>
							{labData?.numberOfAdmins}
						</Typography>
					</Stack>
					{canSeeLabFeatureToggles && (
						<Stack direction="row" spacing={3}>
							<>
								{labData?.settings
									.filter((setting) => setting.type === 'bool')
									.map((setting) => (
										<Stack direction="row" spacing={3} key={setting.id}>
											<LabInfoDivider />
											<Stack direction="column">
												<LabInfoTitle title={t(`lab-details.${setting.name.toLowerCase().replace(' ', '-')}`)} />
												<LabSettingsSwitch
													isDisabled={!labData?.isActive || !canEditLabFeatureToggles}
													status={setting.value === 'true'}
													onStatusChange={(status) => handleSettingStatusChange(setting.id, `${status}`)}
													tooltipTitle={t(
														`lab-details.${setting.name.toLowerCase().replace(' ', '-')}-change-warning`,
													)}
													isLabDeactive={!labData?.isActive}
												/>
											</Stack>
										</Stack>
									))}
							</>
						</Stack>
					)}
				</Stack>
			</GenericCard>
		</Stack>
	);
};
