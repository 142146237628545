import { UserRole } from '@models/user';

export class Lab {
	id: number;
	isActive: boolean;
	name: string;
	technology: string;
	adminName: string;
	adminMail: string;
	userCount: number;
	numberOfAdmins?: number;
	organizationName?: string;
	settings: LabSetting[];
}

export class LabSetting {
	id: number;
	settingId?: number;
	laboratoryId: number;
	name: string;
	type: string;
	value: string;
}

export class CreateLabModal {
	name: string;
	technology: string;
	adminEmail: string;
}

export class AddUserToLabModal {
	email: string;
	role: UserRole[];
}

export const isSecondApprovalEnabled = (lab: Lab) => {
	return lab.settings.find((s) => s.name === 'Second Approval')?.value === 'true';
};
export const isTheoreticalSpectraEnabled = (lab: Lab) => {
	return lab.settings.find((s) => s.name === 'Theoretical Spectra')?.value === 'true';
};
export const isTransmissionSpectraEnabled = (lab: Lab) => {
	return lab.settings.find((s) => s.name === 'Transmission Spectra')?.value === 'true';
};
