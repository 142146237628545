import { HubConnection } from '@microsoft/signalr';
import { config } from '@config';
import { notificationService } from '@services/core';
import { ReferenceMaterialHubMethods } from '@services/ir';
import { useNavigate } from 'react-router-dom';
import { PortalPageRoutes } from '@models/router';
import { useTranslation } from 'react-i18next';
import { useSignalRHubConnection } from '@hooks';

export enum Status {
	success = 'success',
	failed = 'failed',
}
interface StatusInfo {
	translation: string;
	action: string;
}

export const ReferenceStatusMessageMapping: Partial<Record<ReferenceMaterialHubMethods, StatusInfo>> = {
	CreateProcessSucceeded: { translation: 'created-successfully', action: Status.success },
	CreateProcessFailed: { translation: 'created-failed-msg', action: Status.failed },
	UpdateProcessSucceeded: { translation: 'updated-successfully', action: Status.success },
	UpdateProcessFailed: { translation: 'updated-failed-msg', action: Status.failed },
	ReactivationProcessSucceeded: { translation: 'reactivated-successfully', action: Status.success },
	ReactivationProcessFailed: { translation: 'reactivated-failed', action: Status.failed },
	DeactivationProcessSucceeded: { translation: 'deactivated-successfully', action: Status.success },
	DeactivationProcessFailed: { translation: 'deactivated-failed', action: Status.failed },
};

export const useIrCustomReferenceHub = (startConnection: boolean, deps, referenceMateriaId: number) => {
	const navigate = useNavigate();
	const { t } = useTranslation('irportal');

	useSignalRHubConnection({
		hubUrl: config.customReferenceHubUrl,
		body: (connection: HubConnection) => {
			connection.send(ReferenceMaterialHubMethods.CHECK_CUSTOM_REFERENCE_MATERIAL_STATUS, referenceMateriaId);
			connection.on(ReferenceMaterialHubMethods.PROCESS_COMPLETED, (name: ReferenceMaterialHubMethods) => {
				const data = ReferenceStatusMessageMapping[`${name}`];
				if (data?.action === Status.success) {
					notificationService.sendSuccess(t(`user-references.${data?.translation}`));
				} else if (data?.action === Status.failed) {
					notificationService.sendError(t(`user-references.${data?.translation}`));
				}
				navigate(
					`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
				);
			});
		},
		shouldStartConnection: () => startConnection,
		deps: [deps],
	});
};
