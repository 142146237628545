import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CalibrationChip } from '../DeviceForm/CalibrationChip';
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { GenericCard, OverflowText } from '@components/common';
import { notificationService, nmrDeviceService } from '@services';
import { greaterThanMaxFiles, mainTitleSpacer, Tr } from '@utils';
import { DeviceSpecification } from '../NmrDeviceSpecification';
import { useService } from '@hooks';
import { useDeviceFormik, DeviceFormType, useDeviceFileHook, onCalibrateDevice, DeviceFileUpload, unknownToFile } from '../DeviceForm';
import { useDeviceOutlet } from '@hooks/nmr/useDeviceOutlet';

export const NmrRecalibrateDevice = () => {
	const { deviceData, setIsCalibrationProgress, refreshDeviceData } = useDeviceOutlet();
	const [rawFiles, setRawFiles] = useState<File[]>([]);

	const navigate = useNavigate();
	const { calibrationId } = useParams();
	const { t } = useTranslation('portal');

	const { data: deviceNamesResponse } = useService(() => nmrDeviceService.getDeviceNames());

	const formik = useDeviceFormik({
		deviceData,
		deviceNamesResponse,
		formType: DeviceFormType.RECALIBRATE,
		onSubmit: () => {
			notificationService.clear();
			setTimeout(() => {
				setIsCalibrationProgress(true);
			}, 50);
			onCalibrateDevice({
				files,
				successCb: () => {
					setIsCalibrationProgress(false);
					navigate(`../`);
					refreshDeviceData?.();
				},
				finalCb: () => {
					setIsCalibrationProgress(false);
					formik.resetForm();
				},
				formik,
				deviceId: deviceData?.id,
				calibrationId: calibrationId ? Number(calibrationId) : undefined,
				receiverGainRange: receiverGainRange,
			});
		},
	});
	const { files, removeFile, canCalibrate, outOfRangeFiles, setOutOfRangeFiles } = useDeviceFileHook({
		newFile: rawFiles,
		formik,
		deviceData,
		deviceNamesResponse,
	});

	const { data: receiverGainRange } = useService(() => nmrDeviceService.receiverGainThreshold(), []);
	const hide = greaterThanMaxFiles(files);

	return (
		<Stack height={'100%'}>
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: mainTitleSpacer, width: '100%' }}>
				<OverflowText
					enableTooltip={true}
					variant="h1"
					sx={{ color: 'text.secondary', width: '100%', maxWidth: 'calc(100% - 25rem)' }}
				>
					{deviceData?.calibrationData?.name ?? <Tr.Portal path="device-management.device-details" />}
				</OverflowText>
				<Stack direction="row" alignSelf="flex-end">
					<Button
						data-testid="add-device-cancel-button-id"
						variant="outlined"
						sx={{
							marginRight: '1rem',
						}}
						onClick={() => {
							navigate('../');
						}}
					>
						<Tr.Portal path="device-management.cancel" />
					</Button>
					<Tooltip
						disableHoverListener={canCalibrate && formik.isValid}
						data-testid="add-device-tooltip-id"
						title={t('device-management.tooltip-recalibrate')}
					>
						<Button
							disabled={!canCalibrate || !formik.isValid}
							data-testid="add-device-submit-id"
							type="submit"
							onClick={formik.submitForm}
							variant="contained"
							disableElevation
						>
							<Tr.Portal path="device-management.recalibrate" />
						</Button>
					</Tooltip>
				</Stack>
			</Stack>
			<form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
				<Grid data-testid="device-wrapper-id" container columnSpacing={4} height={'100%'}>
					<Grid item xs={6} height={1}>
						<GenericCard sx={{ height: '100%' }}>
							<Stack paddingBottom={1} marginBottom={3} borderBottom="1px solid" borderColor="grey.200">
								<Typography variant="h3" sx={{ marginBottom: 2, textTransform: 'none' }} color="primary.main">
									<Tr.Portal path="device-management.recalibrate-device" />
								</Typography>
								<Typography variant="pg-m" sx={{ marginBottom: 1 }}>
									<Tr.Portal path="device-management.recalibrate-device-calibration-message" />
								</Typography>
								<Stack direction="row" flexWrap="wrap" sx={{ gap: 1, marginBottom: 1.5 }}>
									{deviceData?.calibrationData?.nmrDeviceCalibrations?.map((calibration) => (
										<CalibrationChip key={calibration.id} calibration={calibration} />
									))}
								</Stack>
							</Stack>
							<DeviceFileUpload
								canCalibrate={canCalibrate}
								files={files}
								hide={hide}
								outOfRangeFiles={outOfRangeFiles}
								setOutOfRangeFiles={setOutOfRangeFiles}
								removeFile={removeFile}
								receiverGainRange={receiverGainRange}
								onUploadedFileChange={(data) => setRawFiles(unknownToFile(data))}
							/>
						</GenericCard>
					</Grid>
					<Grid item xs={6} height={1}>
						<GenericCard sx={{ height: '100%' }}>
							<DeviceSpecification formik={formik} file={files[0] ?? {}} recalibration={deviceData} />
						</GenericCard>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
};
