import { DependencyList, useEffect } from 'react';

export const useScrollTop = (deps: DependencyList, isScrollAllowed = true) => {
	useEffect(() => {
		if (isScrollAllowed) {
			document.getElementById('layout-grid')?.scrollTo({
				top: 0,
				left: 0,
			});
		}
	}, deps);

	return null;
};
