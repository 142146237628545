import { Button } from '@mui/material';
import { notificationService, userService } from '@services/index';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { ExtendedComponent } from 'src/types';

type TResendInvitation = {
	id: string;
};

export const ResendInvitation: ExtendedComponent<TResendInvitation> = ({ id, ...props }) => {
	const { t } = useTranslation('common');
	const resendInvitation = (invitedId: string) => {
		RxUtils.promisify(
			userService.resendInvitation(invitedId),
			(invitedUser) =>
				notificationService.sendSuccess(`${t('user-management.add-user-success-message', { email: invitedUser.email })}`),

			() => notificationService.sendError(t('user-management.add-user-fail-message')),
		);
	};
	return (
		<Button
			variant="outlined"
			size="small"
			disableElevation
			sx={{
				borderColor: 'primary.main',
				borderWidth: '2px',
			}}
			onClick={() => resendInvitation(id)}
			{...props}
		>
			<Tr.Common path={`user-management.resend-invitation`} />
		</Button>
	);
};
