import { FC } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { breadcrumbSelector } from '@store/slices/common/common.slice';
import { IRoute } from '@models/router';
import { ROUTES } from '@routes/routes';
import { BreadcrumbConfig, ISubPath } from '../Breadcrumb';
import { Breadcrumbs, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbItem } from './BreadcrumbItem';

export const Breadcrumb: FC = () => {
	const data = useSelector(breadcrumbSelector);
	const loc = useLocation();

	const filtered = BreadcrumbConfig.filter((item) => {
		const route = item.path ? { path: item.path } : ROUTES?.[item.pathIndex]?.children?.[item.subPathIndex];
		return matchRoutes([route] as IRoute[], loc.pathname.replace('/portal', '').replace('/admin', '').replace('/orgAdmin', ''));
	});

	const renderLinks = () => {
		if (filtered.length > 0) {
			return filtered[0].subPaths.map((item: ISubPath, i) => {
				const { conditions } = item;
				const isActive = i === filtered[0].subPaths.length - 1;

				const allPass = conditions?.every((condition) => {
					return condition?.items?.some((el) => data?.[condition?.entityKey as string] === el);
				});

				return !conditions || allPass ? (
					<BreadcrumbItem key={`breadcrumb-item-${i}`} data={data} isActive={isActive} subPath={item} />
				) : null;
			});
		}
	};

	return (
		<>
			{filtered.length > 0 ? (
				<Stack sx={{ p: '8px 0 8px 24px', backgroundColor: '#01884c1a' }}>
					<Breadcrumbs
						sx={{
							' .MuiBreadcrumbs-li:last-child': {
								maxWidth: `calc(100% - ${filtered.length * 35}rem)`,
							},
						}}
						separator={<NavigateNextIcon sx={{ color: 'black' }} />}
						aria-label="breadcrumb"
					>
						{renderLinks()}
					</Breadcrumbs>
				</Stack>
			) : null}
		</>
	);
};
