import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { GenericCard } from '@components/common';
import { Typography, Link } from '@mui/material';
import { Tr, getContactMailAddress } from '@utils';
import { IrAnalysisResultContext } from './Iranalysis-result-context';
import { useLocationSelector } from '@store/slices/common/location.slice';
import { AnalysisCommentField } from './IrAnalysisCommentField';

export const AnalysisErrorSummary: FC = () => {
	const { analysisResultData } = useContext(IrAnalysisResultContext);
	const { t } = useTranslation('irportal');
	const country = useSelector(useLocationSelector);

	return analysisResultData && analysisResultData.isError() ? (
		<GenericCard
			sx={{
				boxShadow: '0px 0px 1px rgba(15, 26, 46, 0.15), 0px 1px 4px rgba(15, 26, 46, 0.15)',
				border: 'none',
				paddingX: 3,
				paddingY: 4,
			}}
		>
			<Typography variant="h4" sx={{ color: 'primary.main' }}>
				<Tr.IrPortal path="analysis-result.error-summary" />
			</Typography>
			<Typography variant="caption" color="background.paper">
				{analysisResultData.integrationErrorMessage}
			</Typography>
			<Typography variant="body2" sx={{ marginY: 2, fontWeight: 'bold' }}>
				<Tr.IrPortal path="analysis-result.technical-issue-error-message" />

				<Link
					sx={{ display: 'inline-block' }}
					href={`mailto:${getContactMailAddress(country.code)}?subject=${t('footer.contact-subject')}`}
				>
					<Tr.IrPortal path="analysis-result.contact-us" />
				</Link>
			</Typography>

			<AnalysisCommentField />
		</GenericCard>
	) : null;
};
