import { Flag } from '@models/flag';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { TypeUtils } from '..';
import { TechnologyPermissionMap } from './technology-permission.enum';

export const getPermissionByTechnology = (key: string | string[], technology: SupportedTechnology | string | undefined) => {
	if (!technology || !(technology in SupportedTechnology)) {
		return key;
	}

	if (!Array.isArray(key)) {
		return TypeUtils.returnValueOfKeys<typeof TechnologyPermissionMap, string>([technology, key], TechnologyPermissionMap);
	}
	return key.map((permission) =>
		TypeUtils.returnValueOfKeys<typeof TechnologyPermissionMap, string>([technology, permission], TechnologyPermissionMap),
	);
};

export const getFlaggedTechnology = (technology: SupportedTechnology, flags: Flag) => {
	if (technology === SupportedTechnology.IR && !flags['ir-pipeline']) return true;
	return false;
};
export const getTechnologiesByPermission = (key: string, flags: Flag, permissions?: string[]) => {
	const technologies: string[] = [];
	if (!permissions) {
		return technologies;
	}

	for (const technology of Object.values(SupportedTechnology)) {
		if (getFlaggedTechnology(technology, flags)) continue;
		if (permissions.includes(TypeUtils.returnValueOfKeys([technology, key], TechnologyPermissionMap) ?? '')) {
			technologies.push(technology.toString());
		}
	}

	return technologies;
};
