import { FC, useMemo } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { NmrAnalysisResult } from '@services';
import { Tr, UsefulLinkUtils } from '@utils';
import { QuantitativeTableOfAssignment } from '../QuantitativeTableAssignment';
import { QualitativeTableOfAssignment } from '../QualitativeTableOfAssignment';

interface IPeaksTableProps {
	resultData: NmrAnalysisResult;
	selectedErmIndex: number;
	sampleName?: string;
}

export const NMRPeaksTable: FC<IPeaksTableProps> = ({ resultData, selectedErmIndex, sampleName }) => {
	const { resultMatches } = resultData;

	const selectedDrm = useMemo(() => resultMatches[`${selectedErmIndex}`], [selectedErmIndex, resultMatches]);
	return (
		<>
			{selectedDrm?.atoms && (
				<Typography variant="h3" sx={{ color: 'primary.main' }} marginBottom={2}>
					<Tr.Portal path="analysis-result.table-of-assignment" />
				</Typography>
			)}
			<Box
				sx={{
					width: 'initial',
					overflow: 'auto',
					borderRadius: '8px',
					border: '1px solid',
					borderColor: 'grey.200',
				}}
			>
				{!resultData.isQualitative() && (
					<QuantitativeTableOfAssignment resultData={resultData} selectedErmIndex={selectedErmIndex} />
				)}

				{resultData.isQualitative() && (
					<QualitativeTableOfAssignment resultData={resultData} selectedErmIndex={selectedErmIndex} sampleName={sampleName} />
				)}
			</Box>

			{selectedDrm?.atoms.some((item) => item.rmHMultiplicity?.includes('sc')) && (
				<Typography variant="label-s" sx={{ marginTop: '1rem' }}>
					<Tr.Portal path="analysis-result.footnotes" />
				</Typography>
			)}
			{selectedDrm?.atoms.some((item) => item.rmHMultiplicity?.includes('sc')) && (
				<Typography variant="pg-m" sx={{ marginTop: '0.75rem' }}>
					<Tr.Portal path="analysis-result.sc" />
				</Typography>
			)}

			{selectedDrm?.comments && (
				<Typography variant="pg-m" sx={{ marginTop: '1rem' }}>
					<Tr.Portal path="analysis-result.diagnostics" />
				</Typography>
			)}

			{selectedDrm?.comments &&
				selectedDrm?.comments?.split('\n').map((item, index) => (
					<Typography key={index} variant="pg-m" sx={{ marginTop: '0.75rem' }}>
						* {item}
					</Typography>
				))}
			<Typography variant="label-s" sx={{ marginTop: '1rem' }}>
				<Tr.Portal path="analysis-result.other-notes" />
			</Typography>
			<Typography variant="pg-m" sx={{ fontSize: '1rem', fontWeight: 400 }}>
				<Tr.Portal path="analysis-result.find-additional" />{' '}
				<Link
					href={UsefulLinkUtils.FAQ}
					target="_blank"
					sx={{ color: 'text.secondary', fontWeight: 'bold', textTransform: 'underline', textDecorationColor: 'inherit' }}
				>
					<Tr.Portal path="analysis-result.faq" />
				</Link>
			</Typography>
		</>
	);
};
