import { FC } from 'react';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import QuestionMark from '@mui/icons-material/QuestionMark';
import RemoveRounded from '@mui/icons-material/RemoveRounded';
import { SxProps } from '@mui/material';

export enum IconType {
	DONE = 'Done',
	CLOSE = 'Close',
	REMOVE = 'Remove',
	QUESTION_MARK = 'QuestionMark',
	ONLY_THEORETICAL = 'OnlyTheoretical',
}

export interface ICompletedStatusIcon {
	sx?: SxProps;
}

const iconSX: SxProps = {
	borderRadius: '50%',
	padding: '0.2rem',
	color: 'background.paper',
};

export const CompletedStatusIcon: Record<IconType, FC<ICompletedStatusIcon>> = {
	[IconType.DONE]: ({ sx }) => (
		<Done
			data-testid="done-icon"
			sx={{
				...iconSX,
				backgroundColor: 'success.main',
				...sx,
			}}
		/>
	),
	[IconType.CLOSE]: ({ sx }) => (
		<Close
			data-testid="close-icon"
			sx={{
				...iconSX,
				backgroundColor: 'error.main',
				...sx,
			}}
		/>
	),
	[IconType.QUESTION_MARK]: ({ sx }) => (
		<QuestionMark
			data-testid="question-mark-icon"
			sx={{
				...iconSX,
				backgroundColor: 'secondary.main',
				color: 'background.black',
				...sx,
			}}
		/>
	),
	[IconType.REMOVE]: ({ sx }) => (
		<RemoveRounded
			data-testid="remove-rounded-icon"
			sx={{
				...iconSX,
				backgroundColor: 'grey.400',
				...sx,
			}}
		/>
	),
	[IconType.ONLY_THEORETICAL]: ({ sx }) => (
		<Done
			data-testid="done-icon"
			sx={{
				...iconSX,
				backgroundColor: 'info.700',
				...sx,
			}}
		/>
	),
};
