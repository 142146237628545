import { InfoMessageBox, ModalDialog } from '@components/common';
import { Button, Checkbox, Grid, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTestId } from '@utils/DataTestId';

interface IBudgetLimitExceededModal {
	budgetLimit?: number;
	currentBudgetLimit?: number;
	currency?: string;
	isOpen?: boolean;
	onCancel?: () => void;
	onSave?: () => void;
}

export const BudgetLimitExceededModal: FC<IBudgetLimitExceededModal> = ({
	currentBudgetLimit = 0,
	budgetLimit = 0,
	isOpen = false,
	onCancel,
	onSave,
	currency,
}) => {
	const { t } = useTranslation('admin');
	const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
	return (
		<ModalDialog
			variant="secondary"
			open={isOpen}
			title={
				<Typography variant="pg-s">
					<Tr.Admin path="organization.confirmation" />
				</Typography>
			}
			closeIconClick={onCancel}
			maxWidth={false}
			PaperProps={{
				sx: {
					maxWidth: '472px',
					maxHeight: '600px',
					minHeight: '500px',
				},
			}}
		>
			<Stack
				sx={{
					paddingX: '68px',
					marginY: 4,
				}}
				spacing={3}
			>
				<Stack sx={{ textAlign: 'center' }}>
					<Typography variant="h3">
						<Tr.Admin path="organization.increase-budget-limit" />
					</Typography>
				</Stack>

				<Stack>
					<Typography variant="pg-m" fontSize="14px" fontWeight={400} textAlign="justify" sx={{ textAlignLast: 'center' }}>
						{t('organization.increase-budget-limit-explanation-1')}
						<strong>{currentBudgetLimit.toLocaleString() + ' ' + currency}</strong>
						{t('organization.increase-budget-limit-explanation-2')}
					</Typography>
				</Stack>

				<InfoMessageBox
					message={t('organization.increase-budget-limit-warning', {
						currency: currency,
						budgetLimit: budgetLimit.toLocaleString(),
					})}
				/>
				<Stack direction="row" alignItems="center" sx={{ border: '1px solid', borderColor: 'grey.200', borderRadius: '4px' }}>
					<Checkbox
						data-testid={DataTestId.getStaticTestId('confirm-checkbox-id')}
						checked={checkboxChecked}
						onChange={() => setCheckboxChecked((prev) => !prev)}
					/>
					<Typography variant="pg-s" fontWeight={400}>
						<Tr.Admin path="organization.yes-confirm" />
					</Typography>
				</Stack>
				<Grid container>
					<Grid
						item
						xs={6}
						sx={{
							paddingRight: 1,
						}}
					>
						<Button
							variant="outlined"
							fullWidth={true}
							size="medium"
							data-testid="increase-budget-cancel-button-id"
							disableElevation
							onClick={() => {
								onCancel && onCancel();
							}}
						>
							<Tr.Portal path="cancel" />
						</Button>
					</Grid>

					<Grid
						item
						xs={6}
						sx={{
							paddingLeft: 1,
						}}
					>
						<Button
							fullWidth={true}
							data-testid="increase-budget-approve-button-id"
							variant="contained"
							size="medium"
							type="submit"
							onClick={() => onSave?.()}
							disableElevation
							disabled={!checkboxChecked}
							sx={{ textTransform: 'capitalize' }}
						>
							<Tr.Admin path="organization.increase-limit" />
						</Button>
					</Grid>
				</Grid>
			</Stack>
		</ModalDialog>
	);
};
