import { DownloadableFileExtension } from '@models/file';
import { Button, ButtonProps } from '@mui/material';
import { notificationService } from '@services/index';
import { downloadFileFromLink } from '@utils/Download';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { ExtendedComponent } from 'src/types';

type TFileDownloadButton = {
	extension: DownloadableFileExtension;
	uri?: string;
	fileName?: string;
};

export const FileDownloadButton: ExtendedComponent<TFileDownloadButton & ButtonProps> = ({ extension, uri, fileName, ...props }) => {
	const { t } = useTranslation('common');
	const defaultFileDownloader = () => {
		if (uri || props.onClick) {
			return uri && fileName ? downloadFileFromLink(uri, fileName) : undefined;
		}
		notificationService.sendError(t('error-codes.other-error-code'));
		return undefined;
	};
	return (
		<Button
			variant="text"
			size="small"
			sx={{
				color: 'primary.main',
			}}
			onClick={props.onClick ? props.onClick : defaultFileDownloader}
			{...props}
		>
			<Tr.Common path={extension} />
		</Button>
	);
};
