import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionProps, AccordionSummary, Stack } from '@mui/material';
import { FC } from 'react';

type TAuditTrailChangeAccordion = {
	changes: string;
};

export const AuditTrailChangeAccordion: FC<TAuditTrailChangeAccordion & Omit<AccordionProps, 'children'>> = ({ changes, ...props }) => {
	const changesList = changes.trim().split('\n');
	const lineCount = changesList.length ?? 0;
	return (
		<Stack fontSize={14}>
			<Accordion
				sx={{
					border: 'none',
					boxShadow: 'none',
					'&.Mui-disabled': {
						backgroundColor: 'transparent',
						color: 'text.primary',
						opacity: 1,
					},
				}}
				disabled={lineCount <= 1}
				{...props}
			>
				{changesList.map((i, index) => (
					<AccordionSummary
						expandIcon={index === 0 && lineCount > 1 ? <ExpandMore sx={{ color: 'primary.main' }} /> : null}
						sx={{
							paddingLeft: 0,
							border: 'none',
							textOverflow: 'ellipsis',
							wordBreak: 'break-word',
							'&.Mui-disabled': {
								opacity: 1,
							},
							'&.MuiAccordionSummary-content': {
								margin: '8px 0px',
							},
						}}
						key={`${index}_${i}`}
					>
						{i}
					</AccordionSummary>
				))}
			</Accordion>
		</Stack>
	);
};
