import { FC } from 'react';
import { subscribeService, Organization } from '@services';
import { Grid, Stack } from '@mui/material';
import { SubscriptionDetailsLayout } from '@components/common/SubscriptionDetailsLayout';
import { AddSubscription } from '../AddSubscription';
import { SubscriptionType } from '@models/subscribe';
import { useService } from '@hooks';
import { SubscriptionHistory } from '../SubscriptionHistory';

interface EditSubscriptionLayoutProps {
	organizationData: Organization;
	editing?: boolean;
}

export type FormFields = Extendable<{
	subscriptionPlanId: number;
	numOfAnalyses: number;
	subscriptionStartDate: Date;
	subscriptionEndDate: Date;
	subscriptionComment: string;
}>;

export const EditSubscriptionLayout: FC<EditSubscriptionLayoutProps> = ({ organizationData, editing = true }) => {
	const { data: activeSubscription, trigger: refreshSubscriptionList } = useService(() => {
		return subscribeService.getActiveSubscriptionPlans(organizationData.id);
	}, []);
	const handleGetSubscriptionTrigger = () => {
		refreshSubscriptionList();
	};

	const isAbleToAddSubscription =
		activeSubscription?.length === 0 ||
		(!activeSubscription?.[1] && activeSubscription?.[0]?.subscriptionType === SubscriptionType.FREE);
	return (
		<Stack>
			<Grid container spacing={5}>
				<Grid item xs={(!editing && activeSubscription?.length === 1) || activeSubscription?.length === 0 ? 12 : 6}>
					{activeSubscription && activeSubscription[0] && (
						<SubscriptionDetailsLayout
							subscriptionData={activeSubscription[0]}
							editing={editing}
							containerSx={{ width: !editing && activeSubscription?.length === 1 ? 0.5 : 1 }}
							triggerSubscriptionRequest={() => handleGetSubscriptionTrigger()}
						/>
					)}
				</Grid>
				<Grid item xs={6}>
					{activeSubscription && activeSubscription[1] && (
						<SubscriptionDetailsLayout
							subscriptionData={activeSubscription[1]}
							editing={editing}
							triggerSubscriptionRequest={() => handleGetSubscriptionTrigger()}
						/>
					)}
					{editing && isAbleToAddSubscription && (
						<AddSubscription organizationId={organizationData.id} onSubmit={() => handleGetSubscriptionTrigger()} />
					)}
				</Grid>
			</Grid>
			<SubscriptionHistory organizationDataId={organizationData.id} />
		</Stack>
	);
};
