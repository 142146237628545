import { NmrAnalysisResult } from '@services';
import { createContext } from 'react';

interface IAnalysisResultContext {
	analysisResultData: Maybe<NmrAnalysisResult>;
	triggerResult: () => void;
	analysisId: Maybe<number>;
	isTheoreticalExecuting: boolean;
	setIsTheoreticalExecuting: (val: boolean) => void;
	theoreticalEnabled: boolean;
	setTheoreticalEnabled: (val: boolean) => void;
}

export const AnalysisResultContext = createContext<IAnalysisResultContext>({
	analysisResultData: null,
	triggerResult: () => undefined,
	analysisId: null,
	isTheoreticalExecuting: false,
	setIsTheoreticalExecuting: (_: boolean) => undefined,
	theoreticalEnabled: false,
	setTheoreticalEnabled: (_: boolean) => undefined,
});
