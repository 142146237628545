import { useService } from '@hooks';
import { FilterValueType, IFilter } from '@models/filter';
import { PagedResult } from '@models/request-response';
import { CUSTOMER_USER_FILTER } from '@models/user';
import { Filter, Lab, organizationService } from '@services/core';
import { labListToFilter } from '@utils/Lab';
import { organizationListToFilter } from '@utils/Organization';
import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

const changeReferenceOfOptions = () => {
	// Array reference workaround with spread operator ...
	const filterWithValues = {
		options: [] as IFilter[],
	};
	filterWithValues.options = [...CUSTOMER_USER_FILTER.options];
	return filterWithValues;
};

export const useCustomerUserManagementFilter = (currentFilterValues?: FilterValueType) => {
	const [dynamicFilters, setDynamicFilters] = useState<Filter>(CUSTOMER_USER_FILTER);
	const [selectedOrgIds, setSelectedOrgIds] = useState(currentFilterValues?.organizationId);
	const { data: organizationList } = useService(() => organizationService.getAll({ PageIndex: 1, PageSize: 0, Status: 0 }));

	const orgId = selectedOrgIds;
	const isValidOrgIdSelected = orgId && (orgId as string[]).length !== 0;

	const changeOnLoading = (state: boolean) => {
		setDynamicFilters((prev) => {
			prev.options[2].loading = state;
			return prev;
		});
	};

	useEffect(() => {
		setSelectedOrgIds(currentFilterValues?.organizationId);
	}, [currentFilterValues?.organizationId]);

	const { data: labList } = useService(() => {
		if (isValidOrgIdSelected) {
			changeOnLoading(true);
			return organizationService.getLaboratories(Number(orgId), { disableLoader: true });
		}
		setDynamicFilters((_) => {
			const filterWithValues = changeReferenceOfOptions();
			filterWithValues.options[2].disabled = true;
			return filterWithValues;
		});
		return new Observable<PagedResult<Lab>>();
	}, [selectedOrgIds]);

	useEffect(() => {
		const filterWithValues = changeReferenceOfOptions();
		filterWithValues.options[1].values = organizationListToFilter(organizationList?.data ?? []);
		setDynamicFilters((_) => filterWithValues);
	}, [organizationList?.data]);

	useEffect(() => {
		if (isValidOrgIdSelected && labList) {
			changeOnLoading(false);
		}
		const filterWithValues = changeReferenceOfOptions();
		filterWithValues.options[2].disabled = false;
		filterWithValues.options[2].values = labListToFilter(labList?.data ?? []);
		setDynamicFilters((_) => filterWithValues);
	}, [labList?.data]);

	return { filter: dynamicFilters };
};
