import { ModalDialog } from '@components/common';
import { useIrAnalysis } from '@hooks';
import { IrAnalysisType } from '@models';
import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { DataTestId, Tr } from '@utils';
import { useState } from 'react';
import { ExtendedComponent } from 'src/types';
import { IrFavoritesTable } from '../IrFavoritesTable';
export interface IAddFromFavoritesButtonProps extends Omit<ButtonProps, 'type'> {
	onFavoriteSelect?: (id: number) => void;
	library: string;
	type: IrAnalysisType;
	isDisabled?: boolean;
}

export const AddFromFavoritesButton: ExtendedComponent<IAddFromFavoritesButtonProps> = ({
	onClick,
	onFavoriteSelect,
	children,
	library,
	type,
	isDisabled,
	...props
}) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const { addedERMs } = useIrAnalysis();

	return (
		<>
			<form>
				<Button
					data-testid="add-favorite-button"
					variant="contained"
					size="medium"
					disabled={isDisabled}
					onClick={(e) => (setDialogOpen(true), onClick?.(e))}
					{...props}
					type="button"
				>
					{children || <Tr.IrPortal path="favourites.add-from-favorites-button" />}
				</Button>
			</form>

			{dialogOpen && (
				<ModalDialog
					variant="secondary"
					open={dialogOpen}
					onClose={() => setDialogOpen(false)}
					maxWidth="lg"
					contentProps={{ sx: { paddingX: 10.5, paddingTop: 2, paddingBottom: 7 } }}
					title={
						<Typography variant="pg-s">
							<Tr.IrPortal path="favourites.add-from-favorites-modal-title" />
						</Typography>
					}
				>
					<Stack
						direction="column"
						spacing={3}
						sx={{ mb: 2, mt: 3 }}
						data-testid={DataTestId.getStaticTestId('add-from-fav-modal-id')}
					>
						<Typography variant="h3" textAlign="center">
							<Tr.IrPortal path="favourites.add-from-favorites-modal-title" />
						</Typography>
						<IrFavoritesTable
							library={library}
							pageSize={5}
							actions={[
								{
									renderer: ({ drmCode, referenceMaterialId }) => (
										<Button
											variant="contained"
											size="small"
											disabled={addedERMs?.some((s) =>
												s.selectedReferenceMaterials?.some((r) => r.drmCode === drmCode),
											)}
											onClick={() => onFavoriteSelect?.(referenceMaterialId)}
										>
											{type === IrAnalysisType.PURITYCHECK && addedERMs && addedERMs?.length > 0 ? (
												<Tr.IrPortal path="favourites.add-as-impurity" />
											) : (
												<Tr.IrPortal path="favourites.add-to-analysis-button" />
											)}
										</Button>
									),
								},
							]}
						/>
					</Stack>
				</ModalDialog>
			)}
		</>
	);
};
