import { IFilterValues } from '@models/filter';
import { Lab } from '@services/core';

export const labToFilter: (lab: Lab) => IFilterValues = (lab: Lab) => {
	return {
		id: String(lab.id),
		value: lab.name,
	};
};

export const labListToFilter: (labs: Lab[]) => IFilterValues[] = (labs) => {
	return labs.map(labToFilter);
};
