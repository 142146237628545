import { useEffect, useState } from 'react';
import { fromJCAMP } from 'nmr-parser';
import { notificationService } from '@services';
import { useTranslation } from 'react-i18next';
import { differenceInBusinessDays } from 'date-fns';

const MAX_FILE_LENGTH = 6;
const ONE_WEEK = 7;
const SUPPORTED_DEVICES = ['bruker', 'jeol'];

export enum FileStatus {
	VALID = 'valid',
	INVALID = 'invalid',
}

export interface IFileStateType {
	file: File;
	fileName: string;
	receiverGain: string;
	measurementDevice: string;
	manufacturer: string;
	solventName: string;
	fileStatus: FileStatus;
}

export const useDeviceFileHook = (newFile: File[]) => {
	const [files, setFiles] = useState<IFileStateType[]>([]);
	const [flag, setFlag] = useState(false);

	const { t } = useTranslation('irportal');
	const removeFile = (index: number) => {
		setFiles(
			files
				.filter((_, fileIndex) => index !== fileIndex)
				.map((file) => {
					return { ...file, fileStatus: FileStatus.VALID };
				}),
		);
	};

	const removeAllFiles = () => {
		setFiles([]);
	};

	const setFileStatus = (index: number, status: FileStatus) => ((files[`${index}`].fileStatus = status), setFlag(!flag));

	useEffect(() => {
		const expireFiles: string[] = [];
		const notSupportedFile: string[] = [];
		newFile.forEach(async (file) => {
			try {
				await file.text().then((data) => {
					const meta = fromJCAMP(data.replaceAll('$$ ##$', '##').replaceAll('\r\n$$', '\r\n##$$empty'))[0].meta;

					meta.PROBHD = Array.isArray(meta.PROBHD) ? meta.PROBHD[0] : meta.PROBHD;
					meta.RG = Array.isArray(meta.RG) ? meta.RG[0] : meta.RG;
					const { RG, ORIGIN, TITLE, LONGDATE, DATE, TIME, ...others } = meta;

					const diffDate = differenceInBusinessDays(Date.now(), new Date(LONGDATE ?? DATE ?? TIME));
					const isExpire = diffDate > ONE_WEEK || isNaN(diffDate);
					isExpire && expireFiles.push(file.name);

					const manufacturerName = ORIGIN.split(' ')?.[0]?.trim()?.toLowerCase();
					const isSupported = SUPPORTED_DEVICES.includes(manufacturerName);
					!isSupported && notSupportedFile.push(file.name);

					!isExpire &&
						isSupported &&
						setFiles((current) => {
							if (current.length < MAX_FILE_LENGTH) {
								const hasSameGain = current.some((currentData) => currentData.receiverGain === RG);
								hasSameGain &&
									notificationService.sendError(
										t('device-management.same-file-error', {
											filename: file.name,
										}),
									);
								return !hasSameGain
									? [
											...current,
											{
												file: file,
												fileName: file.name,
												receiverGain: RG || '',
												manufacturer: ORIGIN || '',
												measurementDevice: TITLE,
												solventName: others['.SOLVENTNAME'] || '',
												fileStatus: FileStatus.VALID,
											},
										]
									: current;
							}
							return current;
						});
				});
			} catch (e) {
				notificationService.sendError(t('file-not-parsed', { filename: file.name }));
			}
		});

		const timeout = setTimeout(() => {
			expireFiles.length &&
				notificationService.sendError(
					t(`device-management.expire-file-error-${expireFiles.length > 1 ? 'multi' : 'single'}`, {
						filename: expireFiles.join(', '),
					}),
				);

			notSupportedFile.length &&
				notificationService.sendError(
					t(`device-management.unsupported-manufacturer-${notSupportedFile.length > 1 ? 'multi' : 'single'}`, {
						filename: notSupportedFile.join(', '),
					}),
				);
		}, 50);

		return () => {
			clearTimeout(timeout);
		};
	}, [newFile]);

	return { files, removeFile, removeAllFiles, setFileStatus };
};
