import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { Tr } from '@utils';
import { TableButton } from '@components/nmr-portal';
import { ModalDialog } from '../ModalDialog';

import CheckIcon from '@mui/icons-material/Check';

export const ResetSuccessfulModal: FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
	return (
		<ModalDialog
			data-testid="reset-successful-modal-id"
			title={<Tr.Login path="reset.done" />}
			variant="secondary"
			open={open}
			onClose={() => onClose()}
			sx={{
				'& .MuiDialog-container': {
					'& .MuiPaper-root': {
						width: 'min(100%, 472px)',
						paddingBottom: '2rem',
					},
				},
			}}
		>
			<Stack sx={{ alignItems: 'center' }}>
				<Box
					sx={{
						width: '5rem',
						height: '5rem',
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						backgroundColor: 'rgba(1, 136, 76, 0.1)',
						borderRadius: '50%',
					}}
				>
					<CheckIcon color="primary" sx={{ width: '3rem', height: '3rem' }} />
				</Box>
				<Typography variant="h3" sx={{ marginTop: '1.5rem' }}>
					<Tr.Login path="reset-successfully.password-reset" />
				</Typography>
				<Typography variant="pg-m" sx={{ fontSize: '0.87rem', fontWeight: 500, marginY: 4 }}>
					<Tr.Login path="reset-successfully.desc" />
				</Typography>
				<TableButton.Contained
					data-testid="reset-goto-login-test-id"
					sx={{ width: '10rem', height: '2.5rem', fontSize: '0.9rem' }}
					size="large"
					onClick={() => onClose()}
				>
					<Tr.Login path="reset-successfully.ok-thanks" />
				</TableButton.Contained>
			</Stack>
		</ModalDialog>
	);
};
