import { IrDrmWaveStatus } from '@services/ir-drm-pipeline';
import { IrDrmWaveReportStage, IrDrmWaveReportStatus } from './IrWaveReportStatus.enum';
export type TIrWaveStatus = {
	status: IrDrmWaveReportStatus;
	stage: IrDrmWaveReportStage;
};

export const IrWaveStatusMap = {
	[IrDrmWaveStatus.READY]: {
		status: IrDrmWaveReportStatus.UPLOADED,
		stage: IrDrmWaveReportStage.UPLOADED,
	},
	[IrDrmWaveStatus.UPLOAD_FAILED]: {
		status: IrDrmWaveReportStatus.FAILED,
		stage: IrDrmWaveReportStage.UPLOADED,
	},
	[IrDrmWaveStatus.RELEASING]: {
		status: IrDrmWaveReportStatus.UPLOADED,
		stage: IrDrmWaveReportStage.UPLOADED,
	},
	[IrDrmWaveStatus.RELEASED]: {
		status: IrDrmWaveReportStatus.RELEASED,
		stage: IrDrmWaveReportStage.RELEASED,
	},
	[IrDrmWaveStatus.RELEASE_FAILED]: {
		status: IrDrmWaveReportStatus.FAILED,
		stage: IrDrmWaveReportStage.RELEASED,
	},
};

export const IrWaveStatusColorMap: Record<IrDrmWaveReportStatus, string> = {
	[IrDrmWaveReportStatus.RELEASED]: 'primary.main',
	[IrDrmWaveReportStatus.UPLOADED]: 'primary.main',
	[IrDrmWaveReportStatus.FAILED]: 'error.main',
};
