import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { EditYourProfile } from '@components/common';
import { userSelector } from '@store/slices/common/common.slice';
import { MFASettings } from './MFASettings/MFASettings';

export const YourProfile = () => {
	const user = useSelector(userSelector);
	const navigate = useNavigate();
	return (
		<Stack direction="row">
			<Stack sx={{ width: '66.6%' }}>
				<EditYourProfile
					userId={user?.username}
					onCloseClick={() => {
						navigate(0);
					}}
				/>
			</Stack>
			<Stack sx={{ width: '33.3%' }}>
				<MFASettings />
			</Stack>
		</Stack>
	);
};
