import { DataTestId } from '@utils/DataTestId';
import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { DrmPipelineHeader } from '@components/admin/DrmPipeline';
import { FC } from 'react';
import { NmrWaveMenu } from '@components/admin/NmrWaveMenu';

export const DrmPipeline: FC = () => {
	return (
		<Stack direction="row" data-testid={DataTestId.getStaticTestId('drm-pipeline-wrapper-id')}>
			<Stack width={1}>
				<DrmPipelineHeader />
				<Outlet />
			</Stack>
			<NmrWaveMenu />
		</Stack>
	);
};
