import { TFunction } from 'i18next';
import * as yup from 'yup';
import { UserRole } from '@models/user';

export const getOrganizationUserValidationSchema: (t: TFunction, editing?: boolean) => yup.AnySchema = (t, editing) =>
	yup.object({
		Email: yup.string().trim().required(t('user-management.email-is-required')).email(t('user-management.email-is-invalid')),
		Surname: editing ? yup.string().trim().max(250).required(t('user-management.last-name-is-required')) : yup.string(),
		Name: editing ? yup.string().trim().max(250).required(t('user-management.first-name-is-required')) : yup.string(),
		MobilePhone: editing ? yup.string().trim().required(t('user-management.mobile-phone-is-required')) : yup.string(),
		Roles: yup
			.array(
				yup
					.mixed()
					.oneOf<UserRole>(Object.values(UserRole) as UserRole[])
					.required(),
			)
			.min(1, t('user-management.role-is-required'))
			.ensure()
			.required(),
		LaboratoryIds: yup.array().when('Roles', {
			is: (Roles: UserRole[]) => !(Roles.length === 1 && Roles.includes(UserRole.ORG_ADMIN)),
			then: () => yup.array().min(1, t('user-management.labs-is-required')),
			otherwise: () => yup.array(),
		}),
		OrganizationId: yup.string().required(),
		Status: yup.mixed().required(),
	});

export const getEditOrganizationUserValidationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		email: yup.string().trim().required(t('user-management.email-is-required')).email(t('user-management.email-is-invalid')),
		surname: yup.string().trim().max(250).required(t('user-management.last-name-is-required')),
		name: yup.string().trim().max(250).required(t('user-management.first-name-is-required')),
		mobilePhone: yup.string().trim().required(t('user-management.mobile-phone-is-required')),
		roles: yup
			.array(
				yup
					.mixed()
					.oneOf<UserRole>(Object.values(UserRole) as UserRole[])
					.required(),
			)
			.min(1, t('user-management.role-is-required'))
			.ensure()
			.required(),
		laboratories: yup.array().when('roles', {
			is: (roles: UserRole[]) => !(roles.length === 1 && roles.includes(UserRole.ORG_ADMIN)),
			then: () => yup.array().min(1, t('user-management.labs-is-required')),
			otherwise: () => yup.array(),
		}),
		organizationId: yup.string().required(),
		status: yup.mixed().required(),
	});
