import { FC, useEffect, useRef } from 'react';
import { FormikApiType, RequestErmListItem } from '@components/common';
import { NmrAnalysisFavorite } from './NmrAnalysisFavorite';
import { NmrAnalysisStage, alertService, nmrAnalysisService } from '@services';
import { NmrAnalyticalReferenceMaterial, IAnalyticalReferenceMaterialRef } from './NmrAnalyticalReferenceMaterial';
import { useAnalysis, useService } from '@hooks';
import { QualitativeOrQuantitative } from '@models';
import { RxUtils, Tr } from '@utils';
import { NmrAnalyticalProcedureLayout } from '@components/nmr-portal';
import { QuantitativeStep } from '@hooks';
import { Stack } from '@mui/material';

const MAX_ERM_COUNT = 5;

interface IAnalyticalProcedureProps {
	id: number;
	isFormValid: boolean;
	onStageChange: (stage: NmrAnalysisStage) => void;
	formik: FormikApiType;
}

export const NmrAnalyticalProcedure: FC<IAnalyticalProcedureProps> = ({ onStageChange, isFormValid, id, formik }) => {
	const { updateQuantitativeStep, updateAddedERMs, measurementType, isAnalysisEditable } = useAnalysis();

	const { data: addedSubstances, trigger: refreshAddedSubstances } = useService(() => nmrAnalysisService.getAnalysisSubstances(id), [id]);

	updateAddedERMs(addedSubstances);

	useEffect(() => {
		onStageChange(isFormValid && addedSubstances?.length ? NmrAnalysisStage.DETAILS_FILLED : NmrAnalysisStage.NEW);
	}, [isFormValid, addedSubstances]);

	useEffect(() => {
		addedSubstances && addedSubstances.length > 0 && onRemoveAllSubstance();
		updateQuantitativeStep(QuantitativeStep.STEP_1);
	}, [measurementType]);

	const onRemoveAllSubstance = (cb?: () => void, disabledRefresh = false) => {
		RxUtils.promisify(nmrAnalysisService.removeSubstances(id), () => {
			!disabledRefresh && refreshAddedSubstances();
			updateQuantitativeStep(QuantitativeStep.STEP_1);
			cb?.();
		});
	};

	const onRemoveSubstanceOrErm = (substanceOrDRMId: number | undefined, isSubstance: boolean) => {
		if (substanceOrDRMId)
			RxUtils.promisify(
				isSubstance
					? nmrAnalysisService.removeSubstanceFromAnalysis(id, substanceOrDRMId)
					: nmrAnalysisService.removeERM(id, substanceOrDRMId),
				() => {
					refreshAddedSubstances();
					updateQuantitativeStep(QuantitativeStep.STEP_1);
				},
			);
	};

	const onAddSubstanceOrDrm = (substanceOrDrmId: number, isSubstance: boolean) => {
		const { QUALITATIVE, QUANTITATIVE } = QualitativeOrQuantitative;
		if (
			(addedSubstances && measurementType === QUANTITATIVE && addedSubstances.length === 0) ||
			(addedSubstances && measurementType === QUALITATIVE && addedSubstances.length < MAX_ERM_COUNT)
		) {
			RxUtils.promisify(
				isSubstance
					? nmrAnalysisService.addSubstanceToAnalysis(id, substanceOrDrmId)
					: nmrAnalysisService.addDRMToAnalysis(id, substanceOrDrmId),
				() => refreshAddedSubstances(),
			);
		} else {
			alertService.send({
				content:
					measurementType === QUANTITATIVE ? (
						<Tr.Portal path="search-references.add-erm-error-single" />
					) : (
						<Tr.Portal path="search-references.add-erm-error-multi" />
					),
				titleText: <Tr.Portal path="warning" />,
				closeTextComponent: <Tr.Portal path="cancel" />,
				confirmTextComponent: <Tr.Portal path="clear-selection" />,
				onConfirm: () =>
					measurementType === QUANTITATIVE && addedSubstances && addedSubstances.length > 0
						? onRemoveSubstanceOrErm(addedSubstances?.[0].selectedReferenceMaterials?.[0].id, false)
						: addedSubstances?.length && onRemoveAllSubstance(),
			});
		}
	};

	const childRef = useRef<IAnalyticalReferenceMaterialRef>(null);
	return (
		<Stack>
			<NmrAnalyticalProcedureLayout>
				<NmrAnalysisFavorite
					formik={formik}
					onRemoveSubstance={onRemoveSubstanceOrErm}
					addedSubstances={addedSubstances}
					onAddReferenceMaterial={(drm) => onAddSubstanceOrDrm(drm, false)}
					onRefreshSubstanceList={() => (childRef?.current?.refreshSubstanceList(), refreshAddedSubstances?.())}
					actionsDisabled={!isAnalysisEditable}
				/>
				<NmrAnalyticalReferenceMaterial
					formik={formik}
					onAddErmOrSubstance={onAddSubstanceOrDrm}
					refreshAddedSubstances={refreshAddedSubstances}
					ref={childRef}
				/>
			</NmrAnalyticalProcedureLayout>
			<RequestErmListItem />
		</Stack>
	);
};
