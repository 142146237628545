import { concatMap, map, tap } from 'rxjs';
import { coreConfig } from '../coreConfig';
import { httpService } from '../http';
import { CreatePhysicalProductModel, PhysicalProduct } from './physical-product.class';
import { logger } from '../logger';
import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';

class PhysicalProductService {
	private getURL() {
		return `${coreConfig.url}/PhysicalProduct`;
	}

	getAllByCasNumber(casNumber: string) {
		return httpService
			.get<PhysicalProduct[]>(this.getURL(), `${casNumber}`, {
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllByCasNumber.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(PhysicalProduct, result)),
			);
	}

	create(payload: CreatePhysicalProductModel) {
		return httpService
			.post(this.getURL(), '', {
				body: payload,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.create.name}]`, result)));
	}
}

export const physicalProductService = new PhysicalProductService();
