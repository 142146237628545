import { UserStatus } from '@models/user';
import { Button, Stack, SxProps } from '@mui/material';
import { Tr } from '@utils/Translation';
import { ExtendedComponent } from 'src/types';

const UserStatusStyleMap: Record<UserStatus, SxProps> = {
	[UserStatus.INACTIVE]: { backgroundColor: 'grey.100', color: 'grey.500', pointerEvents: 'none', width: 'fit-content' },
	[UserStatus.INVITED]: {
		backgroundColor: 'warning.main',
		borderColor: 'warning.main',
		color: 'text.primary',
		pointerEvents: 'none',
		width: 'fit-content',
	},
	[UserStatus.ACTIVE]: {},
};

interface IStatusType {
	status: UserStatus;
}

export const PrimaryUserStatusButton: ExtendedComponent<IStatusType> = ({ status, ...props }) => {
	const variant = status === UserStatus.INVITED ? 'outlined' : 'contained';
	const style = UserStatusStyleMap[`${status}`];

	return (
		<Button
			sx={{
				...style,
				pointerEvents: 'none',
				marginLeft: 1,
				paddingX: 0.75,
				paddingY: 0.75,
				lineHeight: '80%',
				fontSize: 14,
				maxHeight: 28,
			}}
			variant={variant}
			color="primary"
			disableElevation
			{...props}
		>
			<Stack height={12}>
				<Tr.Common path={status?.toLowerCase() ?? ''} />
			</Stack>
		</Button>
	);
};
