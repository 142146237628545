import { Navigate } from 'react-router-dom';
import { IRoute, PageType, PortalPageRoutes, PermissionEnum } from '@models';
import { Tr } from '@utils';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { AuthorizationComponent } from '@routes/Authorization';
import { Portal } from '@routes/Portal/Portal';
import { UserManagement } from '@routes/Portal/common/UserManagement';
import { YourProfile } from '@routes/Portal/common/YourProfile';
import { OrganizationSettings } from '@routes/Portal/common/OrganizationSettings';
import { LabDetails } from '@routes/Portal/common/LabDetails';
import { Analytics } from '@components/common/Analytics';

export const ORGADMIN_ROUTES: IRoute = {
	path: '/orgAdmin',
	element: (
		<AuthorizationComponent path="orgAdmin">
			<Analytics>
				<Portal />
			</Analytics>
		</AuthorizationComponent>
	),
	text: 'Portal',
	children: [
		{
			path: PortalPageRoutes.DASHBOARD,
			element: <OrganizationSettings />,
			icon: <HomeOutlinedIcon color="inherit" />,
			text: <Tr.Portal path="dashboard.title" />,
			pageType: PageType.STANDARD,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.VIEW_DETAILS],
		},
		{
			path: PortalPageRoutes.MANAGE_USERS,
			element: <UserManagement />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="manage-users.title" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.USER],
		},
		{
			path: PortalPageRoutes.YOUR_PROFILE,
			element: <YourProfile />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="your-profile.title" />,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.UserPermission.USER_PROFILE],
		},

		{
			path: `${PortalPageRoutes.DASHBOARD}/:id/:name`,
			element: <OrganizationSettings />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.VIEW_SUBSCRIPTION_DETAILS],
		},
		{
			path: `${PortalPageRoutes.DASHBOARD}/:orgId/${PortalPageRoutes.LAB_DETAILS}/:id`,
			element: <LabDetails />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_DETAILS],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ORGANIZATION_SETTINGS}/:id/:name`,
			element: <OrganizationSettings />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.VIEW_SUBSCRIPTION_DETAILS],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.LAB_DETAILS}/:id`,
			element: <LabDetails />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_DETAILS],
		},
		{
			hideFromMenu: true,
			path: '',
			element: <Navigate to={`/orgAdmin/${PortalPageRoutes.DASHBOARD}`} replace />,
		},
	],
};
