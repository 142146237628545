import { Type } from 'class-transformer';
import { NmrDRM } from './nmr-drm.class';
import { QualityGrade } from '@models/reference-material';

export class NmrDrmDetail extends NmrDRM {
	title: string;
	ermProductNumber: string;
	inChICode: string;
	pH: string;
	purity: string;
	numberOfScans: number;
	frequency: string;
	synonyms: string;
	relaxationTime: string;
	acquisitionTime: string;
	physicalProductNumber: string;
	physicalProductQualityGrade: string;
	generalComment: string;
	instrumentIdentification: string;
	instrumentManufacturer: string;
	measurementUserId: string;
	receiverGain: string;
	pulse: string;
	physicalProductBatchNumber: string;
	siteSupplier: string;
	jdxFileStorageName: string;
	smilesCode: string;
	temperature: string;
	version: string;
	jdxFilePresignedUri: string;
	qualityGrade: QualityGrade;

	@Type(() => Date)
	systemCreationDate: Date;

	@Type(() => Date)
	createTime: Date;

	substanceId: number;
	substanceName: number;
}
