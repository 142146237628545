import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils';
import { checkChallenges, checkMfaType, ILoginUserModel, MfaRules, MfaType, PortalPageRoutes } from '@models';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { authService, notificationService } from '@services/core';
import { useNavigate } from 'react-router';
import useDigitInput from 'react-digit-input'; //TODO: remove this library and implement it own side
import { PromiseUtils } from '@utils/PromiseUtils';
import { CodeInput } from './CodeInput';
import { confirmSignIn } from 'aws-amplify/auth';
const SPECIAL_ERROR_CODE = ['CodeMismatchException'];
const COUNTER = 60;

interface IMFAProps {
	onBackClick: () => void;
	onResendCodeClick: (user: any) => void;
	userInfo?: ILoginUserModel;
	fromLogin: boolean;
	goToLoginStep: () => void;
	mfaType: MfaType;
	onMFASelection: () => void;
	initialCounter?: number;
}
export const MFA: FC<IMFAProps> = ({
	onBackClick,
	userInfo,
	fromLogin,
	onResendCodeClick,
	goToLoginStep,
	mfaType,
	onMFASelection,
	initialCounter = COUNTER,
}) => {
	const { t } = useTranslation('login');
	const [counter, setCounter] = useState(initialCounter || COUNTER);
	const navigate = useNavigate();
	const [mfaCode, setMfaCode] = useState('');
	const digits = useDigitInput({
		acceptedCharacters: MfaRules.ACCEPTED_CHARACTERS,
		length: MfaRules.CODE_LENGTH,
		value: mfaCode,
		onChange: (value) => setMfaCode(value.trim()),
	});
	const [errored, setErrored] = useState(false);
	const checkMFAType = checkMfaType(mfaType);

	useEffect(() => {
		counter > 0 && setTimeout(() => setCounter((prev) => prev - 1), 1000);
	}, [counter]);

	const getPhoneNumber = () => userInfo?.user?.challengeParam?.CODE_DELIVERY_DESTINATION || '';
	const isSpecialError = (code: Maybe<string>) =>
		code && SPECIAL_ERROR_CODE.map((element) => element.toLowerCase()).includes(code.toLowerCase());

	const submitCode = () => {
		if (userInfo?.user) {
			errored && setErrored(false);

			PromiseUtils.runPromise(
				() => authService.confirmSignUp(mfaCode),
				() => navigate(`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.DASHBOARD}`),
				({ code, message }) => {
					notificationService.sendError(isSpecialError(code) ? t(`error.${code}`) : message);

					if (!isSpecialError(code)) {
						goToLoginStep();
					} else {
						setErrored(true);
						setMfaCode('');
					}
				},
			);
		}
	};

	useEffect(() => {
		if (MfaRules.isValid(mfaCode)) {
			submitCode();
		}
	}, [mfaCode]);

	const isMFATypeSelection = () =>
		userInfo?.user?.nextStep?.signInStep && checkChallenges(userInfo.user?.nextStep?.signInStep).isMFAType();

	const resendCode = async () => {
		userInfo &&
			userInfo.email &&
			userInfo.newPassword &&
			PromiseUtils.runPromise(
				() => authService.resendCode(userInfo.email || '', userInfo.newPassword || ''),
				(userData) => {
					setCounter(COUNTER);
					onResendCodeClick(userData);
					isMFATypeSelection() &&
						checkMFAType.isSMS() &&
						confirmSignIn({
							challengeResponse: MfaType.SMS,
						});
				},
				({ message }) => notificationService.sendError(message),
			);
	};

	return (
		<Stack data-testid="login-mfa-wrapper-id">
			<Typography variant="h1" sx={{ marginBottom: '1.5rem' }}>
				{checkMFAType.isSMS() ? <Tr.Login path="mfa.verify-phone" /> : <Tr.Login path="mfa.verify-app" />}
			</Typography>
			<Typography variant="pg-m" sx={{ marginBottom: '1.5rem', fontSize: '0.87rem' }}>
				{checkMFAType.isSMS() ? (
					<Tr.Login path="mfa.verify-desc" options={{ phoneNumber: getPhoneNumber() }} />
				) : (
					<Tr.Login path="mfa.verify-app-desc" />
				)}
			</Typography>
			<CodeInput digits={digits} errored={errored} value={mfaCode} /> {/* take 6-digits from user */}
			<Stack direction="row" justifyContent="space-between" marginTop="0.75rem">
				<Typography variant="subtitle3" sx={{ color: 'grey.800', fontSize: '0.75rem', fontWeight: 700 }}>
					{errored && (
						<>
							<Typography variant="inherit" sx={{ color: 'error.main', display: 'inline' }}>
								<Tr.Login path="mfa.invalid-code.invalid-code" />{' '}
							</Typography>

							<Tr.Login path="mfa.invalid-code.enter-code-again" />
						</>
					)}
				</Typography>
				{checkMFAType.isSMS() &&
					(counter < 1 ? (
						<Typography
							data-testid="mfa-timer-test-id"
							onClick={() => resendCode()}
							variant="label-xs"
							sx={{
								color: 'primary.main',
								pointerEvents: 'initial',
								fontWeight: 700,
								fontSize: '0.75rem',
								cursor: 'pointer',
							}}
						>
							<Tr.Login path="mfa.resend-code" />
						</Typography>
					) : (
						<Typography variant="label-xs" sx={{ color: 'error.main', fontWeight: 700, fontSize: '0.75rem' }}>
							{counter} s
						</Typography>
					))}
			</Stack>
			<Stack sx={{ flexDirection: 'row', alignItems: 'center', marginTop: '2.5rem' }}>
				{fromLogin && (
					<Stack
						data-testid="sign-another-option-id"
						onClick={() => {
							isMFATypeSelection() ? onMFASelection() : onBackClick();
						}}
						direction="row"
						sx={{ cursor: 'pointer', alignItems: 'center' }}
					>
						{!isMFATypeSelection() && <KeyboardBackspaceIcon color="primary" />}
						<Typography variant="label-s" color="primary" marginLeft="0.625rem">
							{isMFATypeSelection() ? <Tr.Login path="mfa.sign-other-option" /> : <Tr.Login path="mfa.back" />}
						</Typography>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};
