import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { formFieldSpacer, mainTitleSpacer, RxUtils, Tr } from '@utils';

import { Stack, Typography } from '@mui/material';
import { UserReferenceModel } from '@services/ir/ir-user-reference';
import { getUserReferenceValidationSchema } from '@schemas/ir/user-reference';
import { useService } from '@hooks';
import { irDrmService } from '@services/ir';
import { useEffect, useState } from 'react';
import { ReferenceStatusCode } from '@components/common/UserReferenceStatus';
import { IUploadedFile } from '@models/file';
import { CreateRefSaveFields, UserReferenceFormLayout } from '../IrReferenceLayout';
import { PortalPageRoutes } from '@models/router';
import { UserReferencesAsyncModal } from '@components/common/UserReferencesAsyncModal';
import { useIrCustomReferenceHub } from '@hooks/ir/useIrCustomerReference';

export const IrCreateUserReference = () => {
	const { t } = useTranslation('irportal');
	const [referenceMaterialId, setreferenceMaterialId] = useState<{ id: number }>({ id: 0 });
	const [uploadedFile, setUploadedFile] = useState<IUploadedFile | null>(null);
	const [showCreateInProgressModal, setShowCreateInProgressModal] = useState(false);

	const navigate = useNavigate();
	const { data: fetchedReference } = useService(() => {
		const body = { drmCode: null };
		return irDrmService.createUserReference(body);
	}, []);

	useEffect(() => {
		if (fetchedReference) {
			formik.setValues(fetchedReference);
			setreferenceMaterialId(fetchedReference);
		}
	}, [fetchedReference]);

	useIrCustomReferenceHub(showCreateInProgressModal, [showCreateInProgressModal], referenceMaterialId?.id);

	const formik = useFormik<UserReferenceModel>({
		initialValues: {
			name: '',
			irReferenceMaterialLibraryId: '',
			casNumber: '',
			linearFormula: '',
			molecularWeight: undefined,
			smilesCode: '',
			synonyms: '',
			samplingTechnique: '',
			numberOfScans: undefined,
			resolution: undefined,
			lastX: undefined,
			firstX: undefined,
			physicalProductBatchNumber: '',
			generalComment: '',
			status: '0',
		},
		validationSchema: getUserReferenceValidationSchema(t),
		onSubmit: (values) => {
			RxUtils.promisify(
				irDrmService.updateUserReference({ ...values, id: referenceMaterialId?.id, status: ReferenceStatusCode.InProgress }),
				() => {
					setShowCreateInProgressModal(true);
				},
			);
		},
	});

	// useEffect(() => {
	// 	dispatch(
	// 		setBreadcrumb({
	// 			tab: <Tr.IrPortal path="user-references.references" />,
	// 			name: <Tr.IrPortal path="user-references.create-reference" />,
	// 		}),
	// 	);
	// }, [t]);

	const createActionName = 'Processing';
	const createModalMessage = 'created';
	const createsuccessPlaceHolder = 'process';

	return (
		<Stack data-testid="create-reference-id" spacing={formFieldSpacer}>
			<form onSubmit={formik.handleSubmit} data-testid="create-reference-form-test-id">
				<Stack direction="row" alignItems="center" spacing={mainTitleSpacer} justifyContent="space-between">
					<Typography variant="h1" sx={{ color: 'text.secondary' }}>
						<Tr.IrPortal path="user-references.create-reference" />
					</Typography>
					<CreateRefSaveFields uploadedFile={uploadedFile} formik={formik} editing={false} />
				</Stack>
				<UserReferenceFormLayout
					uploadedFile={uploadedFile}
					setUploadedFile={setUploadedFile}
					referenceFileId={referenceMaterialId?.id}
					formik={formik}
				/>
				{showCreateInProgressModal ? (
					<UserReferencesAsyncModal
						title={t('user-references.async-updates-modal.title', {
							actionName: createActionName,
							message: createModalMessage,
						})}
						message={t('user-references.async-updates-modal.message', {
							actionName: createActionName,
							message: createModalMessage,
							successPlaceHolder: createsuccessPlaceHolder,
						})}
						subtitle={t('user-references.async-updates-modal.subtitle', {
							actionName: createActionName,
						})}
						onClose={() => {
							navigate(
								`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
							);
						}}
					/>
				) : null}
			</form>
		</Stack>
	);
};
