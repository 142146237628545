import { Filter } from '@services/index';
import { UserStatus } from './user.status.enum';
import { UserRole } from './user.role.enum';

export const CUSTOMER_USER_FILTER: Filter = {
	options: [
		{
			key: 'statuses',
			values: [
				{
					id: '0',
					value: UserStatus.ACTIVE,
				},
				{
					id: '1',
					value: UserStatus.INVITED,
				},
				{
					id: '2',
					value: UserStatus.INACTIVE,
				},
			],
			type: 'dropdown',
		},
		{
			key: 'organizationId',
			values: [],
			type: 'dropdown',
			escapesTranslation: true,
			single: true,
		},
		{
			key: 'laboratoryIds',
			values: [],
			type: 'searchableDropdown',
			escapesTranslation: true,
			loading: false,
			disabled: true,
		},
		{
			key: 'roles',
			values: [
				{
					id: '0',
					value: UserRole.ORG_ADMIN,
				},
				{
					id: '1',
					value: UserRole.LAB_ADMIN,
				},
				{
					id: '2',
					value: UserRole.LAB_MEMBER,
				},
			],
			type: 'dropdown',
		},
	],
};
