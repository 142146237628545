import { FormikApiType, FormikTextfield } from '@components/common';
import { FormikDateRangePicker } from '@components/common/FormikDateRangePicker';
import { Grid, Stack, SxProps, Typography } from '@mui/material';
import { DataTestId } from '@utils/DataTestId';
import { Tr } from '@utils/Translation';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePresetDate } from '../Subscription';
import { FormUtils } from '@utils/Form';

interface IFreeSubscriptionFormProps {
	formik?: FormikApiType;
}

const textInputStyle: SxProps = {
	fieldset: {
		borderColor: 'grey.200',
		padding: 0,
		borderWidth: '1.5px',
	},
	input: {
		backgroundColor: 'grey.50',
	},
	'& .MuiFormHelperText-root.Mui-error': {
		position: 'absolute',
		top: '93%',
	},
};

export const FreeSubscriptionForm: FC<IFreeSubscriptionFormProps> = ({ formik }) => {
	const { t } = useTranslation('admin');
	const rangePresets = usePresetDate([formik?.values?.freeSubscription?.startDate, formik?.values.freeSubscription?.endDate], () => {
		formik?.setFieldValue('freeSubscription.startDate', undefined);
		formik?.setFieldValue('freeSubscription.endDate', undefined);
	});

	return (
		<Stack
			direction="column"
			alignItems="center"
			columnGap={6}
			rowGap={2}
			data-testid={DataTestId.getStaticTestId('form-wrapper-test-id')}
		>
			<Grid container spacing={3} justifyContent={'stretch'}>
				<Grid item xs={12}>
					<Typography variant="h4">
						<Tr.Admin path="organization.free" />
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormikDateRangePicker
						formik={formik}
						value={[formik?.values.freeSubscription.startDate, formik?.values.freeSubscription.endDate]}
						onChange={(values) => {
							if (values) {
								formik?.setFieldValue('freeSubscription.startDate', values[0]);
								formik?.setFieldValue('freeSubscription.endDate', values?.[1]);
								formik?.validateForm();
							}
						}}
						firstTitle={t('organization.start-date')}
						secondTitle={t('organization.end-date')}
						name="freeSubscription.timeRange"
						errorText={t('organization.free-date-range-required')}
						presets={rangePresets}
						status={!formik?.values?.freeSubscription?.startDate || !formik?.values?.freeSubscription.endDate ? 'error' : ''}
					/>
				</Grid>
				<Grid item xs={12}>
					<Stack sx={{ width: 1 }}>
						<FormikTextfield
							formikApi={formik}
							type="number"
							name="freeSubscription.budget"
							value={formik?.values.freeSubscription.budget}
							title={t('organization.number-of-analyses')}
							placeholder={t('organization.number-of-analyses-explanation')}
							variant="outlined"
							inputProps={{ pattern: '[0-9]*', 'data-testid': 'subs-form-number-of-analyses-id' }}
							sx={{ ...textInputStyle }}
							onKeyDown={FormUtils.preventNonNumeric}
							required
						/>
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<FormikTextfield
						formikApi={formik}
						name="freeSubscription.comment"
						value={formik?.values.freeSubscription.comment}
						title={t('organization.subscription-comment')}
						placeholder={t('organization.subscription-comment-placeholder')}
						variant="outlined"
						sx={{
							fieldset: {
								borderWidth: '1.5px',
								borderColor: 'grey.200',
							},
							backgroundColor: 'grey.50',
							'.MuiInputBase-root': {
								padding: '7px 14px!important',
								backgroundColor: 'grey.50',
							},
						}}
						multiline
						rows={4.5}
						containerProps={{ width: 1 }}
						inputProps={{ 'data-testid': 'subscription-comment-id', sx: { maxHeight: '100%' }, maxLength: 250 }}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
};
