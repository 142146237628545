import { Stack, SxProps, Typography } from '@mui/material';
import { NmrDrmOrder, NmrDrmOrderFile, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { FC } from 'react';
import { ReactComponent as CheckCircle } from '@material-symbols/svg-600/rounded/check_circle-fill.svg';
import { ReactComponent as Download } from '@material-symbols/svg-600/outlined/download.svg';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import { RxUtils } from '@utils/Rx';
import { downloadFileFromContent } from '@utils/Download';
import { alertService } from '@services/index';
import { Tr } from '@utils/Translation';
import { FileTypeActionMapping, keysOfChangableFields } from '@components/admin/DrmOrderDetails/drm-order-details.helper';
import { PatchType } from '@models/request-response';
import { DataTestId } from '@utils/DataTestId';
const containerSx: SxProps = {
	border: '1px solid',
	borderColor: 'grey.200',
	borderRadius: 1,
	height: 136,
};

const fileInformationContainerSx: SxProps = {
	paddingY: 2,
	borderBottom: '1px solid',
	borderColor: 'grey.200',
	borderRadius: 0,
	width: '100%',
	alignItems: 'center',
};

type TNmrOrderUploadedFileProps = {
	file: NmrDrmOrderFile;
	nmrDrmOrder?: NmrDrmOrder;
	successCallback?: () => void;
	refreshDrmOrder?: () => void;
	isEditable: boolean;
	type: 'core' | 'additional';
};

export const NmrOrderUploadedFile: FC<TNmrOrderUploadedFileProps> = ({
	file,
	nmrDrmOrder,
	isEditable,
	refreshDrmOrder,
	successCallback,
	type,
}) => {
	const downloadFile = () => {
		RxUtils.promisify(nmrDrmOrderService.getFile(nmrDrmOrder?.id ?? 0, file.id), (val) => {
			downloadFileFromContent(val, file.name);
			return;
		});
	};

	const resetFields = () => {
		if (Object.keys(FileTypeActionMapping).some((action) => file.name.includes(action)) && type === 'core') {
			const payload: unknown[] = [];
			keysOfChangableFields.forEach((key) => {
				payload.push({
					op: PatchType.REPLACE,
					path: `/${key}`,
					value: undefined,
				});
			});
			RxUtils.promisify(nmrDrmOrderService.changeOrder(nmrDrmOrder?.id ?? 0, payload), refreshDrmOrder);
		} else {
			successCallback?.();
		}
	};
	const deleteFile = () => {
		RxUtils.promisify(nmrDrmOrderService.deleteFile(nmrDrmOrder?.id ?? 0, file.id), resetFields);
	};

	const onClickDelete = () => {
		alertService.send({
			confirmTextHeader: <Tr.Admin path="drm-order-details.file-uploader.delete-file" />,
			titleText: <Tr.Common path={'confirmation'} />,
			confirmTextComponent: <Tr.Admin path={'drm-order-details.file-uploader.delete'} />,
			closeTextComponent: <Tr.Admin path="drm-order-details.file-uploader.cancel" />,
			onConfirm: deleteFile,
			content: <Tr.Admin path="drm-order-details.file-uploader.are-you-sure-to-delete" />,
		});
	};

	return (
		<Stack sx={containerSx} alignItems="center">
			<Stack sx={fileInformationContainerSx} spacing={1}>
				<CheckCircle style={{ height: 24, width: 24, fill: '#01884C', transform: 'translateX(1px)' }} />

				<Typography variant="label-xs">
					{file.fileType === 'Other' ? file.name.split('.')?.[1]?.toUpperCase() : file.fileType.toUpperCase()}
				</Typography>
				<Typography variant="label-xs" color="grey.800">
					{file.createdBy}
				</Typography>
			</Stack>
			<Stack direction="row" width="100%" height={'100%'} alignItems="center" justifyContent="center" spacing={1.5}>
				<Download
					style={{ height: 20, width: 20, fill: '#5E5E72', cursor: 'pointer' }}
					onClick={downloadFile}
					data-testid={DataTestId.getStaticTestId('download-file-id')}
				/>
				{isEditable && (
					<Delete
						style={{ height: 20, width: 20, fill: '#5E5E72', cursor: 'pointer' }}
						onClick={onClickDelete}
						data-testid={DataTestId.getStaticTestId('delete-file-id')}
					/>
				)}
			</Stack>
		</Stack>
	);
};
