import { PagedResult } from '@models';
import { httpService, logger } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { concatMap, map, tap } from 'rxjs';
import { nmrConfig } from '../nmrConfig';
import { NmrSubstanceDetail } from './nmr-substance-detail.class';
import { NmrSubstance, NmrSubstanceListResponse } from './nmr-substance.class';

class NmrSubstanceService {
	private getUrl() {
		return `${nmrConfig.url}/Substance`;
	}

	getSubstances(searchParams: URLSearchParams) {
		return httpService
			.get<PagedResult<NmrSubstance>>(`${this.getUrl()}`, ``, {
				params: searchParams,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getSubstances.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(NmrSubstanceListResponse, result)),
			);
	}

	get(id: number) {
		return httpService.get<NmrSubstanceDetail>(this.getUrl(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrSubstanceDetail, result)),
		);
	}
}

export const nmrSubstanceService = new NmrSubstanceService();
