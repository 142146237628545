import { GenericCard } from '@components/common';
import { IrAnalysisType } from '@models';
import { Typography } from '@mui/material';
import { Tr } from '@utils';
import { FC, ReactNode } from 'react';
import { IrAnalysisTypeIndicator } from '../IrAnalysisTypeIndicator';

interface IAlgorithmSummary {
	type: string;
}

const AnalysisTypeTextMapping: Record<IrAnalysisType, ReactNode> = {
	[IrAnalysisType.IDENTIFICATION]: (
		<>
			<Tr.IrPortal path="analysis-result.qualitative-algorithm-summary-content-first" />
			<b style={{ display: 'inline-block' }}>
				<IrAnalysisTypeIndicator
					type={IrAnalysisType.IDENTIFICATION}
					sx={{ display: 'inline-block', color: 'primary.text', fontWeight: 'bold' }}
				/>
			</b>
			<Tr.IrPortal path="analysis-result.qualitative-algorithm-summary-content-next" />
		</>
	),
	[IrAnalysisType.PURITYCHECK]: (
		<>
			<Tr.IrPortal path="analysis-result.purity-check-summary-content-first" />
			<b style={{ display: 'inline-block' }}>
				<IrAnalysisTypeIndicator
					type={IrAnalysisType.PURITYCHECK}
					sx={{ display: 'inline-block', color: 'primary.text', fontWeight: 'bold' }}
				/>
			</b>
			<Tr.IrPortal path="analysis-result.purity-check-summary-content-next" />
		</>
	),
	[IrAnalysisType.QUANTIFICATION]: (
		<>
			<Tr.IrPortal path="analysis-result.quantitative-algorithm-summary-content-first" />
			<b style={{ display: 'inline-block' }}>
				<IrAnalysisTypeIndicator
					type={IrAnalysisType.QUANTIFICATION}
					sx={{ display: 'inline', color: 'primary.text', fontWeight: 'bold' }}
				/>
			</b>
			<Tr.IrPortal path="analysis-result.quantitative-algorithm-summary-content-next" />
		</>
	),
};

export const IrAlgorithmSummary: FC<IAlgorithmSummary> = ({ type }) => (
	<GenericCard dataTestId="algorithm-summary-test-id" id="algorithm-summary-id" sx={{ padding: '2rem 1.5rem 2.5rem' }}>
		<Typography variant="h3" sx={{ color: 'primary.main' }} marginBottom={2}>
			<Tr.IrPortal path="analysis-result.algorithm-summary" />
		</Typography>
		<Typography variant="pg-m">{AnalysisTypeTextMapping[`${type}`]}</Typography>
	</GenericCard>
);
