import { AxiosError } from 'axios';
import { TOptions } from 'i18next';
import { ErrorCode, ERROR_CODE_TRANSLATION_KEY, SpecialError } from './error-code.enum';

export class HttpError {
	ErrorCode: ErrorCode;
	private _message: string;

	set message(msg: string) {
		this._message = msg;
	}

	get message(): string {
		return this.getTranslationKey() || this._message || '';
	}

	get messageTranslationOptions(): TOptions | undefined {
		return this.getTranslationKey() ? { ns: 'common' } : undefined;
	}

	private getTranslationKey() {
		return ERROR_CODE_TRANSLATION_KEY[this.ErrorCode as never];
	}
}

export interface ISpecialError {
	language: TOptions;
	message: string;
}

export const getSpecialError = (err: AxiosError | Error | HttpError): ISpecialError | undefined => {
	const isOffline = !window.navigator.onLine;
	if (isOffline) {
		return { language: { ns: 'common' }, message: ERROR_CODE_TRANSLATION_KEY[SpecialError.OnlineError as never] };
	}

	const code = err instanceof Error && Object.values(SpecialError).find((errorName) => errorName === err.message);
	return code ? { language: { ns: 'common' }, message: ERROR_CODE_TRANSLATION_KEY[code as never] } : undefined;
};
