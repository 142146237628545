import { ReactNode, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { RequestErm } from '@components/common';
import { PageRoutes } from '@models/router';
import { mainTitleSpacer, Tr, TypeUtils } from '@utils';
import { AdminPermission, IrAdminPermission, NmrAdminPermission } from '@models/user';
import { useHasPermissions } from '@hooks';
import { DynamicTechnologyHandler, getSelectedTechnology } from '@components/common/DynamicTechnologyHandler';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

enum ERMManagementType {
	ERM_DATABASE = 'drm-database',
	ERM_REQUESTS = 'drm-requests',
}
interface IDrmManagementTabsMap {
	hasPermission: boolean;
	tab: ReactNode;
	tabType: ERMManagementType;
}
export const DrmManagement = () => {
	const [currentTab, setCurrentTab] = useState<ERMManagementType>(ERMManagementType.ERM_DATABASE);
	const [openRequest, setOpenRequest] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const DrmManagementTabsMap: Record<ERMManagementType, IDrmManagementTabsMap> = {
		[ERMManagementType.ERM_DATABASE]: {
			hasPermission: useHasPermissions(AdminPermission.VIEW_DRM),
			tabType: ERMManagementType.ERM_DATABASE,
			tab: (
				<Tab
					sx={{ textTransform: 'none' }}
					key={'drm-database-tab-key'}
					value={ERMManagementType.ERM_DATABASE}
					data-testid="admin-erm-mgmt-tab-id"
					label={<Tr.Admin path="substance-management.drm-database" />}
				/>
			),
		},
		[ERMManagementType.ERM_REQUESTS]: {
			hasPermission: useHasPermissions(AdminPermission.VIEW_DRM_REQUEST),
			tabType: ERMManagementType.ERM_REQUESTS,
			tab: (
				<Tab
					sx={{ textTransform: 'none' }}
					key={'drm-requests-tab-key'}
					value={ERMManagementType.ERM_REQUESTS}
					data-testid="admin-erm-req-tab-id"
					label={<Tr.Admin path="substance-management.drm-requests" />}
				/>
			),
		},
	};

	useEffect(() => {
		const pieces = location.pathname.split('/');
		const targetTab = pieces?.[pieces.length - 1];

		if (targetTab === PageRoutes.DRM_MANAGEMENT || !targetTab) {
			Object.keys(DrmManagementTabsMap).some((tabType) => {
				const tabValues = TypeUtils.returnValueOfKey<typeof DrmManagementTabsMap, IDrmManagementTabsMap>(
					tabType,
					DrmManagementTabsMap,
				);
				if (tabValues.hasPermission) {
					setCurrentTab(tabValues.tabType);
					navigate(
						`/admin/${PageRoutes.DRM_MANAGEMENT}/${getSelectedTechnology(location)?.toLowerCase() ?? SupportedTechnology.NMR.toLowerCase()}/${tabValues.tabType}`,
						{
							replace: true,
						},
					);

					return true;
				}
			});
		} else {
			setCurrentTab(targetTab as ERMManagementType);
		}
	}, [location]);
	const getAvailableTabs = () => Object.values(DrmManagementTabsMap).map(({ hasPermission, tab }) => hasPermission && tab);

	const hasIrDrmRequestPerm = useHasPermissions(IrAdminPermission.CREATE_DRM_REQUEST);
	const hasNmrDrmRequestPerm = useHasPermissions(NmrAdminPermission.CREATE_DRM_REQUEST);
	const hasViewDrmRequestPerm = DrmManagementTabsMap[ERMManagementType.ERM_REQUESTS].hasPermission;
	return (
		<Stack data-testid="substance-management-wrapper-id" direction="column">
			<Stack direction="row" alignItems="start" justifyContent="space-between">
				<Typography variant="h1" sx={{ color: 'text.secondary', marginBottom: mainTitleSpacer }}>
					<Tr.Admin path="drm-management" />
				</Typography>

				<Stack direction="row">
					<DynamicTechnologyHandler
						mainPath={`/admin/${PageRoutes.DRM_MANAGEMENT}/${currentTab}`}
						appendAfter={PageRoutes.DRM_MANAGEMENT}
					/>

					{(hasIrDrmRequestPerm || hasNmrDrmRequestPerm) && (
						<Button
							data-testid="admin-new-req-id"
							variant="contained"
							onClick={() => setOpenRequest(true)}
							disableElevation
							sx={{ marginLeft: 2 }}
							disabled={
								getSelectedTechnology(location) === SupportedTechnology.NMR ? !hasNmrDrmRequestPerm : !hasIrDrmRequestPerm
							}
						>
							<Tr.Admin path="substance-management.new-request" />
						</Button>
					)}
				</Stack>
			</Stack>

			{hasViewDrmRequestPerm && (
				<Tabs
					textColor="primary"
					onChange={(_, newValue) =>
						navigate(`../${PageRoutes.DRM_MANAGEMENT}/${getSelectedTechnology(location)?.toLowerCase()}/${newValue}`)
					}
					value={currentTab}
					sx={{ marginBottom: '2.5rem', borderBottom: '1px solid #ECECF4' }}
				>
					{getAvailableTabs()}
				</Tabs>
			)}

			<Outlet />
			{currentTab && openRequest && <RequestErm merckAdmin open={openRequest} onCancel={() => setOpenRequest(false)} />}
		</Stack>
	);
};
