import { SingleSelectMenu, SingleSelectMenuCheckmark, SingleSelectMenuOnSelectType } from '@components/common/SingleSelectMenu';
import { NmrDrmOrder, NrmDrmOrderPriority, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { RxUtils } from '@utils/Rx';
import { FC } from 'react';

type TPriorityDropdownProps = {
	nmrDrmOrderData: NmrDrmOrder;
	refresh?: () => void;
	readOnly: boolean;
};

const PriorityColorMapping: Record<NrmDrmOrderPriority, { backgroundColor: string; color: string }> = {
	[NrmDrmOrderPriority.LOW]: {
		backgroundColor: 'grey.100',
		color: '#8B8B9C',
	},
	[NrmDrmOrderPriority.MEDIUM]: {
		backgroundColor: 'warning.main',
		color: '#0F1A2E',
	},
	[NrmDrmOrderPriority.HIGH]: {
		backgroundColor: 'error.main',
		color: '#FFFFFF',
	},
};

export const PriorityDropdown: FC<TPriorityDropdownProps> = ({ nmrDrmOrderData, refresh, readOnly }) => {
	const onSelect: SingleSelectMenuOnSelectType = (item) => {
		if (nmrDrmOrderData.id) {
			RxUtils.promisify(nmrDrmOrderService.changeOrderPriority(nmrDrmOrderData.id, String(item)), () => refresh?.());
		}
	};
	return (
		<SingleSelectMenu
			items={Object.values(NrmDrmOrderPriority)}
			readOnly={readOnly}
			onSelectedItemChange={onSelect}
			selectedItem={nmrDrmOrderData.priority}
			sx={{
				color: PriorityColorMapping[nmrDrmOrderData.priority].color,
				fontSize: '14px',
				fontWeight: 700,
				lineHeight: '12px',
				'&.MuiOutlinedInput-root:has(input)': {
					backgroundColor: PriorityColorMapping[nmrDrmOrderData.priority].backgroundColor,
				},
				fieldset: {
					border: '0px',
				},
				height: '24px',
				pointerEvents: readOnly ? 'none' : 'auto',
				width: 'fit-content',
			}}
			IconProps={{
				sx: {
					display: readOnly ? 'none' : undefined,
					color: `${PriorityColorMapping[nmrDrmOrderData.priority].color} !important`,
					width: 12,
					marginTop: 'auto',
					top: 0,
				},
				style: {
					top: 'unset',
				},
			}}
			SelectDisplayProps={{
				style: {
					minHeight: 0,
					paddingRight: readOnly ? 6 : 24,
					paddingLeft: 6,
				},
			}}
			preceedor={<SingleSelectMenuCheckmark />}
		/>
	);
};
