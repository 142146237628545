import { FC, ReactNode } from 'react';
import { Box, BoxProps, Divider, Stack, Typography } from '@mui/material';
import { ILoginUserModel, MfaType } from '@models';
import { Tr } from '@utils';
import SecurityUpdateGoodOutlined from '@mui/icons-material/SecurityUpdateGoodOutlined';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import East from '@mui/icons-material/East';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { handleMFASelection } from './sendSelection';

const MFAComponentLayout: FC<{ title: ReactNode; desc: ReactNode } & Omit<BoxProps, 'title'>> = ({
	children,
	sx,
	desc,
	title,
	...props
}) => (
	<Box {...props} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', ...sx }}>
		<Stack
			sx={{
				width: '3rem',
				minWidth: '3rem',
				borderRadius: '50%',
				height: '3rem',
				background: 'rgba(1, 136, 76, 0.1)',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{children}
		</Stack>
		<Stack direction="row" sx={{ marginLeft: '1.5rem', width: 1, justifyContent: 'space-between', alignItems: 'center' }}>
			<Stack>
				<Typography variant="h4">{title}</Typography>
				<Typography variant="pg-m">{desc}</Typography>
			</Stack>
			<East sx={{ color: 'grey.800' }} />
		</Stack>
	</Box>
);

const MFATypeComponents: Record<'TOTP' | 'SMS', FC<BoxProps>> = {
	SMS: ({ ...props }) => (
		<MFAComponentLayout
			{...props}
			desc={<Tr.Login path="mfa-selection.sms-desc" />}
			title={<Tr.Login path="mfa-selection.sms-verification" />}
		>
			<SmsOutlined color="primary" />
		</MFAComponentLayout>
	),
	TOTP: ({ ...props }) => (
		<MFAComponentLayout
			{...props}
			desc={<Tr.Login path="mfa-selection.app-desc" />}
			title={<Tr.Login path="mfa-selection.app-verification" />}
		>
			<SecurityUpdateGoodOutlined color="primary" />
		</MFAComponentLayout>
	),
};

interface IMFASelectionProps {
	onSMSSelect: (challengeParam: any) => void;
	onTOTPSelect: (challengeParam: any) => void;
	goToLogin: () => void;
	userInfo?: ILoginUserModel;
}
export const MFASelection: FC<IMFASelectionProps> = ({ goToLogin, userInfo, onSMSSelect, onTOTPSelect }) => {
	const sendMFASelection = (mfaType: MfaType) => {
		userInfo && handleMFASelection(mfaType, onSMSSelect, onTOTPSelect);
	};
	return (
		<Stack data-testid="mfa-typeSelection-id">
			<Typography variant="h1">
				<Tr.Login path="mfa-selection.two-step" />
			</Typography>
			<Typography variant="pg-m" sx={{ marginBottom: '2.5rem', fontSize: '0.875rem' }}>
				<Tr.Login path="mfa-selection.desc" />
			</Typography>

			<MFATypeComponents.SMS data-testid="mfa-sms-selection-id" onClick={() => sendMFASelection(MfaType.SMS)} />
			<Divider sx={{ marginY: '1.5rem' }} />
			<MFATypeComponents.TOTP data-testid="mfa-totp-selection-id" onClick={() => sendMFASelection(MfaType.TOTP)} />
			<Divider sx={{ marginY: '1.5rem' }} />

			<Stack
				data-testid="mfa-goto-login-id"
				onClick={() => goToLogin()}
				direction="row"
				sx={{ cursor: 'pointer', alignItems: 'center', marginTop: '1rem' }}
			>
				<KeyboardBackspace color="primary" />
				<Typography variant="label-s" color="primary" marginLeft="0.625rem">
					<Tr.Login path="mfa-selection.back-to-login" />
				</Typography>
			</Stack>
		</Stack>
	);
};
