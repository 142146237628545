import { FC } from 'react';
import { RxUtils } from '@utils/Rx';
import { DateUtils } from '@utils/Type';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import { IrAdminPermission } from '@models/user';
import { OverflowText } from '@components/common';
import CheckRounded from '@mui/icons-material/CheckRounded';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { useRestrictedComponent } from '@hooks';
import { Card, Stack, Typography, Chip, Divider, Button } from '@mui/material';
import { IrDrmWave, IrDrmWaveStatus, irDrmWaveService } from '@services/ir-drm-pipeline';

export const ReadyWaveCard: FC<{ wave: IrDrmWave; onUpdate: () => void }> = ({ wave, onUpdate }) => {
	const { id, name, orderCount, startDate } = wave;

	const updateDateModified = startDate ? DateUtils.getFormattedDate(startDate, 'dd/MM/yyyy HH:mm') : '-';

	const releaseWave = () => {
		if (!id) return;
		RxUtils.promisify(irDrmWaveService.updateWaveStatus(id, IrDrmWaveStatus.RELEASING), onUpdate);
	};

	return (
		<Card
			variant="elevation"
			sx={{ height: '14rem', padding: 3, display: 'grid' }}
			data-testid={DataTestId.getStaticTestId('wave-ready-card-id')}
		>
			<Stack overflow="hidden">
				<Stack direction="row" justifyContent="space-between">
					<OverflowText variant="h4" enableTooltip={true} sx={{ width: 'calc(100% - 0.5rem)' }}>
						{name}
					</OverflowText>
					<Chip
						size="small"
						color="success"
						variant="filled"
						label={
							<Typography variant="subtitle3" color="white">
								<Tr.Common path="ready" />
							</Typography>
						}
					/>
				</Stack>
				<Divider sx={{ marginY: 1.5 }} />
				<Stack paddingY={1.5} maxHeight={92} overflow="auto">
					<Typography variant="pg-s" fontWeight={700} color="grey.800">
						<Tr.Admin path="wave.ready.message-title" />
					</Typography>
					<Typography variant="pg-s" color="grey.800">
						{updateDateModified}
					</Typography>
				</Stack>
			</Stack>

			<Stack direction="row" alignItems="end" justifyContent="space-between" spacing={0.5}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<TaskOutlinedIcon sx={{ color: 'primary.main', fontSize: 20 }} />
					<Typography variant="subtitle3" color="primary.main">
						{orderCount} <Tr.Common path="files" />
					</Typography>
				</Stack>

				{useRestrictedComponent(
					[IrAdminPermission.RELEASE_WAVE],
					<Button
						size="small"
						variant="outlined"
						onClick={releaseWave}
						startIcon={<CheckRounded />}
						data-testid={DataTestId.getStaticTestId('wave-ready-card-release-btn-id')}
					>
						<Tr.Common path="release" />
					</Button>,
				)}
			</Stack>
		</Card>
	);
};
