import { FC } from 'react';
import { Link, Typography } from '@mui/material';
import { Tr, UsefulLinkUtils } from '@utils';

export const IrCalibrationInstruction: FC<{ isCheckCalibration: boolean }> = ({ isCheckCalibration = false }) => (
	<Typography variant="body1" sx={{ marginTop: 2, fontSize: '0.9rem', whiteSpace: 'pre-line' }}>
		{isCheckCalibration ? (
			<span style={{ whiteSpace: 'pre-line' }}>
				<Tr.IrPortal path="device-management.irinstruction-check-calibration" />
			</span>
		) : (
			<>
				<Tr.IrPortal path="device-management.instruction" />
				<strong>
					<Tr.IrPortal path="device-management.instruction-size" />
				</strong>
				<Tr.IrPortal path="device-management.instruction-continue" />
				<Link sx={{ cursor: 'pointer' }} href={UsefulLinkUtils.GUIDELINE} target="_blank">
					<Tr.IrPortal path="device-management.here" />
				</Link>
			</>
		)}
		.
	</Typography>
);
