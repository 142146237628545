import { FC, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { Tr } from '@utils/Translation';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { NmrDevice, nmrDeviceService } from '@services/nmr';
import { EditDeviceNameDialog } from './EditDeviceNameDialog';
import { alertService } from '@services/core';
import { RxUtils } from '@utils/Rx';
import { DataTestId } from '@utils/DataTestId';
import ReplayIcon from '@mui/icons-material/Replay';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
interface IExistingDeviceHeader {
	calibrationData: Maybe<NmrDevice>;
	refresh: () => void;
}

const sendDeactivateConfirmation = (calibrationData: NmrDevice, refresh: () => void, t: TFunction) => {
	alertService.send({
		confirmTextHeader: <Tr.Portal path="device-management.deactivate-device" />,
		titleText: <Tr.Portal path={'device-management.confirmation'} />,
		confirmTextComponent: <Tr.Portal path={'device-management.deactivate'} />,
		closeTextComponent: <Tr.Portal path="device-management.cancel" />,
		onConfirm: () =>
			RxUtils.promisify(nmrDeviceService.changeDeviceStatus(calibrationData.id, false), () => {
				refresh?.();
			}),
		content: t('device-management.deactivate-message', { deviceName: calibrationData.name }),
		confirmButtonProps: {
			sx: {
				backgroundColor: 'error.main',
			},
		},
	});
};

const sendReactivateConfirmation = (calibrationData: NmrDevice, refresh: () => void, t: TFunction) => {
	alertService.send({
		confirmTextHeader: <Tr.Portal path="device-management.reactivate-device" />,
		titleText: <Tr.Portal path={'device-management.confirmation'} />,
		confirmTextComponent: <Tr.Portal path={'device-management.reactivate'} />,
		closeTextComponent: <Tr.Portal path="device-management.cancel" />,
		onConfirm: () =>
			RxUtils.promisify(nmrDeviceService.changeDeviceStatus(calibrationData.id, true), () => {
				refresh?.();
			}),
		content: t('device-management.reactivate-message', { deviceName: calibrationData.name }),
	});
};

export const ExistingDeviceHeader: FC<IExistingDeviceHeader> = ({ calibrationData, refresh }) => {
	const [editNameOpen, setEditNameOpen] = useState(false);
	const { t } = useTranslation('portal');

	return (
		<Stack direction="row" alignSelf="flex-end" spacing={1.5}>
			{calibrationData && calibrationData?.isActive && (
				<Button variant="text" color="error" onClick={() => sendDeactivateConfirmation(calibrationData, refresh, t)}>
					<Tr.Portal path="device-management.deactivate" />
				</Button>
			)}

			{calibrationData && calibrationData?.isActive && (
				<Button data-testid="existing-device-rename-id" variant="outlined" onClick={() => setEditNameOpen(true)}>
					<EditOutlined sx={{ height: 20, width: 20, marginRight: 1 }} />
					<Tr.Portal path="device-management.rename-device" />
				</Button>
			)}

			{editNameOpen && calibrationData && (
				<EditDeviceNameDialog
					deviceId={calibrationData.id}
					currentName={calibrationData.name}
					onClose={() => setEditNameOpen(false)}
					onSave={() => (refresh(), setEditNameOpen(false))}
				/>
			)}

			{calibrationData && !calibrationData?.isActive && (
				<Button
					data-testid={DataTestId.getStaticTestId('device-reactivation-id')}
					variant="outlined"
					onClick={() => sendReactivateConfirmation(calibrationData, refresh, t)}
				>
					<ReplayIcon sx={{ height: 20, width: 20, marginRight: 1 }} />
					<Tr.Portal path="device-management.reactivate" />
				</Button>
			)}
		</Stack>
	);
};
