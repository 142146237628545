import { TFunction } from 'i18next';
import * as yup from 'yup';

const ONLY_LETTERS_REGEXP = /[a-z]/i;
export const getEditUserValidationSchema: (t: TFunction) => yup.AnyObjectSchema = (t) =>
	yup.object({
		name: yup
			.string()
			.trim()
			.required(t('edit-user.name-required'))
			.matches(ONLY_LETTERS_REGEXP, t('edit-user.wrong-format'))
			.max(100, t('edit-user.max-char')),
		surname: yup
			.string()
			.trim()
			.required(t('edit-user.surname-required'))
			.matches(ONLY_LETTERS_REGEXP, t('edit-user.wrong-format'))
			.max(100, t('edit-user.max-char')),
		mobilePhone: yup
			.string()
			.max(250)
			.matches(/^\+[1-9]{1}[0-9]{3,14}$/, t('edit-user.phone-number-is-invalid'))
			.required(t('edit-user.phone-number-is-required')),
	});
