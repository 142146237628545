import { FC } from 'react';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import { OverflowText } from '@components/common';
import { IrDrmWave } from '@services/ir-drm-pipeline';
import { Card, Stack, Typography, Divider } from '@mui/material';

export const UnsupportedWaveCard: FC<{ wave: IrDrmWave }> = ({ wave }) => {
	const { name } = wave;

	return (
		<Card variant="elevation" sx={{ height: '14rem', padding: 3 }} data-testid={DataTestId.getStaticTestId('wave-unsupported-card-id')}>
			<Stack overflow="hidden">
				<OverflowText variant="h4" enableTooltip={true}>
					{name}
				</OverflowText>
				<Divider sx={{ marginY: 1.5 }} />
				<Stack paddingY={1.5} maxHeight={92} overflow="auto">
					<Typography variant="body2" color="grey.800">
						<Tr.Admin path="wave.unsupported-status" />
					</Typography>
				</Stack>
			</Stack>
		</Card>
	);
};
