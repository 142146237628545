import { DependencyList, useEffect } from 'react';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

interface ISignalRHubConnectionProps {
	hubUrl: string;
	body: (connection: HubConnection) => void;
	shouldStartConnection: () => Maybe<boolean>;
	deps: DependencyList;
}

export const useSignalRHubConnection = ({ hubUrl, body, shouldStartConnection, deps = [] }: ISignalRHubConnectionProps) => {
	useEffect(() => {
		let connection: Maybe<HubConnection> = null;
		if (shouldStartConnection()) {
			try {
				connection = new HubConnectionBuilder()
					.withUrl(hubUrl, { skipNegotiation: true, transport: HttpTransportType.WebSockets })
					.withAutomaticReconnect()
					.build();
				connection
					.start()
					.then(() => connection && body(connection))
					.catch(() => undefined);
			} catch (e) {
				//
			}
		}
		return () => {
			connection && connection.stop();
		};
	}, deps);
};
