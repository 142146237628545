import { Stack, Typography } from '@mui/material';
import { DataTestId, Tr } from '@utils';

export const NotificationSettings = () => (
	<Stack
		direction="row"
		justifyContent="space-between"
		alignItems="center"
		padding="0.8rem 1.2rem"
		borderBottom="1px solid #E1E1EA"
		height="3rem"
		data-testid={DataTestId.getStaticTestId('notification-settings-id')}
	>
		<Typography variant="pg-m" sx={{ fontWeight: 450, fontSize: '1rem', color: 'text.main' }}>
			<Tr.Notification path="notifications" />
		</Typography>
	</Stack>
);
