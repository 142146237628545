import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterSearchParams, useService, setParams, getParams, useHasPermissions } from '@hooks';
import { LabPermission, SortOrder } from '@models';
import { nmrAnalysisService, nmrFilterService } from '@services';
import { onGoingColumns } from '@components/nmr-portal';
import { DataTableBackend } from '@components/common/DataTableBackend';

export const NmrOngoingAnalysisTable: FC = () => {
	const { filtering, setFiltering, searchParams } = useFilterSearchParams('lastActivityDate', SortOrder.DESC);
	const [typedString, setTypedString] = useState('');

	const { t } = useTranslation('common');
	const { t: tPortal, ready } = useTranslation('portal');
	const translate = (path: string) => (ready ? tPortal(path) : '');

	const { data: filterData } = useService(() => nmrFilterService.getAnalysisHistoryFilter());

	const { data: historyData, loading } = useService(() => {
		setTypedString(getParams(searchParams).getQuery());
		setParams(searchParams).setCompleted('false');
		return nmrAnalysisService.getAllWithSearchParams(searchParams);
	}, [searchParams]);

	const hasLabAdminPermission = useHasPermissions(LabPermission.ANALYSIS_OWNER); //TODO: need to change when we have seperate permission for lab admin

	return (
		<>
			<DataTableBackend
				data={historyData ? historyData.data : ([] as any)}
				columns={onGoingColumns(t, tPortal)}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: historyData?.totalPages ?? 1 }}
				searchText={typedString}
				searchPlaceholder={
					hasLabAdminPermission
						? translate('analysis-history.ongoing-analysis-search-placeholder-lab-admin')
						: translate('analysis-history.ongoing-analysis-search-placeholder-lab-member')
				}
				onSearchTextChange={setTypedString}
				sx={{ visibility: loading ? 'hidden' : 'visible', marginX: 2 }}
				headerCellProps={{ sx: { minWidth: 60 } }}
				searchFieldProps={{ sx: { maxWidth: 425, width: 425 }, 'aria-label': 'ongoing-search-id' }}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
		</>
	);
};
