import { Exclude, Type } from 'class-transformer';

export class PagedResult<T> {
	@Exclude()
	private type: Function;

	count: number;
	pageIndex: number;
	totalPages: number;

	@Type((options) => (options?.newObject as PagedResult<T>).type)
	data: T[];
	hasPreviousPage: boolean;
	hasNextPage: boolean;

	constructor(type: Function) {
		this.type = type;
	}
}
