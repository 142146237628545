import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { NmrDrmOrder } from './nmr-drm-order.class';
import { httpService, logger } from '@services/core';
import { Observable, concatMap, map, tap } from 'rxjs';
import { nmrDrmPipelineConfig } from '../nmrDrmPipelineConfig';
import {
	NmrDrmOrderCommentInfo,
	NmrDrmOrderFile,
	NmrDrmOrderTransitionRequestType,
	NmrDrmOrderTransitionResponseType,
	PostNmrOrderFileRequest,
	RequestNmrDrmOrder,
} from './nmr-drm-order.interface';
import { PagedResult, PatchType } from '@models/request-response';
import { NmrDrmOrderTransitionType, NrmDrmOrderPriority } from './nmr-drm-order.enum';
import { NmrDrmOrderBatch } from './nmr-drm-order-batch.class';

class NmrDrmOrderService {
	private getUrl() {
		return `${nmrDrmPipelineConfig.url}/NmrDrmOrder`;
	}

	get(id: number) {
		return httpService
			.get<NmrDrmOrder>(this.getUrl(), `${id}`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(NmrDrmOrder, result)),
			);
	}
	getAllWithSearchParams(urlParams: URLSearchParams) {
		return httpService
			.get<PagedResult<NmrDrmOrder[]>>(this.getUrl(), '', {
				params: urlParams,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllWithSearchParams.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<NmrDrmOrder>(NmrDrmOrder), result)),
			);
	}

	fetchBySubstanceId(substanceId: number): Observable<NmrDrmOrder[]> {
		return httpService.get<NmrDrmOrder[]>(this.getUrl(), `substance/${substanceId}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.fetchBySubstanceId.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrDrmOrder, result)),
		);
	}

	post(requestDrmOrderDto: RequestNmrDrmOrder, errorHandler: 'notification' | 'unhandle') {
		return httpService
			.post(this.getUrl(), '', { body: requestDrmOrderDto, errorHandler: errorHandler })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.post.name}]`, result)));
	}

	changeOrderPriority(id: number, priority: string) {
		return httpService.patch(this.getUrl(), `${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Priority',
					value: priority,
				},
			],
			errorHandler: 'notification',
		});
	}

	changeOrderWave(id: number, wave: number) {
		return httpService.patch(this.getUrl(), `${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/WaveId',
					value: wave,
				},
			],
			errorHandler: 'notification',
		});
	}

	changeOrder(id: number, data: unknown) {
		return httpService
			.patch(this.getUrl(), `${id}`, {
				body: data,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.changeOrder.name}]`, result)));
	}

	postTransition(id: number, transitionData: NmrDrmOrderTransitionRequestType) {
		return httpService
			.post(this.getUrl(), `${id}/transition`, {
				body: transitionData,
				errorHandler: 'unhandle',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.post.name}]`, result)));
	}

	postFile(id: number, data: PostNmrOrderFileRequest) {
		const formData = new FormData();
		formData.append('File', data.File);
		formData.append('FileType', data.FileType);
		return httpService
			.post(this.getUrl(), `${id}/files`, {
				body: formData,
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.postFile.name}]`, result)));
	}

	getFiles(id: number) {
		return httpService
			.get<NmrDrmOrderFile[]>(this.getUrl(), `${id}/files`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getFiles.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(NmrDrmOrderFile, result)),
			);
	}

	getFile(nmrDrmOrderId: number, fileId: number) {
		return httpService
			.get<string>(this.getUrl(), `${nmrDrmOrderId}/files/${fileId}`, {
				errorHandler: 'notification',
				responseType: 'blob',
				headers: { 'Content-Type': 'application/pdf' },
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getFile.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	deleteFile(nmrDrmOrderId: number, fileId: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${nmrDrmOrderId}/files/${fileId}`, {
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.getFile.name}]`, result)));
	}

	listTransitions(nmrDrmOrderId: number, types: Array<NmrDrmOrderTransitionType>) {
		return httpService
			.get<NmrDrmOrderTransitionResponseType[]>(this.getUrl(), `${nmrDrmOrderId}/transitions`, {
				params: { types },
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.listTransitions.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new Array<NmrDrmOrderTransitionResponseType>(), result)),
			);
	}

	addComment(nmrDrmOrderId: number, comment: string) {
		return httpService
			.post(this.getUrl(), `${nmrDrmOrderId}/comments`, {
				body: { content: comment },
				errorHandler: 'unhandle',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.post.name}]`, result)));
	}

	listComments(nmrDrmOrderId: number) {
		return httpService
			.get<NmrDrmOrderTransitionResponseType[]>(this.getUrl(), `${nmrDrmOrderId}/comments`, {
				params: {},
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.listComments.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new Array<NmrDrmOrderCommentInfo>(), result)),
			);
	}

	editComment(nmrDrmOrderId: number, commentId: number, comment: string) {
		return httpService.patch(this.getUrl(), `${nmrDrmOrderId}/comments/${commentId}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Content',
					value: comment,
				},
			],
			errorHandler: 'notification',
		});
	}

	deleteComment(nmrDrmOrderId: number, commentId: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${nmrDrmOrderId}/comments/${commentId}`, {
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.getFile.name}]`, result)));
	}

	batchUpload(file: File, waveId: number, priority: NrmDrmOrderPriority) {
		const formData = new FormData();
		formData.append('File', file);
		formData.append('WaveId', String(waveId));
		formData.append('Priority', priority);
		return httpService
			.post<unknown>(this.getUrl(), `batch`, {
				body: formData,
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.batchUpload.name}]`, result)));
	}

	getBatchUploads(urlParams: URLSearchParams) {
		return httpService
			.get<PagedResult<NmrDrmOrderBatch[]>>(this.getUrl(), 'batch', {
				params: urlParams,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getBatchUploads.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<NmrDrmOrderBatch>(NmrDrmOrderBatch), result)),
			);
	}
}

export const nmrDrmOrderService = new NmrDrmOrderService();
