import { Button, Collapse, Stack, Typography, TypographyProps } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { OverflowText } from '@components/common/OverflowText';
import { useTranslation } from 'react-i18next';
import { DataTestId } from '@utils/DataTestId';

type TViewMore = {
	content: string;
	typographyProps?: TypographyProps;
};

export const ViewMore: FC<TViewMore> = ({ content, typographyProps }) => {
	const [isMoreOpened, setIsMoreOpened] = useState(false);
	const [showMoreButton, setShowMoreButton] = useState(false);
	const { t } = useTranslation('common');
	const overflowDivRef = useRef<HTMLDivElement>(null);
	const typographyRef = useRef<HTMLSpanElement>(null);

	const { sx: typographySx, ...typographyPropsRest } = typographyProps ?? {};

	const getButtonText = () => {
		if (isMoreOpened) {
			return t('view-less');
		}
		return t('view-more');
	};

	return (
		<>
			<div ref={overflowDivRef}>
				{!isMoreOpened && (
					<OverflowText variant="pg-m" onOverflow={() => setShowMoreButton(true)} sx={{ ...typographySx }}>
						{content ?? '-'}
					</OverflowText>
				)}
			</div>
			<Collapse in={isMoreOpened} timeout={250}>
				<Typography
					variant="pg-m"
					whiteSpace="normal"
					sx={{ wordWrap: 'break-word', ...typographySx }}
					ref={typographyRef}
					{...typographyPropsRest}
				>
					{content ?? '-'}
				</Typography>
			</Collapse>

			{showMoreButton && (
				<Stack direction="row" alignItems="start" sx={{ mt: isMoreOpened ? 0 : 0.5 }}>
					<Button
						size="small"
						onClick={() => setIsMoreOpened((prev) => !prev)}
						sx={{ transform: 'translateX(-5px)' }}
						data-testid={DataTestId.getStaticTestId('view-more-id')}
					>
						{getButtonText()}
					</Button>
				</Stack>
			)}
		</>
	);
};
