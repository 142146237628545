import { TypeUtils } from '@utils/Type';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';

export type SingleSelectMenuType = 'selectMenu' | 'anchorEl';

export class SelectMenuItem {
	id: string | undefined | null;
	label: string | ReactNode;
}

export type SingleSelectMenuItemType = SelectMenuItem | string;
export type SingleSelectMenuOnSelectType = <T>(item: T) => void;

export const getValue = (item: SingleSelectMenuItemType | undefined) => {
	if (typeof item === 'string' || !item) {
		return item;
	}
	return item.id;
};

export const getLabel = (item: SingleSelectMenuItemType | undefined, t?: TFunction) => {
	if (typeof item === 'string' || !item) {
		return t ? t(item ?? '') : item;
	}

	if (typeof item.label !== 'string') {
		return item.label;
	}
	return t ? t(item.label) : item.label;
};

export const getOnSelectFunctionValue: <T>(item: T, newValue: unknown) => SingleSelectMenuItemType = (item, newValue) => {
	if (typeof item === 'string' || !item) {
		return String(newValue);
	}
	return TypeUtils.transform(SelectMenuItem, newValue);
};

export const getRenderedValue: (items: readonly SingleSelectMenuItemType[], renderedValue: unknown, defaultLabel?: String) => unknown = (
	items,
	renderedValue,
	defaultLabel,
) => {
	const value = String(renderedValue);
	if (items.length === 0) {
		return defaultLabel ?? '';
	}
	const typeOfItems = typeof items[0];

	if (typeOfItems === 'string') {
		return renderedValue ? renderedValue : defaultLabel;
	}
	const matchingObject = items.filter((item) => TypeUtils.transform(SelectMenuItem, item).id === value)[0];
	if (!matchingObject || !renderedValue || typeof matchingObject === 'string') {
		return defaultLabel ?? '';
	}

	return matchingObject?.label ? matchingObject?.label : defaultLabel;
};
