import { forwardRef } from 'react';
import { Card, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OverflowText } from '@components/common';
import { NmrDrmOrder, NmrDrmOrderStatus } from '@services/nmr-drm-pipeline';
import { NmrDrmOrderAssignees } from '../NmrDrmOrderAssignees';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import { DataTestId } from '@utils/DataTestId';
import { PriorityDropdown, RejectionSeverity } from '@components/admin/NmrPipelineBacklog/TableComponents';
import { DrmPipelineFlag } from '@components/admin/DrmPipelineFlag';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '@models/router/page-routes.enum';
import { getDrmOrderFiles } from '@components/admin/NmrPipelineBacklog/nmr-pipeline-helper';

type TNmrDrmOrderCardProps = {
	order: NmrDrmOrder;
	refreshNmrDrmOrders: () => void;
};

const iconProps = { width: 20, height: 20, marginTop: 0.8 };
export const NmrDrmOrderCard = forwardRef<HTMLDivElement, TNmrDrmOrderCardProps>(({ order, refreshNmrDrmOrders }, ref) => {
	const { t } = useTranslation('admin');
	const navigate = useNavigate();

	const fileTypes = getDrmOrderFiles(order);
	return (
		<Card
			ref={ref}
			sx={{
				padding: 2,
				overflow: 'visible',
				boxShadow: '0px 1px 4px 0px rgba(15, 26, 46, 0.15),0px 0px 1px 0px rgba(15, 26, 46, 0.15)',
				cursor: 'pointer',
			}}
			onClick={() => navigate(`/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.DRM_ORDER_DETAILS}/${order.id}`)}
		>
			<Stack flexDirection="column">
				<Stack spacing={1.5}>
					<Stack flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={0.5}>
						<Typography variant="label-m" color="text.primary">
							{order.physicalProductNumber}
						</Typography>
						<PriorityDropdown readOnly nmrDrmOrderData={order} />
					</Stack>
					<Divider />
				</Stack>

				<Stack sx={{ marginTop: 2, marginBottom: 1 }}>
					<OverflowText enableTooltip={true} variant="pg-s" sx={{ lineHeight: '180%' }} color="grey.800">
						{order.wave.name}
					</OverflowText>
					<Typography variant="pg-s" sx={{ lineHeight: '180%' }} color="grey.800">
						CAS: {order.casNumber}
					</Typography>
				</Stack>

				{fileTypes.length > 0 && (
					<Stack
						direction="row"
						divider={<Divider orientation="vertical" flexItem />}
						justifyContent="space-evenly"
						alignItems="center"
						sx={{ height: 32, marginBottom: 2, borderRadius: '4px', border: '1px solid', borderColor: 'grey.200' }}
						data-testid={DataTestId.getStaticTestId('nmr-drm-pipeline-production-file-types')}
					>
						{fileTypes.map((fileType) => (
							<OverflowText
								textAlign="center"
								paddingX={0.5}
								variant="pg-xs"
								key={fileType}
								sx={{ lineHeight: '180%', zIndex: 1 }}
								enableTooltip={true}
								color="grey.800"
							>
								{t(`drm-pipeline.nmr.production.file-types.${fileType}`)}
							</OverflowText>
						))}
					</Stack>
				)}

				<Stack direction="row" justifyContent="space-between">
					<NmrDrmOrderAssignees order={order} />
					<Stack direction="row">
						{order.status === NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT && (
							<Tooltip placement="top" title={t('drm-pipeline.nmr.production.waiting-for-measurement')}>
								<QueryStatsOutlinedIcon sx={{ color: 'error.main', marginRight: 1, ...iconProps }} />
							</Tooltip>
						)}

						{order.isRejected && order.rejectionSeverity && (
							<RejectionSeverity
								iconSx={{ marginRight: 1 }}
								severity={order.rejectionSeverity}
								testId={DataTestId.getStaticTestId('nmr-drm-pipeline-production-rejected-icon')}
							/>
						)}

						<DrmPipelineFlag order={order} tooltipEnabled refreshData={refreshNmrDrmOrders} />
					</Stack>
				</Stack>
			</Stack>
		</Card>
	);
});
