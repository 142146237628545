import { GenericCard } from '@components/common';
import { Stack, SxProps, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, ReactNode } from 'react';
import { DroppableArea } from './DroppableArea';
import { NmrDrmOrder, NmrDrmOrderFile, NmrDrmOrderStatusChangeErrors } from '@services/nmr-drm-pipeline';
import { NmrOrderUploadedFile } from './UploadedFile';
import { DrmOrderDetailsError } from '../DrmOrderDetails/DrmOrderDetailsError';
import { useSelector } from 'react-redux';
import { transitionErrorsSelector } from '@store/slices/nmr/pipeline.slice';
import { Scroll } from '@utils/Theme';
import { FILE_UPLOADER_WIDTHS } from './nmr-drm-order-file-uploader.helper';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
type TNmrDrmOrderFileUploader = {
	title: string | ReactNode;
	explanation: string | ReactNode;
	onFileUpload: (file: File, switchLoading: () => void) => void;
	files?: NmrDrmOrderFile[];
	maxFiles: number;
	isEditable: boolean;
	isCore: boolean;
	nmrDrmOrder?: NmrDrmOrder;
	successCallback?: () => void;
	refreshDrmOrder?: () => void;
	tooltipText?: string;
};

const uploadedFilesSx: SxProps = {
	borderRadius: 1,
	border: '1px solid #E1E1EA',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 2,
	height: 136,
};

const EmptyFileUpload = () => {
	return (
		<Stack sx={uploadedFilesSx}>
			<Typography variant="label-s" color="grey.800">
				<Tr.Admin path="drm-order-details.file-uploader.no-files-uploaded-yet" />
			</Typography>
		</Stack>
	);
};

export const NmrDrmOrderFileUploader: FC<TNmrDrmOrderFileUploader> = ({
	title,
	explanation,
	onFileUpload,
	files,
	maxFiles,
	isEditable,
	isCore,
	nmrDrmOrder,
	refreshDrmOrder,
	successCallback,
	tooltipText,
}) => {
	const definedFileLength = (files?.length ?? 0) + (isEditable ? 1 : 0);

	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1350));

	const getWidth = () => {
		const maxCount = media ? FILE_UPLOADER_WIDTHS.DEFAULT : FILE_UPLOADER_WIDTHS.XL;
		const columnFileCount = definedFileLength <= maxCount ? definedFileLength : maxCount;
		const fileGridSize = definedFileLength <= maxCount ? definedFileLength : maxCount;
		const calculatedWidth = `calc(${(100 / fileGridSize).toFixed(2)}% - ${
			(8 * 2 * (columnFileCount ? columnFileCount - 1 : 0)) / columnFileCount
		}px)`;
		return calculatedWidth;
	};
	const transitionErrors = useSelector(transitionErrorsSelector);

	const isErrorVisible = transitionErrors.includes(NmrDrmOrderStatusChangeErrors.ORDER_FILES_MISSING);
	return (
		<GenericCard>
			<Stack direction="row" justifyContent="space-between">
				<Typography
					variant="h4"
					sx={{
						'&::after': {
							content: isCore && isEditable ? '"*"' : '""',
							color: 'error.main',
						},
					}}
				>
					{title}
				</Typography>
				<Typography variant="caption-s">
					<Tr.Admin path="drm-order-details.file-uploader.uploaded" options={{ count: files?.length ?? 0 }} />
				</Typography>
			</Stack>
			<Stack sx={{ marginTop: 1, marginBottom: 2 }} direction="row" justifyContent="space-between">
				<Typography variant="pg-m" color="grey.800">
					{explanation}
				</Typography>
				{isEditable && tooltipText && (
					<Tooltip title={tooltipText} placement="top-end">
						<InfoOutlined sx={{ width: 20, height: 20, color: 'grey.800' }} />
					</Tooltip>
				)}
			</Stack>

			{!isEditable && files?.length === 0 && <EmptyFileUpload />}
			<Stack
				direction="row"
				spacing={2}
				sx={{
					paddingBottom: definedFileLength >= 3 && !isCore ? 0.5 : 0,
					...Scroll.X,
				}}
			>
				{isEditable && (files?.length ?? 0) < maxFiles && (
					<Stack width={getWidth()} minWidth={getWidth()}>
						<DroppableArea onFileUpload={onFileUpload} generalFileType={isCore ? 'core' : 'additional'} />
					</Stack>
				)}

				{files?.map((file) => (
					<Stack key={file.id} width={getWidth()} minWidth={getWidth()}>
						<NmrOrderUploadedFile
							type={isCore ? 'core' : 'additional'}
							file={file}
							nmrDrmOrder={nmrDrmOrder}
							successCallback={successCallback}
							isEditable={isEditable}
							refreshDrmOrder={refreshDrmOrder}
						/>
					</Stack>
				))}
			</Stack>
			{isCore && isErrorVisible && (
				<Stack marginTop={1}>
					<DrmOrderDetailsError fileError={true} />
				</Stack>
			)}
		</GenericCard>
	);
};
