import { SingleSelectMenu } from '@components/common/SingleSelectMenu';
import { Button, Stack, Typography } from '@mui/material';
import {
	NmrDrmOrder,
	NmrDrmOrderStatus,
	NmrDrmOrderWave,
	NrmDrmOrderPriority,
	UnassignedWave,
	nmrDrmOrderService,
} from '@services/nmr-drm-pipeline';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pipelineSelector, setTriggerWaveRefresh } from '@store/slices/nmr/pipeline.slice';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { PagedResult, PatchType } from '@models/request-response';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { waveToSelectItem } from '@models';
export interface SelectedNmrDrmOrder extends NmrDrmOrder {
	index: number;
}

type TMultiselectDrmOrderProps = {
	selectedItems: SelectedNmrDrmOrder[];
	onClose: () => void;
	refreshBacklog: () => void;
	backlogData?: PagedResult<NmrDrmOrder> | undefined;
};

type FormFields = Extendable<{
	priority: NrmDrmOrderPriority;
	wave: NmrDrmOrderWave;
}>;

const priorityList = Object.values(NrmDrmOrderPriority);
export const MultiselectDrmOrder: FC<TMultiselectDrmOrderProps> = ({ selectedItems, refreshBacklog, onClose, backlogData }) => {
	const { t } = useTranslation('admin');
	useEffect(() => {
		if (selectedItems.length === 0) {
			formik.resetForm();
		}
		const tableRows = document.querySelectorAll('[role="row"]');
		tableRows.forEach((item, index) => {
			if (!(item instanceof HTMLTableRowElement)) {
				return;
			}
			if (index === 0) {
				return;
			}
			const checkboxChecked = item.children[0]?.children[0].className.includes('Mui-checked');
			if (checkboxChecked) {
				item.style.backgroundColor = '#F8F8FC';
			} else {
				item.style.backgroundColor = '#FFFFFF';
			}
		});
	}, [selectedItems, backlogData]);

	const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);

	const dispatch = useDispatch();
	useEffect(() => {
		if (needsRefresh) {
			formik.resetForm();
			refreshBacklog();
			dispatch(setTriggerWaveRefresh());
			setNeedsRefresh(false);
		}
	}, [needsRefresh]);
	const formik = useFormik<FormFields>({
		enableReinitialize: true,
		initialValues: {
			priority: t('drm-pipeline.nmr.backlog.change-priority'),
			wave: UnassignedWave.id,
		},
		onSubmit: (changes) => {
			const payload: unknown[] = [];
			if (priorityList.includes(changes.priority)) {
				payload.push({
					op: PatchType.REPLACE,
					path: '/Priority',
					value: changes.priority,
				});
			}
			if (`${formik.values.wave}` !== `${UnassignedWave.id}`) {
				payload.push({
					op: PatchType.REPLACE,
					path: '/WaveId',
					value: changes.wave === 'undefined' ? null : changes.wave,
				});
			}
			const resolvedPromises = selectedItems.map((_) => false);
			selectedItems.forEach((item, index) => {
				if (item.id) {
					RxUtils.promisify(nmrDrmOrderService.changeOrder(item.id, payload), () => {
						resolvedPromises[`${index}`] = true;
						if (resolvedPromises.every((p) => !!p)) {
							setNeedsRefresh(true);
						}
					});
				}
			});
		},
	});

	const { waveData } = useSelector(pipelineSelector);
	const currentWaveItems = [waveToSelectItem(UnassignedWave), ...(waveData?.map((wave) => waveToSelectItem(wave)) ?? [])];
	const selectedItemsHaveWave = selectedItems.every((item) => !!item.wave);
	if (selectedItems.length === 0) {
		return null;
	}

	const changeStatusToReady = () => {
		const resolvedPromises = selectedItems.map((_) => false);
		selectedItems.forEach((item, index) => {
			if (item.id)
				RxUtils.promisify(
					nmrDrmOrderService.postTransition(item.id, {
						status: NmrDrmOrderStatus.READY,
					}),
					() => {
						resolvedPromises[`${index}`] = true;
						if (resolvedPromises.every((p) => !!p)) {
							setNeedsRefresh(true);
						}
					},
				);
		});
	};
	return (
		<Stack justifyContent="space-between" direction="row" sx={{ paddingY: 1, paddingX: 2, borderBottom: '1.25px solid #DCDCE8' }}>
			<form onSubmit={formik.handleSubmit}>
				<Stack direction="row" spacing={2} alignItems="center">
					<Typography variant="label-s">{selectedItems.length} Selected</Typography>
					<SingleSelectMenu
						items={priorityList}
						selectedItem={formik.values.priority}
						onSelectedItemChange={(item) => formik.setFieldValue('priority', item)}
						sx={{
							width: '160px',
							height: '32px',
						}}
						IconProps={{
							sx: {
								color: '#01884C !important',
							},
						}}
					/>
					<SingleSelectMenu
						items={currentWaveItems}
						selectedItem={formik.values.wave !== UnassignedWave.id ? formik.values.wave : undefined}
						defaultLabel={t('drm-pipeline.nmr.backlog.change-wave')}
						onSelectedItemChange={(item) => formik.setFieldValue('wave', item)}
						sx={{
							width: '218px',
							height: '32px',
						}}
					/>
					{formik.dirty && (
						<Stack direction="row" spacing={1}>
							<Button
								variant="outlined"
								size="small"
								sx={{ width: 32 }}
								style={{ border: '2px solid !important' }}
								onClick={() => (formik.resetForm(), onClose())}
							>
								<CloseIcon sx={{ width: 16, height: 16 }} />
							</Button>
							<Button variant="contained" size="small" type="submit" sx={{ width: 32 }}>
								<DoneIcon sx={{ width: 16, height: 16 }} />
							</Button>
						</Stack>
					)}
				</Stack>
			</form>
			<Button disabled={!selectedItemsHaveWave || formik.dirty} variant="contained" size="small" onClick={changeStatusToReady}>
				<Tr.Admin path="drm-pipeline.nmr.backlog.mark-as-ready" />
			</Button>
		</Stack>
	);
};
