import { concatMap, tap, map } from 'rxjs/operators';
import { RxUtils, TypeUtils } from '@utils';
import { logger, httpService } from '@services';
import { PagedRequest, PagedResult } from '@models';
import { CreateFavoriteReference } from './create-favorite-reference.modal';
import { FavoriteReference } from './favorite-reference-material.class';
import { nmrConfig } from '../nmrConfig';

export class FavoriteReferencesService {
	private getUrl() {
		return `${nmrConfig.url}/FavoriteReference`;
	}

	get(id: number) {
		return httpService.get<FavoriteReference>(this.getUrl(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(FavoriteReference, result)),
		);
	}

	getAll(req: Partial<PagedRequest>) {
		const params = new URLSearchParams();
		Object.entries(req).forEach(([k, v]) => params.append(k, v.toString()));
		return this.getAllWithParams(params);
	}

	getAllWithParams(params: URLSearchParams) {
		return httpService
			.get<PagedResult<FavoriteReference[]>>(this.getUrl(), '', {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAll.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<FavoriteReference>(FavoriteReference), result)),
			);
	}

	create(dto?: CreateFavoriteReference) {
		return httpService.post<FavoriteReference>(this.getUrl(), '', { body: dto, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.create.name}]`, result)),
			map((result) => TypeUtils.transform(FavoriteReference, result)),
		);
	}

	delete(id: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${id}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.delete.name}]`, result)));
	}

	update(id: number, name: string) {
		return httpService
			.put<unknown>(this.getUrl(), `${id}`, {
				body: { id, name },
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.update.name}]`, result)));
	}
}

export const favoriteReferencesService = new FavoriteReferencesService();
