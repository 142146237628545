import { PortalPageRoutes } from '@models/router';
import { TypeUtils } from '@utils/Type';
import { TFunction } from 'i18next';

export enum IrAnalysisStage {
	NEW = 'New',
	DETAILS_FILLED = 'DetailsFilled',
	EXECUTING = 'Executing',
	EXECUTED = 'Executed',
	CONFIRMED_BY_LAB_MEMBER = 'ConfirmedByLabMember',
	APPROVED_BY_LAB_ADMIN = 'ApprovedByLabAdmin',
	REJECTED_BY_LAB_MEMBER = 'RejectedByLabMember',
	REJECTED_BY_LAB_ADMIN = 'RejectedByLabAdmin',
}

export const getIrAnalysisRoute = (status: IrAnalysisStage) => {
	switch (status) {
		case IrAnalysisStage.NEW:
		case IrAnalysisStage.DETAILS_FILLED:
			return PortalPageRoutes.ANALYSIS;
		case IrAnalysisStage.EXECUTING:
		case IrAnalysisStage.EXECUTED:
		case IrAnalysisStage.CONFIRMED_BY_LAB_MEMBER:
		case IrAnalysisStage.APPROVED_BY_LAB_ADMIN:
		case IrAnalysisStage.REJECTED_BY_LAB_ADMIN:
		case IrAnalysisStage.REJECTED_BY_LAB_MEMBER:
			return PortalPageRoutes.ANALYSIS_RESULT;
	}
};

type TStageConfig = {
	stageMessage: string;
	analysisRoute: PortalPageRoutes;
	stageIndex: 1;
	color: string;
};
const IRAnalysisStageConfig = {
	[IrAnalysisStage.NEW]: {
		stageMessage: 'analysis-status-message-new',
		analysisRoute: PortalPageRoutes.ANALYSIS,
		stageIndex: 1,
		color: 'secondary.main',
	},
	[IrAnalysisStage.DETAILS_FILLED]: {
		stageMessage: 'analysis-status-message-details-filled',
		analysisRoute: PortalPageRoutes.ANALYSIS,
		stageIndex: 2,
		color: 'info.main',
	},
	[IrAnalysisStage.EXECUTING]: {
		stageMessage: 'analysis-status-message-executing',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 3,
		color: 'info.800',
	},
	[IrAnalysisStage.EXECUTED]: {
		stageMessage: 'analysis-status-message-executed',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 4,
		color: 'success.400',
	},
	[IrAnalysisStage.CONFIRMED_BY_LAB_MEMBER]: {
		stageMessage: 'analysis-status-message-confirmed-by-operator',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 5,
		color: 'success.400',
	},
	[IrAnalysisStage.APPROVED_BY_LAB_ADMIN]: {
		stageMessage: 'analysis-status-message-confirmed-by-lab-manager',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 6,
		color: 'success.400',
	},
	[IrAnalysisStage.REJECTED_BY_LAB_MEMBER]: {
		stageMessage: '',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 0,
		color: 'error.main',
	},
	[IrAnalysisStage.REJECTED_BY_LAB_ADMIN]: {
		stageMessage: '',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 0,
		color: 'error.main',
	},
};

const getValueOfConfig = (stage: IrAnalysisStage) => {
	return (
		TypeUtils.returnValueOfKey<typeof IRAnalysisStageConfig, TStageConfig>(stage, IRAnalysisStageConfig) ??
		IRAnalysisStageConfig[IrAnalysisStage.NEW]
	);
};

export const getIRStageMessage = (stage: IrAnalysisStage, t: TFunction) => {
	return t(getValueOfConfig(stage)?.stageMessage);
};

export const getIRAnalysisRoute = (stage: IrAnalysisStage) => {
	return getValueOfConfig(stage)?.analysisRoute;
};

export const getIRAnalysisStageIndex = (stage: IrAnalysisStage) => {
	return getValueOfConfig(stage)?.stageIndex;
};

export const getIRStageColor = (stage: IrAnalysisStage) => {
	return getValueOfConfig(stage)?.color;
};
