import { FC, useRef } from 'react';
import { Checkbox, MenuItem, Typography, Select, SelectProps, IconButton, TypographyProps } from '@mui/material';
import { IFilterValues } from '@models';
import { DataTestId, Scroll, Tr } from '@utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SingleSelectMenuCheckmark } from '@components/common/SingleSelectMenu';
interface ISelectDropdownProps {
	selectedValues: string[];
	options: IFilterValues[];
	elementTitle: string;
	escapesTranslation?: boolean;
	filterOption?: boolean;
	typographyProps?: TypographyProps;
	onClear?: () => void;
}

export const SelectDropDown: FC<SelectProps & ISelectDropdownProps> = ({
	options,
	selectedValues,
	elementTitle,
	escapesTranslation,
	filterOption = true,
	typographyProps,
	required,
	sx,
	onClear,
	...props
}) => {
	const hovered = useRef<boolean>(false);
	return (
		<>
			<Typography
				variant="label-s"
				color="grey.800"
				sx={{ marginBottom: '0.6rem' }}
				className={required ? 'required' : ''}
				{...typographyProps}
			>
				{filterOption ? <Tr.Filter path={`filter.${elementTitle}`} /> : elementTitle}
			</Typography>
			<Select
				onMouseEnter={() => (hovered.current = true)}
				onMouseLeave={() => (hovered.current = false)}
				multiple
				value={selectedValues}
				sx={{
					marginBottom: '1rem',
					height: '40px',
					...sx,
				}}
				MenuProps={{
					sx: { maxHeight: '400px' },
					slotProps: {
						paper: {
							sx: {
								...Scroll.Y,
								mr: 1,
							},
						},
					},
				}}
				IconComponent={() => (
					<IconButton
						sx={{
							position: 'absolute',
							pointerEvents: 'none',
							right: 0,
						}}
					>
						<KeyboardArrowDownIcon
							sx={{
								color: 'primary.main',
							}}
						/>
					</IconButton>
				)}
				onClick={(clickEvent) => {
					if (!props.multiple && (clickEvent.target as HTMLOptionElement).selected === true) {
						onClear?.();
					}
				}}
				{...props}
				data-testid={DataTestId.getTestId('filter-dropdown')}
			>
				{options.map((item, index) => (
					<MenuItem
						value={item.id}
						data-testid={`menu-item-${index}`}
						key={`${index}-${item.id}`}
						sx={{ padding: 0, height: '3rem', borderBottom: '1px solid #E1E1EA', maxHeight: '40px' }}
					>
						{props.multiple && <Checkbox checked={selectedValues.includes(item.id)} sx={{ paddingLeft: '0.75rem' }} />}
						{!props.multiple && (
							<SingleSelectMenuCheckmark
								sx={{ ml: '0.75rem', visibility: selectedValues.includes(item.id) ? 'inherit' : 'hidden' }}
							/>
						)}
						{escapesTranslation ? item.value : <Tr.Filter path={`filter.${item.value.toLowerCase()}`} />}
					</MenuItem>
				))}
			</Select>
		</>
	);
};
