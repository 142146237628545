import { Type } from 'class-transformer';

export class FavoriteReference {
	id: number;
	name: string;

	@Type(() => Date)
	date: Date;

	drmCode: string;
	referenceMaterialName: string;
	referenceMaterialId: number;
}
