import { FormikSubmitButton, FormikTextfield, ModalDialog } from '@components/common';
import { Button, Stack, Typography } from '@mui/material';
import { IrLibrary, irLibraryService } from '@services/ir';
import { RxUtils } from '@utils/Rx';
import { formFieldSpacer } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type TEditIrLibraryModal = {
	library: IrLibrary;
	onClose: () => void;
	onSuccess: () => void;
};
type FormFields = Extendable<{ libraryName: string }>;
export const EditIrLibraryModal: FC<TEditIrLibraryModal> = ({ library, onClose, onSuccess }) => {
	const { t } = useTranslation('irportal');

	const formik = useFormik<FormFields>({
		initialValues: {
			libraryName: library.libraryName,
		},
		validationSchema: yup.object({
			libraryName: yup
				.string()
				.max(250, t('user-references.library-name-max-250'))
				.required(t('user-references.library-name-required')),
		}),
		onSubmit: (values) => {
			RxUtils.promisify(irLibraryService.put(library.libraryId, values.libraryName), () => {
				onSuccess();
			});
		},
	});
	return (
		<ModalDialog
			maxWidth="xs"
			open={true}
			variant="secondary"
			onClose={onClose}
			title={<Typography variant="pg-s">{t('user-references.edit')}</Typography>}
			contentProps={{ sx: { paddingX: 8 } }}
		>
			<Stack data-testid="edit-library-id" direction="column" sx={{ mt: 3 }}>
				<Typography variant="h3" textAlign="center" sx={{ mb: 3 }}>
					<Tr.IrPortal path="user-references.edit-user-library" />
				</Typography>
				<form onSubmit={formik.handleSubmit}>
					<Stack direction="column" rowGap={formFieldSpacer} justifyContent="space-between">
						<FormikTextfield
							formikApi={formik}
							name="libraryName"
							title={t('user-references.library-name')}
							placeholder={t('user-references.library-name-placeholder')}
							variant="outlined"
							fullWidth
							containerProps={{ sx: { flexGrow: 1 } }}
							required
							inputProps={{ 'data-testid': 'library-name-id' }}
						/>

						<Stack direction="row" justifyContent="flex-end" columnGap={1} sx={{ mb: 7 }}>
							<Button variant="outlined" data-testid="cancel-button-id" disableElevation fullWidth onClick={onClose}>
								<Tr.IrPortal path="user-references.cancel" />
							</Button>
							<FormikSubmitButton
								disabled={!formik.isValid || !formik.dirty}
								fullWidth
								formik={formik}
								data-testid="save-library-name-id"
							>
								<Tr.IrPortal path="user-references.save" />
							</FormikSubmitButton>
						</Stack>
					</Stack>
				</form>
			</Stack>
		</ModalDialog>
	);
};
