import Flag from '@mui/icons-material/Flag';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import { FC } from 'react';
import { NmrDrmOrder, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { DataTestId } from '@utils/DataTestId';
import { useHasPermissions } from '@hooks';
import { NmrAdminPermission } from '@models/user';
import { RxUtils } from '@utils/Rx';
import { PatchType } from '@models/request-response';

type TDrmPipelineFlagProps = {
	order: NmrDrmOrder;
	refreshData?: () => void;
	tooltipEnabled?: boolean;
};

const iconProps = { width: 20, height: 20, marginTop: 0.8 };

export const DrmPipelineFlag: FC<TDrmPipelineFlagProps> = ({ order, refreshData, tooltipEnabled }) => {
	const { t } = useTranslation('admin');

	const hasChangeFlagStatusPermission = useHasPermissions(NmrAdminPermission.DRM_ORDER_CHANGE_FLAG);

	if (!tooltipEnabled) {
		return order.isFlagged ? (
			<Flag sx={{ color: 'error.main' }} />
		) : hasChangeFlagStatusPermission ? (
			<FlagOutlined sx={{ color: 'grey.500' }} />
		) : null;
	}

	return (
		<Tooltip
			placement="top"
			title={t(`drm-pipeline.nmr.production.${order.isFlagged ? 'unflag' : 'flag'}-order`)}
			data-testid={DataTestId.getStaticTestId(`nmr-drm-pipeline-production-${order.isFlagged ? '' : 'not-'}flagged-icon`)}
		>
			<IconButton
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					event.stopPropagation();
					hasChangeFlagStatusPermission &&
						RxUtils.promisify(
							nmrDrmOrderService.changeOrder(order.id ?? 0, [
								{
									op: PatchType.REPLACE,
									path: '/IsFlagged',
									value: !order.isFlagged,
								},
							]),
							() => refreshData?.(),
						);
				}}
				sx={{ width: 20, height: 20 }}
			>
				{order.isFlagged ? (
					<Flag sx={{ color: 'error.main', ...iconProps }} />
				) : hasChangeFlagStatusPermission ? (
					<FlagOutlined sx={{ color: 'grey.500', ...iconProps }} />
				) : null}
			</IconButton>
		</Tooltip>
	);
};
