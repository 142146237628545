export interface IAccessory {
	id: number;
	name: string;
}
export const accessoryType: IAccessory[] = [
	{
		id: 0,
		name: 'ATR',
	},
	{
		id: 1,
		name: 'Transmission',
	},
];
