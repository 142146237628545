import { t } from 'i18next';

type ErrorPropertyBag = {
	translate: boolean;
	ns: string;
};

export class MerckError extends Error {
	public options: ErrorPropertyBag = { translate: true, ns: 'common' };

	constructor(
		public message: string,
		opts?: Partial<ErrorPropertyBag>,
	) {
		super();
		this.options = { ...this.options, ...opts };
		this.message = this.options.translate ? t(message, { ns: this.options.ns }) : message;
	}
}
