import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Avatar, Tooltip } from '@mui/material';
import { DataTableBackend, OverflowText } from '@components/common';
import { ExtendedColumn, PageRoutes, SortOrder } from '@models';
import { nmrDrmRequestService, nmrFilterService, NmrRequestDrm } from '@services';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { DrmRequestButton } from '@components/admin';
import { useService } from '@hooks';
import { getParams, setParams, useFilterSearchParams } from '@hooks';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { TableButton } from '@components/common/TableButton';

export const NmrDrmRequests: FC = () => {
	const { searchParams, setSearchParams, filtering, setFiltering } = useFilterSearchParams('date', SortOrder.DESC);

	const [typedString, setTypedString] = useState('');
	const { t } = useTranslation('admin');

	const { data: filterData } = useService(() => nmrFilterService.drmRequestFilter());

	const { data: requestData } = useService(() => {
		setTypedString(getParams(searchParams).getQuery());
		return nmrDrmRequestService.requestsErmListWithParams(searchParams);
	}, [searchParams]);

	const columns = useMemo<ExtendedColumn<NmrRequestDrm>[]>(
		() => [
			{
				accessor: 'status',
				Header: <Tr.Admin path="substance-management.status" />,
				Cell: ({ row }) => <DrmRequestButton isEditable={false} status={String(row.values.status)} />,
			},
			{
				accessor: 'merckPhysicalProductNumber',
				Header: <Tr.Admin path="substance-management.physical-product-number" />,
				Cell: ({ value }) => <OverflowText variant="pg-s">{value || '-'}</OverflowText>,
			},
			{
				accessor: 'substance',
				Header: <Tr.Admin path="substance-management.substance" />,
				Cell: ({ value }) => (
					<OverflowText variant="pg-s" enableTooltip={true}>
						{value}
					</OverflowText>
				),
			},
			{
				accessor: 'casNumber',
				Header: <Tr.Admin path="substance-management.cas-number" />,
				Cell: ({ row, value }) => (
					<Stack direction="row" spacing={1} alignItems="center">
						<Typography
							variant="pg-s"
							sx={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={() => {
								const { setQuery, setPageIndex } = setParams(searchParams);
								setQuery(value);
								setPageIndex('1');
								setSearchParams(searchParams);
								setTypedString(value);
							}}
						>
							{value}
						</Typography>
						{row.original.actionRequired && (
							<Tooltip placement="top" arrow title={<Tr.Common path="action-required" />}>
								<Avatar sx={{ width: '1rem', height: '1rem', backgroundColor: 'error.main' }}>
									<PriorityHighRoundedIcon sx={{ fontSize: '10px', color: 'white' }} />
								</Avatar>
							</Tooltip>
						)}
					</Stack>
				),
			},
			{
				accessor: 'date',
				Header: <Tr.Admin path="substance-management.date" />,
				Cell: ({ value }) => <Typography variant="pg-s">{value ? DateUtils.getFormattedDate(value) : '-'}</Typography>,
			},
			{
				accessor: 'organization',
				Header: <Tr.Admin path="substance-management.organization" />,
				Cell: ({ value }) => <Typography variant="pg-s">{value}</Typography>,
			},
			{
				accessor: 'id',
				sticky: true,
				disableSortBy: true,
				Cell: ({ row }) => (
					<Stack sx={{ float: 'right', mr: 2 }}>
						<TableButton.Outlined
							href={`/admin/${PageRoutes.DRM_MANAGEMENT}/${PageRoutes.ERM_REQUESTS}/${PageRoutes.NMR}/${row.original.id}`}
							data-testid={`erm-req-view-${row.index}-id`}
						>
							<Tr.Admin path="substance-management.view-request" />
						</TableButton.Outlined>
					</Stack>
				),
			},
		],
		[requestData],
	);

	return (
		<>
			<DataTableBackend
				data={requestData?.data || []}
				columns={columns as any}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: requestData?.totalPages ?? 1 }}
				searchText={typedString}
				onSearchTextChange={setTypedString}
				searchPlaceholder={t('substance-management.requests-search-placeholder')}
				searchFieldProps={{ sx: { maxWidth: 710, width: 710 }, 'aria-label': 'erm-reqs-search-id' }}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
				sx={{ marginX: 2 }}
			/>
		</>
	);
};
