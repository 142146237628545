import { FC, useState } from 'react';
import { Tr } from '@utils';
import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { ModalDialog } from '@components/common';
import { NmrFavoritesTable } from '../NmrFavoritesTable';
import { QualitativeOrQuantitative } from '@models/analysis';
import { NmrAnalysisSubstanceListItem } from '@services/nmr';

export interface IAddFromFavoritesButtonProps extends ButtonProps {
	onFavoriteSelect?: (id: number) => void;
	measurementType: QualitativeOrQuantitative;
	addedSubstances: NmrAnalysisSubstanceListItem[] | undefined;
}

export const AddFromFavoritesButton: FC<IAddFromFavoritesButtonProps> = ({
	onClick,
	onFavoriteSelect,
	children,
	measurementType,
	addedSubstances,
	...props
}) => {
	const [dialogOpen, setDialogOpen] = useState(false);

	return (
		<>
			<Button
				data-testid="add-favorite-button"
				variant="contained"
				size="medium"
				disableElevation
				onClick={(e) => (setDialogOpen(true), onClick?.(e))}
				{...props}
			>
				{children || <Tr.Portal path="favourites.add-from-favorites-button" />}
			</Button>

			<ModalDialog
				variant="secondary"
				data-testid="add-from-favorites-modal-id"
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				maxWidth="lg"
				contentProps={{ sx: { paddingX: 10.5, paddingTop: 2, paddingBottom: 7 } }}
				title={
					<Typography variant="pg-s">
						<Tr.Portal path="favourites.add-from-favorites-modal-title" />
					</Typography>
				}
			>
				<Stack direction="column" spacing={3} sx={{ mb: 2, mt: 3 }}>
					<Typography variant="h3" textAlign="center">
						<Tr.Portal path="favourites.add-from-favorites-modal-title" />
					</Typography>

					<NmrFavoritesTable
						measurementType={measurementType}
						pageSize={5}
						actions={[
							{
								renderer: ({ id, referenceMaterialId }) => (
									<Button
										data-testid={`add-from-favorites-add-to-analysis-${id}`}
										variant="contained"
										size="small"
										onClick={() => onFavoriteSelect?.(referenceMaterialId)}
										disabled={addedSubstances?.some((s) =>
											s.selectedReferenceMaterials?.some((r) => r.id === referenceMaterialId),
										)}
									>
										<Tr.Portal path="favourites.add-to-analysis-button" />
									</Button>
								),
							},
						]}
					/>
				</Stack>
			</ModalDialog>
		</>
	);
};
