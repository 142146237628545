import { concatMap, tap } from 'rxjs';
import { RxUtils } from '@utils';
import { logger } from '../';
import { httpService } from '../http';

class FileService {
	getFileFromUrl(url: string) {
		return httpService.get<string>(url, '', { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getFileFromUrl.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}
}

export const fileService = new FileService();
