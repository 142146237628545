import { FilterKey, FilterValueType } from '@models/filter';

export const getFiltersFromSearchParams = (searchParams: URLSearchParams) => {
	const newFilterValues: FilterValueType[] = [];

	Object.values(FilterKey).forEach((key) => {
		if (key === FilterKey.DATE_RANGE) {
			newFilterValues.push({
				[key]: searchParams
					?.get(key)
					?.split(',')
					?.map((d) => (d ? new Date(d) : null)),
			});
		}
		if (key === FilterKey.TO || key === FilterKey.FROM) {
			newFilterValues.push({ [key]: searchParams?.get(key) });
		} else {
			const values = searchParams?.getAll(key);
			values?.forEach((x) => newFilterValues.push({ [key]: x }));
		}
	});
	return newFilterValues;
};

export const getIsFilterActive = (filterValues: FilterValueType[]) => {
	return filterValues.some(
		(entry) => Object.values(FilterKey).some((f) => Object.keys(entry).includes(f)) && !!Object.values(entry).some((e) => !!e),
	);
};
