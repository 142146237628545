import { FC } from 'react';
import { Tr, innerTitleSpacer } from '@utils';
import { Grid, Stack, SxProps, Typography } from '@mui/material';
import { FormikApiType, GenericCard } from '@components/common';
import { PaidSubscriptionForm } from '../PaidSubscriptionForm';
import { FreeSubscriptionForm } from '../FreeSubscriptionForm/FreeSubscriptionForm';

interface SubscriptionFormLayoutProps {
	formik: FormikApiType;
	formWidth?: string;
	sx?: SxProps;
}

export const SubscriptionFormLayout: FC<SubscriptionFormLayoutProps> = ({ formik, sx }) => {
	return (
		<Stack sx={sx}>
			<Stack marginBottom={innerTitleSpacer}>
				<Typography variant="h2" color="primary.main">
					<Tr.Admin path="organization.subscription" />
				</Typography>
			</Stack>

			<Grid container spacing={3}>
				<Grid item xs={formik?.values.paidSubscription.paymentType ? 4 : 6} justifyContent={'stretch'}>
					<GenericCard sx={{ height: 1 }}>
						<FreeSubscriptionForm formik={formik} />
					</GenericCard>
				</Grid>
				<Grid item xs={formik?.values.paidSubscription.paymentType ? 8 : 6}>
					<GenericCard sx={{ height: 1 }} containerSx={{ height: 1 }}>
						<PaidSubscriptionForm formik={formik} />
					</GenericCard>
				</Grid>
			</Grid>
		</Stack>
	);
};
