import { FC } from 'react';
import { Link, Typography } from '@mui/material';
import { Tr, UsefulLinkUtils } from '@utils';

export const CalibrationInstruction: FC<{ isCheckCalibration: boolean }> = ({ isCheckCalibration = false }) => (
	<Typography variant="pg-m" sx={{ marginTop: 1.5, fontSize: '0.9rem', whiteSpace: 'pre-line' }}>
		{isCheckCalibration ? (
			<span style={{ whiteSpace: 'pre-line' }}>
				<Tr.Portal path="device-management.instruction-check-calibration" />{' '}
				<Link sx={{ cursor: 'pointer' }} href={UsefulLinkUtils.GUIDELINE} target="_blank">
					<Tr.Portal path="device-management.here" />
				</Link>
			</span>
		) : (
			<>
				<Tr.Portal path="device-management.instruction" />
				<strong>
					<Tr.Portal path="device-management.instruction-size" />
				</strong>{' '}
				<Tr.Portal path="device-management.instruction-continue" />
				<Link sx={{ cursor: 'pointer' }} href={UsefulLinkUtils.GUIDELINE} target="_blank">
					<Tr.Portal path="device-management.here" />
				</Link>
				{'. '}
				<Tr.Portal path="device-management.instruction-receiver-gain" />
			</>
		)}
		.
	</Typography>
);
