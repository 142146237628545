export const NOTIF_FILTER_LIST = [
	{
		key: 'message',
		value: 'Refresh Token has expired',
	},
	{
		key: 'message',
		value: 'Refresh Token has been revoked',
	},
];
