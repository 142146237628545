import { Button, Grid, Stack, StackProps, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { ModalDialog } from '@components/common/ModalDialog';
import { FormikTextfield } from '@components/common/FormikTextfield';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FC } from 'react';
import { RxUtils } from '@utils/Rx';
import { NmrDrmOrderWave, nmrDrmWaveService } from '@services/nmr-drm-pipeline';

const fullWidth: StackProps = { width: 1 };

type TRenameWaveModalDialogProps = {
	wave: NmrDrmOrderWave;
	onCancel?: () => void;
	onSave?: () => void;
};

type TRenameWaveFields = Extendable<{
	name: string;
}>;

export const RenameWaveModalDialog: FC<TRenameWaveModalDialogProps> = ({ wave, onCancel, onSave }) => {
	const { t } = useTranslation('admin');

	const formik = useFormik<TRenameWaveFields>({
		initialValues: {
			name: wave.name,
		},
		validationSchema: yup.object({
			name: yup
				.string()
				.trim()
				.max(250, t('drm-pipeline.nmr.backlog.name-should-be-max-250-characters'))
				.required(t('drm-pipeline.nmr.backlog.name-is-required')),
		}),
		onSubmit: (fields: TRenameWaveFields) => {
			if (formik.isValid) {
				RxUtils.promisify(
					nmrDrmWaveService.patch({
						id: wave.id,
						name: fields.name,
					}),
					() => {
						onSave && onSave();
					},
				);
			}
		},
		validateOnChange: true,
	});

	return (
		<ModalDialog
			variant="primary"
			open={true}
			title={
				<Typography variant="pg-s">
					<Tr.Admin path="drm-pipeline.nmr.backlog.edit" />
				</Typography>
			}
			titleProps={{
				sx: {
					justifyContent: 'flex-start',
					paddingY: 0,
					height: '40px',
					marginTop: 0,
					minHeight: 0,
					backgroundColor: 'grey.50',
					paddingLeft: '16px',
				},
			}}
			closeIconClick={onCancel}
			maxWidth={false}
			PaperProps={{
				sx: {
					maxWidth: '472px',
					maxHeight: '329px',
					minHeight: '329px',
				},
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<Stack
					sx={{
						paddingX: '68px',
						marginY: 4,
					}}
					spacing={3}
				>
					<Stack justifyContent="center" textAlign="center">
						<Typography variant="subtitle1">
							<Tr.Admin path="drm-pipeline.nmr.backlog.edit-wave-name" />
						</Typography>
					</Stack>
					<Stack spacing={4}>
						<FormikTextfield
							formikApi={formik}
							name="name"
							placeholder={t('drm-pipeline.nmr.backlog.enter-name')}
							title={t('drm-pipeline.nmr.backlog.name')}
							value={formik.values.name || ''}
							variant="outlined"
							containerProps={fullWidth}
							inputProps={{ 'data-testid': 'name-test-id' }}
							required
						/>

						<Grid container>
							<Grid
								item
								xs={6}
								sx={{
									paddingRight: 1.5,
								}}
							>
								<Button
									variant="outlined"
									fullWidth={true}
									data-testid="rename-cancel-button-id"
									disableElevation
									onClick={() => {
										onCancel && onCancel();
									}}
								>
									<Tr.Common path="cancel" />
								</Button>
							</Grid>

							<Grid
								item
								xs={6}
								sx={{
									paddingLeft: 1,
								}}
							>
								<Button
									fullWidth={true}
									data-testid="rename-approve-button-id"
									variant="contained"
									type="submit"
									disableElevation
									disabled={!formik.dirty || !formik.isValid}
									sx={{ textTransform: 'capitalize' }}
								>
									<Tr.Common path="save" />
								</Button>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
