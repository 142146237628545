import { SearchField } from '@components/common';
import { Filter } from '@components/common/Filter';
import { FilterChips } from '@components/common/Filter/FilterChips';
import { FilterValueType, IFilter } from '@models/filter';
import { PopoverProps, Stack, TextFieldProps } from '@mui/material';
import { analyticsErmEvent } from '@utils/Analytics/erm-events';
import { FC, useMemo, useRef, useState } from 'react';

type IReferenceSearchProps = TextFieldProps & {
	onSearch?: (typed: string) => void;
	onSearchClear: () => void;
	filteringSelections?: FilterValueType[];
	searchParams: URLSearchParams;
	onSearchParamsChange: (searchParams: URLSearchParams) => void;
	filterData?: IFilter[];
};

const popOverProps: Omit<PopoverProps, 'open'> = {
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin: {
		horizontal: 'right',
		vertical: 'top',
	},
};
export const IrReferenceSearchField: FC<IReferenceSearchProps> = ({
	onSearchClear,
	onSearch,
	searchParams,
	onSearchParamsChange,
	filteringSelections,
	sx,
	filterData,
	...props
}) => {
	const [openFilter, setOpenFilter] = useState(false);
	const [deletedItem, setDeletedItem] = useState<{ key: string; value: string }>();
	const searchFieldRef = useRef<HTMLDivElement>(null);

	const filterWidth = searchFieldRef.current?.clientWidth ? searchFieldRef.current?.clientWidth / 3 : 300;
	// It shouldnt be getten here, it should be given from parent

	const isFilterOptionsActive = (filterData?.length ?? 0) > 0;

	return (
		<Stack>
			<Stack direction="row">
				<SearchField
					sx={{ flexGrow: 1, ...sx }}
					onSearch={(typed: string) => {
						onSearch?.(typed);
						typed && analyticsErmEvent.ErmSearched(typed);
					}}
					onSearchClear={onSearchClear}
					ref={searchFieldRef}
					filter={isFilterOptionsActive}
					onFilterClick={() => setOpenFilter(!openFilter)}
					{...props}
				/>
				{useMemo(
					() =>
						isFilterOptionsActive && (
							<Filter
								setOpen={() => setOpenFilter(!openFilter)}
								searchParams={searchParams}
								setSearchParams={onSearchParamsChange}
								open={openFilter}
								anchor={searchFieldRef.current as HTMLElement}
								filterData={filterData || []}
								removeFromChip={deletedItem}
								cardSx={{ width: filterWidth }}
								popoverProps={popOverProps}
								data-testid="filter-id"
							/>
						),
					[openFilter, deletedItem, isFilterOptionsActive],
				)}
			</Stack>
			{filteringSelections && (
				<FilterChips
					onDeleteItem={(deletedItemFromChip) => setDeletedItem(deletedItemFromChip)}
					filterData={filterData}
					selectedFilterData={filteringSelections || []}
					sx={{ borderBottom: 0, paddingLeft: 0 }}
					chipProps={{ sx: { marginRight: 1.5 } }}
					buttonSx={{ marginTop: 1 }}
				/>
			)}
		</Stack>
	);
};
