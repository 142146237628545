import { FormikTextfield, ModalDialog } from '@components/common';
import { MultipleRoleSelector } from '@components/common/MultipleRoleSelector';
import { UserRole, UserStatus } from '@models/user';
import { Button, Grid, Stack, Typography, Autocomplete, TextField } from '@mui/material';
import { getOrganizationUserValidationSchema } from '@schemas';
import { AddUserToOrganization, Lab, organizationService } from '@services/core';
import { RxUtils } from '@utils/Rx';
import { formFieldSpacer, Scroll } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from '@hooks';
import { SearchableDropdown } from '@components/common/Filter/SearchableDropdown';

interface IAddUserModalProps {
	visible: boolean;
	onClose: () => void;
	onSubmit?: (data: AddUserToOrganization) => void;
	organizationId?: string;
	skipOrgCheck?: boolean;
}
type FormFields = Extendable<AddUserToOrganization>;

export const AddUserModal: FC<IAddUserModalProps> = ({ visible, onClose, onSubmit, organizationId, skipOrgCheck = false }) => {
	const { t } = useTranslation('common');

	const { data: organizationList } = useService(() => organizationService.getAll({ PageIndex: 1, PageSize: 0, Status: 0 }));
	const [selectedOrg, setSelectedOrg] = React.useState(organizationId || '');
	const orgData: Array<{ label: string; id: number }> = [];
	if (organizationList) {
		organizationList.data.map((org) => {
			orgData.push({ label: org.name, id: +org.id });
		});
	}
	const [organizationLaboratories, setOrganizationLaboratories] = useState<Lab[]>();
	const [selectedLaboraties, setSelectedLaboraties] = useState<any>([]);
	const formik = useFormik<FormFields>({
		initialValues: {
			Email: '',
			Roles: [],
			LaboratoryIds: selectedLaboraties || [],
			OrganizationId: selectedOrg ? selectedOrg : '',
			Status: UserStatus.INVITED,
		},
		validationSchema: getOrganizationUserValidationSchema(t, false),
		onSubmit: (values) => {
			onSubmit?.({
				Email: values.Email.toLowerCase(),
				Roles: values.Roles,
				LaboratoryIds: selectedLaboraties || [],
				OrganizationId: selectedOrg ? selectedOrg : '',
				Status: UserStatus.INVITED,
			});
		},
		initialTouched: {
			email: false,
		},
	});
	const options =
		(organizationLaboratories &&
			organizationLaboratories.map((i) => ({
				id: i.id,
				value: i.name,
			}))) ||
		[];
	const roleSelectedDisabled = formik?.values?.Roles?.length > 0 ? false : true;
	const onlyOrgAdminSelected = formik.values?.Roles?.length === 1 && formik.values.Roles.includes(UserRole.ORG_ADMIN);

	useEffect(() => {
		if (selectedOrg)
			RxUtils.promisify(organizationService.getLaboratories(+selectedOrg), (value) => {
				setOrganizationLaboratories(value.data.filter((l) => l.isActive));
				formik.setFieldValue('OrganizationId', +selectedOrg);
			});
	}, [selectedOrg]);

	const saveUser = () => {
		formik.validateForm();
		formik.submitForm();
	};

	return (
		<ModalDialog
			data-testid="Add-user-modal"
			variant="primary"
			open={visible}
			maxWidth="sm"
			onClose={onClose}
			sx={{ zIndex: 2 }}
			title={<Tr.Portal path={'user-management.add-user-title'} />}
			PaperProps={{
				sx: {
					maxWidth: '832px',
					justifyContent: 'space-between',
				},
			}}
			iconButtonProps={{
				marginTop: 1,
			}}
			contentProps={{
				sx: {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					padding: 2.5,
					...Scroll.Y,
				},
			}}
		>
			<form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
				<Stack flexGrow={1}>
					<Stack>
						<Grid container rowSpacing={formFieldSpacer} columnSpacing={3} marginLeft={-2.25}>
							<Grid item xs={6}>
								<FormikTextfield
									formikApi={formik}
									name="Email"
									title={t('Email')}
									placeholder={t('Email')}
									variant="outlined"
									fullWidth
									required={true}
									inputProps={{ 'data-testid': 'add-user-email-test-id' }}
									onChange={(e) => formik.setFieldValue('email', e.target.value.trim())}
								/>
							</Grid>
							<Grid item xs={6} height={1}>
								<Stack>
									<Typography variant="label-s" color="grey.800" className="required" marginBottom={1.25}>
										{t('Role')}
									</Typography>
									<MultipleRoleSelector formik={formik} name="Roles" set="portal" />
								</Stack>
							</Grid>
							{!skipOrgCheck && (
								<Grid item xs={6}>
									<Stack>
										<Typography variant="label-s" color="grey.800" className="required" marginBottom={1.25}>
											{t('Organization')}
										</Typography>
										<Autocomplete
											disableCloseOnSelect
											options={orgData}
											size="medium"
											disabled={roleSelectedDisabled}
											renderInput={(params) => (
												<TextField
													{...params}
													placeholder={t('user-management.select-organization')}
													variant="outlined"
												/>
											)}
											onChange={(_, newValue: { id; label } | null) => {
												setSelectedOrg(newValue?.id);
											}}
										/>
									</Stack>
								</Grid>
							)}
							<Grid item xs={organizationId ? 12 : 6} height={1}>
								<Stack>
									<Typography variant="label-s" color="grey.800" className="required">
										{t('lab-name')}
									</Typography>
									<SearchableDropdown
										options={options as []}
										onChange={(_, val) => {
											setSelectedLaboraties(val);
											formik.setFieldValue('LaboratoryIds', val);
										}}
										selectedValues={selectedLaboraties}
										disabled={!selectedOrg || onlyOrgAdminSelected}
										filterOption={false}
										placeholder={t('user-management.select-lab')}
									/>
								</Stack>
							</Grid>
						</Grid>
					</Stack>
					<Stack alignItems="end" height="100%" marginTop="auto">
						<Stack
							flexDirection="row"
							width={'100%'}
							marginBottom={1}
							flexGrow={1}
							alignItems="center"
							justifyContent="space-between"
						>
							<Stack direction="row" flexGrow={1} justifyContent="flex-end" alignItems="end">
								<Button
									data-testid="cancel-user-button-id"
									type="submit"
									onClick={onClose}
									variant="outlined"
									size="medium"
									sx={{ alignSelf: 'end', marginLeft: 5, width: 'fit-content' }}
								>
									<Tr.Portal path="user-management.add-user-screen.cancel-button" />
								</Button>
								<Button
									data-testid="add-user-button-id"
									onClick={saveUser}
									variant="contained"
									size="medium"
									disabled={!formik.isValid || !formik.dirty}
									sx={{ alignSelf: 'end', marginLeft: 1 }}
								>
									<Tr.Portal path={'user-management.add-user-screen.add-button'} />
								</Button>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
