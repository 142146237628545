import { Stack, Typography } from '@mui/material';
import { mainTitleSpacer } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { FC } from 'react';
import { DrmPipelineRouteHandler } from './DrmPipelineRouteHandler';
import { NmrDrmOrderProductionSearchField } from '../NmrPipelineProduction/NmrDrmOrderSearchField';
import { useShouldHandleRoute } from './useShouldHandleRoute';
import { PageRoutes } from '@models/router';

export const DrmPipelineHeader: FC = () => {
	const shouldHandleRoute = useShouldHandleRoute(PageRoutes.DRM_PIPELINE);
	const showSearchField = useShouldHandleRoute(`${PageRoutes.DRM_PIPELINE}/${PageRoutes.NMR}/${PageRoutes.DRM_PIPELINE_PRODUCTION}`);

	const allowSpacing = shouldHandleRoute || showSearchField;
	const isIr = useShouldHandleRoute(PageRoutes.IR);
	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{
				pt: allowSpacing && !isIr ? 4.5 : 0,
				px: allowSpacing && !isIr ? 8 : 0,
				marginBottom: mainTitleSpacer,
			}}
		>
			<Typography variant="h1" sx={{ color: 'text.secondary' }}>
				<Tr.Admin path="drm-pipeline.name" />
			</Typography>

			<Stack direction="row" spacing={1.5}>
				{showSearchField && <NmrDrmOrderProductionSearchField />}
				{shouldHandleRoute && <DrmPipelineRouteHandler />}
			</Stack>
		</Stack>
	);
};
