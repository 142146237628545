import { forwardRef } from 'react';
import { Link as ReactRouterDomLink, LinkProps as ReactRouterDomLinkProps } from 'react-router-dom';
import { Button, ButtonProps } from '@mui/material';

export type IRouterLinkButtonProps = Omit<ButtonProps, 'LinkComponent' | 'ref'> & Omit<ReactRouterDomLinkProps, 'to'>;

const Link = forwardRef<HTMLAnchorElement, IRouterLinkButtonProps>(({ href, ...props }, ref) => (
	<ReactRouterDomLink {...props} ref={ref} to={href || ''} role={undefined} />
));

export const RouterLinkButton = forwardRef<HTMLButtonElement, IRouterLinkButtonProps>(({ ...props }, ref) => {
	return <Button ref={ref} LinkComponent={Link} {...props} />;
});
