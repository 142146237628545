import { Stack, SxProps } from '@mui/material';
import { NmrDrmDetail, NmrSubstanceListItem } from '@services';
import { FC, ReactElement } from 'react';
import { SubstanceListItem } from '../NmrSubstanceListItem';

interface ISubstanceListProps {
	substanceList: NmrSubstanceListItem[];
	type?: 'add' | 'remove';
	referenceMaterialAccessor?: string;
	actionLabel: string;
	disabled?: boolean;
	onSubstanceAdd?: (substanceId: number) => void;
	onSubstanceRemove?: (substanceId: number) => void;
	onDrmDetail?: (visible: boolean, detail: NmrDrmDetail, onSaveFavorite?: () => void) => void;
	onRefreshSubstanceList?: () => void;
	substancesToAddFavorite?: number[];
	onCloseFavoriteDialog?: () => void;
	itemSx?: SxProps;
	sx?: SxProps;
	width?: string;
	isTheoretical?: boolean;
	theoreticalCount?: number;
	theoreticalStartId?: number;
	searchString?: string;
	divider?: ReactElement;
	dataTestId?: string;
}

export const NmrSubstanceList: FC<ISubstanceListProps> = ({
	substanceList,
	type,
	actionLabel,
	onSubstanceAdd,
	onSubstanceRemove,
	onDrmDetail,
	itemSx,
	sx,
	dataTestId,
	divider,
	referenceMaterialAccessor,
	onRefreshSubstanceList,
	disabled,
}) => {
	return (
		<>
			{substanceList.length > 0 ? (
				<Stack>
					<Stack direction="column" sx={sx} divider={divider} data-testid={dataTestId ?? 'substance-list-wrapper-test-id'}>
						{substanceList.map((substance) => (
							<SubstanceListItem
								key={`${type}` + substance.id}
								substance={substance}
								referenceMaterialAccessor={referenceMaterialAccessor}
								disabled={disabled}
								type={type}
								actionLabel={actionLabel}
								onSubstanceRemove={onSubstanceRemove}
								onRefreshSubstanceList={onRefreshSubstanceList}
								onSubstanceAdd={onSubstanceAdd}
								onDrmDetail={(visible: boolean, detail: NmrDrmDetail) => onDrmDetail?.(visible, detail, undefined)}
								sx={itemSx}
							/>
						))}
					</Stack>
				</Stack>
			) : null}
		</>
	);
};
