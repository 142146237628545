import { FC } from 'react';
import { Stack } from '@mui/material';
import { NmrDrmOrder } from '@services/nmr-drm-pipeline';
import { AvatarIcon } from '@components/common/UserAvatar/AvatarIcon';
import { DataTestId } from '@utils/DataTestId';

type TNmrDrmOrderAssigneesProps = {
	order: NmrDrmOrder;
};

type TAssignee = {
	name: string;
	surname: string;
	type: string;
};

const assigneeColorMap = {
	analyst: { textColor: '#714EBB', backgroundColor: 'info.600' },
	qcInspector: { textColor: 'warning.main', backgroundColor: '#FFF3D9' },
	qaReviewer: { textColor: 'info.dark', backgroundColor: '#ECF5FC' },
};

export const NmrDrmOrderAssignees: FC<TNmrDrmOrderAssigneesProps> = ({ order }) => {
	const assignees: TAssignee[] = [];
	if (order.analyst?.name) {
		assignees.push(createTempUserObject(order.analyst?.name, 'analyst'));
	}
	if (order.qcInspector?.name) {
		assignees.push(createTempUserObject(order.qcInspector?.name, 'qcInspector'));
	}
	if (order.qaReviewer?.name) {
		assignees.push(createTempUserObject(order.qaReviewer?.name, 'qaReviewer'));
	}

	return (
		<Stack flexDirection="row" height={1}>
			{assignees.map((assignee, index) => (
				<AvatarIcon
					data-testid={DataTestId.getTestId('nmr-drm-order-assignee')}
					key={index}
					user={assignee}
					{...assigneeColorMap[assignee.type]}
					sx={{ transform: `translateX(${-6 * index}px)` }}
				/>
			))}
		</Stack>
	);
};

const createTempUserObject = (fullName: string, type: string) => {
	const nameParts = fullName.split(' ');
	return { name: nameParts[0], surname: nameParts[nameParts.length - 1], type };
};
