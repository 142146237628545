import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { Box, Menu, MenuProps, IconProps as MuiIconProps, Select, SelectProps, Stack, Typography } from '@mui/material';
import {
	SingleSelectMenuItemType,
	SingleSelectMenuType,
	getLabel,
	getOnSelectFunctionValue,
	getRenderedValue,
	getValue,
} from './single-select-menu.class';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { OverflowText } from '../OverflowText';
import { DataTestId } from '@utils/DataTestId';
import { TFunction } from 'i18next';

export interface ISelectMenuProps {
	items: readonly SingleSelectMenuItemType[];
	menuItemProps?: MenuItemProps;
	selectedItem?: SingleSelectMenuItemType;
	onSelectedItemChange: <T>(item: T) => void;
	IconProps?: MuiIconProps;
	preceedor?: ReactNode;
	defaultLabel?: String;
	menuType?: SingleSelectMenuType;
	AnchorComponent?: React.ElementType;
	menuProps?: Omit<MenuProps, 'open'>;
	open?: boolean;
	tFunction?: TFunction;
	showDefaultButton?: boolean;
	applyMenuItemBorder?: boolean;
}

export const SingleSelectMenu: FC<ISelectMenuProps & Omit<SelectProps, 'onChange' | 'value'>> = ({
	items,
	selectedItem,
	onSelectedItemChange,
	menuItemProps,
	IconProps,
	preceedor,
	defaultLabel,
	menuType = 'selectMenu',
	AnchorComponent,
	menuProps,
	open,
	required,
	title,
	tFunction,
	showDefaultButton,
	applyMenuItemBorder,
	...props
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const anchorRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (open && anchorRef.current) {
			setAnchorEl(anchorRef.current);
		} else {
			setAnchorEl(null);
		}
	}, [open, anchorRef.current]);
	const value = getValue(selectedItem);

	const getMenuItems = () => {
		return items.map((item, index) => {
			const itemId = typeof item !== 'string' ? item.id : undefined;
			const isFirstItem = index === 0;

			const itemSx = applyMenuItemBorder
				? {
						paddingLeft: itemId !== 'addMore' ? 4.5 : 1.5,
						borderBottom: index !== items.length - 1 ? '1px solid #E1E1EA' : 'none',
					}
				: {};

			return (
				<MenuItem
					key={`${getValue(item)}-${index}`}
					value={getValue(item) ?? undefined}
					data-testid={DataTestId.getStaticTestId(`${getValue(item)}-menu-item-id`)}
					onClick={menuType === 'anchorEl' ? () => onSelectedItemChange(items[`${index}`]) : undefined}
					sx={{
						fontSize: 16,
						lineHeight: '140%',
						paddingLeft: 1.5,
						textOverflow: 'ellipsis',
						...itemSx,
					}}
					{...menuItemProps}
				>
					<Stack sx={{ visibility: getValue(item) === value ? undefined : 'hidden' }}>{preceedor}</Stack>

					<OverflowText style={{ textOverflow: 'ellipsis' }}>{getLabel(item, tFunction) ?? ''}</OverflowText>

					{showDefaultButton && isFirstItem && (
						<Box
							sx={{
								border: '1px solid #01884C',
								color: 'primary.main',
								padding: '0 4px',
								borderRadius: '4px',
								marginLeft: 3,
								display: 'inline-block',
								fontWeight: 700,
								fontSize: 12,
							}}
						>
							Default
						</Box>
					)}
				</MenuItem>
			);
		});
	};

	// Developer restrict
	if (process.env.NODE_ENV === 'development' && typeof open !== 'undefined' && menuType === 'selectMenu') {
		throw new Error('Controlled inputs cannot have Select Menu type.');
	}
	if (typeof open !== 'undefined' && menuType === 'selectMenu') {
		return null;
	}
	return (
		<Stack>
			{AnchorComponent && (
				<Stack ref={anchorRef}>
					<AnchorComponent />
				</Stack>
			)}
			{menuType === 'anchorEl' && (
				<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} {...menuProps}>
					{getMenuItems()}
				</Menu>
			)}
			{title ? (
				<Typography
					variant="label-s"
					className={required && !props.disabled ? 'required' : ''}
					color={props.disabled ? 'grey.200' : 'grey.800'}
					sx={{
						marginBottom: 1.25,
					}}
				>
					{title}
				</Typography>
			) : null}
			{menuType === 'selectMenu' && (
				<Select
					multiple={false}
					value={value}
					onChange={(event) => onSelectedItemChange(getOnSelectFunctionValue(selectedItem, event.target.value))}
					IconComponent={({ sx, ...params }) => (
						<KeyboardArrowDown
							{...params}
							sx={{ ...sx, color: `${props.disabled ? '#E1E1EA' : '#01884C'} !important`, ...IconProps?.sx }}
						/>
					)}
					displayEmpty
					defaultValue={''}
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
					SelectDisplayProps={{
						style: {
							minHeight: 0,
						},
					}}
					renderValue={(renderValue) => {
						return <>{getRenderedValue(items, renderValue, defaultLabel)}</>;
					}}
					{...props}
				>
					{getMenuItems()}
				</Select>
			)}
		</Stack>
	);
};
