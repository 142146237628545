import { FC } from 'react';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Stack, Typography, Avatar, Button } from '@mui/material';

export const DoneStep: FC<{ onClose: () => void }> = ({ onClose }) => {
	return (
		<Stack direction="column" alignItems={'center'} data-testid={DataTestId.getStaticTestId('drm-pipeline-done-card-id')}>
			<Avatar
				sx={{
					width: '5rem',
					height: '5rem',
					marginBottom: 3,
					backgroundColor: 'primary.50',
				}}
			>
				<CheckRoundedIcon sx={{ fontSize: '2.5rem', color: 'primary.main' }} />
			</Avatar>
			<Typography variant="subtitle1" marginBottom={3}>
				<Tr.Admin path="drm-pipeline.done.title" />
			</Typography>
			<Typography variant="pg-m" textAlign="center" marginBottom={5}>
				<Tr.Admin path="drm-pipeline.done.message" />
			</Typography>
			<Button variant="contained" onClick={onClose} sx={{ width: '10rem' }}>
				<Tr.Common path="ok-thanks" />
			</Button>
		</Stack>
	);
};
