import { FC, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Stack, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield } from '@components/common';
import { useTranslation } from 'react-i18next';
import { IrAccessory, irAccessoryService } from '@services/ir';
import { RxUtils } from '@utils/Rx';
import { DataTestId } from '@utils/DataTestId';

interface ISolventListType {
	name: string;
	formik?: FormikApiType;
	title?: string;
	required?: boolean;
	typographyProps?: any;
	disabled?: boolean;
}

export const AccessoryListForIr: FC<ISolventListType> = ({ name, title, required, typographyProps, formik, disabled = false }) => {
	const { t } = useTranslation('irportal');
	const [accesoryList, setAccesoryList] = useState<IrAccessory[]>([]);
	const [drophidden, setDropHidden] = useState(false);
	useEffect(() => {
		const DeviceId = formik?.values?.deviceId;
		{
			DeviceId &&
				RxUtils.promisify(irAccessoryService.getAccessories(DeviceId, 'calibrated', false), (data) => setAccesoryList(data));
		}
	}, [formik?.values?.deviceId]);
	const defaultTitle = () => (
		<Stack direction="row">
			<Typography
				variant="label-s"
				className={required ? 'required' : ''}
				color={disabled ? 'grey.200' : 'grey.800'}
				display="inline"
			>
				{t('new-analysis.accessory')}
			</Typography>
		</Stack>
	);
	const onAccessoryChange = (e: any) => {
		setDropHidden(true);
		formik?.setFieldValue(name, e.target.value);
		formik?.setFieldValue('accessoryId', e.target.value);
	};

	return (
		<Stack direction="column" sx={{ marginBottom: 1 }} data-testid="accessoryList-test-id">
			<FormControl>
				<InputLabel sx={{ display: drophidden ? 'none' : 'initial', top: '1.2rem', opacity: 0.5 }}>
					<Typography variant="label-m" color={disabled ? 'grey.200' : 'grey.800'}>
						{t('new-analysis.accessory-placeholder')}
					</Typography>
				</InputLabel>
				<FormikTextfield
					formikApi={formik}
					name={name}
					select
					required={false}
					value={formik?.values[`${name}`]}
					onChange={onAccessoryChange}
					placeholder="Accessory"
					title={title ?? defaultTitle()}
					disabled={disabled}
					variant="outlined"
					typographyProps={typographyProps}
					fullWidth
					type="number"
					sx={{
						backgroundColor: 'grey.50',
						'& .MuiOutlinedInput-root': {
							height: '40px',
						},
					}}
				>
					{accesoryList.map((item, index) => (
						<MenuItem
							key={`index-${index}}`}
							value={item.accessoryId}
							sx={{ height: '40px' }}
							data-testid={DataTestId.getStaticTestId(`${index}-accessoryList-test-id`)}
						>
							{item.accessoryName}
						</MenuItem>
					))}
				</FormikTextfield>
			</FormControl>
		</Stack>
	);
};
