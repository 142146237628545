import { IrAccessoryCalibrationStatus } from './ir-accessory-status.enum';
import { AnalysisTechnology } from '@services/core';

export class IrAccessoryCalibration {
	id: number;
	name: string;
	irAccessoryId: number;
	status: IrAccessoryCalibrationStatus;
	manufacturer: string;
	accessory: string;
	linearity: number;
	solvent?: string;
	intercept?: number;
	standardDeviation?: number;
	calibrationErrorMessage?: string;

	createTime: string;
	nextCalibrationTime: string;
	lastCalibrationTime: string;

	public isActive() {
		return this.status === IrAccessoryCalibrationStatus.ACTIVE;
	}
	public isCalibrate() {
		return (
			this.status === IrAccessoryCalibrationStatus.CALIBRATION_CHECK_NEEDED ||
			this.status === IrAccessoryCalibrationStatus.CALIBRATION_NEEDED
		);
	}
	public isInActive() {
		return this.status === IrAccessoryCalibrationStatus.INACTIVE;
	}
	public isCalibrating() {
		return this.status === IrAccessoryCalibrationStatus.CALIBRATING;
	}
	public isCalibrationError() {
		return this.status === IrAccessoryCalibrationStatus.CALIBRATION_ERROR;
	}
}

export class IrAccessoryCalibrationForList extends IrAccessoryCalibration {
	calibrationStatus: IrAccessoryCalibrationStatus;
	calibrationName: string;
	model: string;

	supportedTechnology: AnalysisTechnology;
	calibrationErrorMessage?: string;
}
