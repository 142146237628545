import { useState } from 'react';
import { IrAccessory, IrDevice, IrSubstance } from '@services';
import { IrAnalysisContext } from '../iranalysis';
import { QuantitativeStep } from '../analysis/props';
import { QualitativeOrQuantitative } from '@models/analysis';
import { IrAnalysisType } from '@models';
import { IrMetaDataType } from '@hooks';
import { ExtendedComponent } from 'src/types';
import { IPeaksTouched } from '@models';

export const IrAnalysisProvider: ExtendedComponent = ({ children }) => {
	const [quantitativeStep, setQuantitativeStep] = useState<Maybe<QuantitativeStep>>(QuantitativeStep.STEP_1);
	const [addedERMs, setAddedERMs] = useState<IrSubstance[]>([]);
	const [irDevice, setIrDevice] = useState<Maybe<IrDevice>>(null);
	const [measurementTypeIr, setMeasurementType] = useState<Maybe<QualitativeOrQuantitative>>();
	const [analysisType, setAnalysisType] = useState<Maybe<IrAnalysisType>>();
	const [irAccessory, setIrAccessory] = useState<Maybe<IrAccessory>>(null);
	const [metaData, setMetaData] = useState<Maybe<IrMetaDataType>>();
	const [isAnalysisEditable, setIsAnalysisEditable] = useState<Maybe<boolean>>(false);
	const [selectedLibrary, setSelectedLibrary] = useState<Maybe<string[]>>([]);
	const [isPeaksChanged, setIsPeaksChanged] = useState<Maybe<IPeaksTouched>>();

	const updateQuantitativeStep = (type: Maybe<QuantitativeStep>) => setQuantitativeStep(type);

	const updateAddedERMs = (type?: IrSubstance[]) => setAddedERMs(type as IrSubstance[]);

	const updateIrDevice = (type: Maybe<IrDevice>) => setIrDevice(type);

	const updateMeasurementType = (type: Maybe<QualitativeOrQuantitative>) => setMeasurementType(type);
	const updateAnalysisType = (type: Maybe<IrAnalysisType>) => setAnalysisType(type);

	const updateIrAccessory = (type: Maybe<IrAccessory>) => setIrAccessory(type);

	const updateMetaData = (type: Maybe<IrMetaDataType>) => setMetaData(type);

	const updateAnalysisEditable = (type?: Maybe<boolean>) => setIsAnalysisEditable(type);

	const updateSelectedLibrary = (type?: Maybe<string[]>) => setSelectedLibrary(type);

	const updatePeaksChanged = (type?: Maybe<IPeaksTouched>) => setIsPeaksChanged(type);

	return (
		<IrAnalysisContext.Provider
			value={{
				quantitativeStep,
				updateQuantitativeStep,
				addedERMs,
				updateAddedERMs,
				irDevice,
				updateIrDevice,
				measurementTypeIr,
				analysisType,
				updateMeasurementType,
				updateAnalysisType,
				irAccessory,
				updateIrAccessory,
				metaData,
				updateMetaData,
				isAnalysisEditable,
				updateAnalysisEditable,
				updateSelectedLibrary,
				selectedLibrary,
				isPeaksChanged,
				updatePeaksChanged,
			}}
		>
			{children}
		</IrAnalysisContext.Provider>
	);
};
