import { SelectMenu } from '@components/common';
import { useHasPermissions } from '@hooks';
import { TechnologyPermissionKeys } from '@models/user';
import { alertService, irDrmRequestService, IrDrmRequestStatusType, IrDrmRequestStatusTypes, IrRequestDrm } from '@services/index';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { analyticsErmEvent } from '@utils/Analytics/erm-events';
import { RxUtils } from '@utils/Rx';
import { DrmRequestButton } from './DrmRequestStatusButton';
import { PropertyOf } from '@models/request-response/http-patch';
import { PatchType } from '@models/request-response';
import { Tr } from '@utils/Translation';
import { FC } from 'react';

interface IIrDrmRequestStatusMenuProps {
	ermRequest: IrRequestDrm;
	onConfirm?: () => void;
}

export const IrDrmRequestStatusMenu: FC<IIrDrmRequestStatusMenuProps> = ({ ermRequest, onConfirm }) => {
	const onSelect = (newStatus: IrDrmRequestStatusType) =>
		alertService.send({
			titleText: <Tr.Admin path="erm-request-detail.change-status" />,
			content: <Tr.Admin path="erm-request-detail.change-status-content-second-time" />,
			confirmTextComponent: <Tr.Admin path="erm-request-detail.confirm" />,
			closeTextComponent: <Tr.Admin path="erm-request-detail.cancel" />,
			onConfirm: () => {
				RxUtils.promisify(
					irDrmRequestService.updateRequest(ermRequest.id, [
						{
							op: PatchType.REPLACE,
							path: PropertyOf<IrRequestDrm>('status'),
							value: newStatus,
						},
					]),
					() => onConfirm?.(),
				);
				newStatus === 'InProgress'
					? analyticsErmEvent.ErmInprogress(ermRequest?.casNumber)
					: analyticsErmEvent.ErmUnavailable(ermRequest?.casNumber);
			},
		});
	const hasEditDrmRequestPermission = useHasPermissions(TechnologyPermissionKeys.EDIT_DRM_REQUEST, SupportedTechnology.IR);
	const isEditable = hasEditDrmRequestPermission;
	return (
		<SelectMenu
			disabled={!isEditable}
			items={IrDrmRequestStatusTypes.filter((s) => s !== 'Released')}
			selectedItem={ermRequest?.status}
			getButton={(handleClick) => <DrmRequestButton openMenu={handleClick} isEditable={isEditable} status={ermRequest.status} />}
			onSelect={(newStatus: string) => onSelect(newStatus as IrDrmRequestStatusType)}
		/>
	);
};
