import { Stack, Typography } from '@mui/material';
import styles from './AuthLayout.module.scss';
import { GenericCard } from '../GenericCard';
import { LogoMLetter } from '@components/svg';
import { Footer } from '../Footer';
import { ExtendedComponent } from 'src/types';

export const AuthLayout: ExtendedComponent = ({ children }) => {
	return (
		<Stack
			flexGrow={1}
			justifyContent="space-between"
			className={styles.Login}
			sx={{ backgroundColor: 'primary.main', overflowX: 'hidden', width: 1, height: 1 }}
		>
			<Stack
				sx={{
					marginY: '100px',
					marginX: 'auto',
					flexDirection: 'row',
					flexGrow: 1,
					justifyContent: 'space-between',
					maxWidth: '1440px',
					width: 'calc(100% - 10rem)',
				}}
			>
				<Stack>
					<LogoMLetter color="primary" style={{ width: '140px', marginTop: '10px', fill: '#A5CD50' }} />
					<Typography variant="h1" sx={{ marginTop: '1rem', color: 'secondary.main', fontSize: '3rem', textTransform: 'none' }}>
						ChemisTwin<sup>®</sup>
						<br /> Portal
					</Typography>
				</Stack>
				<GenericCard
					dataTestId="auth-data-test-id"
					sx={{
						width: '31rem',
						height: 'fit-content',
						boxShadow: '0px 16px 32px rgba(9, 23, 52, 0.15), 0px 0px 4px rgba(9, 23, 52, 0.15)',
					}}
				>
					{children}
				</GenericCard>
			</Stack>
			<Footer data-testid="auth-footer" login />
		</Stack>
	);
};
