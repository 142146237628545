import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { NmrDrmOrderRejectionSeverity, NmrDrmOrderStatus, NmrDrmOrderTransitionType, NrmDrmOrderPriority } from './nmr-drm-order.enum';
import { Type } from 'class-transformer';

export type RequestNmrDrmOrder = {
	technology?: SupportedTechnology;
	substanceId?: number;
	priority: NrmDrmOrderPriority;
	casNumber?: String;
	skipExistingDrmOrderValidation: boolean;
};

export type NrmDrmOrderFile = {
	fileType: string;
	uploadTime: Date;
	nmrDrmOrderId: number;
	uploaderUserId: string;
	fileStorageName: string;
};

export type NrmDrmOrderAssignee = {
	userId: string;
	stepType: string;
	nmrDrmOrderId: number;
};

export type NmrDrmOrderTransitionRequestType = {
	status: NmrDrmOrderStatus;
	severity?: NmrDrmOrderRejectionSeverity;
	transitionType?: string;
	declineReason?: string;
	rejectReasons?: string[];
	comment?: string;
};

export type NmrDrmOrderAssignee = {
	id: string;
	name: string;
};

export type PostNmrOrderFileRequest = {
	File: File;
	FileType: string;
};

export class NmrDrmOrderFile {
	id: number;
	fileType: string;
	name: string;
	createdBy: string;
}

export class NmrDrmOrderTransitionResponseType {
	declineReason?: string;
	rejectReasons?: string[];
	comment?: string;
	createdBy: string;
	user: string;
	severity?: NmrDrmOrderRejectionSeverity;
	transitionType: NmrDrmOrderTransitionType;
	isEdited?: boolean;
	referenceMaterialRequestId?: number;

	@Type(() => Date)
	createTime: Date;
}
export interface NmrDrmOrderCommentInfo {
	nmrDrmOrderId: number;
	id: number;
	createTime: string;
	content: string;
	userId: string;
	name: string;
	surname: string;
	isEdited: boolean;
}
