export const getTransMissionData = (data: Array<number>) => {
	for (let i = 0; i < data?.length; i++) {
		const val = Math.pow(10, 2 - data[`${i}`]);
		data[`${i}`] = val;
	}
	return data;
};

export enum SpectraColorCodes {
	RICH_BLUE = '#0f69af',
	RICH_GREEN = '#149b5f',
	VIBRANT_MAGENTA = '#eb3c96',
	RICH_PURPLE = '#503291',
	VIBRANT_CYAN = ' #2dbecd',
	VIBRANT_YELLOW = '#ffc832',
	RICH_RED = '#e61e50',
	PRIMARY_RED = '#FF0000',
	PRIMARY_GREEN = '#00FF00',
	PRIMARY_BLUE = '#0000FF',
	GRAY = '#A9A9BA',
	BLACK = '#FFFFF',
}

export const mixtureColorCodes = [
	SpectraColorCodes.RICH_PURPLE,
	SpectraColorCodes.RICH_BLUE,
	SpectraColorCodes.RICH_RED,
	SpectraColorCodes.VIBRANT_MAGENTA,
	SpectraColorCodes.VIBRANT_CYAN,
	SpectraColorCodes.VIBRANT_YELLOW,
	SpectraColorCodes.RICH_GREEN,
	SpectraColorCodes.PRIMARY_RED,
	SpectraColorCodes.PRIMARY_GREEN,
	SpectraColorCodes.PRIMARY_BLUE,
];
