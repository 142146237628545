import { FC } from 'react';
import { Tr, DateUtils } from '@utils';
import { IrAnalysis, getIrAnalysisRoute } from '@services';
import { Card, Stack, Typography, TypographyProps } from '@mui/material';
import { OverflowText } from '@components/common';
import { IrAnalysisCircularProgress, IrAnalysisTypeIndicator } from '@components/ir-portal';
import { Link } from 'react-router-dom';

interface IMyAnalysesCardProps {
	analysis: IrAnalysis;
	dataTestId: string;
}

const titleStyle: TypographyProps['sx'] = {
	fontSize: '1.125rem',
	fontWeight: 700,
	marginTop: '1.5rem',
	marginBottom: '1.5rem',
};

const labelStyle: TypographyProps['sx'] = {
	color: 'text.main',
	whiteSpace: 'nowrap',
	height: '1.25rem',
};

const textStyle: TypographyProps['sx'] = {
	color: 'grey.800',
	whiteSpace: 'nowrap',
	height: '1.25rem',
};

const viewDetailsStyle: TypographyProps['sx'] = {
	color: 'primary.main',
	fontSize: '0.9375rem',
	fontWeigth: 700,
	marginTop: '1.5rem',
	'&:hover': {
		textDecoration: 'underline',
	},
};

export const ANALYSIS_CARD_WIDTH = 18.25;
const cardStyle = {
	padding: '1rem',
	marginY: 2,
	width: `${ANALYSIS_CARD_WIDTH}rem`,
	height: '30rem',
	cursor: 'pointer',
	boxShadow: '0 0 0.15rem 0.05rem rgba(0,0,0,0.1)',
	[':hover']: {
		boxShadow: '0.25rem 0.25rem 0.75rem 0.25rem rgba(0,0,0,0.2)',
		transform: 'translate(-1%, -1.6%)',
	},
	transitionProperty: 'transform, box-shadow',
	transitionDuration: '0.3s',
};

export const MyAnalysesCard: FC<IMyAnalysesCardProps> = ({ analysis }) => {
	const { id, stage, type, technology, operatorName, measurementDate, title, substances } = analysis;

	return (
		<Card variant="outlined" sx={cardStyle}>
			<Link to={`../${getIrAnalysisRoute(stage)}/${id}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
				<IrAnalysisCircularProgress analysisStage={stage} sx={{ padding: 1 }} />
				<OverflowText sx={titleStyle}>{title || '-'}</OverflowText>
				<Stack direction="row" spacing="0.5rem">
					<Stack spacing={1}>
						<Typography sx={labelStyle}>
							<Tr.IrPortal path="dashboard.my-analyses.analysis-type" />:
						</Typography>
						<Typography sx={labelStyle}>
							<Tr.IrPortal path="dashboard.my-analyses.technology" />:
						</Typography>
						<Typography sx={labelStyle}>
							<Tr.IrPortal path="dashboard.my-analyses.substances" />:
						</Typography>
						<Typography sx={labelStyle}>
							<Tr.IrPortal path="dashboard.my-analyses.measurement-date" />:
						</Typography>
						<Typography sx={labelStyle}>
							<Tr.IrPortal path="dashboard.my-analyses.operator-id" />:
						</Typography>
					</Stack>
					<Stack spacing={1} sx={{ width: '35%' }}>
						<IrAnalysisTypeIndicator variant="pg-s" sx={textStyle} type={type} />
						<OverflowText sx={textStyle}>{technology || '-'}</OverflowText>
						<OverflowText sx={textStyle}>{substances || '-'}</OverflowText>
						<OverflowText sx={textStyle}>{DateUtils.getFormattedDate(measurementDate) || '-'}</OverflowText>
						<OverflowText sx={textStyle}>{operatorName || ''}</OverflowText>
					</Stack>
				</Stack>
				<Typography sx={viewDetailsStyle}>
					<Tr.IrPortal path="dashboard.my-analyses.view-details" />
				</Typography>
			</Link>
		</Card>
	);
};
