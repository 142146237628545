import { Currency, SubscriptionDefaults } from '@models/subscribe';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { formFieldSpacer, RxUtils, Tr } from '@utils';
import { organizationService, CreateOrganizationModel, notificationService } from '@services';
import { Stack, Typography } from '@mui/material';
import { OrganizationFormLayout, OrganizationName, OrganizationSaveFields } from '../Organization';
import { OrganizationLabsFormLayout } from '../OrganizationLabsFormLayout';
import { SubscriptionFormLayout } from '../Subscription';
import { analyticsOrganizationEvent } from '@utils/Analytics/organization-events';
import dayjs from 'dayjs';
import { getCreateOrganizationValidationSchema } from '@schemas';

type FormFields = Extendable<CreateOrganizationModel>;

export const AddOrganizationLayout = () => {
	const { t } = useTranslation('admin');
	const navigate = useNavigate();

	const formik = useFormik<FormFields>({
		initialValues: {
			name: '',
			legalName: '',
			street: '',
			number: '',
			zipCode: '',
			city: '',
			country: '',
			mainContactPerson: '',
			email: '',
			adminEmail: '',
			sapCustomerId: '',
			phoneCode: '',
			phoneDigits: '',
			isEditing: false,
			freeSubscription: {
				budget: SubscriptionDefaults.FREE_SUBSCRIPTION_COUNT,
				startDate: dayjs(),
				endDate: dayjs().add(SubscriptionDefaults.MONTHS, 'M'),
				comment: '',
			},
			paidSubscription: {
				paymentType: null,
				budget: SubscriptionDefaults.BUDGET,
				startDate: dayjs(),
				endDate: dayjs().add(SubscriptionDefaults.MONTHS, 'M'),
				currency: Currency.USD,
				pricing: {
					NMR: SubscriptionDefaults.PRICING,
				},
				comment: '',
			},
			laboratories: [
				{
					name: '',
					technology: '',
					adminEmail: '',
				},
			],
		},
		validationSchema: getCreateOrganizationValidationSchema(t),
		onSubmit: (rest) => {
			const createOrganizationModel: CreateOrganizationModel = {
				...rest,
				paidSubscription: rest.paidSubscription.paymentType ? rest.paidSubscription : undefined,
			} as CreateOrganizationModel;
			RxUtils.promisify(
				organizationService.create(createOrganizationModel),
				(data) => (
					analyticsOrganizationEvent.OrganizationCreated(`${data.id}`),
					navigate(-1),
					notificationService.sendSuccess(t('organization.create-successfully'))
				),
			);
		},
	});

	return (
		<Stack data-testid="add-organization-id" spacing={formFieldSpacer} marginBottom={7}>
			<form onSubmit={formik.handleSubmit} data-testid="add-organization-form-test-id">
				<Stack direction="row" alignItems="center" sx={{ marginBottom: 5 }}>
					<Typography variant="h1" sx={{ color: 'text.secondary' }}>
						<Tr.Admin path="organization.add-new-organization" />
					</Typography>
					<OrganizationSaveFields formik={formik} editing={false} />
				</Stack>
				<OrganizationName organizationName={t('organization.organization-details')} />
				<Stack direction="column" width={1} justifyContent="space-between">
					<OrganizationFormLayout formik={formik} />
					<SubscriptionFormLayout formik={formik} formWidth="100%" sx={{ marginTop: 3 }} />
					<OrganizationLabsFormLayout formik={formik} />
				</Stack>
			</form>
		</Stack>
	);
};
