export enum IrAdminPermission {
	COMMENT_DRM_REQUEST = 'SubstanceManagement.DrmRequest.Ir.Comment',
	CREATE_DRM_REQUEST = 'SubstanceManagement.DrmRequest.Ir.Create',
	DOWNLOAD_WAVE_REPORT = 'DrmPipeline.Ir.Wave.Report.Download',
	EDIT_DRM = 'SubstanceManagement.Drm.Ir.Edit',
	EDIT_DRM_REQUEST = 'SubstanceManagement.DrmRequest.Ir.Edit',
	RELEASE_WAVE = 'DrmPipeline.Ir.Wave.Release',
	REMOVE_FAILED_UPLOAD = 'DrmPipeline.Ir.Wave.FailUpload.Remove',
	REMOVE_FAILED_WAVE = 'DrmPipeline.Ir.Wave.Failed.Remove',
	REMOVE_RELEASED_WAVE = 'DrmPipeline.Ir.Wave.Released.Remove',
	UPLOAD_FILE = 'DrmPipeline.Ir.File.Upload',
	VIEW_BOARD = 'DrmPipeline.Ir.Board.View',
	VIEW_DRM_PIPELINE = 'Admin.DrmPipeline.Ir',
	DELETE_DRM_REQUEST_DETAIL_BANNER = 'SubstanceManagement.DrmRequest.Ir.Banner.Close',
}

export enum NmrAdminPermission {
	APPROVE_DRM_ORDER = 'DrmPipeline.Nmr.Order.Approval',
	ASSIGN_DRM_ORDER = 'DrmPipeline.Nmr.Order.Assignment',
	COMMENT_DRM_REQUEST = 'SubstanceManagement.DrmRequest.Nmr.Comment',
	CREATE_DRM_ORDER = 'SubstanceManagement.Substance.DrmOrder.Nmr.Create',
	CREATE_DRM_REQUEST = 'SubstanceManagement.DrmRequest.Nmr.Create',
	DECLINE_DRM_ORDER = 'DrmPipeline.Nmr.Order.Decline',
	DRM_ORDER_PRIORITY_SETTING = 'DrmPipeline.Nmr.Order.PrioritySetting',
	DRM_ORDER_TO_IN_PRODUCTION = 'DrmPipeline.Nmr.Order.ToInProduction',
	DRM_ORDER_TO_READY_FOR_QC = 'DrmPipeline.Nmr.Order.ToReadyForQc',
	DRM_ORDER_TO_QA_REVIEW = 'DrmPipeline.Nmr.Order.ToQaReview',
	DRM_ORDER_WAVE_PLANNING = 'DrmPipeline.Nmr.Order.WavePlanning',
	DOWNLOAD_BACKLOG_ITEM = 'DrmPipeline.Nmr.Backlog.Item.Download',
	DOWNLOAD_ORDER_FILE = 'DrmPipeline.Nmr.Order.File.Download',
	DOWNLOAD_RELEASED_FILE = 'DrmPipeline.Nmr.Released.File.Download',
	EDIT_DRM = 'SubstanceManagement.Drm.Nmr.Edit',
	EDIT_DRM_ORDER = 'DrmPipeline.Nmr.Order.Edit',
	EDIT_DRM_REQUEST = 'SubstanceManagement.DrmRequest.Nmr.Edit',
	MANAGE_BACKLOG = 'DrmPipeline.Nmr.Backlog.Management',
	REJECT_DRM_ORDER = 'DrmPipeline.Nmr.Order.Rejection',
	SOLVENT_DRM_ORDER = 'DrmPipeline.Nmr.Order.Solvent',
	UPLOAD_FILE = 'DrmPipeline.Nmr.Order.File.Upload',
	VIEW_BOARD = 'DrmPipeline.Nmr.Board.View',
	VIEW_BACKLOG = 'DrmPipeline.Nmr.Backlog.View',
	VIEW_DECLINED_ORDER = 'DrmPipeline.Nmr.Declined.View',
	VIEW_DRM_PIPELINE = 'Admin.DrmPipeline.Nmr',
	VIEW_RELEASED_NMR = 'DrmPipeline.Nmr.Released.View',
	DELETE_DRM_REQUEST_DETAIL_BANNER = 'SubstanceManagement.DrmRequest.Nmr.Banner.Close',
	DRM_ORDER_CHANGE_FLAG = 'DrmPipeline.Nmr.Order.Flag',
	REACTIVATE_DRM_ORDER = 'DrmPipeline.Nmr.Order.Reactivate',
}

export const TechnologyPermissionKeys = Object.fromEntries(
	[...Object.keys(NmrAdminPermission), ...Object.keys(IrAdminPermission)].map((value) => [value, value]),
);

export enum AdminPermission {
	VIEW_CUSTOMER_MANAGEMENT = 'Admin.CustomerManagement',
	VIEW_SUBSTANCE_MANAGEMENT = 'Admin.SubstanceManagement',
	VIEW_USER_MANAGEMENT = 'Admin.MerckUserManagement',
	VIEW_DASHBOARD = 'Admin.Dashboard',
	VIEW_USERS = 'Merck.User.View',
	VIEW_DRM = 'SubstanceManagement.Drm.View',
	VIEW_DRM_REQUEST = 'SubstanceManagement.DrmRequest.View',
	VIEW_SUBSTANCE = 'SubstanceManagement.Substance.View',
	CREATE_SUBSTANCE = 'SubstanceManagement.Substance.Create',
	EDIT_THEORETICAL_SPECTRUM = 'SubstanceManagement.TheoreticalSpectrum.Edit',
	EDIT_ADMIN_USER = 'Merck.User.Edit',
}

export enum OrganizationPermission {
	VIEW_DETAILS = 'Organization.Details',
	DEACTIVATE = 'Organization.Deactivate',
	EDIT_DETAILS = 'Organization.Edit',
	USER = 'Organization.User',
	EDIT_SUBSCRIPTION_DETAILS = 'Organization.Subscription.Edit',
	VIEW_SUBSCRIPTION_DETAILS = 'Organization.Subscription.Details',
	VIEW_DASHBOARD = 'Organization.Dashboard',
}
export enum LabPermission {
	LIST = 'Laboratory.List',
	CREATE = 'Laboratory.Create',
	VIEW_DETAILS = 'Laboratory.Details',
	DEACTIVATE = 'Laboratory.Deactivate',
	EDIT = 'Laboratory.Edit',
	CREATE_USER = 'Laboratory.User.Create',
	DELETE_USER = 'Laboratory.User.Delete',
	LIST_USER = 'Laboratory.User.List',
	USER_LAST_ACTIVE = 'Laboratory.User.LastActive',
	VIEW_SETTINGS = 'Laboratory.Settings.View',
	EDIT_SETTINGS = 'Laboratory.Settings.Edit',
	VIEW_DASHBOARD = 'Laboratory.Dashboard',
	VIEW_ANALYSIS = 'Laboratory.Analysis',
	ANALYSIS_OWNER = 'Laboratory.Analysis.Owner',
	ANALYSIS_APPROVAL = 'Laboratory.Analysis.Approval',
	VIEW_AUDIT_TRAIL = 'Laboratory.AuditTrail',
	DRM = 'Laboratory.DRM',
	FAVORITE = 'Laboratory.Favorite',
	WEBSHOP = 'Laboratory.Webshop',
	DEVICE = 'Laboratory.Device',
}

export enum UserPermission {
	USER_PROFILE = 'User.Profile',
	USER_REFERENCE_CREATE = 'UserReference.Create',
}
export const PermissionEnum = {
	AdminPermission,
	NmrAdminPermission,
	IrAdminPermission,
	UserPermission,
	LabPermission,
	OrganizationPermission,
};
export type PermissionEnum = typeof PermissionEnum;
