import { useService } from '@hooks/common';
import { irLibraryService } from '@services/ir';

const MAX_LIBRARY_COUNT = '999';

export const useLibraries = () => {
	const { data: libraries } = useService(() => {
		const searchParams = new URLSearchParams();
		searchParams.set('pageIndex', '1');
		searchParams.set('pageSize', MAX_LIBRARY_COUNT);
		searchParams.set('sortBy', 'createTime');
		searchParams.set('sortOrder', 'desc');
		return irLibraryService.get(searchParams);
	}, []);

	return libraries?.data ?? [];
};
