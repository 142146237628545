import { CompletedStatusIcon, ICompletedStatusIcon } from '@components/common/Legend';
import { NmrAnalysis } from './nmr-analysis.class';
import { Tr, TypeUtils } from '@utils';
import { NmrAnalysisStage } from './nmr-analysis-stage.enum';
import { NMRAnalysisResultStatus } from './nmr-analysis-status.enum';
import DoneAll from '@mui/icons-material/DoneAll';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import ScienceOutlined from '@mui/icons-material/ScienceOutlined';
import { SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ReactComponent as Tune } from '@material-symbols/svg-600/outlined/discover_tune.svg';
import { ReactComponent as Check } from '@material-symbols/svg-600/outlined/check.svg';
import { ReactComponent as QuickReference } from '@material-symbols/svg-600/outlined/quick_reference_all.svg';

export const NMRAnalysisResultStatusIconMap: Record<NMRAnalysisResultStatus, FC<ICompletedStatusIcon>> = {
	[NMRAnalysisResultStatus.SATISFIED]: ({ sx }) => <CompletedStatusIcon.Done sx={sx} />,
	[NMRAnalysisResultStatus.QUESTIONABLE]: ({ sx }) => <CompletedStatusIcon.QuestionMark sx={sx} />,
	[NMRAnalysisResultStatus.UNSATISFIED]: ({ sx }) => <CompletedStatusIcon.Close sx={sx} />,
	[NMRAnalysisResultStatus.TECHNICAL_ERROR]: ({ sx }) => <CompletedStatusIcon.Remove sx={sx} />,
	[NMRAnalysisResultStatus.ONLY_THEORETICAL]: ({ sx }) => <CompletedStatusIcon.OnlyTheoretical sx={sx} />,
};
export const getCompletedNMRAnalysisResultStatusIcon = (analysis: NmrAnalysis, sx?: SxProps) =>
	NMRAnalysisResultStatusIconMap[analysis.status]?.({ sx });

const NMRAnalysisResultStatusInfoMap = {
	[NMRAnalysisResultStatus.SATISFIED]: () => <Tr.Portal path="completed-history-legend.green-tick" />,
	[NMRAnalysisResultStatus.QUESTIONABLE]: () => <Tr.Portal path="completed-history-legend.yellow-questionmark" />,
	[NMRAnalysisResultStatus.UNSATISFIED]: () => <Tr.Portal path="completed-history-legend.red-cross" />,
	[NMRAnalysisResultStatus.TECHNICAL_ERROR]: () => <Tr.Portal path="completed-history-legend.grey-dash" />,
	[NMRAnalysisResultStatus.ONLY_THEORETICAL]: () => <Tr.Portal path="completed-history-legend.theoretical-match" />,
};
export const getNMRAnalysisResultStatusInfo = (analysis: NmrAnalysis) => NMRAnalysisResultStatusInfoMap[analysis.status]?.();

type TStageIcon =
	| NmrAnalysisStage.NEW
	| NmrAnalysisStage.DETAILS_FILLED
	| NmrAnalysisStage.EXECUTING
	| NmrAnalysisStage.EXECUTED
	| NmrAnalysisStage.CONFIRMED_BY_LAB_MEMBER
	| NmrAnalysisStage.APPROVED_BY_LAB_ADMIN;
const NMRAnalysisStageIcon: Record<TStageIcon, ReactNode> = {
	[NmrAnalysisStage.NEW]: <Tune width={24} height={24} />,
	[NmrAnalysisStage.DETAILS_FILLED]: <QuickReference width={24} height={24} />,
	[NmrAnalysisStage.EXECUTING]: <ScienceOutlined />,
	[NmrAnalysisStage.EXECUTED]: <Check width={24} height={24} />,
	[NmrAnalysisStage.CONFIRMED_BY_LAB_MEMBER]: <DoneAll />,
	[NmrAnalysisStage.APPROVED_BY_LAB_ADMIN]: <Check />,
};

export const getNMRAnalysisStageIcon = (stage: NmrAnalysisStage): ReactNode => {
	return TypeUtils.returnValueOfKey(stage as TStageIcon, NMRAnalysisStageIcon) ?? <ErrorOutlineRounded />;
};
