import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { Tr } from '@utils/Translation';

export const ManualEntry = () => {
	return (
		<Tooltip title={<Tr.Admin path="drm-order-details.manual-entry" />}>
			<InfoOutlined sx={{ width: 20, height: 20, color: 'grey.800' }} />
		</Tooltip>
	);
};
