import { FC, ReactNode } from 'react';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as FlaskLogo } from '@assets/icons/flask.svg';
import { ModalDialog } from '@components/common';

const ProgressAnimation = () => {
	return (
		<Stack
			justifyContent="center"
			alignItems="center"
			sx={{ height: 100, width: 100, backgroundColor: '#01884C', borderRadius: '50%' }}
		>
			<SvgIcon component={FlaskLogo} viewBox={`0 0 32 32`} sx={{ fontSize: '2.9rem' }} />
		</Stack>
	);
};

type TUserReferencesAsyncModal = {
	title?: ReactNode;
	subtitle: ReactNode;
	message?: ReactNode;
	onClose: () => void;
};

export const UserReferencesAsyncModal: FC<TUserReferencesAsyncModal> = ({ title, subtitle, message, onClose }) => {
	return (
		<>
			<ModalDialog
				variant="primary"
				fullWidth={false}
				open={true}
				titleProps={{
					sx: {
						justifyContent: 'space-between',
						marginTop: 0,
						marginBottom: 5,
						minHeight: 30,
						backgroundColor: 'grey.50',
						paddingY: 1.2,
						paddingX: 2,
					},
				}}
				title={
					<Typography variant="body2" sx={{ fontWeight: 400, lineHeight: '200%' }} data-testid="user-sync-modal">
						{title}
					</Typography>
				}
				onClose={onClose}
				maxWidth="sm"
				contentProps={{ sx: { width: 472, height: 350 } }}
			>
				<Stack width={1} direction="column" alignItems="center" justifyContent="space-between">
					<Stack sx={{ textAlign: 'center', paddingX: 8 }}>
						<Stack justifyContent="center" alignItems="center" marginBottom={5}>
							<ProgressAnimation />
						</Stack>
						<Typography variant="subtitle1">{subtitle}</Typography>
						<Typography variant="pg-m" sx={{ marginTop: '1rem', lineHeight: '160%' }}>
							{message}
						</Typography>
					</Stack>
				</Stack>
			</ModalDialog>
		</>
	);
};
