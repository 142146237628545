import { Stack, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { AdjustableSwitch } from '../AdjustableSwitch';

interface ILabSettingsSwitchProps {
	status: boolean;
	onStatusChange: (status: boolean) => void;
	sx?: SxProps;
	tooltipTitle?: string;
	tooltipContent?: string;
	isDisabled?: boolean;
	isLabDeactive?: boolean;
	disableHoverListener?: boolean;
}
export const LabSettingsSwitch: FC<ILabSettingsSwitchProps> = ({
	status,
	onStatusChange,
	tooltipTitle,
	isDisabled = false,
	disableHoverListener = false,
	isLabDeactive = false,
}) => {
	return (
		<Stack direction="column">
			<Tooltip title={tooltipTitle ?? ''} placement="bottom" disableHoverListener={disableHoverListener}>
				{/* Tooltip doesnt work on disabled elements, to accomodate. TO DO: FIND CLASSIER WAY TO DO IT */}
				<Stack>
					<AdjustableSwitch
						name="labSwitch"
						checked={status}
						onChange={() => onStatusChange(!status)}
						disabled={isDisabled}
						size="medium"
						switchTheme="onOff"
						sx={{
							margin: 0,
							'.Mui-disabled + .MuiSwitch-track': {
								backgroundColor: isLabDeactive ? '#E1E1EA !important' : undefined,
								opacity: isLabDeactive ? '1 !important' : undefined,
							},
						}}
					/>
				</Stack>
			</Tooltip>
		</Stack>
	);
};
