import { IConfig } from './config.interface';

export const config: IConfig = {
	apiUrl: process.env.REACT_APP_API_URL || 'localhost',
	analysisHubUrl: process.env.REACT_APP_API_HUB_URL || 'localhost',
	notificationHubUrl: process.env.REACT_APP_NOTIFICATION_HUB_URL || 'localhost',
	deviceHubUrl: process.env.REACT_APP_DEVICE_HUB_URL || 'localhost',
	analysisTheoreticalHubUrl: process.env.REACT_APP_ANALYSIS_THEORETICAL_HUB_URL || 'localhost',
	quantitativeNonTargetedIntegrationUrl: process.env.REACT_APP_QUANTITATIVE_NON_TARGETED_INTEGRATION_HUB_URL || 'localhost',
	irWaveHubUrl: process.env.REACT_APP_IR_WAVE_HUB_URL || 'localhost',
	userHubUrl: process.env.REACT_APP_USER_HUB_URL || 'localhost',
	customReferenceHubUrl: process.env.REACT_APP_CUSTOMER_REFERENCE_MATERIAL_HUB_URL || 'localhost',

	version: process.env.REACT_APP_VERSION || '',
	aws: {
		region: process.env.REACT_APP_AWS_REGION || '',
		userPoolId: process.env.REACT_APP_AWS_USERPOOLID || '',
		userPoolWebClientId: process.env.REACT_APP_AWS_USERPOOLCLIENTID || '',
		oauthDomain: process.env.REACT_APP_AWS_OAUTHDOMAIN || '',
		adminPoolId: process.env.REACT_APP_ADMIN_AWS_USERPOOLID || '',
		adminPoolWebClientId: process.env.REACT_APP_ADMIN_AWS_USERPOOLCLIENTID || '',
		adminOauthDomain: process.env.REACT_APP_ADMIN_AWS_OAUTHDOMAIN || '',
	},
	googleAnalytics: {
		trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '',
	},
};
