import { Stack } from '@mui/material';
import { useState } from 'react';
import { NmrDrmOrderStatus, NmrDrmOrderStatusEnumValue, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { useTranslation } from 'react-i18next';
import { useService } from '@hooks';
import { getParams, useFilterSearchParams } from '@hooks';
import { SortOrder } from '@models';
import { useSelector } from 'react-redux';
import { productionFilterDataSelector } from '@store/slices/nmr/pipeline.slice';
import { groupNmrDrmOrders } from '../NmrPipelineBacklog/nmr-pipeline-helper';
import { DrmPipelineCardGroup } from '../DrmPipeline';
import { EmptyResult } from '@components/common/EmptyResult';
import { FilterChips } from '@components/common/Filter/FilterChips';
import { DataTestId } from '@utils/DataTestId';

const PRODUCTION_STATUS_IDS = [
	NmrDrmOrderStatusEnumValue.READY,
	NmrDrmOrderStatusEnumValue.IN_PRODUCTION,
	NmrDrmOrderStatusEnumValue.WAITING_FOR_MEASUREMENT,
	NmrDrmOrderStatusEnumValue.QC_INSPECTION,
	NmrDrmOrderStatusEnumValue.QA_REVIEW,
];

const PRODUCTION_STATUES = [
	NmrDrmOrderStatus.READY,
	NmrDrmOrderStatus.IN_PRODUCTION,
	NmrDrmOrderStatus.QC_INSPECTION,
	NmrDrmOrderStatus.QA_REVIEW,
];

export const NmrPipelineProduction = () => {
	const { t: translate } = useTranslation('admin');
	const { searchParams, filtering } = useFilterSearchParams('', SortOrder.RESET);
	const productionFilterData = useSelector(productionFilterDataSelector);
	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');

	const { data: nmrPipelineData, trigger: refreshNmrDrmOrders } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		searchParams.set('pageIndex', '1');
		searchParams.delete('pageSize');
		searchParams.delete('statuses');
		PRODUCTION_STATUS_IDS.forEach((status) => searchParams.append('statuses', String(status)));

		return nmrDrmOrderService.getAllWithSearchParams(searchParams);
	}, [searchParams]);

	const showCardGroups = (nmrPipelineData?.data && nmrPipelineData.data.length > 0) || searchTextfieldValue.length > 0;
	const orderGroups = groupNmrDrmOrders(nmrPipelineData?.data ?? []);
	return (
		<Stack direction="column">
			{productionFilterData?.filterData && filtering && (
				<FilterChips
					sx={{ borderBottom: '', mb: 2, ml: -1.5, mt: -2 }}
					onDeleteItem={(deletedItemFromChip) => productionFilterData?.setDeletedItem(deletedItemFromChip)}
					filterData={productionFilterData?.filterDataFiltered || []}
					selectedFilterData={filtering || []}
				/>
			)}
			{showCardGroups ? (
				<Stack
					direction="row"
					spacing={4}
					sx={{ width: '100%', overflow: 'auto' }}
					data-testid={DataTestId.getStaticTestId('nmr-pipeline-production-result-list')}
				>
					{PRODUCTION_STATUES.map((status: NmrDrmOrderStatus) => (
						<DrmPipelineCardGroup
							key={`production_pipeline_${status}`}
							title={translate(`drm-pipeline.nmr.production.status.${status}`)}
							orders={orderGroups[`${status}`] ?? []}
							refreshNmrDrmOrders={refreshNmrDrmOrders}
						/>
					))}
				</Stack>
			) : (
				<EmptyResult
					title={translate('drm-pipeline.nmr.production.no-drm-orders')}
					explanation={translate(
						productionFilterData?.filterData
							? 'drm-pipeline.nmr.production.no-result-explanation'
							: 'drm-pipeline.nmr.production.no-drm-orders-explanation',
					)}
				/>
			)}
		</Stack>
	);
};
