import { Stack } from '@mui/material';
import { Tr } from '@utils/Translation';
import { IrDRMIndicator } from '../IrDrmDetailSection';
import { IrDrmDetail } from '@services/ir';
import { FC } from 'react';
import { DateUtils } from '@utils/Type';

type TIrUserReferenceCardContent = {
	detailData?: IrDrmDetail;
};

export const IrUserReferenceCardContent: FC<TIrUserReferenceCardContent> = ({ detailData }) => {
	return (
		<Stack direction="row">
			<Stack width="30.25rem">
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.cas-number" />} value={detailData?.casNumber} />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.linear-formula" />} value={detailData?.linearFormula} />
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.molecular-weight" />}
					value={detailData?.molecularWeight ? detailData?.molecularWeight : undefined}
					unit="g/mol"
				/>
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.smiles" />} value={detailData?.smilesCode} />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.sampling-technique" />} value={detailData?.samplingTechnique} />
				<IrDRMIndicator label={<Tr.Portal path="drm-detail.number-of-scans" />} value={detailData?.numberOfScans} />
			</Stack>

			<Stack width="43.25rem">
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.erm-resolution" />} value={detailData?.resolution} unit="cm⁻¹" />
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.erm-lower-range" />}
					value={detailData?.lastX?.split('.')[0]}
					unit="cm⁻¹"
				/>
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.erm-upper-range" />}
					value={detailData?.firstX?.split('.')[0]}
					unit="cm⁻¹"
				/>
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.batch-number" />} value={detailData?.physicalProductBatchNumber} />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.reference-code" />} value={detailData?.drmCode} />
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.creation-date" />}
					value={detailData?.createTime ? DateUtils.getFormattedDate(detailData?.createTime) : '-'}
				/>
			</Stack>
		</Stack>
	);
};
