import { FC } from 'react';
import { SingleSelectMenu, ISelectMenuProps, SingleSelectMenuItemType, SingleSelectMenuOnSelectType, getValue } from '../SingleSelectMenu';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { Tr } from '@utils/Translation';
import { Stack } from '@mui/material';

interface LibrarySelectProps extends Omit<ISelectMenuProps, 'items'> {
	onAddMore?: () => void;
	libraryData: SingleSelectMenuItemType[];
	disabled?: boolean;
}

const ADD_SELECT_VALUE = {
	id: 'addMore',
	label: (
		<Stack direction="row">
			<Add sx={{ fontSize: '20px', color: 'primary.main', marginRight: 0.5 }} />
			<Typography color="primary.main">
				<Tr.IrPortal path="user-references.add-new-library" />
			</Typography>
		</Stack>
	),
};

const SelectLibraryDropdown: FC<LibrarySelectProps> = ({ onAddMore, selectedItem, onSelectedItemChange, libraryData, disabled }) => {
	const handleSelectChange: SingleSelectMenuOnSelectType = (item) => {
		const selectedId = getValue(item as string);

		if (selectedId === ADD_SELECT_VALUE.id) {
			onAddMore?.();
		} else {
			onSelectedItemChange?.(item);
		}
	};

	const libraryItems = [...libraryData, ADD_SELECT_VALUE];

	return (
		<SingleSelectMenu
			items={libraryItems}
			selectedItem={selectedItem}
			onSelectedItemChange={handleSelectChange}
			defaultLabel="Select a Library"
			showDefaultButton={true}
			sx={{ height: '40px' }}
			applyMenuItemBorder={true}
			disabled={disabled}
			key={selectedItem ? String(selectedItem) : 'default'}
			MenuProps={{
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left',
				},
				transformOrigin: {
					vertical: 'top',
					horizontal: 'left',
				},
				PaperProps: {
					sx: {
						ul: {
							marginTop: 0,
							marginBottom: 0,
							paddingTop: 0,
							paddingBottom: 0.5,
						},
						li: {
							paddingTop: 1,
							paddingBottom: 1,
						},
					},
				},
			}}
		/>
	);
};

export default SelectLibraryDropdown;
