import { AnalysisTechnology } from '@services';
import { AnalysisTargetType, QualitativeOrQuantitative } from '@models';

export type FormFields = Extendable<{
	technology: AnalysisTechnology;
	measurementType?: QualitativeOrQuantitative;
	targetType?: AnalysisTargetType;
	title: string;
	measurementId: string;
	measurementDeviceBrand: string;
	solvent: string;
	productAndBatchNumber: string;
	measurementExecutorId: string;
	projectId: string;
	details: string;
	measurementDate: Date;
	sampleWeight: number;
	solventWeight: number;
	secondApproval: boolean;
	deviceCalibrationId: number;
	molecularWeight: number;
	peakMin1: number;
	peakMin2: number;
	peakMax2: number;
	peakMax1: number;
}>;

export const initialFormValues = {
	initialValues: {
		technology: 'NMR',
		measurementType: null,
		targetType: null,
		title: '',
		measurementId: '',
		measurementDeviceBrand: '',
		solvent: '',
		productAndBatchNumber: '',
		projectId: '',
		details: '',
		measurementExecutorId: '',
		measurementDate: '',
		sampleWeight: '',
		solventWeight: '',
		secondApproval: true,
		deviceCalibrationId: null,
		peakMin1: null,
		peakMin2: null,
		peakMax2: null,
		peakMax1: null,
	},
	initialTouched: {
		title: true,
		measurementId: true,
		solvent: true,
		measurementDeviceBrand: true,
		measurementDate: true,
		deviceCalibrationId: true,
	},
};
