import { DataTableBackend, ModalDialog } from '@components/common';
import { getParams, setParams, useFilterSearchParams } from '@hooks';
import { useService } from '@hooks';
import { ExtendedColumn, SortOrder } from '@models';
import { Link, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, useMemo, useState } from 'react';
import { subscribeService, Subscription } from '@services';
import { DateUtils } from '@utils';

import { SubscriptionDetailsLayout } from '@components/common/SubscriptionDetailsLayout';
import { useTranslation } from 'react-i18next';

interface SubscriptionHistoryProps {
	organizationDataId: number;
}

export const SubscriptionHistory: FC<SubscriptionHistoryProps> = ({ organizationDataId }) => {
	const { searchParams } = useFilterSearchParams('', SortOrder.RESET);

	const { t } = useTranslation('admin');

	const { data: subscriptionHistory } = useService(() => {
		setParams(searchParams).setPageSize('0');
		setParams(searchParams).setPageIndex('1');
		return subscribeService.getSubscriptionHistory(organizationDataId, searchParams);
	}, [searchParams]);

	const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);

	const columns = useMemo<ExtendedColumn<Subscription>[]>(
		() => [
			{
				id: 'id',
				disableSortBy: true,
				accessor: (originalRow) => originalRow.id,
				Header: <Tr.Common path="subscription.id" />,
				Cell: ({ value, row: { original } }) => (
					<Link
						underline="hover"
						onClick={() => {
							setSelectedSubscription(original);
						}}
						sx={{ cursor: 'pointer' }}
					>
						<Typography variant="pg-s">{value?.toString()}</Typography>
					</Link>
				),
			},
			{
				id: 'subscriptionType',
				accessor: (originalRow) => originalRow.subscriptionType,
				Header: <Tr.Common path="subscription.subscription-type" />,
				Cell: ({ value }) => (
					<Typography variant="pg-s">
						{value ? <Tr.Common path={`subscription.${value?.toLowerCase() + '-subscription'}`} /> : '-'}
					</Typography>
				),
			},
			{
				id: 'paymentType',
				accessor: (originalRow) => originalRow.paymentType,
				Header: <Tr.Common path="subscription.billing-type" />,
				Cell: ({ value }) => (
					<Typography variant="pg-s">{value ? <Tr.Common path={`subscription.${value?.toLowerCase()}`} /> : '-'}</Typography>
				),
			},
			{
				id: 'startDate',
				accessor: (originalRow) => originalRow.startDate,
				Header: <Tr.Common path="subscription.start-date" />,
				Cell: ({ value }) => (
					<Typography variant="pg-s">{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy') : '-'}</Typography>
				),
			},
			{
				id: 'endDate',
				accessor: (originalRow) => originalRow.endDate,
				Header: <Tr.Common path="subscription.end-date" />,
				Cell: ({ value }) => (
					<Typography variant="pg-s">{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy') : '-'}</Typography>
				),
			},
		],
		[subscriptionHistory],
	);

	return (
		<>
			<Stack direction="row" sx={{ marginBottom: 1, marginTop: 2.5 }} justifyContent="space-between" alignItems="center">
				<Typography variant="h2" sx={{ color: 'text.secondary' }}>
					<Tr.Common path={'subscription.title'} />
				</Typography>
			</Stack>
			<DataTableBackend
				data={subscriptionHistory?.data || []}
				columns={columns}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				cardSx={{ backgroundColor: 'background.default', border: 'none' }}
				tableHeaderCellSx={{ color: 'grey.800' }}
			/>
			{selectedSubscription && (
				<ModalDialog
					variant="primary"
					open={!!selectedSubscription}
					width={616}
					height={501}
					tag={t('subscription.expired')}
					dialogSx={{ marginTop: 0 }}
					closeIconClick={() => {
						setSelectedSubscription(null);
					}}
					iconProps={{ width: 20, height: 20 }}
					iconButtonProps={{ top: 'none' }}
				>
					<SubscriptionDetailsLayout
						subscriptionData={selectedSubscription}
						editing={false}
						cardSx={{ border: 'none', padding: '0px 24px 32px 24px' }}
						details={true}
					/>
				</ModalDialog>
			)}
		</>
	);
};
