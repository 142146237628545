import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as Logo } from '@assets/icons/notification-warning.svg';

export const NotificationWarningIcon: FC<SvgIconProps> = ({ sx, ...rest }) => {
	return (
		<SvgIcon
			component={Logo}
			viewBox={`0 0 2 8`}
			data-testid="notification-warning-icon-id"
			sx={{
				fontSize: '20px',
				borderRadius: '50%',
				border: '2px solid white',
				padding: '1px',
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				...sx,
			}}
			{...rest}
		/>
	);
};
