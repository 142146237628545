import { Type } from 'class-transformer';
import { NmrDrmOrderAssignee, NrmDrmOrderAssignee, NrmDrmOrderFile } from './nmr-drm-order.interface';
import {
	NmrDrmOrderFileType,
	NmrDrmOrderRejectionSeverity,
	NmrDrmOrderRequestType,
	NmrDrmOrderStatus,
	NrmDrmOrderPriority,
} from './nmr-drm-order.enum';
import { NmrDrmOrderWave } from '../nmr-drm-wave';

export class NmrDrmOrder {
	id?: number;
	name: string;
	pulseWidth: string;
	casNumber: string;
	waveId: number;
	ermCode: string;
	solvent: string;
	isCertified: true;
	frequency: string;
	linearity: number;
	intercept: number;
	substanceId: number;
	inPipeline: boolean;
	status: NmrDrmOrderStatus;
	temperature: string;
	qualityGrade: string;
	drmRequestId: number;
	receiverGain: string;
	rejectionSeverity: NmrDrmOrderRejectionSeverity;
	numberOfScans: number;
	wave: NmrDrmOrderWave;
	relaxationTime: string;
	acquisitionTime: string;
	ermProductNumber: string;
	measurementUserId: string;
	isCustomerRequest: boolean;
	priority: NrmDrmOrderPriority;
	physicalProductNumber: string;
	assignees: NrmDrmOrderAssignee[];
	nmrDrmOrderFiles: NrmDrmOrderFile[];
	massFraction: number;
	requestType: NmrDrmOrderRequestType;
	availableStatuses: NmrDrmOrderStatus[];
	isFlagged: boolean;
	isRejected: boolean;
	analyst: NmrDrmOrderAssignee;
	qcInspector: NmrDrmOrderAssignee;
	qaReviewer: NmrDrmOrderAssignee;
	fileTypes: NmrDrmOrderFileType[];
	batchNumber?: string;
	siteSupplier?: string;
	pH?: string;
	purity?: string;
	isProductActive?: boolean;

	@Type(() => Date)
	measurementDate: Date;

	@Type(() => Date)
	createTime: Date;

	@Type(() => Date)
	lastStatusTransitionDate: Date;
}
