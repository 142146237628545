import { AnalysisTechnology, IrAccessory } from '@services/index';
import { Type } from 'class-transformer';

export class IrDevice {
	id: number;
	isActive: boolean;
	name: string;
	deviceName: string;
	manufacturer: string;
	isGxP?: boolean;
	calibrationValue: number;
	accessories?: IrAccessory[];

	supportedTechnology: AnalysisTechnology;

	@Type(() => Date)
	createTime: Date;

	@Type(() => Date)
	nextCalibrationTime: Date;
}
