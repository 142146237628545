import { Type } from 'class-transformer';
import { IrCalibrationStatus } from './ir-device-status.enum';
import { IrDeviceAccessory } from './ir-device-accessory.class';

export class IrRecalibrateModel {
	name: string;
	status: IrCalibrationStatus;
	manufacturer: string;
	calibrationValue: number;
	isGxP: boolean;

	@Type(() => Date)
	lastCalibrationTime: Date;
	@Type(() => Date)
	nextCalibrationTime: Date;

	calibrationErrorMessage: string;
	deviceAccessories?: IrDeviceAccessory[];

	public isCalibrateHealthCheck() {
		return this.status === IrCalibrationStatus.HEALTH_CHECK_NEEDED;
	}

	public isCalibrationNeeded() {
		return this.status === IrCalibrationStatus.UNCALIBRATED;
	}

	public isCalibrating() {
		return this.status === IrCalibrationStatus.CALIBRATED;
	}

	public isCalibrate() {
		return this.status === IrCalibrationStatus.UNCALIBRATED;
	}

	public isCalibrationError() {
		return this.status === IrCalibrationStatus.ERROR;
	}
}
