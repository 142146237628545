import { Stack, SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
interface IndicatorType {
	label?: ReactNode;
	value?: string | number;
	unit?: string;
	containerSx?: SxProps;
}
export const invalidValues = ['NA', NaN, undefined, null, ''];

export const IrDRMIndicator: FC<IndicatorType> = ({ label, value = '', unit, containerSx }) => (
	<Stack direction="row" alignItems="center" sx={{ marginBottom: 1.5, ...containerSx }}>
		<Stack>
			<Typography variant="pg-m" sx={{ width: 222, fontWeight: 400, color: 'text.primary', marginRight: 4 }}>
				{label}
			</Typography>
		</Stack>
		<Stack>
			<Typography variant="pg-m" sx={{ color: 'grey.800', lineHeight: 1.6, wordBreak: 'break-all' }}>
				{invalidValues.includes(value) ? '-' : value}
				{value && unit ? ` ${unit}` : ''}
			</Typography>
		</Stack>
	</Stack>
);
