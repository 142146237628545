import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formFieldSpacer, Tr } from '@utils';
import { alertService, Organization } from '@services';
import { Button, Stack, SxProps, Grid } from '@mui/material';
import { FormikTextfield, FormikApiType, GenericCard, FormikAutoComplete } from '@components/common';
import countries from '@assets/data/countries.json';
import { OrganizationStatus } from '@models/organization';
import { FormikPhoneField } from '@components/common/FormikPhoneField';

interface OrganizationFormLayoutProps {
	organizationData?: Organization;
	formik: FormikApiType;
	sx?: SxProps;
	editing?: boolean;
}

interface OrganizationSaveFieldsProps {
	formik: FormikApiType;
	editing: boolean;
	containerSx?: SxProps;
	saveButtonText?: string;
	disabled?: boolean;
}

const fullWidth = { width: 1 };

const countryList = countries.map(({ label }) => ({ label, value: label }));

export const OrganizationFormLayout: FC<OrganizationFormLayoutProps> = ({ organizationData, formik, sx, editing = false }) => {
	const { t } = useTranslation('admin');

	useEffect(() => {
		if (editing) {
			formik.validateForm();
		}
	}, [organizationData]);

	const fieldsDisabled = organizationData && organizationData.status === OrganizationStatus.INACTIVE;

	return (
		<GenericCard sx={sx}>
			<Grid container spacing={formFieldSpacer}>
				<Grid item xs={4}>
					<FormikTextfield
						formikApi={formik}
						name="name"
						disabled={fieldsDisabled}
						title={t('organization.organization-name')}
						placeholder={t('organization.organization')}
						variant="outlined"
						containerProps={fullWidth}
						inputProps={{ 'data-testid': 'form-name-id' }}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<FormikTextfield
						formikApi={formik}
						name="street"
						disabled={fieldsDisabled}
						title={t('organization.address')}
						placeholder={t('organization.address-street')}
						variant="outlined"
						inputProps={{ 'data-testid': 'form-address-street-id' }}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<FormikTextfield
						formikApi={formik}
						name="adminEmail"
						disabled={fieldsDisabled || editing}
						title={t('organization.org-admin-email')}
						placeholder={t('organization.email')}
						variant="outlined"
						containerProps={fullWidth}
						inputProps={{ 'data-testid': 'form-admin-email-id' }}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Grid container spacing={3}>
						{editing ? (
							<Grid item xs={6}>
								<FormikTextfield
									formikApi={formik}
									name="chemisTwinAccountId"
									disabled={true}
									title={t('organization.chemistwin-account-id')}
									variant="outlined"
									inputProps={{ 'data-testid': 'form-chemistwinAccount-id' }}
								/>
							</Grid>
						) : null}

						<Grid item xs={editing ? 6 : 12}>
							<FormikTextfield
								formikApi={formik}
								name="sapCustomerId"
								disabled={fieldsDisabled}
								title={t('organization.sap-customer-id')}
								placeholder={t('organization.sap-customer-id-placeholder')}
								variant="outlined"
								inputProps={{ 'data-testid': 'form-customerId-id' }}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={4}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<FormikTextfield
								formikApi={formik}
								name="number"
								disabled={fieldsDisabled}
								title={t('organization.number')}
								placeholder={t('organization.number')}
								variant="outlined"
								inputProps={{ 'data-testid': 'form-address-number-id' }}
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<FormikTextfield
								formikApi={formik}
								name="zipCode"
								disabled={fieldsDisabled}
								title={t('organization.zip-code')}
								placeholder={t('organization.zip-code')}
								variant="outlined"
								inputProps={{ 'data-testid': 'form-zip-code-id' }}
								required
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={4}>
					<FormikTextfield
						formikApi={formik}
						name="mainContactPerson"
						disabled={fieldsDisabled}
						title={t('organization.main-contact-person')}
						placeholder={t('organization.first-and-last-name')}
						variant="outlined"
						containerProps={fullWidth}
						inputProps={{ 'data-testid': 'form-first-and-last-name-id' }}
						required
					/>
				</Grid>

				<Grid item xs={4}>
					<FormikTextfield
						formikApi={formik}
						name="legalName"
						disabled={fieldsDisabled}
						title={t('organization.organization-legal-name')}
						placeholder={t('organization.organization-legal-name-placeholder')}
						variant="outlined"
						inputProps={{ 'data-testid': 'form-legal-name-id' }}
						required
						containerProps={fullWidth}
					/>
				</Grid>

				<Grid item xs={4}>
					<FormikTextfield
						formikApi={formik}
						name="city"
						required
						disabled={fieldsDisabled}
						title={t('organization.city')}
						placeholder={t('organization.city')}
						variant="outlined"
						containerProps={fullWidth}
						inputProps={{ 'data-testid': 'form-city-id' }}
					/>
				</Grid>
				<Grid item xs={4}>
					<FormikTextfield
						formikApi={formik}
						name="email"
						disabled={fieldsDisabled}
						title={t('organization.main-contact-email')}
						placeholder={t('organization.email')}
						variant="outlined"
						containerProps={fullWidth}
						inputProps={{ 'data-testid': 'form-main-contact-email-id' }}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<FormikPhoneField formik={formik} title={t('organization.phone')} disabled={fieldsDisabled} />
				</Grid>
				<Grid item xs={4}>
					<FormikAutoComplete
						formik={formik}
						value={formik.values.country ? { label: formik.values.country, value: formik.values.country } : null}
						onSelectionChange={(data: unknown) => {
							formik.setFieldValue('country', (data as (typeof countryList)[0])?.label || '');
						}}
						autocompleteProps={{
							isOptionEqualToValue: (opt, val) => val.value === opt.value,
						}}
						select={false}
						title={t('organization.country')}
						placeholder={t('organization.select-country')}
						variant="outlined"
						name="country"
						required
						options={countryList}
						disabled={fieldsDisabled}
					/>
				</Grid>
			</Grid>
		</GenericCard>
	);
};

export const OrganizationSaveFields: FC<OrganizationSaveFieldsProps> = ({ formik, editing, containerSx, saveButtonText, disabled }) => {
	const navigate = useNavigate();
	const { t } = useTranslation('admin');

	const onSaveClick = () => {
		if (formik.dirty) {
			if (editing) {
				alertService.send({
					titleText: <Tr.Admin path="organization.warning" />,
					onConfirm: () => formik.submitForm(),
					onClose: () => navigate(-1),
					content: <Tr.Admin path="organization.cancel-message" />,
					confirmTextComponent: <Tr.Admin path="organization.save-changes" />,
					closeTextComponent: <Tr.Admin path="organization.cancel" />,
				});
			} else {
				alertService.send({
					confirmTextHeader: t('organization.discard-changes') + '?',
					titleText: <Tr.Admin path="substance-management.confirmation" />,
					onClose: () => navigate(-1),
					content: <Tr.Admin path="organization.discard-changes-message" />,
					confirmTextComponent: <Tr.Admin path="organization.save-changes" />,
					closeTextComponent: <Tr.Admin path="organization.discard-changes" />,
				});
			}
		} else navigate(-1);
	};
	return (
		<Stack direction="row" columnGap={2} margin="auto 0 0 auto" sx={containerSx}>
			<Button variant="outlined" data-testid="close-button-test-id" size="medium" sx={{ paddingX: 2 }} onClick={onSaveClick}>
				<Tr.Admin path="organization.cancel" />
			</Button>

			<Button
				data-testid="save-organization-button-id"
				type="submit"
				variant="contained"
				size="medium"
				sx={{ color: 'white' }}
				disabled={disabled !== undefined ? disabled : !formik.isValid || !formik.dirty}
			>
				{saveButtonText ?? <Tr.Admin path={editing ? 'organization.save-changes' : 'organization.save'} />}
			</Button>
		</Stack>
	);
};
