import { FileValidationStatus } from '@models';
import { fromJCAMP } from 'nmr-parser';
import { FileUtils } from './FileUtils';

const MAX_LENGTH = 256;
const INVALID_DATA_TYPE_NMR = 'infrared spectrum';
const INVALID_DATA_TYPE_IR = 'nmr spectrum';

export const nameLengthValidator = (file: File) => {
	if (file?.name?.length > MAX_LENGTH) {
		return {
			code: FileValidationStatus.NAME_LARGE,
			message: `-`,
		};
	}

	return null;
};

export const irDataTypeValidator = async (file: File) => {
	const fileContent = await file.text();
	const metadata = FileUtils.parseContent(file, fileContent);
	const dataType = metadata?.['DATATYPE'] as string;
	const dataTypeWithSpace = metadata?.['DATA TYPE'] as string;

	if (dataType?.toLowerCase() === INVALID_DATA_TYPE_IR || dataTypeWithSpace?.toLowerCase() === INVALID_DATA_TYPE_IR) {
		return {
			code: FileValidationStatus.INVALID_DATA_TYPE,
			message: `-`,
		};
	}
	return null;
};

export const irFileValidator = async (file: File) => {
	const isNameValid = nameLengthValidator(file);
	if (isNameValid) {
		return isNameValid;
	}
	const isDataTypeValid = await irDataTypeValidator(file);
	if (isDataTypeValid) {
		return isDataTypeValid;
	}
	return null;
};

export const nmrDataTypeValidator = async (file: File) => {
	const fileContent = await file.text();
	const metadata = FileUtils.parseContent(file, fileContent);
	const dataType = metadata?.['DATATYPE'] as string;
	const dataTypeWithSpace = metadata?.['DATA TYPE'] as string;

	if (dataType?.toLowerCase() === INVALID_DATA_TYPE_NMR || dataTypeWithSpace?.toLowerCase() === INVALID_DATA_TYPE_NMR) {
		return {
			code: FileValidationStatus.INVALID_DATA_TYPE,
			message: `-`,
		};
	}
	return null;
};

export const nmrFileValidator = async (file: File) => {
	const isNameValid = nameLengthValidator(file);
	if (isNameValid) {
		return isNameValid;
	}
	const isDataTypeValid = await nmrDataTypeValidator(file);
	if (isDataTypeValid) {
		return isDataTypeValid;
	}
	return null;
};

export const checkFileIsValid = (fileContent: string): boolean => {
	try {
		fromJCAMP(fileContent);
		return true;
	} catch (e) {
		return false;
	}
};
