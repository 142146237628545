import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { Stack, TextField, TextFieldProps, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DataTestId } from '@utils/DataTestId';
import { Tr } from '@utils/Translation';

const ALLOWED_NEGATIVE_DIGITS = 17;
const ALLOWED_POSITIVE_DIGITS = 18;
export const SolventSampleWeightInput: FC<
	Omit<TextFieldProps, 'variant'> & { valueChange: (val: number) => void; info?: string; prefix: string }
> = ({ title, valueChange, placeholder, inputProps, info, prefix, ...props }) => {
	const getTitleColor = () => {
		if (props.disabled) {
			return 'grey.200';
		}
		return 'grey.800';
	};
	const isAllowed = (values) => {
		const { value } = values;
		if (value && value.length > 0) {
			return value?.indexOf('.') === -1 ? value.length <= ALLOWED_NEGATIVE_DIGITS : value.length <= ALLOWED_POSITIVE_DIGITS;
		}
		return true;
	};
	return (
		<Stack direction="column">
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 1.25 }}>
				<Typography variant="label-s" color={getTitleColor()}>
					{title}
				</Typography>
				{info && (
					<Tooltip title={<Tr.Portal path={info}></Tr.Portal>}>
						<InfoOutlinedIcon sx={{ height: '16.67px', color: 'grey.800' }} />
					</Tooltip>
				)}
			</Stack>

			<NumericFormat
				customInput={TextField}
				decimalSeparator="."
				decimalScale={2}
				data-testid={DataTestId.getStaticTestId('solvent-sample-weight-input-id')}
				isAllowed={(values) => isAllowed(values)}
				sx={{
					backgroundColor: 'primary.100',
					...props.sx,
				}}
				inputProps={inputProps}
				onValueChange={(value) => valueChange(value.floatValue as number)}
				value={props.value as number}
				placeholder={placeholder}
				suffix={prefix}
				disabled={props.disabled}
				allowNegative={false}
			/>
		</Stack>
	);
};
