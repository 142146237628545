import { FC, useMemo } from 'react';
import { Column } from 'react-table';
import { DataTable } from '@components/common';
import { CommentTypeEnum, NmrAnalysisResult, NmrResultAtoms } from '@services';
import { fixedWith2, mapNumberAndFixWidth2, Tr } from '@utils';
import { Cell, CellTooltip, HeaderCell } from './QualitativeHelperComponents';

interface IQualitativeProps {
	resultData: NmrAnalysisResult;
	selectedErmIndex: number;
	sampleName?: string;
}

export const QualitativeTableOfAssignment: FC<IQualitativeProps> = ({ resultData, sampleName, selectedErmIndex }) => {
	const { resultMatches: results } = resultData;

	const columns = useMemo<Column<NmrResultAtoms>[]>(() => {
		return [
			{
				accessor: 'atomNumber',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.atom" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value, row }) => {
					return (
						<CellTooltip comments={row.original.comments} targetCommentType={CommentTypeEnum.SIGNAL}>
							<Cell
								sx={{
									color: 'primary.main',
									whiteSpace: 'nowrap',
									textAlign: 'center',
									backgroundColor: 'transparent',
								}}
							>
								{value}
							</Cell>
						</CellTooltip>
					);
				},
			},
			{
				accessor: 'sampleChemicalGroup',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.chemical-group" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell sx={{ whiteSpace: 'nowrap' }}>{value || '-'}</Cell>,
			},
			{
				accessor: 'sampleHShift',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.chem-shift" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value, row }) => {
					return (
						<CellTooltip comments={row.original.comments} targetCommentType={CommentTypeEnum.CHEM_SHIFT}>
							<Cell
								sx={{
									whiteSpace: 'nowrap',
									backgroundColor: 'transparent',
								}}
							>
								{mapNumberAndFixWidth2(value)}
							</Cell>
						</CellTooltip>
					);
				},
			},
			{
				accessor: 'sampleHMultiplicity',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.multiplicity" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => (
					<Cell>
						{value || '-'} {value?.includes('sc') ? '*' : ''}
					</Cell>
				),
			},
			{
				accessor: 'sampleCouplingConstant',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.coupling-constant" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell>{value ? mapNumberAndFixWidth2(`${value}`) : '-'}</Cell>,
			},
			{
				accessor: 'sampleNumberOfH',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.number-of-h" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell sx={{ backgroundColor: 'transparent' }}>{value}</Cell>,
			},
			{
				accessor: 'sampleHVolume',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.relative-integral" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<CellTooltip comments={row.original.comments} targetCommentType={CommentTypeEnum.INTEGRAL}>
						<Cell sx={{ backgroundColor: 'transparent' }}>{fixedWith2(value)}</Cell>
					</CellTooltip>
				),
			}, // erm values,
			{
				accessor: 'rmChemicalGroup',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.chemical-group" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell sx={{ whiteSpace: 'nowrap' }}>{value || '-'}</Cell>,
			},
			{
				accessor: 'rmChemShift',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.chem-shift" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<CellTooltip comments={row.original.comments} targetCommentType={CommentTypeEnum.CHEM_SHIFT}>
						<Cell
							sx={{
								whiteSpace: 'nowrap',
								backgroundColor: 'transparent',
							}}
						>
							{mapNumberAndFixWidth2(value)}
						</Cell>
					</CellTooltip>
				),
			},
			{
				accessor: 'rmHMultiplicity',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.multiplicity" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => {
					return (
						<Cell>
							{value || ''} {((value as string) || '').includes('sc') ? '*' : ''}
						</Cell>
					);
				},
			},
			{
				accessor: 'rmCouplingConstant',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.coupling-constant" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell>{mapNumberAndFixWidth2(value)}</Cell>,
			},
			{
				accessor: 'rmNumberOfH',
				Header: (
					<HeaderCell>
						<Tr.Portal path="analysis-result.number-of-h" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell>{value || '-'}</Cell>,
			},
		];
	}, [selectedErmIndex]);

	const selectedDrm = useMemo(() => results[`${selectedErmIndex}`], [results, selectedErmIndex]);
	return selectedDrm?.atoms ? (
		<DataTable
			mainHeader={[
				{ label: '', colspan: 1 },
				{ label: sampleName, colspan: 6 },
				{ label: selectedDrm.substanceName, colspan: 6 },
			]}
			columns={columns}
			data={selectedDrm?.atoms || []}
			sx={{
				'& 	.MuiTableCell-root': {
					borderRight: '1px solid',
					borderColor: 'grey.200',
					padding: 0,
					'&:last-child': {
						borderRight: 'none',
					},
				},
				'& 	.MuiTableCell-head': {
					padding: 1.2,
				},
				'& .MuiTableBody-root 	.MuiTableRow-root': {
					'&:last-child': {
						'& .MuiTableCell-root': {
							borderBottom: 'none',
						},
					},
					'& .MuiTableCell-root': {
						padding: '0',
					},
					height: '2.5rem',
				},
			}}
		/>
	) : (
		<></>
	);
};
