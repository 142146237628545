import { OrganizationNotification } from '@components/common';
import { IRoute, PageType } from '@models';
import { Grid, Stack, Typography } from '@mui/material';
import { ROUTES } from '@routes';
import { pageBackgroundSxSelector, subscriptionNotificationsSelector, userSelector } from '@store/slices/common/common.slice';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';
import { ExtendedComponent } from 'src/types';
import { Breadcrumb } from '../Breadcrumb';
import { Footer } from '../Footer';
import { LeftSidebar } from '../LeftSidebar';
import { Topbar } from '../Topbar';

export const DashboardLayout: ExtendedComponent = ({ children }) => {
	const loc = useLocation();
	const user = useSelector(userSelector);
	const [title, setTitle] = useState<ReactNode>('');
	const [pageType, setPageType] = useState<PageType>(PageType.STANDARD);
	const [hasFooter, setHasFooter] = useState<boolean>(false);
	const [backgroundEnabled, setBackgroundEnabled] = useState<boolean>(false);
	const currentNotifications = useSelector(subscriptionNotificationsSelector);
	const pageBackgroundSx = useSelector(pageBackgroundSxSelector);

	useEffect(() => {
		const matches = matchRoutes(ROUTES, loc);
		const route = matches?.slice(-1)?.[0].route as IRoute;
		setTitle(route.hideTitleInPage ? null : route.text);
		setPageType(route.pageType || PageType.STANDARD);
		setHasFooter(route.hasFooter || false);
		setBackgroundEnabled(route.backgroundEnabled || false);
	}, [loc]);

	const pageBackground = backgroundEnabled ? pageBackgroundSx : {};

	return (
		<Stack
			data-testid="dashboard-portal-layout-id"
			id="portal-layout"
			direction="column"
			flexGrow={1}
			sx={{ backgroundColor: 'background.default', maxHeight: 1.0 }}
		>
			{currentNotifications &&
				currentNotifications.map((notification) => <OrganizationNotification key={notification.id} notification={notification} />)}
			<Topbar />
			<Grid container sx={{ height: 1, maxWidth: 1.0, overflow: 'hidden', position: 'relative' }} flexWrap="nowrap">
				<Grid item>
					<LeftSidebar />
				</Grid>
				<Grid
					item
					xs
					sx={{
						maxWidth: 1.0,
						overflowX: 'hidden',
						overflowY: 'auto',
					}}
					id="layout-grid"
				>
					<Breadcrumb />
					<Stack
						direction="column"
						sx={{
							width: 1,
							height: 1,
							...pageBackground,
						}}
						key={user?.currentLabId ?? 'key' + user?.currentLabId}
					>
						<Stack
							direction="column"
							flexGrow={1}
							sx={{
								pt: pageType !== PageType.FULLSCREEN ? 4.5 : 0,
								pb: pageType !== PageType.FULLSCREEN ? 10 : 0,
								px: pageType !== PageType.FULLSCREEN ? 8 : 0,
							}}
						>
							{title ? (
								<Typography variant="h1" sx={{ color: 'text.secondary' }}>
									{title}
								</Typography>
							) : null}
							{(!loc.pathname.includes('/portal') || user?.currentLabId) && children}
						</Stack>
						{hasFooter && <Footer />}
					</Stack>
				</Grid>
			</Grid>
		</Stack>
	);
};
