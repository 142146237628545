import { OverflowText } from '@components/common/OverflowText';
import { ColorTransition } from '@components/common/Transitions';
import { IFilterValues } from '@models/filter';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Checkbox, Chip, Stack, TextField, Typography } from '@mui/material';
import { Scroll } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useEffect, useState } from 'react';
import { ExtendedComponent } from 'src/types';
type TSearchableDropdown = {
	options: IFilterValues[];
	onChange: (_, value: string[]) => void;
	selectedValues: string[];
	loading?: boolean;
	disabled?: boolean;
	elementTitle?: string;
	filterOption?: boolean;
	placeholder?: string;
};

const TAG_LIMIT = 2;
const chipIcon = <ClearIcon sx={{ fill: 'white', width: 16, height: 16 }} />;
const clearIndicatorProps = {
	sx: {
		padding: 0,
		width: 14,
		height: 14,
		mr: 0.5,
	},
};
const clearIcon = (
	<Stack
		sx={{
			backgroundColor: 'primary.main',
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			alignItems: 'center',
			justifyContent: 'center',
		}}
	>
		<ClearIcon sx={{ color: 'white', width: 12, height: 12 }} />
	</Stack>
);
export const SearchableDropdown: ExtendedComponent<TSearchableDropdown> = ({
	onChange,
	selectedValues,
	disabled,
	loading,
	options,
	filterOption,
	elementTitle,
	placeholder,
}) => {
	const [optionsLoading, setOptionsLoading] = useState<boolean>();
	useEffect(() => {
		setOptionsLoading(loading);
	}, [loading]);
	useEffect(() => {
		const isSelectedValueValid = selectedValues.every((value) => options.some((o) => o.id === value));
		if (!isSelectedValueValid && !loading) {
			onChange(undefined, []);
		}
	}, [options, loading]);

	useEffect(() => {
		if (disabled) {
			onChange(undefined, []);
		}
	}, [disabled]);

	const textFieldPlaceholder = placeholder ? placeholder : 'Any';
	return (
		<>
			{elementTitle && (
				<ColorTransition>
					<Typography variant="label-s" color={disabled ? 'grey.200' : 'grey.800'}>
						{filterOption ? <Tr.Filter path={`filter.${elementTitle}`} /> : elementTitle}
					</Typography>
				</ColorTransition>
			)}

			<Autocomplete
				multiple
				disableCloseOnSelect
				options={options}
				loading={optionsLoading}
				disabled={disabled}
				popupIcon={<KeyboardArrowDown sx={{ color: `${disabled ? '#E1E1EA' : '#01884C'} !important` }} />}
				onChange={(_, val) =>
					onChange(
						_,
						val.map((v) => v.id),
					)
				}
				value={options.filter((o) => selectedValues.includes(o.id))}
				getOptionLabel={(option) => option.value}
				ListboxProps={{
					sx: {
						...Scroll.Y,
					},
				}}
				renderTags={(value: readonly IFilterValues[], getTagProps) => (
					<>
						{value.slice(0, TAG_LIMIT).map((option: IFilterValues, index: number) => {
							const { key, ...tagProps } = getTagProps({ index });
							return (
								<Chip
									variant="outlined"
									key={key}
									{...tagProps}
									sx={{ maxWidth: '30% !important', height: 28, border: '0px', backgroundColor: 'primary.main' }}
									deleteIcon={chipIcon}
									label={
										<OverflowText variant="label-s" color="white" enableTooltip>
											{option.value || '-'}
										</OverflowText>
									}
								/>
							);
						})}
						<Typography variant="pg-m">{value.length > 2 ? `+${value.length - 2}` : null}</Typography>
					</>
				)}
				clearIcon={clearIcon}
				slotProps={{
					clearIndicator: clearIndicatorProps,
				}}
				renderOption={(props, option, { selected }) => (
					<li {...props} style={{ height: '40px', paddingLeft: 0 }}>
						<Checkbox style={{ marginRight: 0 }} checked={selected} />
						{option.value}
					</li>
				)}
				sx={{
					marginBottom: '1rem',
					height: '40px',
					mt: '0.6rem',
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						rows={1}
						multiline={false}
						maxRows={1}
						sx={{
							whiteSpace: 'none',
							overflow: 'hidden',
						}}
						variant="outlined"
						inputProps={{
							sx: {
								'&::placeholder': {
									WebkitTextFillColor: '#5e5e72',
									fontSize: 16,
									fontWeight: 400,
									lineHeight: '140%',
								},
							},
							...params.inputProps,
						}}
						placeholder={selectedValues.length > 0 ? '' : textFieldPlaceholder}
						hiddenLabel
					/>
				)}
			></Autocomplete>
		</>
	);
};
