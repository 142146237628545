import {
	AddUserLibraryModal,
	Body1,
	DataTableBackend,
	DefaultLibraryInfo,
	DeleteButton,
	EditButton,
	useDefaultLibrary,
} from '@components/common';
import { TableButton } from '@components/common/TableButton';
import { EditIrLibraryModal } from '@components/ir-portal';
import { getParams, useFilterSearchParams, useService } from '@hooks';
import { PortalPageRoutes } from '@models/router';
import { SortOrder } from '@models/sorting';
import { ExtendedColumn } from '@models/table';
import Add from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import { alertService, notificationService } from '@services/core';
import { irFilterService, IrLibrary, irLibraryService } from '@services/ir';
import { RxUtils } from '@utils';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type TDeleteUserLibrary = {
	id: number;
	onSuccess: () => void;
};

const DeleteUserLibrary: FC<TDeleteUserLibrary> = ({ id, onSuccess, ...props }) => {
	const { t } = useTranslation('irportal');
	const sendDeleteRequest = () => {
		RxUtils.promisify(irLibraryService.delete(id), () => {
			notificationService.sendSuccess(t('user-references.delete-library-success-message'));
			onSuccess();
		});
	};
	const sendDeleteAlert = () => {
		alertService.send({
			confirmTextHeader: <Tr.IrPortal path="user-references.remove-library" />,
			titleText: <Tr.IrPortal path={'user-references.remove'} />,
			confirmTextComponent: <Tr.IrPortal path={'user-references.remove'} />,
			closeTextComponent: <Tr.IrPortal path="user-references.cancel" />,
			onConfirm: () => sendDeleteRequest(),
			content: <Tr.IrPortal path="user-references.remove-library-explanation" />,
		});
	};
	return <DeleteButton onClick={sendDeleteAlert} {...props} />;
};

export const IrUserLibrary = () => {
	const { searchParams, setFiltering, filtering } = useFilterSearchParams();
	const { t } = useTranslation('irportal');
	const navigate = useNavigate();

	const [addUserLibraryVisible, setAddUserLibraryVisible] = useState(false);
	const [editLibrary, setEditLibrary] = useState<IrLibrary>();

	const { data: userLibraries, trigger: triggerUserLibraries } = useService(() => {
		searchParams.set('pageSize', '15');
		if (!searchParams.get('pageIndex')) {
			searchParams.set('pageIndex', '1');
		}
		searchParams.set('IsUserLibrary', 'true');
		return irLibraryService.get(searchParams);
	}, [searchParams]);

	const { data: filterData } = useService(() => irFilterService.getIrLibrariesFilterOptions(), []);

	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');

	useDefaultLibrary(userLibraries?.data ?? []);

	const onLinkClick = (id: number) => {
		navigate(`../${PortalPageRoutes.REFERENCES}?libraries=${id}`);
	};

	const columns: ExtendedColumn<IrLibrary>[] = [
		{
			accessor: 'libraryName',
			disableSortBy: true,
			Header: (
				<Stack ml={1}>
					<Tr.IrPortal path="user-references.library" />
				</Stack>
			),
			Cell: ({ value, row }) => (
				<Stack ml={1}>
					<Typography
						variant="pg-s"
						sx={{
							color: 'primary.main',
							cursor: 'pointer',
							'&:hover': {
								textDecoration: 'underline',
							},
						}}
						onClick={() => onLinkClick(row.original.libraryId)}
					>
						{value || '-'}
					</Typography>
				</Stack>
			),
		},
		{
			accessor: 'count',
			Header: <Tr.IrPortal path="user-references.number-of-references" />,
			Cell: ({ value }) => <Body1 variant="pg-s">{value || '-'}</Body1>,
		},
		{
			accessor: 'createTime',
			Header: <Tr.IrPortal path="user-references.creation-date" />,
			Cell: ({ value }) => <Body1>{value ? DateUtils.getFormattedDate(value) : '-'}</Body1>,
		},
		{
			accessor: 'libraryId',
			Header: <></>,
			disableSortBy: true,
			Cell: ({ row }) => (
				<Stack direction="row-reverse" alignItems="center" spacing={4} mr={1}>
					{row.original.isDefault ? (
						<DefaultLibraryInfo />
					) : (
						<>
							<DeleteUserLibrary
								id={row.original.libraryId}
								onSuccess={() => triggerUserLibraries()}
								data-testid={`delete-library-${row.index}-id`}
							/>
							<EditButton onClick={() => setEditLibrary(row.original)} data-testid={`edit-library-${row.index}-id`} />
						</>
					)}
				</Stack>
			),
		},
	];
	return (
		<>
			{editLibrary && (
				<EditIrLibraryModal
					onClose={() => setEditLibrary(undefined)}
					library={editLibrary}
					onSuccess={() => (
						triggerUserLibraries(),
						setEditLibrary(undefined),
						notificationService.sendSuccess(t('user-references.edit-success-notification'))
					)}
				/>
			)}
			{addUserLibraryVisible && (
				<AddUserLibraryModal
					onClose={() => setAddUserLibraryVisible(false)}
					visible={addUserLibraryVisible}
					onSubmit={(data) => {
						RxUtils.promisify(irLibraryService.addLibrary(data.libraryName), () => {
							notificationService.sendSuccess(t('add-user-library.success-notification'));
							triggerUserLibraries();
							setAddUserLibraryVisible(false);
						});
					}}
				/>
			)}

			<Stack>
				<Stack direction="row" justifyContent="space-between" mb={1.5}>
					<Typography variant="h2" color="primary.main">
						<Tr.IrPortal path="user-references.all-libraries" />
					</Typography>
					<TableButton.Text
						data-testid="add-user-library"
						sx={{ alignItems: 'center', height: '2.5rem' }}
						onClick={() => setAddUserLibraryVisible(true)}
					>
						{<Add sx={{ fontSize: '20px' }} />}
						<Typography variant="subtitle2" sx={{ paddingBottom: 0.2, paddingLeft: 0.5 }} color="primary.main">
							<Tr.IrPortal path="add-user-library.add-library" />
						</Typography>
					</TableButton.Text>
				</Stack>
				<DataTableBackend
					data={userLibraries?.data ?? []}
					columns={columns}
					sorting={{
						sortBy: getParams(searchParams).getSortBy(),
						sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
					}}
					pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: userLibraries?.totalPages ?? 1 }}
					searchText={searchTextfieldValue}
					searchPlaceholder={t('user-references.library-search-placeholder')}
					onSearchTextChange={setSearchTextfieldValue}
					searchFieldProps={{
						sx: {
							width: (400 / 1440) * window.innerWidth,
						},
					}}
					filterData={filterData?.options}
					onFilter={setFiltering}
					filteringSelections={filtering}
					sx={{
						'& .MuiTableRow-root': {
							transition: 'all 100ms ease-in',
						},
					}}
				/>
			</Stack>
		</>
	);
};
