import React, { useState } from 'react';
import { Card, Chip, ChipProps, Divider, IconButton, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { UserCount } from '../UserCount';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useTranslation } from 'react-i18next';
import { SubstanceTechnology } from '@components/admin';
import { ExtendedComponent } from 'src/types';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { OverflowText } from '../OverflowText';
import { ReferenceTechnique } from '@services/core/substance/technique.enum';
import { QualityGradeChip } from '../QualityGradeChip';
import { QualityGrade } from '@models/reference-material';
export interface ListCardProps {
	title: string;
	onClick?: () => void;
	qualityGrade?: QualityGrade;
	isActive: boolean;
	isLocked?: boolean;
	userCount?: number;
	technique?: ReferenceTechnique;
	sx?: SxProps;
	chipText?: string | JSX.Element;
	chipProps?: ChipProps;
	lockedText?: string;
}

export const ListCard: ExtendedComponent<ListCardProps> = ({
	title,
	onClick,
	isActive,
	userCount,
	children,
	technique,
	sx,
	chipText,
	chipProps,
	isLocked = false,
	lockedText,
	qualityGrade,
	...props
}) => {
	const { t } = useTranslation('common');
	const [arrowVisible, setArrowVisible] = useState(false);

	const { sx: chipSx, ...chipPropsRest } = chipProps ?? {};

	const onCardClick = () => {
		if (!isLocked) {
			onClick?.();
		}
	};

	const LockedTextWrapper: ExtendedComponent = ({ children: lockedTextChildren }) => {
		if (isLocked) {
			return (
				<Tooltip title={lockedText} placement="top">
					<span>{lockedTextChildren}</span>
				</Tooltip>
			);
		}
		return lockedTextChildren;
	};
	return (
		<LockedTextWrapper>
			<span>
				<Card
					sx={{
						padding: 3,
						height: 224,
						width: 400,
						justifyContent: 'space-between',
						boxShadow: '0px 1px 4px 0px rgba(15, 26, 46, 0.15),0px 0px 1px 0px rgba(15, 26, 46, 0.15)',
						'&:hover': !isLocked
							? {
									cursor: 'pointer',
									boxShadow: '0px 0px 4px 0px rgba(15, 26, 46, 0.15), 0px 16px 32px 0px rgba(15, 26, 46, 0.15)',
								}
							: {},
						pointerEvents: isLocked ? 'none' : undefined,
						...sx,
					}}
					onClick={onCardClick}
					onMouseEnter={() => !isLocked && setArrowVisible(true)}
					onMouseLeave={() => setArrowVisible(false)}
					{...props}
				>
					<Stack flexDirection="column" justifyContent="space-between" height={1}>
						<Stack spacing={1.5}>
							<Stack flexDirection="row" justifyContent="space-between" width="100%">
								<Stack direction="row" alignItems="center" overflow="hidden">
									{isLocked && <LockOutlined sx={{ height: 20, width: 20, color: 'grey.500', mr: 1 }} />}

									<Stack pr={1.5} width="100%">
										<OverflowText
											variant="h4"
											color={isActive ? 'text.primary' : 'grey.500'}
											width={'100%'}
											enableTooltip
										>
											{title || '-'}
										</OverflowText>
									</Stack>
								</Stack>

								<Stack direction="column">
									<Chip
										sx={{
											borderRadius: '4px',
											padding: '6px',
											height: '24px',
											backgroundColor: isActive ? 'primary.main' : 'grey.100',
											' .MuiChip-label': { padding: 0 },
											color: isActive ? 'white' : 'grey.500',
											...chipSx,
										}}
										label={
											<Typography variant="subtitle3" sx={{ padding: 0, lineHeight: '85%' }}>
												{chipText || (isActive ? t('active') : t('inactive'))}
											</Typography>
										}
										{...chipPropsRest}
									/>
								</Stack>
							</Stack>
							<Divider sx={{ height: '1px' }} />
						</Stack>
						{children}
						<Stack direction="row" justifyContent="space-between" alignItems="center">
							<Stack direction="row">
								{qualityGrade && (
									<div style={{ paddingRight: 10, marginTop: -2 }}>
										{<QualityGradeChip isReferenceActive={isActive} qualityGrade={qualityGrade} />}
									</div>
								)}
								{technique === ReferenceTechnique.IR_REFERENCE_MATERIAL && (
									<SubstanceTechnology.IrReferenceMaterial disabled={!isActive} qualityGrade={qualityGrade} />
								)}
								{technique === ReferenceTechnique.NMR_REFERENCE_MATERIAL && (
									<SubstanceTechnology.NmrReferenceMaterial disabled={!isActive} qualityGrade={qualityGrade} />
								)}
								{technique === ReferenceTechnique.NMR_THEORETICAL_SPECTRUM && (
									<SubstanceTechnology.NmrTheoreticalSpectrum disabled={!isActive} qualityGrade={qualityGrade} />
								)}

								{userCount !== undefined && <UserCount value={userCount} />}
							</Stack>
							{typeof onClick === 'function' && !isLocked && (
								<IconButton>
									<ArrowForwardOutlinedIcon
										sx={{ color: 'primary.main', visibility: arrowVisible ? 'visible' : 'hidden' }}
									/>
								</IconButton>
							)}
						</Stack>
					</Stack>
				</Card>
			</span>
		</LockedTextWrapper>
	);
};
