import { FC } from 'react';
import { FormikApiType, FormikAutoComplete } from '@components/common';
import { SxProps, TextFieldProps, TypographyProps } from '@mui/material';
import { OrganizationManagementModel } from '@services/core';

type OrganizationListType = { label?: string; id?: number };

interface IOrganizationListProps {
	name: string;
	title: string;
	formik: FormikApiType;
	typographyProps?: TypographyProps;
	autoCompleteSx?: SxProps;
	onOrganizationChange: (data: OrganizationListType) => void;
	organizationList?: OrganizationManagementModel[];
}

export const OrganizationList: FC<IOrganizationListProps & TextFieldProps> = ({
	title,
	required,
	name,
	formik,
	typographyProps,
	onOrganizationChange,
	autoCompleteSx,
	organizationList = [],
	...params
}) => {
	return (
		<FormikAutoComplete
			formik={formik}
			name={name}
			autoCompleteSx={autoCompleteSx}
			onSelectionChange={(newValue) =>
				onOrganizationChange({ id: (newValue as OrganizationListType)?.id, label: (newValue as OrganizationListType)?.label })
			}
			title={title}
			required={required}
			options={
				organizationList?.map((organization) => {
					return { id: organization.id, label: organization.name };
				}) || []
			}
			typographyProps={typographyProps}
			autocompleteProps={{
				isOptionEqualToValue: (option, value) => option.id === value,
			}}
			{...params}
		/>
	);
};
