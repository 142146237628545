import { Button, Grid, Stack, StackProps, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { ModalDialog } from '../../ModalDialog';
import { FormikTextfield } from '../../FormikTextfield';
import { CreateLabModal, organizationService } from '@services/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FC } from 'react';
import { RxUtils } from '@utils/Rx';
import { getLabValidationSchema } from '@schemas';
import { FormikAutoComplete } from '@components/common/FormikAutoComplete';
import { DataTestId } from '@utils/DataTestId';
import { formFieldSpacer } from '@utils/Theme';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

const fullWidth: StackProps = { width: 1 };

type IAddLabFields = Extendable<CreateLabModal>;

interface IAddLabModalDialogProps {
	id: Number;
	labCount?: number;
	isOpen?: boolean;
	onCancel?: () => void;
	onSave?: () => void;
}
export const AddLabModalDialog: FC<IAddLabModalDialogProps> = ({ id, labCount = 0, isOpen = false, onCancel, onSave }) => {
	const { t } = useTranslation('common');
	const { t: tAdmin } = useTranslation('admin'); // Is it best practice
	const formik = useFormik<IAddLabFields>({
		initialValues: {
			name: '',
			technology: '',
			adminEmail: '',
		},
		validationSchema: getLabValidationSchema(tAdmin),
		onSubmit: (fields: IAddLabFields) => {
			if (formik.isValid) {
				RxUtils.promisify(
					organizationService.addLaboratory(
						{
							name: fields.name,
							technology: fields.technology,
							adminEmail: fields.adminEmail,
						},
						id,
					),
					(value) => {
						value && onSave && onSave();
					},
				);
			}
		},
	});

	return (
		<ModalDialog
			variant="secondary"
			open={isOpen}
			title={
				<Typography variant="pg-s">
					<Tr.Common path="organization-details.labs.add-lab" />
				</Typography>
			}
			closeIconClick={onCancel}
			maxWidth={false}
			PaperProps={{
				sx: {
					maxWidth: '472px',
				},
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<Stack
					sx={{
						paddingX: '68px',
						marginY: 4,
					}}
					spacing={4}
				>
					<Stack direction="row" justifyContent="center" textAlign="center">
						<Typography variant="subtitle1">
							<Tr.Common path="organization-details.labs.add-lab" />
							{` (${labCount + 1})`}
						</Typography>
					</Stack>
					<Stack spacing={formFieldSpacer}>
						<FormikTextfield
							formikApi={formik}
							name="name"
							title={t('organization-details.labs.name')}
							placeholder={t('organization-details.labs.name')}
							variant="outlined"
							containerProps={fullWidth}
							inputProps={{ 'data-testid': 'lab-name-test-id' }}
							required
						/>

						<FormikAutoComplete
							options={Object.values(SupportedTechnology)}
							value={formik?.values?.technology || null}
							onSelectionChange={(newValue) => {
								formik.setFieldValue('technology', newValue || '');
							}}
							select={false}
							formik={formik}
							title={t('organization-details.labs.technology')}
							placeholder={t('organization-details.labs.technology-placeholder')}
							variant="outlined"
							name="technology"
							autocompleteProps={{
								disablePortal: true,
							}}
							required
						/>
						<FormikTextfield
							formikApi={formik}
							name="adminEmail"
							title={t('organization-details.labs.lab-admin')}
							placeholder={t('organization-details.labs.email-address')}
							variant="outlined"
							containerProps={fullWidth}
							inputProps={{ 'data-testid': 'lab-email-test-id' }}
						/>
						<Grid container paddingTop={1}>
							<Grid
								item
								xs={6}
								sx={{
									paddingRight: 1,
								}}
							>
								<Button
									variant="outlined"
									fullWidth={true}
									data-testid={DataTestId.getStaticTestId('add-lab-cancel-button-id')}
									disableElevation
									onClick={() => {
										onCancel && onCancel();
									}}
								>
									<Tr.Portal path="cancel" />
								</Button>
							</Grid>

							<Grid
								item
								xs={6}
								sx={{
									paddingLeft: 1,
								}}
							>
								<Button
									fullWidth={true}
									data-testid={DataTestId.getStaticTestId('add-lab-approve-button-id')}
									variant="contained"
									type="submit"
									disableElevation
									disabled={!formik.dirty || !formik.isValid}
									sx={{ textTransform: 'capitalize' }}
								>
									<Tr.Portal path="add" />
								</Button>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
