import { NmrPipelineBulkUpload, NmrPipelineBulkUploadFile } from '@components/admin/NmrPipelineBulkUpload';
import { FileUploader, FormikAutoComplete } from '@components/common';
import { FileStatus, FileValidationStatus, UploadableFileExtensionMapping } from '@models/file';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { getPipelineBulkDrmOrderValidationSchema } from '@schemas';
import { notificationService } from '@services/index';
import { nmrDrmOrderService, NrmDrmOrderPriority } from '@services/nmr-drm-pipeline';
import { pipelineSelector } from '@store/slices/nmr/pipeline.slice';
import { formFieldSpacer } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { TypeUtils } from '@utils/Type';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { validateBulkNmrDrmFiles } from './create-bulk-order-helper';
import { NmrDrmOrderUploadError } from '@models/drm-order/file-errors';
import { BulkUploadSelectWave } from './BulkUploadSelectWave';
import { RxUtils } from '@utils/Rx';
import { BulkUploadError } from './BulkUploadError';
const DropdownTitle: FC<{ title: string }> = ({ title }) => {
	return (
		<Typography className="required" variant="label-s" sx={{ mb: 1.25, color: 'grey.800' }}>
			{title}
		</Typography>
	);
};

const FileUploaderHeader = () => {
	return (
		<>
			<Typography className="required" variant="h4">
				<Tr.Admin path="drm-pipeline.nmr.backlog.upload-files" />
			</Typography>
			<Typography variant="pg-m" color="grey.800">
				<Tr.Admin path="drm-pipeline.nmr.backlog.upload-csv-files" />
			</Typography>
		</>
	);
};

type FormFields = Extendable<{ wave: number; priority: NrmDrmOrderPriority }>;

type TCreateBulkOrder = {
	onClose: () => void;
};

export const CreateBulkOrder: FC<TCreateBulkOrder> = ({ onClose }) => {
	const { waveData } = useSelector(pipelineSelector);
	const { t } = useTranslation('admin');
	const { t: tCommon } = useTranslation('common');
	const [file, setFile] = useState<NmrPipelineBulkUploadFile>();
	const [fileError, setFileError] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [fileReadLoading, setFileReadLoading] = useState<boolean>(false);

	const errorMapping: Record<FileValidationStatus, string> = {
		[FileValidationStatus.INVALID]: t('drm-pipeline.nmr.backlog.file-type-error'),
		[FileValidationStatus.MAX_SIZE]: t('drm-pipeline.nmr.backlog.file-size-error'),
		[FileValidationStatus.NAME_LARGE]: t('drm-pipeline.nmr.backlog.file-length-error'),
		[FileValidationStatus.INVALID_DATA_TYPE]: t('drm-pipeline.nmr.backlog.invalid-data-type'),
	};
	const formik = useFormik<FormFields>({
		initialValues: {
			priority: '',
			wave: '',
		},
		validationSchema: getPipelineBulkDrmOrderValidationSchema(tCommon),
		onSubmit: (values) => {
			setLoading(true);
			if (file && values.priority && values.wave) {
				RxUtils.promisify(
					nmrDrmOrderService.batchUpload(file?.file, values.wave, values.priority),
					() => {
						setLoading(false);
						onClose();
						notificationService.sendSuccess(t('drm-pipeline.nmr.backlog.bulk-upload-started'));
					},
					() => setLoading(false),
				);
			} else {
				setLoading(false);
			}
		},
	});
	const onRemoveFile = () => {
		setFile(undefined);
	};
	const onFileUpload = async (uploadedFiles: unknown[] | undefined) => {
		setFileReadLoading(true);
		if (!uploadedFiles || !uploadedFiles[0] || uploadedFiles.length > 1) {
			notificationService.sendError(t('drm-pipeline.nmr.backlog.file-upload-unsuccessful'));
		} else {
			const targetFile = uploadedFiles[0] as File;
			const fileToUpload: NmrPipelineBulkUploadFile = {
				fileName: targetFile.name,
				file: targetFile,
				fileStatus: FileStatus.VALID,
			};
			try {
				await validateBulkNmrDrmFiles(targetFile);
				setFileError(undefined);
			} catch (e) {
				const convertedError: NmrDrmOrderUploadError = TypeUtils.transform(NmrDrmOrderUploadError, e);
				fileToUpload.fileStatus = FileStatus.INVALID;
				setFileError(t(convertedError.translationMessage, convertedError.translateOptions));
			}
			setFile(fileToUpload);
			setFileReadLoading(false);
		}
	};
	const priorityOptions: Array<string> = Object.values(NrmDrmOrderPriority);
	return (
		<form onSubmit={formik.handleSubmit}>
			<Stack spacing={formFieldSpacer}>
				<Stack spacing={1} position="relative">
					<FileUploaderHeader />
					{!file && (
						<FileUploader
							message={t('drm-pipeline.nmr.backlog.upload-csv-to-continue')}
							accept={UploadableFileExtensionMapping.NmrPipeline}
							onUploadedFileChange={onFileUpload}
							errorMapping={errorMapping}
						/>
					)}

					<Stack>
						{file && (
							<NmrPipelineBulkUpload
								onRemove={onRemoveFile}
								uploadedFile={file}
								onUploadedFileChange={onFileUpload}
								disabled={loading}
								loading={fileReadLoading}
							/>
						)}
						{fileError && <BulkUploadError message={fileError} />}
					</Stack>
				</Stack>

				<Stack>
					<DropdownTitle title={t('drm-pipeline.nmr.backlog.wave')} />
					<BulkUploadSelectWave formik={formik} disabled={!file || !!fileError || loading} waveData={waveData} />
				</Stack>
				<Stack>
					<DropdownTitle title={t('drm-pipeline.nmr.backlog.priority')} />
					<FormikAutoComplete
						required
						select={false}
						formik={formik}
						name="priority"
						disabled={!file || !!fileError || loading}
						variant="outlined"
						options={priorityOptions}
						value={formik.values.priority || null}
						placeholder={t('drm-pipeline.nmr.backlog.select-priority')}
						autocompleteProps={{
							disablePortal: true,
						}}
						onSelectionChange={(value) => {
							formik.setFieldValue('priority', value || null);
						}}
					/>
				</Stack>
				<Stack direction="row" alignSelf="flex-end" spacing={1} width={1}>
					<Button data-testid="add-wave-cancel-id" variant="outlined" fullWidth onClick={onClose}>
						<Tr.Common path="cancel" />
					</Button>
					<Button
						disabled={!formik.dirty || !formik.isValid}
						data-testid="add-wave-submit-id"
						type="submit"
						variant="contained"
						disableElevation
						fullWidth
					>
						{loading ? (
							<Typography color="white">
								<CircularProgress color="inherit" size={20} thickness={4} variant="indeterminate" />
							</Typography>
						) : (
							<Tr.Common path="save" />
						)}
					</Button>
				</Stack>
			</Stack>
		</form>
	);
};
