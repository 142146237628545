import { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, InputAdornment, Stack, SxProps, Typography } from '@mui/material';
import { ResetPassword } from '@components/common';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormikApiType, FormikTextfield, GenericCard, SelectRole, FormikTextFieldProps } from '@components/common';
import { UserRole, UserStatus } from '@models/user';
import { alertService, notificationService, userService } from '@services';
import { mainTitleSpacer, RxUtils, Tr } from '@utils';
import { useFormik } from 'formik';
import { setUser, userSelector } from '@store/slices/common/common.slice';
import { UserUpdateModel } from '@models/user/user-update.model';
import { getEditUserValidationSchema } from '@schemas';

interface UserDetailInputType {
	formik: FormikApiType;
	name: string;
	title: ReactNode;
	readOnly?: boolean;
	isActive?: boolean;
}
const UserDetailInput: FC<UserDetailInputType & FormikTextFieldProps> = ({
	formik,
	name,
	title,
	readOnly,
	isActive,
	sx = {},
	...props
}) => (
	<FormikTextfield
		formikApi={formik}
		sx={{
			marginBottom: 4,
			width: '100%',
			height: 48,
			pointerEvents: readOnly ? 'none' : 'initial',
			...sx,
		}}
		name={name}
		title={title}
		size="small"
		InputProps={{
			endAdornment: !readOnly && (
				<InputAdornment position="end">
					<EditOutlinedIcon color={isActive ? 'success' : 'disabled'} />
				</InputAdornment>
			),
			readOnly: readOnly,
		}}
		{...props}
	/>
);

// POC
type FormFields = Extendable<UserUpdateModel>;

interface IEditYourProfileProps {
	userId: Maybe<string>;
	onCloseClick: () => void;
}

export const EditYourProfile: FC<IEditYourProfileProps> = ({ onCloseClick, userId }) => {
	const { t, ready } = useTranslation('common');
	const translate = (path: string) => (ready ? t(path) : '');
	const userData = useSelector(userSelector);
	const dispatch = useDispatch();
	const [trigger, setTrigger] = useState(false);

	const selectRole = () => (
		<SelectRole
			name="roles"
			title={translate('edit-user.role')}
			value={formik.values.roles}
			onChange={(e) => formik.setFieldValue('roles', e.target.value)}
			readOnly={true}
			sx={{ pointerEvents: 'none', width: '100%' }}
			IconComponent={() => null}
		/>
	);

	const updateUser = () => {
		if (userData)
			RxUtils.promisify(
				userService.updateUser({
					email: userData?.email.trim(),
					id: userData?.username,
					mobilePhone: formik.values.mobilePhone.trim(),
					name: formik.values.name.trim(),
					surname: formik.values.surname.trim(),
					roles: formik.values.roles,
					status: formik.values.status === UserStatus.ACTIVE,
					laboratories: userData?.laboratories?.map((x) => x.id),
				}),
				(user) => (
					userData &&
						dispatch(
							setUser({
								...userData,
								email: user?.email,
								name: user.name,
								surname: user.surname,
								username: user.id,
							}),
						),
					setTrigger(!trigger),
					notificationService.sendSuccess(t('edit-user.profile-update'))
				),
			);
	};

	const formik = useFormik<FormFields>({
		initialValues: {
			name: '',
			surname: '',
			status: UserStatus.INACTIVE,
			email: '',
			organization: '',
			mobilePhone: '',
			roles: [UserRole.LAB_MEMBER],
			id: '',
		},
		validationSchema: getEditUserValidationSchema(t),
		onSubmit: updateUser,
	});

	useEffect(() => {
		userId &&
			RxUtils.promisify(userService.get(userId), (user) => {
				formik.resetForm({ values: user });
			});
	}, [userId, trigger]);

	const handleOnClose = (fromBackdrop = false) => {
		formik.dirty
			? alertService.send({
					closeTextComponent: <Tr.Common path="edit-user.cancel" />,
					onClose: () => !fromBackdrop && onCloseClick(),
					onConfirm: formik.isValid ? updateUser : undefined,
					content: <Tr.Common path="edit-user.are-you-sure-cancel" />,
					confirmTextHeader: <Tr.Common path="edit-user.discard-changes" />,
					confirmTextComponent: formik.isValid ? <Tr.Common path="edit-user.save-changes" /> : null,
					titleText: <Tr.Common path="confirmation" />,
				})
			: onCloseClick();
	};

	const formComponent = (cardTitle?: ReactNode, sx?: SxProps): ReactNode => (
		<>
			<GenericCard sx={{ marginTop: mainTitleSpacer, minWidth: 400, overflow: 'auto', ...sx, height: '33rem' }} title={cardTitle}>
				<form onSubmit={formik.handleSubmit} data-testid="edit-user-modal-id">
					<Stack direction="column" justifyContent="space-between" sx={{ margin: '20px 0' }}>
						<Stack direction="row" spacing={4}>
							<Stack sx={{ width: '50%' }}>
								<UserDetailInput
									readOnly={false}
									isActive={true}
									formik={formik}
									name="name"
									title={<Tr.Common path="edit-user.first-name" />}
									data-testid="edit-user-first-name-text-id"
								/>
							</Stack>
							<Stack sx={{ width: '50%' }}>
								<UserDetailInput
									readOnly={false}
									isActive={true}
									formik={formik}
									name="surname"
									title={<Tr.Common path="edit-user.last-name" />}
									data-testid="edit-user-last-name-text-id"
								/>
							</Stack>
						</Stack>
						<Stack direction="row" spacing={4}>
							<Stack sx={{ width: '50%' }}>{selectRole()}</Stack>
							<Stack sx={{ width: '50%' }}>
								<UserDetailInput
									readOnly
									formik={formik}
									name="email"
									title={<Tr.Common path="edit-user.email" data-testid="edit-user-email-text-id" />}
									disabled={true}
								/>
							</Stack>
						</Stack>

						<Stack direction="row" spacing={4}>
							<Stack sx={{ width: '50%' }}>
								<UserDetailInput
									isActive={formik.values.status}
									formik={formik}
									name="mobilePhone"
									title={<Tr.Common path="edit-user.phone" />}
									sx={{ marginBottom: 4 }}
									inputProps={{
										'data-testid': 'edit-user-phone-number-id',
									}}
								/>
							</Stack>
							<Stack sx={{ width: '50%' }}>
								<UserDetailInput
									readOnly
									formik={formik}
									name="organizationName"
									disabled={true}
									title={<Tr.Common path="edit-user.organization" />}
								/>
							</Stack>
						</Stack>
					</Stack>
					<Stack direction="row" sx={{ width: 1 }}>
						<ResetPassword />
						<Button
							data-testid="edit-user-close"
							disabled={!formik.dirty}
							onClick={() => handleOnClose()}
							variant="outlined"
							disableElevation
							sx={{ marginLeft: 'auto' }}
						>
							<Tr.Common path="edit-user.cancel" />
						</Button>
						<Button
							data-testid="save-changes-id"
							variant="contained"
							disabled={!formik.dirty || !formik.isValid}
							type="submit"
							disableElevation
							sx={{ marginLeft: 3 }}
						>
							<Tr.Common path="edit-user.save-changes" />
						</Button>
					</Stack>
				</form>
			</GenericCard>
		</>
	);

	return (
		<>
			{formComponent(
				<Typography data-testid="edit-profile-id" variant="h2" sx={{ color: 'primary.main' }}>
					<Tr.Common path="edit-user.edit-profile" />
				</Typography>,
			)}
		</>
	);
};
