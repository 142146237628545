import { GenericCard } from '@components/common';
import { Grid, Stack } from '@mui/material';
import { FC } from 'react';
import { SettingIndicator } from '../PortalOrganizationSettings';
import { Tr } from '@utils/Translation';
import { Organization } from '@services/core';

interface IOrganizationDetailsProps {
	organization?: Organization;
}

export const OrganizationDetails: FC<IOrganizationDetailsProps> = ({ organization }) => {
	const checkAndAddString = (addressSpace?: string, additional?: string) => {
		return addressSpace ? addressSpace + additional : '';
	};
	const handleAddress =
		checkAndAddString(organization?.number, ' ') +
		checkAndAddString(organization?.street, ' ') +
		checkAndAddString(organization?.city, ', ') +
		checkAndAddString(organization?.country, ' ') +
		checkAndAddString(organization?.zipCode, ' ');
	return (
		<GenericCard enableWidthControl={true} sx={{ height: 1 }}>
			<Stack data-testid="subscription-settings-wrapper-id" direction="row">
				<Grid container width="100%" spacing={4}>
					<Grid item xs={4}>
						<Stack spacing={3}>
							<SettingIndicator title={<Tr.Admin path="organization.organization-name" />}>
								{organization?.name}
							</SettingIndicator>
							<SettingIndicator title={<Tr.Admin path="organization.chemistwin-account-id" />}>
								{organization?.chemisTwinAccountId}
							</SettingIndicator>
							<SettingIndicator
								dataTestId="subscription-customer-id"
								title={<Tr.Admin path="organization.sap-customer-id" />}
							>
								{organization?.sapCustomerId ?? ' '}
							</SettingIndicator>
						</Stack>
					</Grid>
					<Grid item xs={4}>
						<Stack spacing={3}>
							<SettingIndicator title={<Tr.Admin path="organization.organization-legal-name" />}>
								{organization?.legalName}
							</SettingIndicator>
							<SettingIndicator title={<Tr.Admin path="organization.phone" />}>{organization?.phoneNumber}</SettingIndicator>
							<SettingIndicator heightMax={true} title={<Tr.Admin path="organization.address" />}>
								{handleAddress}
							</SettingIndicator>
						</Stack>
					</Grid>

					<Grid item xs={4}>
						<Stack spacing={3}>
							<SettingIndicator title={<Tr.Admin path="organization.org-admin-email" />}>
								{organization?.adminEmail}
							</SettingIndicator>

							<SettingIndicator title={<Tr.Admin path="organization.main-contact-person" />}>
								{organization?.mainContactPerson}
							</SettingIndicator>

							<SettingIndicator title={<Tr.Admin path="organization.main-contact-email" />}>
								{organization?.email}
							</SettingIndicator>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</GenericCard>
	);
};
