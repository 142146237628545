import { FC } from 'react';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import { OverflowText } from '@components/common';
import { IrDrmWave } from '@services/ir-drm-pipeline';
import { Card, Stack, Typography, Chip, Divider, LinearProgress } from '@mui/material';

export const ReleasingWaveCard: FC<{ wave: IrDrmWave }> = ({ wave }) => {
	const { name } = wave;

	return (
		<Card
			variant="elevation"
			sx={{ height: '14rem', padding: 3, display: 'grid' }}
			data-testid={DataTestId.getStaticTestId('wave-releasing-card-id')}
		>
			<Stack overflow="hidden">
				<Stack direction="row" justifyContent="space-between">
					<OverflowText variant="h4" enableTooltip={true} color="grey.500" sx={{ width: 'calc(100% - 0.5rem)' }}>
						{name}
					</OverflowText>
					<Chip
						size="small"
						variant="filled"
						sx={{ backgroundColor: 'warning.main' }}
						label={
							<Typography variant="subtitle3" padding={0}>
								<Tr.Admin path="wave.inprogress.name" />
							</Typography>
						}
					/>
				</Stack>
				<Divider sx={{ marginY: 1.5 }} />
				<Stack paddingY={1.5} maxHeight={92} overflow="auto">
					<Typography variant="pg-s" fontWeight={700} color="grey.800">
						<Tr.Admin path="wave.releasing.message-title" />
					</Typography>
					<Typography variant="pg-s" color="grey.800">
						<Tr.Admin path="wave.releasing.message" />
					</Typography>
				</Stack>
			</Stack>
			<Stack direction="row" alignItems="end">
				<LinearProgress key="inprogress-indeterminate" variant="indeterminate" />
			</Stack>
		</Card>
	);
};
