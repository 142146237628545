import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppNotification, NotificationType } from '@models';
import { NotificationContentLinkMapping, NotificationMessage } from '../NotificationCenter/NotificationMessageLink';
import { useNavigate } from 'react-router-dom';
import { TypeUtils } from '@utils/Type';
import { RxUtils } from '@utils/Rx';
import { notificationService } from '@services/core';

interface INotificationProps {
	appNotification: AppNotification;
}

export const ToastNotification: FC<INotificationProps> = ({ appNotification }) => {
	const navigate = useNavigate();
	const isClickable = !(
		appNotification.notificationType === NotificationType.NewErmAvailable ||
		appNotification.notificationType === NotificationType.RequestedErmAvailable
	);
	return (
		<Stack
			data-testid="toast-wrapper-id"
			onClick={() => {
				RxUtils.promisify(notificationService.updateNotificationStatus(appNotification.id, true), () => {
					RxUtils.promisify(notificationService.getNotifications(1, 0, false, false));
				});
				if (isClickable)
					navigate(
						NotificationContentLinkMapping[appNotification.notificationType](
							TypeUtils.transformFromExist(new AppNotification(), appNotification),
						),
					);
			}}
			direction="row"
		>
			<Box
				sx={{
					backgroundColor: 'grey.100',
					width: '2rem',
					height: '2rem',
					padding: '0.5rem',
					borderRadius: '50%',
					marginRight: '0.8rem',
				}}
			>
				<NotificationsIcon sx={{ width: '1rem', height: '1rem' }} />
			</Box>
			<NotificationMessage notification={appNotification} />
		</Stack>
	);
};
