import { concatMap, map, tap } from 'rxjs';
import { httpService, logger } from '@services/core';
import { irConfig } from '../irConfig';
import { RxUtils, TypeUtils } from '@utils';
import { PagedResult, PatchType } from '@models/request-response';
import { IrDeviceAccessoryForList, IrDevice, IrDeviceHealthCheckRequestModel, IrDeviceRequestModel } from './index';

//TODO cleanup to remove unused methods
class IrDeviceService {
	private getURL() {
		return `${irConfig.url}/Device`;
	}

	public getAllDevice(req: Partial<IrDeviceRequestModel>) {
		const params = new URLSearchParams();

		Object.entries(req).forEach(([k, v]) => params.append(k, v.toString()));
		return this.getAllDeviceWithParams(params);
	}

	public getAllDeviceWithParams(params: URLSearchParams) {
		return httpService.get<PagedResult<IrDevice[]>>(this.getURL(), '', { params: params, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getAllDevice.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transformFromExist(new PagedResult<IrDevice>(IrDevice), result)),
		);
	}
	public getDevices(params: URLSearchParams) {
		return httpService.get<IrDevice[]>(this.getURL(), 'deviceNames', { params: params }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getDevices.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => result),
		);
	}

	public getAllDevices(params: URLSearchParams) {
		return httpService
			.get<PagedResult<IrDeviceAccessoryForList[]>>(this.getURL(), 'devices', { params: params, errorHandler: 'notification' })
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllDevices.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrDeviceAccessoryForList>(IrDeviceAccessoryForList), result)),
			);
	}

	public getAllArchived(params: URLSearchParams) {
		return httpService
			.get<PagedResult<IrDeviceAccessoryForList[]>>(this.getURL(), 'archivedDevices', {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllArchived.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrDeviceAccessoryForList>(IrDeviceAccessoryForList), result)),
			);
	}

	public getDevice(id: number, disableLoader?: boolean) {
		return httpService.get<IrDevice>(this.getURL(), `${id}`, { errorHandler: 'notification', disableLoader: !!disableLoader }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getDevice.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrDevice, result)),
		);
	}

	public createDevice(body: IrDeviceRequestModel) {
		const formData = new FormData();
		formData.append('name', body.name);
		formData.append('calibrationName', body.calibrationName);

		body.files.forEach((file, index) => {
			formData.append(`file${index + 1}.file`, file.file);
			formData.append(`file${index + 1}.receiverGain`, `${file.receiverGain}`);
		});

		return httpService.post<IrDevice>(this.getURL(), '', { body: formData, disableLoader: true, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.createDevice.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	public calibrationCheck(deviceId: number, body: IrDeviceHealthCheckRequestModel) {
		const formData = new FormData();
		formData.append('calibrationId', ` ${body.calibrationId}`);
		body.solvent && formData.append('solvent', body.solvent);
		formData.append('file.file', body.file.file);
		formData.append('file.receiverGain', `${body.file.receiverGain}`);

		return httpService
			.post<boolean>(this.getURL(), `${deviceId}/calibrations/${body.calibrationId}/check`, {
				body: formData,
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.calibrationCheck.name}]`, result)));
	}

	public addDevice(name: string, manufacturer: string) {
		return httpService
			.post<IrDevice>(this.getURL(), '', {
				body: { name: name, manufacturer: manufacturer },
				disableLoader: true,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.addDevice.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	public deleteDevice(deviceId: number) {
		return httpService
			.delete(this.getURL(), `${deviceId}`, {
				disableLoader: true,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.deleteDevice.name}]`, result)));
	}

	public renameDevice(id: number, name: string, manufacturer: string) {
		return httpService.put(this.getURL(), `${id}`, {
			body: { name: name, manufacturer: manufacturer },
			disableLoader: true,
			errorHandler: 'notification',
		});
	}

	public renameCalibration(id: number, name: string) {
		return httpService.patch(this.getURL(), `calibrations/${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Name',
					value: name,
				},
			],
			errorHandler: 'notification',
		});
	}
}

export const irDeviceService = new IrDeviceService();
