import { ILocation } from '@models/Location';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../types';

export const initialCountry: ILocation = {
	code: null,
};

export const {
	actions: { setLocation },
	reducer,
} = createSlice({
	name: 'location',
	initialState: initialCountry,
	reducers: {
		setLocation: (state, data: PayloadAction<Maybe<ILocation>>) => {
			state.code = data.payload?.code;
		},
	},
});

export const useLocationSelector = (state: RootState) => state.Location;
