import { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Tr } from '@utils';
import { IFileStateType } from '../IrAddDevice/DeviceFileHook';
import { IRecalibrationType } from '../IrAddDevice/DeviceUtils';

export const IrDeviceSpecificationInfo: FC<{ title: string | JSX.Element; value: string | JSX.Element }> = ({ title, value }) => (
	<>
		<Typography variant="h5">
			<Tr.IrPortal path={title as string} />
		</Typography>
		<Typography variant="body2" sx={{ marginBottom: '1.5rem', wordBreak: 'break-all' }}>
			{value}
		</Typography>
	</>
);

export const IrDeviceSpecification: FC<{
	formik?: ReturnType<typeof useFormik>;
	file?: IFileStateType;
	recalibration: IRecalibrationType | undefined;
}> = ({ file, formik, recalibration }) => {
	const { t } = useTranslation('irportal');
	return (
		<>
			<Typography variant="h4" sx={{ marginBottom: '1.5rem', textTransform: 'none' }} color="primary.main">
				<Tr.IrPortal path="device-management.device-specification" />
			</Typography>

			<IrDeviceSpecificationInfo
				title={t('device-management.device-name')}
				value={
					recalibration?.calibrationData?.name ??
					(formik?.values?.deviceName && formik?.values?.deviceName !== '' ? formik?.values?.deviceName : null) ??
					''
				}
			/>
			<IrDeviceSpecificationInfo
				title={t('device-management.manufacturer')}
				value={recalibration?.calibrationData?.manufacturer ?? file?.manufacturer ?? ''}
			/>
		</>
	);
};
