import { CSSProperties, FC, useEffect, useRef } from 'react';
import { InputAttributes } from 'react-digit-input';
import { Stack } from '@mui/material';

export const CodeInput: FC<{ digits: InputAttributes[]; errored?: boolean; value?: string }> = ({
	digits,
	errored = false,
	value = '',
}) => {
	const inputStyle: CSSProperties = {
		width: '3rem',
		height: '3rem',
		backgroundColor: '#F8F8FC',
		borderRadius: '4px',
		outline: 'none',
		borderWidth: '0.1rem',
		borderStyle: 'solid',
		borderColor: errored ? '#E61E50' : '#E1E1EA',
		color: '#0F1A2E',
		textAlign: 'center' as any,
		fontWeight: 500,
	};

	const focusRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (focusRef.current) {
			focusRef.current.focus();
		}
	}, []);
	useEffect(() => {
		if (errored && focusRef.current) {
			focusRef.current.focus();
		}
	}, [errored]);

	useEffect(() => {
		if (value.length === 0 && focusRef.current) {
			focusRef.current.focus();
		}
	}, [value]);

	return (
		<Stack direction="row" justifyContent="space-between">
			<input data-testid="mfa-input-id-1" style={inputStyle} inputMode="decimal" {...digits[0]} ref={focusRef} />
			<input data-testid="mfa-input-id-2" style={inputStyle} inputMode="decimal" {...digits[1]} />
			<input data-testid="mfa-input-id-3" style={inputStyle} inputMode="decimal" {...digits[2]} />
			<input data-testid="mfa-input-id-4" style={inputStyle} inputMode="decimal" {...digits[3]} />
			<input data-testid="mfa-input-id-5" style={inputStyle} inputMode="decimal" {...digits[4]} />
			<input data-testid="mfa-input-id-6" style={inputStyle} inputMode="decimal" {...digits[5]} />
		</Stack>
	);
};
