import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { formFieldSpacer, Tr } from '@utils';
import { ModalDialog } from '../ModalDialog';
import { useFormik } from 'formik';
import { FormikTextfield } from '../FormikTextfield';
import { PasswordPattern } from '../LoginFlow/Register/PasswordPattern';
import { TableButton } from '@components/nmr-portal';
import { authService, notificationService } from '@services/core';
import { PromiseUtils } from '@utils/PromiseUtils';
import { ResetSuccessfulModal } from './ResetSuccessfulModal';
import { useNavigate } from 'react-router-dom';
import { getResetPasswordValidationSchema } from '@schemas';
import { LoginUserErrors } from '@models/user';

type FormFields = Extendable<{ currentPassword: string; newPassword: string; confirmPassword: string }>;

export const ResetPassword: FC = () => {
	const { t } = useTranslation('login');
	const [open, setOpen] = useState(false);
	const [successful, setSuccessful] = useState(false);
	const [passwordValid, setPasswordValid] = useState(false);
	const navigate = useNavigate();

	const getErrorMessage = (code: string, message: string) => {
		if (code === 'NotAuthorizedException') return t('new-password.current-password-not-correct');
		if (message === LoginUserErrors.COMPROMISED_PASSWORD) return t('register.compromised-password');
		return message;
	};
	const formik = useFormik<FormFields>({
		initialValues: { currentPassword: '', confirmPassword: '', newPassword: '' },
		validationSchema: getResetPasswordValidationSchema(t),
		onSubmit: ({ currentPassword, newPassword }) => {
			PromiseUtils.runPromise(
				() => authService.resetPassword(currentPassword.trim(), newPassword.trim()),
				() => (setOpen(false), setSuccessful(true)),
				(err) => notificationService.sendError(getErrorMessage(err.code, err.message)),
			);
		},
	});

	return (
		<>
			<Button
				data-testid="reset-password-button-id"
				onClick={() => {
					formik.resetForm();
					setOpen(true);
				}}
			>
				<Tr.Login path="reset.change-password" />
			</Button>
			<ResetSuccessfulModal open={successful} onClose={() => navigate('/portal')} />
			<ModalDialog
				title={<Tr.Login path="reset.reset-password" />}
				data-testid="reset-password-modal-id"
				variant="secondary"
				open={open}
				onClose={() => setOpen(false)}
				sx={{
					'& .MuiDialog-container': {
						'& .MuiPaper-root': {
							width: 'min(100%, 472px)',
						},
					},
				}}
			>
				<Stack direction="column" alignItems="center">
					<Typography variant="h3" marginBottom="1.5rem" mt={formFieldSpacer}>
						<Tr.Login path="reset.set-new-password" />
					</Typography>

					<form onSubmit={formik.handleSubmit} style={{ width: 'min(100%,360px)', display: 'flex', flexDirection: 'column' }}>
						<FormikTextfield
							inputProps={{ 'data-testid': 'current-password-text-id' }}
							sx={{ backgroundColor: 'grey.50', marginBottom: formFieldSpacer }}
							required
							variant="outlined"
							formikApi={formik}
							type="password"
							name="currentPassword"
							title={t('reset.current-password')}
						/>
						<FormikTextfield
							inputProps={{ 'data-testid': 'new-password-text-id' }}
							sx={{ backgroundColor: 'grey.50', marginBottom: formFieldSpacer }}
							required
							variant="outlined"
							formikApi={formik}
							type="password"
							name="newPassword"
							title={t('new-password.new-password')}
						/>
						<FormikTextfield
							inputProps={{ 'data-testid': 'confirm-password-text-id' }}
							sx={{ backgroundColor: 'grey.50', marginBottom: formFieldSpacer }}
							required
							variant="outlined"
							formikApi={formik}
							type="password"
							name="confirmPassword"
							title={t('new-password.confirm-password')}
						/>
						<PasswordPattern
							pass1={formik.values.newPassword.trim()}
							pass2={formik.values.confirmPassword.trim()}
							patternIsValid={setPasswordValid}
						/>
						<Stack direction="row" justifyContent="space-between" marginTop="1.5rem" marginBottom="2rem">
							<TableButton.Outlined
								data-testid="reset-password-view-summary-id"
								sx={{ height: '2.5rem', width: '10rem', fontSize: '1rem' }}
								onClick={() => setOpen(false)}
							>
								<Tr.Common path="cancel" />
							</TableButton.Outlined>
							<TableButton.Contained
								data-testid="reset-password-go-analysis-id"
								disabled={!passwordValid || !formik.isValid}
								sx={{ height: '2.5rem', width: '10rem', fontSize: '1rem' }}
								type="submit"
							>
								<Tr.Login path="new-password.save-password" />
							</TableButton.Contained>
						</Stack>
					</form>
				</Stack>
			</ModalDialog>
		</>
	);
};
