import { OrganizationStatus } from '@models/organization';
import { Currency } from '@models/subscribe';
import { Subscription } from '@services';
import { Type } from 'class-transformer';
import { Lab } from '@services';
export class Organization {
	id: number;
	city: string;
	chemisTwinAccountId: string;
	status: OrganizationStatus;
	mainContactPerson: string;
	email: string;
	adminEmail: string;
	legalName: string;
	managerId: number;
	name: string;
	number: string;
	phoneNumber: string;
	sapCustomerId: string;
	street: string;
	zipCode: string;
	country: string;
	subscriptions: Subscription[];
	laboratories: Lab[];
}

export interface OrganizationRequestModel {
	PageIndex: number;
	PageSize: number;
	SortOrder: string;
	Query: string;
	SortBy: string;
	Status: number;
}

export class OrganizationManagementModel {
	id: number;
	status: OrganizationStatus;
	mainContactPerson: string;
	name: string;
	sapCustomerId: string;
	subscriptionType: string;
	label: string;

	totalConsumption?: number;
	totalFreeRuns?: number;
	usedConsumption?: number;
	usedFreeRuns?: number;
	currency?: Currency;

	@Type(() => Date)
	startDate: Date;
	@Type(() => Date)
	endDate: Date;
	@Type(() => Date)
	createTime: Date;
}

export class CurrentSubscription {
	analysisCount: number;
	id: number;
	organizationId: number;
	totalConsumptionAmount: number;

	@Type(() => Date)
	subscriptionStartDate: Date;

	@Type(() => Date)
	subscriptionEndDate: Date;
}
