import { FC, useEffect, useState } from 'react';

import { IrResultImpurites, RmSpectrumInfoPurity, SampleSpectrumInfoPurity } from '@services';
import { SpectraColorCodes, Tr, getTransMissionData, mixtureColorCodes } from '@utils';

import { Stack } from '@mui/material';
import { IrChart } from '../IrChart';
import { useTranslation } from 'react-i18next';
import { setIrTransmissionSpectra, useIrSettingsSelector } from '@store/slices/ir/ir.slice';
import { useDispatch, useSelector } from 'react-redux';

interface IStackedSpectrum {
	subtitleVisibility?: boolean;
	rankedRMData: RmSpectrumInfoPurity;
	userSpectrumInfo: SampleSpectrumInfoPurity;
	chartType?: string;
	resultImpurities?: IrResultImpurites[];
}

export const IrStackedSpectrumPurityCheck: FC<IStackedSpectrum> = ({
	subtitleVisibility,
	rankedRMData,
	userSpectrumInfo,
	resultImpurities,
}) => {
	const chartClassName = `c-overlay-purityCheck-chart`;

	const [chartDataList, setChartDataList] = useState<any>([]);
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra);
	const dispatch = useDispatch();

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
		dispatch(setIrTransmissionSpectra(!displaySettingsToggle));
	};

	const { t } = useTranslation('irportal');
	useEffect(() => {
		if (userSpectrumInfo && rankedRMData) {
			//set user spectrum
			const chartDataAry: any = [];
			const userChartData = {
				y: displaySettingsToggle ? getTransMissionData([...userSpectrumInfo.sample_y]) : userSpectrumInfo.sample_y,
				x: userSpectrumInfo.sample_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.RICH_GREEN },
				direction: 'counterclockwise',
				name: t('analysis-result.sample-spectrum'),
			};
			chartDataAry.push(userChartData);

			const targetSubstanceData = {
				y: displaySettingsToggle ? getTransMissionData([...rankedRMData.spectrum_y]) : rankedRMData.spectrum_y,
				x: rankedRMData.spectrum_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
				direction: 'counterclockwise',
				name: t('analysis-result.target-substance-spectrum'),
			};

			chartDataAry.push(targetSubstanceData);

			if (resultImpurities) {
				resultImpurities.map((item, i) => {
					const impurityData = item && item?.impuritySpectrumData;
					const substanceName = item?.substanceName || '';
					if (impurityData) {
						const impurityChartData = {
							y: displaySettingsToggle ? getTransMissionData([...impurityData['y']]) : impurityData['y'],
							x: userSpectrumInfo?.sample_x,
							type: 'scatter',
							mode: 'lines',
							marker: { color: mixtureColorCodes[`${i}`] },
							direction: 'counterclockwise',
							name: substanceName,
						};

						chartDataAry.push(impurityChartData);
					}
				});
			}
			chartDataAry.map((item, i) => {
				Object.assign(item, { yaxis: i === 0 ? 'y' : 'y' + (i + 1) });
			});
			setChartDataList(chartDataAry);
		}
	}, [rankedRMData, userSpectrumInfo, displaySettingsToggle, resultImpurities]);
	//TODO change the generation of the graphs to dynamic y axis rather hardcoded.
	return (
		<Stack sx={{ border: '1px solid black', borderColor: 'grey.200', borderRadius: '8px' }}>
			{chartDataList && userSpectrumInfo && rankedRMData && (
				<IrChart
					subTitle={
						displaySettingsToggle ? (
							<Tr.IrPortal path="analysis-result.transmission" />
						) : (
							<Tr.IrPortal path="analysis-result.intensity" />
						)
					}
					subtitleVisibility={!!subtitleVisibility}
					className={chartClassName}
					enableZoom={false}
					data={chartDataList}
					useResizeHandler
					style={{ width: '100%', height: '100%' }}
					displaySettingsToggle={handleDisplaySettings}
					layout={{
						margin: { pad: 0, t: 10, r: 50, b: 75, l: 50 },
						hovermode: 'x unified',
						legend: {
							traceorder: 'reversed',
							yanchor: 'bottom',
							y: 1.1,
							xanchor: 'center',
							x: 0.4,
							orientation: 'h',
							bgcolor: 'transparent',
							font: { color: SpectraColorCodes.RICH_BLUE, size: 12 },
						},
						autosize: true,
						yaxis: {
							domain: chartDataList?.length > 2 ? [0.1, 0.2] : [0, 0.5],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis2: {
							domain: chartDataList?.length > 2 ? [0.2, 0.3] : [0.5, 1],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis3: {
							domain: [0.3, 0.4],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis4: {
							domain: [0.4, 0.5],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis5: {
							domain: [0.5, 0.6],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis6: {
							domain: [0.6, 0.7],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis7: {
							domain: [0.7, 0.8],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis8: {
							domain: [0.8, 0.9],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis9: {
							domain: [0.9, 1],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						xaxis: {
							showdividers: false,
							showline: true,
							zeroline: false,
							range: userSpectrumInfo.sample_x_range,
							gridcolor: SpectraColorCodes.GRAY,
							griddash: 'dot',
						},

						shapes: [],
					}}
				/>
			)}
		</Stack>
	);
};
