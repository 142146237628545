import { FormikApiType, FormikTextfield, InfoMessageBox } from '@components/common';
import { CardContent, CardActions, Card, Button, Popper, ClickAwayListener, Typography } from '@mui/material';
import { ERROR_CODE_TRANSLATION_KEY } from '@services/core';
import { Substance, substanceService } from '@services/core/substance';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddSubstanceModal } from './AddSubstanceModal';
import { getAddSubstanceValidationSchema } from '@schemas';
import { DataTestId } from '@utils/DataTestId';
import { PagedResult } from '@models/request-response';

interface AddSubstanceOverlayProps {
	isUserReferenceType?: boolean;
	referenceFormik?: FormikApiType;
	disabled?: boolean;
	loadSubstance?: () => void;
}

export const AddSubstanceOverlay: FC<AddSubstanceOverlayProps> = ({
	isUserReferenceType = false,
	referenceFormik,
	disabled,
	loadSubstance: substanceAdded,
}) => {
	const { t: translation } = useTranslation('common');

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'add-substance-button' : undefined;

	const [infoMessage, setInfoMessage] = useState<string>('');
	const [isAddSubstanceModalVisible, setAddSubstanceModalVisible] = useState<boolean>(false);

	const [substance, setSubstance] = useState<Substance>();

	const formik = useFormik<Extendable<{ casNumber: string }>>({
		initialValues: {
			casNumber: '',
		},
		validationSchema: getAddSubstanceValidationSchema(translation),
		initialTouched: {
			casNumber: true,
		},
		onSubmit: ({ casNumber }) => {
			searchSubstance(casNumber);
		},
	});

	const onAddSubstanceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		formik.resetForm();
	};

	const onAddSubstanceClose = () => {
		setAnchorEl(null);
		setInfoMessage('');
	};

	const loadSubstanceByCAS = (casNumber: string) => {
		RxUtils.promisify(
			substanceService.searchSubstance(casNumber.trim()),
			(res: Substance) => {
				if (res) {
					setSubstance(res);
					onAddSubstanceClose();
					setAddSubstanceModalVisible(true);
					substanceAdded?.();
				}
			},
			(onError) => {
				const errorMsg = ERROR_CODE_TRANSLATION_KEY[onError.ErrorCode];
				if (isUserReferenceType) {
					setInfoMessage(translation('substance-management.reference-search-failed'));
				} else {
					setInfoMessage(translation(errorMsg) ?? translation('substance-management.search-failed'));
				}
				setAddSubstanceModalVisible(false);
			},
		);
	};

	const updatedSearchParams = new URLSearchParams();

	const searchSubstance = (casNumber: string) => {
		if (isUserReferenceType) {
			loadSubstanceByCAS(casNumber);
		} else {
			updatedSearchParams.set('casNumber', casNumber);
			updatedSearchParams.set('pageIndex', '1');
			updatedSearchParams.set('pageSize', '15');
			updatedSearchParams.set('sortOrder', 'reset');
			RxUtils.promisify(
				substanceService.searchWithUrlParams(updatedSearchParams),
				(res: PagedResult<Substance>) => {
					if (res) {
						if (res.count > 0) {
							setInfoMessage(translation('error-codes.substance-already-exist-exception'));
						} else {
							loadSubstanceByCAS(casNumber);
						}
					}
				},
				(onError) => {
					const errorMsg = ERROR_CODE_TRANSLATION_KEY[onError.ErrorCode];
					setInfoMessage(translation(errorMsg) ?? translation('substance-management.search-failed'));
				},
			);
		}
	};

	return (
		<>
			<Button
				sx={[{ marginLeft: 'auto' }, anchorEl && !isUserReferenceType && { background: '#01884C33' }]}
				aria-describedby={id}
				variant={isUserReferenceType ? 'text' : 'contained'}
				onClick={onAddSubstanceClick}
				data-testid={DataTestId.getStaticTestId('add-substance-button-id')}
				disabled={disabled}
			>
				{isUserReferenceType ? (
					<Typography
						sx={{ border: '2px solid #01884C', padding: '10px', borderRadius: '4px', fontWeight: '700' }}
						color="primary.main"
					>
						<Tr.IrPortal path="user-references.load-substance-details" />
					</Typography>
				) : (
					<Tr.Admin path="substance-management.add-substance" />
				)}
			</Button>

			<Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
				<ClickAwayListener onClickAway={onAddSubstanceClose}>
					<Card
						variant="outlined"
						sx={{ marginTop: '8px', padding: '8px', minWidth: 380, minHeight: 180 }}
						data-testid={DataTestId.getStaticTestId('add-substance-modal-id')}
					>
						<form onSubmit={formik.handleSubmit}>
							<CardContent>
								<FormikTextfield
									formikApi={formik}
									name="casNumber"
									title={translation('substance-management.cas-number')}
									required
									containerProps={{ width: 1, marginTop: '1rem' }}
									inputProps={{ 'data-testid': 'cas-number-id' }}
								/>

								{infoMessage !== '' ? <InfoMessageBox message={infoMessage} /> : null}
							</CardContent>
							<CardActions sx={{ float: 'right' }}>
								<Button
									onClick={() => {
										onAddSubstanceClose();
									}}
									data-testid={DataTestId.getStaticTestId('cancel-search-substance-id')}
									size="small"
								>
									<Tr.Admin path="substance-detail.cancel" />
								</Button>
								<Button
									disabled={!formik.dirty || !formik.isValid}
									type="submit"
									variant="contained"
									size="small"
									data-testid={DataTestId.getStaticTestId('search-substance-id')}
								>
									<Tr.Admin path="substance-detail.search" />
								</Button>
							</CardActions>
						</form>
					</Card>
				</ClickAwayListener>
			</Popper>
			{substance && isAddSubstanceModalVisible && (
				<AddSubstanceModal
					substance={substance}
					visible={isAddSubstanceModalVisible}
					onCancel={() => setAddSubstanceModalVisible(false)}
					isUserReferenceType={isUserReferenceType}
					referenceFormik={referenceFormik}
				/>
			)}
		</>
	);
};
