import { AnalysisTechnology, NmrDeviceCalibration } from '@services/index';
import { Type } from 'class-transformer';

export class NmrDevice {
	id: number;
	isActive: boolean;
	name: string;
	manufacturer: string;
	frequency: string;
	probeId: string;
	isGxP?: boolean;
	calibrationValue: number;
	nmrDeviceCalibrations?: NmrDeviceCalibration[];
	supportedTechnology: AnalysisTechnology;

	@Type(() => Date)
	createTime: Date;

	@Type(() => Date)
	nextCalibrationTime: Date;
}
