import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { FormikTextfield, GenericCard, OverflowText } from '@components/common';
import { notificationService, nmrDeviceService } from '@services';
import { greaterThanMaxFiles, mainTitleSpacer, Tr } from '@utils';
import { DeviceSpecification } from '../NmrDeviceSpecification';
import { useService } from '@hooks';
import { ReactComponent as Counter2 } from '@material-symbols/svg-600/outlined/counter_2-fill.svg';
import {
	DeviceFormType,
	useDeviceFormik,
	onCalibrateDevice,
	unknownToFile,
	useDeviceFileHook,
	CalibrationChip,
	DeviceFileUpload,
} from '../DeviceForm';
import { useDeviceOutlet } from '@hooks/nmr/useDeviceOutlet';

export const NmrAddCalibration = () => {
	const [rawFiles, setRawFiles] = useState<File[]>([]);
	const { deviceData, setIsCalibrationProgress, refreshDeviceData } = useDeviceOutlet();

	const navigate = useNavigate();
	const { t } = useTranslation('portal');

	const { data: deviceNamesResponse } = useService(() => nmrDeviceService.getDeviceNames());

	const formik = useDeviceFormik({
		deviceData,
		deviceNamesResponse,
		formType: DeviceFormType.NEW_CALIBRATION,
		onSubmit: () => {
			notificationService.clear();
			setTimeout(() => {
				setIsCalibrationProgress(true);
			}, 50);
			onCalibrateDevice({
				files,
				successCb: () => {
					setIsCalibrationProgress(false);
					navigate(`../`);
					refreshDeviceData?.();
				},
				finalCb: () => {
					setIsCalibrationProgress(false);
				},
				formik,
				deviceId: deviceData?.id,
				calibrationId: undefined,
				receiverGainRange: receiverGainRange,
			});
		},
	});

	const { files, removeFile, canCalibrate, outOfRangeFiles, setOutOfRangeFiles } = useDeviceFileHook({
		newFile: rawFiles,
		formik,
		deviceData,
		deviceNamesResponse,
	});
	const { data: receiverGainRange } = useService(() => nmrDeviceService.receiverGainThreshold(), []);

	const hide = greaterThanMaxFiles(files);

	return (
		<Stack height="100%">
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: mainTitleSpacer }}>
				<OverflowText
					enableTooltip={true}
					variant="h1"
					sx={{ color: 'text.secondary', width: '100%', maxWidth: 'calc(100% - 25rem)' }}
				>
					{deviceData?.calibrationData?.name ?? <Tr.Portal path="device-management.device-details" />}
				</OverflowText>

				<Stack direction="row" alignSelf="flex-end">
					<Button
						data-testid="add-device-cancel-button-id"
						variant="outlined"
						sx={{
							marginRight: '1rem',
						}}
						onClick={() => navigate('../')}
					>
						<Tr.Portal path="device-management.cancel" />
					</Button>
					<Button
						disabled={!canCalibrate || !formik.isValid}
						data-testid="add-device-submit-id"
						type="submit"
						variant="contained"
						disableElevation
						onClick={formik.submitForm}
					>
						<Tr.Portal path="device-management.calibrate" />
					</Button>
				</Stack>
			</Stack>
			<form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
				<Grid data-testid="device-wrapper-id" container columnSpacing={4} height={'100%'}>
					<Grid item xs={6} height={1}>
						<GenericCard sx={{ height: '100%' }}>
							<Stack>
								<Stack paddingBottom={1} marginBottom={3} borderBottom="1px solid" borderColor="grey.200">
									<Typography variant="h3" sx={{ marginBottom: 2, textTransform: 'none' }} color="primary.main">
										<Tr.Portal path="device-management.add-calibration" />
									</Typography>
									<Typography variant="pg-m" sx={{ marginBottom: 1 }}>
										<Tr.Portal path="device-management.add-device-calibration-message" />
									</Typography>
									<Stack direction="row" flexWrap="wrap" sx={{ gap: 1, marginBottom: 1.5 }}>
										{deviceData?.calibrationData?.nmrDeviceCalibrations?.map((calibration) => (
											<CalibrationChip key={calibration.id} calibration={calibration} />
										))}
									</Stack>
								</Stack>
								<DeviceFileUpload
									canCalibrate={canCalibrate}
									files={files}
									hide={hide}
									outOfRangeFiles={outOfRangeFiles}
									setOutOfRangeFiles={setOutOfRangeFiles}
									removeFile={removeFile}
									receiverGainRange={receiverGainRange}
									onUploadedFileChange={(data) => setRawFiles(unknownToFile(data))}
								/>

								<Stack marginTop={2}>
									<Stack direction="row">
										<Counter2 width={24} height={24} fill="#01884C" />
										<Typography variant="h4" color="text.primary" marginLeft={1}>
											{t('device-management.calibration-name')}
										</Typography>
									</Stack>
									<Typography variant="pg-m" sx={{ marginY: 1.5 }}>
										{t('device-management.calibration-name-caption')}
									</Typography>
									<FormikTextfield
										data-testid="add-device-calibration-name-id"
										sx={{ marginBottom: '1rem' }}
										formikApi={formik}
										name="calibrationName"
										required
										placeholder={t('device-management.calibration-name-placeholder')}
										containerProps={{ width: 1 }}
										inputProps={{ maxLength: 256 }}
									/>
								</Stack>
							</Stack>
						</GenericCard>
					</Grid>

					<Grid item xs={6} height={1}>
						<GenericCard sx={{ height: '100%' }}>
							<DeviceSpecification formik={formik} file={files[0]} recalibration={deviceData} />
						</GenericCard>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
};
