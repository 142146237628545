import { IRoute, PortalPageRoutes } from '@models';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Link, List, ListItem, ListItemButton, ListItemText, Popover, Stack, Tooltip } from '@mui/material';
import { ROUTES } from '@routes';
import { flagsSelector, userSelector } from '@store/slices/common/common.slice';
import { useLocationSelector } from '@store/slices/common/location.slice';
import { RouterUtils, Tr, UsefulLinkUtils, getContactMailAddress } from '@utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';
import { LeftSidebarItem } from '../LeftSidebarItem';

export const LeftSidebar: FC = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [isHover, setIsHover] = useState(false);
	const loc = useLocation();
	const country = useSelector(useLocationSelector);
	const { t } = useTranslation('common');
	const matches = matchRoutes(ROUTES, loc);
	const { path, children = [] } = matches?.[0].route as IRoute;
	const flags = useSelector(flagsSelector);
	const user = useSelector(userSelector);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const handleClick = (event: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement>) => {
		if (anchorEl) {
			handleClose();
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		setTimeout(() => setIsHover(false), 150);
	};

	const childRoutes = children.filter(({ hideFromMenu, flag }) => {
		if (hideFromMenu) return false;
		if (flag && !flags[`${flag}`]) return false;

		return true;
	});

	const isWebshop = (childPath?: String) => childPath === PortalPageRoutes.PHYSICAL_REFERENCE_MATERIAL;

	const handleWebshopOpen = (event: unknown, childPath?: String) => {
		if (isWebshop(childPath)) {
			window.open(UsefulLinkUtils.WEB_SHOP_LINK, '_blank');
			(event as MouseEvent).stopPropagation();
		}
	};
	return (
		<Stack
			data-testid="sidebar-test-id"
			spacing={0}
			direction="column"
			sx={{
				backgroundColor: 'background.paper',
				height: 1.0,
				overflowY: 'hidden',
				boxShadow: '5px 0px 13px rgba(203, 203, 203, 0.25)',
			}}
		>
			{childRoutes.map((childRoute, i) => {
				const navigateTo = `${path ? path + '/' : ''}${childRoute.path}`;
				if (user) {
					return (
						RouterUtils.checkUserHasAccessToPage(childRoute, user) && (
							<Tooltip
								key={`left-sidebar-item-${childRoute.path}-${i}`}
								title={<Box>{childRoute.text}</Box>}
								arrow
								placement="right"
							>
								<LeftSidebarItem
									onClick={(event) => handleWebshopOpen(event, childRoute.path)}
									data-testid={`left-sidebar-item-${childRoute.path}-${i}`}
									href={isWebshop(childRoute.path) ? undefined : navigateTo}
								>
									{childRoute.icon}
								</LeftSidebarItem>
							</Tooltip>
						)
					);
				}
			})}

			<Tooltip title={<Tr.Portal path="help" />} arrow placement="right" open={isHover && !open}>
				<LeftSidebarItem
					sx={{ marginLeft: 'auto', color: 'text.primary', marginTop: 'auto' }}
					variant="contained"
					onMouseOver={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					data-testid="left-side-bar-help-id"
					onClick={handleClick}
					disableElevation
					disableRipple
				>
					<HelpOutlineIcon sx={{ color: 'black' }} />
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<List>
							<Link target="blank" href={UsefulLinkUtils.CUSTOMER_SUPPORT} underline="none">
								<ListItem disablePadding>
									<ListItemButton>
										<ListItemText primary={t('sidebar.customer-support')} />
									</ListItemButton>
								</ListItem>
							</Link>
							<Link target="blank" href={UsefulLinkUtils.FAQ} underline="none">
								<ListItem disablePadding>
									<ListItemButton>
										<ListItemText primary={t('sidebar.faq')} />
									</ListItemButton>
								</ListItem>
							</Link>
							<Link
								target="blank"
								href={`mailto:${getContactMailAddress(country.code)}?subject=${t('sidebar.contact-subject')}`}
								underline="none"
							>
								<ListItem disablePadding>
									<ListItemButton>
										<ListItemText primary={t('sidebar.contact-us')} />
									</ListItemButton>
								</ListItem>
							</Link>
						</List>
					</Popover>
				</LeftSidebarItem>
			</Tooltip>
		</Stack>
	);
};
