import { DeviceEventAction, DeviceEventLabel, EventCategory } from '@models/analytics';

export enum ErmEventType {
	ADD_DEVICE = 'AddDeviceOrCalibrate',
	RECALIBRATE_DEVICE = 'RecalibrateDevice',
	CALIBRATION_ERROR = 'CalibrationError',
}

export const sendEvent = (category: EventCategory, action: DeviceEventAction, label: string) =>
	(window as any).gtag('event', action, {
		event_category: category,
		event_label: label,
		value: label,
	});

export const analyticsDeviceEvent: Record<ErmEventType, (deviceName?: string) => void> = {
	AddDeviceOrCalibrate: (deviceName?: string) =>
		sendEvent(EventCategory.Device, DeviceEventAction.ADD_DEVICE, `${DeviceEventLabel.ADD_DEVICE} - ${deviceName}`),
	CalibrationError: (deviceName?: string) =>
		sendEvent(EventCategory.Device, DeviceEventAction.CALIBRATION_ERROR, `${DeviceEventLabel.CALIBRATION_ERROR} - ${deviceName}`),
	RecalibrateDevice: (deviceName?: string) =>
		sendEvent(EventCategory.Device, DeviceEventAction.RECALIBRATE_DEVICE, `${DeviceEventLabel.RECALIBRATE_DEVICE} - ${deviceName}`),
};
