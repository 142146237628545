import { Stack, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { FC } from 'react';

type TEmptySubstanceDetailSection = {
	title: string;
	explanation: string;
};

export const EmptySubstanceDetailSection: FC<TEmptySubstanceDetailSection> = ({ title, explanation }) => {
	return (
		<Stack paddingY={7} sx={{ backgroundColor: 'grey.50', borderRadius: '8px' }}>
			<Stack direction="column" justifyContent="center" alignItems="center">
				<Stack paddingBottom={3}>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						padding={1.5}
						sx={{ backgroundColor: 'grey.100', borderRadius: '50%', width: '80px', height: '80px' }}
					>
						<SearchOutlinedIcon sx={{ fontSize: '40px', color: 'grey.400' }} />
					</Stack>
				</Stack>
				<Typography variant="h3" color="grey.800">
					{title}
				</Typography>

				<Typography variant="pg-m" color="grey.800" sx={{ lineHeight: '160%' }}>
					{explanation}
				</Typography>
			</Stack>
		</Stack>
	);
};
