export const DeviceCalibrationColorMap = {
	ACTIVE: {
		backgroundColor: 'primary.100',
		primary: 'primary.main',
		subtitle: 'text.primary',
		content: 'grey.800',
	},
	INACTIVE: {
		backgroundColor: 'grey.50',
		primary: 'grey.500',
		subtitle: 'grey.500',
		content: 'grey.500',
	},
	CALIBRATION_NEEDED: {
		backgroundColor: 'error.200',
		primary: 'error.main',
		subtitle: 'grey.500',
		content: 'grey.500',
	},
};

export const getCalibrationStatusColors = (isActive: boolean, calibrationNeeded: boolean) => {
	if (isActive) {
		return calibrationNeeded ? DeviceCalibrationColorMap.CALIBRATION_NEEDED : DeviceCalibrationColorMap.ACTIVE;
	}
	return DeviceCalibrationColorMap.INACTIVE;
};
