import { FC, useRef } from 'react';
import { Tr } from '@utils/Translation';
import { DataTestId } from '@utils/DataTestId';
import { Card, Avatar, Typography } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

type TFolderSelectCard = {
	onFolderSelected: (files: FileList | null) => void;
};

export const IrFileUploadCard: FC<TFolderSelectCard> = ({ onFolderSelected }) => {
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	return (
		<Card
			variant="elevation"
			sx={{
				height: '14rem',
				display: 'flex',
				cursor: 'pointer',
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
			onClick={() => fileInputRef?.current?.click()}
			data-testid={DataTestId.getStaticTestId('drm-pipeline-file-upload-card-id')}
		>
			<Avatar
				sx={{
					width: '5rem',
					height: '5rem',
					marginBottom: 2,
					backgroundColor: 'primary.50',
				}}
			>
				<DriveFolderUploadIcon sx={{ fontSize: '2.5rem', color: 'primary.main' }} />
			</Avatar>
			<Typography variant="subtitle3" sx={{ color: 'text.secondary' }}>
				<Tr.Admin path="drm-pipeline.uploading.card-title" />
			</Typography>
			<input
				hidden
				multiple
				type="file"
				onChange={({ target }) => {
					onFolderSelected(target.files);
					target.value = '';
				}}
				ref={(node) => {
					fileInputRef.current = node;
					node?.setAttribute('webkitdirectory', '');
				}}
				data-testid={DataTestId.getStaticTestId('drm-pipeline-file-upload-input-id')}
			/>
		</Card>
	);
};
