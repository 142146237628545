import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Link, Card } from '@mui/material';
import { Tr, getContactMailAddress } from '@utils';
import { AnalysisResultContext } from './analysis-result-context';
import { useLocationSelector } from '@store/slices/common/location.slice';
import { NmrAnalysisCommentField } from './NmrAnalysisCommentField';

export const NmrAnalysisErrorSummary: FC = () => {
	const { analysisResultData } = useContext(AnalysisResultContext);
	const { t } = useTranslation('portal');
	const country = useSelector(useLocationSelector);

	if (!analysisResultData?.isError()) return null;

	return (
		<Card variant="elevation" sx={{ padding: '2rem 1.5rem 2.5rem' }}>
			<Typography variant="h3" sx={{ color: 'primary.main' }}>
				<Tr.Portal path="analysis-result.error-summary" />
			</Typography>
			<Typography variant="pg-m" color="background.paper">
				{analysisResultData.integrationErrorMessage}
			</Typography>
			<Typography variant="pg-m" sx={{ marginTop: 2, marginBottom: 4, display: 'block', color: 'grey.800' }}>
				{t('analysis-result.technical-issue-error-message-first')}
				<Link
					sx={{ textDecoration: 'none' }}
					href={`mailto:${getContactMailAddress(country.code)}?subject=${t('analysis-result.contact-subject')}`}
				>
					<Tr.Portal path="analysis-result.contact-us" />
				</Link>
				{t('analysis-result.technical-issue-error-message-next')}
			</Typography>

			<NmrAnalysisCommentField />
		</Card>
	);
};
