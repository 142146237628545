export enum IrCalibrationStatus {
	ARCHIVED = 'Archived',
	UNCALIBRATED = 'Uncalibrated',
	CALIBRATED = 'Calibrated',
	HEALTH_CHECK_NEEDED = 'HealthCheckNeeded',
	ERROR = 'error',
}

export enum irDeviceTypes {
	ALL = 'all',
	CALIBRATED = 'Calibrated',
}
