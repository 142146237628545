export const findMax = (arr: number[]) =>
	arr.length > 0 ? arr?.reduce((first: number, next: number) => (first > next ? first : next)) : 0;

export const findMin = (arr: number[]) =>
	arr.length > 0 ? arr?.reduce((first: number, next: number) => (first < next ? first : next)) : 0;

export const compareArrays = <T>(arr1: T[], arr2: T[]): boolean => {
	if (arr1.length !== arr2.length) {
		return false;
	}
	return arr1.every((element, index) => element === arr2[`${index}`]);
};
