import { IrSubstance } from '../ir-drm';
// import { NmrSubstance } from '@services/nmr';

export class IrFavoriteReference {
	id: number;
	name: string;
	drmCode: string;
	// @Type(() => Date)
	date: string;
	userId: string;
	referenceMaterialId: number;
	physicalProductNumber: string;
	samplingTechnique: string;
	library: string;
	substanceId: number;
	substanceName: string;
	casNumber: string;
	synonyms: string;
	linearFormula: string;
	smilesCode: string;
	substances: IrSubstance[];
}
