import Plotly from 'plotly.js-dist-min';
import {
	fileService,
	irAnalysisService,
	IrAnalysisResult,
	RankBasedMixtureData,
	RmSpectrumInfoPurity,
	SampleSpectrumInfoPurity,
	IrAnalysisReportImages,
	IrReportImageModel,
	IrPurityCheckPeakjson,
	IrImpurityPeak,
	IrResultImpurites,
} from '@services';
import { PromiseUtils } from '@utils/PromiseUtils';
import { RxUtils } from '@utils/Rx';
import { SpectraColorCodes } from '@utils';
import { getPeakHighligtShapes } from '@utils';
import { buildReportObject, getChartData, peakDataUtils, drawSpectrum } from './IrDrawPurityQuantification.helpers';

export interface IrSettings {
	transmissionSpectra?: boolean;
	chart?: string;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SmilesDrawer = require('smiles-drawer') as any;
const PROMISE_RESOLVER = 'Molecular structure promise resolved.';

export interface ICordinate {
	x: number[];
	y: number[];
}

interface ICordinatePeak {
	peakarray: any[];
	referencePeakRatio: string;
	userPeakRatio: string;
}

const BASE64_KEY = 'data:image/png;base64,';

const drawer = new SmilesDrawer.SmiDrawer({
	width: 450,
	height: 450,
	compactDrawing: false,
	themes: {
		light: {
			C: '#000',
			O: '#000',
			N: '#000',
			F: '#000',
			CL: '#000',
			BR: '#000',
			I: '#000',
			P: '#000',
			S: '#000',
			B: '#000',
			SI: '#000',
			H: '#000',
			BACKGROUND: '#fff',
		},
	},
});

const getJSONDataPeak = async (url: string, analysisResult: IrAnalysisResult): Promise<ICordinatePeak> => {
	return new Promise<{ peakarray: any[]; referencePeakRatio: string; userPeakRatio: string }>((resolve) => {
		RxUtils.promisify(fileService.getFileFromUrl(url), (data) => {
			const { irResultAtomforQuantification, irResultAtom, referencePeakRatio, userPeakRatio } = peakDataUtils(analysisResult, data);
			resolve({
				peakarray: analysisResult.isQuantification() ? irResultAtomforQuantification : irResultAtom,
				referencePeakRatio: referencePeakRatio,
				userPeakRatio: userPeakRatio,
			});
		});
	});
};

export const getJSONData = async (url: string): Promise<ICordinate> => {
	return new Promise<{ x: number[]; y: number[] }>((resolve) => {
		RxUtils.promisify(fileService.getFileFromUrl(url), (data) => {
			const yData = data['user_spectrum'].y;
			const xData = [data['user_spectrum'].x.start, data['user_spectrum'].x.stop];
			const xAry: number[] = [];
			for (let i = xData[0]; i >= xData[1]; i--) {
				xAry.push(i);
			}
			resolve({
				x: xAry,
				y: yData,
			});
		});
	});
};

export const sendIrImagePurityQuantification = async (
	analysisResult: IrAnalysisResult,
	rankedRMData: RankBasedMixtureData,
	userSpectrumInfoPurityCheck: SampleSpectrumInfoPurity,
	rmSpectrumInfoPurityCheck: RmSpectrumInfoPurity,
	cb: (pdfUrl: string) => void,
	irSettings: IrSettings,
	peakData?: IrPurityCheckPeakjson[],
	impurityPeakData?: IrImpurityPeak[],
	resultImpurities?: IrResultImpurites[],
) => {
	const returnedModel: IrAnalysisReportImages = {
		substanceImages: [],
		mixtureImages: '',
	};
	const { transmissionSpectra } = irSettings;
	for (let i = 0; i < rankedRMData?.mixtures?.length || 0; i++) {
		const mixture = rankedRMData?.mixtures[`${i}`];
		const referenceMaterial = analysisResult?.referenceMaterials.filter((item) => item.casNumber === mixture.casNumber);
		const { smilesCode, casNumber } = referenceMaterial[0];
		const molecularStructureImg = document.createElement('img');
		const stackedElement = document.createElement('div');
		stackedElement.style.height = '200px';

		const ermChartElement = document.createElement('div');
		try {
			const imgPromise = new Promise((resolve, reject) => {
				drawer.draw(
					smilesCode,
					molecularStructureImg,
					'light',
					() => {
						resolve(PROMISE_RESOLVER);
					},
					() => {
						reject();
					},
				);
			});

			await Promise.resolve(imgPromise).catch();
		} catch {
			//
		}
		let tempObj: Partial<IrReportImageModel> = {};
		const peakJsonData = await getJSONDataPeak(analysisResult?.peakJson, analysisResult);
		await PromiseUtils.delay(() => {
			tempObj = buildReportObject(analysisResult, molecularStructureImg, peakData, impurityPeakData, peakJsonData, casNumber);
			returnedModel.substanceImages.push(tempObj);
		}, 500);

		const targetResult = rankedRMData.mixtures.filter((item: { casNumber: string }) => item.casNumber === casNumber);
		const chartDataAry = getChartData(transmissionSpectra, userSpectrumInfoPurityCheck, rmSpectrumInfoPurityCheck, resultImpurities);

		const chartDataListGreaterThan2 = chartDataAry?.length > 2;
		for (let j = 0; j < targetResult.length; j++) {
			const sampleData = await getJSONData(analysisResult?.sampleSpectrum);
			await Plotly.newPlot(
				stackedElement,
				chartDataAry,
				{
					margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
					height: 250,
					width: 750,
					autosize: true,
					hovermode: 'x unified',
					legend: {
						traceorder: 'reversed',
						yanchor: 'bottom',
						y: 1.1,
						xanchor: 'center',
						x: 0.4,
						orientation: 'h',
						bgcolor: 'transparent',
						font: { color: '#0F1A2E', size: 12 },
					},
					showlegend: true,
					yaxis: {
						domain: chartDataListGreaterThan2 ? [0.1, 0.2] : [0, 0.5],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
						title: transmissionSpectra ? 'Transmission [%]' : 'Absorbance [A.U.]',
					},
					yaxis2: {
						domain: chartDataListGreaterThan2 ? [0.2, 0.3] : [0.5, 1],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis3: {
						domain: [0.3, 0.4],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis4: {
						domain: [0.4, 0.5],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis5: {
						domain: [0.5, 0.6],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis6: {
						domain: [0.6, 0.7],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis7: {
						domain: [0.7, 0.8],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis8: {
						domain: [0.8, 0.9],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis9: {
						domain: [0.9, 1],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					xaxis: {
						showdividers: false,
						showline: true,
						zeroline: false,
						range: userSpectrumInfoPurityCheck.sample_x_range,
						gridcolor: SpectraColorCodes.GRAY,
						griddash: 'dot',
						title: 'Wavenumber [cm⁻¹]',
					},
					shapes: analysisResult?.isQuantification() ? getPeakHighligtShapes(analysisResult?.quantificationDetails) : [],
				},
				{},
			);
			await drawSpectrum(ermChartElement, sampleData, transmissionSpectra || false);
			const ermImage = await Plotly.toImage(ermChartElement);
			tempObj.referenceMaterialSpectrum = ermImage.replace(BASE64_KEY, '');
			const stackedImage = await Plotly.toImage(stackedElement, { scale: 2, format: 'png', width: 750, height: 225 });
			const spectrumSeperated = stackedImage.replace(BASE64_KEY, '');
			await PromiseUtils.delay(() => {
				returnedModel.mixtureImages = spectrumSeperated;
			}, 500);
		}
	}
	RxUtils.promisify(irAnalysisService.sendAnalysisImage(analysisResult?.analysisId, returnedModel), (pdfUrl) => cb(pdfUrl));
};
