import { FC, MouseEvent } from 'react';
import { Tr, TypeUtils } from '@utils';
import { Button, ButtonProps, SxProps, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ExtendedComponent } from 'src/types';

const DrmRequestStatusButton: ExtendedComponent<ButtonProps> = ({ sx, children, ...props }) => {
	return (
		<Button
			variant="contained"
			color="inherit"
			sx={{
				width: '120px',
				height: '24px',
				...sx,
			}}
			disableElevation
			disableRipple
			{...props}
		>
			{children}
		</Button>
	);
};

type StylableStatuses = 'NewRequest' | 'InPlanning' | 'InProgress' | 'NotAvailable' | 'Released';

const DrmRequestStatusStyleMapper: Record<StylableStatuses, SxProps> = {
	NewRequest: {
		backgroundColor: 'grey.100',
		color: 'grey.500',
	},
	InPlanning: {
		backgroundColor: 'secondary.main',
		color: 'text.primary',
	},
	InProgress: {
		backgroundColor: 'secondary.main',
		color: 'text.primary',
	},
	NotAvailable: {
		backgroundColor: 'grey.100',
		color: 'grey.500',
	},
	Released: {
		backgroundColor: 'primary.main',
		color: 'background.paper',
		pointerEvents: 'none',
	},
};

type TDrmRequestButton = {
	openMenu?: (event: MouseEvent<HTMLElement>) => void;
	isEditable: boolean;
	status: string;
};

export const DrmRequestButton: FC<TDrmRequestButton> = ({ openMenu, isEditable, status }) => {
	const style = TypeUtils.returnValueOfKey<typeof DrmRequestStatusStyleMapper, SxProps>(status, DrmRequestStatusStyleMapper);
	return (
		<DrmRequestStatusButton
			sx={{ ...style, pointerEvents: !isEditable ? 'none' : undefined }}
			onClick={isEditable ? (e) => openMenu?.(e) : () => undefined}
			endIcon={isEditable ? <KeyboardArrowDownIcon /> : undefined}
		>
			<Typography variant="caption-m" sx={{ lineHeight: '12px', letterSpacing: 'unset' }}>
				<Tr.Common path={`select-menu-item.${status?.toLocaleLowerCase()}`} />
			</Typography>
		</DrmRequestStatusButton>
	);
};
