import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import { CommonProps } from '@mui/material/OverridableComponent';
import { ExtendedComponent } from 'src/types';

export const FileDownloadIcon: ExtendedComponent<CommonProps> = ({ ...props }) => (
	<FileDownloadOutlined
		sx={{
			width: 20,
			height: 20,
			color: '#8B8B9C',
			marginRight: 0.625,
		}}
		{...props}
	/>
);
