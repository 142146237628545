import { IRecalibrationType } from '@components/nmr-portal/NmrDevice/DeviceForm/DeviceUtils';
import { TFunction } from 'i18next';
import * as yup from 'yup';

const getCalibrationNameValidation: (t: TFunction, deviceData?: IRecalibrationType) => yup.AnySchema = (t, deviceData) =>
	yup
		.string()
		.required(t('device-management.field-error.calibration-name'))
		.notOneOf(
			deviceData?.calibrationData?.nmrDeviceCalibrations?.map((c) => c.name) || [],
			t('device-management.field-error.calibration-name-not-unique'),
		);
export const getRecalibrationValidationSchema: (t: TFunction, deviceNames?: string[], deviceData?: IRecalibrationType) => yup.AnySchema = (
	t,
	deviceNames,
	deviceData,
) =>
	yup.object({
		deviceName: yup
			.string()
			.max(256)
			.required(t('device-management.field-error.device-name'))
			.when('type', ([type]) => {
				if (type === 'newDevice') {
					return yup.string().notOneOf(deviceNames || [], t('device-management.field-error.device-name-not-unique'));
				}
				return yup.string().nullable();
			}),
		calibrationName: yup
			.string()
			.notRequired()
			.trim()
			.when('type', ([type]) => {
				if (type === 'newDevice') {
					return getCalibrationNameValidation(t, deviceData);
				}
				return yup.string().nullable();
			})
			.when('type', ([type]) => {
				if (type === 'newDevice') {
					return getCalibrationNameValidation(t, deviceData);
				}
				return yup.string().nullable();
			}),
	});
