import { FC } from 'react';
import { Box, Typography, LinearProgress, LinearProgressProps } from '@mui/material';

type TLinearProgressWithLabel = LinearProgressProps & { value: number };

export const MerckLinearProgressWithLabel: FC<TLinearProgressWithLabel> = (props) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ marginRight: 1 }}>
				<Typography variant="label-s" color="grey.800">{`${Math.round(props.value)}%`}</Typography>
			</Box>
			<Box sx={{ width: 1, mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
		</Box>
	);
};
