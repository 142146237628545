import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { DataTestId, Tr } from '@utils';
import { IRecalibrationType } from '../DeviceForm/DeviceUtils';
import { NmrCalibrationStatus } from '@services/nmr';
import AddIcon from '@mui/icons-material/Add';
import { PortalPageRoutes } from '@models/router';
import { useNavigate } from 'react-router-dom';
import { DeviceCalibration } from './DeviceCalibration';

export const DeviceCalibrations: FC<{
	recalibration: IRecalibrationType | undefined;
	refresh: () => void;
}> = ({ recalibration, refresh }) => {
	const navigate = useNavigate();
	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
				<Typography
					variant="h3"
					sx={{ textTransform: 'none' }}
					color={recalibration?.calibrationData?.isActive ? 'primary.main' : 'grey.500'}
					data-testid={DataTestId.getStaticTestId('device-calibrations-title-test-id')}
				>
					<Tr.Portal path="device-management.calibrations" />
				</Typography>
				{recalibration?.calibrationData?.isActive && (
					<Button size="medium" variant="text" color="primary" onClick={() => navigate(`../${PortalPageRoutes.ADD_CALIBRATION}`)}>
						<AddIcon sx={{ width: 20, height: 20, transform: 'translateY(1px)', marginRight: 1.25 }} />
						<Tr.Portal path="device-management.add-calibration" />
					</Button>
				)}
			</Stack>

			<Typography
				variant="pg-m"
				sx={{ marginBottom: '1rem', color: recalibration?.calibrationData?.isActive ? 'grey.800' : 'grey.500' }}
			>
				<Tr.Portal path="device-management.add-calibration-message" />
			</Typography>
			{recalibration?.calibrationData?.nmrDeviceCalibrations?.map((calibration) => (
				<DeviceCalibration
					key={calibration.id + '_' + calibration.nmrDeviceId}
					calibration={calibration}
					isDeviceActive={recalibration.calibrationData?.isActive ?? true}
					isCalibrationActive={calibration.status !== NmrCalibrationStatus.INACTIVE}
					refresh={refresh}
				/>
			))}
		</>
	);
};
