import { useEffect, useState } from 'react';
import { fromJCAMP } from 'nmr-parser';
import { notificationService } from '@services';
import { useTranslation } from 'react-i18next';
import { TypeUtils } from '@utils/Type';

enum AxisType {
	X_DATA = 'x',
	Y_DATA = 'y',
}

interface IMasterData {
	USERNAME: string; // measurement executer IDd
	ORIGIN: string; // device name
	['.SOLVENTNAME']: string; // solvent
	TITLE: string; // measurement Id
	['.OBSERVEFREQUENCY']: string; // frequency
	LONGDATE: string; // measurement date
	RG: string; // receiver gain
	PROBHD: string; // probe-id
}

export type MetaDataType = Record<keyof IMasterData, string>;

interface INmrType {
	meta: MetaDataType;
	dependentVariables: any[];
}

export const useNmrParser = (fileContent?: string) => {
	const [file, setFile] = useState<INmrType>();
	const { t } = useTranslation('portal');

	const getData = (target: AxisType) => file?.dependentVariables[0].components[0].data[`${target}`];

	useEffect(() => {
		if (fileContent) {
			// file can have 2 content
			try {
				const jdxFile = fromJCAMP(fileContent.replaceAll('$$ ##$', '##').replaceAll('\r\n$$', '\r\n##$$empty'));
				setFile(jdxFile.length > 1 ? jdxFile[1] : jdxFile[0]);
			} catch (e) {
				notificationService.sendError(t('file-invalid'));
			}
		}
	}, [fileContent]);

	const date = TypeUtils.returnValueOfKey('DATE', file?.meta ?? {});
	const time = TypeUtils.returnValueOfKey('TIME', file?.meta ?? {});
	file && (file.meta.LONGDATE = file?.meta.LONGDATE ?? date ?? time);
	file && (file.meta.PROBHD = Array.isArray(file.meta.PROBHD) ? file.meta.PROBHD[0] : file.meta.PROBHD);
	file && (file.meta.RG = Array.isArray(file.meta.RG) ? file.meta.RG[0] : file.meta.RG);

	return {
		file: file,
		meta: file?.meta,
		xData: getData(AxisType.X_DATA),
		yData: getData(AxisType.Y_DATA),
	};
};
