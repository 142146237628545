import { loadingSpinnerOverlayService } from '@services/core';

export class PromiseUtils {
	public static runPromise<T>(
		promiseFunction: () => Promise<T>,
		onSuccess?: (data: T) => void,
		onError?: (err: { code: string; message: string }) => void,
		onEnd?: () => void,
	) {
		loadingSpinnerOverlayService.increment();
		promiseFunction()
			?.then((val) => onSuccess?.(val))
			.catch((err) => onError?.(err))
			.finally(() => onEnd?.())
			.finally(() => loadingSpinnerOverlayService.decrement());
	}

	public static delay(cb: () => void, duration = 5000): Promise<boolean> {
		return new Promise<boolean>((resolve) =>
			setTimeout(() => {
				cb();
				resolve(true);
			}, duration),
		);
	}
}
