import { NmrDrmOrderUploadError, NmrDrmOrderUploadErrorType } from '@models/drm-order/file-errors';
import { PatchType } from '@models/request-response';
import { PageRoutes } from '@models/router';
import { NmrAdminPermission, UserStoreModel } from '@models/user';
import {
	NmrDrmOrder,
	NmrDrmOrderAssignee,
	NmrDrmOrderFileType,
	NmrDrmOrderParentStatus,
	NmrDrmOrderStatus,
	nmrDrmOrderService,
} from '@services/nmr-drm-pipeline';
import { FileUtils } from '@utils/File';
import { RxUtils } from '@utils/Rx';
import JSZip from 'jszip';
import { Solvents } from '@models/analysis';
import { SxProps } from '@mui/material';
import GreenBanner from '@assets/images/green-banner.png';
import GreyBanner from '@assets/images/grey-banner.png';
import YellowBanner from '@assets/images/yellow-banner.png';

type BreadcrumbFields = {
	label: string;
	path: string;
};

export const StatusBreadcrumbMap: Record<NmrDrmOrderParentStatus, BreadcrumbFields> = {
	[NmrDrmOrderParentStatus.BACKLOG]: {
		label: 'Backlog',
		path: `/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.NMR}/${PageRoutes.DRM_PIPELINE_BACKLOG}`,
	},
	[NmrDrmOrderParentStatus.PRODUCTION]: {
		label: 'Production',
		path: `/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.NMR}/${PageRoutes.DRM_PIPELINE_PRODUCTION}`,
	},
	[NmrDrmOrderParentStatus.DECLINED]: {
		label: 'Declined',
		path: `/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.NMR}/${PageRoutes.DRM_PIPELINE_DECLINED}`,
	},
	[NmrDrmOrderParentStatus.RELEASED]: {
		label: 'Released',
		path: `/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.NMR}/${PageRoutes.DRM_PIPELINE_RELEASED}`,
	},
	[NmrDrmOrderParentStatus.AUDIT_LOGS]: {
		label: 'Audit',
		path: `/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.NMR}/${PageRoutes.DRM_PIPELINE_AUDIT}`,
	},
};

const commonBackgroundSx: SxProps = {
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'top',
	backgroundSize: '100% 200px;',
};

export const StatusBackgroundSxMap: Record<NmrDrmOrderParentStatus, SxProps> = {
	[NmrDrmOrderParentStatus.BACKLOG]: {
		backgroundImage: `url(${YellowBanner})`,
		...commonBackgroundSx,
	},
	[NmrDrmOrderParentStatus.PRODUCTION]: {
		backgroundImage: `url(${YellowBanner})`,
		...commonBackgroundSx,
	},
	[NmrDrmOrderParentStatus.DECLINED]: {
		backgroundImage: `url(${GreyBanner})`,
		...commonBackgroundSx,
	},
	[NmrDrmOrderParentStatus.RELEASED]: {
		backgroundImage: `url(${GreenBanner})`,
		...commonBackgroundSx,
	},

	[NmrDrmOrderParentStatus.AUDIT_LOGS]: {
		backgroundImage: `url(${GreenBanner})`,
		...commonBackgroundSx,
	},
};

type StatusFileEditableType = {
	coreFilesEditable: boolean;
	additionalFilesEditable: boolean;
	requiredAssignee?: keyof NmrDrmOrder | 'analyst' | 'qcInspector' | 'qaReviewer';
};

const StatusFileEditabilityMapping: Record<NmrDrmOrderStatus, StatusFileEditableType> = {
	[NmrDrmOrderStatus.DRAFT]: {
		coreFilesEditable: false,
		additionalFilesEditable: false,
	},
	[NmrDrmOrderStatus.READY]: {
		coreFilesEditable: false,
		additionalFilesEditable: false,
	},
	[NmrDrmOrderStatus.IN_PRODUCTION]: {
		coreFilesEditable: true,
		additionalFilesEditable: true,
		requiredAssignee: 'analyst',
	},
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: {
		coreFilesEditable: true,
		additionalFilesEditable: true,
		requiredAssignee: 'analyst',
	},
	[NmrDrmOrderStatus.QC_INSPECTION]: {
		coreFilesEditable: false,
		additionalFilesEditable: true,
		requiredAssignee: 'qcInspector',
	},
	[NmrDrmOrderStatus.QA_REVIEW]: {
		coreFilesEditable: false,
		additionalFilesEditable: true,
		requiredAssignee: 'qaReviewer',
	},
	[NmrDrmOrderStatus.DECLINED]: {
		coreFilesEditable: false,
		additionalFilesEditable: false,
	},
	[NmrDrmOrderStatus.RELEASED]: {
		coreFilesEditable: false,
		additionalFilesEditable: false,
	},
};

export const getIsFileEditable = (fileArea: 'core' | 'additional', nmrDrmOrder?: NmrDrmOrder, user?: Maybe<UserStoreModel>) => {
	if (!nmrDrmOrder?.status) return false;

	if (!user?.permissions?.includes(NmrAdminPermission.UPLOAD_FILE)) return false;

	const editableValues = StatusFileEditabilityMapping[nmrDrmOrder.status];

	if (
		(fileArea === 'core' && !editableValues.coreFilesEditable) ||
		(fileArea === 'additional' && !editableValues.additionalFilesEditable)
	) {
		return false;
	}
	const assignee = editableValues.requiredAssignee ? nmrDrmOrder[editableValues.requiredAssignee] : undefined;
	return !editableValues.requiredAssignee || (assignee as NmrDrmOrderAssignee)?.id === user?.username;
};

export type TCoreFile = 'jdx' | 'jdf' | 'zip' | 'spectrus' | 'pdf';

export const FileTypeRequestMapping: Record<TCoreFile, NmrDrmOrderFileType> = {
	jdx: NmrDrmOrderFileType.JDX,
	jdf: NmrDrmOrderFileType.RAW,
	zip: NmrDrmOrderFileType.RAW,
	spectrus: NmrDrmOrderFileType.SPECTRUS,
	pdf: NmrDrmOrderFileType.PDF,
};

export const AcceptedAcqusFiles = ['acqus', 'ACQ'];

export const handleZipFile = async (file: File) => {
	const zipPlaceholder = new JSZip();
	const zipContent = await zipPlaceholder.loadAsync(file);

	const fileEntries = Object.entries(zipContent.files);

	const acqusFileEntries = fileEntries.filter((fArray) =>
		AcceptedAcqusFiles.some((acceptedName) => fArray[1].name.includes(acceptedName)),
	);

	if (acqusFileEntries.length === 0) {
		throw new NmrDrmOrderUploadError(`drm-order-details.file-uploader.no-acqus-file`, NmrDrmOrderUploadErrorType.MISSING_ACQUS_FILE);
	}

	const acqusFile = await zipContent.file(acqusFileEntries[0][1].name)?.async('string');

	return acqusFile;
};

export type TFileUploadChangableFields = {
	Solvent?: string;
	NumberOfScans?: number;
	RelaxationTime?: number;
	Frequency?: number;
	MeasurementDate?: Date;
	Temperature?: number;
	PulseWidth?: number;
	ReceiverGain?: number;
	AcquisitionTime?: number | string;
};

export const keysOfChangableFields: (keyof TFileUploadChangableFields)[] = [
	'Solvent',
	'NumberOfScans',
	'RelaxationTime',
	'Frequency',
	'MeasurementDate',
	'Temperature',
	'PulseWidth',
	'ReceiverGain',
	'AcquisitionTime',
];

const S_TO_MS = 1000;

const unknownToNumber = (record: unknown) => {
	return record ? Number(record) : undefined;
};
export const handleAcqusFileContent = async (file: File): Promise<TFileUploadChangableFields> => {
	const content = await handleZipFile(file);

	if (!content) {
		throw new NmrDrmOrderUploadError(`Acqus file is in wrong format`, NmrDrmOrderUploadErrorType.INVALID_ACQUS_FILE);
	}
	const parsedContent = FileUtils.parseAcqusFileContent(content);

	return {
		Solvent: parsedContent?.['$SOLVENT']?.replace(/^<+|>+$/g, ''),
		NumberOfScans: unknownToNumber(parsedContent?.['$NS']),
		RelaxationTime: unknownToNumber(parsedContent?.['$D']?.split(' ')?.[2]), // check if it really occurs at index 2
		Frequency: unknownToNumber(parsedContent?.['$SFO1']),
		MeasurementDate: parsedContent?.['$DATE'] ? new Date(Number(parsedContent?.['$DATE']) * S_TO_MS) : undefined,
		Temperature: unknownToNumber(parsedContent?.['$TE']),
		PulseWidth: unknownToNumber(parsedContent?.['$P']?.split(' ')?.[2]),
		ReceiverGain: unknownToNumber(parsedContent?.['$RG']),
		AcquisitionTime: '',
	};
};

type FileTypes = 'jdf' | 'zip';

export const FileTypeActionMapping: Record<'jdf' | 'zip', (file: File) => Promise<TFileUploadChangableFields>> = {
	jdf: async (file: File) => await FileUtils.readJdf(file),
	zip: async (file: File) => await handleAcqusFileContent(file),
};

const invalidContent = ['NA', NaN, undefined, null];

const matchSolvent = (solvent?: string) => {
	if (!solvent) {
		return undefined;
	}
	const matchingSolvent = Solvents.filter((existingSolvent) =>
		existingSolvent.names.map((i) => i.toUpperCase()).includes(solvent.toUpperCase()),
	).map((existingSolvent) => existingSolvent.code)[0];

	if (!matchingSolvent) {
		return undefined;
	}
	return matchingSolvent;
};

export const sendFileUploadPatchPayload = async (patchPayload: unknown[], id?: number) => {
	if (id)
		await RxUtils.promisify(
			nmrDrmOrderService.changeOrder(id, patchPayload),
			() => undefined,
			() => {
				throw new NmrDrmOrderUploadError(
					'drm-order-details.file-uploader.technical-error',
					NmrDrmOrderUploadErrorType.UNEXPECTED_ERROR,
				);
			},
		);
};

export const getFileUploadPatchPayload = async (file: File, id?: number) => {
	const fileExtension = file.name.split('.').pop();
	if (id && fileExtension && Object.keys(FileTypeActionMapping).includes(fileExtension ?? '')) {
		const fileContent = await FileTypeActionMapping[fileExtension as FileTypes](file);
		const patchPayload: unknown[] = [];
		const unacceptableFields: string[] = [];
		Object.entries(fileContent).forEach((line) => {
			if (invalidContent.some((content) => content === line[1])) {
				unacceptableFields.push(line[0]);
			} else {
				patchPayload.push({
					op: PatchType.REPLACE,
					path: `/${line[0]}`,
					value: line[0] === 'Solvent' ? matchSolvent(line[1] as string) : line[1],
				});
			}
		});
		const showMatchSolvent = !!matchSolvent(fileContent.Solvent);
		if (unacceptableFields.length > 0 || !showMatchSolvent) {
			throw new NmrDrmOrderUploadError(
				'drm-order-details.file-uploader.invalid-content',
				NmrDrmOrderUploadErrorType.FILE_CONTENT_ERROR,
				{
					unacceptableFields:
						unacceptableFields.length > 0
							? `${unacceptableFields
									.map((f) => f.replace(/([A-Z])/g, ' $1').slice(1))
									.join(', ')} $t(drm-order-details.file-uploader.invalid-data-field)`
							: ``,
					invalidSolvent: !showMatchSolvent && fileContent.Solvent ? '$t(drm-order-details.file-uploader.invalid-solvent)' : '',
					interpolation: {
						skipOnVariables: false,
					},
				},
			);
		}
		return patchPayload;
	}
	return [];
};
