import { concatMap, map, tap } from 'rxjs';
import { PagedResult, PatchType } from '@models';
import { RxUtils, TypeUtils } from '@utils';
import { httpService, logger } from '@services';
import { irConfig } from '../irConfig';
import { RequestIrDrmModel } from './ir-request-drm-model.interface';
import { IIrDrmSearchModel, IrRequestDrm } from './ir-request-drm.class';
import { IrRequestAccordionItem } from './ir-admin-note-model.interface';

export class IrDRMRequestService {
	private getUrl() {
		return `${irConfig.url}/ReferenceMaterialRequest`;
	}

	requestErm(requestErmDto: RequestIrDrmModel) {
		return httpService
			.post(this.getUrl(), '', { body: requestErmDto, errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.requestErm.name}]`, result)));
	}

	requestsErmList({ pageIndex, pageSize, query, sortBy, sortOrder, filteringData }: Partial<IIrDrmSearchModel>) {
		const params = new URLSearchParams();
		params.append('pageIndex', `${pageIndex || ''}`);
		params.append('pageSize', `${pageSize || ''}`);
		params.append('query', `${query || ''}`);
		params.append('sortBy', `${sortBy || ''}`);
		params.append('sortOrder', `${sortOrder || ''}`);

		filteringData?.forEach((item) => {
			const entry = Object.entries(item as {})[0];
			entry[0] && entry[1] && params.append(entry[0], `${entry[1]}`);
		});
		return this.requestsErmListWithParams(params);
	}

	requestsErmListWithParams(params: URLSearchParams) {
		return httpService
			.get<PagedResult<IrRequestDrm>>(this.getUrl(), ``, {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.requestsErmList.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrRequestDrm>(IrRequestDrm), result)),
			);
	}

	requestsDetail(id: number) {
		return httpService.get<IrRequestDrm>(this.getUrl(), `/${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.requestsDetail.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrRequestDrm, result)),
		);
	}

	requestsCommentDetail(RequestId?: number, OrderId?: number) {
		return httpService
			.get<IrRequestAccordionItem[]>(this.getUrl(), `/notes`, {
				errorHandler: 'notification',
				params: {
					RequestId,
					OrderId,
				},
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.requestsDetail.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	addComment(id: number, content: string) {
		return httpService
			.post(this.getUrl(), `/${id}/notes`, {
				errorHandler: 'notification',
				body: {
					content: content,
				},
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.addComment.name}]`, result)));
	}

	editComment(RequestId: number, commentId: number, comment: string) {
		return httpService.patch(this.getUrl(), `${RequestId}/notes/${commentId}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Content',
					value: comment,
				},
			],
			errorHandler: 'notification',
		});
	}

	deleteComment(RequestId: number, commentId: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${RequestId}/notes/${commentId}`, {
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}${this.deleteComment.name}]`, result)));
	}

	updateRequest(id: number, data: unknown) {
		return httpService.patch(this.getUrl(), `/${id}`, {
			errorHandler: 'notification',
			body: data,
		});
	}
}

export const irDrmRequestService = new IrDRMRequestService();
