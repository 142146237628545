import { TFunction } from 'i18next';
import { getPhoneCode, getPhoneNumber } from '@components/common/FormikPhoneField/FormikPhoneField.logic';
import * as yup from 'yup';
import phoneCodes from '@assets/data/phone-codes.json';

const phoneCodeList = phoneCodes.map(({ name, dial_code }) => ({ label: `${name} (${dial_code})`, value: dial_code }));
const onlyNumberRegex = new RegExp(/^\d+$/);
export const getPhoneValidationSchema = (t: TFunction) =>
	yup.object({
		phoneCode: yup
			.string()
			.test({
				name: 'required',
				exclusive: false,
				params: {},
				message: t('phone-number.country-code-is-required'),
				test: (_, context) => {
					return !!getPhoneCode(context.parent.phoneNumber);
				},
			})
			.test({
				name: 'oneOf',
				exclusive: false,
				params: {},
				message: t('phone-number.country-code-not-found'),
				test: (_, context) => {
					return phoneCodeList.some((codes) => codes.value === getPhoneCode(context.parent.phoneNumber));
				},
			}),

		phoneDigits: yup
			.string()
			.test({
				name: 'oneOf',
				exclusive: false,
				params: {},
				message: t('phone-number.phone-number-is-invalid'),
				test: (_, context) => {
					return onlyNumberRegex.test(getPhoneNumber(context.parent.phoneNumber));
				},
			})
			.test({
				name: 'required',
				exclusive: false,
				params: {},
				message: t('phone-number.phone-number-is-required'),
				test: (_, context) => {
					return !!getPhoneNumber(context.parent.phoneNumber);
				},
			}),
	});
