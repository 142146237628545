import { IRouterLinkButtonProps, RouterLinkButton } from '@components/common';
import { Tr } from '@utils/Translation';
import { ExtendedComponent } from 'src/types';

const RouterButton: ExtendedComponent<IRouterLinkButtonProps> = ({ children, sx, size = 'small', ...props }) => (
	<RouterLinkButton
		sx={{
			fontSize: '0.875rem',
			lineHeight: '120%',
			padding: 1,
			borderWidth: '2px',
			borderColor: 'primary.main',
			'&:hover': {
				borderWidth: '2px',
			},
			...sx,
		}}
		variant="outlined"
		size={size}
		disableElevation
		{...props}
	>
		{children}
	</RouterLinkButton>
);

type TableButtonType = 'Outlined' | 'Contained' | 'Text';
export const TableButton: Record<TableButtonType, ExtendedComponent<IRouterLinkButtonProps>> = {
	Outlined: ({ href, sx, size = 'small', children, ...props }) => (
		<RouterButton size={size} href={href} sx={sx} {...props}>
			{children || <Tr.Common path="view-summary" />}
		</RouterButton>
	),
	Contained: ({ href, sx, size = 'small', children, ...props }) => (
		<RouterButton size={size} href={href} sx={sx} variant="contained" {...props}>
			{children || <Tr.Portal path="go-to-analysis" />}
		</RouterButton>
	),
	Text: ({ sx, size = 'small', children, ...props }) => (
		<RouterButton size={size} sx={sx} variant="text" {...props}>
			{children || <Tr.Portal path="export" />}
		</RouterButton>
	),
};
