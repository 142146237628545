import { PoweredByAcdIcon } from '@components/icons';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { Tr } from '@utils';
import { FC } from 'react';

export const ACDLogoFooter: FC<{ fontSize?: number; sx?: SxProps<Theme>; logoSize?: string }> = ({
	fontSize = 26,
	sx = {},
	logoSize = '80px',
	...props
}) => {
	return (
		<Stack direction="row" justifyContent="space-between" marginTop="1rem" sx={sx} {...props}>
			<Typography
				variant="subtitle1"
				data-testid="footer-chemistwin-id"
				fontSize={fontSize}
				display="block"
				color="grey.400"
				width="5em"
			>
				<Tr.Portal path="analysis-result.chemistwin-portal" />
			</Typography>
			<PoweredByAcdIcon sx={{ fontSize: logoSize }} />
		</Stack>
	);
};
