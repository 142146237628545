import { FC, RefObject, useCallback, useEffect, useState } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { loadingSpinnerOverlayService, notificationService, NotificationType } from '@services';
import { FileValidationStatus, IUploadedFile } from '@models';
import { FileUtils, irFileValidator, nameLengthValidator, Tr } from '@utils';
import { Box, Button, IconButton, Paper, Stack, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudUpload } from '@material-symbols/svg-600/outlined/cloud_upload-fill.svg';
import { ReactComponent as CloudUpload2 } from '@material-symbols/svg-600/outlined/cloud_upload.svg';
import { IrSpectrumChart } from '../IrSpectrumChart';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

const MAX_FILE_SIZE = 209715200;

export interface ISampleFileUploaderProps {
	analysisHasFile?: boolean;
	onUploadedFileChange?: (f?: IUploadedFile) => void;
	uploadedFile: any;
	title: string;
	isDisabled?: boolean;
	userReferenceType?: boolean;
	dropzoneSx?: SxProps;
	createRefernceId?: number;
	editing?: boolean;
	triggerReUpload?: boolean;
	fileInputRef?: RefObject<HTMLInputElement>;
}

export const SampleFileUploader: FC<ISampleFileUploaderProps> = ({
	analysisHasFile,
	onUploadedFileChange,
	uploadedFile,
	title,
	isDisabled = false,
	userReferenceType = false,
	dropzoneSx,
	createRefernceId,
	editing = false,
	triggerReUpload = false,
	fileInputRef,
}) => {
	const { t } = useTranslation('irportal');
	const ErrorContentMapping: Record<FileValidationStatus, string> = {
		[FileValidationStatus.INVALID]: t('new-analysis.ir-file-type-error'),
		[FileValidationStatus.MAX_SIZE]: t('new-analysis.file-size-error'),
		[FileValidationStatus.NAME_LARGE]: t('new-analysis.file-length-error'),
		[FileValidationStatus.INVALID_DATA_TYPE]: t('new-analysis.file-data-type-error'),
	};

	const onDrop = useCallback<Required<DropzoneOptions>['onDrop']>(([file]) => {
		if (file) {
			loadingSpinnerOverlayService.increment();
			file.text()
				.then((content) => ({
					file,
					content,
					ext: FileUtils.getExtension(file),
					parsedData: FileUtils.parseContent(file, content),
				}))
				.catch(() => undefined)
				.then(onUploadedFileChange)
				.finally(() => loadingSpinnerOverlayService.decrement());
		}
	}, []);

	const onDropRejected = ([{ errors }]: FileRejection[]) => {
		notificationService.send({
			type: NotificationType.ERROR,
			message: ErrorContentMapping[errors[0].code],
		});
	};

	const onDropActivated = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
		irFileValidator(acceptedFiles[0]).then((val) => {
			if (val) {
				notificationService.send({
					type: NotificationType.ERROR,
					message: ErrorContentMapping[val.code],
				});
			} else {
				onDrop(acceptedFiles, fileRejections, event);
			}
		});
	};

	const [acceptData, setAcceptData] = useState({});
	const [xAxisTitle, setXAxisTitle] = useState('');
	const [yAxisTitle, setYAxisTitle] = useState('');

	useEffect(() => {
		setAcceptData({
			'chemical/x-jcamp-dx': ['.dx', '.spa', '.spc', '.0', '.sp'],
		});
		setXAxisTitle('Wavenumber [cm⁻¹]');
		setYAxisTitle('Absorbance [A.U.]');
	}, []);

	const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
		onDrop: onDropActivated,
		multiple: false,
		accept: acceptData,
		useFsAccessApi: false,
		maxSize: MAX_FILE_SIZE,
		validator: nameLengthValidator,
		onDropRejected,
	});
	const getBorderColor = () => {
		if (isDragActive) {
			return 'grey.700';
		} else {
			return 'grey.200';
		}
	};

	const getTypographyColor = () => {
		if (!isDisabled) {
			return 'grey.800';
		}
		return 'grey.200';
	};

	const getIconColor = () => {
		if (!isDisabled) {
			return '#5E5E72';
		}
		return '#E1E1EA';
	};

	return (
		<Paper
			{...(analysisHasFile ? {} : getRootProps())}
			elevation={0}
			ref={fileInputRef}
			square
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: 218,
				padding: analysisHasFile || editing ? 1 : 2,
				border: analysisHasFile || editing ? '1.5px solid' : '2px dashed',
				borderRadius: '8px',
				borderColor: getBorderColor(),
				backgroundColor: analysisHasFile || editing ? undefined : 'grey.50',
				...dropzoneSx,
			}}
		>
			<Stack direction="column" alignItems="center" justifyContent="center" flexGrow={1}>
				{analysisHasFile || (editing && !triggerReUpload) ? (
					<Box sx={{ position: 'relative', width: 1.0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<IconButton
							sx={{ position: 'absolute', bottom: 4, right: 8, color: 'primary.main' }}
							onClick={() => fileRejections.length === 0 && onUploadedFileChange?.(undefined)}
						/>
						<IrSpectrumChart
							axisTitle={xAxisTitle}
							yaxisTitle={yAxisTitle}
							file={uploadedFile}
							title={title}
							uniqueId="file-uploader-chart"
							createRefernceId={createRefernceId}
							editing={editing}
							isDisabled={isDisabled}
						/>
					</Box>
				) : (
					<>
						{!isDisabled && <input data-testid="file-upload-id" {...getInputProps()} />}
						{userReferenceType ? (
							<>
								<CloudUpload2 width={40} height={40} fill="#5E5E72" />
								<Typography
									variant="body2"
									sx={{
										textAlign: 'center',
										fontSize: 14,
										fontWeight: 700,
										marginTop: 1,
										marginBottom: 1,
										color: '#5E5E72',
									}}
								>
									<Tr.IrPortal path="user-references.upload-drag-and-drop-msg" />
								</Typography>
								<Button data-testid="file-upload-button-id" disabled={false} sx={{ marginTop: 1 }}>
									<AddBoxOutlinedIcon />
								</Button>
							</>
						) : (
							<Stack spacing={3} alignItems="center">
								<CloudUpload width={40} height={40} fill={getIconColor()} />

								<Typography variant="pg-m" color={getTypographyColor()}>
									<Tr.IrPortal path="new-analysis.ir-drag-and-drop-file-here" />
								</Typography>

								<Button variant="contained" disabled={isDisabled} sx={{ width: 116, height: 40, paddingX: 1.5 }}>
									<Tr.IrPortal path="new-analysis.choose-file" />
								</Button>
							</Stack>
						)}
					</>
				)}
			</Stack>
		</Paper>
	);
};
