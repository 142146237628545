import { MfaType } from '@models/user';
import { confirmSignIn } from 'aws-amplify/auth';

type TOnSelect = (challengeParam: any) => void;

export const handleMFASelection = async (mfaType: MfaType, onSMSSelect: TOnSelect, onTOTPSelect: TOnSelect) => {
	try {
		const output = await confirmSignIn({
			challengeResponse: mfaType,
		});
		if (mfaType === MfaType.SMS) {
			onSMSSelect?.(output);
		} else if (mfaType === MfaType.TOTP) {
			onTOTPSelect?.(output);
		}
	} catch (error) {
		console.log(error);
	}
};
