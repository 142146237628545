import { Type } from 'class-transformer';
import { IrDrmWaveStatus } from './ir-drm-wave.enum';

export class IrDrmWave {
	id?: number;
	name: string;
	UploaderIpAddress?: string;
	status: IrDrmWaveStatus;
	orderCount?: number;

	@Type(() => Date)
	startDate?: Date;

	uploader?: string;
	releasedBy?: string;

	@Type(() => Date)
	releaseDate?: Date;
}
