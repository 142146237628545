import { FC } from 'react';
import { Currency } from '@models/subscribe';
import { DataTestId } from '@utils/DataTestId';
import { Chip, Typography } from '@mui/material';
import { FormikApiType, SelectMenu } from '@components/common';

interface ICurrencySelectorProps {
	formik?: FormikApiType;
}

export const CurrencySelector: FC<ICurrencySelectorProps> = ({ formik }) => {
	const currencies = Object.values(Currency);

	return (
		<SelectMenu
			items={currencies}
			selectedItem={formik?.values['paidSubscription.currency']}
			onSelect={(selected) => formik?.setFieldValue('paidSubscription.currency', selected)}
			getButton={(handleClick) => (
				<Chip
					size="small"
					color="success"
					variant="outlined"
					onClick={(e) => handleClick(e)}
					data-testid={DataTestId.getStaticTestId('currency-button-id')}
					label={<Typography variant="subtitle3">{formik?.values.paidSubscription.currency}</Typography>}
				/>
			)}
		/>
	);
};
