import { config } from '@config';
import { PoolType } from './AmplifyConfig.model';
import { ResourcesConfig } from 'aws-amplify';

const ADMIN_SCOPE = ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'];
const USER_SCOPE = ['phone', ...ADMIN_SCOPE];

class AmplifyConfig {
	private _currentConfig: PoolType = PoolType.USER_POOL;

	set currentConfig(poolType: PoolType) {
		this._currentConfig = poolType;
	}
	get currentConfig() {
		return this._currentConfig;
	}

	private isAdminPool() {
		return this._currentConfig === PoolType.ADMIN_POOL;
	}

	getCurrentAmplifyConfig(): ResourcesConfig {
		return {
			Auth: {
				Cognito: {
					// region: config.aws.region,
					userPoolId: this.isAdminPool() ? config.aws.adminPoolId : config.aws.userPoolId,
					userPoolClientId: this.isAdminPool() ? config.aws.adminPoolWebClientId : config.aws.userPoolWebClientId, // HOSTED UI CLIENT */
					// userPoolId: config.aws.userPoolId,
					// userPoolWebClientId: config.aws.userPoolWebClientId,

					loginWith: {
						oauth: {
							domain: this.isAdminPool() ? config.aws.adminOauthDomain : config.aws.oauthDomain,
							scopes: this.isAdminPool() ? ADMIN_SCOPE : USER_SCOPE,
							redirectSignIn: !this.isAdminPool()
								? [`${location.origin}/loggedin`]
								: [`${location.origin}/loggedin?target=admin`],
							redirectSignOut: !this.isAdminPool()
								? [`${location.origin}/loggedout`]
								: [`${location.origin}/loggedout?target=admin`],
							responseType: 'code', // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
						},
					},
				},
			},
		};
	}
}

export const amplifyConfig = new AmplifyConfig();
