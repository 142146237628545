import { Type } from 'class-transformer';
import { NotificationType } from './notification-type.enum';

export type DataType = {
	[data: string]: string;
};

export class AppNotification {
	id: number;

	@Type(() => Date)
	createTime: Date;

	userId: string;
	notificationType: NotificationType;
	isRead: boolean;

	@Type(() => Date)
	readTime: Date;

	isPermanent: boolean;
	data: DataType;

	getData() {
		return JSON.parse(this.data as unknown as string);
	}
}

export const reachedAnalysisLimit = (notificationType: NotificationType) =>
	notificationType === NotificationType.ReachedTheCountLimitOfAnalysis;

export const reachedTimeLimit = (notificationType: NotificationType) =>
	notificationType === NotificationType.ReachedNinetyPercentOfAnalysis;
