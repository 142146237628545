import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { useHasPermissions, useService } from '@hooks';
import { DateUtils, innerTitleSpacer, Tr } from '@utils';
import {
	IrAnalysis,
	getIRAnalysisResultStatusInfo,
	getIRAnalysisRoute,
	getCompletedIRAnalysisResultStatusIcon,
	irAnalysisService,
} from '@services';
import { Card, Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { DataTable, OverflowText } from '@components/common';
import { PortalPageRoutes } from '@models/router';
import { AnalysisHistoryTabs } from '@models/analysis';
import { LabPermission } from '@models/user';
import { TableButton } from '@components/nmr-portal';
import { IrAnalysisTypeIndicator } from '@components/ir-portal/IrAnalysisTypeIndicator';
import { useNavigate } from 'react-router-dom';
import { userSelector } from '@store/slices/common/common.slice';
import { useSelector } from 'react-redux';
import { IrCompletedHistoryLegend } from '@components/ir-portal/IrCompleteHistoryLegend';

const Cell: FC<TypographyProps> = ({ sx, ...props }) => (
	<Typography sx={{ color: 'text.primary', whiteSpace: 'nowrap', ...sx }} {...props} />
);

const Header: FC<TypographyProps> = ({ sx, ...props }) => <Typography sx={{ wordBreak: 'keep-all', ...sx }} {...props} />;

export const MostRecentCompletedAnalysis: FC = () => {
	const { t } = useTranslation('irportal');
	const user = useSelector(userSelector);
	const navigate = useNavigate();

	const { data: analysisPagedResult, loading } = useService(() => {
		return irAnalysisService.getAll({ completed: true, pageIndex: 1, pageSize: 5 });
	});
	const hasAnalysisOwnerPermission = useHasPermissions(LabPermission.ANALYSIS_OWNER);
	const handleClick = () => navigate(`../${PortalPageRoutes.HISTORY}/${AnalysisHistoryTabs.COMPLETED_ANALYSIS}`);

	const columns = useMemo<Column<IrAnalysis>[]>(
		() => [
			{
				accessor: 'status',
				Header: <Header>{t('dashboard.most-recent-completed-analysis.status')}</Header>,
				Cell: ({ row }) => (
					<Tooltip title={getIRAnalysisResultStatusInfo(row.original as IrAnalysis)}>
						<span>
							<Cell sx={{ ml: '1rem' }}>
								{getCompletedIRAnalysisResultStatusIcon(row.original, { width: '1.25rem', height: '1.25rem' })}
							</Cell>
						</span>
					</Tooltip>
				),
			},
			{
				accessor: 'completedTime',
				Header: <Header>{t('dashboard.most-recent-completed-analysis.completion-date')}</Header>,
				Cell: ({ value }) => <Cell>{DateUtils.getFormattedDate(value)}</Cell>,
			},
			{
				accessor: 'title',
				Header: <Header>{t('dashboard.most-recent-completed-analysis.analysis-title')}</Header>,
				Cell: ({ value }) => (
					<OverflowText sx={{ color: 'primary.main', minWidth: '10rem', width: '100%', maxWidth: '20rem' }}>
						{value || '-'}
					</OverflowText>
				),
			},
			{
				accessor: 'type',
				Header: <Header>{t('dashboard.most-recent-completed-analysis.analysis-type')}</Header>,
				Cell: ({ row }) => <IrAnalysisTypeIndicator type={row?.original?.type} sx={{ margin: 0, color: 'text.primary' }} />,
			},
			{
				accessor: 'technology',
				Header: <Header>{t('dashboard.most-recent-completed-analysis.technology')}</Header>,
				Cell: ({ value }) => <Cell>{value || '-'}</Cell>,
			},
			{
				accessor: 'substances',
				Header: <Header>{t('dashboard.most-recent-completed-analysis.substance')}</Header>,
				Cell: ({ value }) => (
					<OverflowText enableTooltip={true} containerProps={{ width: 'auto !important', maxWidth: '10rem' }}>
						{value || '-'}
					</OverflowText>
				),
			},
			{
				accessor: 'operatorName',
				Header: <Header>{t('dashboard.most-recent-completed-analysis.operator-id')}</Header>,
				Cell: ({ value }) => <Cell>{value || '-'}</Cell>,
			},
			{
				accessor: 'id',
				Header: '',
				disableSortBy: true,
				sticky: true,
				Cell: ({ row }) => {
					const showReport =
						!(row.original as IrAnalysis).isAnalysisRejected() && !(row.original as IrAnalysis).isErrorInAnalysis();
					return (
						<Stack direction="row" width="fit-content" marginLeft="auto">
							{showReport ? (
								<TableButton.Contained
									href={`../${getIRAnalysisRoute(row.original.stage)}/${row.original.id}?openReport=true`}
									data-testid={`dash-go-analysis-button-${row.index}-id`}
									sx={{ width: '7rem' }}
								>
									<Tr.IrPortal path="dashboard.most-recent-completed-analysis.view-report" />
								</TableButton.Contained>
							) : (
								<TableButton.Outlined
									href={`../${getIRAnalysisRoute(row.original.stage)}/${row.original.id}`}
									data-testid={`dash-view-summary-button-${row.index}-id`}
								/>
							)}
						</Stack>
					);
				},
			},
		],
		[user, t],
	);

	return !loading ? (
		<Stack data-testid="most-recent-wrapper-id">
			<Stack direction="row" justifyContent="space-between">
				<Typography
					variant="h4"
					sx={{ color: 'primary.main', marginBottom: innerTitleSpacer, fontSize: '1.625rem', fontWeigth: 700 }}
				>
					<Tr.IrPortal path="dashboard.most-recent-completed-analysis.title" />
				</Typography>
			</Stack>
			{analysisPagedResult?.data && analysisPagedResult.data.length ? (
				<Card variant="outlined" sx={{ borderRadius: 0, borderBottom: 'none', overflow: 'auto' }}>
					<DataTable
						columns={columns}
						data={analysisPagedResult.data || []}
						hiddenColumns={hasAnalysisOwnerPermission ? [] : ['operatorName']}
					/>
				</Card>
			) : (
				<Typography variant="h4" textAlign="center" color="grey.700">
					<Tr.IrPortal path="dashboard.most-recent-completed-analysis.no-completed-analysis-yet" />
				</Typography>
			)}
			{analysisPagedResult?.data && analysisPagedResult.data.length > 0 && (
				<Stack direction="row" justifyContent="space-between" sx={{ display: 'inline-flex', alignItems: 'center', mt: '1.5rem' }}>
					<IrCompletedHistoryLegend />

					<Typography
						onClick={handleClick}
						data-testid="dashboard-completed-all-button-id"
						sx={{
							color: 'primary.main',
							fontSize: '0.875rem',
							fontWeight: 700,
							'&:hover': {
								cursor: 'pointer',
								textDecoration: 'underline',
							},
						}}
					>
						<Tr.IrPortal path="dashboard.my-analyses.view-all" />
					</Typography>
				</Stack>
			)}
		</Stack>
	) : (
		<></>
	);
};
