import { ModalDialog, MolecularFormula, OverflowText, PortalBadge, QualityGradeChip } from '@components/common';
import { QuantitativeStep, useIrAnalysis, useMemoizedTestId } from '@hooks';
import {
	Button,
	Stack,
	StackProps,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Theme,
	Tooltip,
	Typography,
	TypographyProps,
	useMediaQuery,
} from '@mui/material';
import { IrDRM, IrSubstance } from '@services';
import { checkReferenceMaterialNew, fixedWith2 } from '@utils/index';
import { Tr } from '@utils/Translation';
import React, { FC, useState } from 'react';
import { IrReferenceMaterialFavorite } from '../IrFavorites/IrReferenceMaterialFavorite';
import { TableButton } from '@components/common/TableButton';

const LARGE_MAX_CHARACTERS = 80;
const MEDIUM_MAX_CHARACTERS = 48;

interface IrSubstanceListItemProps {
	substance: IrSubstance;
	type: 'add' | 'remove' | 'favorite';
	actionLabel: string;
	disabled?: boolean;
	onSubstanceListRefresh?: () => void;
	onSubstanceAdd?: (ermId: number) => void;
	onSubstanceRemove?: (drmCode: number) => void;
	onSubstanceDetail?: (visible: boolean, detailId: number) => void;
	sx?: SxProps;
	width?: string;
}

interface IrSubstanceTableItemProps {
	ermId: number;
	favoriteId?: number;
	name?: string;
	library?: string;
	technology?: string;
	onSubstanceDetail?: (visible: boolean, detailId: number) => void;
	onSubstanceListRefresh?: () => void;
	drmCode: string;
	details: IrDRM;
	createTime?: Date;
	showAddToButton?: boolean;
	getActionButton?: (drmCode: string, ermId: number, isButtonSmall?: boolean, props?: any) => React.ReactNode;
}

interface IrSubstanceTableProps {
	referenceMaterials: IrDRM[];
	onSubstanceDetail?: (visible: boolean, detailId: number) => void;
	onSubstanceListRefresh?: () => void;
	drmCode: string;
	showAddToButton?: boolean;
	getActionButton?: (drmCode: string, ermId: number, isButtonSmall?: boolean, props?: any) => React.ReactNode;
}

const fieldStyle: StackProps['sx'] = {
	marginBottom: 1.8,
};

const labelStyle: TypographyProps['sx'] = {
	fontWeight: 700,
	marginRight: 1,
	fontSize: '14px',
	whiteSpace: 'nowrap',
	color: 'text.primary',
};
const puritylabelStyle: TypographyProps['sx'] = {
	marginRight: 1,
	fontSize: 'large',
	whiteSpace: 'nowrap',
};
const substanceLabelStyle: TypographyProps['sx'] = {
	fontWeight: 400,
	fontSize: '16px',
	whiteSpace: 'nowrap',
	lineHeight: '160%',
};

const textStyle: TypographyProps['sx'] = {
	fontWeight: 400,
	fontSize: '14px',
	wordBreak: 'break-word',
	color: 'text.primary',
};
// TO DO: CREATE DIFFERENT FILES FOR EVERY COMPONENT !
export const IrDRMTableField: FC<IrSubstanceTableItemProps> = ({
	ermId,
	name,
	library,
	favoriteId,
	onSubstanceDetail,
	onSubstanceListRefresh,
	drmCode,
	createTime,
	showAddToButton,
	getActionButton,
	details,
	...props
}) => {
	const isNew = checkReferenceMaterialNew(createTime);

	return (
		<TableRow
			sx={{
				width: '100%',
				'&:last-child td': {
					borderBottom: 0,
				},
			}}
		>
			<TableCell sx={{ paddingLeft: 0, paddingY: 1, width: 24 }}>
				<IrReferenceMaterialFavorite favoriteId={favoriteId} drmCode={drmCode} onSuccess={() => onSubstanceListRefresh?.()} />
			</TableCell>
			<TableCell sx={{ paddingLeft: 0, paddingY: 1 }}>
				{name && (
					<Typography data-testid={`${props['data-testid']}-prod-num`} sx={{ ...textStyle }}>
						{name}
					</Typography>
				)}
			</TableCell>
			<TableCell sx={{ paddingLeft: 0, paddingY: 1 }}>
				<QualityGradeChip isReferenceActive={true} qualityGrade={details?.qualityGrade} />
			</TableCell>
			<TableCell sx={{ paddingLeft: 0, paddingY: 1 }}>
				{library && <Typography sx={{ ...textStyle }}>{library}</Typography>}
			</TableCell>
			<TableCell sx={{ paddingLeft: 0, paddingY: 1, alignItems: 'start' }}>
				{isNew && <PortalBadge color="warning" badgeContent={<Tr.Common path="new" />} />}
			</TableCell>
			<TableCell sx={{ paddingLeft: 0, paddingY: 1, textAlign: 'right' }}>
				<TableButton.Outlined
					data-testid={`${props['data-testid']}-view-details`}
					sx={{
						paddingLeft: 0.75,
						':hover': {
							backgroundColor: 'transparent',
						},
						marginRight: '1rem',
					}}
					onClick={() => onSubstanceDetail?.(true, ermId)}
				>
					<Tr.IrPortal path="new-analysis.view-details" />
				</TableButton.Outlined>
				{showAddToButton && getActionButton && getActionButton(drmCode, ermId, true)}
			</TableCell>
		</TableRow>
	);
};

const IrSubstanceTable: FC<IrSubstanceTableProps> = ({
	referenceMaterials,
	onSubstanceDetail,
	onSubstanceListRefresh,
	showAddToButton,
	getActionButton,
}) => {
	const tableTestId = useMemoizedTestId('table-id', true);
	return (
		<Table sx={{ marginTop: 2, borderTop: '2px solid #E1E1EA' }}>
			<TableHead>
				<TableRow
					sx={{
						width: '100%',
						'&:last-child td': {
							borderBottom: 0,
						},
					}}
				>
					<TableCell sx={{ paddingLeft: 0 }}>
						<Typography></Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0 }}>
						<Typography sx={{ ...labelStyle }}>
							<Tr.IrPortal path="drm-detail.reference-code" />
						</Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0 }}>
						<Typography sx={{ ...labelStyle }}>
							<Tr.Portal path="drm-detail.quality-grade" />
						</Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0 }}>
						<Typography sx={{ ...labelStyle }}>
							<Tr.IrPortal path="drm-detail.erm-library" />
						</Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0 }}>
						<Typography></Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0 }}>
						<Typography></Typography>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{referenceMaterials?.map((drm, index) => (
					<IrDRMTableField
						key={index}
						ermId={drm.id}
						name={drm.drmCode}
						favoriteId={drm.favoriteId}
						library={drm.library || drm.libraryName}
						onSubstanceDetail={onSubstanceDetail}
						onSubstanceListRefresh={onSubstanceListRefresh}
						data-testid={`data-table-${tableTestId}-${index}`}
						drmCode={drm.drmCode}
						createTime={drm.createTime}
						showAddToButton={showAddToButton}
						getActionButton={getActionButton}
						details={drm}
					/>
				))}
			</TableBody>
		</Table>
	);
};

const SubstanceField: FC<{ namePath: string; value: string | number; enableTooltip?: boolean; unit?: string }> = ({
	namePath,
	value,
	enableTooltip = false,
	unit,
}) => (
	<Stack direction="row" sx={fieldStyle}>
		<Typography variant="pg-m" sx={{ ...substanceLabelStyle, color: 'text.primary', flex: 1 }}>
			<Tr.IrPortal path={namePath} />
		</Typography>
		<OverflowText
			variant="pg-m"
			enableTooltip={enableTooltip}
			sx={{ ...substanceLabelStyle, color: 'grey.800' }}
			containerProps={{ flex: 2 }}
		>
			{value || '-'}
			{value && unit ? ` ${unit}` : ''}
		</OverflowText>
	</Stack>
);

export const IrSubstanceListItem: FC<IrSubstanceListItemProps> = ({
	substance,
	type,
	actionLabel,
	disabled,
	onSubstanceListRefresh,
	onSubstanceAdd,
	onSubstanceRemove,
	onSubstanceDetail,
	sx,
}) => {
	const { name, linearFormula, molecularWeight, synonyms, casNumber, referenceMaterials, drmCode, selectedReferenceMaterials } =
		substance;
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const medium = useMediaQuery((theme: Theme) => theme?.breakpoints?.down(1350) ?? false);
	const { updateQuantitativeStep, addedERMs } = useIrAnalysis();
	const testid = useMemoizedTestId(`substance-list-item-${type}`, true);

	const getSubstanceName = () => {
		if (medium) {
			return `${name.substring(0, MEDIUM_MAX_CHARACTERS)} ${name.length >= MEDIUM_MAX_CHARACTERS ? '...' : ''}`;
		}
		return `${name.substring(0, LARGE_MAX_CHARACTERS)} ${name.length >= LARGE_MAX_CHARACTERS ? '...' : ''}`;
	};

	const getActionButton = (actionDrmCode: string, ermId: number, isButtonSmall = false): React.ReactNode => {
		const buttonSize = isButtonSmall ? 'small' : 'medium';
		if (type === 'add' && onSubstanceAdd) {
			return (
				<Button
					variant="contained"
					data-testid={`substance-list-add-button-${testid}`}
					size={buttonSize}
					disabled={disabled || addedERMs?.some((d) => d.selectedReferenceMaterials?.some((r) => r.drmCode === actionDrmCode))}
					disableElevation
					onClick={() => {
						onSubstanceAdd(ermId);
						updateQuantitativeStep(QuantitativeStep.STEP_2);
					}}
				>
					{actionLabel}
				</Button>
			);
		} else if (type === 'remove' && onSubstanceRemove) {
			return (
				<Button
					variant="contained"
					data-testid={`substance-list-remove-button-${testid}`}
					size={buttonSize}
					disabled={disabled}
					disableElevation
					onClick={() => {
						onSubstanceRemove(ermId);
					}}
				>
					{actionLabel}
				</Button>
			);
		}
		return null;
	};

	const molecularFormulaRef = React.createRef<HTMLDivElement>();
	return (
		<>
			{selectedReferenceMaterials && selectedReferenceMaterials.length > 0 && (
				<Stack direction="row" sx={fieldStyle}>
					<Typography variant="h5" sx={{ ...puritylabelStyle, color: 'grey.800', flex: 2, marginTop: '2rem' }}>
						{selectedReferenceMaterials.some((i) => i.isTargetSubstance) ? (
							<Tr.IrPortal path="target-substance" />
						) : (
							<Tr.IrPortal path="expected-impurity" />
						)}
					</Typography>
				</Stack>
			)}
			<Stack id={`substance-list-item-${substance.id}`} direction="row" justifyContent="space-between" width={1} sx={sx}>
				<MolecularFormula
					width={250}
					height={200}
					sx={{ width: '250px', height: '200px' }}
					smilesFormula={substance.smilesCode}
					ref={molecularFormulaRef}
					deactivateColors
				/>

				<Stack direction="column" sx={{ maxWidth: 'calc(100% - 17rem)' }} width={1}>
					<Stack sx={{ position: 'relative' }}>
						<Stack maxWidth="50rem" width="100%">
							<Tooltip title={name}>
								<Typography
									variant="h4"
									sx={{
										color: 'text.primary',
										width: 'fit-content',
										height: 'fit-content',
										marginBottom: '1.8rem',
										marginRight: '1rem',
										maxHeight: '90px',
										whiteSpace: 'nowrap',
									}}
								>
									{getSubstanceName()}
								</Typography>
							</Tooltip>
							<SubstanceField namePath="new-analysis.cas-number" value={casNumber} />
							<SubstanceField namePath="drm-detail.linear-formula" value={linearFormula} />
							<SubstanceField
								namePath="new-analysis.molecular-weight"
								value={molecularWeight ? fixedWith2(molecularWeight) : ''}
								unit="g/mol"
							/>
							<SubstanceField enableTooltip={true} namePath="new-analysis.synonyms" value={synonyms} />
						</Stack>

						<Stack
							direction="column"
							rowGap={2}
							justifyContent={type !== 'favorite' ? 'space-around' : 'flex-start'}
							sx={{ position: 'absolute', top: 10, right: 10 }}
						>
							<ModalDialog
								maxWidth="xs"
								onClose={() => setIsDialogOpen(false)}
								title={
									<Typography variant="inherit">
										<Tr.IrPortal path="new-analysis.remove" />
									</Typography>
								}
								titleProps={{ sx: { paddingBottom: 0 } }}
								onBackdropClick={() => setIsDialogOpen(false)}
								open={isDialogOpen}
								variant="secondary"
								sx={{
									'& .MuiDialog-container': {
										'& .MuiPaper-root': {
											width: '100%',
											maxWidth: '472px',
										},
									},
								}}
							>
								<Stack sx={{ paddingX: 8, paddingTop: 3, paddingBottom: 7 }} textAlign="center" spacing={3}>
									<Stack>
										<Typography variant="h4">
											<Tr.IrPortal path="new-analysis.remove-dialog.title" />
										</Typography>
									</Stack>
									<Stack>
										<Typography variant="body2">
											<Tr.IrPortal path="new-analysis.remove-dialog.description" />
										</Typography>
									</Stack>
									<Stack direction="row" justifyContent="space-between" spacing={2}>
										<Button fullWidth variant="outlined" onClick={() => setIsDialogOpen(false)}>
											<Tr.IrPortal path="new-analysis.remove-dialog.cancel" />
										</Button>
									</Stack>
								</Stack>
							</ModalDialog>
						</Stack>
						<IrSubstanceTable
							referenceMaterials={referenceMaterials ?? selectedReferenceMaterials}
							onSubstanceDetail={onSubstanceDetail}
							onSubstanceListRefresh={onSubstanceListRefresh}
							drmCode={drmCode}
							showAddToButton={!(type === 'favorite')}
							getActionButton={getActionButton}
						/>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};
