import { useParams } from 'react-router-dom';
import { RxUtils, Tr } from '@utils';
import { FC, useEffect, useState } from 'react';
import { GenericCard } from '@components/common';

import { Grid } from '@mui/material';
import { CommentAccordion } from '@components/admin/CommentAccordion';

import { DrmOrderCommentForm } from './DrmOrderCommentForm';
import { NmrDrmOrderCommentInfo, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { EmptyAdditionalActions } from '@components/admin/EmptyAdditionalActions/EmptyAdditionalActions';
interface IDrmOrderCommentFormProps {
	isAddCommentButtonClicked: boolean;
	nmrDrmOrderId: number;
	setIsAddCommentButtonClicked: (value: boolean) => void;
}
export const DrmOrderComments: FC<IDrmOrderCommentFormProps> = ({
	isAddCommentButtonClicked,
	nmrDrmOrderId,
	setIsAddCommentButtonClicked,
}) => {
	const { id } = useParams();

	const [comments, setComments] = useState<NmrDrmOrderCommentInfo[]>();
	const [isCommentsUpdated, setIsCommentsUpdated] = useState(false);

	const getDetailData = () =>
		id &&
		RxUtils.promisify(nmrDrmOrderService.listComments(nmrDrmOrderId ?? 0), (value) => {
			setComments(value);
		});

	useEffect(() => {
		getDetailData();
		return () => {
			setComments(undefined);
		};
	}, []);

	useEffect(() => {
		if (isCommentsUpdated) {
			getDetailData();
			setIsCommentsUpdated(false);
		}
	}, [isCommentsUpdated]);

	return (
		<>
			{!id || (!(comments && comments?.length > 0) && !(comments && comments?.length > 0) && !isAddCommentButtonClicked) ? (
				<EmptyAdditionalActions
					title={
						<>
							<Tr.Admin path="drm-order-details.order-comments.no-comments" />
						</>
					}
					explanation={
						<>
							<Tr.Admin path={'drm-order-details.order-comments.no-comment-explanation'} />
						</>
					}
				/>
			) : (
				<Grid container direction="column">
					<Grid item>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<Grid container justifyContent="space-between">
									<Grid item></Grid>
								</Grid>
							</Grid>
							<Grid item>
								<GenericCard sx={{ paddingY: 2 }}>
									<Grid container direction="column" spacing={2}>
										<Grid item>
											{isAddCommentButtonClicked && (
												<DrmOrderCommentForm
													onCommentUpdate={setIsCommentsUpdated}
													nmrDrmOrderId={nmrDrmOrderId}
													setIsAddCommentButtonClicked={setIsAddCommentButtonClicked}
												/>
											)}
										</Grid>
										<Grid item>
											<CommentAccordion onCommentUpdate={setIsCommentsUpdated} itemList={comments ? comments : []} />
										</Grid>
									</Grid>
								</GenericCard>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};
