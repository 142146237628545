import { Type } from 'class-transformer';
import { AnalysisTechnology } from '../analysis';

export class Accessory {
	id: number;
	isActive: boolean;
	name: string;
	manufacturer: string;
	frequency: string;
	probeId: number;
	calibrationValue: string;
	isGxP: boolean;
	accessoryType: string;
	supportedTechnology: AnalysisTechnology;

	@Type(() => Date)
	createTime: Date;

	@Type(() => Date)
	nextCalibrationTime: Date;
}
