import { ExtendedColumn } from '@models/table';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { NmrDrmOrderBatch } from '@services/nmr-drm-pipeline';
import { Body1, FileDownloadButton, FileDownloadIcon } from '@components/common';
import { DownloadableFileExtension } from '@models/file';
import { DataTestId } from '@utils/DataTestId';

export const NmrBulkUploadReportColumns: (data?: NmrDrmOrderBatch[]) => ExtendedColumn<NmrDrmOrderBatch>[] = (data) => {
	return useMemo<ExtendedColumn<NmrDrmOrderBatch>[]>(
		() => [
			{
				accessor: 'waveName',
				disableSortBy: true,
				Header: (
					<Stack marginLeft={1}>
						<Tr.Admin path="drm-pipeline.reports-page.wave" />
					</Stack>
				),
				Cell: ({ value }) => <Body1 sx={{ pl: 1 }}>{value || '-'}</Body1>,
			},
			{
				accessor: 'uploadDate',
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.upload-date" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy', '-') : '-'}</Body1>,
			},
			{
				accessor: 'uploader',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.uploader" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value ?? '-'}</Body1>,
			},
			{
				accessor: 'numberOfFailedOrders',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.failed" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value ? value : '-'}</Body1>,
			},
			{
				accessor: 'numberOfSuccessfulOrders',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.success" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value || value === 0 ? value : '-'}</Body1>,
			},
			{
				accessor: 'id',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.reports" />
					</Stack>
				),
				Cell: ({ value, row }) => {
					const rowContent = data?.find((d) => d.id === value);
					if (!rowContent)
						return (
							<>
								<FileDownloadIcon />
							</>
						);
					return (
						<>
							<Stack direction="row" alignItems="center" spacing={0.75}>
								<FileDownloadIcon />
								<FileDownloadButton
									extension={DownloadableFileExtension.CSV}
									uri={rowContent?.csvReport?.uri}
									fileName={rowContent?.csvReport?.fileName}
									data-testid={DataTestId.getStaticTestId(`csv-button-${row.index}-id`)}
								/>
								<FileDownloadButton
									extension={DownloadableFileExtension.PDF}
									uri={rowContent?.pdfReport?.uri}
									fileName={rowContent?.pdfReport?.fileName}
									data-testid={DataTestId.getStaticTestId(`pdf-button-${row.index}-id`)}
								/>
							</Stack>
						</>
					);
				},
			},
		],
		[data],
	);
};
