import { PatchType } from '@models/request-response';
import { NmrDrmOrderStatus } from '@services/nmr-drm-pipeline';
import { TypeUtils } from '@utils/Type';

type EditDataDetailsModalFields = 'physicalProductNumber' | 'qualityGrade' | 'batchNumber' | 'solvent' | 'purity' | 'pH';

const EditableStatusesArray = [
	NmrDrmOrderStatus.DRAFT,
	NmrDrmOrderStatus.READY,
	NmrDrmOrderStatus.IN_PRODUCTION,
	NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT,
];
type EditableStatuses = 'Draft' | 'Ready' | 'InProduction' | 'WaitingForMeasurement';

// Implement Generic Type Guard
const isEditableStatus = (status: string): status is EditableStatuses => {
	return EditableStatusesArray.some((s) => s === status);
};
const StatusDataDetailsFieldMapping: Record<EditableStatuses, EditDataDetailsModalFields[]> = {
	[NmrDrmOrderStatus.DRAFT]: ['physicalProductNumber', 'qualityGrade'],
	[NmrDrmOrderStatus.READY]: ['physicalProductNumber', 'qualityGrade', 'batchNumber'],
	[NmrDrmOrderStatus.IN_PRODUCTION]: ['physicalProductNumber', 'qualityGrade', 'batchNumber', 'purity', 'pH'],
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: ['physicalProductNumber', 'qualityGrade', 'batchNumber', 'purity', 'pH'],
};

export const getIsDataLocked = (status: NmrDrmOrderStatus) => {
	return !Object.keys(StatusDataDetailsFieldMapping).includes(status);
};

export const getIsFieldEditable = (field: EditDataDetailsModalFields, status: string) => {
	if (!isEditableStatus(status)) return false;
	const dataFields = TypeUtils.returnValueOfKey<typeof StatusDataDetailsFieldMapping, EditDataDetailsModalFields[]>(
		status,
		StatusDataDetailsFieldMapping,
	);
	return dataFields?.includes(field);
};

export type getPatchValueReturnType = {
	op: string;
	path: string;
	value: string | number;
};

const nonPatchableFields = ['qualityGrade', 'siteSupplier', 'physicalProductId'];

export const getPatchValue: (fieldName: string, value: unknown) => getPatchValueReturnType | undefined = (fieldName, value) => {
	if (nonPatchableFields.includes(fieldName)) return undefined;

	if (value === null || value === undefined || typeof value === 'string' || typeof value === 'number') {
		return {
			op: PatchType.REPLACE,
			path: `/${fieldName}`,
			value: value ?? '',
		};
	}
	return undefined;
};
