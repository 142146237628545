import { IrAdminPermission, NmrAdminPermission } from '@models/user';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

export interface KeyofPermissions {
	[key: string]: string;
}

export const TechnologyPermissionMap: Record<SupportedTechnology | string, KeyofPermissions> = {
	[SupportedTechnology.NMR]: NmrAdminPermission,
	[SupportedTechnology.IR]: IrAdminPermission,
};
