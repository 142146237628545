import { useService } from '@hooks';
import { UserResponseModel, UserRole, UserStatus } from '@models/user';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { getEditOrganizationUserValidationSchema } from '@schemas';
import { EditUserOrganization, notificationService, organizationService, userService } from '@services/core';
import { RxUtils } from '@utils/Rx';
import { formFieldSpacer, Scroll } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchableDropdown } from '../Filter/SearchableDropdown';
import { FormikTextfield } from '../FormikTextfield';
import { ModalDialog } from '../ModalDialog';
import { MultipleRoleSelector } from '../MultipleRoleSelector';
import { ReactivateUser } from '../UserActions';
import { DeactivateUser } from '../UserActions/DeactivateUser';

type FormFields = Extendable<EditUserOrganization>;

type TEditUserModal = {
	currentUser: UserResponseModel;
	saveCallback: () => void;
	onClose: () => void;
};

const SNACKBAR_DURATION_AS_MS = 5000;

const enum OptimisticUserRole {
	ORG_ADMIN,
	LAB_ADMIN,
	LAB_MEMBER,
}

const OptimisticUserRoleUserRoleMapping: Record<OptimisticUserRole, UserRole> = {
	[OptimisticUserRole.ORG_ADMIN]: UserRole.ORG_ADMIN,
	[OptimisticUserRole.LAB_ADMIN]: UserRole.LAB_ADMIN,
	[OptimisticUserRole.LAB_MEMBER]: UserRole.LAB_MEMBER,
};
export const EditUserModal: FC<TEditUserModal> = ({ currentUser, saveCallback, onClose }) => {
	const { t } = useTranslation('common');
	const { data: laboratoryList, loading } = useService(() => organizationService.getLaboratories(currentUser.organizationId || 0));
	const [selectedLaboraties, setSelectedLaboraties] = useState<string[]>(new Array<string>());
	const { data: userDetail, loading: userLoading } = useService(() => userService.get(currentUser.id), []);

	const sendNotification = () =>
		notificationService.sendSuccess(t('user-management.edit-user-success-message', { email: currentUser.email }), {
			duration: SNACKBAR_DURATION_AS_MS,
		});

	useEffect(() => {
		if (userDetail) {
			const userExistingLabs = userDetail.laboratories?.filter((l) => !!l.isActive).map((l) => String(l.id)) ?? [];
			formik.resetForm({
				values: { ...userDetail, laboratories: userExistingLabs },
			});
			setSelectedLaboraties(userExistingLabs);
		}
	}, [userDetail, laboratoryList]);
	const formik = useFormik<FormFields>({
		initialValues: {
			name: currentUser.name,
			surname: currentUser.surname,
			email: currentUser.email,
			// eslint-disable-next-line security/detect-object-injection
			roles: currentUser.roles.map((i) => OptimisticUserRoleUserRoleMapping[i]),
			laboratories: currentUser.laboratories?.map((l) => String(l.id)) ?? [],
			organizationId: currentUser.organizationId,
			status: currentUser.status,
		},
		validationSchema: getEditOrganizationUserValidationSchema(t),
		onSubmit: (values) => {
			RxUtils.promisify(
				userService.updateOrganizationUser({
					name: values.name ?? currentUser.name,
					surname: values.surname ?? currentUser.surname,
					mobilePhone: values.mobilePhone ?? currentUser.mobilePhone,
					roles: values.roles ?? currentUser.roles,
					laboratories: values.laboratories,
					status: currentUser.status ? UserStatus.ACTIVE : UserStatus.INACTIVE,
					id: currentUser.id,
					email: currentUser.email,
				}),
				() => (sendNotification(), saveCallback()),
				onClose,
			);
		},
		initialTouched: {
			email: false,
		},
	});

	const onlyOrgAdminSelected = formik.values?.roles?.length === 1 && formik.values.roles.includes(UserRole.ORG_ADMIN);

	const laboratoryOptions = laboratoryList?.data
		.filter((l) => l.isActive)
		.map((l) => ({
			id: String(l.id),
			value: l.name,
		}));
	return (
		<ModalDialog
			data-testid="edit-user-modal"
			variant="primary"
			open={true}
			maxWidth="sm"
			onClose={onClose}
			sx={{ zIndex: 2 }}
			title={<Tr.Common path={'user-management.edit-user-title'} />}
			PaperProps={{
				sx: {
					maxWidth: '832px',
					justifyContent: 'space-between',
				},
			}}
			iconButtonProps={{
				marginTop: 1,
			}}
			contentProps={{
				sx: {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					padding: 2.5,
					...Scroll.Y,
				},
			}}
		>
			<form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
				<Stack flexGrow={1}>
					<Stack>
						<Grid container rowSpacing={formFieldSpacer} columnSpacing={3} marginLeft={-2.25}>
							<Grid item xs={6}>
								<Stack>
									<FormikTextfield
										formikApi={formik}
										name="name"
										title={t('name')}
										placeholder={t('name')}
										variant="outlined"
										fullWidth
										required={true}
										inputProps={{ 'data-testid': 'edit-user-name-test-id' }}
									/>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack>
									<FormikTextfield
										formikApi={formik}
										name="surname"
										title={t('lastname')}
										placeholder={t('lastname')}
										variant="outlined"
										fullWidth
										required={true}
										inputProps={{ 'data-testid': 'edit-user-surname-test-id' }}
									/>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<FormikTextfield
									formikApi={formik}
									name="Email"
									title={t('Email')}
									placeholder={t('Email')}
									variant="outlined"
									value={currentUser.email}
									fullWidth
									required={true}
									inputProps={{ 'data-testid': 'edit-user-email-test-id' }}
									disabled
								/>
							</Grid>

							<Grid item xs={6} height={1}>
								<FormikTextfield
									formikApi={formik}
									name="mobilePhone"
									title={t('Phone')}
									placeholder={t('Phone')}
									variant="outlined"
									fullWidth
									required
									inputProps={{ 'data-testid': 'edit-user-mobilePhone-test-id' }}
									onChange={(e) => formik.setFieldValue('mobilePhone', e.target.value.trim())}
								/>
							</Grid>
							<Grid item xs={6} height={1}>
								<Stack>
									<Typography variant="label-s" color="grey.800" className="required" marginBottom={1.25}>
										{t('Role')}
									</Typography>
									<MultipleRoleSelector formik={formik} name="roles" set="portal" />
								</Stack>
							</Grid>

							<Grid item xs={6}>
								<Stack>
									<FormikTextfield
										formikApi={formik}
										name="organization"
										title={t('organization')}
										placeholder={t('organization')}
										variant="outlined"
										value={currentUser.organizationName}
										disabled
										fullWidth
										required={true}
										inputProps={{ 'data-testid': 'edit-user-email-test-id' }}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} height={1}>
								<Stack>
									<Typography
										variant="label-s"
										color={onlyOrgAdminSelected ? 'grey.200' : 'grey.800'}
										className={!onlyOrgAdminSelected ? 'required' : ''}
									>
										{t('lab-name')}
									</Typography>
									<SearchableDropdown
										options={laboratoryOptions ?? []}
										onChange={(_, val) => {
											setSelectedLaboraties(val);
											formik.setFieldValue('laboratories', val);
										}}
										loading={loading || userLoading}
										selectedValues={selectedLaboraties}
										disabled={onlyOrgAdminSelected}
										filterOption={false}
										placeholder={t('user-management.select-lab')}
										elementTitle={''}
									/>
								</Stack>
							</Grid>
						</Grid>
					</Stack>
					<Stack alignItems="end" height="100%" marginTop={2}>
						<Stack
							flexDirection="row"
							width={'100%'}
							marginBottom={1}
							flexGrow={1}
							alignItems="center"
							justifyContent="space-between"
						>
							{currentUser.status === UserStatus.ACTIVE ? (
								<DeactivateUser refresh={saveCallback} user={userDetail} disabled={!formik.isValid} />
							) : (
								<ReactivateUser user={userDetail} refresh={saveCallback} disabled={!formik.isValid} />
							)}
							<Stack direction="row" flexGrow={1} justifyContent="flex-end" alignItems="end">
								<Button
									data-testid="cancel-user-button-id"
									type="submit"
									onClick={onClose}
									variant="outlined"
									size="medium"
									sx={{ alignSelf: 'end', marginLeft: 5, width: 'fit-content' }}
								>
									<Tr.Common path="user-management.edit-user.cancel" />
								</Button>
								<Button
									data-testid="edit-user-button-id"
									type="submit"
									variant="contained"
									size="medium"
									disabled={!formik.isValid || !formik.dirty}
									sx={{ alignSelf: 'end', marginLeft: 1 }}
								>
									<Tr.Common path={'user-management.edit-user.save-changes'} />
								</Button>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
