import { concatMap, map, tap } from 'rxjs';
import { PagedResult } from '@models';
import { RxUtils, TypeUtils } from '@utils';
import { httpService, logger } from '@services';
import { Substance } from './substance.class';
import { coreConfig } from '../coreConfig';

export class SubstanceService {
	private getUrl() {
		return `${coreConfig.url}/Substance`;
	}

	searchWithUrlParams(urlParams: URLSearchParams) {
		return httpService
			.get<PagedResult<Substance>>(this.getUrl(), ``, {
				params: urlParams,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.searchWithUrlParams.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<Substance>(Substance), result)),
			);
	}

	get(id: number) {
		return httpService.get<Substance>(this.getUrl(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Substance, result)),
		);
	}
	searchSubstance(casNumber: string) {
		return httpService.get<Substance>(this.getUrl(), `/pubchem/${casNumber}`).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.searchSubstance.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Substance, result)),
		);
	}

	addSubstance(substance: Substance) {
		return httpService
			.post<Substance>(this.getUrl(), '', { body: substance, errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.addSubstance.name}]`, result)));
	}
}

export const substanceService = new SubstanceService();
