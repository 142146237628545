import { catchError, concatMap, map, of, tap, toArray } from 'rxjs';
import { httpService, logger } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { AnalysisTargetType, IUploadedFile, PagedResult, PatchType, QualitativeOrQuantitative } from '@models';
import { nmrConfig } from '../nmrConfig';
import { NmrAnalysisResult } from './nmr-analysis-result.class';
import { IAnalysisReportImages, NmrAnalysis } from './nmr-analysis.class';
import { NmrAnalysisSubstanceListItem } from '../nmr-substance';
import { NMRAnalysisFile, NMRAnalysisPagedRequest, NMRAnalysisReviewLevel, NMRAnalysisReview, NmrDrmDetail } from '@services/nmr';
import { QuantitativeSpectrum } from './nmr-quantitive-spectrum.class';

class NmrAnalysisService {
	private getUrl() {
		return `${nmrConfig.url}/analysis`;
	}
	get(id: number) {
		return httpService
			.get<NmrAnalysis>(this.getUrl(), `${id}`, {
				errorHandler: 'notification',
				headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(NmrAnalysis, result)),
			);
	}
	getAll({ completed, pageIndex, pageSize, query, sortBy, sortOrder, filteringData }: Partial<NMRAnalysisPagedRequest>) {
		const params = new URLSearchParams();
		params.append('completed', `${completed ?? ''}`);
		params.append('pageIndex', `${pageIndex || ''}`);
		params.append('pageSize', `${pageSize || ''}`);
		params.append('query', `${query || ''}`);
		params.append('sortBy', `${sortBy || ''}`);
		params.append('sortOrder', `${sortOrder || ''}`);

		filteringData?.forEach((item) => {
			const entry = Object.entries(item as {})[0];
			entry[0] && entry[1] && params.append(entry[0], `${entry[1]}`);
		});

		return this.getAllWithSearchParams(params);
	}

	getAllWithSearchParams(urlParams: URLSearchParams) {
		return httpService
			.get<PagedResult<NmrAnalysis[]>>(this.getUrl(), '', {
				params: urlParams,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAll.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<NmrAnalysis>(NmrAnalysis), result)),
			);
	}

	getFiles(analysisId: number) {
		return httpService.get<NMRAnalysisFile[]>(this.getUrl(), `${analysisId}/files`, { errorHandler: 'silent' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getFiles.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transformFromExist(new Array<NMRAnalysisFile>(), result)),
		);
	}

	downloadFile(analysisId: number, fileId: number) {
		return httpService.get<string>(this.getUrl(), `${analysisId}/files/${fileId}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.downloadFile.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	deleteFile(analysisId: number, fileId: number) {
		return httpService.delete<unknown>(this.getUrl(), `${analysisId}/files/${fileId}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.deleteFile.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrAnalysis, result)),
		);
	}

	deleteAllFiles(analysisId: number) {
		return this.getFiles(analysisId).pipe(
			concatMap((files) => files.map(({ id }) => id)),
			concatMap((id) => this.deleteFile(analysisId, id)),
			tap((result) => logger.debug(`[${this.constructor.name}.${this.deleteAllFiles.name}]`, result)),
			catchError((e) => {
				logger.error(e);
				return of(null);
			}),
			toArray(),
		);
	}

	getDRMs(analysisId: number) {
		return httpService.get<NmrDrmDetail[]>(this.getUrl(), `${analysisId}/referenceMaterials`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getDRMs.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrDrmDetail, result)),
		);
	}

	getQuantitativeSpectrumData(analysisId: number) {
		return httpService
			.get<QuantitativeSpectrum>(this.getUrl(), `${analysisId}/quantitativeSpectrumData`, { errorHandler: 'notification' })
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getQuantitativeSpectrumData.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(QuantitativeSpectrum, result)),
			);
	}

	addDRMToAnalysis(analysisId: number, drmId: number) {
		return httpService
			.post<unknown>(this.getUrl(), `${analysisId}/referenceMaterials/${drmId}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.addDRMToAnalysis.name}]`, result)));
	}

	removeERM(analysisId: number, drmId: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${analysisId}/referenceMaterials/${drmId}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.removeERM.name}]`, result)));
	}

	removeSubstances(analysisId: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${analysisId}/substances`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.removeSubstances.name}]`, result)));
	}

	deleteAnalysis(id: number) {
		return httpService
			.delete(this.getUrl(), `${id}`)
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.deleteAnalysis.name}]`, result)));
	}

	changeAnalysisType(id: number, measurementType: QualitativeOrQuantitative, targetType: AnalysisTargetType) {
		return httpService.patch(this.getUrl(), `${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/MeasurementType',
					value: measurementType,
				},
				{
					op: PatchType.REPLACE,
					path: '/TargetType',
					value: targetType,
				},
			],
			errorHandler: 'notification',
		});
	}

	changeNumberOfProtons(analysisId: number, peakId: number, numberOfH: Maybe<string>, compoundId?: Maybe<string>) {
		const body = [
			{
				op: PatchType.REPLACE,
				path: '/NumberOfH',
				value: numberOfH,
			},
			...(compoundId ? [{ op: PatchType.REPLACE, path: '/CompoundID', value: compoundId }] : []),
		];
		return httpService
			.patch(this.getUrl(), `${analysisId}/quantitativeSpectrumData/peaks/${peakId}`, {
				body,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.changeNumberOfProtons.name}]`, result)),
				map((result) => TypeUtils.transform(QuantitativeSpectrum, result)),
			);
	}

	comment(id: number, comment: string) {
		return httpService.patch<unknown>(this.getUrl(), `${id}/result`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Comment',
					value: comment,
				},
			],
			disableLoader: true,
			errorHandler: 'notification',
		});
	}
	create(analysis?: Partial<NmrAnalysis>) {
		return httpService.post<NmrAnalysis>(this.getUrl(), '', { body: analysis, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.create.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrAnalysis, result)),
		);
	}

	update(analysis: Partial<NmrAnalysis>, disableLoader = false) {
		return httpService
			.put<NmrAnalysis>(this.getUrl(), '', { body: analysis, disableLoader, errorHandler: 'notification' }) // it might be silent because of auto-saves
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.update.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(NmrAnalysis, result)),
			);
	}

	getResult(id: number) {
		return httpService.get<NmrAnalysisResult>(this.getUrl(), `${id}/result`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getResult.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrAnalysisResult, result)),
		);
	}

	addSubstanceToAnalysis(analysisId: number, substanceId: number) {
		return httpService
			.post<unknown>(this.getUrl(), `${analysisId}/substances`, { body: { substanceId }, errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.addSubstanceToAnalysis.name}]`, result)));
	}

	getAnalysisSubstances(analysisId: number) {
		return httpService
			.get<NmrAnalysisSubstanceListItem[]>(this.getUrl(), `${analysisId}/substances`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.getAnalysisSubstances.name}]`, result)));
	}

	removeSubstanceFromAnalysis(analysisId: number, substanceId: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${analysisId}/substances/${substanceId}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.removeSubstanceFromAnalysis.name}]`, result)));
	}

	loadDRMFromFavorites(analysisId: number, favoriteId: number) {
		return httpService
			.post<unknown>(this.getUrl(), `${analysisId}/favoriteSubstance/${favoriteId}`, {
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.loadDRMFromFavorites.name}]`, result)));
	}

	sendAnalysisImage(analysisId: number, images: IAnalysisReportImages) {
		return httpService
			.post<string>(this.getUrl(), `${analysisId}/report`, {
				body: images,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.sendAnalysisImage.name}]`, result)));
	}

	uploadFile(analysisId: number, file: IUploadedFile) {
		const formData = new FormData();
		formData.append('data', file.file);
		return httpService
			.post<{ id: number; uri: string }>(this.getUrl(), `${analysisId}/files`, {
				body: formData,
				disableLoader: true,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.uploadFile.name}]`, result)));
	}

	triggerTheoreticalSearch(analysisId: number) {
		return httpService
			.post<unknown>(this.getUrl(), `${analysisId}/TheoreticalSearch/run`, {
				body: {},
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.triggerTheoreticalSearch.name}]`, result)));
	}

	cancelTheoreticalSearch(analysisId: number) {
		return httpService
			.post<unknown>(this.getUrl(), `${analysisId}/TheoreticalSearch/cancel`, { body: {}, errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.cancelTheoreticalSearch.name}]`, result)));
	}

	getReview(id: number, reviewLevel: NMRAnalysisReviewLevel) {
		return httpService
			.get<NMRAnalysisReview>(this.getUrl(), `${id}/review`, {
				params: { reviewLevel },
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getReview.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(NMRAnalysisReview, result)),
			);
	}

	sendReview(id: number, decision: boolean, comment?: string) {
		return httpService
			.post<unknown>(this.getUrl(), `${id}/review`, {
				body: {
					decision: decision,
					comment: comment,
				},
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.sendReview.name}]`, result)));
	}

	getSpectrumCompounds(analysisId: number) {
		return httpService.get(this.getUrl(), `${analysisId}/compounds`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getSpectrumCompounds.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	updateCompound(analysisId: number, compound) {
		return httpService
			.put(this.getUrl(), `${analysisId}/compound`, { body: compound, errorHandler: 'notification' }) // it might be silent because of auto-saves
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.updateCompound.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	addCompound(analysisId: number) {
		return httpService.post(this.getUrl(), `${analysisId}/compound`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.addCompound.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	deleteCompound(analysisId: number, compoundId: number) {
		return httpService.delete(this.getUrl(), `${analysisId}/compounds/${compoundId}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.deleteCompound.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	assignCompoundToPeak(analysisId: number, peakId: number, compoundId: Maybe<string>) {
		return httpService
			.patch(this.getUrl(), `${analysisId}/quantitativeSpectrumData/peaks/${peakId}`, {
				body: [
					{
						op: PatchType.REPLACE,
						path: '/CompoundId',
						value: compoundId,
					},
				],
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.assignCompoundToPeak.name}]`, result)),
				map((result) => TypeUtils.transform(QuantitativeSpectrum, result)),
			);
	}

	unselectAnalysisPeak(analysisId: number, peakId: number) {
		return httpService
			.patch(this.getUrl(), `${analysisId}/quantitativeSpectrumData/peaks/${peakId}`, {
				body: [
					{
						op: PatchType.REPLACE,
						path: '/CompoundId',
						value: null,
					},
					{
						op: PatchType.REPLACE,
						path: '/NumberOfH',
						value: '',
					},
				],
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.unselectAnalysisPeak.name}]`, result)),
				map((result) => TypeUtils.transform(QuantitativeSpectrum, result)),
			);
	}
}

export const nmrAnalysisService = new NmrAnalysisService();
