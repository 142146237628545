import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikTextfield, ModalDialog } from '@components/common';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RxUtils } from '@utils/Rx';
import { nmrDeviceService } from '@services/nmr';

export interface IEditDeviceNameDialogProps {
	deviceId: number;
	currentName: string;
	onClose: () => void;
	onSave: () => void;
}

export const EditDeviceNameDialog: FC<IEditDeviceNameDialogProps> = ({ deviceId, currentName, onClose, onSave }) => {
	const { t } = useTranslation('portal');
	const formik = useFormik<Extendable<{ name: string }>>({
		initialValues: {
			name: currentName,
		},
		validationSchema: yup.object({
			name: yup.string().trim().required(t('device-management.field-error.device-name')),
		}),
		initialTouched: {
			name: true,
		},
		onSubmit: ({ name }) => {
			RxUtils.promisify(nmrDeviceService.renameDevice(deviceId, name.trim()), () => onSave());
		},
	});

	// TO DO: USE FORM MODAL DIALOG
	return (
		<ModalDialog
			variant="primary"
			fullWidth={false}
			open={true}
			titleProps={{
				sx: {
					justifyContent: 'space-between',
					marginTop: 0,
					marginBottom: 3,
					minHeight: 30,
					backgroundColor: 'grey.50',
					paddingY: 1.2,
					paddingX: 2,
					height: 40,
				},
			}}
			title={<Typography variant="pg-s">{t('device-management.rename')}</Typography>}
			onClose={() => onClose()}
			maxWidth="sm"
			iconButtonProps={{
				right: 8,
			}}
			PaperProps={{
				sx: {
					maxWidth: '472px',
					minHeight: '351px',
					justifyContent: 'space-between',
				},
			}}
			contentProps={{ sx: { width: 472, paddingBottom: 3 } }}
		>
			<Stack data-testid="edit-device-wrapper" width={1} direction="column" justifyContent="space-between">
				<form onSubmit={formik.handleSubmit}>
					<Stack paddingX={8}>
						<Stack sx={{ textAlign: 'center' }}>
							<Typography variant="subtitle1"> {t('device-management.rename-device')}</Typography>
							<Typography variant="pg-m" sx={{ marginTop: '1.5rem', lineHeight: '160%' }}>
								<Tr.Portal path="device-management.rename-device-information" />
							</Typography>
						</Stack>
						<FormikTextfield
							data-testid="edit-device-name-id"
							formikApi={formik}
							name="name"
							required
							placeholder={t('device-management.device-name-placeholder')}
							containerProps={{ width: 1, marginTop: '1.5rem' }}
							inputProps={{ 'data-testid': 'device-name-id' }}
						/>
					</Stack>
					<Stack direction="row" width={1} paddingX={8} marginTop={4}>
						<Button
							data-testid="cancel-btn-id"
							variant="outlined"
							sx={{ marginRight: '1rem', width: '100%' }}
							onClick={() => onClose()}
						>
							<Tr.Portal path="device-management.cancel" />
						</Button>
						<Button
							data-testid="submit-button-id"
							type="submit"
							disabled={!formik.dirty}
							sx={{ width: '100%' }}
							variant="contained"
							disableElevation
						>
							<Tr.Portal path="Save" />
						</Button>
					</Stack>
				</form>
			</Stack>
		</ModalDialog>
	);
};
