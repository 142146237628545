import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/types';

export interface ITestState {
	[prefix: string]: number;
}

const initialState: ITestState = {};

export const {
	actions: { setTestState },
	reducer,
} = createSlice({
	name: 'test',
	initialState,
	reducers: {
		setTestState: (state, data: PayloadAction<Maybe<ITestState>>) => {
			state = data.payload ? { ...data.payload } : {};
			return state;
		},
	},
});

export const testSelector = (state: RootState) => state.Test;
