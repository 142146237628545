import { UserResponseModel } from '@models/user';
import { Button, Stack, Typography } from '@mui/material';
import { userService } from '@services/index';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendedComponent } from 'src/types';
import { DeactivationConfirmPopUp } from '../DeactivationConfirmation';

type TDeactivateUser = {
	user?: Maybe<UserResponseModel>;
	disabled?: boolean;
	refresh: () => void;
};

export const DeactivateUser: ExtendedComponent<TDeactivateUser> = ({ user, refresh, disabled, ...props }) => {
	const { t } = useTranslation('common');
	const [userStatusOpened, setUserStatusOpened] = useState<boolean>(false);
	if (!user) return <></>;

	const deactivateUser = () => {
		RxUtils.promisify(
			userService.updateUser({
				...user,
				laboratories: user.laboratories?.map((l) => l.id) ?? [],
				status: false,
			}),
			refresh,
		);
	};

	return (
		<>
			<Button
				size="medium"
				variant="text"
				sx={{
					margin: 'auto auto 0 0',
					textTransform: 'capitalize',
				}}
				disableElevation
				disabled={disabled}
				color="error"
				onClick={() => setUserStatusOpened(true)}
				{...props}
				data-testid="deactivate-button-id"
			>
				<Tr.Portal path={'user-management.deactivate-user'} />
			</Button>
			{userStatusOpened && (
				<DeactivationConfirmPopUp
					title={<Tr.Common path={'user-management.confirmation'} />}
					subTitle={
						<>
							<Stack>
								<Typography variant="subtitle1">
									<Tr.Common path="user-management.deactivate-title" />
								</Typography>
							</Stack>
							<Stack>
								<Typography variant="pg-m">
									{t('user-management.deactivate-user-explanation', {
										userName: user?.email,
									})}
								</Typography>
							</Stack>
						</>
					}
					message={t('user-management.deactivate-comment')}
					visible={userStatusOpened}
					onConfirm={() => deactivateUser()}
					onCancel={() => setUserStatusOpened(false)}
				/>
			)}
		</>
	);
};
