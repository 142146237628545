import { useEffect, useState } from 'react';
import { useIdle } from 'react-use';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { alertService, authService } from '@services';
import { Tr } from '@utils/Translation';
import { setUser } from '@store/slices/common/common.slice';

const TWENTY_MINUTES = 20 * 60 * 1000;

export const useIdleDetection = (idleDuration: number = TWENTY_MINUTES): void => {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();
	const isUserIdle = useIdle(idleDuration);
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (isUserIdle && !isOpen && location.pathname !== '/login') {
			setIsOpen(() => true);
			alertService.send({
				content: <Tr.Common path="user-idle.message" />,
				titleText: <Tr.Common path="user-idle.title" />,
				closeTextComponent: <Tr.Common path="user-idle.reject-message" />,
				confirmTextComponent: <Tr.Common path="user-idle.confirm-text" />,
				onConfirm: () => {
					authService.authenticatedUser(); // make the user re-authenticate
					setIsOpen(false);
					navigate(0);
				},
				onClose: () => {
					authService.logout().then(() => {
						dispatch(setUser(null));
						setIsOpen(false);
						navigate(0);
					});
				}, // logout user
			});
		}
	}, [isUserIdle, idleDuration]);
};
