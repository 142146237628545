import { NmrDrmOrderDeclineReason } from '@services/nmr-drm-pipeline';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getDeclineConfirmationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		details: yup
			.string()
			.max(250)
			.nullable()
			.required(t('validation.required', { key: t('details') })),
		reason: yup
			.string()
			.max(250)
			.nullable()
			.oneOf(Object.values(NmrDrmOrderDeclineReason), t('validation.select-value', { key: t('reason') }))
			.required(t('validation.required', { key: t('reason') })),
	});
