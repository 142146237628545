import { FC, useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { GenericCard, InfoMessageBox } from '@components/common';
import { Tr } from '@utils';
import { AnalysisResultRunTheoretical, NmrAnalysisResultTable, NmrAnalysisTypeIndicator } from '@components/nmr-portal';
import { AnalysisResultContext } from './analysis-result-context';
// import { AnalysisCommentField } from './AnalysisCommentField';
import { AnalysisResultTheoreticalSearchStatus } from '@models/analysis';
import { NoMatchTable } from '@components/nmr-portal/NmrAnalysisResultTable/NoMatchTable';
import { NmrAnalysisCommentField } from './NmrAnalysisCommentField';
import { NMRAnalysisResultMatchType } from '@services/nmr';
import { useTranslation } from 'react-i18next';

export const NmrAnalysisMatchTable: FC = () => {
	const { t } = useTranslation('portal');

	const { analysisResultData } = useContext(AnalysisResultContext);
	const hasResultMatches = analysisResultData?.resultMatches && analysisResultData?.resultMatches.length > 0;
	const hasDrmResult = analysisResultData?.resultMatches?.some((r) => !r.isTheoretical);
	const hasTheoreticalResult = analysisResultData?.resultMatches?.some((r) => r.isTheoretical);

	const resultDrms = analysisResultData?.resultMatches?.filter((r) => !r.isTheoretical) ?? [];
	const resultTheoreticals = analysisResultData?.resultMatches?.filter((r) => r.isTheoretical) ?? [];
	const shouldShowRunTheoretical =
		hasResultMatches &&
		analysisResultData.isMatched() &&
		analysisResultData.theoreticalSearchStatus !== AnalysisResultTheoreticalSearchStatus.EXECUTED &&
		analysisResultData.theoreticalSearchStatus !== AnalysisResultTheoreticalSearchStatus.NON_EXECUTABLE;

	return analysisResultData && (analysisResultData.isExecutedSuccessful() || analysisResultData.isRejected()) ? (
		<GenericCard
			enableWidthControl={true}
			sx={{
				boxShadow: '0px 0px 1px rgba(15, 26, 46, 0.15), 0px 1px 4px rgba(15, 26, 46, 0.15)',
				border: 'none',
				display: analysisResultData.isQualitative() ? 'flex' : 'block',
				paddingX: 3,
				paddingY: 4,
			}}
		>
			<Typography variant="h3" sx={{ color: 'primary.main' }}>
				<Tr.Portal path="analysis-result.analysis-results" />
			</Typography>
			<NmrAnalysisTypeIndicator
				data-testid={`analysis-type-indicator`}
				sx={{ color: 'grey.800', marginTop: '0.6rem', fontWeight: 400, fontSize: '16px', marginBottom: '1.5rem' }}
				measurementType={analysisResultData.nmrAnalysis.measurementType}
				targetType={analysisResultData.nmrAnalysis.targetType}
			/>

			{hasDrmResult && (
				<>
					<NmrAnalysisResultTable resultMatches={resultDrms} isTheoretical={false} />
				</>
			)}

			{hasTheoreticalResult && (
				<>
					<NmrAnalysisResultTable resultMatches={resultTheoreticals} isTheoretical={true} />
				</>
			)}

			<NoMatchTable analysisResult={analysisResultData} />

			{hasTheoreticalResult && (
				<Stack>
					<Stack
						direction="column"
						marginTop="1rem"
						justifyContent="center"
						width={1}
						padding={2}
						borderRadius="12px"
						sx={{ backgroundColor: 'secondary.50' }}
						data-testid={`has-theoretical-result`}
					>
						<InfoMessageBox
							title={t('theoretical-spectrum-run.accuracy-message-title')}
							message={t('theoretical-spectrum-run.accuracy-message')}
						/>
					</Stack>
					{analysisResultData.isQualitative() &&
					!analysisResultData.isTargeted() &&
					analysisResultData.matchType === NMRAnalysisResultMatchType.ONLYTHEORETICALMATCH ? (
						<Stack direction="row" sx={{ marginTop: 2 }}>
							<Typography variant="pg-m" fontWeight={700} color="error.main">
								<Tr.Portal path="No Match: &nbsp;" />
							</Typography>
							<Typography variant="pg-m" color="grey.800" sx={{ fontWeight: 400 }}>
								<Tr.Portal path="analysis-result-table.non-targeted-below-50-part-1" />
							</Typography>
						</Stack>
					) : null}
				</Stack>
			)}

			{!analysisResultData.isMatched() && <NmrAnalysisCommentField />}

			{shouldShowRunTheoretical && <AnalysisResultRunTheoretical />}
		</GenericCard>
	) : null;
};
