import { SubscriptionDefaults, SubscriptionType } from '@models/subscribe';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getSubscriptionLimitationsSchema: (t: TFunction, subscriptionType: string) => yup.AnySchema = (t, subscriptionType) =>
	yup
		.object()
		.shape({
			endDate: yup.date().required(t('subscription.end-date-required')),
			budget:
				subscriptionType === SubscriptionType.FREE
					? yup
							.number()
							.typeError(t('subscription.number-of-analyses-is-invalid'))
							.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
							.min(1, t('subscription.number-of-analyses-min'))
							.max(SubscriptionDefaults.MAX_BUDGET, t('subscription.number-of-analyses-max'))
							.required(t('subscription.number-of-analyses-is-required'))
					: yup.number(),
		})
		.optional();
