import { PortalPageRoutes } from '@models/router';
import { TypeUtils } from '@utils/Type';
import { TFunction } from 'i18next';

export enum NmrAnalysisStage {
	NEW = 'New',
	DETAILS_FILLED = 'DetailsFilled',
	EXECUTING = 'Executing',
	EXECUTED = 'Executed',
	CONFIRMED_BY_LAB_MEMBER = 'ConfirmedByLabMember',
	APPROVED_BY_LAB_ADMIN = 'ApprovedByLabAdmin',
	REJECTED_BY_LAB_MEMBER = 'RejectedByLabMember',
	REJECTED_BY_LAB_ADMIN = 'RejectedByLabAdmin',
}

export const getNmrAnalysisRoute = (status: NmrAnalysisStage) => {
	switch (status) {
		case NmrAnalysisStage.NEW:
		case NmrAnalysisStage.DETAILS_FILLED:
			return PortalPageRoutes.ANALYSIS;
		case NmrAnalysisStage.EXECUTING:
		case NmrAnalysisStage.EXECUTED:
		case NmrAnalysisStage.CONFIRMED_BY_LAB_MEMBER:
		case NmrAnalysisStage.APPROVED_BY_LAB_ADMIN:
		case NmrAnalysisStage.REJECTED_BY_LAB_ADMIN:
		case NmrAnalysisStage.REJECTED_BY_LAB_MEMBER:
			return PortalPageRoutes.ANALYSIS_RESULT;
	}
};

type TStageConfig = {
	stageMessage: string;
	analysisRoute: PortalPageRoutes;
	stageIndex: 1;
	color: string;
};

const NMRAnalysisStageConfig = {
	[NmrAnalysisStage.NEW]: {
		stageMessage: 'analysis-status-message-new',
		analysisRoute: PortalPageRoutes.ANALYSIS,
		stageIndex: 1,
		color: 'secondary.main',
	},
	[NmrAnalysisStage.DETAILS_FILLED]: {
		stageMessage: 'analysis-status-message-details-filled',
		analysisRoute: PortalPageRoutes.ANALYSIS,
		stageIndex: 2,
		color: 'info.main',
	},
	[NmrAnalysisStage.EXECUTING]: {
		stageMessage: 'analysis-status-message-executing',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 3,
		color: 'info.800',
	},
	[NmrAnalysisStage.EXECUTED]: {
		stageMessage: 'analysis-status-message-executed',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 4,
		color: 'success.400',
	},
	[NmrAnalysisStage.CONFIRMED_BY_LAB_MEMBER]: {
		stageMessage: 'analysis-status-message-confirmed-by-operator',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 5,
		color: 'success.400',
	},
	[NmrAnalysisStage.APPROVED_BY_LAB_ADMIN]: {
		stageMessage: 'analysis-status-message-confirmed-by-lab-manager',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 6,
		color: 'success.400',
	},
	[NmrAnalysisStage.REJECTED_BY_LAB_MEMBER]: {
		stageMessage: '',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 0,
		color: 'error.main',
	},
	[NmrAnalysisStage.REJECTED_BY_LAB_ADMIN]: {
		stageMessage: '',
		analysisRoute: PortalPageRoutes.ANALYSIS_RESULT,
		stageIndex: 0,
		color: 'error.main',
	},
};
const getStageConfig = (stage: NmrAnalysisStage) => {
	return (
		TypeUtils.returnValueOfKey<typeof NMRAnalysisStageConfig, TStageConfig>(stage, NMRAnalysisStageConfig) ??
		NMRAnalysisStageConfig[NmrAnalysisStage.NEW]
	);
};

export const getNMRStageMessage = (stage: NmrAnalysisStage, t: TFunction) => {
	return t(getStageConfig(stage)?.stageMessage);
};

export const getNMRAnalysisRoute = (stage: NmrAnalysisStage) => {
	return getStageConfig(stage)?.analysisRoute;
};

export const getNMRAnalysisStageIndex = (stage: NmrAnalysisStage) => {
	return getStageConfig(stage)?.stageIndex;
};

export const getNMRStageColor = (stage: NmrAnalysisStage) => {
	return getStageConfig(stage)?.color;
};
