import { forwardRef } from 'react';
import { RouterUtils } from '@utils';
import { IRouterLinkButtonProps, RouterLinkButton } from '@components/common';

export interface ILeftSidebarItemProps extends Omit<IRouterLinkButtonProps, 'classes' | 'size'> {
	size?: number;
	active?: boolean;
}

export const LeftSidebarItem = forwardRef<HTMLButtonElement, ILeftSidebarItemProps>(
	({ href, size = 40, active, sx = {}, ...props }, ref) => {
		const isActive = active || RouterUtils.isLocationAndRouteMatched(href);
		return (
			<RouterLinkButton
				ref={ref}
				href={href}
				variant="text"
				{...props}
				sx={{
					padding: 0,
					margin: 1.5,
					borderRadius: 3,
					backgroundColor: isActive ? 'secondary.main' : 'transparent',
					color: 'text.primary',
					'&:hover': {
						backgroundColor: 'secondary.300',
					},
					...sx,
					minWidth: size,
					maxWidth: size,
					width: size,
					height: size,
				}}
			/>
		);
	},
);
