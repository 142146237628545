import { FC } from 'react';
import { ReadyWaveCard } from './ReadyWaveCard';
import { FailedWaveCard } from './FailedWaveCard';
import { ReleasedWaveCard } from './ReleasedWaveCard';
import { ReleasingWaveCard } from './ReleasingWaveCard';
import { InprogressWaveCard } from './InprogressWaveCard';
import { UnsupportedWaveCard } from './UnsupportedWaveCard';
import { IrDrmWave, IrDrmWaveStatus } from '@services/ir-drm-pipeline';

type TWaveDynamicCard = {
	wave: IrDrmWave;
	onUpdate: () => void;
};

export const WaveDynamicCard: FC<TWaveDynamicCard> = ({ wave, onUpdate }) => {
	const waveStatus = {
		[IrDrmWaveStatus.RELEASING]: <ReleasingWaveCard wave={wave} />,
		[IrDrmWaveStatus.PROCESSING]: <InprogressWaveCard wave={wave} />,
		[IrDrmWaveStatus.UPLOAD_COMPLETED]: <InprogressWaveCard wave={wave} />,
		[IrDrmWaveStatus.READY]: <ReadyWaveCard wave={wave} onUpdate={onUpdate} />,
		[IrDrmWaveStatus.RELEASED]: <ReleasedWaveCard wave={wave} onUpdate={onUpdate} />,
		[IrDrmWaveStatus.UPLOAD_FAILED]: <FailedWaveCard wave={wave} type="onUpload" onUpdate={onUpdate} />,
		[IrDrmWaveStatus.RELEASE_FAILED]: <FailedWaveCard wave={wave} type="onRelease" onUpdate={onUpdate} />,
	};

	const card = waveStatus[wave.status] || <UnsupportedWaveCard wave={wave} />;

	return card;
};
