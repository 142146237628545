import { FC } from 'react';
import { config } from '@config';
import { Grid } from '@mui/material';
import { DataTestId } from '@utils/DataTestId';
import { useService } from '@hooks';
import { IrAdminPermission } from '@models/user';
import { IrBulkFileUpload } from './BulkFileUpload';
import { HubConnection } from '@microsoft/signalr';
import { WaveDynamicCard } from './WaveDynamicCard';
import { useRestrictedComponent } from '@hooks';
import { IrDrmWave, irDrmWaveService } from '@services/ir-drm-pipeline';
import { useSignalRHubConnection } from '@hooks/common/useSignalRHubConnection';

export const IrPipelineBacklog: FC = () => {
	const { data: waves = [], trigger: getWaves } = useService(() => irDrmWaveService.getWaves(), []);

	useSignalRHubConnection({
		hubUrl: config.irWaveHubUrl,
		body: (connection: HubConnection) => {
			connection.send('CheckIrWaveStatus', 'backlogWaveGroup');
			connection.on('IrWaveUpdated', getWaves);
		},
		shouldStartConnection: () => waves.length > 0,
		deps: [waves],
	});

	return (
		<Grid container spacing={4} data-testid={DataTestId.getStaticTestId('drm-pipeline-backlog-wrapper-id')}>
			{waves.map((wave: IrDrmWave) => {
				return (
					<Grid key={wave.id} item xs={12} md={6} lg={4} xl={3}>
						<WaveDynamicCard wave={wave} onUpdate={getWaves} />
					</Grid>
				);
			})}
			{useRestrictedComponent(
				IrAdminPermission.UPLOAD_FILE,
				<Grid item xs={12} md={6} lg={4} xl={3}>
					<IrBulkFileUpload onUpdate={getWaves} />
				</Grid>,
			)}
		</Grid>
	);
};
