import { FormikApiType, FormikTextfield } from '@components/common';
import { Button, IconButton, Stack, SxProps, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tr } from '@utils/Translation';
import AddIcon from '@mui/icons-material/Add';
import { alertService } from '@services/core';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { CurrencySelector } from '../CurrencySelector';
import { AddTechnologyModal } from '../AddTechnologyModal';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import { DataTestId } from '@utils/DataTestId';
import { TypeUtils } from '@utils/Type';
import { FormUtils } from '@utils/Form';

interface IFormikTechnologyAddProps {
	formik?: FormikApiType;
}
const labelStyle: SxProps = {
	color: 'grey.800',
};
const textInputStyle: SxProps = {
	fieldset: {
		borderColor: 'grey.200',
		padding: 0,
		borderWidth: '1.5px',
	},
	input: {
		backgroundColor: 'grey.50',
	},
	'& .MuiFormHelperText-root.Mui-error': {
		position: 'absolute',
		top: '93%',
	},
};

export const FormikTechnologyAdd: FC<IFormikTechnologyAddProps> = ({ formik }) => {
	const { t } = useTranslation('admin');
	const [isAddModalOpened, setIsAddModalOpened] = useState<boolean>(false);

	const isDeleteDisabled = Object.keys(formik?.values.paidSubscription?.pricing).length === 1;
	// TO DO: get from backend
	const technologyList = [SupportedTechnology.NMR, SupportedTechnology.IR];

	return (
		<Stack width={1}>
			{formik?.values?.paidSubscription?.pricing &&
				Object.keys(formik?.values.paidSubscription?.pricing).map((technology, index) => (
					<Stack direction="row" key={`${technology}-stack`} width={1} marginBottom={3}>
						<Stack position="relative" width={1}>
							<Stack direction="row" justifyContent="space-between" alignItems="end" marginBottom={1.25}>
								<Typography variant="label-s" className="required" sx={{ ...labelStyle }}>
									{t('organization.technology-price', { technology: technology })}
								</Typography>
								<Typography variant="body3" color="grey.500">
									<Tr.Admin path="organization.price-per-analysis" />
								</Typography>
							</Stack>
							<FormikTextfield
								formikApi={formik}
								name={`paidSubscription.pricing.${technology}`}
								type="number"
								value={TypeUtils.returnValueOfKey(technology, formik?.values.paidSubscription.pricing ?? {})}
								placeholder={t(`organization.technology-price-placeholder`, { technology: technology })}
								sx={textInputStyle}
								onKeyDown={FormUtils.preventNonNumeric}
								InputProps={{
									endAdornment: <CurrencySelector formik={formik} />,
								}}
							/>
						</Stack>

						<IconButton
							sx={{
								height: 1,
								paddingX: '5px',
								marginTop: 3.25,
							}}
							disabled={isDeleteDisabled}
							onClick={() => {
								alertService.send({
									titleText: <Tr.Admin path="organization.confirmation" />,
									confirmTextHeader: t('organization.delete-technology-title', { technology: technology }),
									onConfirm: () => {
										const newTechs = formik.values.paidSubscription.pricing;
										delete newTechs[`${technology}`];
										formik?.setFieldValue('paidSubscription.pricing', newTechs);
									},
									onClose: () => formik?.values.pricing,
									content: t('organization.delete-technology-message', { technology: technology }),
									confirmTextComponent: <Tr.Admin path="organization.delete" />,
									closeTextComponent: <Tr.Admin path="organization.cancel" />,
								});
							}}
							data-testid={DataTestId.getStaticTestId(`delete-technology-${index}`)}
						>
							<Delete width={24} height={24} fill="#5E5E72" />
						</IconButton>
					</Stack>
				))}
			{Object.keys(formik?.values?.paidSubscription.pricing).length !== 2 && (
				<Button
					data-testid="add-technology-test-id"
					variant="text"
					sx={{ width: 'fit-content' }}
					onClick={() => setIsAddModalOpened(true)}
				>
					<AddIcon sx={{ fontSize: '20px' }} />
					<Typography
						variant="body2"
						fontWeight={700}
						lineHeight={'120%'}
						sx={{ paddingBottom: 0.2, paddingLeft: 0.5 }}
						color="primary.main"
					>
						<Tr.Admin path="organization.add-technology" />
					</Typography>
				</Button>
			)}

			{isAddModalOpened && (
				<AddTechnologyModal
					isOpen={isAddModalOpened}
					onCancel={() => setIsAddModalOpened(false)}
					onSave={(technology, price) => {
						formik?.setFieldValue('paidSubscription.pricing', {
							...(formik.values.paidSubscription.pricing ?? {}),
							[technology]: price,
						});
						setIsAddModalOpened(false);
					}}
					technologyOptions={technologyList.filter(
						(item) => !Object.keys(formik?.values.paidSubscription.pricing ?? []).includes(item),
					)}
					paidBudget={formik?.values?.paidSubscription.budget}
					currency={formik?.values?.paidSubscription.currency}
				/>
			)}
		</Stack>
	);
};
