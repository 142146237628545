import { FC } from 'react';
import { SxProps, TextFieldProps, TypographyProps } from '@mui/material';
import { FormikApiType, FormikAutoComplete } from '@components/common';
import { UserResponseModel } from '@models/user';

interface IUserListProps {
	name: string;
	onUserListChange: (data: { label?: Maybe<string>; email?: Maybe<string> }) => void;
	listDisabled?: boolean;
	formik: FormikApiType;
	typographyProps: TypographyProps;
	title: string;
	autoCompleteSx?: SxProps;
	userList?: UserResponseModel[];
}
export const UserList: FC<IUserListProps & TextFieldProps> = ({
	listDisabled,
	name,
	formik,
	onUserListChange,
	value,
	typographyProps,
	title,
	autoCompleteSx,
	userList = [],
	...params
}) => {
	return (
		<FormikAutoComplete
			data-testid="user-list-id"
			formik={formik}
			name={name}
			typographyProps={typographyProps}
			title={title}
			value={value}
			disabled={listDisabled}
			onSelectionChange={(newValue) =>
				onUserListChange({
					email: newValue,
				})
			}
			onInputChange={(newValue) => (!newValue || newValue.length === 0) && onUserListChange(newValue)}
			options={
				userList?.map((user) => {
					return user.email;
				}) || []
			}
			autoCompleteSx={autoCompleteSx}
			autocompleteProps={{
				disablePortal: true,
				isOptionEqualToValue: (option, val) => option === val,
			}}
			{...params}
		/>
	);
};
