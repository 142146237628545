/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { config } from '@config';
import { RxUtils } from '@utils/Rx';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { UpdateButtons } from './UpdateButtons';
import { HubConnection } from '@microsoft/signalr';
import { useDispatch, useSelector } from 'react-redux';
import { NmrAnalysisMatchTable } from './NmrAnalysisMatchTable';
import { labService } from '@services/core/lab/lab.service';
import { useService, useSignalRHubConnection } from '@hooks';
import { NmrAnalysisErrorSummary } from './NmrAnalysisErrorSummary';
import { NmrAnalysisResultFields } from './NmrAnalysisResultFields';
import { Lab, NmrAnalysisStage, isTheoreticalSpectraEnabled, nmrAnalysisService } from '@services';
import { AnalysisResultContext } from './analysis-result-context';
import { AnalysisMatchComparison } from './NmrAnalysisMatchComparison';
import { setBreadcrumb, userSelector } from '@store/slices/common/common.slice';
import { AnalysisResultTheoreticalSearchStatus } from '@models/analysis';
import { AnalysisHubMethods } from '@models';
import { AnalysisInProgressModal } from '@components/common';
import { ACDLogoFooter, NmrAlgorithmSummary, NmrAnalysisResultLabel, NmrAnalysisSetupSummary } from '@components/nmr-portal';
import { QuantitativeNonTargetedResultPage } from './QuantitativeNonTargetedResultPage';

export const NmrAnalysisResult: FC = () => {
	const { id = '' } = useParams<{ id: string }>();

	const user = useSelector(userSelector);
	const { data: analysisResultData, trigger: refreshResult } = useService(() => nmrAnalysisService.getResult(+id), []);
	const [isTheoreticalExecuting, setIsTheoreticalExecuting] = useState(false);
	const [theoreticalEnabled, setTheoreticalEnabled] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		document.querySelector('#layout-grid') && (document.querySelector('#layout-grid')!.scrollTop = 0);
	}, []);

	useEffect(() => {
		dispatch(
			setBreadcrumb({
				stage: analysisResultData?.nmrAnalysis?.stage,
				title: analysisResultData?.nmrAnalysis?.title,
				isCompleted: analysisResultData?.nmrAnalysis?.isCompleted,
			}),
		);

		setIsTheoreticalExecuting(analysisResultData?.theoreticalSearchStatus === AnalysisResultTheoreticalSearchStatus.EXECUTING);
		user?.currentLabId &&
			RxUtils.promisify(labService.get(+user.currentLabId), (data) => {
				setTheoreticalEnabled(isTheoreticalSpectraEnabled(data as Lab) ?? false);
			});
	}, [analysisResultData]);

	useSignalRHubConnection({
		hubUrl: config.analysisHubUrl,
		body: (connection: HubConnection) => {
			connection.send(AnalysisHubMethods.CHECK_ANALYSIS_RESULT, +id);
			connection.on(AnalysisHubMethods.ANALYSIS_COMPLETED, (stage: NmrAnalysisStage) => {
				refreshResult();
				if (stage !== NmrAnalysisStage.EXECUTING) {
					document.querySelector('#layout-grid') && (document.querySelector('#layout-grid')!.scrollTop = 0);
				}
			});
		},
		shouldStartConnection: () =>
			analysisResultData?.isExecuting() &&
			analysisResultData?.theoreticalSearchStatus !== AnalysisResultTheoreticalSearchStatus.EXECUTING,
		deps: [analysisResultData],
	});

	if (!analysisResultData) return null;

	return analysisResultData?.isExecuting() &&
		analysisResultData?.theoreticalSearchStatus !== AnalysisResultTheoreticalSearchStatus.EXECUTING ? (
		<AnalysisInProgressModal />
	) : (
		<AnalysisResultContext.Provider
			value={{
				analysisResultData: analysisResultData,
				triggerResult: () => refreshResult(),
				analysisId: +id,
				isTheoreticalExecuting,
				setIsTheoreticalExecuting,
				theoreticalEnabled,
				setTheoreticalEnabled,
			}}
		>
			{analysisResultData.isQuantitativeNonTargeted() ? (
				<QuantitativeNonTargetedResultPage />
			) : (
				<Grid data-testid="result-grid-wrapper-id" container sx={{ maxWidth: 'auto', marginX: 'auto' }} rowGap={2}>
					<Grid item md={6} xs={6} sm={6}>
						<NmrAnalysisResultLabel />
					</Grid>
					<Grid item md={6} xs={6} sm={6}>
						<UpdateButtons />
					</Grid>
					<Grid item md={12} xs={12} sm={12}>
						{id && <NmrAnalysisResultFields id={+id} />}
					</Grid>

					<Grid item md={12} xs={12} sm={12}>
						<NmrAnalysisErrorSummary />
					</Grid>
					<Grid item md={12} xs={12} sm={12}>
						<NmrAnalysisMatchTable />
					</Grid>
					<Grid item md={12} xs={12} sm={12}>
						<AnalysisMatchComparison />
					</Grid>
					<Grid item md={12} xs={12} sm={12}>
						<NmrAnalysisSetupSummary />
					</Grid>
					<Grid item md={12} xs={12} sm={12}>
						<NmrAlgorithmSummary
							measurementType={analysisResultData.nmrAnalysis.measurementType}
							targetType={analysisResultData.nmrAnalysis.targetType}
						/>
					</Grid>
					<Grid item md={12} xs={12} sm={12}>
						<ACDLogoFooter />
					</Grid>
				</Grid>
			)}
		</AnalysisResultContext.Provider>
	);
};
