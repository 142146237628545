import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { DialogProps, IconButton, IconButtonProps, Stack, Tooltip, Typography } from '@mui/material';
import { ModalDialog } from '../ModalDialog';
import ZoomIn from '@mui/icons-material/ZoomIn';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { DataTestId } from '@utils/DataTestId';

interface IZoomChildProps {
	hideZoomIcon?: boolean;
	onZoomChange?: (isBig: boolean) => void;
	infoMessage?: string;
	isTheoretical?: boolean;
	isZoomIconEnabled?: boolean;
	isDeactivated?: boolean;
	header?: string | ReactNode;
	zoomIconProps?: IconButtonProps & { 'data-testid': string | undefined | number };
	iconColor?: string;
}

export const ZoomChild: FC<Omit<DialogProps, 'open'> & IZoomChildProps> = ({
	children,
	hideZoomIcon = false,
	onZoomChange,
	sx,
	isTheoretical,
	infoMessage,
	isDeactivated,
	isZoomIconEnabled = true,
	header,
	zoomIconProps,
	iconColor,
	...props
}) => {
	const [showBigger, setShowBigger] = useState(false);

	useEffect(() => {
		onZoomChange?.(showBigger);
	}, [showBigger]);

	const getZoomIcon = () => {
		let color = 'inherit';
		if (isDeactivated) {
			color = 'grey.800';
		} else if (isTheoretical) {
			color = 'info.700';
		} else if (iconColor) {
			color = iconColor;
		}

		return !showBigger && !hideZoomIcon ? (
			<IconButton
				data-testid={DataTestId.getStaticTestId('zoom-icon-test-id')}
				sx={[
					{
						position: 'absolute',
						top: 4,
						right: 8,
						color: isZoomIconEnabled ? 'primary.main' : 'grey.400',
						zIndex: 1,
						width: '2.7rem',
						height: '2.7rem',
					},
				]}
				onClick={() => setShowBigger(true)}
				{...zoomIconProps}
			>
				<ZoomIn fontSize="small" color="inherit" sx={{ width: '1.7rem', height: '1.7rem', color }} />
			</IconButton>
		) : null;
	};

	const getInfoIcon = () =>
		!showBigger && infoMessage ? (
			<Tooltip placement="top" title={infoMessage}>
				<IconButton
					sx={[
						{ position: 'absolute', top: 4, right: 8, color: 'primary.main', zIndex: 1, width: '2.7rem', height: '2.7rem' },
						infoMessage ? { right: 'unset', left: 8 } : {},
					]}
				>
					<InfoOutlined sx={{ color: isDeactivated ? 'grey.400' : 'info.700' }} />
				</IconButton>
			</Tooltip>
		) : null;

	const getTitle = () => {
		if (!isTheoretical) return null;
		return (
			<Typography sx={{ top: 5, marginLeft: 'auto', marginRight: 'auto', color: 'grey.600', fontSize: '26px' }}>{header}</Typography>
		);
	};

	const BaseChildren: FC = () => (
		<Stack data-testid="zoom-child-wrapper-id" width={'100%'} sx={{ padding: '1rem', position: 'relative', ...sx }}>
			{getInfoIcon()}

			{getZoomIcon()}
			{header && getTitle()}
			<Stack sx={{ width: '100%', height: '100%', marginTop: 0.75, pointerEvents: !showBigger ? 'none' : 'initial' }}>
				{children}
			</Stack>
		</Stack>
	);
	return (
		<>
			{useMemo(
				() => (
					<BaseChildren />
				),
				[children],
			)}
			{showBigger && (
				<ModalDialog
					variant="primary"
					maxWidth="lg"
					{...props}
					onClose={() => setShowBigger(false)}
					open={showBigger}
					titleProps={{ color: 'primary.main' }}
				>
					<BaseChildren />
				</ModalDialog>
			)}
		</>
	);
};
