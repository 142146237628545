import { Type } from 'class-transformer';
import { IrDRM } from './ir-drm.class';

class IrAnalysisDrm extends IrDRM {
	isTargetSubstance: boolean;
}

export class IrSubstance {
	id: number;
	name: string;
	drmCode: string;
	casNumber: string;
	synonyms: string;
	linearFormula: string;
	smilesCode: string;
	molecularWeight: number;
	@Type(() => Date)
	createTime: Date;
	selectedReferenceMaterials?: IrAnalysisDrm[];
	referenceMaterials: IrDRM[];
	technology: string;
	organizationId?: null;
}
export interface IrSubstanceGetModel {
	query: string;
	pageIndex: number;
	pageSize: number;
	sortBy?: string;
	sortOrder?: string;
	isLatestUsed: boolean;
	qualityGrades: [number];
}

export interface IrSubstanceByLibraryGetModel {
	query: string;
	pageIndex: number;
	pageSize: number;
	sortBy?: string;
	sortOrder?: string;
	isLatestUsed: boolean;
	libraries: [];
	qualityGrades: [number];
}
