import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { formFieldSpacer, mainTitleSpacer, RxUtils, Tr } from '@utils';

import { Stack, Typography } from '@mui/material';
import { UserReferenceModel } from '@services/ir/ir-user-reference';
import { getUserReferenceValidationSchema } from '@schemas/ir/user-reference';
import { useNavigate, useParams } from 'react-router-dom';
import { alertService } from '@services/core';
import { useEffect, useState } from 'react';
import { PortalPageRoutes } from '@models/router';
import { UserReferencesAsyncModal } from '@components/common/UserReferencesAsyncModal';
import { Button } from '@mui/material';
import { DeactivationConfirmPopUp } from '@components/common';
import { irDrmService } from '@services/ir';
import { ReferenceStatusCode, UserReferenceStatus } from '@components/common/UserReferenceStatus';
import { CreateRefSaveFields, UserReferenceFormLayout } from '../IrReferenceLayout';
import { IUploadedFile } from '@models/file';
import { useIrCustomReferenceHub } from '@hooks/ir/useIrCustomerReference';

export type FormFields = Extendable<UserReferenceModel>;

export const IrEditUserReference = () => {
	const { id = '', status, drmCode } = useParams<{ id: string; drmCode: string; status: string }>();
	const { t } = useTranslation('irportal');
	const navigate = useNavigate();

	const [isDeactivationConfirmVisible, setDeactivationConfirmVisible] = useState(false);
	const [userReferenceData, setUserReferenceData] = useState<UserReferenceModel>();
	const [showUpdateInProgressModal, setShowUpdateInProgressModal] = useState(false);
	const [showDeactivateInProgressModal, setShowDeactivateInProgressModal] = useState(false);
	const [showReactivateInProgressModal, setShowReactivateInProgressModal] = useState(false);
	const [uploadedFile, setUploadedFile] = useState<IUploadedFile | null>(null);
	const [loadSubstance, setSubstanceAdded] = useState(false);
	const [fileReUploaded, setFileReUploaded] = useState(false);

	const formik = useFormik<FormFields>({
		initialValues: {
			isEditing: true,
			...userReferenceData,
		},
		validationSchema: getUserReferenceValidationSchema(t, true),
		onSubmit: () => {
			const referenceMaterialId = userReferenceData?.id;
			if (referenceMaterialId) {
				RxUtils.promisify(
					irDrmService.updateUserReference({
						id: userReferenceData?.id,
						resolution: formik?.values?.resolution,
						firstX: formik?.values?.firstX,
						lastX: formik?.values?.lastX,
						numberOfScans: formik?.values?.numberOfScans,
						physicalProductBatchNumber: formik?.values?.physicalProductBatchNumber,
						irReferenceMaterialLibraryId: formik?.values?.irReferenceMaterialLibraryId,
						name: formik.values.name,
						status: UserReferenceStatus.InProgress,
						generalComment: formik?.values?.generalComment,
						molecularWeight: formik?.values?.molecularWeight,
						casNumber: formik?.values?.casNumber,
						linearFormula: formik?.values?.linearFormula,
						synonyms: formik?.values?.synonyms,
						smilesCode: formik?.values?.smilesCode,
						samplingTechnique: formik?.values?.samplingTechnique,
					}),
					() => {
						setShowUpdateInProgressModal(true);
					},
				);
			}
		},
	});

	useEffect(() => {
		if (status === UserReferenceStatus.InProgress) {
			setShowUpdateInProgressModal(true);
		} else if (status === UserReferenceStatus.Reactivating) {
			setShowReactivateInProgressModal(true);
		} else if (status === UserReferenceStatus.Deactivating) {
			setShowDeactivateInProgressModal(true);
		}
		const body = { drmCode: drmCode };
		RxUtils.promisify(irDrmService.createUserReference(body), (res) => {
			if (res?.id) {
				RxUtils.promisify(irDrmService.fetchUserReference(res?.id), (details) => {
					const newReferenceMaterialDetails = {
						...details,
						irReferenceMaterialLibraryId: details?.libraryId?.toString() || '',
					};
					setUserReferenceData(newReferenceMaterialDetails);
					formik.setValues(newReferenceMaterialDetails);
				});
			}
		});
	}, []);

	useEffect(() => {
		formik.validateForm();
	}, [formik.values]);

	const actionName = showDeactivateInProgressModal ? 'Deactivating' : 'Reactivating';
	const message = showDeactivateInProgressModal ? 'deactivated' : 'reactivated';
	const successPlaceHolder = showDeactivateInProgressModal ? 'deactivation' : 'reactivation';
	const updateActionName = 'Updating';
	const updateModalMessage = 'updated';
	const updateSuccessPlaceHolder = 'update';
	const materialIdForHub = showUpdateInProgressModal ? +formik.values.id : +id;
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;

	useIrCustomReferenceHub(
		showUpdateInProgressModal || showDeactivateInProgressModal || showReactivateInProgressModal,
		[showUpdateInProgressModal, showDeactivateInProgressModal, showReactivateInProgressModal],
		materialIdForHub,
	);

	function handleSubstanceAdded() {
		setSubstanceAdded(true);
	}

	function handleUploadFile(data) {
		setUploadedFile(data);
		setFileReUploaded(true);
	}

	return (
		<>
			<Stack data-testid="edit-user-reference" spacing={formFieldSpacer}>
				<form onSubmit={formik.handleSubmit} data-testid="edit-reference-form-test-id">
					<Stack direction="row" alignItems="center" spacing={mainTitleSpacer} justifyContent="space-between">
						<Typography variant="h1" sx={{ color: disableRmInActiveFields ? 'grey.400' : 'text.secondary' }}>
							<Tr.IrPortal path="user-references.edit-reference" />
						</Typography>
						<CreateRefSaveFields
							uploadedFile={uploadedFile}
							formik={formik}
							editing={true}
							loadSubstance={loadSubstance}
							fileReuploaded={fileReUploaded}
						/>
					</Stack>
					<UserReferenceFormLayout
						formik={formik}
						editing={true}
						referenceFileId={formik?.values?.id}
						uploadedFile={uploadedFile}
						setUploadedFile={handleUploadFile}
						substanceAdded={handleSubstanceAdded}
					/>
				</form>
				<Stack direction="row">
					<Button
						data-testid="deactivate-button-id"
						variant="text"
						disableElevation
						color={status === UserReferenceStatus.Active ? 'error' : 'primary'}
						onClick={() =>
							status === UserReferenceStatus.Active
								? setDeactivationConfirmVisible(true)
								: alertService.send({
										confirmTextHeader: <Tr.IrPortal path="user-references.reactiate-modal-reference-title" />,
										titleText: <Tr.IrPortal path={'user-references.confirmation'} />,
										confirmTextComponent: <Tr.IrPortal path={'user-references.reactivate'} />,
										closeTextComponent: <Tr.IrPortal path="user-references.cancel" />,
										onConfirm: () =>
											RxUtils.promisify(
												irDrmService.changeUserReferenceStatus(
													+id,
													ReferenceStatusCode.Activating,
													UserReferenceStatus.Active,
												),
												() => {
													setShowReactivateInProgressModal(true);
												},
											),
										content: <Tr.IrPortal path="user-references.reactivate-reference-message" />,
									})
						}
					>
						<Tr.IrPortal
							path={
								status === UserReferenceStatus.Active
									? 'user-references.deactivate-reference'
									: 'user-references.reactivate'
							}
						/>
					</Button>
				</Stack>
				{showUpdateInProgressModal ? (
					<UserReferencesAsyncModal
						title={t('user-references.async-updates-modal.title', {
							actionName: updateActionName,
						})}
						message={t('user-references.async-updates-modal.message', {
							actionName: updateActionName,
							message: updateModalMessage,
							successPlaceHolder: updateSuccessPlaceHolder,
						})}
						subtitle={t('user-references.async-updates-modal.subtitle', {
							actionName: updateActionName,
						})}
						onClose={() => {
							navigate(
								`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
							);
						}}
					/>
				) : null}

				{showDeactivateInProgressModal || showReactivateInProgressModal ? (
					<UserReferencesAsyncModal
						title={t('user-references.async-updates-modal.title', {
							actionName: actionName,
						})}
						message={t('user-references.async-updates-modal.message', {
							message: message,
							successPlaceHolder: successPlaceHolder,
						})}
						subtitle={t('user-references.async-updates-modal.subtitle', { actionName: actionName })}
						onClose={() => {
							navigate(
								`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
							);
						}}
					/>
				) : null}
			</Stack>

			<DeactivationConfirmPopUp
				title={<Tr.IrPortal path={'user-references.confirmation'} />}
				subTitle={
					<>
						<Stack>
							<Typography variant="h3" lineHeight="120%">
								<Tr.IrPortal path="user-references.deactivate-reference-modal-title" />
							</Typography>
						</Stack>
						<Stack>
							<Typography variant="pg-m">{t('user-references.deactivate-reference-message')}</Typography>
						</Stack>
					</>
				}
				message={t('user-references.deactivate-reference-comment')}
				visible={isDeactivationConfirmVisible}
				onConfirm={(comment) => {
					RxUtils.promisify(irDrmService.changeUserReferenceStatus(+id, ReferenceStatusCode.Deactivating, comment));
					setDeactivationConfirmVisible(false);
					setShowDeactivateInProgressModal(true);
				}}
				onCancel={() => setDeactivationConfirmVisible(false)}
			/>
		</>
	);
};
