import { FC } from 'react';
import { MenuItem, Stack } from '@mui/material';
import { FormikApiType, FormikTextfield } from '@components/common';
import { accessoryType } from '@models/analysis';

interface ISolventListType {
	name: string;
	formik: FormikApiType;
	title?: string;
	required?: boolean;
	typographyProps?: any;
}

export const MeasurementTypeListForIr: FC<ISolventListType> = ({ name, typographyProps, formik }) => {
	return (
		<Stack direction="column" sx={{ marginBottom: 1 }}>
			<FormikTextfield
				formikApi={formik}
				name={name}
				select
				required={false}
				value={formik.values[`${name}`]}
				onChange={(e) => formik.setFieldValue(name, e.target.value)}
				placeholder="Type"
				variant="outlined"
				typographyProps={typographyProps}
				fullWidth
				type="number"
				sx={{
					backgroundColor: 'grey.50',
					'& .MuiOutlinedInput-root': {
						height: '40px',
					},
				}}
			>
				{accessoryType.map((item, index) => (
					<MenuItem key={`index-${index}}`} value={item.id} sx={{ height: '40px' }}>
						{item.name}
					</MenuItem>
				))}
			</FormikTextfield>
		</Stack>
	);
};
