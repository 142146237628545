import { IrAnalysisResult, IrImpurityPeak, IrPurityCheckPeakjson } from '@services';
import { createContext } from 'react';

interface IrAnalysisResultContext {
	analysisResultData: Maybe<IrAnalysisResult>;
	triggerResult: () => void;
	analysisId: Maybe<number>;
	peakData?: Maybe<IrPurityCheckPeakjson[]>;
	impurityPeakData?: Maybe<IrImpurityPeak[]>;
}

export const IrAnalysisResultContext = createContext<IrAnalysisResultContext>({
	analysisResultData: null,
	triggerResult: () => undefined,
	analysisId: null,
	peakData: null,
	impurityPeakData: null,
});
