import { Button, Stack, SxProps, Tab, Tabs, TabsProps } from '@mui/material';
import { Tr } from '@utils/Translation/Tr';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

export enum ErmSearchTabValues {
	All = 'all',
	Recent = 'recent',
	DrmSelectedLibrary = 'drmselected',
}

export const IrDrmTabs: FC<TabsProps> = ({ value, onChange }) => {
	const location = useLocation();
	const getStyle = (tab: ErmSearchTabValues) => {
		let style: SxProps = {
			borderRadius: tab === ErmSearchTabValues.Recent ? '4px 0px 0px 4px' : '0px 4px 4px 0px',
			border: '2px solid #01884C',
			boxShadow: 'none',
			'&:hover': { backgroundColor: 'transparent' },
		};

		if (tab !== value) {
			style = {
				backgroundColor: 'white',
				color: '#01884C',
				...style,
			};
		} else {
			style = {
				pointerEvents: 'none',
				...style,
			};
		}

		return style;
	};
	return (
		<>
			<Stack direction="row">
				<Tabs
					TabIndicatorProps={{ style: { display: 'none' } }}
					textColor="primary"
					value={value}
					data-testid="drm-tabs"
					sx={{ '&:before': { content: 'unset' }, marginBottom: 5 }}
				>
					<Tab value={ErmSearchTabValues.Recent} sx={{ display: 'none' }} />
					<Tab value={ErmSearchTabValues.All} sx={{ display: 'none' }} />
					<Tab value={null} sx={{ display: 'none' }} />
				</Tabs>
				<Button
					data-testid="erm-tab-id"
					variant="contained"
					disableElevation
					onClick={(_) => value !== ErmSearchTabValues.Recent && onChange?.(_, ErmSearchTabValues.Recent)}
					sx={getStyle(ErmSearchTabValues.Recent)}
				>
					<Tr.IrPortal path="search-references.latest-substance-tab-title" />
				</Button>
				{location.pathname.includes('search') && (
					<Button
						data-testid="rm-tab-id"
						variant="contained"
						disableElevation
						onClick={(_) => value !== ErmSearchTabValues.All && onChange?.(_, ErmSearchTabValues.All)}
						sx={getStyle(ErmSearchTabValues.All)}
					>
						<Tr.IrPortal path="search-references.all-substances" />
					</Button>
				)}
				{!location.pathname.includes('search') && (
					<Button
						data-testid="drmLibrary-tab-id"
						variant="contained"
						disableElevation
						onClick={(_) =>
							value !== ErmSearchTabValues.DrmSelectedLibrary && onChange?.(_, ErmSearchTabValues.DrmSelectedLibrary)
						}
						sx={getStyle(ErmSearchTabValues.DrmSelectedLibrary)}
					>
						<Tr.IrPortal path="search-references.drm-selected-libraries" />
					</Button>
				)}
			</Stack>
		</>
	);
};
