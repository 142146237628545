import { Button, Stack, Typography, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import { Tr } from '@utils';
import { ModalDialog } from '../ModalDialog';
import { ExtendedComponent } from 'src/types';

interface IConfirmPopupProps {
	visible?: boolean;
	confirmTextComponent?: ReactNode;
	closeTextComponent?: ReactNode;
	titleText?: ReactNode;
	confirmTextHeader?: ReactNode;
	onClose?: () => void;
	onConfirm?: () => void;
	hideCloseIcon?: boolean;
	confirmButtonProps?: ButtonProps;
}

export const ConfirmPopup: ExtendedComponent<IConfirmPopupProps> = ({
	visible,
	titleText,
	confirmTextComponent,
	closeTextComponent,
	onClose,
	children,
	onConfirm,
	confirmTextHeader,
	confirmButtonProps,
}) => {
	return titleText ? (
		<ModalDialog
			variant="primary"
			fullWidth={false}
			open={!!visible}
			title={
				<>
					<Typography variant="pg-s" sx={{ fontStyle: 'normal' }}>
						{' '}
						{titleText}
					</Typography>
				</>
			}
			titleProps={{
				sx: {
					justifyContent: 'space-between',
					marginTop: 0,
					marginBottom: 5,
					minHeight: 30,
					backgroundColor: 'grey.50',
					paddingY: 1.2,
					paddingX: 2,
					height: 40,
				},
			}}
			onClose={onClose}
			maxWidth="sm"
			contentProps={{ sx: { paddingX: 8, paddingBottom: 7, width: 472, alignItems: 'center', justifyContent: 'center' } }}
		>
			<Typography
				data-testid="confirm-popup-test-id"
				variant="subtitle1"
				sx={{ textAlign: 'center', color: 'grey.900', marginBottom: 3 }}
			>
				{confirmTextHeader ?? titleText}
			</Typography>
			<Typography
				variant="pg-m"
				sx={{ textAlign: 'center', display: 'block', color: 'grey.900', paddingX: 0.5, marginBottom: 3.5, width: '100%' }}
			>
				{children || <Tr.Common path="confirm-message" />}
			</Typography>
			<Stack direction="row" spacing={3} width={1} sx={{ marginTop: 3.5 }}>
				<Button variant="outlined" disableElevation data-testid="alert-cancel-id" fullWidth onClick={onClose}>
					{closeTextComponent || <Tr.Portal path="cancel" />}
				</Button>
				<Button
					variant="contained"
					disableElevation
					fullWidth
					data-testid="alert-confirm-id"
					onClick={onConfirm}
					{...confirmButtonProps}
				>
					{confirmTextComponent || <Tr.Portal path="confirm" />}
				</Button>
			</Stack>
		</ModalDialog>
	) : null;
};
