import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { IrDrmWave } from './ir-drm-wave.class';
import { IrDrmWaveStatus, IrWaveReportType } from './ir-drm-wave.enum';
import { httpService, logger } from '@services/core';
import { Observable, concatMap, map, tap } from 'rxjs';
import { irDrmPipelineConfig } from '../irDrmPipelineConfig';
import { PagedResult } from '@models/request-response';
import { TIrWaveReportLinkResponse } from './ir-drm-wave.interface';

class IrDrmWaveService {
	private getUrl() {
		return `${irDrmPipelineConfig.url}/Wave`;
	}

	getWaves(): Observable<IrDrmWave[]> {
		return httpService.get<IrDrmWave[]>(this.getUrl(), '', { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getWaves.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrDrmWave, result)),
		);
	}

	getAllWithParams(params: URLSearchParams) {
		return httpService
			.get<PagedResult<IrDrmWave>>(`${this.getUrl()}`, 'reports', {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAllWithParams.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrDrmWave>(IrDrmWave), result)),
			);
	}

	getReportLinkById(id: number, reportType: IrWaveReportType) {
		return httpService
			.get<TIrWaveReportLinkResponse>(`${this.getUrl()}`, `${id}/report`, {
				params: {
					reportType: reportType,
					errorHandler: 'notification',
				},
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getReportLinkById.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	createWave(name: string): Observable<IrDrmWave> {
		return httpService.post(this.getUrl(), '', { body: { name }, disableLoader: true }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.createWave.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrDrmWave, result)),
		);
	}

	updateWaveStatus(waveId: number, status: IrDrmWaveStatus) {
		return httpService
			.patch(this.getUrl(), `${waveId}`, {
				body: [
					{
						path: '/Status',
						op: 'replace',
						value: status,
					},
				],
				disableLoader: true,
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.updateWaveStatus.name}]`, result)));
	}

	uploadFile(waveId: number, file: File) {
		const formData = new FormData();
		formData.append('File', file);
		return httpService
			.post(this.getUrl(), `/${waveId}/files`, {
				body: formData,
				disableLoader: true,
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.uploadFile.name}]`, result)));
	}

	deleteWave(waveId: number) {
		return httpService
			.delete(this.getUrl(), `/${waveId}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.deleteWave.name}]`, result)));
	}
}

export const irDrmWaveService = new IrDrmWaveService();
