import { FC, useContext, useEffect } from 'react';
import { Collapse, Tab, Tabs, Typography } from '@mui/material';

import { Tr } from '@utils';
import { IrReferenceMaterialResultView } from '@components/ir-portal';
import { GenericCard } from '@components/common';
import { IrAnalysisResultContext } from './Iranalysis-result-context';
import { AnalysisCommentField } from './IrAnalysisCommentField';
import { IrResultImpurites, RankBasedMixtureData, RmSpectrumInfoPurity, SampleSpectrumInfo, SampleSpectrumInfoPurity } from '@services/ir';

interface IrAnalysisMatchComparisonProps {
	rankedRMData: RankBasedMixtureData[];
	userSpectrumInfo: SampleSpectrumInfo;
	rmSpectrumInfoPurityCheck: RmSpectrumInfoPurity;
	userSpectrumInfoPurityCheck: SampleSpectrumInfoPurity;
	setIndexOnTabChange: (newValue: number) => void;
	resultImpurities?: IrResultImpurites[];
	analysisResultSelectedOptionIndex: number;
	selectedRMIndex: number;
}

export const AnalysisMatchComparison: FC<IrAnalysisMatchComparisonProps> = ({
	rankedRMData,
	userSpectrumInfo,
	rmSpectrumInfoPurityCheck,
	userSpectrumInfoPurityCheck,
	setIndexOnTabChange,
	resultImpurities,
	analysisResultSelectedOptionIndex,
	selectedRMIndex,
}) => {
	const { analysisResultData } = useContext(IrAnalysisResultContext);

	const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setIndexOnTabChange(newValue);
	};

	if (!analysisResultData || analysisResultData.isError() || !analysisResultData.isMatched()) return null;

	useEffect(() => {
		setIndexOnTabChange(analysisResultSelectedOptionIndex);
	}, [analysisResultSelectedOptionIndex]);

	return (
		<GenericCard
			sx={{
				paddingX: 3,
				paddingY: 4,
			}}
		>
			{rankedRMData &&
			(analysisResultData.isExecutedSuccessful() || analysisResultData.isRejected()) &&
			analysisResultData.isIdentification() ? (
				<Tabs value={selectedRMIndex} onChange={onTabChange} style={{ marginBottom: '32.5px' }}>
					{rankedRMData.map((data, index) => (
						<Tab
							key={`index-${index}-drmId-rank-${data.rank}`}
							data-testid={`drm-test-id-${index}`}
							label={
								analysisResultData.irAnalysis.includeMixture === true
									? `Mixture - ${data.rank}`
									: `${data.mixtures[0].substanceName}`
							}
							value={index}
							sx={{ color: 'text.primary', fontWeight: 700 }}
						/>
					))}
				</Tabs>
			) : null}

			{rankedRMData && (analysisResultData.isExecutedSuccessful() || analysisResultData.isRejected()) && (
				<Typography variant="h3" sx={{ color: 'primary.main' }}>
					<Tr.IrPortal path="analysis-result.spectrum-comparison" />
				</Typography>
			)}

			{rankedRMData && (analysisResultData.isExecutedSuccessful() || analysisResultData.isRejected()) && (
				<>
					{rankedRMData.map((_, i) => (
						<Collapse in={selectedRMIndex === i} key={i}>
							<IrReferenceMaterialResultView
								hasSubtitle={true}
								containerSx={{ marginTop: '-2rem' }}
								rankedRMData={rankedRMData[`${i}`]}
								resultData={analysisResultData}
								userSpectrumInfo={userSpectrumInfo}
								userSpectrumInfoPurityCheck={userSpectrumInfoPurityCheck}
								rmSpectrumInfoPurityCheck={rmSpectrumInfoPurityCheck}
								resultImpurities={resultImpurities}
							/>
						</Collapse>
					))}
				</>
			)}

			<AnalysisCommentField />
		</GenericCard>
	);
};
