import { UserRole } from '@models/user';
import { MenuItem, Select, SelectProps, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Tr } from '@utils';

type TSelectRoleProps = SelectProps;

export const SelectRole: FC<TSelectRoleProps> = ({ value, title, name, onChange, sx, ...props }) => {
	return (
		<Stack direction="column" sx={{ marginBottom: 1 }}>
			<Typography variant="label-s" sx={{ marginBottom: 1.25, color: 'grey.200' }}>
				{title}
			</Typography>
			<Select
				multiple={true}
				value={value}
				title={title}
				name={name}
				onChange={onChange}
				sx={{ background: '#F8F8FC !important', height: '40px', ...sx }}
				disabled={true}
				{...props}
			>
				<MenuItem value={UserRole.LAB_MEMBER}>
					<Tr.Portal path={`user-management.roles.${UserRole.LAB_MEMBER}`} />
				</MenuItem>
				<MenuItem value={UserRole.LAB_ADMIN}>
					<Tr.Portal path={`user-management.roles.${UserRole.LAB_ADMIN}`} />
				</MenuItem>
				<MenuItem value={UserRole.ORG_ADMIN}>
					<Tr.Portal path={`user-management.roles.${UserRole.ORG_ADMIN}`} />
				</MenuItem>
			</Select>
		</Stack>
	);
};
