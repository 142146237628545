import { useMemoizedTestId } from '@hooks';
import Star from '@mui/icons-material/Star';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type TReferenceMaterialFavorite = {
	onClick: () => void;
	isFavorite: boolean;
	iconButtonProps?: IconButtonProps;
};

const iconProps = {
	width: 24,
	height: 24,
	sx: {
		color: 'warning.main',
	},
};

export const ReferenceMaterialFavorite: FC<TReferenceMaterialFavorite> = ({ onClick, isFavorite, ...props }) => {
	const { t } = useTranslation('common');
	const tooltipTitle = t(isFavorite ? 'remove-from-favorites' : 'add-to-favorites');
	return (
		<Tooltip title={tooltipTitle}>
			<IconButton
				color="primary"
				onClick={onClick}
				sx={{ transform: 'translateY(-1px)' }}
				data-testid={useMemoizedTestId('star-fav-test')}
				{...props}
			>
				{isFavorite ? <Star {...iconProps} /> : <StarBorderOutlined {...iconProps} />}
			</IconButton>
		</Tooltip>
	);
};
