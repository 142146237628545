import { Stack, StackProps, SxProps, Typography, TypographyProps } from '@mui/material';
import { DataTestId } from '@utils/DataTestId';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { FC, ReactNode } from 'react';

type TEmptyAdditionalActions = {
	title: string | ReactNode;
	explanation: string | ReactNode;
	titleProps?: TypographyProps;
	explanationProps?: TypographyProps;
	iconSx?: SxProps;
};
export const EmptyAdditionalActions: FC<TEmptyAdditionalActions & Partial<Omit<StackProps, 'title'>>> = ({
	title,
	explanation,
	titleProps,
	explanationProps,
	iconSx,
	...props
}) => {
	return (
		<Stack
			sx={{
				alignItems: 'center',
				height: '454px',
				paddingTop: '56px',
			}}
			{...props}
		>
			<Stack
				sx={{
					width: 80,
					height: 80,
					borderRadius: '50%',
					backgroundColor: 'grey.100',
					justifyContent: 'center',
					alignItems: 'center',
					marginBottom: 3,
				}}
				data-testid={DataTestId.getStaticTestId('empty-comment-id')}
			>
				<ChatBubbleOutlineIcon sx={{ width: 40, height: 40, color: 'grey.400', ...iconSx }} />
			</Stack>
			<Typography variant="h3" color="grey.800" marginBottom={1} {...titleProps}>
				{title}
			</Typography>
			<Typography variant="pg-m" color="grey.800" {...explanationProps}>
				{explanation}
			</Typography>
		</Stack>
	);
};
