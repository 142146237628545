import { FC, useEffect } from 'react';
import { MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield } from '@components/common';
import { useTranslation } from 'react-i18next';
import { AnalysisTargetType, QualitativeOrQuantitative, Solvents } from '@models/analysis';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface ISolventListType {
	name: string;
	formik: FormikApiType;
	title?: string;
	required?: boolean;
	typographyProps?: any;
	disabled?: boolean;
}

export const NmrSolventList: FC<ISolventListType> = ({ name, title, required, typographyProps, formik, disabled = false }) => {
	const { t } = useTranslation('portal');

	useEffect(() => {
		const solventValue = formik?.values?.[`${name}`] ? formik?.values?.[`${name}`]?.toUpperCase() : null;
		const solventConstant = Solvents.find((solvent) => solvent.names.includes(solventValue));

		if (solventValue && !solventConstant) {
			!formik.errors[`${name}`] && formik.setFieldError(`${name}`, t('new-analysis.solvent-not-list', { solvent: solventValue }));
		}

		if (solventConstant && solventConstant.code.toUpperCase() !== solventValue) {
			formik.setFieldValue(`${name}`, solventConstant.code);
		}
	}, [formik]);
	// TO DO: USE DYNAMIC SELECT
	return (
		<Stack direction="column" sx={{ marginBottom: 1 }}>
			<FormikTextfield
				formikApi={formik}
				name={name}
				select
				required={false}
				value={formik.values[`${name}`] === '' ? undefined : formik.values[`${name}`]}
				onChange={(e) => formik.setFieldValue(name, e.target.value)}
				placeholder="solvent"
				title={
					title ?? (
						<Stack direction="row" justifyContent="space-between">
							<Typography
								variant="label-s"
								className={required && !disabled ? 'required' : ''}
								color={disabled ? 'grey.200' : 'grey.800'}
								display="inline"
							>
								{t('new-analysis.solvent')}
							</Typography>{' '}
							{formik.values.solvent &&
								formik.values.measurementType === QualitativeOrQuantitative.QUANTITATIVE &&
								formik.values.targetType !== AnalysisTargetType.NON_TARGETED && (
									<Tooltip title={t('new-analysis.solvent-selection-dmso-warning')}>
										<Stack>
											<InfoOutlinedIcon sx={{ height: '16.67px', color: 'grey.800' }} />
										</Stack>
									</Tooltip>
								)}
						</Stack>
					)
				}
				variant="outlined"
				typographyProps={typographyProps}
				fullWidth
				type="number"
				sx={{
					backgroundColor: 'grey.50',
					'& .MuiOutlinedInput-root': {
						height: '40px',
					},
				}}
				SelectProps={{
					IconComponent: (props) => <KeyboardArrowDownIcon {...props} color="#ff11ff"></KeyboardArrowDownIcon>,
					sx: {
						'& svg': {
							color: 'primary.main',
						},
					},
				}}
				inputProps={{
					'data-testid': 'preffered-solvent-id',
				}}
				disabled={disabled}
			>
				{Solvents.map((solvent, index) => (
					<MenuItem key={`index-${index}}`} value={solvent.code} sx={{ height: '40px' }}>
						{solvent.code}
					</MenuItem>
				))}
			</FormikTextfield>
		</Stack>
	);
};
