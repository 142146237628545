import { IDrmSpectrumDataType } from '@components/ir-portal';
import { useState } from 'react';

export interface IrDRMDetailState {
	visible: boolean;
	drmId: number | null;
	actionLabel?: string;
	onHandleAction?: (drmId: string | number) => void;
	actionDisabled?: boolean;
	drmSpectrumData?: IDrmSpectrumDataType[];
}

const initialIrDRMDetail = {
	visible: false,
	drmId: null,
	actionLabel: '',
	actionDisabled: false,
	onHandleAction: () => null,
	drmSpectrumData: [],
};

export const useIrDRMDetail = () => {
	const [drmDetail, setIrDRMDetail] = useState<IrDRMDetailState>(initialIrDRMDetail);

	function resetIrDetail() {
		setIrDRMDetail(initialIrDRMDetail);
	}

	return { ...drmDetail, setIrDRMDetail, resetIrDetail };
};
