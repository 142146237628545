import { createContext } from 'react';
import { IrProps } from './props';
export * from './irprovider';
export * from './props';

export const IrAnalysisContext = createContext<IrProps>({
	addedERMs: [],
	updateAddedERMs: () => null,
	quantitativeStep: null,
	updateQuantitativeStep: () => null,
	irDevice: null,
	updateIrDevice: () => null,
	measurementTypeIr: null,
	analysisType: null,
	updateMeasurementType: () => null,
	updateAnalysisType: () => null,
	irAccessory: null,
	updateIrAccessory: () => null,
	metaData: null,
	updateMetaData: () => null,
	isAnalysisEditable: null,
	updateAnalysisEditable: () => null,
	updateSelectedLibrary: () => null,
	selectedLibrary: [],
	isPeaksChanged: null,
	updatePeaksChanged: () => null,
});
