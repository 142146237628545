import { Type } from 'class-transformer';
import { NmrCalibrationStatus } from './nmr-device-status.enum';
import { AnalysisTechnology } from '@services/core';

export class NmrDeviceCalibration {
	id: number;
	name: string;
	nmrDeviceId: number;
	status: NmrCalibrationStatus;
	manufacturer: string;
	frequency: string;
	linearity: number;
	solvent?: string;
	intercept?: number;
	standardDeviation?: number;
	calibrationErrorMessage?: string;
	receiverGainThresholds: {
		min: number;
		max: number;
	};
	@Type(() => Date)
	createTime: Date;
	@Type(() => Date)
	nextCalibrationTime: Date;
	@Type(() => Date)
	lastCalibrationTime: Date;
	@Type(() => Date)
	calibrationDeactivationTime: Date;

	public isActive() {
		return this.status === NmrCalibrationStatus.ACTIVE;
	}
	public isCalibrate() {
		return this.status === NmrCalibrationStatus.CALIBRATION_CHECK_NEEDED || this.status === NmrCalibrationStatus.CALIBRATION_NEEDED;
	}
	public isInActive() {
		return this.status === NmrCalibrationStatus.INACTIVE;
	}
	public isCalibrating() {
		return this.status === NmrCalibrationStatus.CALIBRATING;
	}
	public isCalibrationError() {
		return this.status === NmrCalibrationStatus.CALIBRATION_ERROR;
	}
	public isCalibrationNeeded() {
		return this.status === NmrCalibrationStatus.CALIBRATION_NEEDED;
	}
}

export class NmrDeviceCalibrationForList extends NmrDeviceCalibration {
	calibrationStatus: NmrCalibrationStatus;
	calibrationName: string;
	model: string;

	supportedTechnology: AnalysisTechnology;
	calibrationErrorMessage?: string;
}
