import { Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DataTestId } from '@utils/DataTestId';
import { ExtendedComponent } from 'src/types';

type TEmptyResultProps = {
	title: string;
	explanation?: string;
	icon?: React.ReactNode;
};

export const EmptyResult: ExtendedComponent<TEmptyResultProps> = ({ title, explanation, children, icon }) => {
	return (
		<Stack
			width={1}
			sx={{ justifyContent: 'center', alignItems: 'center', paddingY: 7 }}
			data-testid={DataTestId.getStaticTestId('empty-result')}
		>
			<Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
				<Stack
					sx={{
						width: 80,
						height: 80,
						borderRadius: '50%',
						backgroundColor: 'grey.100',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: 3,
					}}
				>
					{icon || <SearchIcon sx={{ width: 40, height: 40, color: 'grey.400' }} />}
				</Stack>
				<Typography variant="h3" color="grey.800" marginBottom={1}>
					{title}
				</Typography>
				<Typography variant="pg-m" color="grey.800">
					{explanation}
				</Typography>

				{children}
			</Stack>
		</Stack>
	);
};
