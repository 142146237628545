export interface ISolvent {
	code: string;
	names: string[];
}

export const Solvents: ISolvent[] = [
	{
		code: 'DMSO',
		names: ['DMSO', 'DMSO-D6', 'DMSO-D', 'DMSO_SALT', 'DIMETHYLSULFOXIDE', 'DIMETHYLSULFOXIDE-D6', 'METHYLSULFOXIDE-D6'],
	},
	{
		code: 'CD2Cl2',
		names: [
			'CD2CL2',
			'DICHLOROMETHANED',
			'DICHLOROMETHANE',
			'METHYLENECHLORIDED',
			'METHYLENECHLORIDED2',
			'DICHLOROMETHANE-D2',
			'CDMD2',
		],
	},
	{ code: 'CDCl3', names: ['CDCL3', 'CHLOROFORM', 'CHLOROFORM-D'] },
	{ code: 'D2O', names: ['D2O', 'DEUTERIUMOXIDE', 'WATER-D2', 'DEUTERIUM OXIDE', 'D2O_SALT'] },
	{
		code: 'MeOD',
		names: ['MEOD', 'METHANOL-D4', 'METHANOL', 'CD3OD', 'METHANOLD', 'METHYLALCOHOL', 'METHYLALCOHOLD4'],
	},
	{
		code: 'CD3CN',
		names: ['CD3CN', 'MECN', 'ACETONITRILE-D3'],
	},
	{
		code: 'Acetone-d6',
		names: ['ACETONE-D6', 'ACETONED6', 'ACETONED', 'CD3COCD3'],
	},
	{
		code: 'BENZENE-d6',
		names: ['BENZENE-D6', 'C6D6'],
	},
	{
		code: 'Ethanol-d6',
		names: ['ETHANOL-D6', 'ETOD', 'CD3CD2OD'],
	},
	{
		code: 'THF-d8',
		names: ['THF-D8', 'TETRAHYDROFURAN'],
	},
];
