import { IRoute } from '@models';
import { PORTAL_ROUTES } from './Portal';
import { LoginFlow } from './Login';
import { AuthCallbackPage } from './AuthCallbackPage';
import { ADMIN_ROUTES } from './Admin/Routes';
import { AuthorizationComponent } from './Authorization';
import { Analytics } from '@components/common/Analytics';
import { ORGADMIN_ROUTES } from './OrgAdmin/Routes';

export const ROUTES: IRoute[] = [
	{
		path: '/login',
		element:
			process.env.NODE_ENV === 'test' ? (
				<LoginFlow />
			) : (
				<Analytics>
					<LoginFlow />
				</Analytics>
			),
	},
	{
		path: '/loggedin',
		element: <AuthCallbackPage mode="login" />,
	},
	{
		path: '/loggedout',
		element: <AuthCallbackPage mode="logout" />,
	},
	PORTAL_ROUTES,
	ADMIN_ROUTES,
	ORGADMIN_ROUTES,
	{
		// Rest matching works here but doesnt work in children
		path: '*',
		element: <AuthorizationComponent path="/portal" />,
	},
];
