import { DataTestId } from '@utils/DataTestId';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useDataTestId = () => {
	const loc = useLocation();

	useEffect(() => {
		DataTestId.resetState();
	}, [loc.pathname]);

	return null;
};
