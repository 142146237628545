export const NmrNonTargetedResultTableKeys = [
	'compoundName',
	'molecularWeight',
	'sampleWeight',
	'solventWeight',
	'massFraction',
	'concentration',
];
type NmrNonTargetedResultTableKeysType = (typeof NmrNonTargetedResultTableKeys)[number];

export const NonTargetedResultUnitMapping: Record<NmrNonTargetedResultTableKeysType, string | null> = {
	compoundName: null,
	molecularWeight: 'g/mol',
	sampleWeight: 'mg',
	solventWeight: 'mg',
	massFraction: 'g/g',
};
