import { FC } from 'react';
import { Organization } from '@services/core';
import { EditSubscriptionLayout } from '@components/admin/EditSubscriptionLayout';

interface ISubscriptionDetailsProps {
	organization?: Organization;
}

export const SubscriptionDetails: FC<ISubscriptionDetailsProps> = ({ organization }) => {
	return <>{!!organization && <EditSubscriptionLayout organizationData={organization} editing={false} />}</>;
};
