import { concatMap, tap, map } from 'rxjs/operators';
import { RxUtils, TypeUtils } from '@utils';
import { logger, httpService, IIrAddFavorite } from '@services';
import { PagedRequest, PagedResult } from '@models';
import { IrFavoriteReference } from './ir-favorite-reference.class';
import { irConfig } from '../irConfig';

export class IrFavoriteReferencesService {
	private getUrl() {
		return `${irConfig.url}/FavoriteIrDrm`;
	}

	get(id: number) {
		return httpService.get<IrFavoriteReference>(this.getUrl(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrFavoriteReference, result)),
		);
	}

	getAll(req: Partial<PagedRequest>) {
		const params = new URLSearchParams();
		Object.entries(req).forEach(([k, v]) => params.append(k, v.toString()));
		return this.getAllWithParams(params);
	}

	getAllWithParams(params: URLSearchParams) {
		return httpService
			.get<PagedResult<IrFavoriteReference[]>>(this.getUrl(), '', {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAll.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrFavoriteReference>(IrFavoriteReference), result)),
			);
	}

	create(data: IIrAddFavorite) {
		return httpService
			.post(this.getUrl(), '', {
				body: data,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.create.name}]`, result)),
				map((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	delete(id: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${id}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.delete.name}]`, result)));
	}

	update(id: number, name: string) {
		return httpService
			.put<unknown>(this.getUrl(), `${id}`, {
				body: { name },
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.update.name}]`, result)));
	}
}

export const irFavoriteReferencesService = new IrFavoriteReferencesService();
