import { nmrAnalysisService } from '@services/nmr';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';
import { RxUtils } from '@utils/Rx';

const changeNumberOfProtons = (
	analysisId: number,
	peakId: number,
	numberOfH: Maybe<number>,
	callback: () => void,
	compoundId?: Maybe<number>,
) => {
	RxUtils.promisify(
		nmrAnalysisService.changeNumberOfProtons(+analysisId, peakId, numberOfH?.toString() ?? '', compoundId?.toString() ?? ''),
		callback,
	);
};

export const assignCompoundToPeak = (analysisId: number, peakId: number, compoundId: Maybe<number>, callback: () => void) => {
	RxUtils.promisify(nmrAnalysisService.assignCompoundToPeak(+analysisId, peakId, compoundId?.toString() ?? ''), callback);
};

export const onPeakSelectionsChange = (
	analysisId: number,
	numberOfH: Maybe<number>,
	peak: QuantitivePeakType,
	callback: () => void,
	compoundId: Maybe<number>,
) => {
	const updatePeak = (selectedCompoundId: Maybe<number>, selectedNumberOfH: Maybe<number>) => {
		if (numberOfH || (compoundId && numberOfH)) {
			changeNumberOfProtons(analysisId, peak.id, selectedNumberOfH, callback, compoundId);
		}

		if (compoundId && !numberOfH) {
			assignCompoundToPeak(analysisId, peak.id, compoundId, callback);
		}
	};

	updatePeak(compoundId, numberOfH);
};

export const onUnselectingPeakIntegration = (analysisId: number, peakId: QuantitivePeakType, callback: () => void) => {
	RxUtils.promisify(nmrAnalysisService.unselectAnalysisPeak(+analysisId, peakId.id), callback);
};
