import { useLayoutEffect, useState } from 'react';
import { BrowserRouterProps, Router } from 'react-router-dom';
import { BrowserHistory } from 'history';
import { history as navigator } from '@utils';
interface Props extends BrowserRouterProps {
	history: BrowserHistory;
}
export const RouteProvider = ({ basename, history, children }: Props) => {
	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	});
	useLayoutEffect(() => history.listen(setState), [history]);
	return (
		<Router navigator={navigator} location={state.location} navigationType={state.action} basename={basename}>
			{children}
		</Router>
	);
};
